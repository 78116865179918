import { Col, Row, Space, Tag } from "antd";
import CustomFormItem from "components/Common/RFForm/CustomFormItem";
import LinkQuestionsTable from "../../LinkQuestionsTable";

const LinkQuestionsField: React.FC<any> = (props) => {
  const {
    input: { onChange, value },
    label,
    mode,
    meta,
  } = props;

  const fullLabel = `${label} | ${
    value.length > 0 ? `${value.length} selected` : "0 selected"
  }`;

  return (
    <CustomFormItem
      label={
        <Row>
          <Col flex="100">
            <Space>{fullLabel}</Space>
          </Col>
        </Row>
      }
    >
      {meta.error && meta.touched && (
        <Col flex="100" style={{ marginBottom: 12 }}>
          <Tag color="red">Please select at least 1 question</Tag>
        </Col>
      )}
      <LinkQuestionsTable onChange={onChange} value={value} mode={mode} />
    </CustomFormItem>
  );
};

export default LinkQuestionsField;
