import { P1 } from "./P1";
import { P2 } from "./P2";
import { P3 } from "./P3";
import { P4 } from "./P4";
import { P5 } from "./P5";

import "../style.css";

// prettier-ignore
const pages = [
  P1,
  P2,
  P3,
  P4,
  P5
];

export const FieldWrapper = ({ height, children, styles }) => {
  return (
    <div
      style={{
        ...styles,
        background: "#AAD3EE",
        maxWidth: "100%",
        border: "1px solid #000000",
        height: height ? height : 170,
        marginBottom: 12,
      }}
    >
      {children}
    </div>
  );
};

export default pages;
