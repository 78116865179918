import React, { useState } from "react";
import IntegrationCard from "./Components/IntegrationCard";
import SetupModal from "./Components/SetupModal";
import RunModal from "./Components/RunModal";
import DocsDrawer from "./Components/DocsDrawer";
import SetupSonarcloud from "./SetupForms/SetupSonarcloud";
import SetupNessus from "./SetupForms/SetupNessus";
import RunNessus from "./RunForms/RunNessus";
import NessusDocs from "./DocsForms/NessusDocs";
import { Button, List, message } from "antd";

import SonarCloudIcon from "../../../../images/icons/sonarcloud.svg";
import NessusLogo from "../../../../images/icons/nessus.svg";
import RunSonarCloud from "./RunForms/RunSonarCloud";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import { ApiTwoTone, CustomerServiceOutlined, InfoCircleOutlined } from "@ant-design/icons";

const GET_APP_INTEGRATION_STATUS = gql`
  query application($id: String!) {
    application(id: $id) {
      id
      name
      integrationFiles {
        isConfirmed
        tool
        id
        applicationId
        filename
        uploadedOn
        confirmedUploadOn
      }
    }
  }
`;

const IntegrationsDashboard: React.FC = () => {
  const { applicationId } = useParams();
  const [nessusState, setNessusState] = useState({
    configured: false,
    fileId: undefined,
  });
  const { data, loading } = useQuery(GET_APP_INTEGRATION_STATUS, {
    variables: {
      id: applicationId,
    },
    onCompleted: (res) => {
      console.log(res);
      res.application.integrationFiles.forEach((integration: any) => {
        if (!integration) return;
        if (integration.tool === "NESSUS") {
          setNessusState({
            configured: integration.isConfirmed,
            fileId: integration.id,
          });
        }
      });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });
  const [setupModal, setSetupModal] = useState<IntegrationState>({
    open: false,
    integration: undefined,
  });
  const [runModal, setRunModal] = useState<IntegrationState>({
    open: false,
    integration: undefined,
  });
  const [docsDrawer, setDocsDrawer] = useState<IntegrationState>({
    open: false,
    integration: undefined,
  });

  const openSetupModal = (integration: Integration) => {
    setSetupModal({
      open: true,
      integration,
    });
  };

  const openRunModal = (integration: Integration) => {
    setRunModal({
      open: true,
      integration,
    });
  };

  const openDocsDrawer = (integration: Integration) => {
    setDocsDrawer({
      open: true,
      integration,
    });
  };

  const integrations: Array<Integration> = [
    {
      id: "SONARCLOUD",
      name: "Sonarcloud",
      description: "Code Quality & Security",
      content: "Enhance Your Workflow with Continuous Code Quality & Code Security",
      url: "https://docs.sonarcloud.io/",
      configured: true,
      setup: SetupSonarcloud,
      run: RunSonarCloud,
      docs: undefined,
      image: SonarCloudIcon,
    },
    {
      id: "NESSUS",
      name: "Nessus",
      description: "Vulnerability Scanner",
      content:
        "The Global Gold Standard in Vulnerability Assessment - Built for the Modern Attack Surface",
      url: "https://docs.tenable.com/Nessus.htm",
      configured: nessusState.configured,
      // configured: false,
      fileId: nessusState.fileId,
      setup: SetupNessus,
      run: RunNessus,
      docs: NessusDocs,
      image: NessusLogo,
    },
    {
      id: "CYLANCE",
      name: "Cylance",
      description: "Blackberry Cyber Security",
      content:
        "Cylance technology powers BlackBerry cybersecurity, providing customers endpoint security that proactively detects cyberthreats and prevents cyberattacks from happening.",
      url: "https://docs.motar.io/",
      configured: false,
      setup: undefined,
      run: undefined,
      docs: undefined,
      image: undefined,
    },
    {
      id: "SENTINELONE",
      name: "SentinelOne",
      description: "Endpoint Security",
      content:
        "One platform. Unprecedented speed. Infinite scale. The future of cybersecurity is autonomous. Singularity XDR secures endpoint, cloud, and identity.",
      url: "",
      configured: true,
      setup: undefined,
      run: undefined,
      docs: undefined,
      image: undefined,
    },
    {
      id: "BLACKBERRY",
      name: "Blackberry",
      description: "Cybersecurity for All",
      content:
        "Cybersecurity has failed to keep up, because it fails to look ahead. Our intelligent security pairs artificial intelligence with machine learning to proactively protect your system from cyberthreats. It’s time to protect, prevent and respond.",
      url: "https://support.docusign.com/knowledgeMarket",
      configured: true,
      setup: undefined,
      run: undefined,
      docs: undefined,
      image: undefined,
    },
  ];

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="3rd Party Integrations"
        icon={<ApiTwoTone />}
        actions={[
          <Button
            key="support"
            icon={<CustomerServiceOutlined />}
            onClick={() =>
              window.open("https://i-csar.zendesk.com/hc/en-us", "_blank", "noopener")
            }
            style={{ marginRight: 16 }}
          >
            Integration Support
          </Button>,
          <Button
            key="about"
            icon={<InfoCircleOutlined />}
            onClick={() =>
              window.open("https://i-csar.zendesk.com/hc/en-us", "_blank", "noopener")
            }
          >
            About
          </Button>,
        ]}
      />

      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        dataSource={integrations}
        loading={loading}
        renderItem={(item: any) => (
          <List.Item key={item.title}>
            <IntegrationCard
              loading={false}
              integration={item}
              openSetupModal={openSetupModal}
              openRunModal={openRunModal}
              openDocsDrawer={openDocsDrawer}
            />
          </List.Item>
        )}
      />
      {setupModal.open && (
        <SetupModal
          state={setupModal}
          onClose={() => setSetupModal({ open: false, integration: undefined })}
        />
      )}
      {runModal.open && (
        <RunModal
          state={runModal}
          onClose={() => setRunModal({ open: false, integration: undefined })}
        />
      )}
      {docsDrawer.open && (
        <DocsDrawer
          state={docsDrawer}
          onClose={() => setDocsDrawer({ open: false, integration: undefined })}
        />
      )}
    </>
  );
};

export default IntegrationsDashboard;
