import gql from "graphql-tag";

export const ConditionalLogic = {
  GetAllConditionalRelationships: () => gql`
    query getAllConditionalRelationships {
      allConditionalRelationships {
        logicType
        parentModule {
          id
          name
        }
        parentCategory {
          id
          name
        }
        parentQuestion {
          id
          question
          questionType
          category {
            id
            name
          }
          linkedQuestions {
            answer {
              id
              answer
              answerType
            }
            question {
              id
              question
              category {
                id
                name
              }
              linkedQuestions {
                answer {
                  id
                  answer
                  answerType
                }
                question {
                  id
                  question
                  category {
                    id
                    name
                  }
                  linkedQuestions {
                    answer {
                      id
                      answer
                      answerType
                    }
                    question {
                      id
                      question
                      category {
                        id
                        name
                      }
                      linkedQuestions {
                        answer {
                          id
                          answer
                          answerType
                        }
                        question {
                          id
                          question
                          category {
                            id
                            name
                          }
                          linkedQuestions {
                            answer {
                              id
                              answer
                              answerType
                            }
                            question {
                              id
                              question
                              category {
                                id
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        parentAnswerChoice {
          id
          answer
          answerType
          additionalFieldType
        }
        linkedObject {
          ... on QuestionNode {
            id
            question
          }
          ... on QuestionModuleNode {
            id
            name
          }
        }
      }
    }
  `,
  GetExistingRelationships: () => gql`
    query getExistingRelationships {
      allConditionalRelationships {
        logicType
        parentAnswerChoice {
          id
          answer
          answerType
          additionalFieldType
        }
      }
    }
  `,
  ApplyCondition: () => gql`
    mutation applyCondition(
      $additionalFieldArgs: AdditionalFieldInputObject
      $additionalQuestionsArgs: AdditionalQuestionsInputObject
      $answerId: String!
      $conditionType: ConditionAnswerOption!
      $moduleFilterArgs: ModuleFilterInputObject
    ) {
      applyCondition(
        additionalFieldArgs: $additionalFieldArgs
        additionalQuestionsArgs: $additionalQuestionsArgs
        answerId: $answerId
        conditionType: $conditionType
        moduleFilterArgs: $moduleFilterArgs
      ) {
        answerId
        conditionType
        dataType
        questionIds
        moduleIds
      }
    }
  `,
  DeleteCondition: () => gql`
    mutation deleteCondition($answerId: String!) {
      removeCondition(answerId: $answerId) {
        answerId
      }
    }
  `,
};
