import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Api from "api";
import usePasswordChange from "components/Auth/useChangePassword";
import useAccessControl, { Roles } from "hooks/useAccessControl";
import useFormChanges from "hooks/useFormChanges";
import LoadingSpinner from "components/Common/LoadingSpinner";
import UserForm from "./UserForm";
import ManageRoleForm from "./ManageRoleForm";
import InputConfirmModal from "../../Common/InputConfirmModal";
import { Button, Col, Divider, message, Popconfirm, Row, Space, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";

interface Props {
  userId: string;
}

const ManageUser: React.FC<Props> = ({ userId }) => {
  const navigate = useNavigate();
  const { RBAC } = useAccessControl();

  const { sendPasswordResetEmail, sendPasswordResetEmailLoading } = usePasswordChange();
  const { formChanges, onChange, hasFormChanges } = useFormChanges({});

  const [manageRole, setManageRole] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { data, loading } = useQuery(Api.User.GetUserInfo(), {
    variables: {
      id: userId,
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const user: User = data?.user;

  const [update, { loading: updateLoading }] = useMutation(Api.User.UpdateInfo(), {
    onCompleted: (res) => {
      console.log(res);
      message.success(`Successfully updated ${user.email}`, 5);
      navigate(-1);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
    },
    refetchQueries: [{ query: Api.User.GetAll() }],
  });

  const updateUser = () => {
    console.log(formChanges);

    update({
      variables: {
        id: user.id,
        ...formChanges,
      },
    });
  };

  const handleChange = (changedValues) => {
    const ans = Object.keys(changedValues);
    onChange(ans[0], changedValues[ans[0]]);
  };

  const [deleteUser, { loading: deleteLoading }] = useMutation(Api.User.Delete(), {
    onCompleted: (res) => {
      console.log(res);
      message.success(`Successfully deleted ${user.email}`, 5);
      setDeleteModalOpen(false);
      navigate(-1);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
    },
    refetchQueries: [{ query: Api.User.GetAll() }],
  });

  const deleteUserMutation = () => {
    console.log(user);
    // if (user.email === "admin@csar.iis") {
    //   message.warn("Not allowed to delete this user");
    //   return;
    // }
    deleteUser({
      variables: {
        id: user.id,
      },
    });
  };

  const handleChangePassword = () => {
    console.log("Change Password");
    sendPasswordResetEmail(user.email);
  };

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <Row justify="space-between">
        <Col span={24}>
          <UserForm
            initialValues={user}
            loading={updateLoading || deleteLoading}
            submit={updateUser}
            onChange={handleChange}
            openRoleForm={() => setManageRole(true)}
            managingRole={manageRole}
            disableSubmit={!hasFormChanges}
            allowDelete={() => setDeleteModalOpen(true)}
          />
        </Col>

        {RBAC([Roles.GlobalAdmin, Roles.OrganizationAdmin]) && (
          <Row style={{ marginTop: 20 }}>
            <Popconfirm
              title={
                <Row>
                  <div>
                    Send Password Change email to <b>{user.email}</b>?
                  </div>
                </Row>
              }
              onConfirm={handleChangePassword}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={sendPasswordResetEmailLoading}>
                <Space>
                  <LockOutlined /> Send Password Change Email
                </Space>
              </Button>
            </Popconfirm>
          </Row>
        )}

        {manageRole && (
          <Col span={24} style={{ marginTop: 16 }}>
            <Divider style={{ marginTop: 0 }} />
            <Typography.Title level={3}>Manage Role</Typography.Title>
            <ManageRoleForm user={user} closeView={() => setManageRole(false)} />
          </Col>
        )}
      </Row>

      {deleteModalOpen && (
        <InputConfirmModal
          visible={deleteModalOpen}
          loading={deleteLoading}
          onClose={() => setDeleteModalOpen(false)}
          prompt="This will permanently delete this user."
          confirmation={`user/${user.email}`}
          mutation={deleteUserMutation}
        />
      )}
    </>
  );
};

export default ManageUser;
