import { Form, Input, Select, Col, Button, Row, Space } from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { ApplicationStatusEnums, RMFActionPathEnums } from "api/enums";
import { ApplicationNode } from "types";
import { useOrganizationList } from "api/hooks/useOrganizationList";

/**
 * Used in ManageApplication (app as initialValues)
 * Used in CreateApplication (admin side)
 * Used in CreateApplication (applicant side)
 */

type Props = {
  noRules?: boolean;
  loading: boolean;
  submit: (any) => any;
  allowDelete?: any;
  disableSubmit?: boolean;
  initialValues?: ApplicationNode;
  onChange?: any;
};

const ApplicationForm = ({
  noRules,
  submit,
  loading,
  allowDelete,
  disableSubmit,
  initialValues,
  onChange,
}: Props) => {
  const [form] = Form.useForm();
  const { organizations, loading: allOrgsLoading } = useOrganizationList();
  console.log({ organizations });
  const isManagingApp = !!initialValues?.id;

  return (
    <Form
      form={form}
      onFinish={submit}
      initialValues={{
        ...initialValues,
        organizationId: {
          label: initialValues?.organization?.name,
          value: initialValues?.organization?.id,
        },
      }}
      onValuesChange={onChange}
      name="applicationForm"
      layout="vertical"
      autoComplete="off"
      style={{ width: "100%", height: "100%" }}
      role="form"
    >
      <Row justify="start">
        <Col span={24}>
          <Form.Item
            label="Name"
            name="name"
            rules={noRules ? [] : [{ required: true, message: "Please input a name" }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="start">
        <Col span={24}>
          <Form.Item
            label="Organization"
            name="organizationId"
            rules={
              noRules ? [] : [{ required: true, message: "Please choose an organization" }]
            }
          >
            <Select
              showSearch
              options={organizations?.map((org) => ({
                key: org.id,
                label: org.name,
                value: org.id,
              }))}
              loading={allOrgsLoading}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item label="Platform" name="platform">
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item label="Status" name="status" style={{ textAlign: "left" }}>
            <Select options={ApplicationStatusEnums} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Action Path" name="rmfActionPath" style={{ textAlign: "left" }}>
            <Select options={RMFActionPathEnums} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Authorizer Name" name="authorizerName">
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item label="Authorizer Phone" name="authorizerPhoneNumber">
            <Input addonBefore="1" style={{ width: "100%" }} type="text" />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item label="Authorizer Email" name="authorizerEmail">
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <div
        style={
          isManagingApp
            ? { ...styles, bottom: 16, right: 24, position: "fixed" }
            : { ...styles }
        }
      >
        <Space>
          {allowDelete && (
            <Button type="primary" danger icon={<DeleteOutlined />} onClick={allowDelete}>
              Delete
            </Button>
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={disableSubmit}
              icon={isManagingApp ? <SaveOutlined /> : undefined}
            >
              {isManagingApp ? "Save" : "Submit"}
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

const styles = {
  display: "flex",
  height: 40,
  justifyContent: "flex-end",
};

export default ApplicationForm;
