import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Row, Col } from "antd";

import pages from "./Pages";

export const ITCSCv84Pages = pages;

export const ITCSCv84Header = (props) => {
  const document = props?.state?.document;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  }

  const fieldNames = [
    "document_classification_top_secret",
    "document_classification_secret",
    "document_classification_confidential",
    "document_classification_cui",
    "document_classification_unclassified",
  ];

  return (
    <Row justify="center" style={{ marginTop: 20 }}>
      <div style={{ width: "150px" }}>
        <SheetClassification
          fieldNames={fieldNames}
          elementProps={elementProps}
          document={document}
        />
      </div>
    </Row>
  );
};

export const ITCSCv84Footer = (props) => {
  const style = {
    paddingTop: 5,
    fontSize: 11,
  };

  const { state, pageNumber } = props;

  const document = state?.document;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  }

  const fieldNames = [
    "document_classification_top_secret",
    "document_classification_secret",
    "document_classification_confidential",
    "document_classification_cui",
    "document_classification_unclassified",
  ];

  // console.log({ document, state: props?.state });

  return (
    <Row justify="space-between">
      <Col span={8}>
        <p style={style}>DAF ITCSC Version 8.4 – 24 Mar 2023</p>
      </Col>
      <Col span={4.5}>
        <div style={{ width: "150px" }}>
          <SheetClassification
            fieldNames={fieldNames}
            elementProps={elementProps}
            document={document}
          />
        </div>
      </Col>
      <Col span={8}>
        <p style={{ ...style, textAlign: "right" }}>
          Page: {pageNumber} of {props.totalPages}
        </p>
      </Col>
    </Row>
  );
};

export const ClassificationSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={{ fontSize: 11, ...style }}
      label={config.label}
      fieldName={config.value}
      pageNumber={1}
      options={[
        { label: "TOP SECRET", value: `${config.value}_top_secret`, },
        { label: "SECRET", value: `${config.value}_secret`, },
        { label: "CONFIDENTIAL", value: `${config.value}_confidential`, },
        { label: "CUI", value: `${config.value}_cui`, },
        { label: "UNCLASSIFIED", value: `${config.value}_unclassified`, },
        { label: "TS/SCI", value: `${config.value}_tssci` },
      ]}
    />
  );
};

export const SheetClassification = ({ elementProps }) => {
  return (
    <ClassificationSelect
      style={{ width: "100%" }}
      elementProps={elementProps}
      config={{
        label: "Select Classification",
        value: "document_classification",
      }}
    />
  );
};

export const InformationTypeSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "HIGH", value: `${config.value}_high` },
        { label: "MODERATE", value: `${config.value}_moderate` },
        { label: "LOW", value: `${config.value}_low` },
      ]}
    />
  );
};

export const FirstCategorySubmissionSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "Yes", value: `${config.value}_yes` },
        { label: "No", value: `${config.value}_no` },
      ]}
    />
  );
};

export const EmassClassificationSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "NIPR", value: `${config.value}_nipr` },
        { label: "SIPR", value: `${config.value}_sipr` },
        { label: "N/A", value: `${config.value}_na` },
      ]}
    />
  );
};


// prettier-ignore
export const RmfActionSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "Assess & Authorize", value: `${config.value}_assess_and_authorize` },
        { label: "Assess Only - Assess and Incorporate", value: `${config.value}_assess_and_incorporate` },
        {
          label: "Assess Only - Assess and Approve for Use and Inherit Process",
          value: `${config.value}_assess_and_approve`,
        },
      ]}
    />
  );
};
