import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";

const SmartSearchDropdown = ({
  dataIndex,
  searchInput,
  selectedKeys,
  onChange,
  submit,
  reset,
}) => (
  <div style={{ padding: 8 }}>
    <Input
      name="smartSearchDropdownInput"
      ref={searchInput}
      placeholder={`Search ${dataIndex}`}
      value={selectedKeys[0]}
      onChange={onChange}
      style={{ width: 188, marginBottom: 8, display: "block" }}
    />
    <Space>
      <Button
        name="smartSearchDropdownReset"
        onClick={reset}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
      <Button
        name="smartSearchDropdownSearch"
        type="primary"
        onClick={submit}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90 }}
      >
        Search
      </Button>
    </Space>
  </div>
);

export default SmartSearchDropdown;
