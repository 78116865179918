import { Space, Button, List } from "antd";
import { OrderedListOutlined, PlusCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { GroupType, QuestionGroupNode, QuestionNode } from "types";
import { GroupFieldKeysEnum as FieldKeysEnum } from "../reducer";
import ContentCard from "../../../Shared/ContentCard";
import { mapTagToType } from "components/Common/Tags/QuestionType";

type Props = {
  group: QuestionGroupNode;
  loading: Loading;
  refetchGroup: any;
  editorMode: string;
  setEditorMode: any;
  setEditState: (arg0: FieldKeysEnum) => void;
};

const QuestionList: React.FC<Props> = ({ group, loading, setEditorMode, setEditState }) => {
  const groupHasQuestions = group?.questions?.length > 0 && group?.questions[0]?.id !== "423";
  const questionCount: number = group?.questions?.length;
  const groupType = group?.groupType;
  const questionList: QuestionNode[] | any[] = group?.questions ? group.questions : [];

  const Actions = () => {
    const manageButton = (
      <Button
        onClick={() => {
          setEditorMode("manage");
          setEditState(FieldKeysEnum.questions);
        }}
        icon={<SettingOutlined />}
      >
        Manage Questions
      </Button>
    );
    const orderButton = (
      <Button
        onClick={() => {
          setEditorMode("order");
          setEditState(FieldKeysEnum.questionOrder);
        }}
        icon={<OrderedListOutlined />}
      >
        Re-order
      </Button>
    );
    const addButton = (
      <Button
        onClick={() => {
          setEditorMode("manage");
          setEditState(FieldKeysEnum.questions);
        }}
        icon={<PlusCircleOutlined />}
      >
        Add Questions
      </Button>
    );

    if (groupType === GroupType.Control) {
      if (!groupHasQuestions) {
        return addButton;
      }
      return manageButton;
    }

    if (!groupHasQuestions) {
      return addButton;
    }

    return (
      <Space>
        {manageButton} {orderButton}
      </Space>
    );
  };

  return (
    <ContentCard
      title={`Question List (${questionCount})`}
      loading={loading}
      fieldKey={FieldKeysEnum.questions}
      editableField={false}
      extra={<Actions />}
      styles={{ height: 600 }}
      bodyStyles={{ height: 534, overflow: "auto" }}
    >
      <List
        dataSource={questionList}
        itemLayout="vertical"
        size="small"
        locale={{ emptyText: "No Questions Assigned" }}
        renderItem={(question: QuestionNode) => (
          <List.Item style={{ marginLeft: 0, paddingLeft: 0 }}>
            <List.Item.Meta
              title={<p style={{ fontSize: 14 }}>{question?.question}</p>}
              description={<p>{question?.helpText}</p>}
            />
            <div style={{ paddingBottom: 8 }}>
              {mapTagToType(question.questionType, question.id)}
            </div>
          </List.Item>
        )}
      />
    </ContentCard>
  );
};

export default QuestionList;
