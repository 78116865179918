import { Routes } from "react-router-dom";
import { PublicRoutes, PrivateRoutes, RenderRouting } from "routes";
import ApolloProvider from "components/Providers/WithApollo";
import ImpersonationProvider from "components/Providers/WithImpersonation";
import AuthProvider from "components/Providers/WithAuth";
import PermissionProvider from "components/Providers/WithPermission";
import "antd/dist/antd.css";
import "styles/index.css";

function App() {
  return (
    <div id="App">
      <ApolloProvider>
        <ImpersonationProvider>
          <AuthProvider>
            <PermissionProvider>
              <Routes>
                {RenderRouting(PublicRoutes)}
                {RenderRouting(PrivateRoutes)}
              </Routes>
            </PermissionProvider>
          </AuthProvider>
        </ImpersonationProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
