import { Card, CardProps, Typography } from "antd";

const { Title } = Typography;

interface WrapperCardProps extends CardProps {
  children: any;
  title?: string;
  rest?: any;
}

const WrapperCard: React.FC<WrapperCardProps> = ({ children, title, ...rest }) => {
  return (
    <Card
      title={
        title ? (
          <Title level={3} style={{ fontSize: 16 }}>
            {title}
          </Title>
        ) : undefined
      }
      bodyStyle={{ padding: 16 }}
      size="small"
      className="shadowed-static"
      {...rest}
    >
      {children}
    </Card>
  );
};

export default WrapperCard;
