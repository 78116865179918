import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, ResetButton } from "..";
import { Space, Button } from "antd";

export const P5 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="itcsc page" style={{ marginTop: '5%' }}>
        {/* 5. System or Subsystem */}
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width="33%" />
              <col span={1} width="33%" />
              <col span={1} width="33%" />
            </colgroup>

            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <div style={{ textAlign: 'center', verticalAlign: 'center' }}>
                      6. System or Subsystem Type
                      <b style={{ fontWeight: 'normal' }}> <i>Note 5:</i> Ensure System or Subsystem in Section 1 is selected prior to continuing this section</b>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td colSpan={2} style={{ borderRight: 'none' }}>
                  <b> System Type</b> <i>Note 6:</i> Systems are Assess and Authorize (A&A)
                </td>
                <td style={{ padding: 4, borderLeft: 'none' }}>
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '100%' }}
                    fieldName="6_system_or_subsystem_aa"
                    options={[
                      { label: 'Enclave', value: '6_system_or_subsystem_aa_enclave' },
                      { label: 'Major Application', value: '6_system_or_subsystem_aa_major_application' },
                      { label: 'Platform IT', value: '6_system_or_subsystem_aa_pit' },
                    ]}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={3} >
                  <b>Subsystem Type - PIT/Services/Products</b> <i>Note 7:</i> Subsystem Types are Assess Only
                </td>

              </tr>

              <tr>
                <td className="label" style={{ textAlign: 'center', borderBottom: 'none' }}>PIT</td>
                <td className="label" style={{ textAlign: 'center', borderBottom: 'none' }}>Services</td>
                <td className="label" style={{ textAlign: 'center', borderBottom: 'none' }}>Products</td>
              </tr>

              <tr>
                <td style={{ verticalAlign: 'top', borderBottom: 'none', borderTop: 'none', textAlign: 'center' }} className="answer">
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '80%' }}
                    label="Select One"
                    fieldName="6_subsystem_type_pit"
                    options={[
                      { label: 'PIT Product', value: '6_subsystem_type_pit_products' },
                      { label: 'PIT Subsystem', value: '6_subsystem_type_pit_subsystems' }
                    ]}
                  />
                </td>

                <td style={{ verticalAlign: 'top', borderBottom: 'none', borderTop: 'none', textAlign: 'center' }} className="answer">
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '80%' }}
                    label="Select One"
                    fieldName="6_subsystem_type_services"
                    options={[
                      { label: 'External', value: '6_subsystem_type_services_external' },
                      { label: 'Internal', value: '6_subsystem_type_services_internal' }
                    ]}
                  />
                </td>

                <td style={{ verticalAlign: 'top', borderBottom: 'none', borderTop: 'none', textAlign: 'center' }} className="answer">
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '80%' }}
                    label="Select One"
                    fieldName="6_subsystem_type_products"
                    options={[
                      { label: 'Application', value: '6_subsystem_type_products_application' },
                      { label: 'Hardware', value: '6_subsystem_type_products_hardware' },
                      { label: 'Software', value: '6_subsystem_type_products_software' }
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* 6. System Operational Status */}
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width="100%" />
            </colgroup>

            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <div>
                      7. System Operational Status
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody >
              <tr>
                <td colSpan={1} className="answer">
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '100%' }}
                    label="Select One"
                    fieldName="7_system_operational_status"
                    options={[
                      { label: 'Operational - System is in production (Initial Operting Capability (IOC) / Full Operating Capability (FOC))', value: '7_system_operational_status_operational' },
                      { label: 'Under Development - System is in the design phase', value: '7_system_operational_status_under_development' },
                      { label: 'Major Modification - System is undergoing significant change', value: '7_system_operational_status_major_modification' }
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* 7. System Description / Purpose */}
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width="100%" />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <div>
                      8. System Description / Purpose
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="text answer">
                  <i>Note 8:</i> Provide a description of the system environment. All acronyms must be spelled out first time in the<br />
                  description. Failure to spell out acronyms may result in the form being returned. This description should tell the story<br />
                  of who, what, where, when, why, and how the information and system will be protected against digital attacks.
                </td>
              </tr>
              <tr>
                <td className="answer">
                  <EditableField {...elementProps} fieldName="8_system_description_purpose"
                    style={{ minHeight: '200px' }}
                  />
                </td>
              </tr>

            </tbody>
          </table>
        </div>

        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width="100%" />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <div>
                      9. Boundary Topology and System Location
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="text answer">
                  <i>Note 9:</i> This is a text field only. Provide narrative reference in the field below and upload any pictures or diagrams (e.g.,<br />
                  DODAF OV-1 and SV-6), including interface requirements, to eMASS as artifacts. See instructions.
                </td>
              </tr>
              <tr>
                <td className="answer">
                  <EditableField {...elementProps} fieldName="9_system_description_purpose"
                    style={{ minHeight: '200px' }}
                  />
                </td>
              </tr>

            </tbody>
          </table>
        </div>

        <ResetButton>Reset Section 6-9</ResetButton>

      </div>
    </>
  );
};
