import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Api from "api";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  message,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { DeleteOutlined, EditOutlined, FilterTwoTone, PlusOutlined } from "@ant-design/icons";
import { ProList } from "@ant-design/pro-components";
import { Link } from "react-router-dom";
import { mapTagToType } from "components/Common/Tags/QuestionType";
import { WorkflowSearchViewSkeleton } from "components/Common/Skeletons/CustomSkeletons";
import InputConfirmModal from "components/Common/InputConfirmModal";
import "styles/workflowEditor.css";
import { QuestionTypesEnums } from "api/enums";
import { SelectedQuestion } from "./Components/reducer";
import { QuestionNode } from "types";
import CreateWorkflowModal from "../../../Modals/CreateWorkflowModal";
import useBreakpoints from "hooks/useBreakpoints";

type Props = {
  state: any;
  dispatch: any;
};

const WorkflowSearchView: React.FC<Props> = ({ state, dispatch }) => {
  const { breakpoints } = useBreakpoints();
  const [activeFilter, setActiveFilter] = useState<React.Key | undefined>("allQuestions");
  const [typeFilter, setTypeFilter] = useState("allQuestions");
  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  const [dataSource, setDataSource] = useState<Array<QuestionNode>>([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<SelectedQuestion | any>({});
  const { data, loading } = useQuery(Api.Question.GetFullQuestionary(), {
    onCompleted: (res) => {
      console.log({ initDataSource: res.allQuestions });
      setDataSource(res.allQuestions);
    },
    onError: (error) => {
      message.error(error.message, 7);
      console.log(error);
    },
  });
  const [deleteQuestion, { loading: deleteQuestionLoading }] = useMutation(
    Api.Question.Delete(),
    {
      onCompleted: () => {
        message.success("Successfully deleted a question!", 7);
        onClose();
      },
      onError: (error) => {
        message.error(error.message, 7);
        console.log(error.message);
        onClose();
      },
      refetchQueries: [Api.Question.WorkflowSearchView()],
    },
  );

  const deleteQuestionMutation = () => {
    deleteQuestion({
      variables: {
        id: selectedQuestion.id,
      },
    });
  };

  const onClose = () => {
    setDeleteModalOpen(false);
    setSelectedQuestion({});
  };

  const handleParentFilter = (filterKey: React.Key | undefined) => {
    setActiveFilter(filterKey);
    setTypeFilter("allQuestions");
    let filteredByParent;

    if (filterKey === "orphan") {
      filteredByParent = data.allQuestions.filter(
        (question: QuestionNode) => !question.categoryId && !question.group,
      );
    } else if (filterKey === "group") {
      filteredByParent = data.allQuestions.filter((question: QuestionNode) => question.group);
    } else {
      filteredByParent = data.allQuestions;
    }
    setDataSource(filteredByParent);
  };

  const handleTypeFilter = (typeKey: string) => {
    setTypeFilter(typeKey);
    setActiveFilter("allQuestions");
    let filteredByType;

    if (typeKey === "allQuestions") {
      filteredByType = data.allQuestions;
    } else {
      filteredByType = data.allQuestions.filter(
        (question: QuestionNode) => question.questionType === typeKey,
      );
    }

    setDataSource(filteredByType);
  };

  const clearTypeFilter = () => {
    console.log("on clear called!!!");
    setTypeFilter("allQuestions");
    setDataSource(data.allQuestions);
  };

  const search = (term: string) => {
    const list: Array<any> = [];

    data.allQuestions.forEach((question: QuestionNode) => {
      const questionMatch = question.question.toLowerCase().includes(term.toLowerCase());
      const answerMatch = question.answers.some((answer) => {
        if (answer?.answer) {
          return answer.answer.toLowerCase().includes(term.toLowerCase());
        } else {
          return false;
        }
      });
      if (questionMatch || answerMatch) {
        list.push(question);
      }
    });

    return list;
  };

  const handleSearch = (value: string) => {
    console.log({ searchedValue: value });
    if (value.length < 1) {
      setDataSource(data.allQuestions);
    } else {
      setDataSource(search(value));
    }
  };

  const orphanQuestionCount = data?.allQuestions.filter(
    (ques: QuestionNode) => !ques.categoryId && !ques.groupId,
  ).length;
  const inGroupQuestionCount = data?.allQuestions.filter(
    (ques: QuestionNode) => ques.group,
  ).length;

  const menuItems = [
    {
      key: "allQuestions",
      label: (
        <Space size={0}>
          <Typography.Text>All Questions</Typography.Text>
          {breakpoints.lg
            ? renderBadge(data?.allQuestions.length, activeFilter === "allQuestions")
            : undefined}
        </Space>
      ),
    },
    {
      key: "orphan",
      label: (
        <Space size={0}>
          No Category
          {breakpoints.lg
            ? renderBadge(orphanQuestionCount, activeFilter === "orphan")
            : undefined}
        </Space>
      ),
    },
    {
      key: "group",
      label: (
        <Space size={0}>
          In Group
          {breakpoints.lg
            ? renderBadge(inGroupQuestionCount, activeFilter === "group")
            : undefined}
        </Space>
      ),
    },
  ];

  const metaActions = (_text: never, record: SelectedQuestion) => {
    // will need recordId for manage/delete
    // popconfirm/ delete confirm modal
    return (
      <Space>
        <Tooltip title="Edit this question">
          <Link to={`/administration/workflow-editor/question-editor/${record.id}`}>
            <Button icon={<EditOutlined />}>Edit</Button>
          </Link>
        </Tooltip>
        <Tooltip title="Delete this question">
          <Button
            onClick={() => {
              setSelectedQuestion(record);
              setDeleteModalOpen(true);
            }}
            danger
            icon={<DeleteOutlined />}
          >
            Delete
          </Button>
        </Tooltip>
      </Space>
    );
  };

  return loading ? (
    <WorkflowSearchViewSkeleton />
  ) : (
    <>
      <Row style={{ height: 800, overflow: "auto" }}>
        <Col span={24}>
          <Card>
            <Typography.Title style={{ textAlign: "left", fontSize: 20, paddingTop: 0 }}>
              Question Search
            </Typography.Title>
            <Divider style={{ margin: "12px 0px 2px 0px" }} />
            <ProList
              cardProps={{
                bodyStyle: {
                  paddingInline: 0,
                  paddingBlock: 0,
                  textAlign: "left",
                },
              }}
              rowKey="id"
              itemLayout="vertical"
              split={true}
              className="workflow-search-view-list"
              showActions="hover"
              showExtra="hover"
              expandable={{
                expandedRowKeys,
                onExpandedRowsChange: setExpandedRowKeys,
              }}
              locale={{
                emptyText: <Empty description="No Data" />,
              }}
              dataSource={dataSource}
              toolbar={{
                menu: {
                  activeKey: activeFilter,
                  items: menuItems,
                  onChange: (key) => handleParentFilter(key),
                },
                search: {
                  onSearch: (value) => handleSearch(value),
                  onChange: (e) => handleSearch(e.target.value),
                  placeholder: "Search Questions & Answers",
                  style: {
                    width: breakpoints.xxl ? 300 : "auto",
                  },
                  enterButton: true,
                },
                actions: [
                  <Select
                    onSelect={(val: string) => handleTypeFilter(val)}
                    value={typeFilter}
                    options={[{ label: "Type", value: "allQuestions" }, ...QuestionTypesEnums]}
                    dropdownMatchSelectWidth={false}
                    suffixIcon={<FilterTwoTone />}
                    onClear={clearTypeFilter}
                    allowClear={typeFilter !== "allQuestions"}
                  />,
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setCreateModalOpen(true);
                    }}
                  >
                    {breakpoints.xl && "Create"}
                  </Button>,
                ],
              }}
              metas={{
                title: {
                  dataIndex: "question",
                },
                subTitle: {
                  dataIndex: "questionType",
                  render: (_text, record) => metaSubtitle(_text, record),
                },

                description: {
                  render: (text, record) => {
                    // greyed out subtitle/ description
                    return record.helpText
                      ? `Help Text: ${record.helpText}`
                      : "Help Text: Not Set";
                  },
                },
                content: {
                  render: (_text, record) => metaContent(_text, record),
                },

                extra: {
                  render: (_text: never, record: any) => metaActions(_text, record),
                },
              }}
            />
          </Card>
        </Col>
      </Row>
      {createModalOpen && (
        <CreateWorkflowModal
          open={createModalOpen}
          closeModal={() => setCreateModalOpen(false)}
          state={state}
        />
      )}
      {deleteModalOpen && (
        <InputConfirmModal
          visible={deleteModalOpen}
          prompt="This will permanently delete this question"
          confirmation="delete/question"
          onClose={() => setDeleteModalOpen(false)}
          mutation={deleteQuestionMutation}
          loading={deleteQuestionLoading}
        />
      )}
    </>
  );
};

const renderBadge = (count: number, active = false) => {
  return (
    <Badge
      count={count}
      style={{
        marginTop: -2,
        marginLeft: 4,
        color: active ? "#1890FF" : "#999",
        backgroundColor: active ? "#E6F7FF" : "#eee",
      }}
    />
  );
};

const metaContent = (_text: any, record: QuestionNode) => {
  return record.questionType === "MULTIPLE_CHOICE_PICK_ONE" ||
    record.questionType === "MULTIPLE_CHOICE_PICK_MANY" ? (
    <Space>
      <label>Answers:</label>
      <Space size={[0, 4]} wrap>
        {record.answers.map((ans) => (
          <Tag key={ans!.id}>{ans!.answer}</Tag>
        ))}
      </Space>
    </Space>
  ) : null;
};

const metaSubtitle = (_: any, record: QuestionNode) => {
  return (
    <Space key={record.id}>
      <Tag>{record.category ? record.category.name : "No Category"}</Tag>
      {record.group ? (
        <Tooltip title={record.group?.header}>
          <Tag>In Group</Tag>
        </Tooltip>
      ) : (
        <Tag>No Group</Tag>
      )}
      {mapTagToType(record.questionType, record.id)}
    </Space>
  );
};

export default WorkflowSearchView;
