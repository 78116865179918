import { Col, Row } from "antd";

interface CustomFormItemProps {
  children: any;
  label?: string | JSX.Element;
}

const CustomFormItem: React.FC<CustomFormItemProps> = ({ label, children }) => {
  return (
    <Row justify="start" gutter={label ? [0, 12] : undefined} style={{ marginBottom: 16 }}>
      <Col span={24}>
        <label style={{ fontWeight: 600 }}>{label}</label>
      </Col>
      <Col span={24}>{children}</Col>
    </Row>
  );
};

export default CustomFormItem;
