import { CategoryFieldKeysEnum } from "components/Administration/Workflow/Editor/Category Editor/Components/reducer";
import { GroupFieldKeysEnum } from "components/Administration/Workflow/Editor/Group Editor/Components/reducer";
import { FieldKeys } from "components/Administration/Workflow/Editor/Question Editor/Components/reducer";
import { SaveStatusEnum } from "components/Administration/Workflow/Editor/Shared/types";
import { useEffect, useMemo, useState } from "react";

type FieldKeyEnum = FieldKeys | GroupFieldKeysEnum | CategoryFieldKeysEnum;

const useEditorLoading = (
  fieldKey: FieldKeyEnum,
  state: any,
  initialLoading: boolean,
): boolean => {
  const [loading, setLoading] = useState(true);
  const fieldStatus = state[fieldKey].saveStatus;

  const fieldLoading = useMemo(() => {
    let loading = false;
    if (!fieldStatus) return loading;

    if (fieldStatus === SaveStatusEnum.Loading) {
      // loading = true;
      return true;
    }

    return loading;
  }, [fieldStatus]);

  useEffect(() => {
    if (initialLoading) {
      setLoading(true);
      return;
    }
    if (!fieldStatus) {
      setLoading(true);
      return;
    }

    if (fieldStatus === SaveStatusEnum.Loading) {
      setLoading(true);
      return;
    }

    setLoading(false);
    return;

    // return false;
  }, [fieldStatus, initialLoading]);

  return loading;
};

export default useEditorLoading;
