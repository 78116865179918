import { gql } from "graphql-tag";

export const Organization = {
  GetMy: () => gql`
    query myOrganizations {
      myOrganizations {
        id
        createdOn
        updatedOn
        name
        doingBusinessAs
        addressStreet
        addressCity
        addressState
        addressZip
        pocName
        pocEmail
        pocPhoneNumber
      }
    }
  `,
  GetAllBasicList: () => gql`
    query allOrganizationsBasicList {
      allOrganizations {
        id
        name
      }
    }
  `,
  GetAll: () => gql`
    query allOrganizations {
      allOrganizations {
        id
        createdOn
        updatedOn
        name
        doingBusinessAs
        addressStreet
        addressCity
        addressState
        addressZip
        pocName
        pocEmail
        pocPhoneNumber
      }
    }
  `,
  GetOne: () => gql`
    query organization($id: String!) {
      organization(id: $id) {
        id
        createdOn
        updatedOn
        name
        doingBusinessAs
        addressStreet
        addressCity
        addressState
        addressZip
        pocName
        pocEmail
        pocPhoneNumber
      }
    }
  `,
  Create: () => gql`
    mutation createOrganization(
      $name: String!
      $doingBusinessAs: String
      $address: AddressObject
      $pointOfContact: PersonObject
    ) {
      createOrganization(
        address: $address
        doingBusinessAs: $doingBusinessAs
        name: $name
        pointOfContact: $pointOfContact
      ) {
        id
        createdOn
        updatedOn
        name
        doingBusinessAs
        addressStreet
        addressCity
        addressState
        addressZip
        pocName
        pocEmail
        pocPhoneNumber
      }
    }
  `,
  Update: () => gql`
    mutation updateOrganization(
      $id: String!
      $name: String
      $doingBusinessAs: String
      $address: AddressObject
      $pointOfContact: PersonObject
    ) {
      updateOrganization(
        id: $id
        name: $name
        doingBusinessAs: $doingBusinessAs
        address: $address
        pointOfContact: $pointOfContact
      ) {
        id
        createdOn
        updatedOn
        name
        doingBusinessAs
        addressStreet
        addressCity
        addressState
        addressZip
        pocName
        pocEmail
        pocPhoneNumber
      }
    }
  `,
  Delete: () => gql`
    mutation deleteOrganization($id: String!) {
      deleteOrganization(id: $id) {
        deletedId
      }
    }
  `,
};
