import { Link } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Result, Button } from "antd";

const PageNotFound: React.FC = () => {
  useDocumentTitle("Page not found");

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary">
          <Link to="/home">Back to Home</Link>
        </Button>
      }
    />
  );
};

export default PageNotFound;
