import React from "react";
import DocumentField, { IDocumentFieldProps } from "./DocumentField";
import { Button } from "antd";

interface Props extends IDocumentFieldProps {
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  button?: boolean;
  label?: string;
  group?: any;
  extra?: any;
}

const EditableRadio: React.FC<Props> = (props) => {
  const { style, contentStyle, button, label, group, extra, editable } = props;
  return (
    <DocumentField {...props} fieldType="MULTIPLE_CHOICE_PICK_ONE" showAnswers>
      {({ handleClick, styleId, answer }) => {
        const checked = answer?.applicationAnswer?.isSaved;

        if (button) {
          return (
            <Button
              className="documentRadio"
              size="small"
              htmlType={"radio" as any}
              id={editable ? styleId : checked ? "answeredButton" : ""}
              disabled={!editable}
              onClick={handleClick}
            >
              {label}
            </Button>
          );
        }

        if (label === undefined) {
          return (
            <input
              readOnly
              type="radio"
              className="documentRadio"
              id={editable ? styleId : ""}
              name={group}
              checked={checked || false}
              onClick={handleClick}
            />
          );
        }

        return (
          <div style={{ display: "flex", width: "100%", ...style }}>
            <input
              readOnly
              style={{ alignSelf: "center" }}
              type="radio"
              className="documentRadio"
              id={editable ? styleId : ""}
              name={group}
              checked={checked || false}
              onClick={handleClick}
            />
            <div
              style={{
                display: "flex",
                marginLeft: "4px",
                width: "100%",
                alignItems: "center",
                ...contentStyle,
              }}
            >
              {label}
              {extra}
            </div>
          </div>
        );
      }}
    </DocumentField>
  );
};

export default EditableRadio;
