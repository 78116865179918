import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Col, Row, Space } from "antd";
import LetterBullet from "../Assets/LetterBullet";

export const P3 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pia"
        style={{
          width: "100%",
          fontSize: 10,
        }}
      >
        <div style={{ display: "flex" }}>
          <Row style={{ padding: "0 10px" }}>
            <b>m.</b>
          </Row>
          <Row>
            <b>
              What is the authority to collect information?  A Federal law or Executive Order must authorize the collection and maintenance of a system of records.  For PII not collected or maintained in a system of records, the collection or maintenance of the PII must be necessary to discharge the requirements of a statue or Executive Order.
            </b>
          </Row>
        </div>
        <div style={{ paddingLeft: 20, paddingTop: 10 }}>
          <Row>
            <LetterBullet letter='(1)'>
              If this system has a Privacy Act SORN, the authorities in this PIA and the existing Privacy Act SORN should be similar
            </LetterBullet>
          </Row>
          <Row>
            <LetterBullet letter='(2)'>
              If a SORN does not apply, cite the authority for this DoD information system or electronic collection to collect, use, maintain and/or disseminate  PII.
              <br />(If multiple authorities are cited, provide all that apply).
            </LetterBullet>
          </Row>
          <div style={{ paddingLeft: 20, paddingTop: 10 }}>
            <Row style={{ paddingBottom: 5 }}>
              <LetterBullet letter='(a)'>
                Cite the specific provisions of the statute and/or EO that authorizes the operation of the system and the collection of PII.
              </LetterBullet>
            </Row>
            <Row style={{ paddingBottom: 5 }}>
              <LetterBullet letter='(b)'>
                If direct statutory authority or an Executive Order does not exist, indirect statutory authority may be cited if the authority requires the
                operation or administration of a program, the execution of which will require the collection and maintenance of a system of records.
              </LetterBullet>
            </Row>
            <Row style={{ paddingBottom: 5 }}>
              <LetterBullet letter='(c)'>
                If direct or indirect authority does not exist, DoD Components can use their general statutory grants of authority (“internal housekeeping”) as
                the primary authority.  The requirement, directive, or instruction implementing the statute within the DoD Component must be identified.
              </LetterBullet>
            </Row>
          </div>
          <Row>
            <EditableField
              {...elementProps}
              fieldName="3_m_authority_to_collect_info"
              style={{ height: 50 }}
            />
          </Row>
        </div>

        <div style={{ display: "flex" }}>
          <Row style={{ padding: "0 10px" }}>
            <b>n.</b>
          </Row>
          <Row>
            <b>
              Does this DoD information system or electronic collection have an active and approved Office of Management and Budget (OMB) Control
              Number?
            </b>
          </Row>
        </div>
        <div style={{ paddingLeft: 20, paddingTop: 10 }}>
          <Row>
            Contact the Component Information Management Control Officer or DoD Clearance Officer for this information. This number indicates OMB approval to
            collect data from 10 or more members of the public in a 12-month period regardless of form or format.
          </Row>
          <br />
          <Row>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="3_n_dod_info_approved_omb_control_number_yes"
              />
              <span style={{ marginLeft: 10 }}>Yes</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="3_n_dod_info_approved_omb_control_number_no"
              />
              <span style={{ marginLeft: 10 }}>No</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="3_n_dod_info_approved_omb_control_number_pending"
              />
              <span style={{ marginLeft: 10 }}>Pending</span>
            </div>
          </Row>
          <br />
          <br />
          <Row>
            <LetterBullet letter='(1)'>
              If "Yes," list all applicable OMB Control Numbers, collection titles, and expiration dates.
            </LetterBullet>
          </Row>
          <Row>
            <LetterBullet letter='(2)'>
              If "No," explain why OMB approval is not required in accordance with DoD Manual 8910.01, Volume 2, " DoD Information Collections Manual:
              Procedures for DoD Public Information Collections.”
            </LetterBullet>
          </Row>
          <Row>
            <LetterBullet letter='(3)'>
              If "Pending," provide the date for the 60 and/or 30 day notice and the Federal Register citation
            </LetterBullet>
          </Row>
          <Row>
            <EditableField
              {...elementProps}
              fieldName="3_n_dod_info_approved_omb_control_number_pending_text"
              style={{ height: 50 }}
            />
          </Row>
        </div>
      </div>
    </>
  );
};
