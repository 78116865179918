import { useLazyQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import Api from "api";
import { QuestionCategoryNode } from "types";
import { message } from "antd";
import { client } from "components/Providers/WithApollo";

/**
 * @description: retrieves basic list of categories for use in selection components, will grab list from existing query if present, else query server
 */

const filterFromModules = (list: any) => {
  console.log({ list });
  let filtered: any = [];
  list.flatMap((mod) => {
    return mod.categories.forEach((cat) =>
      filtered.push({
        id: cat.id,
        name: cat.name,
        module: {
          name: mod.name,
          id: mod.id,
        },
      }),
    );
  });
  return filtered;
};

const filterBasicList = (list: QuestionCategoryNode[]) => {
  return list.map((module) => ({ name: module.name, id: module.id }));
};

export const useCategoryList = () => {
  const [categories, setCategories] = useState<BasicQuestionCategory[]>([]);
  const [queryCategories, { loading, error, refetch }] = useLazyQuery(
    Api.QuestionCategory.GetBasicList(),
    {
      onCompleted: (res) => {
        console.log({ res });
        const filtered = filterBasicList(res.allQuestionCategories);
        setCategories(filtered);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 5);
      },
    },
  );

  const getCategories = () => {
    try {
      const existing = client.readQuery({
        query: Api.QuestionModule.WorkflowEditor(),
      });
      console.log({ existing });
      if (existing?.allQuestionModules.length > 0) {
        const filtered = filterFromModules(existing.allQuestionModules);
        if (filtered.length > 0) {
          setCategories(filtered);
          console.log("categories already EXISTED", { filtered });
          return;
        }
      }
      console.log("queried for categories!");
      queryCategories();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return { categories, loading, error, refetch };
};
