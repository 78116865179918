import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { message } from "antd";
import Api from "api";
import { client } from "components/Providers/WithApollo";
import { OrganizationNode } from "types";

/**
 * @description: retrieves basic list of organizations for use in selection components, will grab list from existing query/cache if present, else query server
 */

export const useOrganizationList = () => {
  const [organizations, setOrganizations] = useState<BasicOrganization[]>([]);
  const [queryOrganizations, { loading, error, refetch }] = useLazyQuery(
    Api.Organization.GetAllBasicList(),
    {
      onCompleted: (res) => {
        console.log({ useOrganizationList: res });
        const filtered = filterBasicList(res.allOrganizations);
        setOrganizations(filtered);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(`Error retrieving Organization list: ${error.message}`, 5);
      },
    },
  );

  const getOrganizations = () => {
    try {
      const existingOrganizations = client.readQuery({
        query: Api.Organization.GetAll(),
      });

      if (existingOrganizations?.allOrganizations.length > 0) {
        const filtered = filterBasicList(existingOrganizations.allOrganizations);
        console.log({ existingOrganizations, filtered });
        setOrganizations(filtered);
        return;
      }

      queryOrganizations();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  return { organizations, loading, error, refetch };
};

const filterBasicList = (list: OrganizationNode[]) => {
  return list.map((organization) => ({ name: organization.name, id: organization.id }));
};
