import { Typography, Button, Form, Input, Modal } from "antd";
import React from "react";

const { Title, Text } = Typography;

type Values = {
  [key: string]: any;
};

type Props = {
  visible: boolean;
  loading: boolean;
  onClose: () => any;
  prompt: string;
  confirmation: string;
  mutation: () => any;
};

const InputConfirmModal: React.FC<Props> = ({
  visible,
  loading,
  onClose,
  prompt,
  confirmation,
  mutation,
}) => {
  const [form] = Form.useForm();
  confirmation = confirmation?.replaceAll(" ", "-");

  const onFinish = (values: Values) => {
    console.log("Success:", values);
    mutation();
  };

  return (
    <Modal
      width={500}
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose={true}
      title={
        <Title level={2} style={{ marginBottom: 0 }}>
          Delete entity
        </Title>
      }
    >
      <Form form={form} layout="vertical" onFinish={onFinish} preserve={false}>
        <Form.Item>
          <Text>{prompt}</Text>
          <br />
          <Text onClick={() => navigator.clipboard.writeText(confirmation)}>
            Please type <b>{confirmation}</b> to confirm.
          </Text>
        </Form.Item>
        <Form.Item
          name="confirmation"
          rules={[
            {
              validator: (_, value) => {
                if (value === confirmation) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Invalid confirmation text"));
              },
            },
          ]}
        >
          <Input autoFocus={true} autoComplete="off" autoCorrect="off" autoCapitalize="off" />
        </Form.Item>
        <Form.Item style={{ margin: "32px 0 0 0" }}>
          <Button htmlType="submit" type="primary" danger={true} loading={loading}>
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InputConfirmModal;
