import { useNavigate } from "react-router-dom";
import { client } from "../Providers/WithApollo";
import { useMutation } from "@apollo/client";
import Api from "api";
import { message } from "antd";

const useLogout = () => {
  const navigate = useNavigate();

  const [logoutFromCsarMutation, { loading: logoutLoading }] = useMutation(Api.User.Logout(), {
    onCompleted: (res) => {
      console.log(
        "%cLogout: %cRemoved Authorization, redirecting to Login",
        "color:steelblue",
        "color:green",
      );
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
    },
  });

  const logout = () => {
    logoutFromCsarMutation().then(() => {
      localStorage.removeItem("authorization");
      client.clearStore();

      setTimeout(() => {
        navigate("/login");
      }, 250);
    });
  };

  return { logout, logoutLoading };
};

export default useLogout;
