// workflow editors (NEW) shared types
export type SaveStatusType = "loading" | "success" | "error" | "default";
export enum SaveStatusEnum {
  Loading = "loading",
  Success = "success",
  Error = "error",
  Default = "default",
}

export type FieldEditState = {
  editActive: boolean;
  saveStatus: SaveStatusEnum;
};
