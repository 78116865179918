/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import useBreakpoints from "./useBreakpoints";
import { TabsPosition } from "antd/lib/tabs";

/**
 * Use this to hook to compute values from breakpoints, return what you need in the final obj
 *
 * @returns shared computed values
 * - shared component variables
 * - component classNames and styles
 */

const useViewport = () => {
  const { breakpoints } = useBreakpoints();

  const card = useMemo(() => {
    const bodyStyle = breakpoints.md
      ? { padding: 20 }
      : breakpoints.sm
      ? { padding: 10 }
      : breakpoints.xs
      ? { padding: 0 }
      : {};
    return {
      styles: {
        bodyStyle,
      },
    };
  }, [breakpoints.md, breakpoints.sm, breakpoints.xs]);

  const button = useMemo(() => {
    const showText = breakpoints.md ? true : false;
    return {
      showText,
    };
  }, [breakpoints.md]);

  const drawer = useMemo(() => {
    const resizable = breakpoints.md ? true : false;
    const small = breakpoints.lg ? 700 : breakpoints.md ? 700 : "100%";
    const large = breakpoints.xl ? 1200 : breakpoints.lg ? 900 : breakpoints.md ? 700 : "100%";

    return {
      resizable,
      width: {
        small,
        large,
      },
    };
  }, [breakpoints.md, breakpoints.lg, breakpoints.xl]);

  const layout = useMemo(() => {
    const collapsed = breakpoints.xl ? false : true;
    const sider = breakpoints.xl ? "layout-sider" : "layout-sider-collapsed";
    const brand = breakpoints.xl ? "sider-brand" : "sider-brand-small";
    const content = breakpoints.sm ? "layout-content" : "layout-content-mobile";

    return {
      collapsed,
      cns: {
        sider,
        content,
        brand,
      },
    };
  }, [breakpoints.sm, breakpoints.xl]);

  const layoutHeader = useMemo(() => {
    const headerRight = breakpoints.sm ? "header-right" : "header-right-mobile";
    return {
      cns: {
        headerRight,
      },
    };
  }, [breakpoints.sm]);

  const layoutFooter = useMemo(() => {
    const footer = breakpoints.sm ? "layout-footer" : "layout-footer-mobile";

    return {
      cns: {
        footer,
      },
    };
  }, [breakpoints.sm]);

  const breadcrumbs = useMemo(() => {
    const collapsed = breakpoints.sm ? true : false;

    return {
      collapsed,
    };
  }, [breakpoints.sm]);

  const account = useMemo(() => {
    const tabPosition: TabsPosition = breakpoints.md ? "left" : "top";
    const tabsCentered = tabPosition === "top" ? true : false;
    const card = {
      paddingLeft: breakpoints.md ? 0 : 24,
    };

    return {
      tabPosition,
      tabsCentered,
      styles: {
        card,
      },
    };
  }, [breakpoints.md]);

  const questionnaire = useMemo(() => {
    const pageControls = {
      showControls: breakpoints.md === true ? true : false,
      edges: breakpoints.lg === true ? 2 : 1,
    };

    const inputs = {
      text: breakpoints.md === true ? "75%" : "100%",
      date: breakpoints.md === true ? "35%" : "100%",
    };
    const additionalAnswerRow = {
      maxWidth: breakpoints.md === true ? "35%" : "100%",
    };

    return {
      pageControls,
      styles: {
        inputs,
        additionalAnswerRow,
      },
    };
  }, [breakpoints.md, breakpoints.lg]);

  const viewport = {
    isMobile: breakpoints.xs,
    isTablet: breakpoints.sm,
    isDesktop: breakpoints.md,
    card,
    button,
    drawer,
    layout,
    layoutHeader,
    layoutFooter,
    breadcrumbs,
    account,
    questionnaire,
  };

  // console.log({ viewport});

  return viewport;
};

export default useViewport;
