import { gql } from "graphql-tag";

export const Integration = {
  SetIntegrationConfig: () => gql`
    mutation setIntegrationConfig(
      $applicationId: String!
      $data: JSONString!
      $name: IntegrationTools!
    ) {
      setIntegrationConfig(applicationId: $applicationId, data: $data, name: $name) {
        id
        name
        applicationId
      }
    }
  `,
  ImportNessusFile: () => gql`
    mutation importNessusFileData($fileId: String!) {
      importNessusFileData(fileId: $fileId)
    }
  `,
  GetIssues: () => gql`
    query fetchSonarcloudIssues($applicationId: String!) {
      fetchSonarcloudIssues(applicationId: $applicationId)
    }
  `,
  UploadFile: () => gql`
    mutation uploadIntegrationFile(
      $applicationId: String!
      $filename: String!
      $tool: IntegrationTools!
    ) {
      uploadIntegrationFile(applicationId: $applicationId, filename: $filename, tool: $tool) {
        fileId
        postOutput {
          url
          fields
        }
      }
    }
  `,
  UpdateFileStatus: () => gql`
    mutation updateIntegrationFileStatus($fileId: String!, $status: Int!) {
      updateIntegrationFileStatus(fileId: $fileId, status: $status) {
        id
        filename
        applicationId
        tool
        isConfirmed
        uploadedOn
        confirmedUploadOn
      }
    }
  `,
  DeleteFile: () => gql`
    mutation deleteIntegrationFile($fileId: String!) {
      deleteIntegrationFile(fileId: $fileId) {
        deletedId
      }
    }
  `,
};
