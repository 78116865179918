import { useState } from "react";
import { useQuery } from "@apollo/client";
import Api from "api";
import useDocumentTitle from "hooks/useDocumentTitle";
import useAccessControl, { Roles } from "hooks/useAccessControl";
import CustomPageHeader, { PageHeaderTitle } from "components/Common/PageHeader/PageHeader";
import MyApplicationsTable from "./MyApplicationsTable";
import CreateModal from "components/Administration/Modals/CreateModal";
import CreateApplication from "components/Administration/Applications/CreateApplication";
import { Row, Col, Card, Button, message } from "antd";
import { HomeTwoTone, PlusOutlined } from "@ant-design/icons";

import { ApplicationNode, MyApplicationsQuery } from "types";

const HomeDashboard = () => {
  useDocumentTitle("Home");

  const { RBAC } = useAccessControl();

  const { data, loading } = useQuery<MyApplicationsQuery>(Api.Application.GetMy(), {
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const applications: Array<ApplicationNode> | any = data?.myApplications;
  data && console.log({ UsersApplications: applications });

  const closeModal = () => setCreateModalOpen(false);

  return (
    <>
      <CustomPageHeader className="shadowed-static" title="Home" icon={<HomeTwoTone />} />
      <Row justify="center">
        <Col span={24}>
          <Card
            className="shadowed-static"
            title={<PageHeaderTitle title="My Applications" level={2} />}
            extra={
              RBAC([Roles.ApplicantUser]) && (
                <Button
                  type="primary"
                  onClick={() => setCreateModalOpen(true)}
                  icon={<PlusOutlined />}
                  role="create-application"
                >
                  Create
                </Button>
              )
            }
            style={{ textAlign: "left" }}
          >
            <MyApplicationsTable loading={loading} data={applications}/>
          </Card>
        </Col>
      </Row>
      {createModalOpen && (
        <CreateModal title="Create Application" open={createModalOpen} onClose={closeModal}>
          <CreateApplication onClose={closeModal} />
        </CreateModal>
      )}
    </>
  );
};

export default HomeDashboard;
