import { Card, Col, Divider, Row, Typography } from "antd";
import { mapTagToType as mapConditionTypeTag } from "components/Common/Tags/AnswerCondition";
import { HorizontalLabel, VerticalLabel } from "../Shared/LabelWrapper";
import QuoteLabel from "../Shared/QuoteLabel";
import TextLabel from "../Shared/TextLabel";

const { Title } = Typography;

const ConditionInformation = ({ condition }) => {
  const moduleLabel = condition?.module ? condition.module.name : "Unassigned";
  const categoryLabel = condition?.category ? condition.category.name : "Unassigned";

  if (!condition) return <></>;

  return (
    <Card
      title={
        <Title level={3} style={{ fontSize: 16 }}>
          Condition Information
        </Title>
      }
      bodyStyle={{ padding: 16 }}
      size="small"
      className="shadowed-static"
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <HorizontalLabel>
            <TextLabel label="Module:" strong={true} />
            <TextLabel label={moduleLabel} />
          </HorizontalLabel>
        </Col>
        <Col span={24}>
          <HorizontalLabel>
            <TextLabel label="Category:" strong={true} />
            <TextLabel label={categoryLabel} />
          </HorizontalLabel>
        </Col>
        <Col span={24}>
          <HorizontalLabel align="end">
            <TextLabel label="Answer Condition:" strong={true} />
            {mapConditionTypeTag(condition.answerCondition)}
          </HorizontalLabel>
        </Col>
        <Col span={24}>
          <VerticalLabel>
            <TextLabel label="Answer Choice:" strong style={{ paddingRight: 8 }} />
            <TextLabel label={condition.answer.answer} />
          </VerticalLabel>
        </Col>
        <Divider style={{ margin: "6px 12px" }} />
        <Col span={24}>
          <VerticalLabel>
            <TextLabel label="Question Text:" strong={true} />
            <QuoteLabel>{condition.question.question}</QuoteLabel>
          </VerticalLabel>
        </Col>
      </Row>
    </Card>
  );
};

export default ConditionInformation;
