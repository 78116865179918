import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Button, Divider, message, Row, Space, Typography } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";

import Api from "api";
import LoadingSpinner from "components/Common/LoadingSpinner";
import RunResult from "../Components/RunResult";
import { ResultStatusType } from "antd/lib/result";
import { useParams } from "react-router-dom";
const { Paragraph } = Typography;

type Status = ResultStatusType;

const RunSonarCloud = ({ onClose }: any) => {
  const [runStatus, setRunStatus] = useState<Status | undefined>(undefined);

  if (runStatus) {
    return <RunResult status={runStatus} onClose={onClose} integrationName="Sonarcloud" />;
  } else {
    return <NotYetRanView setRunStatus={setRunStatus} />;
  }
};

const NotYetRanView = ({ setRunStatus }: any) => {
  const { applicationId } = useParams();
  const [fetchSonarIssues, { loading }] = useLazyQuery(Api.Integration.GetIssues(), {
    variables: {
      applicationId: applicationId,
    },
    onCompleted: (res) => {
      console.log(res);
      setRunStatus("success");
      message.success("Successfully ran SonarCloud", 7);
    },
    onError: (error) => {
      setRunStatus("error");
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const runSonarcloud = () => {
    fetchSonarIssues();
  };

  return (
    <>
      <Row>
        <Paragraph>
          Running the SonarCloud integration will populate the Security Findings dashboard with
          Security data results from SonarCloud.
        </Paragraph>

        <Paragraph strong style={{ marginTop: 24, marginBottom: 0 }}>
          Would you like to continue and run the integration?
        </Paragraph>
        {loading && (
          <Space style={{ marginTop: 24 }} align="baseline">
            <strong>Running Sonarcloud...</strong> <LoadingSpinner />
          </Space>
        )}
      </Row>
      <Divider />
      <Row justify="end">
        <Button
          type="primary"
          icon={<PlayCircleOutlined />}
          onClick={runSonarcloud}
          loading={loading}
        >
          Run
        </Button>
      </Row>
    </>
  );
};

export default RunSonarCloud;
