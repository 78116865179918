import { useState } from "react";
import { Divider, Modal, Segmented } from "antd";
import CreateQuestion from "../Workflow/Forms/Create/CreateQuestion";
import CreateModule from "../Workflow/Forms/Create/CreateModule";
import CreateCategory from "../Workflow/Forms/Create/CreateCategory";

import commonModalProps from "../../Common/commonModalProps";

/**
 * uses reducer state, only is used with ManageWorkflows.js
 * @returns conditional modal for CREATING workflows
 */

type Props = {
  open?: boolean;
  closeModal: () => void;
  state?: any;
};

const CreateWorkflowModal: React.FC<Props> = ({ open, closeModal, state }) => {
  const [activeTab, setActiveTab] = useState<string | number>("Module");

  return (
    <Modal
      title={`Create: ${activeTab}`}
      open={open}
      onCancel={closeModal}
      bodyStyle={{ maxHeight: 700, overflow: "auto" }}
      width={580}
      {...commonModalProps}
    >
      <Segmented
        value={activeTab}
        onChange={setActiveTab}
        options={["Module", "Category", "Question"]}
        style={{ marginBottom: 8 }}
        block
      />
      <Divider />
      {activeTab === "Module" && <CreateModule closeModal={closeModal} />}
      {activeTab === "Category" && <CreateCategory closeModal={closeModal} />}
      {activeTab === "Question" && <CreateQuestion closeModal={closeModal} />}
    </Modal>
  );
};

export default CreateWorkflowModal;
