import React, { createContext, useContext } from "react";
import { useQuery } from "@apollo/client";
import Api from "api";
import { useImpersonationContext } from "./WithImpersonation";
import LoadingSpinner from "../Common/LoadingSpinner";
import { AuthUserQuery, UserNode } from "types";

const AuthContext = createContext<any>(undefined);
export const useAuthContext = () => useContext(AuthContext);

export function shouldLogout(route: string) {
  if (route === "/login") {
    return false;
  }

  if (route.includes("/password-change/")) {
    return false;
  }

  return true;
}

const AuthProvider: React.FC<any> = ({ children }) => {
  const { data, error, loading } = useQuery<AuthUserQuery>(Api.User.GetAuth());
  const { state: impersonation } = useImpersonationContext();

  let context: UserNode | Partial<UserNode> = {};

  if (loading) {
    console.log("%cAuthProvider: %cQuery Loading", "color:steelblue", "color:goldenrod");
    return <LoadingSpinner global />;
  }

  if (error) {
    console.log("%cAuthProvider: %cQuery Error, User null", "color:steelblue", "color:red");

    if (shouldLogout(window.location.pathname)) {
      window.location.href = "/login";
    }

    localStorage.removeItem("authorization");
    context = {
      id: undefined,
      role: null,
      permissions: [],
      cac: null,
    };
  } else {
    console.log(
      "%cAuthProvider: %cQuery Success, User in context",
      "color:steelblue",
      "color:green",
    );

    context = {
      ...data?.me,
    } as UserNode;

    if (impersonation.role !== undefined) {
      context.role = impersonation.role;
      context.permissions = impersonation.permissions.map((p: any) => ({ permission: p }));
    }

    console.log({ User: context });
  }

  return <AuthContext.Provider value={{ user: context }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
