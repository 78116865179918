import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Col, Row } from "antd";

export const P5 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pia"
        style={{
          width: "100%",
          fontSize: 10,
        }}
      >
        <Row style={{ display: "flex" }}>
          <b>c. How will the Pll be secured?</b>
        </Row>
        <Row style={{ display: "flex" }}>
          <div style={{ paddingLeft: 20 }}>(1) Physical Controls. <i>(Check all that apply)</i></div>
        </Row>

        <Row style={{ paddingLeft: 10, paddingTop: 5 }}>
          <Col span={12}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c1_cipher_locks" />
                <span style={{ marginLeft: 10 }}>Cipher Locks</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c1_combination_locks" />
                <span style={{ marginLeft: 10 }}>Combination Locks</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c1_key_cards" />
                <span style={{ marginLeft: 10 }}>Key Cards</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c1_security_guards" />
                <span style={{ marginLeft: 10 }}>Security Guards</span>
              </Col>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c1_close_circuit_cctv" />
                <span style={{ marginLeft: 10 }}>Closed Circuit TV (CCTV)</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c1_identification_badges" />
                <span style={{ marginLeft: 10 }}>Identification Badges</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c1_safes" />
                <span style={{ marginLeft: 10 }}>Safes</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c1_other_info" />
                <span style={{ marginLeft: 10 }}>
                  If Other, enter the information in the box below
                </span>
              </Col>
            </div>
          </Col>
        </Row>

        <Row style={{ paddingTop: 10 }}>
          <EditableField
            {...elementProps}
            fieldName="5_c1_other_text"
            style={{ height: 50 }}
          />
        </Row>
        <Row style={{ paddingLeft: 20, paddingTop: 10 }}>
          (2) Administrative Controls. <i>(Check all that apply)</i>
        </Row>
        <Row style={{ paddingLeft: 10, paddingTop: 10 }}>
          <Col span={24}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c2_backups_secured_offsite" />
                <span style={{ marginLeft: 10 }}>Backups Secured Off-site</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c2_encryption_backups" />
                <span style={{ marginLeft: 10 }}>Encryption of Backups</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox
                  {...elementProps}
                  fieldName="5_c2_method_ensure_auth_access"
                />
                <span style={{ marginLeft: 10 }}>
                  Methods to Ensure Only Authorized Personnel Access to PII
                </span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox
                  {...elementProps}
                  fieldName="5_c2_periodic_security_audits"
                />
                <span style={{ marginLeft: 10 }}>Periodic Security Audits</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox
                  {...elementProps}
                  fieldName="5_c2_monitoring_users_security"
                />
                <span style={{ marginLeft: 10 }}>
                  Regular Monitoring of Users’ Security Practices
                </span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c2_other_info" />
                <span style={{ marginLeft: 10 }}>
                  Other, enter the information in the box below
                </span>
              </Col>
            </div>
          </Col>
        </Row>

        <Row style={{ paddingTop: 10 }}>
          <EditableField
            {...elementProps}
            fieldName="5_c2_other_text"
            style={{ height: 50 }}
          />
        </Row>

        <Row style={{ paddingLeft: 20, paddingTop: 10 }}>
          (3) Technical Controls. (Check al that apply)
        </Row>
        <Row style={{ paddingLeft: 10, paddingTop: 5 }}>
          <Col span={8}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_biometrics" />
                <span style={{ marginLeft: 10 }}>Biometrics</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_encryption_data_rest" />
                <span style={{ marginLeft: 10 }}>Encryption of Data at Rest</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_firewall" />
                <span style={{ marginLeft: 10 }}>Firewall</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox
                  {...elementProps}
                  fieldName="5_c3_role_based_access_controls"
                />
                <span style={{ marginLeft: 10 }}>Role-Based Access Controls</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_virtual_private_network" />
                <span style={{ marginLeft: 10 }}>Virtual Private Network (VPN)</span>
              </Col>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_command_access_card" />
                <span style={{ marginLeft: 10 }}>Command Access Card (CAC)</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_encryption_data_transit" />
                <span style={{ marginLeft: 10 }}>Encryption of Data in Transit</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_intrusion_detection" />
                <span style={{ marginLeft: 10 }}>Intrusion Detection System (IDS)</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_user_only_privilege" />
                <span style={{ marginLeft: 10 }}>
                  Used Only for Privileged (Elevated Roles)
                </span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_other_info" />
                <span style={{ marginLeft: 10 }}>
                  If Other, enter the information in the box below
                </span>
              </Col>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox
                  {...elementProps}
                  fieldName="5_c3_dod_public_key_infra_certs"
                />
                <span style={{ marginLeft: 10 }}>
                  DoD Public Key Infrastructure Certificates
                </span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox
                  {...elementProps}
                  fieldName="5_c3_external_cert_auth_certs"
                />
                <span style={{ marginLeft: 10 }}>
                  External Certificate Authority Certificates
                </span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_least_privilege_access" />
                <span style={{ marginLeft: 10 }}>Least Privilege Access</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="5_c3_user_iden_pass" />
                <span style={{ marginLeft: 10 }}>User Identification and Password</span>
              </Col>
            </div>
          </Col>
        </Row>

        <Row style={{ paddingTop: 10 }}>
          <EditableField
            {...elementProps}
            fieldName="5_c3_other_text"
            style={{ height: 60 }}
          />
        </Row>

        <Row style={{ paddingTop: 10 }}>
          <b style={{ fontSize: 10 }}>
            d. What additional measures/safeguards have been put in place to address privacy
            risks for this information system or electronic collection?
          </b>
          <EditableField
            {...elementProps}
            fieldName="5_d1_additional_measures_text"
            style={{ height: 60 }}
          />
        </Row>
      </div>
    </>
  );
};
