import { Tooltip, Space } from "antd";
import { LockTwoTone } from "@ant-design/icons";
import { QuestionModuleNode } from "types";

type Props = {
  module: QuestionModuleNode;
  locked?: boolean;
};

const LockedTabHeader: React.FC<Props> = ({ module, locked }) => {
  // displays lock for modules with checkpoints
  return locked ? (
    <Space key={module.id}>
      {module.name}
      <Tooltip title="Tied to checkpoint">
        <LockTwoTone style={{ fontSize: 20 }} twoToneColor="#c25604" />
      </Tooltip>
    </Space>
  ) : (
    <>{module.name}</>
  );
};

export default LockedTabHeader;
