import { gql } from "graphql-tag";

export const SecurityFindings = {
  GetAll: () => gql`
    query allSecurityFindings {
      allSecurityFindings {
        id
        sourceTool
        severity
        approvalStatus
        createdOn
        updatedOn
        alertName
        appId
        remediationDescription
        advisorGuidance
      }
    }
  `,
  GetAppFindings: () => gql`
    query securityFindings($appId: String!) {
      securityFindings(appId: $appId) {
        severity
        approvalStatus
        sourceTool
        id
        createdOn
        updatedOn
        alertName
        toolSeverity
        appId
        remediationDescription
        advisorGuidance
      }
    }
  `,
  // top 5 security findings chart
  GetTop5Findings: () => gql`
    query top5SecFindingsGraphData($filterby: IntegrationTools) {
      top5SecFindingsGraphData(filterby: $filterby) {
        count
        sourceTool
        alertName
      }
    }
  `,
  // Risk Distribution Chart
  GetRiskDistData: () => gql`
    query riskDistSecFindingsGraphData($stacked: Boolean = false) {
      riskDistSecFindingsGraphData(stacked: $stacked) {
        count
        sourceTool
        severity
      }
    }
  `,
  UpdateFinding: () => gql`
    mutation updateSecurityFinding(
      $id: String!
      $advisorGuidance: String
      $approvalStatus: ApprovalStatus
      $remediationDescription: String
    ) {
      updateSecurityFinding(
        id: $id
        advisorGuidance: $advisorGuidance
        approvalStatus: $approvalStatus
        remediationDescription: $remediationDescription
      ) {
        id
        appId
        updatedOn
        createdOn
        alertName
        severity
        sourceTool
        approvalStatus
        advisorGuidance
        remediationDescription
      }
    }
  `,
  DeleteFinding: () => gql`
    mutation deleteSecurityFinding($id: String!) {
      deleteSecurityFinding(id: $id) {
        deletedId
      }
    }
  `,
};
