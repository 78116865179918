import useDocumentTitle from "hooks/useDocumentTitle";
import { useLazyQuery } from "@apollo/client";
import Api from "api";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import { cyberPlans } from "components/CyberPlans";
import { Button, Row, Col, message, List } from "antd";
import { BookTwoTone, DownloadOutlined } from "@ant-design/icons";

const BUCKET = process.env.REACT_APP_CYBERPLAN_BUCKET;

const HomeCyberPlans = () => {
  useDocumentTitle("Cyber Plans");

  const [getDownloadUrl, { loading }] = useLazyQuery(Api.CyberPlan.GetCyberPlans(), {
    onCompleted: (res) => {
      const { downloadCyberplans } = res.me;
      if (!downloadCyberplans) return;
      window.open(downloadCyberplans, "_self");
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
    },
  });

  const handleDownloadClick = (fileName: string) => {
    getDownloadUrl({
      variables: {
        bucket: BUCKET,
        fileName: fileName,
      },
    });
    message.info("Standby, your download will begin shortly", 7);
  };

  const sortedCyberPlans = [...cyberPlans].sort((a, b) => a?.name.localeCompare(b?.name));

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="Cyber Plans"
        icon={<BookTwoTone />}
      />
      <Row align="middle" style={{ textAlign: "left" }}>
        <Col span={24}>
          <List
            className="shadowed-static"
            dataSource={sortedCyberPlans}
            rowKey={(row) => row.id}
            renderItem={(plan) => (
              <List.Item
                style={{ backgroundColor: "white", padding: "16px 20px" }}
                actions={[
                  <Button
                    role="download"
                    type="primary"
                    onClick={() => handleDownloadClick(plan.fileName)}
                    loading={loading}
                    icon={<DownloadOutlined />}
                  >
                    Download
                  </Button>,
                ]}
              >
                <List.Item.Meta title={plan.name} description={plan.description} />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default HomeCyberPlans;
