import { gql } from "graphql-tag";

export const UserRole = {
  GetAll: () => gql`
    query allRoles {
      allRoles {
        administrators {
          role
          permissions
        }
        advisors {
          role
          permissions
        }
        users {
          role
          permissions
        }
      }
    }
  `,
  UpdateRole: () => gql`
    mutation updateUserRole(
      $userId: String!
      $role: UserRole!
      $organizationId: String
      $applicationId: String
    ) {
      updateUserRole(
        userId: $userId
        role: $role
        applicationId: $applicationId
        organizationId: $organizationId
      ) {
        id
        firstName
        lastName
        salutation
        email
        role
      }
    }
  `,
};
