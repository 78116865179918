import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useAuthContext } from "components/Providers/WithAuth";
import useLocalStorage from "hooks/useLocalStorage";
import useViewport from "hooks/useViewport";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLogin from "./useLogin";
import CsarBrand from "components/Common/Brands/CsarBrand";
import CsarBrandFull from "components/Common/Brands/CsarBrandFull";
import CacBrand from "components/Common/Brands/CacBrand";
import LoadingSpinner from "../Common/LoadingSpinner";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Layout, Card, Button, Input, Form, Row, Col, Divider, Typography, Space } from "antd";

const Login: React.FC = () => {
  useDocumentTitle("CSAR | Login");
  const [auth] = useLocalStorage("authorization", { authorized: false });
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { user } = useAuthContext();

  const [pageReady, setPageReady] = useState(false);

  const { loginWithCsar, csarLoginLoading, loginWithCac, cacLoginLoading } =
    useLogin(setPageReady);

  const [form] = Form.useForm();

  useEffect(() => {
    const isAuth = auth.authorized;
    if (isAuth) {
      console.log(
        "%cLogin: %cFound Authorization, redirecting to Home",
        "color:steelblue",
        "color:red",
      );
      navigate("/home");
    }

    const timer = setTimeout(() => {
      setPageReady(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleCsarLogin = (values: any) => {
    loginWithCsar(values.email, values.password);
  };

  return (
    <LoginLayout pageReady={pageReady}>
      <Col
        style={{
          height: "100%",
          width: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Row justify="center">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Account Login
          </Typography.Title>
        </Row>

        <Divider style={{ margin: "20px 0" }} />
        <br />

        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Form name="loginForm" layout="vertical" form={form} onFinish={handleCsarLogin}>
            <Form.Item
              name="email"
              style={{ textAlign: "left", marginBottom: 30 }}
              rules={[
                {
                  required: true,
                  message: "Please input your email address",
                },
              ]}
            >
              <Input
                style={{
                  height: 35,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                type="email"
                placeholder="Email"
                prefix={<UserOutlined style={{ marginRight: 10 }} />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              style={{ textAlign: "left", marginBottom: 40 }}
              rules={[{ required: true, message: "Please input your password" }]}
            >
              <Input.Password
                style={{
                  height: 35,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                placeholder="Password"
                prefix={<LockOutlined style={{ marginRight: 10 }} />}
              />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={csarLoginLoading}
                style={{
                  width: "100%",
                  borderRadius: "50px",
                  height: 40,
                  fontSize: "0.9rem",
                }}
              >
                Login
              </Button>
            </Form.Item>

            {/* <ThirdPartyLoginLoadingSkeleton loading={cacLoginLoading}>
              <Form.Item style={{ marginBottom: 10 }}>
                <CacBrand onClick={loginWithCac} />
              </Form.Item>
            </ThirdPartyLoginLoadingSkeleton> */}
          </Form>
        </Row>

        <Divider style={{ margin: "0px 0" }} />
        <br />
        <Row justify="center">
          <Space align="end">
            <span style={{ whiteSpace: "nowrap" }}>Need an account?</span>
            <a href="https://i-csar.zendesk.com/hc/en-us" rel="noreferrer" target="_blank">
              <Button type="link" style={{ padding: 0, paddingTop: 10 }}>
                Contact Administrator
              </Button>
            </a>
          </Space>
        </Row>
      </Col>
    </LoginLayout>
  );
};

export default Login;

const LoginLayout = ({ pageReady, children }) => {
  const viewport = useViewport();
  const isDesktop = viewport.isDesktop;

  const Content = () => (
    <Row justify="space-around" align="stretch">
      <Col
        span={24}
        style={{
          width: "100%",
          maxWidth: !isDesktop ? "100%" : "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          textAlign: "left",
        }}
      >
        <div
          style={{
            flex: 2,
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CsarBrandFull width={250} />
        </div>
      </Col>
      {!isDesktop && <Divider style={{ margin: "20px 0" }} />}
      {children}
    </Row>
  );

  const Loading = () => (
    <Row justify="center">
      <div style={{ paddingBottom: 20 }}>
        <CsarBrand />
      </div>
      <LoadingSpinner />
      <br />
    </Row>
  );

  return (
    <Layout>
      <Row
        justify="center"
        align="middle"
        style={!isDesktop ? { height: "100%" } : { height: "100vh" }}
      >
        <Card
          className="shadowed-static"
          style={{
            width: "100%",
            maxWidth: !isDesktop ? "100%" : "800px",
          }}
        >
          {pageReady ? <Content /> : <Loading />}
        </Card>
      </Row>
    </Layout>
  );
};

const ThirdPartyLoginLoadingSkeleton = ({ loading, children }) => {
  if (loading)
    return (
      <>
        <div
          style={{
            height: "60px",
            display: "grid",
            placeItems: "center",
            marginBottom: "24px",
          }}
        >
          <LoadingSpinner />
        </div>
      </>
    );

  return children;
};
