import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import {
  AOBoundarySelect,
  ComplianceStatusSelect,
  EmassClassificationSelect,
  FirstCategorySubmissionSelect,
  ResetButton,
} from "..";

import { Row, Col, Button } from 'antd'

export const P2 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };


  // prettier-ignore
  const FirstCategorySubmissionConfig = {
    label: "",
    value: "first_categorization_submission"
  };

  const EmassClassificationConfig = {
    label: "",
    value: "emass_classification",
  };


  const Q2Radio = ({ fieldName, children }) => {
    return <EditableRadio {...elementProps} fieldName={fieldName} label={children} />
  }

  return (
    <>
      <div className="itcsc page">
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} />
              <col span={1} width={'55%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="text" colSpan={2}>
                  The purpose of Prepare step of the RMF is to identify essential activities of organization, mission, and business processes.<br />
                  The PM/RMF team is required to fill out the DAF ITCSC during this step to identify and prepare for the management of<br />
                  cybersecurity and privacy risks. See instructions for more details and examples.<i> Refs: (d-e)</i>
                </th>
              </tr>
              <tr>
                <th className="title" colSpan={2}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <div>1. System Identification Information</div>
                  </div>

                </th>
              </tr>

            </thead>
            <tbody>
              <tr>
                <td className="label">System Name:</td>
                <td><EditableField borderless {...elementProps} fieldName="system_name" /></td>
              </tr>

              <tr>
                <td className="label">System Acronym:</td>
                <td><EditableField borderless {...elementProps} fieldName="system_acronym" /></td>
              </tr>

              <tr>
                <td className="label">Version:</td>
                <td><EditableField borderless {...elementProps} fieldName="version" /></td>
              </tr>

              <tr>
                <td className="label">Is it a System or Subsystem? (Select one) (See sect 6)</td>
                <td>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                    <EditableRadio {...elementProps} fieldName="system_or_subsystem_system" button label="System" />
                    <EditableRadio {...elementProps} fieldName="system_or_subsystem_subsystem" button label="Subsystem" />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="label">First Categorization Submission:</td>
                <td><FirstCategorySubmissionSelect style={{ width: "100%", border: "none" }} elementProps={elementProps} config={FirstCategorySubmissionConfig} /></td>
              </tr>
              <tr>
                <td className="label">IT Investment Portfolio Suite (ITIPS) ID:</td>
                <td><EditableField borderless {...elementProps} fieldName="it_investment_portfolio" /></td>
              </tr>

              <tr>
                <td className="label">Clinger Cohen Act (CCA) Compliance Status</td>
                <td><ComplianceStatusSelect style={{ width: "100%", border: "none" }} elementProps={elementProps} config={{
                  label: 'Select One',
                  value: '1_cca_compliance_status'
                }} /></td>
              </tr>

              <tr>
                <td className="label">Enterprise Mission Assurance Support Service (eMASS) ID:</td>
                <td><EditableField borderless {...elementProps} fieldName="emass_id" /></td>
              </tr>
              <tr>
                <td className="label">eMASS Classification:</td>
                <td><EmassClassificationSelect style={{ width: "100%", border: "none" }} elementProps={elementProps} config={EmassClassificationConfig} /></td>
              </tr>
              <tr>
                <td className="label">DOD Information Technology Portfolio Repository (DITPR) ID:</td>
                <td><EditableField borderless {...elementProps} fieldName="ditpr" /></td>
              </tr>
              <tr>
                <td className="label">Air Force ID (AFID):</td>
                <td><EditableField borderless {...elementProps} fieldName="afid" /></td>
              </tr>
              <tr>
                <td colSpan={2} className="text" style={{ padding: '6px 10px' }}>
                  IAW AFMAN 17-1402,<i> Clinger Cohen Act (CCA) Compliance</i>, CCA Compliance Approval is required for all IT investments/ programs<br />
                  that acquire digital capabilities as defined in DODI 5000.82,<i> Requirements for the Acquisition of Digital Capabilities</i>, including NSS,<br />
                  regardless of acquisition pathway, acquisition category (ACAT) level, or business category (BCAT) level.<i> Refs: (f-g)</i><br />
                  <i>Note 1</i>: Systems in sustainment without an ITIPS ID must contact SAF/CNZA (SAF.CNZA.Workflow@us.af.mil) for CCA compliance
                </td>
              </tr>
            </tbody>
          </table>

          <table border={1}>
            <colgroup>
              <col span={1} width={'25%'} />
              <col span={1} width={'25%'} />
              <col span={1} width={'25%'} />
              <col span={1} width={'25%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={4}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <div>2. AO Boundary (Select One) <i>Ref: (h)</i></div>
                  </div>

                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4} style={{ padding: 4 }}>
                  <AOBoundarySelect
                    elementProps={elementProps}
                    style={{ width: '100%' }}
                    config={{
                      label: 'SELECT AO Boundary',
                      value: '1_ao_boundary'
                    }} />
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{ padding: 4 }}>
                  <Row gutter={10}>
                    <Col>
                      <EditableRadio {...elementProps} fieldName={'1_ao_boundary_other'} label="Other (Please specify - See Air Force Instruction (AFI) 17-101)" />
                    </Col>
                    <Col span={12}>
                      <EditableField borderless {...elementProps} fieldName="1_ao_boundary_text" />
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="text" style={{ padding: '6px 10px' }}>
                  <i>Note 2:</i> A listing of AOs and AO boundary descriptions can be found on the DAF RMF Knowledge Service <i>(https://rmfks.osd.mil/
                    rmf/Collaboration/Component%20Workspaces/AirForce/Lists/Air%20Force%20Authorization%20Boundaries/AllItems.aspx)</i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ResetButton>Reset Section 1-2</ResetButton>
      </div>
    </>
  );
};
