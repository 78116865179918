import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

export const P2 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <>
      <div
        className="doc_pcil"
        style={{
          width: "100%",
          display: "grid",
          placeItems: "center",
          fontSize: 11,
        }}
      >
        <b style={{ flex: 1, fontSize: 18, marginBottom: 40 }}>
          <u>Change History</u>
        </b>

        <div
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            border: "1px solid black",
            borderTop: "1px solid black",
            borderBottom: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Version</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Date of change</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Author(s)</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Description of change</b>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r1_version" />
              </div>
            </div>
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r1_date" isDate/>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r1_author" />
              </div>
            </div>
            <div
              style={{
                flex: 0.4,
                display: "flex",
                flexDirection: "column",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r1_description" />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r2_version" />
              </div>
            </div>
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r2_date" isDate/>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r2_author" />
              </div>
            </div>
            <div
              style={{
                flex: 0.4,
                display: "flex",
                flexDirection: "column",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r2_description" />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r3_version" />
              </div>
            </div>
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r3_date" isDate/>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r3_author" />
              </div>
            </div>
            <div
              style={{
                flex: 0.4,
                display: "flex",
                flexDirection: "column",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r3_description" />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r4_version" />
              </div>
            </div>
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r4_date" isDate/>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r4_author" />
              </div>
            </div>
            <div
              style={{
                flex: 0.4,
                display: "flex",
                flexDirection: "column",
                height: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r4_description" />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
                borderBottom: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r5_version" />
              </div>
            </div>
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: 40,
                overflow: "hidden",
                borderBottom: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r5_date" isDate />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
                height: 40,
                borderBottom: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r5_author" />
              </div>
            </div>
            <div
              style={{
                flex: 0.4,
                display: "flex",
                flexDirection: "column",
                height: 40,
                borderBottom: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField borderless {...elementProps} fieldName="2_r5_description" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
