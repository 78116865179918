import { Button, Result } from "antd";

/**
 *
 * React-error-boundary will catch component render errors
 * it will NOT catch asynchronous code errors, or event handler errors
 * it is up to the developer if they want to catch errors somewhere using the hook
 * as it can depend on how they want the UI to respond
 */

const FallbackUI: React.FC<any> = ({ error, componentStack, resetErrorBoundary }) => {
  if (error) {
    console.log("error from boundary");
    console.log(error.message);
  }
  if (componentStack) {
    console.log("componentStack from boundary");
    console.log(componentStack);
  }

  // todo: where to re-route back to, check react router 6 props for intelligent routing
  const handleGoBack = () => {
    resetErrorBoundary();
    return (window.location.href = "/");
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="500"
        title="Error"
        subTitle="Oops! Something went wrong"
        extra={[
          <Button type="primary" onClick={handleGoBack}>
            Go Back
          </Button>,
          <Button>Report Issue</Button>,
        ]}
      />
    </div>
  );
};

export default FallbackUI;
