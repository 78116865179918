import { useState } from "react";
import { Drawer } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeaderTitle } from "components/Common/PageHeader/PageHeader";
import ManageApplication from "../Applications/ManageApplication";
import ManageOrganization from "../Organizations/ManageOrganization";
import ManageUser from "../Users/ManageUser";

interface Props {
  title: string;
  type: "application" | "organization" | "user";
}

const ManageDrawer: React.FC<Props> = ({ title, type }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { applicationId, organizationId, userId } = useParams<string>();

  console.log({ applicationId, organizationId, userId });

  const onClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const MANAGE_FORMS = {
    application: <ManageApplication applicationId={applicationId!} />,
    organization: <ManageOrganization organizationId={organizationId!} />,
    user: <ManageUser userId={userId!} />,
  };

  return (
    <Drawer
      width={800}
      open={open}
      onClose={onClose}
      bodyStyle={{
        overflow: "auto",
      }}
      title={<PageHeaderTitle title={title} level={2} />}
    >
      {MANAGE_FORMS[type]}
    </Drawer>
  );
};

export default ManageDrawer;
