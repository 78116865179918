import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Api from "api";
import useDocumentTitle from "hooks/useDocumentTitle";
import useAccessControl, { Permissions } from "hooks/useAccessControl";
import useViewport from "hooks/useViewport";
import { mapStatusToTag } from "../../Common/Tags/ApplicationStatus";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import CreateApplication from "./CreateApplication";
import CreateModal from "components/Administration/Modals/CreateModal";
import SmartTable, { compareDates } from "components/Common/Tables/index";
import { Row, Col, Card, Button, message } from "antd";
import { ApartmentOutlined, PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { ApplicationNode } from "types";

const ManageApplications = () => {
  useDocumentTitle("Applications");
  const { PBAC } = useAccessControl();
  const viewport = useViewport();

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { data, loading } = useQuery(Api.Application.GetAll(), {
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const applications = data?.allApplications;
  console.log({ Applications: applications });

  const columns: ColumnsType<ApplicationNode> = [
    {
      title: "Created On",
      dataIndex: "createdOn",
      defaultSortOrder: "descend",
      sorter: (a, b) => compareDates(a.createdOn, b.createdOn),
      render: (createdOn) => createdOn && new Date(createdOn).toDateString(),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return status ? mapStatusToTag(status) : undefined;
      },
    },
    {
      title: "Action Path",
      dataIndex: "rmfActionPath",
    },
    {
      title: "Last Updated",
      dataIndex: "updatedOn",
      key: "updatedOn",
      sorter: (a, b) => compareDates(a.updatedOn, b.updatedOn),
      render: (updatedOn) => updatedOn && new Date(updatedOn).toLocaleString(),
    },
    {
      title: "Action",
      width: "10%",
      align: "center",
      render: (_: any, application) => (
        <Link
          to={`/administration/applications/manage/${application.id}`}
          state={{ application }}
        >
          Manage
        </Link>
      ),
    },
  ];

  const headerActions = PBAC([Permissions.CreateApplications])
    ? [
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setCreateModalOpen(true)}
        >
          Create
        </Button>,
      ]
    : undefined;

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="Applications"
        icon={<ApartmentOutlined style={{ color: "rgb(24, 144, 255)" }} />}
        subTitle="Applications you are permitted to manage"
        actions={headerActions}
      />
      <Row justify="center" align="middle">
        <Col span={24}>
          <Card className="shadowed-static" bodyStyle={viewport.card.styles.bodyStyle}>
            <SmartTable loading={loading} data={applications} columns={columns} />
          </Card>
        </Col>
      </Row>

      {createModalOpen && (
        <CreateModal
          title="Create Application"
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
        >
          <CreateApplication onClose={() => setCreateModalOpen(false)} />
        </CreateModal>
      )}
      <Outlet />
    </>
  );
};

export default ManageApplications;
