import React from "react";
import { useQuery } from "@apollo/client";
import Api from "api";
import useAccessControl, { Roles } from "hooks/useAccessControl";
import useBreadcrumbs from "hooks/useBreadCrumbs";
import useViewport from "hooks/useViewport";
import useEnumPresentation from "hooks/useEnumPresentation";
import { useAuthContext } from "components/Providers/WithAuth";
import { useImpersonationContext } from "components/Providers/WithImpersonation";
import { message, Tooltip, Layout, Row, Col, Space, Dropdown, Button, Breadcrumb } from "antd";
import { DownOutlined, CustomerServiceOutlined, UserSwitchOutlined } from "@ant-design/icons";

import "styles/layoutHeader.css";

const LayoutHeader = () => {
  const breadcrumbs = useBreadcrumbs();
  const viewport = useViewport();

  return (
    <Layout.Header className="shadowed-static layout-header">
      <Row justify="space-between">
        <Col>
          <div className="header-left">
            <Space direction="horizontal" align="center">
              <Breadcrumb>{breadcrumbs}</Breadcrumb>
            </Space>
          </div>
        </Col>
        <Col>
          <div className={viewport.layoutHeader.cns.headerRight}>
            <ImpersonationDropdown showButtonText={viewport.button.showText} />
            <HelpDropdown showButtonText={viewport.button.showText} />
          </div>
        </Col>
      </Row>
    </Layout.Header>
  );
};

const structureRoles = (rolesObj) => {
  const roleKeys = Object.keys(rolesObj);
  const structure: any = [];
  roleKeys.forEach((key) => {
    const roles = rolesObj[key];
    structure.push(
      ...roles.map((role: any) => ({
        role: role.role,
        permissions: role.permissions,
      })),
    );
  });
  return structure;
};

export const ImpersonationDropdown: React.FC<any> = ({ showButtonText }) => {
  const { user } = useAuthContext();
  const { state: impersonation, dispatch } = useImpersonationContext();

  const { PresentRoleName } = useEnumPresentation();
  const { RBAC } = useAccessControl();

  const { data } = useQuery(Api.UserRole.GetAll(), {
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const roleStructure = {
    administrators: data?.allRoles.administrators,
    advisors: data?.allRoles.advisors,
    users: data?.allRoles.users,
  };
  const allRoles = data ? structureRoles(roleStructure) : [];

  const setImpersonation = (role) => {
    console.log("Set: Impersonation State");

    sessionStorage.setItem("roleImpersonation", JSON.stringify(role));
    dispatch({
      type: "SET_IMPERSONATION",
      impersonation: role,
    });
  };
  const resetImpersonation = () => {
    console.log("Reset: Impersonation State");

    sessionStorage.removeItem("roleImpersonation");
    dispatch({
      type: "STOP_IMPERSONATION",
      impersonation: {
        role: undefined,
        permissions: [],
      },
    });
  };
  const impersonationMenu = {
    items: [
      {
        type: "group",
        key: "roleImpersonation",
        label: "Role Impersonation",
        children: allRoles
          .filter((role: any) => role.role !== user.role)
          .map((role: any) => ({
            key: role.value,
            label: (
              <div onClick={() => setImpersonation(role)}>{PresentRoleName(role.role)}</div>
            ),
          })),
      },
    ],
  };

  return (
    <>
      {impersonation.role ? (
        <>
          <Tooltip
            title={
              <>
                <Row>{PresentRoleName(user.role)}</Row>
                <Row>
                  <Button style={{ marginTop: 5, width: "100%" }} onClick={resetImpersonation}>
                    Stop
                  </Button>
                </Row>
              </>
            }
          >
            <Button danger style={{ marginRight: 10 }}>
              {showButtonText ? (
                <Space>
                  <UserSwitchOutlined />
                  Impersonating
                </Space>
              ) : (
                <UserSwitchOutlined />
              )}
            </Button>
          </Tooltip>
        </>
      ) : (
        RBAC([Roles.GlobalAdmin]) && (
          <Dropdown menu={impersonationMenu} placement="bottomRight" arrow>
            <Button style={{ marginRight: 10 }}>
              <UserSwitchOutlined />
            </Button>
          </Dropdown>
        )
      )}
    </>
  );
};

export const HelpDropdown: React.FC<any> = ({ showButtonText }) => {
  const helpCenterLink = process.env.REACT_APP_HELP_CENTER;
  const contactUsLink = "https://i-csar.zendesk.com/hc/en-us/requests/new";

  const helpMenu = {
    items: [
      {
        key: "helpCenter",
        label: (
          <a target="_blank" rel="noopener noreferrer" href={helpCenterLink}>
            Help Center
          </a>
        ),
      },
      {
        key: "contactUs",
        label: (
          <a target="_blank" rel="noopener noreferrer" href={contactUsLink}>
            Contact Us
          </a>
        ),
      },
    ],
  };

  return (
    <Dropdown menu={helpMenu} placement="bottomRight" arrow>
      <Button>
        <Space>
          <CustomerServiceOutlined />
          {showButtonText && (
            <>
              Get Help
              <DownOutlined />
            </>
          )}
        </Space>
      </Button>
    </Dropdown>
  );
};

export default LayoutHeader;
