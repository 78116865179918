import { useState } from "react";
import { SkeletonWrapper } from "components/Common/Skeletons/SkeletonLoading";
import ChartHeader from "../Components/ChartHeader";
import FilterIcon from "../Components/FilterIcon";
import { Card, Empty, message, Select } from "antd";
import { Bar } from "@ant-design/plots";
import { AlertTwoTone } from "@ant-design/icons";
import Api from "api";
import { useQuery } from "@apollo/client";

type FilterOptions =
  | false
  | "BLACKBERRY"
  | "CYLANCE"
  | "NESSUS"
  | "SENTINELONE"
  | "SONARCLOUD";

const TopFiveChart = () => {
  const [filter, setFilter] = useState<FilterOptions>(false);

  const { data, loading } = useQuery(Api.SecurityFindings.GetTop5Findings(), {
    variables: filter
      ? {
          filterby: filter,
        }
      : undefined,
    onCompleted: (res) => {
      console.log({ top5Data: res.top5SecFindingsGraphData });
      setInitData(res.top5SecFindingsGraphData);
      const cloneData = [...res.top5SecFindingsGraphData];
      let sortedData = cloneData.sort((a, b) => {
        return b.count - a.count;
      });

      const finalData = sortedData.slice(0, 5);
      setDataSource(finalData);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const [initData, setInitData] = useState(data?.top5SecFindingsGraphData);
  const [dataSource, setDataSource] = useState(data?.top5SecFindingsGraphData);

  const config: any = {
    data: dataSource,
    xField: "count",
    yField: "alertName",
    autoSize: false,
    height: 200,
    color: () => "#0E1B41",
    label: {
      position: "middle",
    },
    yAxis: {
      label: {
        // autoEllipsis: true,
        formatter: (text: string) => {
          return text.slice(0, 15);
        },
      },
    },
    tooltip: {
      showTitle: false,
      customContent: (title: string, data: any) => {
        let dataValue;
        if (data[0]?.data) {
          dataValue = data[0].value;
        }
        return <ToolTip title={title} value={dataValue} />;
      },
    },
  };

  const handleFilter = (filterKey: FilterOptions) => {
    setFilter(filterKey);
  };

  return (
    <Card
      className="shadowed-static"
      title={
        <ChartHeader
          title="Top 5 Security Findings"
          icon={<AlertTwoTone />}
          info="Top 5 info coming soon!"
        />
      }
      extra={
        <Select
          style={{ width: 200 }}
          options={filterOptions}
          onSelect={handleFilter}
          value={filter}
          suffixIcon={<FilterIcon filterActive={filter !== false} />}
          onClear={() => {
            setFilter(false);
            setDataSource(initData);
          }}
          allowClear={filter !== null}
        />
      }
    >
      <SkeletonWrapper loading={loading} type="barChart">
        {dataSource?.length >= 1 ? <Bar {...config} /> : <Empty />}
      </SkeletonWrapper>
    </Card>
  );
};

const ToolTip = ({ title, value }: { title: string; value: number }) => {
  return (
    <div style={{ paddingTop: 12 }}>
      <p>Alert Name: {title}</p>
      {value ? <p>Count: {value}</p> : null}
    </div>
  );
};

const filterOptions = [
  { label: "Source Tool (All)", value: false },
  { label: "Blackberry", value: "BLACKBERRY" },
  { label: "Cylance", value: "CYLANCE" },
  { label: "Nessus", value: "NESSUS" },
  { label: "SentinelOne", value: "SENTINELONE" },
  { label: "Sonarcloud", value: "SONARCLOUD" },
];

export default TopFiveChart;
