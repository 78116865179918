import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useViewport from "./useViewport";
import { Breadcrumb, Tooltip } from "antd";
import { ClusterOutlined, AppstoreOutlined, HomeOutlined } from "@ant-design/icons";
import { validate as uuidValidate } from "uuid";
import useProtectedParams from "./useProtectedParams";

const useBreadcrumbs = () => {
  const viewport = useViewport();
  const location = useLocation();
  const params = useProtectedParams();
  const {
    userId,
    organizationId,
    applicationId,
    moduleId,
    categoryId,
    pageNumber,
    documentId,
    questionId,
    groupId,
  } = params;

  const state: any = location.state ? location.state : {};

  const applicationName = state.applicationName || "Application";
  const categoryName = state.categoryName || "Category";
  const moduleName = state.moduleName || "Module";
  const documentName = state.document?.name || "Document";
  const conditionId = state?.condition?.id || "Condition";

  const ApplicationCrumb = () => {
    return applicationId === currentCrumb ? (
      applicationName
    ) : (
      <HoverTip
        title={applicationName ? `${applicationName} Overview` : "Application Overview"}
        icon={<AppstoreOutlined />}
      />
    );
  };

  const ModuleCrumb = () => {
    return moduleId === currentCrumb ? (
      moduleName
    ) : (
      <HoverTip
        title={moduleName ? `${moduleName} Overview` : "Module Overview"}
        icon={<ClusterOutlined />}
      />
    );
  };

  const breadcrumbNameMap = {
    "/cyber-plans": "Cyber Plans",
    "/account": "Account",
    "/workflow": "Workflow",
    "/application/create": "Create Application",
    "/motar/apps/prompt": "MOTAR prompt",
    "/motar/apps/import": "Import Applications",
    [`/workflow/${applicationId}`]: <ApplicationCrumb />,
    [`/workflow/${applicationId}/${moduleId}`]: <ModuleCrumb />,
    [`/workflow/${applicationId}/${moduleId}/${categoryId}/${pageNumber}`]: `${categoryName} - P${pageNumber}`,
    [`/workflow/${applicationId}/integrations`]: "Integrations",
    [`/workflow/${applicationId}/security-findings`]: "Security Findings",
    [`/workflow/${applicationId}/documents`]: "Documents",
    [`/workflow/${applicationId}/documents/${documentId}`]: documentName,
    "/administration": "Administration",
    "/administration/account": "Account",
    "/administration/users": "Users",
    [`/administration/users/manage/${userId}`]: state.user?.email,
    "/administration/organizations": "Organizations",
    [`/administration/organizations/manage/${organizationId}`]: state.organization?.name,
    "/administration/applications": "Applications",
    [`/administration/applications/manage/${applicationId}`]: state.application?.name,
    "/administration/workflow-editor": "Workflow Editor",
    [`/administration/workflow-editor/question-editor`]: "Question Editor",
    [`/administration/workflow-editor/category-editor`]: "Category Editor",
    "/administration/question-groups": "Question Groups",
    "/administration/question-groups/group-editor": "Group Editor",
    "/administration/question-repository": "Question Repository",
    "/administration/question-repository/question-editor": "Question Editor",
    "/administration/answer-conditions": "Answer Conditions",
    [`/administration/answer-conditions/condition-editor/new`]: "Create",
    [`/administration/answer-conditions/condition-editor/${conditionId}`]: "Manage",
    "/administration/document-editor": "Documents",
    [`/administration/document-editor/manage/${documentId}`]: documentName,
  };

  const skippedBreadCrumbs = [
    "/motar",
    "/motar/apps",
    "/workflow",
    `/workflow/${applicationId}/${moduleId}/${categoryId}`,
    "/application",
    "/administration/users/manage",
    "/administration/organizations/manage",
    "/administration/applications/manage",
    "/administration/document-editor/manage",
    `/administration/workflow-editor/question-editor/${questionId}`,
    `/administration/workflow-editor/category-editor/${categoryId}`,
    `/administration/question-groups/group-editor/${groupId}`,
    `/administration/question-groups/answer-conditions/${groupId}`,
    `/administration/answer-conditions/condition-editor`,
    `/administration/question-repository/question-editor/${questionId}`,
  ];

  const snippets = location.pathname.split("/");
  const pathSnippets = snippets.filter((i) => i);
  const currentCrumb = pathSnippets[pathSnippets.length - 1];
  const extraBreadcrumbs = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (skippedBreadCrumbs.includes(url)) {
      return <Breadcrumb.Item key={url}></Breadcrumb.Item>;
    }

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url} state={location.state}>
          {breadcrumbNameMap[url]}
        </Link>
      </Breadcrumb.Item>
    );
  });
  const isAdminMode = snippets[1] === "administration";
  const linkTo = isAdminMode ? "/administration" : "/home";

  const breadcrumbs = [
    <Breadcrumb.Item key="home">
      <Link to={linkTo} state={location.state}>
        <HoverTip title="Home" icon={<HomeOutlined />} />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbs);

  const breadcrumb = [
    <Breadcrumb.Item key="home">
      <Link to={linkTo} state={location.state}>
        <HoverTip title="Home" icon={<HomeOutlined />} />
      </Link>
    </Breadcrumb.Item>,
  ];

  return viewport.breadcrumbs.collapsed ? breadcrumbs : breadcrumb;
};

export default useBreadcrumbs;

const HoverTip: React.FC<{ title: string; icon: any }> = ({ title, icon }) => (
  <Tooltip title={title} color="black">
    {icon}
  </Tooltip>
);
