import { Skeleton, Space } from "antd";
import ProSkeleton from "@ant-design/pro-skeleton";
import {
  WorkflowInformation,
  CategoryTitleCard,
  ModuleRenderer,
  QuestionnaireSkeleton,
  SkeletonInnerTable,
  WorkflowContent,
} from "./CustomSkeletons";
import PropTypes from "prop-types";
import "styles/skeletonLoaders.css";

type SkeletonType = string;

type SkeletonWrapperProps = {
  loading: Loading;
  type: SkeletonType;
  children: any;
};

type SkeletonLoadingProps = {
  type: SkeletonType;
};

/* all default available skeletons, works best for basic skeletons without additional config,add custom ones as they're built */
const typeMap: { [key: string]: JSX.Element } = {
  barChart: (
    <Space direction="vertical" style={{ width: "100%" }} className="barChart-skeleton">
      <Skeleton.Input active={true} />
      <Skeleton.Input active={true} />
      <Skeleton.Input active={true} />
      <Skeleton.Input active={true} />
    </Space>
  ),
  list: (
    <div
      style={{
        background: "#fafafa",
        padding: 24,
      }}
    >
      <ProSkeleton type="list" pageHeader={false} />
    </div>
  ),
  sorterList: (
    <ProSkeleton list={8} statistic={false} pageHeader={false} active={true} toolbar={false} />
  ),
  avatar: <Skeleton.Avatar active={true} />,
  button: <Skeleton.Button active={true} />,
  default: <Skeleton active={true} />,
  descriptions: <ProSkeleton type="descriptions" pageHeader={false} />,
  image: <Skeleton.Image active={true} />,
  input: <Skeleton.Input active={true} />,
  textRow: <Skeleton active={true} paragraph={{ rows: 1, width: "100%" }} title={false} />,
  result: <ProSkeleton type="result" />,
  statistic: <Skeleton.Input active={true} />,
  tableInner: <SkeletonInnerTable rowCount={undefined} loading={true} />,
  workflowInformation: <WorkflowInformation />,
  workflowModuleContent: <WorkflowContent content="Modules" />,
  workflowCategoryContent: <WorkflowContent content="Categories" />,
  moduleRenderer: <ModuleRenderer />,
  categoryTitle: <CategoryTitleCard />,
  questionnaire: <QuestionnaireSkeleton />,
};

// used to wrap an entire component / page
export const SkeletonWrapper = ({ loading, type, children }: SkeletonWrapperProps) => {
  return loading ? typeMap[type] : <>{children}</>;
};

// used to access multiple skeletons in one page, directly
export const SkeletonLoading = ({ type }: SkeletonLoadingProps) => {
  return typeMap[type];
};

SkeletonWrapper.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.string,
};

SkeletonLoading.propTypes = {
  type: PropTypes.string,
};
