import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, ResetButton } from "..";
import P6D1 from "../Assets/P6D1.png";
import P6D2 from "../Assets/P6D2.png";
import P6D3 from "../Assets/P6D3.png";
import { Button, Row, Col } from "antd";

export const P6 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>

      <div className="itcsc page">
        <div className="page-table">
          <table border={1} className="section">
            <colgroup>
              <col span={1} width="50%" />
              <col span={1} width="50%" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={3} className="title" >
                  10. System Architecture
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}>
                  <EditableRadio {...elementProps} fieldName="10_system_architecture_on_premises_deployed"
                    label={<div><b>On-Premises Deployed</b> - This system is deployed<br /> completely on premises.</div>} />
                </td>
                <td style={{verticalAlign:'top'}}>
                  <div style={{ width: '100%', textAlign: 'center', paddingBottom: 10 }}>
                    <b>Cloud Deployment</b> <i>Refs: (u-v)</i>
                  </div>
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '100%' }}
                    label="Select One"
                    fieldName="10_system_architecture_cloud_deployment"
                    options={[
                      { label: 'Private', value: '10_system_architecture_cloud_deployment_private' },
                      { label: 'Community', value: '10_system_architecture_cloud_deployment_community' },
                      { label: 'Public', value: '10_system_architecture_cloud_deployment_public' },
                      { label: 'Hybrid', value: '10_system_architecture_cloud_deployment_hybrid' },
                      { label: 'N/A', value: '10_system_architecture_cloud_deployment_na' },
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <td className="label" colSpan={2}>
                  <Row align="middle" gutter={20}>
                    <Col span={8}>
                      10A. Cloud Service Model
                    </Col>
                    <Col span={16}>
                      <EditableSelect
                        {...elementProps}
                        style={{ width: '100%' }}
                        fieldName="10a_cloud_service_model"
                        options={[
                          { label: 'Infrastructure as a Service (IaaS)', value: '10a_cloud_service_model_iaas' },
                          { label: 'Platform as a Service (PaaS)', value: '10a_cloud_service_model_paas' },
                          { label: 'Software as a Service (SaaS)', value: '10a_cloud_service_model_saas' },
                          { label: 'N/A', value: '10a_cloud_service_model_na' },
                        ]}
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </table>

          <table border={1} className="section">
            <colgroup>
              <col span={1} width="70%" />
              <col span={1} width="30%" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={3} className="title" >
                  11. Cloud Impact Level (IL) Determination Ref: (u)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="label">
                  11A. Does the System have classified Information Up to SECRET?
                </td>
                <td>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <EditableRadio {...elementProps} fieldName="11a_cloud_impact_level_yes" label="Yes (IL6)" />
                    <EditableRadio {...elementProps} fieldName="11a_cloud_impact_level_no" label="No" />
                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  11B. Does the System contain CUI and designated NSS?
                </td>
                <td>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <EditableRadio {...elementProps} fieldName="11b_cloud_impact_level_yes" label="Yes (IL5)" />
                    <EditableRadio {...elementProps} fieldName="11b_cloud_impact_level_no" label="No" />
                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  11C. Does the System contain CUI?
                </td>
                <td>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <EditableRadio {...elementProps} fieldName="11c_cloud_impact_level_yes" label="Yes (IL4)" />
                    <EditableRadio {...elementProps} fieldName="11c_cloud_impact_level_no" label="No" />
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <b>11D. Does the System contain Non-Controlled Unclassified Information?</b><br />
                  <i>Note 10:</i> If No is checked, reevaluate Cloud requirement before proceeding
                </td>
                <td>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <EditableRadio {...elementProps} fieldName="11d_cloud_impact_level_yes" label="Yes (IL2)" />
                    <EditableRadio {...elementProps} fieldName="11d_cloud_impact_level_no" label="No" />
                  </div>
                </td>
              </tr>

            </tbody>
          </table>

          <table border={1} className="section">
            <colgroup>
              <col span={1} width="100%" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={3} className="title" >
                  12. Security Description / Environment
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <i>Note 11</i>: Describe your Defense-in-Depth: Provide a narrative DOD Architecture Framework (DODAF) view or other
                  artifact that captures processes in place to protect the information and system. See instructions.
                </td>
              </tr>
              <tr>
                <td>
                  <EditableField {...elementProps} fieldName="12_security_description" style={{ width: '100%', minHeight: 500 }} />
                </td>
              </tr>

            </tbody>

          </table>
        </div>
        <ResetButton>Reset Section 10-12</ResetButton>
      </div>
    </>
  );
};
