// import { useAssignmentValue } from "../useFormAssignment";
import { QuestionType } from "types";
import { json } from "stream/consumers";
import { useDocument } from "../DocumentEditor";
import { getAnswersFromMapping, getFieldFromMapping } from "../fieldmap";
import DocumentField, { IDocumentFieldProps } from "./DocumentField";

/**
 * * if editable is true, then field stuff comes from appFields, else comes from doc.variableFields
 */

interface Props extends IDocumentFieldProps {
  formId: string;
  borderless?: boolean;
  style?: React.CSSProperties;
  isDate?: boolean;
}

const EditableField: React.FC<Props> = (props) => {
  const { editable, borderless = false, style, isDate = false } = props;
  const fieldType = isDate ? "FULFILL_DATE" : "FULFILL_TEXT";
  return (
    <DocumentField {...props} fieldType={fieldType}>
      {({ answer, handleClick, styleId }) => {
        const fieldValue = answer?.applicationAnswer?.answer;
        const styles: any = {};
        if (borderless) styles.border = "none";
        if (fieldValue) {
          styles.fontSize = 11;
          styles.paddingLeft = 4;
        }

        return (
          <div
            style={{ ...styles, ...style }}
            className="documentInput"
            id={editable ? styleId : ""}
            onClick={handleClick}
          >
            {(!editable && fieldValue) || ""}
          </div>
        );
      }}
    </DocumentField>
  );
};

export default EditableField;
