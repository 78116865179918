import { FilterOutlined, FilterTwoTone } from "@ant-design/icons";

type Props = {
  filterActive: boolean;
};

const FilterIcon: React.FC<Props> = ({ filterActive }) => {
  return filterActive ? <FilterTwoTone /> : <FilterOutlined />;
};

export default FilterIcon;
