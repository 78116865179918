import { useState } from "react";
import { sortAndMoveQuickStart } from "components/Applicant/Helpers/sortAndMoveQuickStart";
import CategoryCard from "./CategoryCard";
import { Typography, List, Card, Alert } from "antd";
import { ApplicationQuestionCategoryNode } from "types";

interface Props {
  loading: boolean;
  modName: string;
  categories: Array<ApplicationQuestionCategoryNode>;
  isLocked: boolean;
}

const ModuleCategories: React.FC<Props> = ({ loading, modName, categories, isLocked }) => {
  const [openCardId, setOpenCardId] = useState<string | null>(null);

  const toggleOpenCardId = (id: string) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  return (
    <>
      <Card className="shadowed-static" bodyStyle={{ padding: 15 }}>
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          Categories
        </Typography.Title>
        {isLocked && (
          <Alert
            type="warning"
            message="Content Locked"
            description="Contact your advisor to unlock this section."
            style={{ marginTop: 20 }}
          />
        )}
      </Card>
      <br />
      <List
        grid={{
          gutter: 16,
          column: 3,
          xl: 2,
          lg: 2,
          md: 2,
          sm: 1,
          xs: 1,
        }}
        loading={loading}
        dataSource={sortAndMoveQuickStart(categories, "Quick Start")}
        renderItem={(category: ApplicationQuestionCategoryNode) => (
          <List.Item>
            <CategoryCard
              modName={modName}
              category={category}
              openCardId={openCardId}
              toggleOpenCardId={toggleOpenCardId}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default ModuleCategories;
