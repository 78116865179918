import { useMutation } from "@apollo/client";
import Api from "api";
import { useAuthContext } from "components/Providers/WithAuth";
import useFormChanges from "hooks/useFormChanges";
import useEnumPresentation from "hooks/useEnumPresentation";
import usePasswordChange from "components/Auth/useChangePassword";
import {
  Typography,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  message,
  Divider,
  Space,
  Popconfirm,
} from "antd";
import { LockOutlined, SaveOutlined } from "@ant-design/icons";

const InformationTab: React.FC<any> = () => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const { formChanges, onChange } = useFormChanges({});
  const { sendPasswordResetEmail, sendPasswordResetEmailLoading } = usePasswordChange();

  const [update, { loading }] = useMutation(Api.User.UpdateInfo(), {
    onCompleted: (res) => {
      console.log(res);
      message.success("Successfully saved");
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
    },
    refetchQueries: [Api.User.GetAuth()],
  });

  const onFinish = () => {
    update({
      variables: {
        id: user.id,
        ...formChanges,
      },
    });
  };

  const hasFormChanges: boolean = Object.keys(formChanges).length > 0 ? true : false;

  const handleChangePassword = () => {
    console.log("Change Password");
    sendPasswordResetEmail(user.email);
  };

  return (
    <>
      <Row>
        <Typography.Title level={3}>My Information</Typography.Title>
      </Row>
      <Divider />
      <Form
        form={form}
        layout="vertical"
        initialValues={user}
        onChange={(e: any) => onChange(e.target.id, e.target.value)}
        onFinish={onFinish}
      >
        <UserInformationForm onInfoChange={onChange} />
        <Row justify="end">
          <Button
            data-testid="update-user-info"
            type="primary"
            htmlType="submit"
            disabled={!hasFormChanges}
            loading={loading}
            icon={<SaveOutlined />}
          >
            Save Changes
          </Button>
        </Row>
        <Divider />
        <Row>
          <Form.Item>
            <Popconfirm
              title={
                <Row>
                  <div>
                    Send Password Change email to <b>{user.email}</b>?
                  </div>
                </Row>
              }
              onConfirm={handleChangePassword}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={sendPasswordResetEmailLoading}>
                <Space>
                  <LockOutlined /> Change Password
                </Space>
              </Button>
            </Popconfirm>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};

export default InformationTab;

const UserInformationForm = ({ onInfoChange }: any) => {
  const { user } = useAuthContext();
  const { PresentRoleName } = useEnumPresentation();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xxl={6} xl={10} lg={10} md={12} sm={24} xs={24}>
          <Form.Item label="First Name" name="firstName" style={{ width: "100%" }}>
            <Input />
          </Form.Item>
        </Col>
        <Col xxl={6} xl={10} lg={10} md={12} sm={24} xs={24}>
          <Form.Item label="Last Name" name="lastName" style={{ width: "100%" }}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xxl={6} xl={10} lg={10} md={12} sm={24} xs={24}>
          <Form.Item label="Email" name="email" style={{ width: "100%" }}>
            <Input />
          </Form.Item>
        </Col>
        <Col xxl={4} xl={4} lg={10} md={12} sm={12} xs={24}>
          <Form.Item label="Salutation" name="salutation" style={{ textAlign: "left" }}>
            <Select
              allowClear
              onChange={(value) => onInfoChange("salutation", value)}
              options={[
                { label: "Mr.", value: "Mr." },
                { label: "Ms.", value: "Ms." },
                { label: "Mrs.", value: "Mrs." },
                { label: "Miss", value: "Miss" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item name="role" label="Role">
          <Typography.Text>{PresentRoleName(user.role!)}</Typography.Text>
        </Form.Item>
      </Row>
    </>
  );
};
