import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "components/Common/FallbackUI";
import App from "App";

export const disableLogsInProduction = (env) => {
  if (env === "production") {
    console.log = () => {};
    console.group = () => {};
    console.groupCollapsed = () => {};
    console.table = () => {};
  }
};

export const FallbackHandler = (error, errorInfo) => {
  console.log("Error Handler: ", error, errorInfo);
};

const Index = () => {
  // disableLogsInProduction(process.env.NODE_ENV);

  return (
    <ErrorBoundary FallbackComponent={FallbackUI} onError={FallbackHandler}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </ErrorBoundary>
  );
};
const root = document.getElementById("root");
if (root) ReactDOM.render(<Index />, root);

export default Index;
