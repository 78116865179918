import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Button } from "antd";

export const P12 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="instructions">
        <div className="title">INSTRUCTIONS</div>
        <p>
          <b>
            Aggregation of all data, plus the potential impact and likelihood of a security issue arising from mishandling or misuse of that data, should factor in the assessment of all decisions within the ITCSC.
          </b>
        </p>

        <p>
          <b>
            All acronyms must be spelled out once in the description. Failure to spell out acronyms once may result in the form being returned.
          </b>
        </p>

        <p>
          <b>General: CUI Designation Indicator Box</b>
        </p>

        <p>
          If the ITCSC includes any CUI information or material, complete the CUI Designation Indicator Box on the bottom of Page 1:
        </p>

        <p>First Line: Identify the Office making the determination.</p>
        <p>Second line: Identify all types of CUI contained in the document. </p>
        <p>Third line: Identify the distribution statement or the dissemination controls applicable to the document. </p>
        <p>Fourth line: Identify phone number or office mailbox for the originating DoD Component or authorized CUI holder.</p>

        <p><b>General: CUI Warning Box for Classified Material</b> </p>
        <p>If the ITCSC includes classified information, complete the CUI Warning Box to alert to the presence of CUI in a classified DoD document. </p>
        <p><b>General: Overall Document Marking Classification</b></p>

        <p>Select marking classification. If document is classified, complete the Classification Authority Block on Page 1. </p>
        <p><b>General: Section Marking </b></p>
        <p>Select security classification for each section</p>

        <p><b>1.System Identification Information</b></p>
        <p>System Name: The name of the System must match the investment name being entered during ITIPS registration. </p>
        <p>System Acronym: The same acronym entered in ITIPS as the investment acronym. </p>
        <p>Version: Version number of the System. </p>
        <p>First Categorization Submission: Self-explanatory.</p>

        <p>System vs. Subsystem: Select the appropriate button. Automatically selects appropriate types in section 6 ITIPS ID: Enter ITIPS ID number. An ITIPS ID is required for all DAF systems and will be provided after an approved ITIPS registration. If subsystem, field is automatically grayed out.</p>
        <p>CCA Compliance Approval Status. Select one: Compliant, non-compliant, Not assessed, Exempt</p>

        <ul className="slash">
          <li>Compliant: Systems that have a CCA approval.</li>
          <li>
            Non-Compliant: Systems that have not completed the CCA compliance process.<br />
            NOTE: Systems with a non-compliant status are excluded from issuing an authorization decision document (Contact SAF/CNZA)
          </li>
          <li>
            Not Assessed: Systems that are actively pursuing CCA compliance.
          </li>
        </ul>

      </div>
    </>
  );
};
