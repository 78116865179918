import { useEffect, useState } from "react";

interface WindowSize {
  width: number;
  height: number;
}

/**
 * Use this hook to get the current size of the viewport
 *
 * @returns width and height of window
 */

const useWindowSize = () => {
  const [size, setSize] = useState<WindowSize>({ width: 0, height: 0 });

  useEffect(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener("resize", () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    });

    return () => {
      window.removeEventListener("resize", () => {
        setSize({ width: window.innerWidth, height: window.innerHeight });
      });
    };
  }, []);

  // console.log({ size });

  return { width: size.width, height: size.height };
};

export default useWindowSize;
