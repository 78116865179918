import React from "react";
import { Radio, Space } from "antd";
import { MultipleChoiceInputProps } from "../Questions/MultipleChoiceQuestion";
import AdditionalAnswerRenderer from "../AdditionalAnswerRenderer";

const PickOneInput: React.FC<MultipleChoiceInputProps> = ({
  defaultAnswer,
  question,
  onChange,
  onChangeAdditionalField,
  disabled,
}) => {
  const hasSaved = question.savedAnswers.length >= 1;
  const hasToggled = question.answerChoices.find((ac) => ac?.additionalFieldToggle);
  const hasActive = hasSaved || hasToggled;

  let isChecked = hasActive ? hasToggled?.id || defaultAnswer : null;

  return (
    <Radio.Group
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      }}
      defaultValue={defaultAnswer}
      value={isChecked}
      disabled={disabled}
    >
      <Space direction="vertical" size={2}>
        {question.answerChoices.map((answerChoice: any) => {
          const hasAdditional = answerChoice.additionalFieldType !== null;
          const showAdditional = hasAdditional && answerChoice.additionalFieldToggle;

          const savedAnswerChoice = question.savedAnswers.find(
            (sa) => sa?.id === answerChoice.id,
          )!;

          const defaultAnswer = savedAnswerChoice?.answer || "";

          return (
            <React.Fragment key={answerChoice.id}>
              <Radio
                value={answerChoice.id}
                onChange={(e) => {
                  const id = e.target.value;
                  const answer = { id };

                  if (hasAdditional) {
                    console.log("Toggling additional");
                  }

                  onChange(answer);
                }}
              >
                {answerChoice.answerText}
              </Radio>
              {showAdditional && (
                <AdditionalAnswerRenderer
                  type={answerChoice.additionalFieldType}
                  answerId={answerChoice.id}
                  defaultAnswer={defaultAnswer}
                  onChange={onChangeAdditionalField}
                  disabled={disabled}
                />
              )}
            </React.Fragment>
          );
        })}
      </Space>
    </Radio.Group>
  );
};

export default PickOneInput;
