import { useState, useEffect } from "react";
import { Button, Drawer, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import CreateAnswerConditions from "./CreateAnswerConditions";
import ManageAnswerConditions from "./Manage/ManageAnswerConditions";
import { DeleteOutlined } from "@ant-design/icons";
import InputConfirmModal from "components/Common/InputConfirmModal";
import Api from "api";
import { useMutation } from "@apollo/client";

const AnswerConditionDrawer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log({ location });
  const condition = location.state?.condition;
  const [width, setWidth] = useState(600);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteCondition, { loading: deleteLoading }] = useMutation(
    Api.ConditionalLogic.DeleteCondition(),
    {
      variables: {
        answerId: condition?.answer?.id,
      },
      onCompleted: (res) => {
        console.log({ DeleteCondition: res });
        message.success("Condition deleted successfully", 5);
        onClose();
      },
      onError: (error) => {
        console.log(error);
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: Api.ConditionalLogic.GetAllConditionalRelationships() }],
    },
  );

  const onClose = () => {
    navigate(-1);
  };

  const hasCondition: boolean = condition && condition.id ? true : false;

  console.log({ condition, hasCondition });

  useEffect(() => {
    if (hasCondition) return;
    setWidth(1200);
  }, []);

  let formNode = hasCondition ? (
    <ManageAnswerConditions setWidth={setWidth} />
  ) : (
    <CreateAnswerConditions />
  );

  return (
    <>
      <Drawer
        open={true}
        title={hasCondition ? "Manage Answer Condition" : "Create Answer Condition"}
        width={width}
        onClose={onClose}
        extra={
          hasCondition && (
            <Button
              onClick={() => setDeleteModalOpen(true)}
              icon={<DeleteOutlined />}
              type="primary"
              danger
            >
              Delete
            </Button>
          )
        }
        bodyStyle={{ background: "#f0f0f0", padding: 16 }}
      >
        {formNode}
      </Drawer>
      {deleteModalOpen && (
        <InputConfirmModal
          visible={deleteModalOpen}
          loading={deleteLoading}
          prompt="Are you sure you want to delete this answer condition?"
          mutation={deleteCondition}
          confirmation="answer/condition"
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
    </>
  );
};

export default AnswerConditionDrawer;
