import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Api from "api";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import AlertApprovalStats from "./Components/AlertApprovalStats";
import RiskDistributionChart from "./Charts/RiskDistribution";
import TopFiveChart from "./Charts/TopFive";
import DataTable from "./Tables/DataTable";
import { Col, message, Row } from "antd";
import { SecurityScanTwoTone } from "@ant-design/icons";

const SecurityFindings = () => {
  const { applicationId } = useParams();
  const { data, loading } = useQuery(Api.SecurityFindings.GetAppFindings(), {
    variables: {
      appId: applicationId,
    },
    onCompleted: (res) => {
      console.log({ SecurityFindings: res.securityFindings });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  return (
    <div style={{ textAlign: "left" }}>
      <CustomPageHeader
        className="shadowed-static"
        title="Security Findings"
        icon={<SecurityScanTwoTone />}
      >
        <AlertApprovalStats data={data?.securityFindings} loading={loading} />
      </CustomPageHeader>

      <Row justify="space-between" gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col lg={12} md={24}>
          <TopFiveChart />
        </Col>
        <Col lg={12} md={24}>
          <RiskDistributionChart />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <DataTable loading={loading} data={data?.securityFindings} />
        </Col>
      </Row>
    </div>
  );
};

export default SecurityFindings;
