import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, InformationTypeSelect } from "..";
import { Button } from "antd";

const fieldStyle = {
  width: '100%',
  height: '100%',
  border: 'none',
  minHeight: '25px'
}

export const P13 = (props) => {
  const { document, state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const RMFKRMarking = {
    label: 'Select Marking',
    value: 'rmf_key_roles_marking'
  }

  return (
    <>
      <div style={{ display: "grid", placeItems: "baseline", height: '100%' }}>
        <div style={{ width: '100%' }}>
          <div className="rmf-table">
            <table style={{ height: '1px' }}>
              <colgroup>
                <col span={1} width={'25%'} />
                <col span={1} width={'25%'} />
                <col span={1} width={'25%'} />
                <col span={1} width={'25%'} />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={5} style={{ backgroundColor: "lightgrey", padding: '2px 4px', color: 'black' }}>
                    <div style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 2fr 1fr',
                      gridTemplateRows: '1fr',
                      textAlign: 'center'
                    }}>
                      <div>
                        <ClassificationSelect elementProps={elementProps} config={RMFKRMarking} />
                      </div>
                      <div>15.RMF Key Roles <i>Ref: (c)</i></div>
                    </div>
                  </th>
                </tr>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ color: 'black' }}>Title</th>
                  <th style={{ color: 'black' }}>Name</th>
                  <th style={{ color: 'black' }}>Phone (Comm/DSN)</th>
                  <th style={{ color: 'black' }}>Organization</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style={{ paddingLeft: '15px' }}><b>AO*</b></td>
                  <td><EditableField {...elementProps} fieldName="15_title_ao" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_phone_ao" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_organization_ao" style={fieldStyle} /></td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: '15px' }}><b>
                    AO Designated<br /> Representative (AODR)
                  </b></td>
                  <td><EditableField {...elementProps} fieldName="15_title_aodr" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_phone_aodr" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_organization_aodr" style={fieldStyle} /></td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: '15px' }}><b>
                    Information System Owner<br /> (ISO) *
                  </b></td>
                  <td><EditableField {...elementProps} fieldName="15_title_iso" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_phone_iso" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_organization_iso" style={fieldStyle} /></td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: '15px' }}><b>
                    Program Manager (PM)
                  </b></td>
                  <td><EditableField {...elementProps} fieldName="15_title_pm" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_phone_pm" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_organization_pm" style={fieldStyle} /></td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: '15px' }}><b>
                    Security Control<br /> Assessor (SCA) *
                  </b></td>
                  <td><EditableField {...elementProps} fieldName="15_title_sca" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_phone_sca" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_organization_sca" style={fieldStyle} /></td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: '15px' }}>
                    <b>
                      Information System<br />
                      Security Manager<br />
                      (ISSM) *
                    </b>

                  </td>
                  <td><EditableField {...elementProps} fieldName="15_title_issm" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_phone_issm" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_organization_issm" style={fieldStyle} /></td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: '15px' }}><b>
                    Information System<br /> Security Engineer (ISSE)
                  </b></td>
                  <td><EditableField {...elementProps} fieldName="15_title_isse" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_phone_isse" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_organization_isse" style={fieldStyle} /></td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: '15px' }}><b>
                    User Representative
                  </b></td>
                  <td><EditableField {...elementProps} fieldName="15_title_ur" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_phone_ur" style={fieldStyle} /></td>
                  <td><EditableField {...elementProps} fieldName="15_organization_ur" style={fieldStyle} /></td>
                </tr>

              </tbody>
            </table>
          </div>

          <div className="sig-table">
            <table style={{ height: '1px' }} border={1}>
              <colgroup>
                <col span={1} width={'35%'} />
                <col span={1} />
              </colgroup>

              <thead style={{ backgroundColor: "lightgrey" }}>
                <th></th>
                <th style={{ color: 'black', paddingLeft: '10%' }}>Approval</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div style={{ padding: '4px', fontSize: 14 }}>
                      <b>ISO/PM:</b> (Digital Signature)
                    </div>
                  </td>
                  <td> <div style={{ ...fieldStyle, background: 'white', minHeight: '40px' }}> </div> </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ backgroundColor: "lightgrey", padding: '1px', height: '20px', borderBottom: 'none' }}><div> </div></td>
                </tr>
                <tr>
                  <td>
                    <div style={{ padding: '4px', fontSize: 14 }}>
                      <b>AO/AODR:</b> (Digital Signature)
                    </div>
                  </td>
                  <td> <div style={{ ...fieldStyle, background: 'white', minHeight: '40px' }}> </div> </td>
                </tr>
              </tbody>
            </table>
          </div>


        </div>
        <div
          style={{
            width: "80%",
            padding: 5,
            alignSelf: 'end',
            justifySelf: 'end',
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ marginBottom: '30px' }}>* denotes minimum staffing</h3>
            <Button size="small" style={{ outline: '4px solid black', borderRadius: 0, width: 'fit-content' }}>
              Reset Section 15
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
