import React from "react";
import {
  ApiOutlined,
  PlayCircleOutlined,
  ReadOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Card, Col, message, Row, Typography } from "antd";
import {
  DynamicParagraphSkeleton,
  ParagraphSkeleton,
} from "../../../../Common/Skeletons/CustomSkeletons";

interface Props {
  loading: boolean;
  integration: Integration;
  openSetupModal: (integration: Integration) => void;
  openRunModal: (integration: Integration) => void;
  openDocsDrawer: (integration: Integration) => void;
}

const IntegrationCard: React.FC<Props> = ({
  loading,
  integration,
  openSetupModal,
  openRunModal,
  openDocsDrawer,
}) => {
  const isConfigured = integration.configured;
  const cardActions = [
    <Row justify="space-evenly">
      <Col xxl={14} xl={12} lg={12} md={12}>
        {isConfigured ? (
          <Button
            type="primary"
            icon={<PlayCircleOutlined />}
            style={{ width: "100%" }}
            onClick={() => {
              if (!integration.run)
                return message.error("Integration not supported. Try again later.");
              openRunModal(integration);
            }}
          >
            Run
          </Button>
        ) : (
          <Button
            type="primary"
            icon={<ApiOutlined />}
            style={{ width: "100%" }}
            onClick={() => {
              if (!integration.setup)
                return message.error("Integration not supported. Try again later.");
              openSetupModal(integration);
            }}
          >
            Setup
          </Button>
        )}
      </Col>
      <Col xxl={6} xl={8} lg={8} md={9}>
        <Button
          icon={<ReadOutlined />}
          style={{ width: "100%" }}
          onClick={() => {
            if (!integration.docs)
              return message.error("Integration not supported. Try again later.");
            openDocsDrawer(integration);
          }}
        >
          Docs
        </Button>
      </Col>
    </Row>,
  ];

  return (
    <Card
      className="shadowed-w-hover"
      actions={cardActions}
      bodyStyle={{ minHeight: 220, maxHeight: 220 }}
      style={{ textAlign: "left" }}
    >
      <DynamicParagraphSkeleton loading={loading} avatar="square" title={true} rows={1}>
        <Row style={{ width: "100%" }} justify="space-between">
          <Card.Meta
            avatar={
              integration.image && (
                <Avatar src={integration.image} size="large" shape="square" />
              )
            }
            description={integration.description}
            title={integration.name}
            style={{ marginBottom: 18, position: "relative" }}
          />
          <Button
            icon={<SettingOutlined />}
            style={{ position: "absolute", right: 8, top: 8 }}
            onClick={() => openSetupModal(integration)}
          />
        </Row>
      </DynamicParagraphSkeleton>

      <ParagraphSkeleton loading={loading} title={false} rows={2} styles={{ marginTop: 18 }}>
        <Typography.Paragraph ellipsis={{ rows: 5, expandable: true, symbol: "more" }}>
          {integration.content}
        </Typography.Paragraph>
      </ParagraphSkeleton>
    </Card>
  );
};

export default IntegrationCard;
