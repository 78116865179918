import { useState } from "react";
import { useQuestionnairePageContext } from "../../Questionnaire";
import GridQuestionRenderer from "./GridQuestionRenderer";
import SmartTable from "components/Common/Tables";
import { Card, Row, Table, Tooltip } from "antd";
import { ApplicationQuestionNode } from "types";

export interface ColumnContent {
  columnLabel: string;
  rows: Array<RowContent>;
}

export interface RowContent {
  id: number;
  rowLabel: string | null;
  question: ApplicationQuestionNode | null;
}

export type GridContent = Array<ColumnContent>;

const GridPage: React.FC = () => {
  const { page, loading } = useQuestionnairePageContext();

  const [focusedColumn, setFocusedColumn] = useState("");

  const toggleFocusedColumn = (label: string) =>
    setFocusedColumn((fc) => (fc === label ? "" : label));

  const content: GridContent = page.questions;

  const columns = content.map((column, colIndex) => {
    const label = column.columnLabel;
    const colDataIndex = `col_${colIndex}`;
    const columnWidth = label === focusedColumn ? "40%" : "100px";

    return {
      className: "grid-cell",
      title: (
        <Row
          justify="center"
          style={{ userSelect: "none", cursor: "pointer", whiteSpace: "nowrap" }}
          onClick={() => toggleFocusedColumn(label)}
        >
          <Tooltip title="Expand">{label}</Tooltip>
        </Row>
      ),
      dataIndex: colDataIndex,
      align: "left",
      width: columnWidth,
      render: (_, row) => {
        const record = row[colDataIndex];
        if (!record) return <>Cell Error</>;

        const hasRowLabel = record.rowLabel !== null;
        const question = record.question;

        return hasRowLabel ? record.rowLabel : <GridQuestionRenderer question={question} />;
      },
    };
  });

  const data = content.reduce((acc: Array<any>, column, colIndex) => {
    const colDataIndex = `col_${colIndex}`;
    column.rows.forEach((v, i) => {
      const existingRow = acc[i] || {};
      acc[i] = {
        ...existingRow,
        id: `page_${page?.pageNumber}_${colDataIndex}_row_${i}`,
        [colDataIndex]: v,
      }
    })
    return acc;
  }, []);

  console.log("%cGridPage: Grid", "color: steelblue", {
    Columns: columns,
    Data: data,
  });

  return (
    <Row style={{ marginBottom: 20 }}>
      <Card
        className="shadowed-static"
        bodyStyle={{ padding: 0, margin: 0 }}
        style={{ width: "100%" }}
      >
        <SmartTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{ defaultPageSize: 20, hideOnSinglePage: true }}
        />
      </Card>
    </Row>
  );
};

export default GridPage;
