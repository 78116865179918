import React, { useEffect, useState } from "react";
import DeveloperTools from "./DocumentDevTools";
import { useQuery } from "@apollo/client";
import Api from "api";
import { useSaveAssignment } from "../useFormAssignment";
import useEnumPresentation from "hooks/useEnumPresentation";
import {
  Typography,
  Row,
  Select,
  Button,
  Divider,
  message,
  Dropdown,
  Menu,
  Space,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilterOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  SaveOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { QuestionType } from "types";

const { Title } = Typography;

const sorters = {
  default: (a) => a,
  abc: (a, b) => a.question.localeCompare(b.question),
};

const EditorDrawer = ({ state, dispatch, drawerOpen, setDrawerOpen }) => {
  const [save, loading] = useSaveAssignment(() => handleSaveCallback());
  const { PresentQuestionType } = useEnumPresentation();

  const [sortType, setSortType] = useState("default");

  // ? do we need these local state variables?
  const [moduleId, setModuleId] = useState(undefined);
  const [categoryId, setCategoryId] = useState(undefined);
  const [questionId, setQuestionId] = useState(undefined);
  const [answerId, setAnswerId] = useState(undefined);
  const [currentField, setCurrentField] = useState(undefined);

  const { data } = useQuery(Api.QuestionModule.GetDocumentEditorModules());

  const shouldShowAnswersSelect = !state.questionType?.includes("FULFILL");

  // todo: check question type, if not fulfill, keep question selected

  useEffect(() => {
    // sync local state to reducer state
    if (!state) return;
    if (!state.fieldId) return;

    setCurrentField(state.fieldId);
  }, [state]);

  useEffect(() => {
    // reset local state when reducer holds different field
    if (!state) return;
    if (state?.fieldId !== currentField) {
      setQuestionId(undefined);
      setAnswerId(undefined);
      setCurrentField(undefined);
    }
  }, [state?.fieldId, currentField]);

  useEffect(() => {
    // ? this is confusing
    if (shouldShowAnswersSelect) {
      setAnswerId(undefined);
    } else {
      setAnswerId(undefined);
    }
  }, [state.fieldId]);

  if (!data) return <></>;

  const modules = data.allQuestionModules;
  const selectedModule = moduleId && modules.find((m) => m.id === moduleId);

  const categories = selectedModule && selectedModule.categories;
  const selectedCategory = categoryId && categories.find((c) => c.id === categoryId);

  const questions =
    selectedCategory &&
    selectedCategory.allQuestions
      .filter((q) => q.questionType === state.questionType)
      .sort(sorters[sortType]);

  const selectedQuestion = questionId && questions.find((q) => q.id === questionId);

  const answers = selectedQuestion && selectedQuestion.answers;

  const handleSaveMapping = () => {
    console.log({
      formId: state.formId,
      fieldId: state.fieldId,
      questionId: questionId,
      answerId: answerId,
    });
    save(state.fieldId, questionId, answerId);
  };

  const handleSaveCallback = () => {
    message.success("Form assignment saved");
    handlePartialReset();
  };

  const handlePartialReset = () => {
    if (state.showAnswers) setAnswerId(undefined);
    dispatch({ type: "RESET" });
  };

  const handleFullReset = () => {
    setModuleId(undefined);
    setCategoryId(undefined);
    setAnswerId(undefined);
    setQuestionId(undefined);
    dispatch({ type: "RESET" });
  };

  if (!drawerOpen) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row justify="end">
          <Button type="primary" onClick={() => setDrawerOpen(!drawerOpen)}>
            <MenuOutlined />
          </Button>
        </Row>
      </div>
    );
  }

  modules && console.log({ Modules: modules });
  categories && console.log({ Categories: categories });
  questions && console.log({ Questions: questions });

  const sortMenu = {
    onClick: ({ key }) => setSortType(key),
    items: [
      {
        key: "default",
        label: "Default",
      },
      {
        key: "abc",
        label: "Alphabetical",
      },
    ],
  };

  const assignment = state.document.variableFields.find((vf) => vf.id === state.fieldId);
  const isAssigned = assignment && assignment.answer !== null;

  const DevTools = process.env.REACT_APP_DEV_TOOLS !== undefined;

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginLeft: 10,
        padding: 20,
        background: "white",
      }}
    >
      <Row justify="space-between">
        <Title level={4}>Form Assignment</Title>
        <Button type="primary" onClick={() => setDrawerOpen(!drawerOpen)}>
          <MenuUnfoldOutlined />
        </Button>
      </Row>
      <Divider />
      <Row gutter={[0, 16]}>
        <b>Module</b>
        <Select
          style={{ width: "100%", textAlign: "left" }}
          placeholder="Select Module"
          value={moduleId}
          onChange={(key) => {
            setModuleId(key);
            setCategoryId(null);
            setQuestionId(null);
            setAnswerId(null);
          }}
        >
          {modules.map((m, i) => (
            <Select.Option key={i} value={m.id}>
              {m.name}
            </Select.Option>
          ))}
        </Select>
        {moduleId && (
          <>
            <b>Category</b>
            <Select
              style={{ width: "100%", textAlign: "left" }}
              placeholder="Select Category"
              value={categoryId}
              onChange={(key) => {
                setCategoryId(key);
                setQuestionId(null);
                setAnswerId(null);
              }}
            >
              {categories.map((c, i) => (
                <Select.Option key={i} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        {categoryId && (
          <>
            <b style={{ textAlign: "left", width: "100%" }}>Question</b>
            {state.fieldId || questionId ? (
              <>
                <Select
                  style={{ width: "90%", textAlign: "left" }}
                  placeholder="Select Question"
                  value={questionId}
                  onChange={(key) => {
                    setQuestionId(key);
                    setAnswerId(null);
                  }}
                  listHeight={500}
                  dropdownMatchSelectWidth={false}
                >
                  {questions?.map((q, i) => (
                    <Select.Option key={i} value={q.id}>
                      {q.question}
                    </Select.Option>
                  ))}
                </Select>
                <Dropdown menu={sortMenu}>
                  <Button style={{ width: "10%" }}>
                    <FilterOutlined />
                  </Button>
                </Dropdown>
              </>
            ) : (
              <b style={{ width: "100%", color: "red" }}>Select Form Element</b>
            )}
          </>
        )}
        {questionId && shouldShowAnswersSelect && (
          <>
            <b>Answer</b>
            {state.fieldId || questionId ? (
              <Select
                style={{ width: "100%", textAlign: "left" }}
                placeholder="Select Answer"
                value={answerId}
                onChange={(key) => {
                  setAnswerId(key);
                }}
                dropdownMatchSelectWidth={false}
              >
                {answers?.map((a, i) => (
                  <Select.Option key={i} value={a.id}>
                    {a.answer}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <b style={{ width: "100%", color: "red" }}>Select Form Element</b>
            )}
          </>
        )}
      </Row>
      <div>
        {state.fieldId && (
          <>
            <Divider />
            <Row>
              <b>Selected Question Type</b>
            </Row>
            <Row>{PresentQuestionType(state.questionType)}</Row>

            <Row style={{ paddingTop: 10 }}>
              <b>Assignment</b>
            </Row>
            <Row>
              {isAssigned ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Space>
                    <span style={{ color: "green" }}>
                      <CheckCircleOutlined />
                    </span>
                    Assigned
                  </Space>
                  <Row style={{ paddingTop: 10 }}>
                    <b>Question</b>
                  </Row>
                  <Row style={{ textAlign: "left" }}>
                    {assignment?.answer?.question?.question}
                  </Row>
                </div>
              ) : (
                <>
                  <Space>
                    <span style={{ color: "crimson" }}>
                      <CloseCircleOutlined />
                    </span>
                    Not Assigned
                  </Space>
                </>
              )}
            </Row>
          </>
        )}
      </div>
      <Divider />
      <Row justify="space-between">
        <Button onClick={handleFullReset} icon={<UndoOutlined />}>
          Reset
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={handleSaveMapping}
          icon={<SaveOutlined />}
          disabled={
            state.showAnswers ? !questionId || !answerId : !questionId || !state.fieldId
          }
        >
          Save Assignment
        </Button>
      </Row>
      {DevTools && (
        <>
          <Divider />
          <DeveloperTools assignment={assignment} state={state} />
        </>
      )}
    </div>
  );
};

export default EditorDrawer;
