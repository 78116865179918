import { useMemo } from "react";
import { Col, Row, Statistic } from "antd";
import { QuestionModuleNode } from "types";

type Props = {
  module: QuestionModuleNode;
};

const ModuleStatsHeader: React.FC<Props> = ({ module }) => {
  // todo: refactor to NOT useMemo
  const questionCount = useMemo(() => {
    if (!module) return;
    if (module.categories?.length < 1) return;

    let count = 0;

    module.categories.forEach((category) => {
      if (category!.allQuestions.length < 1) return;
      count += category!.allQuestions.length;
    });

    return count;
  }, [module]);

  if (!module) return <></>;

  return (
    <Row style={{ textAlign: "left", marginBottom: 24 }}>
      <Col span={4}>
        <Statistic title="Category Count" value={module.categories?.length} />
      </Col>
      <Col span={4}>
        <Statistic title="Question Count" value={questionCount} />
      </Col>
      <Col span={4}>
        <Statistic title="Checkpoint Status" value={module.hasCheckpoint ? "True" : "False"} />
      </Col>
      <Col span={12}>
        <Statistic title="Help Text" value={module.helpText ? module.helpText : "Not Set"} />
      </Col>
    </Row>
  );
};

export default ModuleStatsHeader;
