import { Space, Tooltip, Typography } from "antd";

type Props = {
  title: string;
  icon: any;
  info: string;
};

const ChartHeader: React.FC<Props> = ({ title, icon, info }) => {
  return (
    <Tooltip title={info}>
      <Space>
        {icon}
        <Typography.Title style={{ fontSize: 16, marginBottom: 0 }}>{title}</Typography.Title>
      </Space>
    </Tooltip>
  );
};

export default ChartHeader;
