import { gql } from "graphql-tag";

export const FormAssignment = {
  GetAll: () => gql`
    query getAllAppFormAssignments($id: String!) {
      application(id: $id) {
        id
        formAssignments {
          id
          documentVariableFieldId
          expectedAnswer
          question {
            id
            questionVariableName
            questionType
            question
            order
            helpText
            totalTime
            totalRisk
            answerChoices {
              id
              answer
              riskIncrease
              timeIncreaseInDays
            }
            savedAnswers {
              id
              answer
              riskIncrease
              timeIncreaseInDays
            }
          }
        }
      }
    }
  `,
  GetOne: () => gql`
    query getOneAppFormAssignment($id: String!, $ids: [String]) {
      application(id: $id) {
        id
        formAssignments(filter: { variableFieldIds: $ids }) {
          id
          documentVariableFieldId
          expectedAnswer
          question {
            id
            questionVariableName
            questionType
            question
            order
            helpText
            totalTime
            totalRisk
            answerChoices {
              id
              answer
              riskIncrease
              timeIncreaseInDays
            }
            savedAnswers {
              id
              answer
              riskIncrease
              timeIncreaseInDays
            }
          }
        }
      }
    }
  `,
};
