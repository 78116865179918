import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Button } from "antd";

export const P18 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="instructions">
        <p>Financial (FIAR), Acquisition, etc.</p>
        <b>14. Privacy</b>
        <p>For assistance with completing this section, please contact your Base Privacy Manager.</p>
        <b>14A. PII/PHI</b>
        <p>Will the system process, store, or transmit PII/PHI? Use DD Form 2093, Privacy Impact Assessment (PIA) to determine PII/PHI</p>

        <b>14B. What PII will be collected?</b> Select all that apply.
        <b>14C. Privacy Factors</b>
        <p>From the six Privacy Factors provided, evaluate the PII in the System</p>

        <b>14D. PII Confidentiality Impact Level.</b> Select overall PII Confidentiality Impact Level (PCIL).
        <b>15. Categorization Information</b>
        <span>
          In the table, be sure to add main information type category for the specific information yypes. For example, if the system manages “Air Traffic Control Data” but the closest data type is “Air Transportation” describe 
          the<b> similarities and gaps in CIA impacts</b>. For any reduction or increase from the default recommended security categorization for each information type, add a detailed justification for the change in the amplifying data column. Final system categorization below should represent the overall categorization based on the highest ranking in all categories.
        </span>

        <p><b>16. RMF Roles</b></p>
        <p>Provide name, phone (comm/DSN) and organization for each role.</p>
        <p>IAW AFI 17-101, the ISO/PM and AO/AODR is required to sign the ITCSC. The signature order is organizationally determined.</p>

      </div>
    </>
  );
};
