import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

import P7D1 from "../Assets/P7D1.png";

export const P7 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <>
      <div
        className="doc_pcil"
        style={{
          width: "95%",
          fontSize: 12,
        }}
      >
        <div style={{ paddingLeft: 80 }}>
          <p>
            In order to determine the PII confidentiality impact level, the impact levels from
            Reference (d) and the 6 factors from Reference (f) should be used together using a
            “Balanced Approach.” The “Balanced Approach” considers all inputs as an average. It
            is a best judgment standard where the analyst considers the values and various
            weights of the individual components. This “Balanced Approach” takes all factors
            into consideration to determine the PII confidentiality impact level.
          </p>
          <p style={{ fontSize: 16, display: "flex" }}>
            <b style={{ paddingRight: 10 }}>2.6.1</b>
            <b style={{ flex: 1 }}>
              <u>STEP 1. REVIEW THE FIPS 199 IMPACT VALUE FOR EACH OF THE SIX FACTORS.</u>
            </b>
          </p>
        </div>
        <div style={{ paddingLeft: 40 }}>
          <p style={{ fontSize: 16, display: "flex" }}>
            <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
            <b style={{ flex: 1 }}>
              <i>
                Carefully read the definitions of each impact value in Table 1 (below). Use
                these definitions, as tailored below in Step 2, to determine the impact value
                for each of the six factors from NIST SP 800-122.
              </i>
            </b>
          </p>
        </div>
        <div>
          <img src={P7D1} alt="P7_D1" style={{ width: "100%" }} />
        </div>

        <br />
        <br />
        <div style={{ paddingLeft: 40 }}>
          4. Note: the descriptions given in the Low, Moderate, and High cells are examples.
          They are for illustrative purposes and provided to clarify both the more general
          descriptions in Table 1 and the six factors from NIST SP 800-122; each instance of
          PII is different, and each organization has a unique set of requirements and
          different missions to consider. Refer directly to NIST SP 800-122 section 3.2 for a
          more complete description of the 6 factors.
        </div>
      </div>
    </>
  );
};
