import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Api from "api";
import { Roles } from "hooks/useAccessControl";
import { useAuthContext } from "components/Providers/WithAuth";
import CommentItem from "./CommentItem";
import CommentForm from "./CommentForm";
import { SkeletonQuestionComment } from "components/Common/Skeletons/CustomSkeletons";
import { Button, Drawer, Dropdown, Empty, Input, List, message, Row, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  ApplicationQuestionCommentNode,
  ApplicationQuestionNode,
  QuestionCommentsQuery,
  QuestionCommentsQueryVariables,
} from "types";
import { useQuestionnairePageContext } from "../../Questionnaire";
import useViewport from "hooks/useViewport";

export interface CommentsDrawerState {
  open: boolean;
  question: ApplicationQuestionNode | null;
}

interface Props {
  state: CommentsDrawerState;
}

const CommentsDrawer: React.FC<Props> = ({ state }) => {
  const { open, question } = state;
  const { setCommentsDrawer, incrementTotalComments } = useQuestionnairePageContext();
  const { user } = useAuthContext();
  const viewport = useViewport();

  const userCanSeePrivate = user.role !== Roles.ApplicantUser;
  const [adding, setAdding] = useState(false);
  const [privatesVisible, setPrivatesVisible] = useState(userCanSeePrivate);

  const [GetComments, { data, loading: commentsLoading }] = useLazyQuery<
    QuestionCommentsQuery,
    QuestionCommentsQueryVariables
  >(Api.ApplicationComment.GetAll(), {
    variables: {
      id: question?.id!,
    },
    onCompleted: (res) => {
      console.log({ Comments: res.questionComments });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  useEffect(() => {
    question && console.log({ DrawerOpen: open });
    question && console.log({ QuestionInDrawer: question });

    if (!open) return;
    GetComments();
  }, [state.open]);

  const comments: Array<ApplicationQuestionCommentNode> | any = data?.questionComments;

  const togglePrivateComments = () => setPrivatesVisible(!privatesVisible);

  const dropdownMenu = {
    items: [
      {
        key: "togglePrivate",
        label: (
          <Row onClick={() => togglePrivateComments()}>
            <Space>
              <span>Toggle private comments</span>
              <span>
                <Input type="checkbox" readOnly checked={privatesVisible} />
              </span>
            </Space>
          </Row>
        ),
      },
    ],
  };

  const onClose = () => {
    setAdding(false);
    setCommentsDrawer({
      open: false,
      question: null,
    });
  };

  const DrawerFooter = () => {
    if (adding || commentsLoading || comments?.length === 0) return <></>;

    return (
      <Row justify="end">
        <Button type="primary" onClick={() => setAdding(true)} icon={<PlusOutlined />}>
          Add Comment
        </Button>
      </Row>
    );
  };

  return (
    <>
      <Drawer
        width={viewport.drawer.width.small}
        open={open}
        onClose={onClose}
        title="Comments"
        extra={
          userCanSeePrivate && (
            <Dropdown menu={dropdownMenu}>
              <Button>More</Button>
            </Dropdown>
          )
        }
        footer={<DrawerFooter />}
      >
        {adding && (
          <CommentForm
            userCanSeePrivate={userCanSeePrivate}
            question={question}
            setAdding={setAdding}
            incrementTotalComments={incrementTotalComments}
          />
        )}
        {commentsLoading ? (
          <CommentSkeleton />
        ) : (
          <List
            itemLayout="vertical"
            dataSource={comments}
            renderItem={(item: ApplicationQuestionCommentNode) => (
              <CommentItem
                comment={item}
                visible={!item.isPrivate || (item.isPrivate && privatesVisible)}
              />
            )}
            locale={{
              emptyText: (
                <Empty description={<span>This question has no comments</span>}>
                  <Button
                    type="primary"
                    onClick={() => setAdding(true)}
                    icon={<PlusOutlined />}
                  >
                    Add Comment
                  </Button>
                </Empty>
              ),
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default CommentsDrawer;

const CommentSkeleton = () => (
  <div style={{ marginTop: 20 }}>
    {[1, 2, 3, 4, 5].map((x, i) => (
      <div style={{ marginBottom: 40 }} key={i}>
        <SkeletonQuestionComment key={i} />
      </div>
    ))}
  </div>
);
