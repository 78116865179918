import { useState } from "react";
import { SkeletonWrapper } from "components/Common/Skeletons/SkeletonLoading";
import ChartHeader from "../Components/ChartHeader";
import FilterIcon from "../Components/FilterIcon";
import { Bar } from "@ant-design/plots";
import { Card, Empty, message, Select } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import { globalColors } from "../Utils/index";
import Api from "api";
import { useQuery } from "@apollo/client";

const RiskDistributionChart = () => {
  const [filter, setFilter] = useState(false);

  const { data, loading } = useQuery(Api.SecurityFindings.GetRiskDistData(), {
    variables: {
      stacked: filter,
    },
    onError: (error) => {
      console.log(error);
      message.error(error.message, 7);
    },
    onCompleted: (res) => {
      console.log({ riskDistData: res.riskDistSecFindingsGraphData });
      setInitDataSource(res.riskDistSecFindingsGraphData);
      setDataSource(res.riskDistSecFindingsGraphData);
    },
  });

  const [initDataSource, setInitDataSource] = useState(data?.riskDistSecFindingsGraphData);
  const [dataSource, setDataSource] = useState(data?.riskDistSecFindingsGraphData);

  const basicBarConfig: any = {
    config: {
      data: dataSource,
      xField: "count",
      yField: "severity",
      autoSize: false,
      height: 200,
      legend: false,
      direction: "downward",
      label: {
        position: "middle",
      },
      color: ({ severity }: { severity: Severity }) => {
        let color = "#0E1B41";
        if (severity) {
          switch (severity) {
            case "LOW":
              color = globalColors.blue;
              break;
            case "MEDIUM":
              color = globalColors.yellow;
              break;
            case "HIGH":
              color = globalColors.orange;
              break;
            case "CRITICAL":
              color = globalColors.red;
              break;
            case "NONE":
              color = "#0E1B41";
              break;
            default:
              color = "#0E1B41";
              break;
          }
        }
        return color;
      },
    },
  };

  // stacked by source
  const stackedBarConfig: any = {
    config: {
      data: dataSource,
      isStack: true,
      xField: "count",
      yField: "severity",
      seriesField: "sourceTool",
      autoSize: false,
      height: 200,
      color: ({ sourceTool }: { sourceTool: SourceTool }) => {
        let color;
        if (sourceTool) {
          switch (sourceTool) {
            case "CYLANCE":
              color = "#6BC827";
              break;
            case "SENTINELONE":
              color = "#5C08CC";
              break;
            case "BLACKBERRY":
              color = "#25B290";
              break;
            default:
              color = "#0E1B41";
              break;
          }
        }
        return color;
      },
      label: {
        position: "middle",
        layout: [
          { type: "interval-adjust-position" },
          { type: "interval-hide-overlap" },
          { type: "adjust-color" },
        ],
      },
      legend: {
        position: "top-left",
      },
    },
  };

  console.log({ riskDistSource: dataSource });
  return (
    <Card
      className="shadowed-static"
      title={
        <ChartHeader
          title="Risk Distribution"
          icon={<WarningTwoTone />}
          info="Risk Distribution info coming soon!"
        />
      }
      style={{ textAlign: "left", width: "100%" }}
      extra={
        <Select
          style={{ width: 200 }}
          options={filterOptions}
          defaultValue={false}
          onSelect={(val: boolean) => setFilter(val)}
          value={filter}
          suffixIcon={<FilterIcon filterActive={filter !== false} />}
          onClear={() => {
            setFilter(false);
            setDataSource(initDataSource);
          }}
          allowClear={filter !== false}
        />
      }
    >
      <SkeletonWrapper type="barChart" loading={loading}>
        {dataSource?.length >= 1 ? (
          <>
            {filter === false && <Bar {...basicBarConfig.config} />}
            {filter === true && <Bar {...stackedBarConfig.config} />}
          </>
        ) : (
          <Empty />
        )}
      </SkeletonWrapper>
    </Card>
  );
};

const filterOptions = [
  { label: "Source Tool (All)", value: false },
  { label: "By Source Tool", value: true },
];

export default RiskDistributionChart;
