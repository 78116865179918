import { useEffect, useMemo, useState } from "react";
import { Button, Col, Dropdown, Menu, message, Popconfirm, Row, Tag, Typography } from "antd";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { ApolloError, useMutation, useQuery } from "@apollo/client";

import {
  AllQuestionGroupsQuery,
  BulkUpdateGroupMutation,
  GetAllQuestionCategoriesQuery,
  QuestionNode,
  UpdateQuestionMutation,
} from "types";
import Api from "api";
import { FieldKeysEnum, ParentTypeEnum, QuestionEditorState } from "./reducer";
import SidebarSection, { SidebarManageButton } from "../../Shared/SidebarSection";
import ParentOrderingField from "../../Shared/ParentOrderingField";
import ParentSelectField from "../../Shared/ParentSelectField";
import { SaveStatusEnum } from "../../Shared/types";
import useEditorLoading from "hooks/useEditorLoading";

const { Text } = Typography;

type ParentInfoProps = {
  question: QuestionNode;
  setEditState: any;
  setSaveState: any;
  fieldKey: FieldKeysEnum;
  loading: Loading;
  state: any;
  updateQuestion: any;
  refetchQuestion?: any;
  parentValues: any;
};

type ParentSelectMenuProps = {
  updateQuestion: any;
  question: QuestionNode;
  setSaveState: any;
  refetchQuestion: any;
  setEditState: any;
};

type DropdownProps = {
  question: QuestionNode;
  updateQuestion: any;
  setSaveState: any;
  setEditState: any;
  refetchQuestion: any;
  fieldKey: FieldKeysEnum;
  parentValues: any;
  state: QuestionEditorState;
  loading: Loading;
  setNewParentType: any;
};

const ParentInformation: React.FC<ParentInfoProps> = (props) => {
  const {
    question,
    setEditState,
    setSaveState,
    fieldKey,
    loading,
    state,
    updateQuestion,
    refetchQuestion,
    parentValues,
  } = props;
  const [newParentType, setNewParentType] = useState(undefined);

  const initialOrder = parentValues?.editableParent?.order;

  const parentTags = useMemo(() => {
    const tags: any[] = [];
    if (!parentValues) {
      return tags;
    }
    if (parentValues?.groupId) {
      tags.push("In Group");
    }
    if (parentValues?.categoryId) {
      tags.push("In Category");
    }

    console.log("tags: ", tags);
    return tags;
  }, [question]);

  console.log({ parentStatus: parentValues.parentStatus });

  const parentAssignmentLoading = useEditorLoading(
    FieldKeysEnum.parentAssignment,
    state,
    loading,
  );
  const parentOrderLoading = useEditorLoading(FieldKeysEnum.parentOrder, state, loading);
  const saveParentOrder = (orderKey: "groupOrder" | "order", orderVal: number) => {
    console.log("%cSAVE PARENT OORDER", "color:pink");
    console.log("saveParentOrder orderKey & orderVal ", orderKey, orderVal);

    if (!orderVal) return;
    if (initialOrder !== orderVal) {
      setSaveState(FieldKeysEnum.parentOrder, SaveStatusEnum.Loading);
      setEditState(FieldKeysEnum.parentOrder, false);
      updateQuestion({
        variables: {
          id: question.id,
          [orderKey]: orderVal,
        },
        onCompleted: (res: UpdateQuestionMutation) => {
          console.log(res);
          setSaveState(fieldKey, SaveStatusEnum.Success);
          refetchQuestion();
        },
        onError: (error: ApolloError) => {
          console.log(error.message);
          message.error(error.message, 5);
          setSaveState(fieldKey, SaveStatusEnum.Error);
        },
      });
    }
  };

  const ParentAssignmentFieldRenderer = () => {
    const editingCurrentField: boolean = state[FieldKeysEnum.parentAssignment].editActive;
    const parentStatus = parentValues.parentStatus;

    if (!editingCurrentField) {
      return <ParentTagRenderer parentTags={parentTags} />;
    }
    if (parentStatus === ParentTypeEnum.category || newParentType === "category") {
      return (
        <CategorySelectField
          question={question}
          updateQuestion={updateQuestion}
          setSaveState={setSaveState}
          refetchQuestion={refetchQuestion}
          setEditState={setEditState}
        />
      );
    }
    if (parentStatus === ParentTypeEnum.group || newParentType === "group") {
      return (
        <GroupSelectField
          question={question}
          updateQuestion={updateQuestion}
          setSaveState={setSaveState}
          refetchQuestion={refetchQuestion}
          setEditState={setEditState}
        />
      );
    }
    return <></>;
  };

  const renderParentOrder = parentValues?.renderParentOrder;

  const parentAssignmentTitle = useMemo(() => {
    let title: string = "Parent Assignment";
    if (state[FieldKeysEnum.parentAssignment].editActive) {
      if (parentValues.parentStatus === "category") {
        title = "Choose Category";
      } else if (parentValues.parentStatus === "group") {
        title = "Choose Group";
      }
    } else {
      title = "Parent Assignment";
    }
    return title;
  }, [state[FieldKeysEnum.parentAssignment], parentValues.parentStatus, state]);

  return (
    <>
      <SidebarSection
        title={parentAssignmentTitle}
        tooltip="The question's direct parent assignment"
        actionMenu={
          <ParentAssignmentDropdown
            question={question}
            updateQuestion={updateQuestion}
            refetchQuestion={refetchQuestion}
            setEditState={setEditState}
            setSaveState={setSaveState}
            parentValues={parentValues}
            state={state}
            loading={parentAssignmentLoading}
            fieldKey={FieldKeysEnum.parentAssignment}
            setNewParentType={setNewParentType}
          />
        }
        loading={parentAssignmentLoading}
      >
        <ParentAssignmentFieldRenderer />
      </SidebarSection>

      {parentValues?.categoryName && (
        <SidebarSection title="Category Name" loading={loading}>
          <Text>{parentValues.categoryName}</Text>
        </SidebarSection>
      )}
      <ParentOrderField
        renderParentOrder={renderParentOrder}
        loading={loading}
        setSaveState={setSaveState}
        setEditState={setEditState}
        state={state}
        parentOrderLoading={parentOrderLoading}
        saveParentOrder={saveParentOrder}
        parentValues={parentValues}
      />
    </>
  );
};

const ParentAssignmentDropdown: React.FC<DropdownProps> = ({
  question,
  updateQuestion,
  setSaveState,
  setEditState,
  refetchQuestion,
  parentValues,
  state,
  loading,
  fieldKey,
  setNewParentType,
}) => {
  const [removeFromGroup, { loading: removeGroupLoading }] =
    useMutation<BulkUpdateGroupMutation>(Api.QuestionGroup.BulkUpdate());

  const removeQuestionFromCategory = () => {
    setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Loading);
    updateQuestion({
      variables: {
        id: question.id,
        categoryId: "CLEAR",
      },
      onCompleted: (res: UpdateQuestionMutation) => {
        setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Success);
        refetchQuestion();
      },
      onError: (error: ApolloError) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Error);
      },
    });
  };

  const removeQuestionFromGroup = () => {
    setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Loading);

    removeFromGroup({
      variables: {
        questionIds: [question.id],
        groupId: "CLEAR",
      },
      onCompleted: (res) => {
        console.log({ removedFromGroup: res });
        refetchQuestion();
        setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Success);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Error);
      },
      refetchQueries: [Api.QuestionModule.WorkflowEditor()],
    });
  };

  //  keys: changeCategory, changeGroup, detachCategory, detachGroup, attachCategory, detachCategory
  const handleParentAssignmentMenu = (key: string) => {
    console.group("handleParentAssignmentMenu");
    console.log({ key });
    console.groupEnd();
    if (!key) return;
    if (key === "detachCategory" || key === "detachGroup") return;
    if (key === "attachCategory") {
      setNewParentType("category");
    }
    if (key === "attachGroup") {
      setNewParentType("group");
    }

    setEditState(FieldKeysEnum.parentAssignment);
  };

  const resetEditing = () => {
    setEditState(FieldKeysEnum.parentAssignment);
  };

  const inGroupOptions =
    parentValues.parentStatus === ParentTypeEnum.group
      ? [
          {
            label: "Change Group",
            key: "changeGroup",
          },
          {
            label: (
              <Popconfirm
                title="Confirm detach from Group"
                okText="Remove"
                cancelText="Cancel"
                onConfirm={removeQuestionFromGroup}
              >
                <a href="groupRemove">Remove from Group</a>
              </Popconfirm>
            ),
            key: "detachGroup",
            danger: true,
          },
        ]
      : [];

  const inCategoryOptions =
    parentValues.parentStatus === ParentTypeEnum.category
      ? [
          {
            label: "Change Category",
            key: "changeCategory",
          },
          {
            label: (
              <Popconfirm
                title="Confirm detach from Category"
                okText="Remove"
                cancelText="Cancel"
                onConfirm={removeQuestionFromCategory}
              >
                <a href="categoryRemove">Remove from Category</a>
              </Popconfirm>
            ),
            key: "detachCategory",
            danger: true,
          },
        ]
      : [];

  const notAssignedOptions =
    parentValues.parentStatus === ParentTypeEnum.orphan
      ? [
          {
            label: "Attach to Category",
            key: "attachCategory",
          },
          { label: "Attach to Group", key: "attachGroup" },
        ]
      : [];

  const parentAssignmentMenuItems = [
    ...inGroupOptions,
    ...inCategoryOptions,
    ...notAssignedOptions,
  ];

  const parentAssignmentMenu = (
    <Menu
      items={parentAssignmentMenuItems}
      onClick={(e) => handleParentAssignmentMenu(e.key)}
    />
  );

  const editingThisField: boolean = state[FieldKeysEnum.parentAssignment].editActive;

  if (editingThisField) {
    return <Button icon={<CloseOutlined />} size="small" onClick={resetEditing} />;
  } else {
    return (
      <Dropdown overlay={parentAssignmentMenu}>
        <Button icon={<SettingOutlined />} size="small" disabled={loading} />
      </Dropdown>
    );
  }
};

const ParentOrderField: React.FC<any> = (props) => {
  const [orderVal, setOrderVal] = useState<number | undefined>(undefined);

  const { loading, setEditState, state, saveParentOrder, parentValues } = props;

  const handleOrderChange = (e: any) => {
    setOrderVal(Number(e.target.value));
  };

  const orderKey = parentValues?.editableParent?.orderKey;
  const editingCurrentField: boolean = state[FieldKeysEnum.parentOrder].editActive;

  if (parentValues?.renderParentOrder === true) {
    return (
      <SidebarSection
        title={parentValues!.orderHeading}
        loading={loading}
        valueLoading={loading}
        renderSaveStatus={
          state[FieldKeysEnum.parentOrder].saveStatus !== SaveStatusEnum.Default
        }
        saveStatus={state[FieldKeysEnum.parentOrder].saveStatus}
        actionMenu={
          <SidebarManageButton
            setEditState={setEditState}
            fieldKey={FieldKeysEnum[FieldKeysEnum.parentOrder]}
            tooltip="The order this question appears in its group. Only group order can be changed for questions in a group."
            loading={loading}
            editingCurrentField={state[FieldKeysEnum.parentOrder].editActive}
          />
        }
      >
        {editingCurrentField ? (
          <Row align="top" justify="space-between">
            <Col span={20}>
              <ParentOrderingField
                value={orderVal}
                defaultValue={parentValues?.editableParent?.editableOrder}
                onChange={(e: any) => handleOrderChange(e)}
                saveValue={() => saveParentOrder(orderKey, orderVal)}
                styles={{ width: 85, height: "32.25px !important" }}
              />
            </Col>
          </Row>
        ) : (
          <Text>{parentValues?.editableParent?.order}</Text>
        )}
      </SidebarSection>
    );
  } else {
    return <></>;
  }
};

const CategorySelectField: React.FC<ParentSelectMenuProps> = ({
  updateQuestion,
  setSaveState,
  setEditState,
  question,
  refetchQuestion,
}) => {
  const [newCategory, setNewCategory] = useState<undefined | string>(undefined);
  const { data: allCategories, loading: categoriesLoading } =
    useQuery<GetAllQuestionCategoriesQuery>(Api.QuestionCategory.GetBasicList(), {
      onCompleted: (res) => {
        console.log(res);
      },
      onError: (error) => {
        message.error(error.message, 5);
        console.log(error.message);
      },
    });

  const handleChangeCategory = () => {
    setEditState(FieldKeysEnum.parentAssignment);
    setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Loading);
    updateQuestion({
      variables: {
        id: question.id,
        categoryId: newCategory,
      },
      onCompleted: (res: UpdateQuestionMutation) => {
        setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Success);
        refetchQuestion();
      },
      onError: (error: ApolloError) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Success);
      },
    });
  };

  return (
    <ParentSelectField
      options={allCategories?.allQuestionCategories.map((category: any) => ({
        label: category?.name,
        value: category?.id,
      }))}
      value={newCategory}
      loading={categoriesLoading}
      onChange={setNewCategory}
      onSave={handleChangeCategory}
    />
  );
};

const GroupSelectField: React.FC<ParentSelectMenuProps> = ({
  updateQuestion,
  setSaveState,
  setEditState,
  question,
  refetchQuestion,
}) => {
  const [newGroup, setNewGroup] = useState(undefined);
  const { data: allGroups, loading: groupsLoading } = useQuery<AllQuestionGroupsQuery>(
    Api.QuestionGroup.GetAll(),
    {
      onCompleted: (res) => {
        console.log(res);
      },
      onError: (error) => {
        message.error(error.message, 7);
        console.log(error.message);
      },
    },
  );

  const handleChangeGroup = () => {
    setEditState(FieldKeysEnum.parentAssignment);
    setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Loading);
    updateQuestion({
      variables: {
        id: question.id,
        groupId: newGroup,
      },
      onCompleted: () => {
        setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Success);
        refetchQuestion();
      },
      onError: (error: ApolloError) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.parentAssignment, SaveStatusEnum.Error);
      },
    });
  };

  return (
    <ParentSelectField
      options={allGroups?.allQuestionGroups.map((group: any) => ({
        label: group?.header,
        value: group?.id,
      }))}
      value={newGroup}
      loading={groupsLoading}
      onChange={setNewGroup}
      onSave={handleChangeGroup}
    />
  );
};

const ParentTagRenderer = ({ parentTags }: { parentTags: any[] }) => {
  return (
    <>
      {parentTags.length > 0 ? (
        parentTags.map((tag) => <Tag key={tag}>{tag}</Tag>)
      ) : (
        <Text>Not Assigned</Text>
      )}
    </>
  );
};

export default ParentInformation;
