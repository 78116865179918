export const P22 = (props) => {
  return (
    <>
      <div className="itcsc" style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "left",
          }}
        >
          <ol type="a" style={{ padding: "80px" }}>
            <h2 style={{ borderBottom: "2px solid black", width: "fit-content" }}>
              REFERENCES
            </h2>
            <li>
              Department of Defense (DoD) Instruction (DoDI) 8510.01,
              <i> Risk Management Framework for DoD Systems</i>, 19 July 2022
            </li>
            <li>
              DoDI 5200.48,<i> Controlled Unclassified Information (CUI)</i>, 06 March 2020
            </li>
            <li>
              DoD CUI Registry is located at https://www.dodcui.mil/Home/DoD-CUI-Registry/
            </li>
            <li>
              DoDI 5230.09,<i> Clearance of DoD Information for Public Release</i>, 25 January
              2019
            </li>
            <li>
              Program managers for all information systems are required to complete a Privacy
              Impact Assessment (PIA) DD Form2930 in conjunction with an organizational privacy
              subject matter expert.
            </li>
            <ul>
              <li>
                In cases where no PII/PHI is present, the PIA will serve as a conclusive
                determination that privacy requirements do not apply to the system. • All
                documentation must be coordinated through the servicing Base and MAJCOM Privacy
                Manager/ Monitor before submission to DAF Privacy.
              </li>
            </ul>
            <li>
              Defense Information Systems Agency Risk Executive (RE) Risk Adjudication and
              Connection Division,
              <i> Department Of Defense (DoD) Cloud Connection Process Guide Version 2</i>,
              March 2017
            </li>
            <li>
              DoDI 5200.01 Change 2,
              <i>
                DoD Information Security Program and Protection of Sensitive Compartmented
                Information (SCI)
              </i>
              , 01 October 2020
            </li>
            <li>
              Committee on National Security Systems (NSS) Instruction (CNSSI) 1253F Attachment
              (Atch) 6,<i> Privacy Overlay</i>, 23 April 2015
            </li>
            <li>
              <i>
                DoD Cloud Computing Security Requirements Guide (Cloud SRG) Version 1 Release 4
              </i>
              , 20 January 2022
            </li>
            <li>
              National Institute of Standards and Technology Special Publication (NIST SP)
              800-60 Volumes 1 & 2 Revision 1, Guide for Mapping Types of Information and
              Information Systems to Security Categories, 01 August 2008
            </li>
            <li>
              Air Force Instruction 33-332, Air Force Privacy and Civil Liberties Program, 12
              May 2020
            </li>
            <li>
              United States Office of Personnel Management, System of Records Notice (SORN)
              Guide, 22 April 2010
            </li>
            <li>
              DoD Chief Information Officer Memorandum,
              <i>
                Treatment of Personally Identifiable Information within Information Impact
                Level 2 Commercial Cloud Services for the Department of Defense,
              </i>
              07 August 2019
            </li>
            <li>Additional references for NSS Determination</li>
            <ul>
              <li>
                10 U.S.C. § 130b, Personnel in Overseas, Sensitive, or Routinely Deployable
                Units: Nondisclosure of Personally Identifying Information (2020)
              </li>
              <li>
                10 U.S.C. § 130e, Treatment under Freedom of Information Act of Certain
                Critical Infrastructure Security Information (2020)
              </li>
              <li>15 U.S.C. §§ 46(f), 57b-2 & 15 U.S.C. §3710a(c), Trade Secrets Act Data</li>
              <li>
                18 U.S.C. § 3771, Crime Victim’s Rights Act (DoD implemented by Article 6b,
                Uniform Code of Military Justice — 10 U.S.C. § 806b)
              </li>
              <li> 40 U.S.C. § 11103, Applicability to NSS (2020)</li>
              <li> 42 U.S.C. § 2162, Unclassified Nuclear Data</li>
              <li>
                Critical Infrastructure Information Act of 2002, Civilian Critical
                Infrastructure
              </li>
              <li>
                DoDI 6495.02 Volume 1 Change 6, Sexual Assault Prevention and Response: Program
                Procedures, 10 November 2021
              </li>
              <li>
                DoD Memorandum (Memo),
                <i>
                  Implementing National Security Memorandum-8, Task 5: Identification and
                  Inventorying of National Security Systems Guidance (NMM-2022-05) (25 February
                  2022)
                </i>
                , 05 May 2022
              </li>
              <li>
                National Manager Memorandum-2022-05, National Security Memorandum 8, Task 5:
                Identification and Inventorying of National Security Systems Guidance, 25
                February 2022
              </li>
              <li>
                NIST SP 800-59,
                <i>
                  Guideline for Identifying an Information System as a National Security System
                </i>
                , 20 August 2003
              </li>
            </ul>
          </ol>
        </div>
      </div>
    </>
  );
};
