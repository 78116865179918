import FulfillQuestion from "../Grid/Questions/FulfillQuestion";
import MultipleChoiceQuestion from "./Questions/MultipleChoiceQuestion";

import FulfillTextInput from "../Grid/Inputs/FulfillTextInput";
import FulfillDateInput from "../Grid/Inputs/FulfillDateInput";
import FulfillDateRangeInput from "../Grid/Inputs/FulfillDateRangeInput";
import SelectOneInput from "../Grid/Inputs/SelectOneInput";

import { ApplicationQuestionNode, QuestionType } from "types";
import "styles/questionnaire.css";

export interface GridRendererProps {
  question: ApplicationQuestionNode;
}

const GridQuestionRenderer: React.FC<GridRendererProps> = ({ question }) => {
  switch (question.questionType) {
    case QuestionType.FulfillText:
      return (
        <FulfillQuestion
          question={question}
          input={FulfillTextInput}
          defaultAnswer={question.savedAnswers[0]?.answer!}
        />
      );
    case QuestionType.FulfillDate:
      return (
        <FulfillQuestion
          question={question}
          input={FulfillDateInput}
          defaultAnswer={question.savedAnswers[0]?.answer!}
        />
      );
    case QuestionType.FulfillDateRange:
      return (
        <FulfillQuestion
          question={question}
          input={FulfillDateRangeInput}
          defaultAnswer={question.savedAnswers[0]?.answer!}
        />
      );
    case QuestionType.MultipleChoicePickOne:
      return (
        <MultipleChoiceQuestion
          question={question}
          input={SelectOneInput}
          defaultAnswer={question.savedAnswers[0]?.id!}
        />
      );
    default:
      return <>Invalid Question Type</>;
  }
};

export default GridQuestionRenderer;
