import React, { useState } from "react";
import HelpDrawer from "./HelpDrawer";
import { Tooltip } from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";

interface Props {
  text: string;
}

const GetHelp: React.FC<Props> = ({ text }) => {
  const [open, setOpen] = useState(false);

  if (!text) return <></>;

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <Tooltip color="black" title="Click for Help" mouseLeaveDelay={0.4}>
        <QuestionCircleTwoTone style={iconStyle} onClick={onOpen} />
      </Tooltip>
      {open && <HelpDrawer open={open} onClose={onClose} text={text} />}
    </>
  );
};

export default GetHelp;

const iconStyle = {
  cursor: "pointer",
  fontSize: "16px",
};
