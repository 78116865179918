import { gql } from "graphql-tag";

export const ApplicationCategoryFragments = gql`
  fragment QuestionFields on ApplicationQuestionNode {
    id
    questionType
    question
    helpText
    totalComments
    totalTime
    totalRisk
    isLocked
    isSavedWithDefaults
    isAnswered
    order
    groupOrder
    answerChoices {
      id
      isSaved
      answerText
      answer
      answerType
      additionalFieldType
      additionalFieldToggle
      riskIncrease
      timeIncreaseInDays
    }
    savedAnswers {
      id
      answerText
      answer
      additionalFieldType
      answerType
      riskIncrease
      timeIncreaseInDays
    }
  }
  fragment QuestionGroupFields on ApplicationQuestionGroupNode {
    id
    header
    description
    order
    groupType
    categoryId
  }
`;

export const ApplicationCategory = {
  GetOne: () => gql`
    query applicationCategory($id: String!) {
      applicationCategory(id: $id) {
        id
        name
        isLocked
        isComplete
        checkpoint {
          triggered
          questionsLocked
          checkpointSessionCompleted
          isWorkflowCheckpoint
        }
      }
    }
  `,

  GetQuestionsByPage: () => gql`
    ${ApplicationCategoryFragments}
    query getQuestionsByPage($categoryId: String!, $pageNumber: Int!) {
      getQuestionsByPage(categoryId: $categoryId, pageNumber: $pageNumber) {
        pageType
        pageNumber
        pageSize
        totalPages
        group {
          ...QuestionGroupFields
        }
        grid: gridContent {
          columnLabel
          rows {
            rowLabel
            question {
              ...QuestionFields
            }
          }
        }
        list: listContent {
          ...QuestionFields
          linkedQuestions {
            ...QuestionFields
            linkedQuestions {
              ...QuestionFields
              linkedQuestions {
                ...QuestionFields
                linkedQuestions {
                  ...QuestionFields
                  linkedQuestions {
                    ...QuestionFields
                    linkedQuestions {
                      ...QuestionFields
                      linkedQuestions {
                        ...QuestionFields
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};
