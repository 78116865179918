import { Tag } from "antd";
import {
  CheckCircleOutlined,
  HourglassOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";

type RiskType = string;

interface Risk {
  risk: RiskType;
}

type ApprovalStatus = string;

interface Status {
  status: ApprovalStatus;
}

const globalColors = {
  // used for risk in security findings dashboard
  green: "#21A000",
  yellow: "#B39B00",
  red: "#C70000",
  orange: "#FF7000",
  blue: "#005EC7",
};

export const LowRiskTag = ({ risk }: Risk) => {
  return <Tag color={globalColors.blue}>{risk}</Tag>;
};

export const MediumRiskTag = ({ risk }: Risk) => {
  return <Tag color={globalColors.yellow}>{risk}</Tag>;
};

export const HighRiskTag = ({ risk }: Risk) => {
  return <Tag color={globalColors.orange}>{risk}</Tag>;
};

export const CriticalRiskTag = ({ risk }: Risk) => {
  return <Tag color={globalColors.red}>{risk}</Tag>;
};

export const NoneRiskTag = ({ risk }: Risk) => {
  return <Tag>{risk}</Tag>;
};

export const mapSeverityTags = (risk: string): JSX.Element | string => {
  // use to map security findings status text to risk colored tag
  let ans;
  switch (risk.toLowerCase()) {
    case "low":
      ans = <LowRiskTag risk={risk} />;
      break;
    case "medium":
      ans = <MediumRiskTag risk={risk} />;
      break;
    case "high":
      ans = <HighRiskTag risk={risk} />;
      break;
    case "critical":
      ans = <CriticalRiskTag risk={risk} />;
      break;
    case "none":
      ans = <NoneRiskTag risk={risk} />;
      break;
    default:
      ans = risk;
  }

  return ans;
};

export const ReviewTag = ({ status }: Status) => {
  return (
    <Tag color="orange" icon={<PauseCircleOutlined />}>
      {status}
    </Tag>
  );
};
export const PendingTag = ({ status }: Status) => {
  return (
    <Tag color="purple" icon={<HourglassOutlined />}>
      {status}
    </Tag>
  );
};
export const ApprovedTag = ({ status }: Status) => {
  return (
    <Tag color="green" icon={<CheckCircleOutlined />}>
      {status}
    </Tag>
  );
};

export const mapApprovalTags = (status: string): JSX.Element | string => {
  // use to map security approval text to colored tag
  let ans;
  switch (status.toLowerCase()) {
    case "needs_review":
      ans = <ReviewTag status={status} />;
      break;
    case "pending_approval":
      ans = <PendingTag status={status} />;
      break;
    case "approved":
      ans = <ApprovedTag status={status} />;
      break;
    default:
      ans = <Tag>{status}</Tag>;
  }

  return ans;
};
