import { useQuestionnairePageContext } from "../../Questionnaire";
import GetHelp from "components/Common/Drawers/Help/GetHelp";
import MarkdownPreviewer from "components/Common/Markdown/MarkdownPreview";
import SaveStatusTag from "../../Components/SaveStatusTag";
import MetricsStatusTags from "../../Components/MetricsStatusTags";
import CommentsButton from "../../Components/CommentsDrawer/CommentsButton";
import { Button, Divider, List, Row, Space } from "antd";
import { ApplicationQuestionNode, QuestionType } from "types";

interface Props {
  question: ApplicationQuestionNode | any;
  saveStatus: string;
  unSave: (questionId: string, answerId?: string) => void;
  unSaveLoading: boolean;
  children: any;
}

const ListQuestion: React.FC<Props> = ({
  question,
  saveStatus,
  unSave,
  unSaveLoading,
  children,
}) => {
  const { setCommentsDrawer } = useQuestionnairePageContext();

  const hasDefaultAnswers = question.isSavedWithDefaults!;
  const isLocked = question.isLocked || hasDefaultAnswers;
  const isSaved = question.isAnswered;
  const isUnsaveType =
    question.questionType === QuestionType.MultipleChoicePickOne ||
    question.questionType === QuestionType.FulfillDate ||
    question.questionType === QuestionType.FulfillDateRange ||
    question.questionType === QuestionType.UploadFile;
  const allowUnsave = !isLocked && isSaved && isUnsaveType;
  const showMetricStatus =
    question.questionType === QuestionType.MultipleChoicePickOne ||
    question.questionType === QuestionType.MultipleChoicePickMany;

  const onUnsave = () => {
    unSave(question.id);
  };

  return (
    <>
      <List.Item
        className="shadowed-static"
        key={question.id}
        style={{
          textAlign: "left",
          padding: 20,
          paddingBottom: 10,
          marginBottom: 20,
          background: "white",
        }}
      >
        <Row style={{ display: "flex", alignItems: "flex-start", marginBottom: 20 }}>
          <Space style={{ flex: 1 }}>
            <MarkdownPreviewer source={question.question} />
          </Space>
          <Space style={{ marginLeft: 10 }}>
            <GetHelp text={question?.helpText} />
          </Space>
        </Row>
        {children}
        <Divider style={{ margin: "20px 0 10px" }} />
        <Row justify="end" style={{ marginTop: 10, display: "flex" }}>
          <Space style={{ marginRight: 10, flex: 1 }}>
            {allowUnsave && (
              <Button
                danger
                type="link"
                onClick={onUnsave}
                loading={unSaveLoading}
                style={{ padding: "0 10px 0 0" }}
              >
                Unsave
              </Button>
            )}
            {showMetricStatus && question.savedAnswers.length > 0 && (
              <MetricsStatusTags time={question.totalTime} risk={question.totalRisk} />
            )}
          </Space>
          <Space>
            <SaveStatusTag status={saveStatus} />
            <CommentsButton question={question} setDrawer={setCommentsDrawer} />
          </Space>
        </Row>
      </List.Item>
    </>
  );
};

export default ListQuestion;
