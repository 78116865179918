import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P14 = (props) => {
  const { state } = props;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const RMFRMarking = {
    label: "Select Marking",
    value: "rmf_roles_marking",
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>
                15. RMF Roles: Key Roles for Executing the RMF Consistent With the Prepare Step
                of The RMF and NIST SP 800-37 Revision 2
              </b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={RMFRMarking} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <b>Title</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <b>Name</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <b>Phone (DSN)</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <b>Organization</b>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                AO:*
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_ao_name"
                  // fieldId={document.variableFields[414]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_ao_phone"
                  // fieldId={document.variableFields[415]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_ao_organization"
                  // fieldId={document.variableFields[416]?.id}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                AO Designated Representative (AODR):
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_aodr_name"
                  // fieldId={document.variableFields[417]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_aodr_phone"
                  // fieldId={document.variableFields[418]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_aodr_organization"
                  // fieldId={document.variableFields[419]?.id}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                Program Manager:*
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_pm_name"
                  // fieldId={document.variableFields[420]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_pm_phone"
                  // fieldId={document.variableFields[421]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_pm_organization"
                  // fieldId={document.variableFields[422]?.id}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                Information System Owner (ISO):*
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_iso_name"
                  // fieldId={document.variableFields[423]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_iso_phone"
                  // fieldId={document.variableFields[424]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_iso_organization"
                  // fieldId={document.variableFields[425]?.id}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                Security Control Assessor (SCA):*
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_csa_name" // typos in backend field name
                  // fieldId={document.variableFields[426]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_csa_phone"
                  // fieldId={document.variableFields[427]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_csa_organization"
                  // fieldId={document.variableFields[428]?.id}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                Information System Security Manager (ISSM):*
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_issm_name"
                  // fieldId={document.variableFields[429]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_issm_phone"
                  // fieldId={document.variableFields[430]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_issm_organization"
                  // fieldId={document.variableFields[431]?.id}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                Information System Security Engineer (ISSE):
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_isse_name"
                  // fieldId={document.variableFields[432]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_isse_phone"
                  // fieldId={document.variableFields[433]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_isse_organization"
                  // fieldId={document.variableFields[434]?.id}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                User Representative:
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_user_representative_name"
                  // fieldId={document.variableFields[435]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_user_representative_phone"
                  // fieldId={document.variableFields[436]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_user_representative_organization"
                  // fieldId={document.variableFields[437]?.id}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                Requirements Lead:
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_requirements_lead_name"
                  // fieldId={document.variableFields[438]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.2,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_requirements_lead_phone"
                  // fieldId={document.variableFields[439]?.id}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="15_requirements_lead_organization"
                  // fieldId={document.variableFields[440]?.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
