export const P21 = (props) => {
  return (
    <>
      <div className="itcsc" style={{ display: "grid", placeItems: "center" }}>
        <div
          className="instructions"
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "10px 20px",
              textAlign: "left",
            }}
          >
            <div style={{ width: "48%", marginRight: "40px" }}>
              <div style={{ paddingLeft: "20px" }}>
                <div style={{ paddingLeft: "20px" }}>
                  <p style={{ fontWeight: "bold" }}>12F. PII Adverse Effect</p>
                  <p>
                    Evaluate the PII with respect to the threat to an individual's data if the
                    information is lost or compromised. Brief explanation of impact levels if
                    PII is exposed:
                  </p>
                  <ul>
                    <li>Limited - Limited (Minor Harm)</li>
                    <li>Serious - Significant degradation, damage, loss, or harm</li>
                    <li>Severe - Major degradation, damage, loss, or catastrophic harm.</li>
                  </ul>
                  <p>
                    <i>See Ref: (w) for more information. </i>
                  </p>
                  <p style={{ fontWeight: "bold" }}>12G. Specific Examples of Harm</p>
                  <p>
                    Describe specific examples of harm that could happen to an individual or
                    organization if PII was to be leaked, misused,or otherwise compromised.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    12H. Retrieving Information by a Personal Identifier
                  </p>
                  <p>
                    <i>See instructions under 7D/F/I.</i>
                  </p>
                  <p>
                    <i>See Refs: (i), (j), (k), & (l) for more information.</i>
                  </p>
                  <p style={{ fontWeight: "bold" }}>12I. PII Confidentiality Impact</p>
                  <p>
                    IAW Questions 12D-12G and Ref: (w), evaluate the Confidentiality Impact
                    Level of the System. If any factor in Questions 12D-12G is considered High,
                    the overall assessment should be "High." The aggregation of context, type,
                    and Confidentiality Impact of PII should factor into consideration.
                  </p>
                </div>
                <p style={{ fontWeight: "bold" }}>13. Classification</p>
                <div style={{ paddingLeft: "20px" }}>
                  <p style={{ fontWeight: "bold" }}>
                    13A. Highest Data Classification Processed
                  </p>
                  <p>
                    In Question 13A, identify the highest data classification that the System
                    will process.
                  </p>
                  <p>
                    <i>See Ref: (x) for more information.</i>
                  </p>
                  <p style={{ fontWeight: "bold" }}>13B. Dissemination Control</p>
                  <p>
                    In Question 10B, identify the dissemination control for the information
                    that the System will process.
                  </p>
                  <p>
                    <i>See Refs: (y) & (z) for more information.</i>
                  </p>
                </div>

                <p style={{ fontWeight: "bold" }}>14. Categorization Information</p>
                <div style={{ paddingLeft: "20px" }}>
                  <p>
                    In the table, be sure to add main Information Type category above the
                    specific Information Types. For any reduction from the default recommended
                    Security Categorization for each information type, add a detailed
                    justification for the change in the Amplifying Data column. Final System
                    Categorization below should represent the overall categorization based on
                    the highest ranking in all categories.
                  </p>
                </div>
              </div>
            </div>

            <div style={{ width: "52%" }}>
              <div style={{ paddingLeft: "20px" }}>
                <p style={{ fontWeight: "bold" }}>15. RMF Roles</p>
                <p>
                  The Program Office/Information System Owner (ISO) will integrate
                  cybersecurity risk management into their overall systems engineering,
                  acquisition, test and evaluation, and risk management processes.
                </p>
                <p>
                  The Program Office/ISO will complete RMF steps to obtain the appropriate
                  approval or authorization documentation before System testing or operation.
                </p>
                <p>
                  For DAF System(s), the Program Office/ISO will ensure the System (as
                  applicable) is registered in ITIPS and eMASS, as applicable.
                </p>
                <p>
                  Ensure aggregation of all data, plus the potential impact and likelihood of a
                  security issue arising from mishandling or misuse of that data, is factored
                  in the assessment of all decisions within the ITCSC.
                </p>
                <p style={{ fontWeight: "bold" }}>Summary</p>
                <p>
                  In accordance with AFI 17-101, the requirement is for a dual signed ITCSC
                  that documents ISO/PM and AO/AO Designated Representative concurrence on RMF
                  CATEGORIZE and SELECT elements. The signature order is organizationally
                  determined.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
