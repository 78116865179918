import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, InformationTypeSelect } from "..";
import { Button } from "antd";

const informationSelectStyle = {
  fontSize: ".7rem",
  border: "none",
  width: "100%",
  height: "100%",
};



const TableRow = ({ row, elementProps, getFieldID, label }) => {
  const confidentialityConfig = {
    label: 'Select One',
    value: `14_r${row}_confidentiality`,
  }

  const integrityConfig = {
    label: "Select One",
    value: `14_r${row}_integrity`,
  }

  const availabilityConfig = {
    label: "Select One",
    value: `14_r${row}_availability`,
  }

  return (
    <tr>
      <td style={{ padding: 0, border: 'none' }} height={'30px'} >
        {label === undefined ?
          <EditableField
            borderless
            {...elementProps}
            style={{ height: '100%', width: '100%' }}
            fieldName={`14_r${row}_information_type`}
          />
          : label
        }
      </td>
      <td style={{ padding: 0, border: 'none' }} height={'30px'}>
        <InformationTypeSelect
          elementProps={elementProps}
          style={informationSelectStyle}
          config={confidentialityConfig}
        />
      </td>
      <td style={{ padding: 0, border: 'none' }} height={'30px'}>
        <InformationTypeSelect
          elementProps={elementProps}
          style={informationSelectStyle}
          config={integrityConfig}
        />
      </td>
      <td style={{ padding: 0, border: 'none' }} height={'30px'}>
        <InformationTypeSelect
          elementProps={elementProps}
          style={informationSelectStyle}
          config={availabilityConfig}
        />
      </td>
      <td style={{ padding: 0, border: 'none' }} height={'30px'}>
        <EditableField
          borderless
          {...elementProps}
          style={{ height: '100%', width: '100%' }}
          fieldName={`14_r${row}_amplifying_data`}
        />
      </td>
    </tr>
  )
}

export const P12 = (props) => {
  const { document, state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const CIMarking = {
    label: "Select Marking",
    value: "categorization_information_classification_marking",
  };

  const rows = []
  for (let i = 1; i < 15; i++) {
    rows.push(
      <TableRow row={i}
        elementProps={elementProps}
        getFieldID={fieldId}
      />
    )
  }

  return (
    <>
      <div style={{ display: "grid", placeItems: "baseline", height: '100%' }}>
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'23%'} />
              <col span={1} width={'18%'} />
              <col span={1} width={'18%'} />
              <col span={1} width={'18%'} />
              <col span={1} width={'23%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={5}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={CIMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                      14. Categorization Information <i>Refs: (ab) & (ac)</i>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="text" colSpan={5}>
                  <i>Note 18: Categorize the CIA for applicable Information Types (i.e., Low, Moderate, or High)</i>
                </th>
              </tr>
              <tr>
                <th className="title">Information Types</th>
                <th className="title">Confidentiality</th>
                <th className="title">Integrity</th>
                <th className="title">Availability</th>
                <th className="title">Amplifying Data</th>
              </tr>
            </thead>

            <tbody>
              {rows}
              <TableRow row={rows.length}
                elementProps={elementProps}
                getFieldID={fieldId}
                label={<div style={{ borderBottom: '1px solid', height: '30px' }}></div>}
              />
              <TableRow row={rows.length + 1}
                elementProps={elementProps}
                getFieldID={fieldId}
                label={
                  <div style={{ textAlign: 'start', paddingLeft: '10px', borderBottom: '1px solid' }}>
                    <b>
                      FINAL SECURITY
                      CATEGORIZATION
                    </b>
                  </div>
                }
              />

            </tbody>
          </table>
        </div>
        <div
          style={{
            width: "80%",
            textAlign: "end",
            padding: 5,
            alignSelf: 'end',
            justifySelf: 'end'
          }}
        >
          <Button size="small" style={{ outline: '4px solid black', borderRadius: 0 }}>
            Reset Section 14
          </Button>
        </div>
      </div>
    </>
  );
};
