import React from "react";
import { MultipleChoiceInputProps } from "../Questions/MultipleChoiceQuestion";
import { Select } from "antd";

const SelectOneInput: React.FC<MultipleChoiceInputProps> = ({
  question,
  defaultAnswer,
  onChange,
  onUnsave,
  disabled,
}) => {
  return (
    <Select
      allowClear
      placeholder="Select One"
      defaultValue={defaultAnswer}
      options={question.answerChoices.map((a: any) => ({
        id: a.id,
        label: a.answerText,
        value: a.id,
      }))}
      onChange={(id) => {
        let answer = { id };
        if (!id) return;

        onChange(answer);
      }}
      onClear={() => onUnsave(defaultAnswer)}
      disabled={disabled}
      style={{ width: "100%" }}
    />
  );
};

export default SelectOneInput;
