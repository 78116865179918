import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { SaveStatusEnum } from "components/Administration/Workflow/Editor/Shared/types";

type Props = {
  status: SaveStatusEnum;
  styles?: any;
};

const SaveStatusIndicator: React.FC<Props> = ({ status, styles }) => {
  switch (status) {
    case SaveStatusEnum.Loading:
      return <SavingTag styles={styles} />;
    case SaveStatusEnum.Error:
      return <SaveFailTag styles={styles} />;
    case SaveStatusEnum.Success:
      return <SaveSuccessTag styles={styles} />;
    default:
      return <></>;
  }
};

export const SavingTag = ({ styles }: { styles?: any }) => {
  return (
    <Tag color="processing" icon={<SyncOutlined spin />} style={styles}>
      Saving
    </Tag>
  );
};

export const SaveSuccessTag = ({ styles }: { styles?: any }) => {
  return (
    <Tag color="green" icon={<CheckCircleOutlined />} style={styles}>
      Saved
    </Tag>
  );
};

export const SaveFailTag = ({ styles }: { styles?: any }) => {
  return (
    <Tag color="error" icon={<CloseCircleOutlined />} style={styles}>
      Save Failed
    </Tag>
  );
};

export default SaveStatusIndicator;
