import { Row, Tag } from "antd";
import CustomFormItem from "components/Common/RFForm/CustomFormItem";
import QuestionSelectionTable from "../QuestionSelectionTable";

const QuestionSelectField = (props) => {
  const {
    input: { onChange, value },
    setAnswerChoices,
    meta,
  } = props;

  return (
    <CustomFormItem>
      {meta.error && meta.touched && (
        <Row style={{ marginBottom: 12 }}>
          <Tag color="red">{meta.error}</Tag>
        </Row>
      )}
      <QuestionSelectionTable
        onChange={onChange}
        selectedQuestion={value}
        setAnswerChoices={setAnswerChoices}
      />
    </CustomFormItem>
  );
};

export default QuestionSelectField;
