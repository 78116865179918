export const P17 = (props) => {
  return (
    <>
      <div className="itcsc" style={{
        columnCount: 2,
        padding: '0px 6%',
        height: '100%'
      }}>
        <div className="instructions" style={{ width: '100%', textAlign: 'left', height: '100%' }}>
          <p><b>13F. PII Confidentiality Impact Level</b></p>
          <p><b>14. Categorization Information</b></p>
          <p>
            In the table, be sure to add main Information Type <br />
            category above the specific Information Types. For any <br />
            reduction from the default recommended Security <br />
            Categorization for each information type, add a detailed <br />
            justification for the change in the Amplifying Data <br />
            column. Final System Categorization below should <br />
            represent the overall categorization based on the <br />
            highest ranking in all categories.
          </p>
          <p><b>15. RMF Roles </b></p>
          <p>
            The ISO/PM will integrate cybersecurity risk<br />
            management into their overall systems engineering,<br />
            acquisition, test and evaluation, and risk management<br />
            processes.
          </p>
          <p>
            The ISO/PM will complete RMF steps to obtain the <br />
            appropriate approval or authorization documentation <br />
            before System testing or operation.
          </p>
          <p>
            The ISO/PM will ensure the System is registered <br />
            in ITIPS and eMASS, as applicable. Ensure aggregation of <br />
            all data, plus the potential impact and likelihood of a <br />
            security issue arising from mishandling or misuse of that <br />
            data, is factored in the assessment of all decisions within <br />
            the ITCSC.
          </p>
          <p>
            IAW AFI 17-101, the requirement is for a dual signed <br />
            ITCSC that documents ISO/PM and AO/AODR <br />
            concurrence on RMF CATEGORIZE and SELECT <br />
            elements. The signature order is organizationally <br />
            determined.
          </p>

        </div>
      </div>
    </>
  );
};
