import { Button, Card, Space, Typography } from "antd";
import {
  CloseCircleOutlined,
  EditOutlined,
  FileMarkdownOutlined,
  SaveOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import MarkdownEditor from "components/Common/Markdown/Editor";

const { Title } = Typography;

type Props = {
  title: string;
  mdEditorOpen: boolean;
  mdValue: string;
  clearMdValue: () => void;
  onMdChange: (value: string) => void;
  openMdEditor: () => void;
  closeMdEditor: () => void;
  setEditState: () => void;
  editingCurrentField: boolean;
  onSave: () => void;
  loading: boolean;
  fieldKey: string;
};

type ActionProps = {
  editingCurrentField: boolean;
  openMdEditor: () => void;
  closeMdEditor: () => void;
  mdEditorOpen: boolean;
  clearMdValue: () => void;
  onSave: () => void;
  setEditState: () => void;
  loading: boolean;
};

const MdEditorCard: React.FC<Props> = (props) => {
  // for usage in entity editors
  const {
    title,
    mdEditorOpen,
    mdValue,
    clearMdValue,
    onMdChange,
    openMdEditor,
    closeMdEditor,
    setEditState,
    editingCurrentField,
    onSave,
    loading,
  } = props;

  return (
    <Card
      title={
        <Space>
          <Title level={5} style={{ marginBottom: -3 }}>
            {title}
          </Title>
        </Space>
      }
      bodyStyle={{
        padding: 12,
        minHeight: 50,
        height: "auto",
      }}
      style={{ marginBottom: 16 }}
      extra={
        <MdCardActions
          editingCurrentField={editingCurrentField}
          openMdEditor={openMdEditor}
          closeMdEditor={closeMdEditor}
          mdEditorOpen={mdEditorOpen}
          clearMdValue={clearMdValue}
          onSave={onSave}
          setEditState={setEditState}
          loading={loading}
        />
      }
    >
      <MarkdownEditor value={mdValue} onChange={onMdChange} containerStyle={{ height: 500 }} />
    </Card>
  );
};

export const MdCardActions: React.FC<ActionProps> = (props) => {
  const {
    editingCurrentField,
    openMdEditor,
    closeMdEditor,
    mdEditorOpen,
    clearMdValue,
    onSave,
    setEditState,
    loading,
  } = props;

  if (editingCurrentField) {
    return (
      <Space>
        {mdEditorOpen && (
          <Button onClick={clearMdValue} icon={<UndoOutlined />}>
            Clear Markdown
          </Button>
        )}
        <Button onClick={closeMdEditor} icon={<CloseCircleOutlined />} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={onSave} icon={<SaveOutlined />} loading={loading} type="primary">
          Save
        </Button>
      </Space>
    );
  }

  return (
    <Space>
      <Button onClick={openMdEditor} icon={<FileMarkdownOutlined />} disabled={loading}>
        Edit Markdown
      </Button>
      <Button onClick={setEditState} icon={<EditOutlined />} disabled={loading}>
        Edit
      </Button>
    </Space>
  );
};

export default MdEditorCard;
