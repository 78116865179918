export const initialEditorState = {
  formId: undefined,
  fieldId: undefined,
  questionType: undefined,
  showAnswers: false,
  document: undefined,
  fieldAnswers: undefined,
};

export const editorStateReducer = (state, action) => {
  switch (action.type) {
    case "INIT_DOCUMENT":
      console.log("%c INIT_DOCUMENT", "color:green", { actionState: action.state, state });

      return {
        ...state,
        ...action.state,
      };
    case "INIT_ANSWERS":
      console.log("%c INIT_ANSWERS", "color:green", { actionState: action.state, state });

      return {
        ...state,
        ...action.state,
      };
    case "SET_ASSIGNMENT":
      console.log("%c SET_ASSIGNMENT", "color:green", { action: action.type, state });
      return {
        ...state,
        ...action.state,
      };
    case "SET_VARIABLE_FIELDS":
      return {
        ...state,
        document: { ...state?.document, variableFields: action.variableFields },
      };
    case "RESET":
      console.log("%c RESET", "color:green", { action: action.type, state });
      if (state.document) {
        console.log("Document still exists");
        return {
          ...initialEditorState,
          document: state?.document,
        };
      }
      return initialEditorState;
    default:
      return state;
  }
};
