import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

import { Button } from "antd";

export const P4 = (props) => {
  const { document, state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const NSDMarking = {
    label: "Select Marking",
    value: "nss_status_determination_marking",
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "baseline", height: '100%' }}>
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'65%'} />
              <col span={1} />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={2}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={NSDMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                      4. NSS Status Determination <i>Refs: (n) & (o)</i>
                    </div>
                  </div>

                </th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'top' }}>

              <tr>
                <td className="label">
                  4A. Does the function, operation, or use of the system involve intelligence activities?
                </td>
                <td>
                  <div style={{
                    display: 'flex', rowGap: '4px', flexDirection: 'column',
                    alignItems: 'start', paddingLeft: 30
                  }}>
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4a_yes" label={<b>Yes</b>} />
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4a_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  4B. Does the function, operation, or use of the system involve cryptologic activities<br />
                  related to national security?
                </td>
                <td>
                  <div style={{
                    display: 'flex', rowGap: '4px', flexDirection: 'column',
                    alignItems: 'start', paddingLeft: 30
                  }}>
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4b_yes" label={<b>Yes</b>} />

                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4b_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  4C. Does the function, operation, or use of the system involve military command<br />
                  and control of military forces?
                </td>
                <td>
                  <div style={{
                    display: 'flex', rowGap: '4px', flexDirection: 'column',
                    alignItems: 'start', paddingLeft: 30
                  }}>
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4c_yes" label={<b>Yes</b>} />

                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4c_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  4D. Does the function, operation, or use of the system involve equipment that is an<br />
                  integral part of the weapon or weapons system?
                </td>
                <td>
                  <div style={{
                    display: 'flex', rowGap: '4px', flexDirection: 'column',
                    alignItems: 'start', paddingLeft: 30
                  }}>
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4d_yes" label={<b>Yes</b>} />

                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4d_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  4E. If the system is not used for routine administrative or business<br />
                  applications, is the system critical to the direct fulfillment of military or<br />
                  intelligence missions?
                </td>
                <td>
                  <div style={{
                    display: 'flex', rowGap: '4px', flexDirection: 'column',
                    alignItems: 'start', paddingLeft: 30
                  }}>
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4e_yes" label={<b>Yes</b>} />

                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4e_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  4F. Does the system store, process, or communicate classified information?
                </td>
                <td>
                  <div style={{
                    display: 'flex', rowGap: '4px', flexDirection: 'column',
                    alignItems: 'start', paddingLeft: 30
                  }}>
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4f_yes" label={<b>Yes</b>} />

                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4f_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  4G. Does the system process any information the unauthorized access, use, disclosure,<br />
                  disruption, modification, or destruction of which would have a debilitating impact on<br />
                  the mission of the DOD or an element of the Intelligence Community?
                </td>
                <td>
                  <div style={{
                    display: 'flex', rowGap: '4px', flexDirection: 'column',
                    alignItems: 'start', paddingLeft: 30
                  }}>
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4g_yes" label={<b>Yes</b>} />

                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4g_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  4H. Is the system designated as NSS per organizationally defined guidance<br />
                  but does not meet any of the above criteria? If yes, then an appropriate<br />
                  explanation must be provided.
                </td>
                <td>
                  <div style={{
                    display: 'flex', rowGap: '4px', flexDirection: 'column',
                    alignItems: 'start', paddingLeft: 30
                  }}>
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4h_yes" label={<b>Yes</b>} />

                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4h_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>
              <tr><td colSpan={2}><EditableField {...elementProps} fieldName="nss_status_determination_4h_text" style={{ minHeight: '100px' }} /></td></tr>


              <tr>
                <td className="label">
                  4I. Is this an NSS?<br />
                  <i style={{ fontWeight: 'normal' }}>Note 4: If any of the answers above is “Yes”, then the system is an NSS</i>
                </td>
                <td>
                  <div style={{
                    display: 'flex', rowGap: '4px', flexDirection: 'column',
                    alignItems: 'start', paddingLeft: 30
                  }}>
                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4i_yes" label={<b>Yes</b>} />

                    <EditableRadio {...elementProps} fieldName="nss_status_determination_4i_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            width: "80%",
            textAlign: "end",
            padding: 5,
            alignSelf: 'end',
            justifySelf: 'end'
          }}
        >
          <Button size="small" style={{ outline: '4px solid black', borderRadius: 0 }}>
            Reset Section 4
          </Button>
        </div>
      </div>
    </>
  );
};
