import { Link } from "react-router-dom";
import useAccessControl, { Roles } from "hooks/useAccessControl";
import useViewport from "hooks/useViewport";
import Layout from "./Layout";
import {
  ApartmentOutlined,
  ClusterOutlined,
  TeamOutlined,
  FileTextOutlined,
  BankOutlined,
  GroupOutlined,
  DatabaseOutlined,
  FileWordOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
import "styles/layout.css";
import useProtectedParams from "hooks/useProtectedParams";

const AdministrationLayout: React.FC = () => {
  const viewport = useViewport();
  const { RBAC } = useAccessControl();

  const items: Array<MenuItem> = [
    {
      type: "group",
      label: !viewport.layout.collapsed ? "Administration" : "",
      key: "administrationMenu",
      children: [
        RBAC([Roles.GlobalAdmin, Roles.CsarAdvisor, Roles.OrganizationAdmin]) && {
          label: <Link to="/administration/users">Users</Link>,
          key: "users",
          icon: <TeamOutlined />,
        },
        RBAC([Roles.GlobalAdmin, Roles.CsarAdvisor, Roles.OrganizationAdmin]) && {
          label: <Link to="/administration/organizations">Organizations</Link>,
          key: "organizations",
          icon: <BankOutlined />,
        },
        RBAC([Roles.GlobalAdmin, Roles.CsarAdvisor]) && {
          label: <Link to="/administration/applications">Applications</Link>,
          key: "applications",
          icon: <ApartmentOutlined />,
        },
        RBAC([Roles.GlobalAdmin, Roles.WorkflowAdmin]) && {
          label: !viewport.layout.collapsed ? "Workflow Management" : <FileWordOutlined />,
          key: "workflowsMenu",
          style: { marginTop: 12 },
          icon: <FileWordOutlined />,
          children: [
            {
              label: <Link to="/administration/workflow-editor">Workflow Editor</Link>,
              key: "workflow-editor",
              icon: <ClusterOutlined />,
            },
            {
              label: <Link to="/administration/question-groups">Question Groups</Link>,
              key: "question-groups",
              icon: <GroupOutlined />,
            },
            {
              label: <Link to="/administration/question-repository">Question Repository</Link>,
              key: "question-repository",
              icon: <DatabaseOutlined />,
            },
            {
              label: <Link to="/administration/answer-conditions">Answer Conditions</Link>,
              key: "answer-conditions",
              icon: <BranchesOutlined />,
            },
          ],
        },
        RBAC([Roles.GlobalAdmin]) && {
          label: <Link to="/administration/document-editor">Documents</Link>,
          key: "document-editor",
          icon: <FileTextOutlined />,
        },
      ],
    },
  ];

  return <Layout items={items} />;
};

export default AdministrationLayout;
