import {
  ClusterOutlined,
  DiffOutlined,
  HeatMapOutlined,
  LinkOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import { ConditionAnswerOption } from "types";
import { AnswerConditionEnums } from "api/enums";

export const AdditionalFieldsTag = () => {
  return (
    <Tag icon={<MessageOutlined />} color="magenta">
      Additional Field
    </Tag>
  );
};

export const LinkedQuestionsTag = () => {
  return (
    <Tag icon={<LinkOutlined />} color="blue">
      Linked Question
    </Tag>
  );
};

export const DocumentFilterTag = () => (
  <Tag icon={<DiffOutlined />} color="green">
    {AnswerConditionEnums[2].label}
  </Tag>
);

export const ModuleFilterTag = () => (
  <Tag icon={<ClusterOutlined />} color="orange">
    {AnswerConditionEnums[3].label}
  </Tag>
);

const PoamEntryTag = () => (
  <Tag icon={<ClusterOutlined />} color="purple">
    {AnswerConditionEnums[4].label}
  </Tag>
);

const TieredConditionTag = () => (
  <Tag icon={<HeatMapOutlined />} color="geekblue">
    {AnswerConditionEnums[5].label}
  </Tag>
);

export const mapTagToType = (conditionType: ConditionAnswerOption) => {
  switch (conditionType) {
    case ConditionAnswerOption.AdditionalField:
      return <AdditionalFieldsTag />;

    case ConditionAnswerOption.AdditionalQuestions:
      return <LinkedQuestionsTag />;

    case ConditionAnswerOption.DocumentFilter:
      return <DocumentFilterTag />;

    case ConditionAnswerOption.ModuleFilter:
      return <ModuleFilterTag />;

    case ConditionAnswerOption.PoamEntry:
      return <PoamEntryTag />;

    case ConditionAnswerOption.TieredCondition:
      return <TieredConditionTag />;

    default:
      return conditionType;
  }
};
