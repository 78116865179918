import { Statistic, StatisticProps } from "antd";

interface StatProps extends StatisticProps {
  title: string;
  value?: string | number;
}

const HeaderStat: React.FC<StatProps> = ({ title, value, ...rest }) => {
  return <Statistic title={title} value={value} valueStyle={{ fontSize: 18 }} {...rest} />;
};

export default HeaderStat;
