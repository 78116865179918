import { gql } from "graphql-tag";

export const Question = {
  WorkflowSearchView: () => gql`
    query getWorkflowSearchView($filter: QuestionFilterInputType) {
      allQuestions(filter: $filter) {
        id
        question
        questionType
        order
        helpText
        answers {
          id
          answer
        }
        categoryId
        categoryOrder
        category {
          id
          name
        }
        groupId
        groupOrder
        group {
          id
          header
          order
          # description
          # groupType
          # categoryId
        }
        # rmfActionPaths {
        #   path
        #   questionId
        #   answers {
        #     answerId
        #     textAnswer
        #   }
        # }
      }
    }
  `,
  GetFullQuestionary: () => gql`
    query getFullQuestionary($filter: QuestionFilterInputType) {
      allQuestions(filter: $filter) {
        id
        question
        questionType
        order
        categoryOrder
        variableName
        helpText
        answers {
          id
          answer
          answerType
          additionalFieldType
          riskIncrease
          timeIncreaseInDays
        }
        defaultAnswers {
          id
          path
          answerId
          questionId
          textAnswer
          answer {
            id
            answer
            answerType
            additionalFieldType
            riskIncrease
            timeIncreaseInDays
          }
        }
        categoryId
        categoryOrder
        category {
          id
          name
          order
        }
        groupId
        groupOrder
        group {
          id
          header
          order
          description
          groupType
          categoryId
        }
        rmfActionPaths {
          path
          questionId
          answers {
            answerId
            textAnswer
          }
        }
      }
    }
  `,
  GetBasicList: () => gql`
    query allQuestions($filter: QuestionFilterInputType) {
      allQuestions(filter: $filter) {
        id
        question
      }
    }
  `,
  QuestionSelectionTable: () => gql`
    query questionSelectionTable($filter: QuestionFilterInputType) {
      allQuestions(filter: $filter) {
        id
        question
        questionType
        helpText
        gridCol
        answers {
          id
          answer
          answerType
          additionalFieldType
          # riskIncrease
          # timeIncreaseInDays
        }
        # defaultAnswers {
        #   id
        #   path
        #   answerId
        #   questionId
        #   textAnswer
        #   answer {
        #     id
        #     answer
        #     answerType
        #     additionalFieldType
        #     riskIncrease
        #     timeIncreaseInDays
        #   }
        # }
        categoryId
        # categoryOrder
        category {
          id
          name
          module {
            id
            name
          }
          # order
        }
        groupId
        # groupOrder
        group {
          id
          header
          order
          description
          groupType
          categoryId
        }
        # rmfActionPaths {
        #   path
        #   questionId
        #   answers {
        #     answerId
        #     textAnswer
        #   }
        # }
      }
    }
  `,
  // GetOne: () => gql`
  //   query getQuestion($id: String!) {
  //     getQuestion(id: $id) {
  //       id
  //       question
  //       questionType
  //       order
  //       variableName
  //       helpText
  //       groupId
  //       groupOrder
  //       group {
  //         id
  //         header
  //         order
  //         description
  //         groupType
  //         categoryId
  //       }
  //       answers {
  //         id

  //     }
  //   }
  // `,

  GetAllOrphanedQuestions: () => gql`
    query allOrphanQuestions {
      allOrphanQuestions {
        id
        question
        questionType
        order
        variableName
        helpText
        groupId
        groupOrder
        group {
          id
          header
          order
          description
          groupType
          categoryId
        }
        answers {
          id
          answer
          riskIncrease
          timeIncreaseInDays
        }
        rmfActionPaths {
          path
          questionId
          answers {
            answerId
            textAnswer
          }
        }
      }
    }
  `,
  Create: () => gql`
    mutation createQuestion(
      $answers: [AnswerChoiceInput]!
      $categoryId: String
      $order: Int
      $question: String!
      $questionType: QuestionType!
      $variableName: String!
      $helpText: String
      $rmfActionPath: [RMFActionPath]
    ) {
      createQuestion(
        answers: $answers
        categoryId: $categoryId
        order: $order
        question: $question
        questionType: $questionType
        variableName: $variableName
        helpText: $helpText
        rmfActionPath: $rmfActionPath
      ) {
        id
        variableName
        questionType
        question
        order
        helpText
        groupId
        groupOrder
        rmfActionPaths {
          path
          questionId
          answers {
            answerId
            textAnswer
          }
        }
      }
    }
  `,
  Update: () => gql`
    mutation updateQuestion(
      $id: String!
      $categoryId: String
      $groupId: String
      $groupOrder: Int
      $helpText: String
      $order: Int
      $question: String
      $questionType: QuestionType
      $variableName: String
      $rmfActionPath: [RMFActionPath]
    ) {
      updateQuestion(
        id: $id
        categoryId: $categoryId
        groupId: $groupId
        groupOrder: $groupOrder
        helpText: $helpText
        order: $order
        question: $question
        questionType: $questionType
        variableName: $variableName
        rmfActionPath: $rmfActionPath
      ) {
        id
        variableName
        questionType
        question
        order
        helpText
        groupId
        groupOrder
        rmfActionPaths {
          path
          questionId
          answers {
            answerId
            textAnswer
          }
        }
      }
    }
  `,
  UpdateRmfActionPathAnswers: () => gql`
    mutation createDefaultAnswer($id: String!, $paths: [DefaultAnswerInputObject]!) {
      createDefaultAnswer(questionId: $id, paths: $paths) {
        id
        variableName
        questionType
        question
        order
        helpText
        groupId
        groupOrder
        rmfActionPaths {
          path
          questionId
          answers {
            answerId
            textAnswer
          }
        }
      }
    }
  `,
  UpdateQuestionAnswer: () => gql`
    mutation updateQuestionAnswerChoice(
      $id: String!
      $answer: String
      $answerType: ConditionAnswerOption
      $additionalFieldType: AdditionalFieldType
      $riskIncrease: RiskLevel
      $timeIncreaseInDays: Int
    ) {
      updateQuestionAnswerChoice(
        id: $id
        answer: $answer
        answerType: $answerType
        additionalFieldType: $additionalFieldType
        riskIncrease: $riskIncrease
        timeIncreaseInDays: $timeIncreaseInDays
      ) {
        id
        answer
        answerType
        additionalFieldType
        riskIncrease
        timeIncreaseInDays
      }
    }
  `,
  UpdateQuestionAnswerChoices: () => gql`
    mutation replaceQuestionAnswerChoices(
      $answerChoices: [AnswerChoiceInput]!
      $questionId: String!
    ) {
      replaceQuestionAnswerChoices(answerChoices: $answerChoices, questionId: $questionId) {
        id
        variableName
        questionType
        question
        order
        helpText
        groupId
        groupOrder
        answers {
          id
          answer
          answerType
          additionalFieldType
          riskIncrease
          timeIncreaseInDays
        }
      }
    }
  `,
  UpdateMultiple: () => gql`
    mutation bulkUpdateOrder($items: [NewOrderInput]) {
      bulkUpdateOrder(items: $items)
    }
  `,
  Delete: () => gql`
    mutation deleteQuestion($id: String!) {
      deleteQuestion(id: $id) {
        deletedId
      }
    }
  `,
};
