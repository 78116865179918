import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  global?: boolean;
  extra?: any;
}

const LoadingSpinner: React.FC<Props> = ({ global = false, extra }) => {
  return (
    <div className={global ? "global-loading" : "local-loading"}>
      <LoadingOutlined spin />
      {global && <div style={{ marginTop: 20 }}>{extra}</div>}
    </div>
  );
};

export default LoadingSpinner;
