import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";
import { Space } from "antd";

export const P10 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const PMarking = {
    label: "Select Marking",
    value: "privacy_marking",
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'60%'} />
              <col span={1} />
            </colgroup>

            <thead>
              <tr>
                <th className="title" colSpan={3} style={{ borderBottom: 'none' }}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={PMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                      13. Privacy <i>Refs: (x) through (ad)</i>
                    </div>
                  </div>

                </th>
              </tr>
              <tr>
                <th className="title text" colSpan={2} style={{ borderTop: 'none' }}>
                  <i style={{ fontWeight: 2, textAlign: 'start', width: '100%' }}>Note 14: For assistance with completing this section, please contact your Base Privacy Manager.</i>
                </th>
              </tr>
            </thead>

            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td className="label" style={{ fontWeight: 'normal' }}>
                  <b>13A. Will this System process, store, or transmit PII/PHI?</b>
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="13a_privacy_yes"
                      label={<><b>Yes</b> (PIA (DD Form 2930) required)</>} />
                    <EditableRadio {...elementProps} fieldName="13a_privacy_no"
                      label={<><b>No</b> (Skip to Section 14)</>}
                    />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ fontWeight: 'normal' }}>
                  <b>13B. Is the System an NSS?</b><br />
                  <i>
                    Refs: (n) & (o)<br />
                    Note 15: Auto-populated from Section 4
                  </i>
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="13b_privacy_yes"
                      label={<><b>Yes</b> (CNSSI Privacy Overlay required)</>} />
                    <EditableRadio {...elementProps} fieldName="13b_privacy_no"
                      label={<><b>No</b></>}
                    />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ fontWeight: 'normal' }}>
                  <b>
                    13C. Is the PII maintained in a system that meets the statutory<br />
                    definition of a “system of records” under the Privacy Act?<br />
                  </b>
                  <i>
                    Refs: (e) & (ab)
                  </i>
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="13c_privacy_yes"
                      label={<><b>Yes</b> (Provide System of Records Notice (SORN))</>} />
                    <EditableRadio {...elementProps} fieldName="13c_privacy_no"
                      label={<><b>No</b></>}
                      extra={<EditableField {...elementProps} fieldName="13c_privacy_no_other" style={{ marginLeft: '10%' }} />}
                      style={{ display: 'flex' }}
                    />
                  </Space>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'33%'} />
              <col span={1} width={'33%'} />
              <col span={1} width={'33%'} />
            </colgroup>

            <thead>
              <tr>
                <th className="title text" colSpan={3} style={{ borderTop: 'none' }}>
                  13D. What PII will be collected (a data element alone or in combination that can uniquely identify an individual)? (Select all that apply)
                </th>
              </tr>
            </thead>

            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td >
                  <div style={{ marginLeft: '10px', marginTop: '10px', marginBottom: '10px'}}>
                    <Space direction="vertical">
                      <b style={{ marginLeft: '13px' }}>Could be LOW</b>
                      <EditableCheckbox {...elementProps} fieldName="13d_low_dod_id_number" label={<>DOD ID Number (EDIPI)</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_low_employment_information" label={<>Employment Information</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_low_cell_phone" label={<>Home / Cell Phone</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_low_mailing_address" label={<>Mailing/Home Address</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_low_official_duty_address" label={<>Official Duty Address</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_low_personal_email_address" label={<>Personal Email Address</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_low_photo" label={<>Photo</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_low_position" label={<>Position / Title Rank / Grade</>} />
                    </Space>

                  </div>
                </td>

                <td>
                  <div style={{ marginLeft: '10px', marginTop: '10px', marginBottom: '10px' }}>
                    <Space direction="vertical">
                      <b style={{ marginLeft: '13px' }}>Could be MODERATE</b>
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_birth_date" label={<>Birth Date</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_birth_place" label={<>Birth Place</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_child_information" label={<>Child Information</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_citizenship" label={<>Citizenship</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_drivers_license" label={<>Driver’s License</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_emergency_contact" label={<>Emergency Contact</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_financial_information" label={<>Financial Information</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_gender" label={<>Gender / Gender Identification</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_legal_status" label={<>Legal Status</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_mothers_middle_maiden_name" label={<>Mother’s Middle / Maiden Name</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_other_id_number" label={<>Other ID Number</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_photo_ephemeris_data" label={<>Photo with ephemeris data</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_moderate_race" label={<>Race / Ethnicity</>} />
                    </Space>
                  </div>
                </td>

                <td>
                  <div style={{ marginLeft: '10px', marginTop: '10px', marginBottom: '10px' }}>
                    <Space direction="vertical">
                      <b style={{ marginLeft: '13px' }}>Could be <u>HIGH</u></b>
                      <EditableCheckbox {...elementProps} fieldName="13d_high_law_enforcement_information" label={<>Law Enforcement Information</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_high_legal_records" label={<>Legal Records</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_high_medical_information" label={<>Medical Information</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_high_passport_number" label={<>Passport Number</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_high_protected_health_information" label={<>Protected Health Information (PHI)</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_high_security_information" label={<>Security Information</>} />
                      <EditableCheckbox {...elementProps} fieldName="13d_high_social_security_number" label={<>Social Security Number (SSN)</>} />
                    </Space>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};
