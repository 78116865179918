import { useEffect, useState } from "react";
import { message } from "antd";
import { ApolloError } from "@apollo/client";
import { QuestionNode, UpdateQuestionMutation } from "types";
import { FieldKeysEnum as FieldKeys, QuestionEditorState } from "./reducer";
import useEditorLoading from "hooks/useEditorLoading";
import HelpTextEditor from "./HelpTextEditor";
import QuestionTextEditor from "./QuestionTextEditor";
import { SaveStatusEnum as SaveStatus } from "components/Administration/Workflow/Editor/Shared/types";

type Props = {
  setEditState: any;
  setSaveState: any;
  loading: Loading;
  state: QuestionEditorState;
  question: QuestionNode;
  refetchQuestion: any;
  updateQuestion: any;
};

const QuestionInformation: React.FC<Props> = (props) => {
  const {
    loading,
    state,
    question,
    refetchQuestion,
    updateQuestion,
    setSaveState,
    setEditState,
  } = props;

  const [questionValues, setQuestionValues] = useState({
    questionText: "",
    helpText: "",
    groupHeader: "",
  });

  const [mdEditorOpen, setMdEditorOpen] = useState(false);

  const questionTextLoading = useEditorLoading(FieldKeys.questionText, state, loading);
  const helpTextLoading = useEditorLoading(FieldKeys.helpText, state, loading);

  useEffect(() => {
    if (question.id === "123") return;
    // if (!question.helpText && !question.question) return;
    const vals = {
      questionText: question.question ?? "",
      helpText: question.helpText ?? "",
      groupHeader: question.group?.header ?? "",
    };

    console.log("%c!syncVals useEffect", "color:purple", { vals, question });

    setQuestionValues(vals);
  }, [question.id]);

  const handleQuestionTextMd = (value: string) => {
    console.log("%c!handleQuestionTextMd", "color:purple", { value, questionValues });
    setQuestionValues({
      ...questionValues,
      questionText: value,
    });
  };

  const handleHelpTextMd = (value: string) => {
    console.log("%c!handleHelpTextMd", "color:purple", { value, questionValues });
    setQuestionValues({
      ...questionValues,
      helpText: value,
    });
  };

  const clearMarkdownValue = (fieldKey: FieldKeys) => {
    console.log("%c!clearMarkdownValue", "color:purple");

    setQuestionValues({
      ...questionValues,
      [fieldKey]: "",
    });
  };

  const openMdEditor = (fieldKey: FieldKeys) => {
    console.log("%c!openMdEditor", "color:purple", { questionValues });
    setMdEditorOpen(true);
    setEditState(fieldKey);
  };

  const closeMdEditor = (fieldKey: FieldKeys) => {
    setMdEditorOpen(false);
    setEditState(fieldKey);

    if (fieldKey === FieldKeys.questionText) {
      if (!questionValues.questionText && question.question) {
        setQuestionValues({
          ...questionValues,
          questionText: question.question,
        });
      }
    }

    if (fieldKey === FieldKeys.helpText) {
      if (!questionValues.helpText && question.helpText) {
        setQuestionValues({
          ...questionValues,
          helpText: question.helpText,
        });
      }
    }
  };

  const questionText = question.question;
  const helpText = question.helpText;

  const handleQuestionValuesChange = (value: string, fieldKey: string) => {
    console.log("%c!handleQuestionValsChange", "color:magenta");
    console.log(`Field: ${fieldKey} | value: ${value}`);
    setQuestionValues({
      ...questionValues,
      [fieldKey]: value,
    });
  };

  const updateQuestionInfo = (fieldKey: FieldKeys) => {
    setSaveState(fieldKey, SaveStatus.Loading);
    setEditState(fieldKey);
    if (mdEditorOpen) {
      setMdEditorOpen(false);
    }
    updateQuestion({
      variables: {
        id: question.id,
        question: questionValues.questionText,
        helpText: questionValues.helpText,
      },
      onCompleted: (res: UpdateQuestionMutation) => {
        console.log(res);
        setSaveState(fieldKey, SaveStatus.Success);
        // refetchQuestion();
      },
      onError: (error: ApolloError) => {
        setSaveState(fieldKey, SaveStatus.Error);
        console.log(error.message);
        message.error(error.message, 5);
      },
    });
  };

  useEffect(() => {
    console.log("%cQuestionValsStateInQuestionInfo", "color:purple", { questionValues });
  }, [questionValues]);

  return (
    <>
      <QuestionTextEditor
        title="Question Text"
        textValue={questionText}
        fieldKey={FieldKeys.questionText}
        mdEditorOpen={mdEditorOpen}
        mdValue={questionValues.questionText}
        clearMdValue={() => clearMarkdownValue(FieldKeys.questionText)}
        onMdChange={handleQuestionTextMd}
        openMdEditor={() => openMdEditor(FieldKeys.questionText)}
        closeMdEditor={() => closeMdEditor(FieldKeys.questionText)}
        setEditState={() => setEditState(FieldKeys.questionText)}
        editingCurrentField={state[FieldKeys.questionText].editActive}
        onSave={() => updateQuestionInfo(FieldKeys.questionText)}
        renderSaveStatus={state[FieldKeys.questionText].saveStatus !== SaveStatus.Default}
        saveStatus={state[FieldKeys.questionText].saveStatus}
        loading={questionTextLoading}
        formValues={questionValues}
        onValuesChange={handleQuestionValuesChange}
      />
      <HelpTextEditor
        title="Help Text"
        textValue={helpText}
        fieldKey={FieldKeys.helpText}
        mdEditorOpen={mdEditorOpen}
        mdValue={questionValues.helpText}
        clearMdValue={() => clearMarkdownValue(FieldKeys.helpText)}
        onMdChange={handleHelpTextMd}
        openMdEditor={() => openMdEditor(FieldKeys.helpText)}
        closeMdEditor={() => closeMdEditor(FieldKeys.helpText)}
        setEditState={() => setEditState(FieldKeys.helpText)}
        editingCurrentField={state[FieldKeys.helpText].editActive}
        onSave={() => updateQuestionInfo(FieldKeys.helpText)}
        renderSaveStatus={state[FieldKeys.helpText].saveStatus !== SaveStatus.Default}
        saveStatus={state[FieldKeys.helpText].saveStatus}
        loading={helpTextLoading}
        formValues={questionValues}
        onValuesChange={handleQuestionValuesChange}
      />
    </>
  );
};

export default QuestionInformation;
