import { Drawer } from "antd";

type Props = {
  state: IntegrationState;
  onClose: () => void;
};

const DocsDrawer: React.FC<Props> = ({ state, onClose }) => {
  const DynamicSetupComponent = state.integration?.docs;

  return (
    <Drawer
      title={`Setup ${state.integration?.name}`}
      visible={state.open}
      onClose={onClose}
      footer={null}
    >
      <DynamicSetupComponent />
    </Drawer>
  );
};

export default DocsDrawer;
