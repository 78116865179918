import { createContext, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import useViewport from "hooks/useViewport";
import Layout from "./Layout";
import { SkeletonMenuItem } from "components/Common/Skeletons/CustomSkeletons";
import {
  ClusterOutlined,
  BookOutlined,
  FileTextOutlined,
  GlobalOutlined,
  HomeOutlined,
  SecurityScanOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import useApplication from "components/Applicant/Hooks/useApplication";
import { ApplicationNode, ApplicationQuestionModuleNode } from "types";
import { validate as uuidValidate } from "uuid";
import "styles/layout.css";
import "styles/application.css";
import useProtectedParams from "hooks/useProtectedParams";

export interface IApplicationContext {
  application: ApplicationNode | null;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
}
export const initialApplicationContext = {
  application: null,
  loading: true,
  error: undefined,
  refetch: () => {},
};

export const ApplicationContext = createContext<IApplicationContext>(
  initialApplicationContext,
);
export const useApplicationContext = () => useContext(ApplicationContext);

const ApplicantLayout: React.FC = () => {
  const viewport = useViewport();
  const { applicationId } = useProtectedParams();

  const { application, loading, error, refetch } = useApplication(applicationId!);

  const modules: Array<ApplicationQuestionModuleNode> = application?.questionModules || [];

  const context: IApplicationContext = {
    application,
    loading,
    error,
    refetch,
  };

  const items: Array<MenuItem> = [
    {
      type: "group",
      label: !viewport.layout.collapsed ? "General" : "",
      key: "general",
      children: [
        {
          label: <Link to="/home">Home</Link>,
          key: "home",
          icon: <HomeOutlined />,
        },
        {
          label: <Link to="/cyber-plans">Cyber Plans</Link>,
          key: "cyber-plans",
          icon: <BookOutlined />,
        },
      ],
    },
    {
      type: "group",
      label: !viewport.layout.collapsed ? "Application" : "",
      key: "applicationMenu",
      children: [
        {
          label: (
            <Link
              to={`/workflow/${applicationId}/`}
              state={{ applicationName: application?.name, key: "applicationDashboard" }}
            >
              Dashboard
            </Link>
          ),
          key: "applicationDashboard",
          icon: <GlobalOutlined />,
        },
        error
          ? null
          : {
              label: "Modules",
              key: "modules",
              icon: <ClusterOutlined />,
              children: loading
                ? skeletonList
                : modules?.map((mod: any) => ({
                    label: (
                      <Link
                        to={`/workflow/${applicationId}/${mod.id}`}
                        state={{
                          applicationName: application?.name,
                          moduleName: mod.name,
                          key: mod.name,
                        }}
                      >
                        {mod.name}
                      </Link>
                    ),
                    key: mod.name,
                  })),
            },
        {
          label: (
            <Link
              to={`/workflow/${applicationId}/documents`}
              state={{ applicationName: application?.name }}
            >
              Documents
            </Link>
          ),
          key: "documents",
          icon: <FileTextOutlined />,
        },
        // * removed for Prod launch, routes left in tact for admin easteregg usage (by request evans)
        // {
        //   label: <Link to={`/workflow/${applicationId}/integrations`}>Integrations</Link>,
        //   key: "integrations",
        //   icon: <ApiOutlined />,
        // },
        // {
        //   label: (
        //     <Link to={`/workflow/${applicationId}/security-findings`}>Security Findings</Link>
        //   ),
        //   key: "security-findings",
        //   icon: <SecurityScanOutlined />,
        // },
      ],
    },
  ];

  return (
    <ApplicationContext.Provider value={context}>
      <Layout items={items} />
    </ApplicationContext.Provider>
  );
};

export default ApplicantLayout;

const skeletonList: Array<MenuItem> = [
  {
    key: 1,
    label: <SkeletonMenuItem />,
  },
];
