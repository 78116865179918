import MDEditor, { commands } from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import "./styles.css";

// todo: fix styling in toolbar, active/ selected highlight sizing etc

interface Props {
  value: string;
  onChange: (value: string, arg2?: any) => void;
  clearValue?: () => void;
  theme?: "light" | "dark";
  containerStyle?: any;
}

const MarkdownEditor: React.FC<Props> = (props) => {
  const {
    value,
    onChange = () => {},
    theme = "light",
    clearValue = () => {},
    containerStyle,
    ...rest
  } = props;

  console.log({ mdValue: value });

  const onValueChange = (value?: string) => {
    if (!value) return;
    console.log({ mdValChange: value });
    onChange(value);
  };

  return (
    <div style={{ height: "100%", ...containerStyle }}>
      <MDEditor
        className="editor-pane"
        value={value}
        onChange={onValueChange}
        data-color-mode={theme}
        overflow={false}
        previewOptions={{
          rehypePlugins: [rehypeSanitize],
        }}
        {...rest}
      />
    </div>
  );
};

MarkdownEditor.defaultProps = {
  onChange() {},
};

const testMarkdown =
  "| Syntax      | Description |\n| ----------- | ----------- |\n| Header      | Title       |\n| Paragraph   | Text        |";

export default MarkdownEditor;
