import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

import P11D1 from "../Assets/P11D1.png";

export const P11 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <>
      <div
        className="doc_pcil"
        style={{
          width: "95%",
          fontSize: 12,
        }}
      >
        <div style={{ paddingLeft: 80 }}>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <EditableRadio {...elementProps} fieldName="11_access_location_pii_low" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>LOW</span>
            </div>
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="11_access_location_pii_moderate"
              />
              <span style={{ marginLeft: 10, fontSize: 14 }}>MODERATE</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="11_access_location_pii_high" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>HIGH</span>
            </div>
          </div>
          <br />
          <b>Provide justification:</b>
          <div>
            <EditableField
              {...elementProps}
              fieldName="11_access_location_pii_text"
              style={{ width: "100%", height: 100 }}
            />
          </div>
          <br />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <div>
            <b style={{ fontSize: 16, paddingRight: 10 }}>FACTOR 6 - CONTEXT OF USE</b>
          </div>
          <img src={P11D1} alt="P11D1" style={{ width: "100%" }} />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>
            Factor 6. Select Context of Use impact value
          </b>
        </div>

        <div style={{ paddingLeft: 80 }}>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <EditableRadio {...elementProps} fieldName="11_context_of_use_low" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>LOW</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="11_context_of_use_moderate" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>MODERATE</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="11_context_of_use_high" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>HIGH</span>
            </div>
          </div>
          <br />
          <b>Provide justification:</b>
          <div>
            <EditableField
              {...elementProps}
              fieldName="11_context_of_use_text"
              style={{ width: "100%", height: 100 }}
            />
          </div>
          <br />
        </div>
        <div style={{ paddingLeft: 100 }}>
          <div>
            <b style={{ fontSize: 16, paddingRight: 10 }}>2.6.3</b>
            <b style={{ fontSize: 16 }}>
              <u>STEP 3. DETERMINE PII CONFIDENTIALITY IMPACT LEVEL (PCIL) VALUE</u>
            </b>
          </div>
        </div>

        <div style={{ paddingLeft: 40 }}>
          <p style={{ fontSize: 16, display: "flex" }}>
            <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
            <b style={{ flex: 1 }}>
              <i>
                Use the following table to roll up the previous answers from Factors 1 through
                6. Enter an “X” in the Low, Moderate, or High column for each row. Use these
                values to determine the PII Confidentiality impact level (PCIL) value.
              </i>
            </b>
          </p>
        </div>

        <br />
        <br />

        <div style={{ paddingLeft: 40 }}>
          <span style={{ fontSize: 8 }}>10 </span>Ibid
        </div>
      </div>
    </>
  );
};
