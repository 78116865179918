import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { message } from "antd";
import { client } from "components/Providers/WithApollo";
import Api, { Fragments } from "api";
import { AllTheQuestionGroupsQuery, QuestionGroupNode, QuestionNode } from "types";

/**
 * @description: retrieve question group from cache if present, otherwise query server
 * @param id: string - groupId
 * @returns QuestionGroupNode
 */

type callback = (arg: any) => void;

export const useQuestionGroup = (id?: string, callback: callback = () => {}) => {
  const [group, setGroup] = useState<QuestionGroupNode | any>();
  const [queryGroup, { loading, error, refetch: refetchGroup }] =
    useLazyQuery<AllTheQuestionGroupsQuery>(Api.QGroup.getAllTheQuestionGroups(), {
      variables: {
        filter: {
          id: id,
        },
      },
      onCompleted: (res) => {
        const questionGroup = res.allQuestionGroups[0];
        console.log({ useQuestionGroup: questionGroup });
        setGroup(questionGroup);
        callback(questionGroup);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 5);
        refetchGroup();
      },
    });

  function update() {
    console.log("%cupdate called!", "color:blue");
    getGroup();
  }

  function getGroup() {
    try {
      const cachedGroup = client.readFragment({
        id: `QuestionGroupNode:${id}`,
        fragment: Fragments.QuestionGroup.group,
        fragmentName: "QuestionGroup",
      });

      const cachedQuestions = client.readFragment({
        id: `QuestionGroupNode:${id}`,
        fragment: Fragments.QuestionGroup.groupQuestions,
        fragmentName: "QuestionGroupQuestions",
      });
      if (cachedGroup && cachedQuestions?.questions?.length > 0) {
        const questions: QuestionNode[] = cachedQuestions.questions;
        const clone: QuestionGroupNode = {
          ...cachedGroup,
          questions,
        };

        console.log({ cachedGroupFinal: clone });
        setGroup(clone);
        callback(clone);
      } else {
        queryGroup();
        console.log("%cqueried for questionGroup!", "color:blue");
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getGroup();
    // eslint-disable-next-line
  }, []);

  return { group, loading, error, refetchGroup, update };
};
