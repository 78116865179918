import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { AlignedEditableCheckbox, AlignedEditableRadio } from "..";
import { Footer } from "./P2";

export const P3 = (props) => {
  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <>
      <div
        className="ao-document"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 40,
        }}
      >
        <div className="attachment-2">
          <div style={{ width: 100 }}>
            <h1
              style={{
                textDecoration: "underline",
                fontWeight: "bolder",
                fontSize: 12,
                marginBottom: 16,
              }}
            >
              Attachment 2
            </h1>
          </div>
          <div style={{ width: 110 }}>
            <h1
              style={{
                fontWeight: "bolder",
                fontSize: 12,
                marginBottom: 16,
              }}
            >
              Body of Evidence
            </h1>
          </div>
        </div>

        <div style={{ width: "75%", marginBottom: 16 }}>
          <div>
            The Body of Evidence referenced below, <strong>&lt;(1) through (7)&gt;</strong> are
            key artifacts that supported this authorization. At the program's discretion, the
            complete package of evidence may be requested through{" "}
            <FieldWrapper styles={{ width: 140 }}>
              <EditableField {...elementProps} fieldName="3_program_poc" />
            </FieldWrapper>
          </div>
        </div>

        <div style={{ marginBottom: 16, width: "100%", paddingLeft: 85 }}>
          <p>Example Only -- List the appropriate references.</p>
        </div>

        <div style={{ width: "100%", paddingLeft: 90 }}>
          <ol>
            <li>AO Determination Brief</li>
            <li>CRA Risk Recommendation Memo</li>
            <li>Risk Assessment Report</li>
            <li>SSP/CONOPs</li>
            <li>STIGs</li>
            <li>ACAS Scans</li>
            <li>POA&M</li>
            <li>ConMon Plan</li>
            <li>Incident Response</li>
            <li>Cyber Technical Order</li>
          </ol>
        </div>

        <Footer {...props} />
      </div>
    </>
  );
};

export const FieldWrapper = ({ height, children, styles }) => {
  return (
    <div
      style={{
        background: "#AAD3EE",
        width: 200,
        maxWidth: "100%",
        border: "1px solid #000000",
        height: height,
        marginBottom: 12,
        ...styles,
      }}
    >
      {children}
    </div>
  );
};
