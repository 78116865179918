export const cyberPlans = [
  {
    id: "DevSecOps Concept of Operations",
    name: "DevSecOps Concept of Operations",
    fileName: "ovl_dsop_conops_april_2021.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "DevSecOps Concept of Operations Appendix A",
    name: "DevSecOps Concept of Operations Appendix A",
    fileName: "ovl_dsop_conops_appendix_a_april_2021.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "System Security Plan",
    name: "System Security Plan",
    fileName: "system_security_plan.xlsx",
    description: "Added May 1, 2023",
  },
  {
    id: "Acceptable Use Policy",
    name: "Acceptable Use Policy",
    fileName: "CSAR SystemAcronym_Acceptable Use Policy _mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Access Control Plan",
    name: "Access Control Plan",
    fileName: "CSAR SystemAcronym_Access Control Plan (AC)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Accountability, Audit and Risk Management Plan",
    name: "Accountability, Audit and Risk Management Plan",
    fileName:
      "CSAR SystemAcronym_Accountability, Audit and Risk Management Plan (AR)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Asset Management Policy",
    name: "Asset Management Policy",
    fileName: "CSAR SystemAcronym_Asset Management Policy _mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Audit and Accountability Plan",
    name: "Audit and Accountability Plan",
    fileName: "CSAR SystemAcronym_Audit and Accountability (AU)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Authority and Purpose Plan",
    name: "Authority and Purpose Plan",
    fileName: "CSAR SystemAcronym_Authority and Purpose Plan (AP)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Awareness and Training Plan",
    name: "Awareness and Training Plan",
    fileName: "CSAR SystemAcronym_Awareness and Training Plan (AT)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Configuration Control Board Charter",
    name: "Configuration Control Board Charter",
    fileName: "CSAR SystemAcronym_Configuration Control Board Charter_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Configuration Management Plan",
    name: "Configuration Management Plan",
    fileName: "CSAR SystemAcronym_Configuration Management (CM)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Contingency Plan",
    name: "Contingency Plan",
    fileName: "CSAR SystemAcronym_Contingency Plan (CP)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Continuous Monitoring Strategy",
    name: "Continuous Monitoring Strategy",
    fileName: "CSAR SystemAcronym_Continuous_Monitoring_Strategy_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Data Mininization and Retention Plan",
    name: "Data Mininization and Retention Plan",
    fileName: "CSAR SystemAcronym_Data Minimization and Retention Plan (DM)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Data Quality and Integrity",
    name: "Data Quality and Integrity",
    fileName: "CSAR SystemAcronym_Data Quality and Integrity (DI)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Identification and Authentication Plan",
    name: "Identification and Authentication Plan",
    fileName: "CSAR SystemAcronym_Identification and Authentication Plan (IA)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Incident Response Plan",
    name: "Incident Response Plan",
    fileName: "CSAR SystemAcronym_Incident Response Plan (IR)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Individual Participation and Redress Plan",
    name: "Individual Participation and Redress Plan",
    fileName: "CSAR SystemAcronym_Individual Participation and Redress (IP)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Maintenance Plan",
    name: "Maintenance Plan",
    fileName: "CSAR SystemAcronym_Maintenance Plan (MA)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Media Protection Plan",
    name: "Media Protection Plan",
    fileName: "CSAR SystemAcronym_Media Protection Plan (MP)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Personnel Security Plan",
    name: "Personnel Security Plan",
    fileName: "CSAR SystemAcronym_Personnel Security Plan (PS)_mmddyyyy.DOCX",
    description: "Added May 1, 2023",
  },
  {
    id: "Physical and Environmental Protection Plan",
    name: "Physical and Environmental Protection Plan",
    fileName: "CSAR SystemAcronym_Physical and Environmental Plan (PE)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Physical Security Resource Protection Policy",
    name: "Physical Security Resource Protection Policy",
    fileName: "CSAR SystemAcronym_Physical Security_Resource Protection Policy _mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Plan of Action and Milestones",
    name: "Plan of Action and Milestones",
    fileName: "CSAR SystemAcronym_Plan of Action and Milestones (POAM) _mmddyyyy.xlsm",
    description: "Added May 1, 2023",
  },
  {
    id: "Planning Plan",
    name: "Planning Plan",
    fileName: "CSAR SystemAcronym_Planning Plan (PL)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Program Management Plan",
    name: "Program Management Plan",
    fileName: "CSAR SystemAcronym_Program Management Plan (PM)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Risk Assessment Plan",
    name: "Risk Assessment Plan",
    fileName: "CSAR SystemAcronym_Risk Assessment Plan (RA)_mmddyyyy.DOCX",
    description: "Added May 1, 2023",
  },
  {
    id: "Rules of Behavior",
    name: "Rules of Behavior",
    fileName: "CSAR SystemAcronym_Rules of Behavior (RoB)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Security Assessment and Authorization Plan",
    name: "Security Assessment and Authorization Plan",
    fileName:
      "CSAR SystemAcronym_Security Assessment and Authorization Plan (CA)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Security Plan",
    name: "Security Plan",
    fileName: "CSAR SystemAcronym_Security Plan (SE)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Social Media Policy",
    name: "Social Media Policy",
    fileName: "CSAR SystemAcronym_Social Media Policy _mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "System and Communications Protection Plan",
    name: "System and Communications Protection Plan",
    fileName: "CSAR SystemAcronym_System and Communications Plan_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "System and Information Integrity Plan",
    name: "System and Information Integrity Plan",
    fileName: "CSAR SystemAcronym_System and Information Integrity (SI)_mmddyyyy.DOCX",
    description: "Added May 1, 2023",
  },
  {
    id: "System and Services Acquisition Plan",
    name: "System and Services Acquisition Plan",
    fileName: "CSAR SystemAcronym_System and Services Acquisition Plan (SA)_mmddyyyy.DOCX",
    description: "Added May 1, 2023",
  },
  {
    id: "Transparency Plan",
    name: "Transparency Plan",
    fileName: "CSAR SystemAcronym_Transparency Plan (TR)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Use Limitation Plan",
    name: "Use Limitation Plan",
    fileName: "CSAR SystemAcronym_Use Limitation Plan (UL)_mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Workplace Drug Policy",
    name: "Workplace Drug Policy",
    fileName: "CSAR SystemAcronym_Workplace Drug Policy _mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
  {
    id: "Workplace Security Plan",
    name: "Workplace Security Plan",
    fileName: "CSAR SystemAcronym_Workplace Security Plan _mmddyyyy.docx",
    description: "Added May 1, 2023",
  },
];
