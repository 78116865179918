import { useState, createContext, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useViewport from "hooks/useViewport";
import useQuestionnairePage, {
  QuestionnairePage,
} from "../../../../Hooks/useQuestionnairePage";
import { SkeletonWrapper } from "components/Common/Skeletons/SkeletonLoading";
import PageRenderer from "./Pages/PageRenderer";
import PageComplete from "./Components/PageComplete";
import PageControls from "./Components/PageControls";
import CommentsDrawer from "./Components/CommentsDrawer/CommentsDrawer";
import { CommentsDrawerState } from "./Components/CommentsDrawer/CommentsDrawer";

interface Props {
  categoryId: string;
  pageNumber: string;
  onPageChange: (page: QuestionnairePage | null) => any;
}

const QuestionnairePageContext = createContext<any>(null);

export const useQuestionnairePageContext = () => useContext(QuestionnairePageContext);

const Questionnaire: React.FC<Props> = ({ categoryId, pageNumber, onPageChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const viewport = useViewport();
  const pathname = location.pathname;

  const { page, loading, refetch, updateQuestionState, incrementTotalComments } =
    useQuestionnairePage(categoryId, pageNumber);

  useEffect(() => {
    onPageChange(page);
  }, [page]);

  const [commentsDrawer, setCommentsDrawer] = useState<CommentsDrawerState>({
    open: false,
    question: null,
  });

  const handlePaginationChange = (page: number) => {
    console.log("%cNew Page", "color: steelblue", {
      Page: page,
    });
    const path = pathname.split("/");
    path.pop();
    path.join("/");
    path.push(page.toString());

    navigate(path.join("/"), { state: location.state });
  };

  const totalPages = page?.totalPages!;
  const pageIndex = parseInt(pageNumber);

  const onLastPage = pageIndex - 1 === totalPages - 1;

  const pageContext = {
    categoryId,
    pageNumber,
    page,
    loading,
    viewport,
    refetch,
    updateQuestionState,
    incrementTotalComments,
    setCommentsDrawer,
  };

  return (
    <SkeletonWrapper loading={loading} type="questionnaire">
      <QuestionnairePageContext.Provider value={pageContext}>
        <PageRenderer pageType={page?.pageType!} />
        <PageComplete onLastPage={onLastPage} />
        <PageControls page={page} onChange={handlePaginationChange} />
        {commentsDrawer.open && <CommentsDrawer state={commentsDrawer} />}
      </QuestionnairePageContext.Provider>
    </SkeletonWrapper>
  );
};

Questionnaire.defaultProps = {
  onPageChange() {},
};

export default Questionnaire;
