import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Button } from "antd";

export const P19 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="references">
        <div className="title">REFERENCES</div>
        <ol>
          <li>DOD CUI Registry is located at <a>https://www.dodcui.mil/Home/DOD-CUI-Registry/</a></li>
          <li>DODI 5200.48, Controlled Unclassified Information (CUI), 06 March 2020</li>
          <li>DODI 5230.09 Change 1, Clearance of DOD Information for Public Release, 09 February 2022</li>
          <li>DAF Guidance Memorandum to AFI 17-101, Risk Management Framework (RMF) for Air Force Information Technology (IT), 10 June 2022</li>
          <li>AFI 33-332, Air Force Privacy and Civil Liberties Program, 12 May 2020</li>
          <li>AFMAN 17-1402, Clinger Cohen Act (CCA) Compliance, 20 Jun 2018</li>
          <li>DODI 5000.82, Requirements for the Acquisition of Digital Capabilities, 1 Jun 2023</li>

          <li>DAF RMF Knowledge Service at <a>https://rmfks.osd.mil/rmf/collaboration/Component%20Workspaces/AirForce/Pages/default.aspx</a></li>
          <li>DOD Manual (DODM) 5200.01 Volume 1, Change 2, DOD Information Security Program: Overview, Classification, and Declassification, 28 July 2020</li>
          <li>DODM 5200.01 Volume 2, Change 4, DOD Information Security Program:Marking of Information, 28 July 2020</li>
          <li>DODI 5200.01 Change 2, DOD Information Security Program and Protection of Sensitive Compartmented Information (SCI), 01 October 2020</li>
          <li>DOD Joint Special Access Program Implementation Guide (JSIG), 11 April 2016</li>
          <li>DoD Directive 5205.07, Special Access Program (SAP) Policy, July 1, 2010</li>
          <li>DODI 5210.02 Change 2, Access to and Dissemination of Restricted Data and Formerly Restricted Data, 3 June 2011</li>
          <li>DOD Directive 5230.11, Disclosure of ClassifiedMilitary Information to Foreign Governments and International Organizations, 16 June 1992</li>

          <li>DAF CISO CSSP Alignment and ValidationMemo, 12 Oct 2023</li>
          <li>AFI 17-130, Cybersecurity ProgramManagement, 13 Feb 2020</li>
          <li>CNSSI 1253, Security Categorization and Control Selection for National Security Systems, 01 August 2022</li>
          <li>CNSS Instructions <a>https://www.cnss.gov/CNSS/issuances/Instructions.cfm</a></li>
          <li> References for NSS Determination </li>
        </ol>
        <ul>
          <li>40 USC 11103: <i>Applicability to National Security Systems</i></li>
          <li>NIST SP 800-59, <i>Guideline for Identifying an Information System as a National Security System</i>, 20 August 2003</li>
          <li>DOD CIO Memorandum, Implementing National Security Memorandum-8, Task 5: Identification and Inventorying of National Security Systems Guidance (NMM-2022-05) (25 February 2022), 05 May 2022</li>
          <li>National Manager Memorandum (NMM)NMM-2022-05, National Security Memorandum 8, Task 5: Identification and Inventorying of National Security Systems Guidance, 25 February 2022</li>
        </ul>
        <ol style={{ counterIncrement: 'list-counter 20' }}>
          <li>DOD Cloud Computing Security Requirements Guide, Version 1 Release 4, 20 January 2022</li>
          <li>NIST SP 800-210, General Access Control Guidance for Cloud Systems, Jul 2020</li>
        </ol>
      </div>
    </>
  );
};
