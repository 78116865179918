import { SaveStatusEnum } from "../../Shared/types";

export type CategoryFieldKeys =
  | "categoryName"
  | "categoryOrder"
  | "parentModule"
  | "checkpointStatus"
  | "rmfPaths"
  | "questionList";

export enum CategoryFieldKeysEnum {
  categoryName = "categoryName",
  categoryOrder = "categoryOrder",
  parentModule = "parentModule",
  checkpointStatus = "checkpointStatus",
  rmfPaths = "rmfPaths",
  questions = "questions",
  questionOrder = "questionOrder",
}

export type EditStateType = {
  fieldKey?: CategoryFieldKeys;
  editActive: boolean;
};

export type FieldEditState = {
  editActive: boolean;
  saveStatus: SaveStatusEnum;
};

export type CategoryEditorState = {
  categoryName: FieldEditState;
  categoryOrder: FieldEditState;
  parentModule: FieldEditState;
  checkpointStatus: FieldEditState;
  questionOrder: FieldEditState;
  questions: FieldEditState;
  rmfPaths: FieldEditState;
  rmfAnswers: FieldEditState;
};

export enum CategoryEditorReducerEnum {
  ToggleEdit = "toggleEdit",
  SetLoading = "setLoading",
  SetSuccess = "setSuccess",
  SetError = "setError",
  SetDefault = "setDefault",
  UpdateCategory = "updateCategory",
  ResetEditor = "resetEditor",
}

export type CategoryEditorReducer =
  | {
      type: CategoryEditorReducerEnum.SetDefault;
      params: any;
    }
  | {
      type: CategoryEditorReducerEnum.SetLoading;
      params: any;
    }
  | {
      type: CategoryEditorReducerEnum.SetSuccess;
      params: any;
    }
  | {
      type: CategoryEditorReducerEnum.SetError;
      params: any;
    }
  | {
      type: CategoryEditorReducerEnum.ToggleEdit;
      params: any;
    }
  | {
      type: CategoryEditorReducerEnum.ResetEditor;
      params: any;
    };

export const categoryEditorReducer = (
  state: CategoryEditorState,
  action: CategoryEditorReducer,
): any => {
  console.log("%creducerState", "color:blue");
  console.log({ state });
  console.log({ action });

  const fieldKey: CategoryFieldKeys = action?.params?.fieldKey;
  switch (action.type) {
    case CategoryEditorReducerEnum.ToggleEdit:
      console.log("%cTOGGLE EDIT!", "color:blue");
      const toggleState: CategoryEditorState = { ...state };
      const currentFieldState = toggleState[fieldKey];
      const editActive: boolean | undefined = currentFieldState?.editActive;
      console.log({ editActive });
      console.log({ currentFieldState });

      const newFieldState = {
        ...currentFieldState,
        editActive: !editActive,
      };
      console.log({ newFieldState });

      return {
        ...state,
        [fieldKey]: newFieldState,
      };

    case CategoryEditorReducerEnum.SetDefault:
      console.log("%cSET DEFAULT!", "color:blue");

      const setDefaultState: CategoryEditorState = { ...state };
      setDefaultState[fieldKey].saveStatus = SaveStatusEnum.Default;

      return {
        ...setDefaultState,
      };

    case CategoryEditorReducerEnum.SetLoading:
      console.log("%cSET LOADING!", "color:blue");
      console.log({ fieldKey });
      const setLoadingState: CategoryEditorState = { ...state };
      console.log({ fieldStateB4: setLoadingState[fieldKey] });
      console.log(fieldKey === CategoryFieldKeysEnum.checkpointStatus);
      setLoadingState[fieldKey].saveStatus = SaveStatusEnum.Loading;

      console.log({ fieldState: setLoadingState[fieldKey] });
      console.log({ setLoadingState });

      return {
        ...setLoadingState,
      };
    case CategoryEditorReducerEnum.SetSuccess:
      console.log("%cSET SUCCESS!", "color:blue");
      const setSuccessState: CategoryEditorState = { ...state };
      setSuccessState[fieldKey].saveStatus = SaveStatusEnum.Success;
      console.log({ setSuccessState });
      return {
        ...setSuccessState,
      };
    case CategoryEditorReducerEnum.SetError:
      console.log("%cSET ERROR!", "color:blue");
      const setErrorState: CategoryEditorState = { ...state };
      setErrorState[fieldKey].saveStatus = SaveStatusEnum.Error;

      return {
        ...setErrorState,
      };

    case CategoryEditorReducerEnum.ResetEditor:
      console.log("%cResetEditor!", "color:blue");
      console.log({ state });
      const copy: CategoryEditorState = { ...state };
      copy.questionOrder.editActive = false;
      copy.questions.editActive = false;
      return {
        ...state,
      };
    default:
      console.log("default!");
      return {
        ...state,
      };
  }
};
