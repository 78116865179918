import { useState } from "react";
import { Drawer } from "antd";
import CreateQuestionGroup from "../Workflow/Forms/Create/CreateQuestionGroup";

interface Props {
  open: boolean;
  onClose: () => void;
}

const CreateGroupDrawer: React.FC<Props> = ({ open, onClose }) => {
  const [drawerType, setDrawerType] = useState<"baseForm" | "gridEditor">("baseForm");

  const width = drawerType === "baseForm" ? 600 : '90%';

  return (
    <Drawer
      title="Create Question Group"
      open={open}
      onClose={onClose}
      width={width}
      autoFocus={true}
      keyboard={false}
    >
      <CreateQuestionGroup closeDrawer={onClose} setDrawerType={setDrawerType} />
    </Drawer>
  );
};

export default CreateGroupDrawer;
