import { DeleteOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, message, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { QuestionGroupNode, QuestionNode } from "types";
import Api from "api";
import { mapTagToType } from "../../../../Common/Tags/QuestionType";
import ExpandIcon from "components/Common/ExpandedTableIcon";

type Props = {
  data: QuestionGroupNode;
};

const NestedGroupsTable: React.FC<Props> = ({ data }: any) => {
  console.log({ data });
  const [removeFromGroup, { loading }] = useMutation(Api.QuestionGroup.BulkUpdate(), {
    onCompleted: (res) => {
      console.log(res);
      message.success("Successfully removed from group!", 7);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
    refetchQueries: [{ query: Api.QuestionGroup.GetAll() }],
  });

  const handleRemove = (record: QuestionNode) => {
    console.log(record);
    removeFromGroup({
      variables: {
        groupId: "CLEAR",
        questionIds: [record.id],
      },
    });
  };

  const columns: ColumnsType<QuestionNode> = [
    { title: "Order", dataIndex: "groupOrder", width: "5%", align: "center" },
    { title: "Question", dataIndex: "question" },
    {
      title: "Data Type",
      dataIndex: "questionType",
      width: "8%",
      render: (text, record) => mapTagToType(text, record.id),
    },
    {
      title: "Remove",
      width: "5%",
      align: "left",
      render: (_text, record, _index) => (
        <Popconfirm
          title="Are you sure you want to remove from group?"
          onConfirm={() => handleRemove(record)}
          key={record.id}
          okText="Remove"
          cancelText="Cancel"
          okButtonProps={{ danger: true }}
        >
          <Button danger icon={<DeleteOutlined />} size="small" loading={loading} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Table
      dataSource={data.questions}
      columns={columns}
      rowKey={(row) => row.id}
      pagination={false}
      bordered
    />
  );
};

export const expandComponents = {
  expandedRowRender: (record: QuestionGroupNode) => (
    <NestedGroupsTable data={record} key={record.id} />
  ),
  expandIcon: ({ expanded, onExpand, record }) => {
    console.log({ expandIcon: "=> ", expanded, onExpand, record });
    if (record.questions.length === 0) return null;
    return <ExpandIcon expanded={expanded} onExpand={onExpand} record={record} />;
  },
};

export default NestedGroupsTable;
