import React from "react";
import LoadingSpinner from "../LoadingSpinner";
import ProSkeleton from "@ant-design/pro-skeleton";
import { Gauge } from "@ant-design/plots";
import ProgressMetric from "components/Applicant/Workflow/Components/Metrics/ProgressMetric";
import { Typography, Card, Col, Empty, List, Row, Skeleton } from "antd";

const { Title } = Typography;

export const SkeletonMenuItem = () => (
  <Skeleton active={true} paragraph={{ rows: 2 }} style={{ paddingRight: 16 }} />
);

export const SkeletonQuestionComment = () => (
  <Skeleton active={true} paragraph={{ rows: 2 }} style={{ paddingRight: 16 }} />
);

export const WorkflowEditorSkeleton: React.FC<any> = ({ loading, children }) => {
  return loading ? (
    <ProSkeleton type="list" active={true} pageHeader={false} toolbar={false} statistic={4} />
  ) : (
    <>{children}</>
  );
};

export const WorkflowSearchViewSkeleton = () => {
  return <ProSkeleton type="list" pageHeader={false} toolbar={false} statistic={false} />;
};

export const QuestionGroups = () => {
  return <ProSkeleton />;
};

export const WorkflowInformation = () => {
  return (
    <div style={{ padding: "24px", backgroundColor: "white" }}>
      <Skeleton active={true} paragraph={{ rows: 2 }} />
      <Row style={{ marginTop: 10 }}>
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Loading Progress
        </Typography.Title>
      </Row>
      <ProgressMetric percent={0} />
    </div>
  );
};

export const CategoryTitleCard = () => (
  <Skeleton paragraph={{ rows: 1 }} active={true} title={false} />
);

export const WorkflowListCards = () => {
  return (
    <List
      grid={{
        gutter: 16,
        column: 3,
        xl: 3,
        lg: 2,
        md: 1,
        sm: 1,
        xs: 1,
      }}
      dataSource={[1, 2, 3]}
      renderItem={() => (
        <List.Item>
          <Card
            style={{ minWidth: 250 }}
            title={<Skeleton active={true} paragraph={{ rows: 0 }} />}
          >
            <Skeleton active={true} paragraph={{ rows: 3 }} />
          </Card>
        </List.Item>
      )}
    />
  );
};

export const WorkflowContent = ({ content }) => {
  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col span={6} xs={24} md={8} xxl={6} order={window.innerWidth > 768 ? 2 : 1}>
        <Card
          className="shadowed-static"
          bodyStyle={{ padding: 15 }}
          style={{ marginBottom: 16 }}
        >
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            Metrics
          </Typography.Title>
        </Card>
        <Card style={{ marginBottom: 16, height: 225 }}>
          <Gauge {...{ percent: 0, loading: true, style: { maxWidth: 1, maxHeight: 1 } }} />
        </Card>
        <Card style={{ height: 225 }}>
          <Gauge {...{ percent: 0, loading: true, style: { maxWidth: 1, maxHeight: 1 } }} />
        </Card>
      </Col>
      <Col span={16} xs={24} md={16} order={window.innerWidth > 768 ? 1 : 2}>
        <Card
          style={{ marginBottom: 16 }}
          className="shadowed-static"
          bodyStyle={{ padding: 15 }}
        >
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            {content}
          </Typography.Title>
        </Card>
        <List
          grid={{
            gutter: 16,
            column: 3,
            xl: 2,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
          dataSource={[1, 2, 3]}
          renderItem={() => (
            <List.Item>
              <Card
                style={{ minWidth: 250 }}
                title={<Skeleton active={true} paragraph={{ rows: 0 }} />}
              >
                <Skeleton active={true} paragraph={{ rows: 3 }} />
              </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export const ModuleRenderer = () => {
  return (
    <div style={{ width: "100%" }}>
      <span>Stand by while we retrieve Module Information</span>
      <br />
      <br />
      <LoadingSpinner />
    </div>
  );
};

export const QuestionnaireSkeleton = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((_, i) => (
        <React.Fragment key={i}>
          <Card>
            <Row justify="start">
              <Title level={3} style={{ margin: 0 }}>
                Question
              </Title>
            </Row>
            <br />
            <Row justify="start">
              <Skeleton active={true} paragraph={{ rows: 2 }} />
            </Row>
            <br />
            <Row justify="start">
              <Title level={3} style={{ margin: 0 }}>
                Answer
              </Title>
            </Row>
            <br />
            <Row justify="start">
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Row>
          </Card>
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export const DynamicAvatar: React.FC<any> = ({ shape, loading, children }) => {
  if (loading) {
    return <Skeleton.Avatar active={true} shape={shape} />;
  } else {
    return children;
  }
};

export const DynamicParagraphSkeleton: React.FC<any> = ({
  rows,
  title,
  loading,
  avatar,
  styles,
  children,
}) => {
  return loading ? (
    <div style={styles}>
      <Skeleton
        loading={loading}
        title={title}
        avatar={{
          shape: avatar,
          size: "large",
        }}
        paragraph={{
          rows: rows,
          width: "100%",
        }}
        active={true}
      />
    </div>
  ) : (
    children
  );
};

export const ParagraphSkeleton: React.FC<any> = ({
  loading,
  title,
  rows,
  styles,
  children,
}) => {
  return loading ? (
    <div style={styles}>
      <Skeleton
        title={title}
        avatar={false}
        active={true}
        paragraph={{ rows: rows, width: "100%" }}
      />
    </div>
  ) : (
    children
  );
};

export const SkeletonInnerTable: React.FC<any> = ({ loading, rowCount = 4 }) => {
  const row = (i: number) => (
    <Row key={i} align="bottom" justify="start" gutter={[32, 0]} style={{ marginBottom: 12 }}>
      <Col span={3}>
        <Skeleton paragraph={{ rows: 1, width: "100%" }} title={false} active={true} />
      </Col>
      <Col span={5}>
        <Skeleton paragraph={{ rows: 1, width: "100%" }} title={false} active={true} />
      </Col>
      <Col span={6}>
        <Skeleton paragraph={{ rows: 1, width: "100%" }} title={false} active={true} />
      </Col>
      <Col span={8}>
        <Skeleton paragraph={{ rows: 1, width: "100%" }} title={false} active={true} />
      </Col>
      <Col span={2} style={{ paddingRight: 4 }}>
        <Skeleton paragraph={{ rows: 1, width: "100%" }} title={false} active={true} />
      </Col>
    </Row>
  );

  const ans: any = [];
  for (let i = 0; i < rowCount; i++) {
    ans.push(row(i));
  }

  return loading ? <>{ans}</> : <Empty />;
};

// used for content cards in admin editors
export const ContentCardSkeletonWrapper = ({
  loading,
  children,
}: {
  loading: Loading;
  children: any;
}) => {
  return loading ? <Skeleton active={true} paragraph={{ rows: 1, width: "85%" }} /> : children;
};
