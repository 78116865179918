import React from "react";
import useViewport from "hooks/useViewport";
import { SkeletonWrapper } from "components/Common/Skeletons/SkeletonLoading";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import GaugeMetrics from "../Metrics/GaugeMetrics";
import { Col, Row, Space } from "antd";

interface Props {
  loading: boolean;
  information: any;
  header: { title: string; icon: any };
  skeletonType: string;
  time: number | any;
  risk: number | any;
  children: React.ReactNode;
}

const DashboardLayout: React.FC<Props> = (props) => {
  const { loading, information, header, skeletonType, time, risk, children } = props;
  const viewport = useViewport();

  return (
    <>
      <CustomPageHeader
        title={header.title}
        icon={<Space style={{ color: "#1890FF", marginBottom: 3 }}>{header.icon}</Space>}
      />
      <SkeletonWrapper loading={loading} type="workflowInformation">
        {information}
      </SkeletonWrapper>
      <br />
      <SkeletonWrapper loading={loading} type={skeletonType}>
        <Row gutter={[16, 16]} justify="space-between">
          <Col span={6} xs={24} md={8} xxl={6} order={viewport.isDesktop ? 2 : 1}>
            <GaugeMetrics loading={loading} time={time} risk={risk} />
          </Col>
          <Col span={16} xs={24} md={16} order={viewport.isDesktop ? 1 : 2}>
            {children}
          </Col>
        </Row>
      </SkeletonWrapper>
    </>
  );
};

export default DashboardLayout;
