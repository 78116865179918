import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

import P7D1 from "../Assets/P7D1.png";
import { Space } from "antd";

export const P7 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const ABTSLMarking = {
    label: "Select Marking",
    value: "system_architecture_marking",
  };

  const CILDMarking = {
    label: "Select Marking",
    value: "cloud_impact_level_determination_marking",
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        {/* 9. System Architecture */}
        <div className="page-table">
          <table border={1}>

            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={ABTSLMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                      9. System Architecture
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td width={'35%'} style={{ borderBottom: 'none' }} className="text">
                  <EditableRadio {...elementProps} fieldName="9_system_architecture_on_premises_deployed"
                    label={<div><b>On-Premises Deployed</b> – This system is deployed completely on premises</div>}
                    extra={<i>Note 11: If checked, skip to Section 11</i>}
                    contentStyle={{ flexDirection: 'column', alignItems: 'start' }}
                  />
                </td>
                <td colSpan={2}>
                  <div style={{ height: '100%', display: 'grid', placeItems: 'baseline', justifyItems: 'center' }}>
                    <b>Cloud Deployment <i>Refs: (p) & (q)</i></b>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', padding: '0px 20px' }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="9_system_architecture_cloud_deployment_private"
                        label={<b>Private</b>}
                      />

                      <EditableRadio
                        {...elementProps}
                        fieldName="9_system_architecture_cloud_deployment_community"
                        label={<b>Community</b>}
                      />

                      <EditableRadio
                        {...elementProps}
                        fieldName="9_system_architecture_cloud_deployment_public"
                        label={<b>Public</b>}
                      />

                      <EditableRadio
                        {...elementProps}
                        fieldName="9_system_architecture_cloud_deployment_hybrid"
                        label={<b>Hybrid</b>}
                      />
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan={3} className="label"> 9A. Cloud Service Model</td>
              </tr>
              <tr>
                <td className="text">
                  <EditableRadio
                    {...elementProps}
                    fieldName="9a_cloud_service_model_iaas"
                    label={<b>Infrastructure as a Service (IaaS)</b>}
                  />
                </td>
                <td className="text">
                  <EditableRadio
                    {...elementProps}
                    fieldName="9a_cloud_service_model_paas"
                    label={<b>Platform as a Service (PaaS)</b>}
                  />
                </td>
                <td className="text" style={{ padding: '4px' }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="9a_cloud_service_model_saas"
                    label={<b>Software as a Service (SaaS)</b>}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ borderBottom: 'none' }}>
                  <img style={{ maxWidth: "100%", width: '100%' }} src={P7D1} alt="page 7 diagram 1" />
                </td>
              </tr>

            </tbody>
          </table>
        </div>

        {/* 9. System Architecture */}
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'70%'} />
              <col span={1} width={'30%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={CILDMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                      10. Cloud Impact Level (IL) Determination <i>Ref: (p)</i>
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="label" style={{ padding: '10px' }}>
                  10A. Is the System a non-public, classified NSS (up to information categorization (M-M-x)<br />
                  and information up to Secret?
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="10a_cloud_impact_level_determination_yes" label={<><b>Yes</b> (IL6)</>} />
                    <EditableRadio {...elementProps} fieldName="10a_cloud_impact_level_determination_no" label={<><b>No</b></>} />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ padding: '10px' }}>
                  10B. Is the System a non-public, unclassified NSS to include CUI that may require<br />
                  a higher level of protection (up to CNSSI-1253 (M-M-x))?
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="10b_cloud_impact_level_determination_yes" label={<><b>Yes</b> (IL5)</>} />
                    <EditableRadio {...elementProps} fieldName="10b_cloud_impact_level_determination_no" label={<><b>No</b></>} />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ padding: '10px' }}>
                  10C. Does the System contain non-public, unclassified data and may include CUI<br />
                  (up to CNSSI-1253 (M-M-x))?
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="10c_cloud_impact_level_determination_yes" label={<><b>Yes</b> (IL4)</>} />
                    <EditableRadio {...elementProps} fieldName="10c_cloud_impact_level_determination_no" label={<><b>No</b></>} />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ padding: '10px' }}>
                  10D. Does the System contain publicly releasable data or non-public unclassified data<br />
                  and may include non-CUI (up to CNSSI-1253 (L-M-x))?<br />
                  <i style={{ fontWeight: 'normal' }}>Note 12: If No is checked, reevaluate Cloud requirement before proceeding</i>
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="10d_cloud_impact_level_determination_yes" label={<><b>Yes</b> (IL2)</>} />
                    <EditableRadio {...elementProps} fieldName="10d_cloud_impact_level_determination_no" label={<><b>No</b></>} />
                  </Space>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};
