import { Col, Row, Space } from "antd";
import { Footer } from "antd/lib/layout/layout";
import useViewport from "hooks/useViewport";
import "styles/layoutFooter.css";

const LayoutFooter = () => {
  const viewport = useViewport();

  return (
    <Footer
      className={viewport.layoutFooter.cns.footer}
      style={{ boxShadow: "-2px -2px 5px rgba(150, 150, 150, .2)" }}
    >
      Powered by <a href="https://iinfosec.com">Ingalls Information Security</a>
      <Row>Copyright © 2023 Ingalls Information Security, all rights reserved.</Row>
      <Row justify={"space-evenly"}>
        <Space split>
          <a href="https://i-csar.zendesk.com/hc/en-us/articles/15870145468820-CSAR-End-User-License-Agreement-EULA-">
            End-User License Agreement
          </a>
          |
          <a href="https://i-csar.zendesk.com/hc/en-us/articles/15053539458836-Privacy-Policy">
            Privacy Policy
          </a>
        </Space>
      </Row>
    </Footer>
  );
};

export default LayoutFooter;
