import { useState } from "react";
import { Button, Checkbox, Form, Input, Select, Space } from "antd";
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { CreateDefaultAnswerMutation, QuestionNode, QuestionType } from "types";
import { RMFActionPathEnums } from "api/enums";
import { FieldKeysEnum, QuestionEditorState } from "./reducer";
import Api from "api";
import ContentCard from "../../Shared/ContentCard";
import { SaveStatusEnum } from "../../Shared/types";
import useEditorLoading from "hooks/useEditorLoading";

type RmfapAnswersProps = {
  question: QuestionNode;
  updateQuestion: any;
  refetchQuestion: any;
  fieldKey: FieldKeysEnum;
  setEditState: any;
  setSaveState: any;
  state: QuestionEditorState;
  loading: Loading;
};

type FormProps = {
  question: QuestionNode;
  onFinish: any;
  handleChange: any;
};

const ManageRmfapAnswers: React.FC<RmfapAnswersProps> = ({
  question,
  refetchQuestion,
  state,
  setEditState,
  setSaveState,
  loading,
}) => {
  const [managingPaths, setManagingPaths] = useState<any>([]);
  const [updateRmfAnswers, { loading: updateLoading }] =
    useMutation<CreateDefaultAnswerMutation>(Api.Question.UpdateRmfActionPathAnswers(), {
      onCompleted: (res) => {
        console.log(res);
        setSaveState(FieldKeysEnum.rmfAnswers, SaveStatusEnum.Success);
        refetchQuestion();
        setTimeout(() => {
          setEditState(FieldKeysEnum.rmfAnswers, false);
        }, 4000);
      },
      onError: (error) => {
        setSaveState(FieldKeysEnum.rmfAnswers, SaveStatusEnum.Error);
        console.log(error.message);
      },
      refetchQueries: [
        {
          query: Api.QuestionModule.WorkflowEditor(),
        },
        { query: Api.Question.GetAllOrphanedQuestions() },
      ],
    });

  const onFinish = () => {
    console.log({ rmfApAnswers: managingPaths });
    setSaveState(FieldKeysEnum.rmfAnswers, SaveStatusEnum.Loading);
    updateRmfAnswers({
      variables: {
        id: question.id,
        paths: managingPaths,
      },
    });
  };

  const handleChange = (path: any) => {
    if (managingPaths.some((mp: any) => mp.actionPath === path.actionPath)) {
      if (path.answers.length) {
        const updatedPaths = managingPaths.map((mp: any) => {
          if (mp.actionPath === path.actionPath) {
            return {
              ...mp,
              ...path,
            };
          } else {
            return mp;
          }
        });
        setManagingPaths(updatedPaths);
      } else {
        const filteredPaths = managingPaths.filter(
          (mp: any) => mp.actionPath !== path.actionPath,
        );
        setManagingPaths(filteredPaths);
      }
    } else {
      const concattedPaths = [...managingPaths, path];
      setManagingPaths(concattedPaths);
    }
  };

  const CardActions = () => {
    return (
      <Space key="rmfAnswersButtons">
        <Button type="primary" icon={<SaveOutlined />} key="rmfSave" onClick={onFinish}>
          Save
        </Button>
        <Button
          onClick={() => setEditState(FieldKeysEnum.rmfAnswers)}
          icon={<CloseCircleOutlined />}
          key="rmfCancel"
        >
          Cancel
        </Button>
      </Space>
    );
  };

  const rmfAnswersLoading = useEditorLoading(FieldKeysEnum.rmfAnswers, state, loading);

  return (
    <ContentCard
      title="Edit RMFAP Default Answers"
      setEditState={setEditState}
      fieldKey={FieldKeysEnum.rmfAnswers}
      extra={<CardActions />}
      loading={loading || rmfAnswersLoading}
      editingCurrentField={state[FieldKeysEnum.rmfAnswers].editActive}
      saveStatus={state[FieldKeysEnum.rmfAnswers].saveStatus}
      editableField={false}
      renderSaveStatus={state[FieldKeysEnum.rmfAnswers].saveStatus !== SaveStatusEnum.Default}
    >
      <RmfapAnswersForm question={question} onFinish={onFinish} handleChange={handleChange} />
    </ContentCard>
  );
};

const RmfapAnswersForm: React.FC<FormProps> = ({ question, handleChange, onFinish }) => {
  const questionType = question?.questionType;

  return (
    <Form layout="vertical" onFinish={onFinish} name="rmfApAnswersForm">
      {RMFActionPathEnums.map((ap, i) => {
        const apOnQuestion = question.rmfActionPaths?.some((p: any) => p.path === ap.value);

        if (!apOnQuestion) return <></>;

        const questionAP = question.rmfActionPaths?.find((da: any) => da.path === ap.value);

        return (
          <Form.Item key={ap.label} label={ap.label}>
            {questionType === QuestionType.FulfillText && (
              <Input
                placeholder="Enter default value"
                id={question?.answers[0]!.id}
                defaultValue={questionAP?.answers[0]?.textAnswer || ""}
                onChange={(e) => {
                  let path = {
                    text: e.target.value,
                    actionPath: ap.value,
                    answers: [e.target.id],
                  };
                  handleChange(path);
                }}
              />
            )}
            {questionType === QuestionType.MultipleChoicePickOne && (
              <Select
                allowClear
                {...styles}
                options={question.answers!.map((a: any) => ({
                  id: a.id,
                  label: a.answer,
                  value: a.id,
                }))}
                defaultValue={questionAP?.answers[0]?.answerId}
                onChange={(id) => {
                  let path = {
                    actionPath: ap.value,
                    answers: id ? [id] : [],
                  };
                  handleChange(path);
                }}
              />
            )}
            {questionType === QuestionType.MultipleChoicePickMany && (
              <Checkbox.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                options={question?.answers!.map((a: any) => ({
                  id: a.id,
                  label: a.answer,
                  value: a.id,
                }))}
                defaultValue={questionAP?.answers?.map((da: any) => da.answerId)}
                onChange={(values) => {
                  let path = {
                    actionPath: ap.value,
                    answers: values,
                  };
                  handleChange(path);
                }}
              />
            )}
          </Form.Item>
        );
      })}
    </Form>
  );
};

const styles = {
  select: {
    width: "100%",
  },
};

export default ManageRmfapAnswers;
