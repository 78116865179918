import { Button, Collapse, Empty, Space, Tag } from "antd";
import { LockOutlined, SettingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { QuestionModuleNode, QuestionNode } from "types";
import CategoryQuestionsTable from "components/Administration/Workflow/Tables/CategoryQuestionsTable";

const { Panel } = Collapse;

type Props = {
  module: QuestionModuleNode;
  setActiveCategory: any;
  activeCategory: string;
  state: any;
  dispatch: any;
};

const RenderModules: React.FC<Props> = (props) => {
  const { module, setActiveCategory, activeCategory, dispatch } = props;

  const onCollapse = (category: [string]) => {
    dispatch({ type: "onCollapse" });
    console.log({ category });
    setActiveCategory(category);
  };

  if (module.categories.length > 0) {
    return (
      <Collapse
        collapsible="header"
        onChange={(key: any) => onCollapse(key)}
        activeKey={activeCategory}
        className="categoryCollapse"
      >
        {module.categories.map((category, index) => (
          <Panel
            header={
              <LockedCategoryHeader
                categoryName={category!?.name}
                locked={category?.hasCheckpoint}
              />
            }
            key={category!?.id}
            extra={
              <Link to={`/administration/workflow-editor/category-editor/${category?.id}`}>
                <Button icon={<SettingOutlined />}>Category</Button>
              </Link>
            }
          >
            <CategoryQuestionsTable questions={category?.allQuestions as QuestionNode[]} />
          </Panel>
        ))}
      </Collapse>
    );
  }

  return <Empty description="No Categories assigned to this Module..." />;
};

const LockedCategoryHeader = ({
  categoryName,
  locked,
}: {
  categoryName: string;
  locked: boolean | null | undefined;
}) => {
  // displays lock tag for category checkpoints
  if (locked) {
    return (
      <Space key={categoryName} align="center">
        <span style={{ cursor: "pointer", fontSize: 14 }}>{categoryName}</span>
        <Tag color="#c25604" icon={<LockOutlined />}>
          Checkpoint
        </Tag>
      </Space>
    );
  } else {
    return <span>{categoryName}</span>;
  }
};

export default RenderModules;
