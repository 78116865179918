import { Link } from "react-router-dom";
import useViewport from "hooks/useViewport";
import Layout from "./Layout";
import { BookOutlined, HomeOutlined } from "@ant-design/icons";
import "styles/layout.css";
import "styles/application.css";

const IndexLayout: React.FC = () => {
  const viewport = useViewport();

  const items: Array<MenuItem> = [
    {
      type: "group",
      label: !viewport.layout.collapsed ? "General" : "",
      key: "General",
      children: [
        {
          label: <Link to="/home">Home</Link>,
          key: "home",
          icon: <HomeOutlined />,
        },
        {
          label: <Link to="cyber-plans">Cyber Plans</Link>,
          key: "cyber-plans",
          icon: <BookOutlined />,
        },
      ],
    },
  ];

  return <Layout items={items} />;
};

export default IndexLayout;
