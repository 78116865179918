import useViewport from "hooks/useViewport";
import { Row } from "antd";
import { QuestionType } from "types";
import FulfillTextInput from "./Inputs/FulfillTextInput";
import FulfillDateInput from "./Inputs/FulfillDateInput";

export interface AdditionalAnswerRendererProps {
  type: string;
  answerId: string;
  defaultAnswer: string;
  onChange: (answer: { id: string; text: string }) => void;
  disabled: boolean;
}

const AdditionalAnswerRenderer: React.FC<AdditionalAnswerRendererProps> = ({
  type,
  answerId,
  defaultAnswer,
  onChange,
  disabled,
}) => {
  const viewport = useViewport();

  const Renderer = () => {
    switch (type) {
      case QuestionType.FulfillText:
        return (
          <FulfillTextInput
            rows={1}
            answerId={answerId}
            defaultAnswer={defaultAnswer}
            onChange={onChange}
            disabled={disabled}
          />
        );
      case QuestionType.FulfillDate:
        return (
          <FulfillDateInput
            answerId={answerId}
            defaultAnswer={defaultAnswer}
            onChange={onChange}
            disabled={disabled}
            style={{ width: "100%" }}
          />
        );
      default:
        return <>Invalid Additional Answer Type</>;
    }
  };

  return (
    <Row
      style={{
        padding: "8px 0 16px 25px",
        ...viewport.questionnaire.styles.additionalAnswerRow,
      }}
    >
      <Renderer />
    </Row>
  );
};

export default AdditionalAnswerRenderer;
