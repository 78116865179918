export const P20 = (props) => {
  return (
    <>
      <div className="itcsc" style={{ display: "grid", placeItems: "center" }}>
        <div
          className="instructions"
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "10px 20px",
              textAlign: "left",
            }}
          >
            <div style={{ width: "48%", marginRight: "40px" }}>
              <div style={{ paddingLeft: "20px" }}>
                <p style={{ fontWeight: "bold" }}>
                  7D/F/I. PII Retrieved and System of Records Notice (SORN)
                </p>
                <p>
                  Does the System retrieve information by a personal identifier? If the IS uses
                  a unique identifier to retrieve information, then a SORN is required.
                </p>
                <p>
                  <i>See Refs: (i), (j), (k), & (l) for more information</i>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  7E. Controlled Unclassified Information (CUI)
                </p>
                <p>Does the System contain CUI, which is defined in DODI 5200.48?</p>
                <p>
                  <i>See Refs: (k) & (m) for more information</i>
                </p>
                <p>
                  In order to answer this question "No," the user must answer Questions 12D-12G
                  and arrive at a "Low" determination for Question 12I (Privacy Impact
                  Confidentiality Level).
                </p>
                <p style={{ fontWeight: "bold" }}>7G. Cloud - NSS</p>
                <p>
                  Has the System been determined to be an NSS (“Yes” to Question 11I), or is it
                  categorized as NSS because of additional factors listed in Ref: (n)?
                </p>
                <p>
                  <i>See Ref: (n) for more information.</i>
                </p>
              </div>

              <p style={{ fontWeight: "bold" }}>8. Proposed System</p>
              <div style={{ paddingLeft: "20px" }}>
                <p style={{ fontWeight: "bold" }}>8A-8B. System Type</p>
                <p>Select one box from the listed System Types.</p>
                <p>
                  <i>See Refs: (o) & (p) for more information.</i>
                </p>
              </div>

              <p style={{ fontWeight: "bold" }}>
                9. Authorization Boundary Topology and System Location
              </p>
              <p>
                Provide a detailed boundary drawing that clearly showsthe cybersecurity
                authorization boundary. This topology should match the infrastructure outlined
                in both the System and Security Descriptions.
              </p>
              <p>
                Required DoDAF Artifact: If external interfaces exist, the follow-on
                requirement is to generate a detailed and comprehensive boundary drawing (DoDAF
                OV-1 and SV-1) and post it to eMASS as an artifact during or subsequent to the
                initial registration.
              </p>
              <p style={{ fontWeight: "bold" }}>10. Overlays</p>
              <div style={{ paddingLeft: "20px" }}>
                <p style={{ fontWeight: "bold" }}>10A. Intelligence Overlay</p>
                <p>
                  Does the System process, store, or transmit Intelligence, Surveillance, or
                  Reconnaissance (ISR)?
                </p>
                SeeRef: (q) formore information.
                <p style={{ fontWeight: "bold" }}>10B. Cross Domain Solution Overlay</p>
                <p>
                  Will you implement, manage, or maintain a Cross Domain Solution? SeeRef: (r)
                  for more information.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  10C. Nuclear Command, Control & Communications (NC3) Overlay
                </p>
                <p>
                  Does the System store, process, or transmit NC3 data? Please note that use of
                  the NC3 Overlay also requires the implementation of the Intel non-NC3
                  Overlay.
                </p>
                <p>
                  <i>See Refs: (q) &(s) for more information.</i>
                </p>
              </div>
            </div>

            <div style={{ width: "52%" }}>
              <div style={{ paddingLeft: "20px" }}>
                <p style={{ fontWeight: "bold" }}>10D. Space Platform Overlay</p>
                <p>
                  Is the System (or subsystem) a space platform as defined in CNSSI 1253F
                  Attachment (Atch) 2 and unmanned?
                </p>
                <p>
                  <i>See Ref: (t) for more information.</i>
                </p>
                <p style={{ fontWeight: "bold" }}>10E. Classified Information Overlay</p>
                <p>Does the System store, process, or transmit classified information?</p>
                <p>
                  <i>See Ref: (u) for more information.</i>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  10F. DoD Functional Mission/Capability Specific Overlay
                </p>
                <p>
                  Is your System required to execute an organizational mission or
                  function-specific overlay? For example, Financial (FIAR), Acquisition.
                </p>
                <p>
                  <i>See Ref: (v) for more information.</i>
                </p>
              </div>
              <p style={{ fontWeight: "bold" }}>
                11. National Security System(NSS) Determination
              </p>
              <p>
                Carefully read and answer Questions 11A-11H, checking the "Yes" or "No" box on
                each one. Multiple "Yes" responses are acceptable. If the answer to any of the
                eight Questions is “Yes,” then the System is an NSS as to be noted in Question
                11I, pending SAF/CN review.
              </p>
              <p>
                <i>See Ref: (n) for more information.</i>
              </p>
              <p style={{ fontWeight: "bold" }}>12.Privacy</p>
              <p>
                For assistance with completing this section, please contact your Base Privacy
                Manager.
              </p>
              <div style={{ paddingLeft: "20px" }}>
                <p style={{ fontWeight: "bold" }}>12B. Is System an NSS?</p>
                <p>
                  If you answered “Yes” to Question 7G, then continue. Otherwise, proceed to
                  Question 12E.
                </p>
                <p>
                  <i>See Ref: (n) for more information.</i>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  12C. PII and Rolodex Information See instructions under 7C/H.
                </p>
                <p>
                  <i>See Ref: (n) for more information.</i>
                </p>
                <p style={{ fontWeight: "bold" }}>12D. CUI /PII Context</p>
                <p>
                  Provide context with respect to the CUI or PII stored or transmitted through
                  the System. For example: Is it a list of deployed individuals (impact would
                  be higher) or a list of individuals who attended a safety meeting (impact
                  would be lower).
                </p>
                <p>
                  <i>See Refs: (b) & (k) for more information,</i>
                </p>
                <p style={{ fontWeight: "bold" }}>12E. PII Types</p>
                <p>
                  From the lists provided, identify which PII is applicable to the information
                  in this System. Information identified as “Rolodex” information is marked
                  with (**) and may be evaluated as "Low."
                </p>
                <p>
                  <i>See Ref: (h) for more information.</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
