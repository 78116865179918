import { Space } from "antd";

const LetterBullet = ({ letter, children }) => (
  <Space align="start">
    {letter}
    <div>
      {children}
    </div>
  </Space>

)

export default LetterBullet;