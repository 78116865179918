import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SortCard from "./SortableCard";
import { QuestionNode } from "types";
import "../../../../../../styles/sortable.css";

interface Props {
  parent: any;
  parentType: "group" | "category";
  itemList: any[];
  submit?: any;
  setList: any;
}

const Sortable: React.FC<Props> = ({ parent, parentType, itemList, submit, setList }) => {
  useEffect(() => {
    console.log("%cSortableProps", "color:cyan", {
      parent,
      parentType,
      itemList,
      submit,
      setList,
    });
  }, [parent, parentType, itemList, submit, setList]);
  const [lockedItems, setLockedItems] = useState<any[]>([]);
  const [dropDisabled, setDropDisabled] = useState(false);

  useEffect(() => {
    console.log({ itemList });
  }, [itemList]);

  useEffect(() => {
    console.log({ lockedItems });
  }, [lockedItems]);

  const lockItem = (itemId: string, index: number) => {
    const locked = [...lockedItems];
    locked.push({ id: itemId, index });
    console.log({ newLockedItem: { itemId, index } });
    const newLockedItems = [...locked];
    return newLockedItems;
  };

  const unlockItem = (itemId: string, index: number) => {
    return lockedItems.filter((item: any) => item.id !== itemId);
  };

  const itemIsLocked = (arr, itemId) => {
    console.log("itemIsLocked", { arr, itemId });
    return arr.some((el) => el.id === itemId);
  };

  const handleItemLock = (itemId: string, index: number) => {
    if (!itemId || index == null) return;

    const itemLocked: boolean = itemIsLocked(lockedItems, itemId);
    console.log("handleItemLock", { itemId, index, itemLocked });
    if (itemLocked) {
      setLockedItems(unlockItem(itemId, index));
      return;
    }
    setLockedItems(lockItem(itemId, index));
  };

  const reorder = (list: any, startIndex: number, endIndex: number): QuestionNode[] => {
    console.log("reorder(): ", { startIndex, endIndex, list });
    const result: QuestionNode[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragStart() {}

  function disableDrop() {
    console.log("%cDISABLE DROP func!", "color:blue");
    setDropDisabled(true);
    setTimeout(() => {
      setDropDisabled(false);
    }, 1500);
  }

  function onDragUpdate(initial, provided) {
    console.log("onDragUpdate !!", { lockedItems });
    const { destination, source } = initial;
    console.log("pre return", { destination, source });

    if (!destination?.index) {
      console.log("no target index, early return: ", { source, destination });
      return;
    }

    const hasLockedItems = lockedItems?.length > 0;
    console.log("onDragUpdate: ", { hasLockedItems, lockedItems });
    console.log("post return", { source: source.index, target: destination.index });

    if (!hasLockedItems) return;

    const lockedTarget = lockedItems.some((item) => item.index === destination.index);

    if (lockedTarget) {
      console.log("%cTARGET WAS LOCKED! disableDrop()", "color:pink");
      console.log({ lockedTarget });
      disableDrop();
      return;
    }

    console.log({ source, destination });

    const _lastLocked = lockedItems.length - 1;
    const _firstLocked = 0;

    if (destination.index === _lastLocked || destination.index === _firstLocked) {
      // catches attempt to drag before or after a locked item if the locked item is first or last
      console.log("%cTARGET WAS LOCKED, first or last", "color:orange");
      disableDrop();
      return;
    }

    if (lockedItems.some((item) => item.index >= destination.index)) {
      // catches
      console.log("%cTARGET was NOT locked, but had locked items before it", "color:blue");
      disableDrop();
      return;
    }

    if (
      lockedItems.some(
        (item) => item.index >= destination.index && item.index <= destination.index,
      )
    ) {
      console.log(
        "%cTARGET was NOT locked, but had locked items before and after it",
        "color:yellow",
      );
      disableDrop();
      return;
    }
  }

  function onDragEnd(result: any) {
    const { destination, source } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    if (destination.index === source.index) return;

    const items: QuestionNode[] = reorder(itemList, source.index, destination.index);
    console.log({ onDragEndItems: items });
    setList(items);
  }

  const moveUp = (index: number) => {
    if (index === 0) return [];
    let items = [...itemList];

    console.log("%cmoveUp()", "color:green", { currentItems: items, index });

    [items[index - 1], items[index]] = [items[index], items[index - 1]];

    console.log("%cmoveUp()", "color:green", { endItems: items });
    return items;
  };

  const moveDown = (index: number) => {
    if (index >= itemList.length - 1) return [];
    const items = [...itemList];

    console.log("%cmoveDown()", "color:pink", { currentItems: items, index });

    [items[index], items[index + 1]] = [items[index + 1], items[index]];

    console.log("%cmoveDown()", "color:pink", { endItems: items });

    return items;
  };

  const moveItem = (direction: string, itemId: string, index: number) => {
    console.log("%cmoveItem called with: ", "color:purple", { direction, itemId, index });
    console.log("moveItem called with", { direction });
    if (direction === "up") {
      setList(moveUp(index));
      return;
    }
    console.log("%cmovedItem down!", "color:magenta");
    setList(moveDown(index));
  };

  return (
    <Row justify="space-between" style={{ width: "100%" }}>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart} // if need to block updates during drag
        onDragUpdate={onDragUpdate}
      >
        <Col span={24}>
          <Droppable
            droppableId="droppableCategoryQuestions"
            direction="vertical"
            isDropDisabled={dropDisabled}
          >
            {(provided) => (
              <DragList provided={provided} innerRef={provided.innerRef}>
                {itemList?.map((item: any, i: number) => (
                  <Draggable draggableId={item.id} index={i} key={item.id}>
                    {(provided, snapshot) => (
                      <SortCard
                        question={item}
                        key={item.id}
                        index={i}
                        provided={provided}
                        snapshot={snapshot}
                        innerRef={provided.innerRef}
                        moveItem={moveItem}
                        itemList={itemList}
                        lockedItems={lockedItems}
                        handleItemLock={handleItemLock}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </DragList>
            )}
          </Droppable>
        </Col>
      </DragDropContext>
    </Row>
  );
};

const DragList = (props) => {
  const { provided, innerRef, children } = props;
  return (
    <div {...provided.droppableProps} ref={innerRef} id="droppable">
      {children}
    </div>
  );
};

export default Sortable;
