import { useState } from "react";
import useViewport from "hooks/useViewport";
import SmartTable, { compareDates, compareStrings } from "components/Common/Tables/index";
import { mapApprovalTags, mapSeverityTags } from "components/Common/Tags/SecurityFindings";
import { expandComponents } from "../../../../Administration/Workflow/Tables/NestedQuestionTable";
import SecurityFindingsModal from "../SecurityFindingsModal";
import { Button, Card } from "antd";
import { MenuOutlined, SecurityScanTwoTone } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { SecurityFindingNode } from "types";

type Props = {
  loading: Loading;
  data: Array<any>;
};

const DataTable: React.FC<Props> = ({ loading, data }) => {
  const viewport = useViewport();
  const [selectedRows, setSelectedRows] = useState<SecurityFindingNode[] | []>([]);
  const [manageModalOpen, setManageModalOpen] = useState(false);

  const findings = loading ? [] : data;
  const disableManageButton = selectedRows.length < 1;

  const columns: ColumnsType<SecurityFindingNode> = [
    {
      title: "Date Collected",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: (a, b) => compareDates(a.createdOn, b.createdOn),
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Alert Name",
      dataIndex: "alertName",
      key: "alertName",
      sorter: (a, b) => compareStrings(a.alertName, b.alertName),
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      sorter: (a, b) => compareStrings(a.severity, b.severity),
      filters: [
        { text: "None", value: "NONE" },
        { text: "Low", value: "LOW" },
        { text: "Medium", value: "MEDIUM" },
        { text: "High", value: "HIGH" },
        { text: "Critical", value: "CRITICAL" },
      ],
      onFilter: (value: any, record) =>
        record.severity.toLowerCase().includes(value.toLowerCase()),
      render: (text, _record, _index) => text && mapSeverityTags(text),
    },
    {
      title: "Approval Status",
      dataIndex: "approvalStatus",
      key: "approvalStatus",
      sorter: (a, b) => compareStrings(a.approvalStatus, b.approvalStatus),
      filters: [
        { text: "Approved", value: "APPROVED" },
        { text: "Needs Review", value: "NEEDS_REVIEW" },
        { text: "Pending", value: "PENDING_APPROVAL" },
      ],
      onFilter: (value: any, record) => record.approvalStatus!.includes(value),
      render: (text, _record, _index) => text && mapApprovalTags(text),
    },
    {
      title: "Source Tool",
      dataIndex: "sourceTool",
      key: "sourceTool",
      filters: [
        { text: "Blackberry", value: "Blackberry" },
        { text: "Cylance", value: "Cylance" },
        { text: "SentinelOne", value: "SentinelOne" },
      ],
      onFilter: (value: any, record) =>
        record.sourceTool.toLowerCase().includes(value.toLowerCase()),
    },
  ];

  const searchColumnKeys = ["alertName"];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: SecurityFindingNode[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: SecurityFindingNode) => ({
      disabled: record.alertName === "Disabled User",
      // Column configuration not to be checked
      name: record.alertName,
    }),
  };

  return (
    <Card
      className="shadowed-static"
      title={
        <>
          <SecurityScanTwoTone />
          <span style={{ marginLeft: "8px" }}>Security Findings Data </span>
        </>
      }
      extra={
        <Button
          onClick={() => setManageModalOpen(true)}
          disabled={disableManageButton}
          icon={<MenuOutlined />}
          type="primary"
        >
          {viewport.button.showText && "Manage"}
        </Button>
      }
    >
      <SmartTable
        loading={loading}
        data={findings}
        columns={columns}
        searchColumnKeys={searchColumnKeys}
        rowSelection={rowSelection}
        expandable={{
          expandedRowRender: (record) => <ExpandedTableRow record={record} />,
          expandIcon: ({ expanded, onExpand, record }) =>
            expandComponents.expandIcon({
              expanded,
              onExpand,
              record,
            }),
        }}
      />
      {manageModalOpen && (
        <SecurityFindingsModal
          visible={manageModalOpen}
          selectedFindings={selectedRows}
          onClose={() => setManageModalOpen(false)}
        />
      )}
    </Card>
  );
};

export default DataTable;

const ExpandedTableRow = ({ record }: { record: SecurityFindingNode }) => {
  return (
    <div key={record.id} style={{ margin: "0px 0px 0px 90px" }}>
      <p style={{ margin: 0 }}>
        <strong>Remediation Description: </strong>
        {record.remediationDescription ? record.remediationDescription : null}
        <br />
        <strong>Advisor Guidance: </strong>
        {record.advisorGuidance ? record.advisorGuidance : null}
      </p>
    </div>
  );
};
