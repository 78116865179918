import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { BankTwoTone, PlusOutlined } from "@ant-design/icons";
import Api from "api";
import useDocumentTitle from "hooks/useDocumentTitle";
import useAccessControl, { Permissions } from "hooks/useAccessControl";
import useViewport from "hooks/useViewport";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import SmartTable, { compareDates } from "components/Common/Tables/index";
import CreateOrganization from "./CreateOrganization";
import { Row, Col, Card, Button, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { OrganizationNode } from "types";
import CreateModal from "components/Administration/Modals/CreateModal";

const ManageOrganizations: React.FC = () => {
  useDocumentTitle("Organizations");
  const { PBAC } = useAccessControl();
  const viewport = useViewport();

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { data, loading } = useQuery(Api.Organization.GetAll(), {
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const organizations = data?.allOrganizations;
  console.log({ AllOrganizations: organizations });

  const columns: ColumnsType<OrganizationNode> = [
    {
      title: "Created On",
      dataIndex: "createdOn",
      defaultSortOrder: "descend",
      sorter: (a, b) => compareDates(a.createdOn, b.createdOn),
      render: (createdOn) => createdOn && new Date(createdOn).toDateString(),
    },
    { title: "Name", dataIndex: "name" },
    { title: "Doing Business As", dataIndex: "doingBusinessAs" },
    { title: "Point of Contact", dataIndex: "pocName" },
    {
      title: "Updated On",
      dataIndex: "updatedOn",
      sorter: (a, b) => compareDates(a.updatedOn, b.updatedOn),
      render: (updatedOn) => updatedOn && new Date(updatedOn).toDateString(),
    },
    {
      title: "Action",
      align: "center",
      render: (_, organization) => (
        <Link to={`/administration/organizations/manage/${organization.id}`}>Manage</Link>
      ),
    },
  ];

  const headerActions = PBAC([Permissions.CreateOrganizations])
    ? [
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setCreateModalOpen(true)}
        >
          Create
        </Button>,
      ]
    : undefined;

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="Organizations"
        icon={<BankTwoTone />}
        actions={headerActions}
      />
      <Row align="middle">
        <Col span={24}>
          <Card className="shadowed-static" bodyStyle={viewport.card.styles.bodyStyle}>
            <SmartTable loading={loading} data={organizations} columns={columns} />
          </Card>
        </Col>
      </Row>

      {createModalOpen && (
        <CreateModal
          title="Create Organization"
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
        >
          <CreateOrganization onClose={() => setCreateModalOpen(false)} />
        </CreateModal>
      )}
      <Outlet />
    </>
  );
};

export default ManageOrganizations;
