import { SaveOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";

type Props = {
  options?: Array<any>;
  loading: Loading;
  value: string | undefined;
  onChange: (arg0: any) => void;
  onSave: any;
};

const ParentSelectField: React.FC<Props> = ({ options, loading, value, onChange, onSave }) => {
  return (
    <Input.Group compact>
      <Select
        options={options}
        style={{ width: 200 }}
        loading={loading}
        value={value}
        onChange={(val) => onChange(val)}
        placement="bottomRight"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 300 }}
      />
      <Button icon={<SaveOutlined />} type="primary" onClick={onSave} />
    </Input.Group>
  );
};

export default ParentSelectField;
