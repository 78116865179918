import React, { useState } from "react";
import { Form, Input, Button, Select, Row, Col, message, InputNumber, Divider } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import Api from "api";
import { SalutationEnums, UserRolesEnums } from "api/enums";

type Props = {
  onClose: () => void;
};

const CreateUser: React.FC<Props> = ({ onClose }) => {
  const [form] = Form.useForm();
  const selectedRole = Form.useWatch("role", form);

  const [allOrganizations, setAllOrganizations] = useState([]);
  const [allApplications, setAllApplications] = useState([]);

  useQuery(Api.Organization.GetAllBasicList(), {
    onCompleted: (res) => {
      console.log({ AllOrganizations: res.allOrganizations });
      setAllOrganizations(res.allOrganizations);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  useQuery(Api.Application.GetAllBasicList(), {
    onCompleted: (res) => {
      console.log({ AllApplications: res.allApplications });
      setAllApplications(res.allApplications);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const [createUser, { loading: createLoading }] = useMutation(Api.User.Create(), {
    onCompleted: () => {
      message.success("Successfully created a User", 7);
      form.resetFields();
      onClose();
    },
    onError: (error) => {
      message.error(error.message, 7);
    },
    refetchQueries: [Api.User.GetAll()],
  });

  const onFinish = (values: any) => {
    createUser({
      variables: {
        role: selectedRole,
        applicationId: values.applicationId,
        organizationId: values.organizationId,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        email: values.email,
        salutation: values.salutation,
        phoneNumber: values.phoneNumber,
      },
    });
  };

  return (
    <Form
      form={form}
      name="createUserForm"
      id="createUserForm"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      initialValues={{ role: "APPLICATION_USER" }}
      style={{ width: "100%" }}
    >
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item label="Role" name="role">
            <Select options={UserRolesEnums} />
          </Form.Item>
        </Col>

        {selectedRole === "ORGANIZATION_ADMINISTRATOR" && (
          <Col span={11}>
            <Form.Item
              label="Organization"
              name="organizationId"
              rules={[
                {
                  required: true,
                  message: "Please select an organization",
                },
              ]}
            >
              <Select
                options={allOrganizations.map((o: OrganizationDataType) => ({
                  value: o.id,
                  label: o.name,
                }))}
              />
            </Form.Item>
          </Col>
        )}

        {(selectedRole === "CSAR_ADVISOR" || selectedRole === "APPLICATION_USER") && (
          <Col span={11}>
            <Form.Item
              label="Application"
              name="applicationId"
              rules={[{ required: true, message: "Please select an application" }]}
            >
              <Select
                options={allApplications.map((a: ApplicationDataType) => ({
                  value: a.id,
                  label: a.name,
                }))}
                dropdownMatchSelectWidth={false}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Divider />
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Please input a first name" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please input a last name" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input a valid email address",
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter a password",
              },
              {
                message: "Please enter valid password",
                validator(_, value) {
                  const hasSpecial = /[^a-z0-9]/gi.test(value);
                  const hasNumber = /[0-9]/gi.test(value);
                  const hasCapital = /[A-Z]/.test(value);
                  const hasLength = value.length >= 8;
                  const passwordValid = hasSpecial && hasLength && hasCapital && hasNumber;
                  console.log({ value, hasSpecial, hasLength, hasCapital, hasNumber });


                  if (!value || passwordValid) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("Passwords do not match"));
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col span={11}>
          <Form.Item label="Phone" name="phone">
            <InputNumber addonBefore="1" controls={false} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item label="Salutation" name="salutation" style={{ textAlign: "left" }}>
            <Select options={SalutationEnums} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end" align="bottom" style={{ marginTop: 16, height: 32 }}>
        <Form.Item name="submit">
          <Button
            type="primary"
            form="createUserForm"
            htmlType="submit"
            loading={createLoading}
            role="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default CreateUser;
