import { FieldEditState, SaveStatusEnum } from "../../Shared/types";

export type GroupFieldKeys =
  | "groupType"
  | "header"
  | "description"
  | "parentCategory"
  | "order"
  | "questions"
  | "questionOrder";

export enum GroupFieldKeysEnum {
  groupType = "groupType",
  header = "header",
  description = "description",
  parentCategory = "parentCategory",
  order = "order",
  questions = "questions",
  questionOrder = "questionOrder",
}

export type EditStateType = {
  fieldKey?: GroupFieldKeys;
  editActive: boolean;
};

export type GroupEditorState = {
  groupType: FieldEditState;
  header: FieldEditState;
  description: FieldEditState;
  parentCategory: FieldEditState;
  order: FieldEditState;
  questions: FieldEditState;
  questionOrder: FieldEditState;
  update: () => void;
};

export enum GroupEditorReducerEnum {
  ToggleEdit = "toggleEdit",
  SetLoading = "setLoading",
  SetSuccess = "setSuccess",
  SetError = "setError",
  SetDefault = "setDefault",
}

export type GroupEditorReducer =
  | { type: GroupEditorReducerEnum.ToggleEdit; params: any }
  | { type: GroupEditorReducerEnum.SetLoading; params: any }
  | { type: GroupEditorReducerEnum.SetSuccess; params: any }
  | { type: GroupEditorReducerEnum.SetError; params: any }
  | { type: GroupEditorReducerEnum.SetDefault; params: any };

export const groupEditorReducer = (
  state: GroupEditorState,
  action: GroupEditorReducer,
): any => {
  console.log("%creducerState", "color:blue");
  console.log({ state });
  console.log({ action });

  const fieldKey: GroupFieldKeys = action?.params?.fieldKey;

  switch (action.type) {
    case GroupEditorReducerEnum.ToggleEdit:
      const toggleState: GroupEditorState = { ...state };
      const currentFieldState = toggleState[fieldKey];
      const editActive: boolean | undefined = currentFieldState?.editActive;
      console.log({ editActive, currentFieldState });

      const newFieldState = {
        ...currentFieldState,
        editActive: !editActive,
      };
      console.log({ newFieldState });

      return {
        ...state,
        [fieldKey]: newFieldState,
      };

    case GroupEditorReducerEnum.SetDefault:
      console.log("%cSET DEFAULT!", "color:blue");

      const setDefaultState: GroupEditorState = { ...state };
      setDefaultState[fieldKey].saveStatus = SaveStatusEnum.Default;

      return {
        ...setDefaultState,
      };

    case GroupEditorReducerEnum.SetLoading:
      console.log("%cSET LOADING!", "color:blue");
      console.log({ fieldKey });
      const setLoadingState: GroupEditorState = { ...state };
      console.log({ fieldStateB4: setLoadingState[fieldKey] });
      setLoadingState[fieldKey].saveStatus = SaveStatusEnum.Loading;

      console.log({ fieldState: setLoadingState[fieldKey] });

      return {
        ...setLoadingState,
      };
    case GroupEditorReducerEnum.SetSuccess:
      console.log("%cSET SUCCESS!", "color:blue");
      const setSuccessState: GroupEditorState = { ...state };
      setSuccessState[fieldKey].saveStatus = SaveStatusEnum.Success;
      console.log({ setSuccessState });
      return {
        ...setSuccessState,
      };
    case GroupEditorReducerEnum.SetError:
      console.log("%cSET DEFAULT!", "color:blue");
      const setErrorState: GroupEditorState = { ...state };
      setErrorState[fieldKey].saveStatus = SaveStatusEnum.Error;

      return {
        ...setErrorState,
      };
    default:
      return { ...state };
  }
};
