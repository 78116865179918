import { Grid } from "antd";
import { useEffect, useState } from "react";
const { useBreakpoint } = Grid;

const useDynamicDrawerWidth = () => {
  // for use in editor drawers, width set to match content and conditional UI sizing
  const breakpoints = useBreakpoint();
  const [width, setWidth] = useState<string | number>(1100);

  useEffect(() => {
    console.log({ drawerWidth: width });
  }, [width]);

  useEffect(() => {
    setWidth(returnWidth(breakpoints));
  }, [breakpoints]);
  return width;
};

function returnWidth(breakpoints) {
  // let tempWidth;
  if (breakpoints.xxl) {
    return 1400;
  }
  if (breakpoints.xl) {
    // tempWidth = 1400;
    // return tempWidth;
    return 1300;
  }
  if (breakpoints.lg) {
    // tempWidth = 1100;
    return 900;
    // return tempWidth;
  }
  if (breakpoints.md) {
    // tempWidth = 900;
    // return tempWidth;
    return 700;
  }
  // tempWidth = "100%";
  // return tempWidth;
  return "100%";
}

// function returnWidth(breakpoints) {
//   let tempWidth;
//   if (breakpoints.xl) {
//     tempWidth = 1200;
//     return tempWidth;
//   }
//   if (breakpoints.lg) {
//     tempWidth = 900;
//     return tempWidth;
//   }
//   if (breakpoints.md) {
//     tempWidth = 700;
//     return tempWidth;
//   }
//   tempWidth = "100%";
//   return tempWidth;
// }

export default useDynamicDrawerWidth;
