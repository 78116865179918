import moment from "moment";
import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Typography, Row, Col, Space } from "antd";

import pages from "./Pages";
import { lowerCase } from "lodash";
export const PIAPages = pages;

export const PIAHeader = () => {
  return (
    <Row justify="space-between">
      <div style={{ marginTop: 20 }}></div>
    </Row>
  );
};

export const PIAFooter = ({ state, pageNumber, totalPages }) => {
  const style = {
    paddingTop: 5,
    fontSize: 11,
  };

  const document = state?.document;

  return (
    <Row justify="space-between">
      <Col span={8}>
        <p style={style}>
          <b>
            DD FORM {document?.formNumber}, {moment(document?.revisionDate).format("MMM YYYY").toUpperCase()}
          </b>
        </p>
      </Col>
      <Col span={8}>
        <center style={style}>PREVIOUS EDITION IS OBSOLETE.</center>
      </Col>
      <Col span={8}>
        <p style={{ ...style, textAlign: "right" }}>
          Page: {pageNumber} of {totalPages}
        </p>
      </Col>
    </Row>
  );
};

export const AlignedEditableCheckbox = (props) => (
  <Row>
    <Space>
      <EditableCheckbox {...props} />
      <Typography.Text>{props.text}</Typography.Text>
    </Space>
  </Row>
);

export const AlignedEditableRadio = (props) => (
  <Row>
    <Space>
      <EditableRadio {...props} />
      <Typography.Text>{props.text}</Typography.Text>
    </Space>
  </Row>
  // <Row gutter={8}>
  //   <Col span={1.5}>
  //     <EditableRadio {...props} />
  //   </Col>
  //   <Col span={20}>
  //     <Typography.Text>{props.text}</Typography.Text>
  //   </Col>
  // </Row>
);

export const DodComponentNameSelect = ({ elementProps, config, style = {} }) => {

  const options = [
    { label: "Assistant to the Secretary of Defense for Homeland Defense", value: `${config.value}_assistant_to_the_secretary_of_defense_for_homeland_defense` },
    { label: "Assistant Secretary of Defense - International Security Affairs", value: `${config.value}_assistant_secretary_of_defense_international_security_affairs` },
    { label: "Assistant Secretary of Defense - International Security Policy", value: `${config.value}_assistant_secretary_of_defense_international_security_policy` },
    { label: "Assistant Secretary of Defense - Legal Affairs", value: `${config.value}_assistant_secretary_of_defense_legal_affairs` },
    { label: "Assistant Secretary of Defense - Networks and Information", value: `${config.value}_assistant_secretary_of_defense_networks_and_information` },
    { label: "Assistant to the Secretary of Defense - Public Affairs", value: `${config.value}_assistant_to_the_secretary_of_defense_public_affairs` },
    { label: "Assistant Secretary of Defense - Reserve Affairs", value: `${config.value}_assistant_secretary_of_defense_reserve_affairs` },
    { label: "Assistant Secretary of Defense - Special Operations/Low Intensity Conflict", value: `${config.value}_assistant_secretary_of_defense_special_operations_low_intensity_conflict` },
    { label: "Assistant Secretary of Defense for Research and Engineering", value: `${config.value}_assistant_secretary_of_defense_for_research_and_engineering` },
    { label: "Assistant Secretary of Defense/Health Affairs", value: `${config.value}_assistant_secretary_of_defense_health_affairs` },
    { label: "Counterintelligence Field Activity", value: `${config.value}_counterintelligence_field_activity` },
    { label: "Defense Acquisition University", value: `${config.value}_defense_acquisition_university` },
    { label: "Defense Advanced Research Projects Agency", value: `${config.value}_defense_advanced_research_projects_agency` },
    { label: "Defense Commissary Agency", value: `${config.value}_defense_commissary_agency` },
    { label: "Defense Contract Audit Agency", value: `${config.value}_defense_contract_audit_agency` },
    { label: "Defense Contract Management Agency", value: `${config.value}_defense_contract_management_agency` },
    { label: "Defense Finance and Accounting Service", value: `${config.value}_defense_finance_and_accounting_service` },
    { label: "Defense Health Agency", value: `${config.value}_defense_health_agency` },
    { label: "Defense Human Resources Activity", value: `${config.value}_defense_human_resources_activity` },
    { label: "Defense Information Systems Agency", value: `${config.value}_defense_information_systems_agency` },
    { label: "Defense Intelligence Agency", value: `${config.value}_defense_intelligence_agency` },
    { label: "Defense Legal Services Agency", value: `${config.value}_defense_legal_services_agency` },
    { label: "Defense Logistics Agency", value: `${config.value}_defense_logistics_agency` },
    { label: "Defense Media Activity", value: `${config.value}_defense_media_activity` },
    { label: "Defense Personnel Accounting Agency", value: `${config.value}_defense_personnel_accounting_agency` },
    { label: "Defense Security Cooperation Agency", value: `${config.value}_defense_security_cooperation_agency` },
    { label: "Defense Security Service", value: `${config.value}_defense_security_service` },
    { label: "Defense Technical Information Center", value: `${config.value}_defense_technical_information_center` },
    { label: "Defense Technology Security Administration", value: `${config.value}_defense_technology_security_administration` },
    { label: "Defense Test Resource Management Center", value: `${config.value}_defense_test_resource_management_center` },
    { label: "Defense Threat Reduction Agency", value: `${config.value}_defense_threat_reduction_agency` },
    { label: "Department of Defense - Chief Information Officer", value: `${config.value}_department_of_defense_chief_information_officer` },
    { label: "Department of Defense Education Activity", value: `${config.value}_department_of_defense_education_activity` },
    { label: "Department of Defense Inspector General", value: `${config.value}_department_of_defense_inspector_general` },
    { label: "Department of the Navy", value: `${config.value}_department_of_the_navy` },
    { label: "Department of the Navy/United States Marine Corps", value: `${config.value}_department_of_the_navy_united_states_marine_corps` },
    { label: "Deputy Chief Management Officer", value: `${config.value}_deputy_chief_management_officer` },
    { label: "Deputy Undersecretary of Defense for Installations and Environmental Security", value: `${config.value}_deputy_undersecretary_of_defense_for_installations_and_environmental_security` },
    { label: "Director, Administration", value: `${config.value}_director_administration` },
    { label: "Director, Cost Assessment and Program Evaluation", value: `${config.value}_director_cost_assessment_and_program_evaluation` },
    { label: "Director, Force Transformation", value: `${config.value}_director_force_transformation` },
    { label: "Director, Net Assessment", value: `${config.value}_director_net_assessment` },
    { label: "Director, Operational Test and Evaluation", value: `${config.value}_director_operational_test_and_evaluation` },
    { label: "DoD Business Enterprise", value: `${config.value}_do_d_business_enterprise` },
    { label: "DoD Force Protection", value: `${config.value}_do_d_force_protection` },
    { label: "General Counsel of the Department of Defense", value: `${config.value}_general_counsel_of_the_department_of_defense` },
    { label: "Inter-American Defense. Board", value: `${config.value}_inter_american_defense_board` },
    { label: "Joint Improvised Explosive Device Defeat Organization", value: `${config.value}_joint_improvised_explosive_device_defeat_organization` },
    { label: "Joint Program Executive Office for Joint Tactical Radio System", value: `${config.value}_joint_program_executive_office_for_joint_tactical_radio_system` },
    { label: "Joint Staff", value: `${config.value}_joint_staff` },
    { label: "Missile Defense Agency", value: `${config.value}_missile_defense_agency` },
    { label: "National Defense University", value: `${config.value}_national_defense_university` },
    { label: "National Geospatial-Intelligence Agency", value: `${config.value}_national_geospatial_intelligence_agency` },
    { label: "National Guard Bureau", value: `${config.value}_national_guard_bureau` },
    { label: "National Reconnaissance Office", value: `${config.value}_national_reconnaissance_office` },
    { label: "National Security Agency", value: `${config.value}_national_security_agency` },
    { label: "North American Aerospace Defense Command", value: `${config.value}_north_american_aerospace_defense_command` },
    { label: "Office of Economic Adjustment", value: `${config.value}_office_of_economic_adjustment` },
    { label: "Office of Military Commissions", value: `${config.value}_office_of_military_commissions` },
    { label: "Office of Secretary of Defense", value: `${config.value}_office_of_secretary_of_defense` },
    { label: "Office of the Deputy Assistant Secretary of Defense for Counter-Narcotics", value: `${config.value}_office_of_the_deputy_assistant_secretary_of_defense_for_counter_narcotics` },
    { label: "Office of the Secretary of Defense (Chief Information Officer)", value: `${config.value}_office_of_the_secretary_of_defense_chief_information_officer` },
    { label: "Outside DoD", value: `${config.value}_outside_do_d` },
    { label: "Pentagon Force Protection Agency", value: `${config.value}_pentagon_force_protection_agency` },
    { label: "U.S. Coast Guard", value: `${config.value}_u_s_coast_guard` },
    { label: "U.S. Mission to NATO", value: `${config.value}_u_s_mission_to_nato` },
    { label: "Under Secretary of Defense (Comptroller)/Chief Financial Officer", value: `${config.value}_under_secretary_of_defense_comptroller_chief_financial_officer` },
    { label: "Under Secretary of Defense for Acquisition, Technology and Logistics", value: `${config.value}_under_secretary_of_defense_for_acquisition_technology_and_logistics` },
    { label: "Under Secretary of Defense for Intelligence", value: `${config.value}_under_secretary_of_defense_for_intelligence` },
    { label: "Under Secretary of Defense for Personnel and Readiness", value: `${config.value}_under_secretary_of_defense_for_personnel_and_readiness` },
    { label: "Under Secretary of Defense for Policy", value: `${config.value}_under_secretary_of_defense_for_policy` },
    { label: "United States Africa Command (USAFRICOM)", value: `${config.value}_united_states_africa_command_usafricom` },
    { label: "United States Air Force", value: `${config.value}_united_states_air_force` },
    { label: "United States Army", value: `${config.value}_united_states_army` },
    { label: "United States Central Command (USCENTCOM)", value: `${config.value}_united_states_central_command_uscentcom` },
    { label: "United States Cyber Command (USCYBERCOM)", value: `${config.value}_united_states_cyber_command_uscybercom` },
    { label: "United States European Command (USEUCOM)", value: `${config.value}_united_states_european_command_useucom` },
    { label: "United States Forces Korea", value: `${config.value}_united_states_forces_korea` },
    { label: "United States Joint Forces Command", value: `${config.value}_united_states_joint_forces_command` },
    { label: "United States Northern Command (USNORTHCOM)", value: `${config.value}_united_states_northern_command_usnorthcom` },
    { label: "United States Pacific Command (USPACOM)", value: `${config.value}_united_states_pacific_command_uspacom` },
    { label: "United States Southern Command (USSOUTHCOM)", value: `${config.value}_united_states_southern_command_ussouthcom` },
    { label: "United States Space Command (USSPACECOM)", value: `${config.value}_united_states_space_command_usspacecom` },
    { label: "United States Special Operations Command (USSOCOM)", value: `${config.value}_united_states_special_operations_command_ussocom` },
    { label: "United States Strategic Command (USSTRATCOM)", value: `${config.value}_united_states_strategic_command_usstratcom` },
    { label: "United States Transportation Command (USTRANSCOM)", value: `${config.value}_united_states_transportation_command_ustranscom` },
    { label: "US Army NATO", value: `${config.value}_us_army_nato` },
    { label: "Washington Headquarters Service", value: `${config.value}_washington_headquarters_service` },
    { label: "White House Communications Agency", value: `${config.value}_white_house_communications_agency` },
    { label: "If Other, enter the Component name in the box below.", value: `${config.value}_if_other_enter_the_component_name_in_the_box_below` },
  ]

  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={options}
    />
  );
};

