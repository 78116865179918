import React from "react";
import Account from "./Account";
import InformationTab from "./Tabs/InformationTab";
import OrganizationsTab from "./Tabs/OrganizationsTab";
import ApplicationsTab from "./Tabs/ApplicationsTab";
import ConfigurationsTab from "./Tabs/ConfigurationsTab";
import {
  ApartmentOutlined,
  ApiOutlined,
  BankOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const ApplicantAccount: React.FC = () => {
  const items = [
    {
      key: "information",
      label: (
        <>
          <InfoCircleOutlined /> Information
        </>
      ),
      children: <InformationTab />,
    },
    {
      key: "organizations",
      label: (
        <>
          <BankOutlined />
          Organizations
        </>
      ),
      children: <OrganizationsTab />,
    },
    {
      key: "applications",
      label: (
        <>
          <ApartmentOutlined />
          Applications
        </>
      ),
      children: <ApplicationsTab />,
    },
    // {
    //   key: "configurations",
    //   label: (
    //     <>
    //       <ApiOutlined />
    //       Configurations
    //     </>
    //   ),
    //   children: <ConfigurationsTab />,
    // },
  ];

  return <Account tabs={items} />;
};

export default ApplicantAccount;
