import { useState, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Drawer, message, Row } from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import Api from "api";
import InputConfirmModal from "components/Common/InputConfirmModal";
import { QuestionCategoryNode } from "types";
import { categoryEditorReducer, CategoryEditorState } from "./Components/reducer";
import CategoryEditor from "./CategoryEditor";
import { SaveStatusEnum } from "../Shared/types";
import useDynamicDrawerWidth from "hooks/useDynamicDrawerWidth";
import "../../../../../styles/workflowEditor.css";

const initialCategoryState: QuestionCategoryNode = {
  id: "123",
  name: "Question",
  module: null,
  groups: [],
  questions: [],
  allQuestions: [],
  allQuestionsPlusGrids: [],
  rmfActionPaths: [],
};

const initialState: CategoryEditorState = {
  categoryName: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  categoryOrder: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  parentModule: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  checkpointStatus: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  questionOrder: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  questions: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  rmfPaths: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  rmfAnswers: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
};

const CategoryEditorDrawer: React.FC = () => {
  const width = useDynamicDrawerWidth();
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const [open, setOpen] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<QuestionCategoryNode | any>(
    initialCategoryState,
  );

  const { loading, refetch: refetchCategory } = useQuery(
    Api.QuestionCategory.GetAllQuestionCategories(),
    {
      variables: {
        filter: {
          id: categoryId,
        },
      },
      onCompleted: (res) => {
        console.log({ refetchedCategory: res.allQuestionCategories[0] });
        console.log("%csetSelectedCategory to refetch response!", "color:blue");
        setSelectedCategory(res?.allQuestionCategories[0]);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 7);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const [deleteCategory, { loading: deleteLoading }] = useMutation(
    Api.QuestionCategory.Delete(),
    {
      onCompleted: (res) => {
        console.log(res);
        message.success("Category Successfully deleted", 5);
        setOpen(false);
        setDeleteModalOpen(false);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: Api.QuestionModule.WorkflowEditor() }],
    },
  );

  const [state, dispatch] = useReducer(categoryEditorReducer, initialState);

  useEffect(() => {
    console.log({ stateInDrawer: state });
  }, [state]);

  const onClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const deleteCategoryMutation = () => {
    deleteCategory({
      variables: {
        id: categoryId,
      },
    });
  };

  const invalidCategory = selectedCategory.id === "123";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={width}
      destroyOnClose={true}
      title="Manage Category"
      extra={
        <Button
          danger
          icon={<DeleteOutlined />}
          type="primary"
          key="delete"
          onClick={() => setDeleteModalOpen(true)}
          disabled={invalidCategory}
        >
          Delete Category
        </Button>
      }
      bodyStyle={{
        background: "#f0f0f0",
        overflow: "hidden",
        height: "calc(100vh - 65px) !important",
        maxHeight: "calc(100vh - 65px)",
        padding: 0,
      }}
      className="wf-editor-drawer"
    >
      <CategoryEditor
        state={state}
        dispatch={dispatch}
        category={selectedCategory}
        refetchCategory={refetchCategory}
        loading={loading}
        key="categoryEditor"
      />
      {deleteModalOpen && (
        <InputConfirmModal
          visible={deleteModalOpen}
          loading={deleteLoading}
          prompt="Are you sure you want to delete this Category?"
          mutation={deleteCategoryMutation}
          confirmation="Category"
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
    </Drawer>
  );
};

export default CategoryEditorDrawer;
