import useQuestion from "components/Applicant/Hooks/useQuestion";
import React, { useEffect, useState } from "react";
import { ApplicationQuestionNode } from "types";
import ListQuestion from "../ListQuestion";

export interface FileInputProps {
  answerId: string;
  defaultAnswer: string;
  onChange: (answer: { id: string; text: string }) => void;
  disabled: boolean;
  style?: any;
}

interface Props {
  question: ApplicationQuestionNode;
  defaultAnswer: string;
  input: React.FC<FileInputProps>;
}

const FileUploadQuestion: React.FC<Props> = (props) => {
  const { question, defaultAnswer, input } = props;

  const [saveStatus, setSaveStatus] = useState<string>("");
  const { saveFile, unSaveFile, unSaveLoading } = useQuestion(question, setSaveStatus);

  useEffect(() => {
    setSaveStatus(hasSavedAnswers ? "saved" : "");
  }, [question]);

  const Input = input;
  const hasSavedAnswers = question.isAnswered || question.savedAnswers.length > 0;
  const hasDefaultAnswers = question.isSavedWithDefaults!;
  const isLocked = question.isLocked || hasDefaultAnswers;

  const answerId = question.answerChoices[0]?.id!;

  const onChange = (answer: any) => {
    if (isLocked) return;

    saveFile(question.id, answer);
  };

  return (
    <ListQuestion
      question={question}
      saveStatus={saveStatus}
      unSave={unSaveFile}
      unSaveLoading={unSaveLoading}
    >
      <Input
        answerId={answerId}
        defaultAnswer={defaultAnswer}
        onChange={onChange}
        disabled={isLocked}
      />
    </ListQuestion>
  );
};

export default FileUploadQuestion;
