import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, InformationTypeSelect } from "..";

const informationSelectStyle = {
  fontSize: ".7rem",
  border: "none",
  width: "100%",
  height: "100%",
};

export const P13 = (props) => {
  const { document, state } = props;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  }

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const confidentialityNames = [
    "categorization_information_marking_top_secret",
    "categorization_information_marking_secret",
    "categorization_information_marking_confidential",
    "categorization_information_marking_cui",
    "categorization_information_marking_unclassified",
  ];

  const CIMarking = {
    label: "Select Marking",
    value: "categorization_information_marking",
  };

  let fieldIndex = 278;

  const confidentialityConfig = (row) => ({
    label: "Select One",
    value: `14_r${row}_confidentiality`,
  });

  const integrityConfig = (row) => ({
    label: "Select One",
    value: `14_r${row}_integrity`,
  });

  const availabilityConfig = (row) => ({
    label: "Select One",
    value: `14_r${row}_availability`,
  });

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <b style={{ flex: 1, fontSize: 14 }}>14. Categorization Information</b>

            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={CIMarking} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              padding: "10px 0",
            }}
          >
            <i>
              NOTE 16: Categorize the CIA for APPLICABLE Information Types (i.e., Low,
              Moderate, or High) IAW Refs: (o), (ac), & (ad).
            </i>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.23,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Information Types</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Confidentiality</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Integrity</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Availability</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.23,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Amplifying Data</b>
              </div>
            </div>
          </div>

          {
            Array.from({ length: 11 }, (_, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  border: "1px solid black",
                  borderBottom: "none",
                }}
              >
                <div
                  style={{
                    flex: 0.23,
                    display: "flex",
                    flexDirection: "column",
                    borderRight: "1px solid black",
                    height: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      fontSize: 14,
                    }}
                  >
                    <EditableField borderless {...elementProps} fieldName={`14_r${index + 1}_information_type`} />
                  </div>
                </div>
                <div
                  style={{
                    flex: 0.18,
                    display: "flex",
                    flexDirection: "column",
                    borderRight: "1px solid black",
                    height: "30px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      fontSize: 14,
                    }}
                  >
                    <InformationTypeSelect
                      elementProps={elementProps}
                      config={confidentialityConfig(index + 1)}
                      style={informationSelectStyle}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 0.18,
                    borderRight: "1px solid black",
                    height: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      fontSize: 14,
                    }}
                  >
                    <InformationTypeSelect
                      elementProps={elementProps}
                      config={integrityConfig(index + 1)}
                      style={informationSelectStyle}
                    />
                  </div>
                </div>
                <div
                  style={{
                    flex: 0.18,
                    display: "flex",
                    flexDirection: "column",
                    borderRight: "1px solid black",
                    height: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      fontSize: 14,
                    }}
                  >
                    <InformationTypeSelect
                      elementProps={elementProps}
                      config={availabilityConfig(index + 1)}
                      style={informationSelectStyle}
                    />
                  </div>
                </div>
                <div
                  style={{
                    flex: 0.23,
                    display: "flex",
                    flexDirection: "column",
                    borderRight: "none",
                    height: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      fontSize: 14,
                    }}
                  >
                    <EditableField
                      borderless
                      {...elementProps}
                      fieldName={`14_r${index + 1}_amplifying_data`}
                    />
                  </div>
                </div>
              </div>
            ))
          }

          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "60px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  padding: "0 10px",
                  width: "100%",
                  height: "100%",
                  fontSize: 12,
                }}
              >
                <b>FINAL SECURITY CATEGORIZATION</b>
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "60px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(12)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "60px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  borderless
                  elementProps={elementProps}
                  config={integrityConfig(12)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "60px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  borderless
                  elementProps={elementProps}
                  config={availabilityConfig(12)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "60px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r12_amplifying_data"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
