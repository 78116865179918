import { gql } from "graphql-tag";

export const QuestionCategory = {
  GetByQuestionId: () => gql`
    query oneQuestionCategory($filter: QuestionCategoryFilterInputType) {
      allQuestionCategories(filter: $filter) {
        id
        name
        order
        hasCheckpoint
        groups {
          header
          id
          groupType
          order
        }
        rmfActionPaths {
          path
          categoryId
        }
        module {
          id
          name
        }
        allQuestions {
          order
          groupOrder
          categoryId
          groupId
          question
          questionType
          id
          helpText
          category {
            id
            name
            module {
              id
              name
            }
          }
        }
      }
    }
  `,
  GetAllQuestionCategories: () => gql`
    query getAllQuestionCategories($filter: QuestionCategoryFilterInputType) {
      allQuestionCategories(filter: $filter) {
        id
        name
        order
        hasCheckpoint
        module {
          id
          name
        }
        groups {
          header
          id
          groupType
          # category
          order
        }
        rmfActionPaths {
          path
          categoryId
        }
        rmfActionPaths {
          path
          categoryId
        }
        questions {
          id
          question
          questionType
          categoryId
          groupId
          order
          groupOrder
        }
        allQuestions {
          order
          groupOrder
          categoryId
          groupId
          question
          questionType
          id
          helpText
          answers {
            id
            answer
            timeIncreaseInDays
            riskIncrease
          }
          defaultAnswers {
            id
            path
            questionId
            answerId
            textAnswer
          }
          category {
            id
            name
            module {
              id
              name
            }
          }
        }
      }
    }
  `,
  GetBasicList: () => gql`
    query basicCategoryList($filter: QuestionCategoryFilterInputType) {
      allQuestionCategories(filter: $filter) {
        id
        name
        module {
          id
          name
        }
      }
    }
  `,
  Create: () => gql`
    mutation createQuestionCategory($moduleId: String!, $name: String!, $order: Int) {
      createQuestionCategory(moduleId: $moduleId, name: $name, order: $order) {
        id
        name
        order
      }
    }
  `,
  Update: () => gql`
    mutation updateQuestionCategory(
      $id: String!
      $moduleId: String
      $name: String
      $order: Int
      $rmfActionPath: [RMFActionPath]
    ) {
      updateQuestionCategory(
        id: $id
        moduleId: $moduleId
        name: $name
        order: $order
        rmfActionPath: $rmfActionPath
      ) {
        id
        name
        order
        hasCheckpoint
        module {
          id
          name
        }
        rmfActionPaths {
          path
          categoryId
        }
        allQuestions {
          id
          question
          questionType
          order
          answers {
            id
            answer
          }
        }
        groups {
          id
          header
        }
      }
    }
  `,
  Delete: () => gql`
    mutation deleteQuestionCategory($id: String!) {
      deleteQuestionCategory(id: $id) {
        deletedId
      }
    }
  `,
};
