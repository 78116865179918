import { useState } from "react";
import { Typography, Row, Col, Card, Button, Alert } from "antd";
import SmartTable from "components/Common/Tables/index";

const { Title } = Typography;

const MotarApplicationImport = () => {
  // fetch motar applications
  // feed applications into table
  // user clicks "import" button, adds application id to list
  // user clicks "submit" button, createManyApplications mutation receives all app ids

  const [importLoading, setImportLoading] = useState(false);

  const applications = [
    { id: "app1", name: "Motar App 1" },
    { id: "app2", name: "Motar App 2" },
    { id: "app3", name: "Motar App 3" },
    { id: "app4", name: "Motar App 4" },
    { id: "app5", name: "Motar App 5" },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Import",
      dataIndex: "id",
      align: "right",
      width: "10%",
      render: (id) => {
        return (
          <Button type="primary" onClick={() => handleImportClick(id)}>
            Import
          </Button>
        );
      },
    },
  ];

  const handleImportClick = (id) => {
    console.log(id);
  };

  return (
    <>
      <ImportAlert loading={importLoading} />
      <Row justify="center" align="middle">
        <Title level={2}>Import from MOTAR</Title>
        <Col span={24}>
          <Card title={<Title level={3}>Applications</Title>} style={{ textAlign: "left" }}>
            <SmartTable loading={importLoading} data={applications} columns={columns} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MotarApplicationImport;

const ImportAlert = ({ loading }) =>
  loading && (
    <Alert
      type="info"
      style={{ marginBottom: 16 }}
      message={
        <>
          <div>Your MOTAR Applications are being imported</div>
          <div>Please do not navigate away from this page. Progress may be lost.</div>
        </>
      }
    />
  );
