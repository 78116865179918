import React from "react";
import { Row, Col, Typography, Divider, Collapse, Tag, Button } from "antd";
import { useDocument } from "../DocumentEditor";

const { Text, Paragraph } = Typography;

const ListFieldVariable = ({ field }) => {
  var type = ""
  var color = ""
  switch (field.field_type) {
    case "MULTIPLE_CHOICE_PICK_MANY":
      type = "MCPM"
      color = "#ff85c0"
      break
    case "MULTIPLE_CHOICE_PICK_ONE":
      type = "MCPO"
      color = "#69b1ff"

      break
    case "FULFILL_TEXT":
      type = "FFT"
      color = "#95de64"
      break
    case "FULFILL_DATE":
      type = "FFD"
      color = "#5cdbd3"
      break
  }

  return (
    <div style={{ display: 'flex', columnGap: '30px' }}>
      <div>
        <Tag color={color}>{type}</Tag>
      </div>
      <div>{field.field_name}</div>
    </div>
  )
}

const VariableFields = ({ variableFields, formId, dispatch }) => {
  if (variableFields?.current === undefined) return <></>
  const fields = Array.from(variableFields.current.objects)
  const pages = fields.reduce((result, current) => {
    var currPage = result[current.page_number]
    if (currPage === undefined) {
      currPage = []
    }
    result[current.page_number] = [...currPage, current]
    return result
  }, {})

  const fieldsJSON = JSON
    .stringify(fields, null, "\t")
    .replaceAll(
      "],\n\t\"",
      "],\n\n\t\""
    );
    

  console.log(pages[11])

  return (
    <>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
        <Button type="primary" onClick={() => {
          console.log(fieldsJSON)
          navigator.clipboard.writeText(fieldsJSON)
        }}>Copy</Button>
      </div>
      <div style={{ textAlign: 'left', maxHeight: '400px', overflowY: 'scroll' }}>
        <Collapse>
          {Object.entries(pages).map(([key, value]) => (
            <Collapse.Panel header={`Page ${key}`} key={key}>
              {
                value.map((p, i) => <ListFieldVariable field={p} key={`${key}-${p.field_name}`} />)
              }
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </>
  )


}

const DeveloperTools = (props) => {
  const variableFields = useDocument()
  const { assignment, state, dispatch } = props;

  const ErrorFormat = ({ text, solution = null }) => {
    return (
      <>
        <Divider />
        <Row justify={"space-between"}>
          <Text strong>Error:</Text>
          <Text strong type="danger">
            {text}
          </Text>
        </Row>
        {solution && (
          <Row justify={"space-between"}>
            <Text strong>Solution:</Text>
            <Text strong type="success">
              {solution}
            </Text>
          </Row>
        )}
      </>
    );
  };


  if (assignment === undefined) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h4>Developer Tools</h4>
        <VariableFields variableFields={variableFields} formId={state?.formId} />
      </div>
    );
  }

  const { fieldName, fieldType, pageNumber } = assignment;
  const { questionType, document } = state;
  const { name } = document;

  const MismatchTypeError = fieldType !== questionType && (
    <ErrorFormat
      text={"Field Types dont match"}
      solution={"Change 1 field type on either FE or BE such that they match"}
    />
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h4>Developer Tools</h4>
      <h4 style={{ textAlign: "left", marginLeft: "10px" }}>Backend</h4>
      <Row justify={"space-between"}>
        <Col style={{ fontWeight: "bold" }}>Field Name</Col>
        <Col>
          <Text copyable>{fieldName}</Text>
        </Col>
      </Row>
      <Row justify={"space-between"}>
        <Col style={{ fontWeight: "bold" }}>Field Type</Col>
        <Col>{fieldType}</Col>
      </Row>
      <Row justify={"space-between"}>
        <Col style={{ fontWeight: "bold" }}>Document</Col>
        <Col>
          <Text>{name}</Text>
        </Col>
      </Row>
      <Row justify={"space-between"}>
        <Col style={{ fontWeight: "bold" }}>Page</Col>
        <Col>{pageNumber}</Col>
      </Row>
      <h4 style={{ textAlign: "left", marginLeft: "10px", marginTop: "5px" }}>Frontend</h4>
      <Row justify={"space-between"}>
        <Col style={{ fontWeight: "bold" }}>Document Expected Type</Col>
        <Col>{questionType}</Col>
      </Row>
      {MismatchTypeError}
    </div>
  );
};

export default DeveloperTools;
