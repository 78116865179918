import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Button } from "antd";
import P6D3 from "../Assets/P6D3.png";

export const P14 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="instructions">
        <ul className="slash">
          <li>
            Major Modification - The System is undergoing a necessary modification to improve performance
            and reduce ownership costs.
          </li>
        </ul>
        <p><b>8.System Description/Purpose</b></p>

        <p>Prepare a general description of the function and purpose of the System. This description should tell the story of who, what, where, when, why, and how the system supports the warfighter and/or other systemsby answering each of the following</p>

        <ul className="slash">
          <li>
            <span>Describe the purpose of the System and indicate if the system is mission essential to the warfighter</span>
            <p>
              Example: The purpose of the new system is to provide a database of safety information that is searchable via a web interface. The system is not mission essential to the warfighter but is mission essential to the 57th Safety Wing.
            </p>
          </li>

          <li>
            Describe the major hardware/software components of the system. Example: The system consists of multiple Dell PowerEdge 3000 servers loaded with Windows Server 2012R2 and Microsoft SQL Server 2014…
          </li>

          <li>
            Describe the services provided by the system and whether any of the services are publicly accessible (Publicly accessible means does not require authentication to access the information).<i> Example: The system provides, etc.</i>
          </li>

          <li>
            Describe how each of the information types are, or will be, stored, processed, and/or transmitted by the system.
          </li>

          <li>
            If the system is undergoing a major modification, describe the modification and its purpose: otherwise ignore this requirement.
          </li>
        </ul>

        <p><b>9. Boundary Topology and System LocationProvide</b></p>

        <p>Provide a detailed narrative of the system that clearly shows the cybersecurity authorization boundary. This topology should match the infrastructure outlined in both the system and security descriptions.</p>
        <p>
          Required DoDAF Artifact: If external interfaces exist, the follow-on requirement is to generate a comprehensive boundary drawing (DoDAF OV-1 and SV-1) and post it to eMASS as an artifact during or after the initial registration.
        </p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Examples:</span>
          <img style={{ maxWidth: "80%"}} src={P6D3} alt="page 6 diagram 1" />
        </div>

      </div>
    </>
  );
};
