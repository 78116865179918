import { Form, Col, Button, Row, Input, Select, Space } from "antd";
import { DeleteOutlined, SaveOutlined, UserSwitchOutlined } from "@ant-design/icons";
import useEnumPresentation from "hooks/useEnumPresentation";

type Props = {
  initialValues?: User;
  loading: boolean;
  onChange?: any;
  submit: (any) => any;
  disableSubmit?: boolean;
  managingRole: boolean;
  openRoleForm: () => void;
  allowDelete: any;
};

const UserForm: React.FC<Props> = ({
  initialValues,
  loading,
  onChange,
  submit,
  disableSubmit,
  openRoleForm,
  managingRole,
  allowDelete,
}) => {
  const { PresentRoleName } = useEnumPresentation();
  const [form] = Form.useForm();
  const user = initialValues;

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={submit}
      onValuesChange={onChange}
      name="manageUsersForm"
      layout="vertical"
      autoComplete="off"
      style={{ width: "100%" }}
    >
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}>
          <Form.Item label="First Name" name="firstName" style={{ width: "100%" }}>
            <Input style={{ outline: "none" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Last Name" name="lastName" style={{ width: "100%" }}>
            <Input style={{ outline: "none" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="start">
        <Col span={12}>
          <Form.Item label="Email" name="email">
            <Input style={{ outline: "none" }} />
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: "left" }}>
          <Form.Item label="Salutation" name="salutation" style={{ textAlign: "left" }}>
            <Select
              allowClear
              options={[
                { label: "Mr.", value: "Mr." },
                { label: "Ms.", value: "Ms." },
                { label: "Mrs.", value: "Mrs." },
                { label: "Miss", value: "Miss" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="start" style={{ marginBottom: 32 }}>
        <Space direction="vertical">
          <label>Role</label>
          <Space>
            <Input readOnly value={PresentRoleName(user?.role!)} style={{ width: 200 }} />
            <Button onClick={openRoleForm} icon={<UserSwitchOutlined />}>
              Change Role
            </Button>
          </Space>
        </Space>
      </Row>

      <Row
        justify="end"
        style={
          !managingRole
            ? {
                display: "flex",
                justifyContent: "flex-end",
                height: 40,
                bottom: 16,
                right: 16,
                position: "fixed",
              }
            : { height: 40 }
        }
      >
        <Space>
          {allowDelete && (
            <Form.Item>
              <Button type="primary" danger icon={<DeleteOutlined />} onClick={allowDelete}>
                Delete
              </Button>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={disableSubmit}
              icon={<SaveOutlined />}
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Row>
    </Form>
  );
};

export default UserForm;
