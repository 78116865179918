import { useEffect, useState } from "react";
import { message } from "antd";
import useEditorLoading from "hooks/useEditorLoading";
import ContentCard from "../../Shared/ContentCard";
import { SaveStatusEnum as SaveStatus } from "../../Shared/types";
import GroupDescriptionEditor from "./GroupDescriptionEditor";
import { GroupFieldKeysEnum as FieldKeys } from "./reducer";
import Api from "api";

const GroupInformation = (props) => {
  const { loading, state, group, refetchGroup, updateGroup, setSaveState, setEditState } =
    props;

  const descriptionLoading = useEditorLoading(FieldKeys.description, state, loading);
  const headerLoading = useEditorLoading(FieldKeys.header, state, loading);

  const [mdEditorOpen, setMdEditorOpen] = useState(false);

  const [groupValues, setGroupValues] = useState({
    header: undefined,
    description: "",
  });

  const description = group?.description;

  useEffect(() => {
    if (!group?.description || group.description.length < 1) return;

    setGroupValues({
      ...groupValues,
      description: group.description,
    });
  }, [group]);

  const updateGroupInfo = (fieldKey: FieldKeys) => {
    setSaveState(fieldKey, SaveStatus.Loading);
    setEditState(fieldKey);
    updateGroup({
      variables: {
        id: group.id,
        ...groupValues,
      },
      onCompleted: (res) => {
        console.log({ updateQuestionGroup: res });
        setSaveState(fieldKey, SaveStatus.Success);
        refetchGroup();
        // update();
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(fieldKey, SaveStatus.Error);
      },
      notifyOnNetworkStatusChange: true,
      refetchQueries: [{ query: Api.QGroup.getAllTheQuestionGroups() }],
    });
  };

  const handleMarkdownValue = (value: string) => {
    setGroupValues({
      ...groupValues,
      description: value,
    });
  };

  const openMdEditor = () => {
    setMdEditorOpen(true);
    setEditState(FieldKeys.description);
  };

  const closeMdEditor = () => {
    setMdEditorOpen(false);
    setEditState(FieldKeys.description);
    if (description) {
      setGroupValues({
        ...groupValues,
        description,
      });
    }
  };

  const handleValuesChange = (value: string, fieldKey: string) => {
    setGroupValues({
      ...groupValues,
      [fieldKey]: value,
    });
  };

  useEffect(() => {
    console.log({ groupValues: groupValues });
  }, [groupValues]);

  return (
    <>
      <ContentCard
        title="Group Header"
        loading={headerLoading}
        fieldKey={FieldKeys.header}
        editableField={true}
        setEditState={setEditState}
        onValuesChange={handleValuesChange}
        formValues={groupValues}
        onSave={() => updateGroupInfo(FieldKeys.header)}
        saveStatus={state[FieldKeys.header].saveStatus}
        editingCurrentField={state[FieldKeys.header].editActive}
        renderSaveStatus={state[FieldKeys.header].saveStatus !== SaveStatus.Default}
      >
        {group?.header}
      </ContentCard>
      <GroupDescriptionEditor
        title="Group Description"
        textValue={description}
        loading={descriptionLoading}
        fieldKey={FieldKeys.description}
        setEditState={() => setEditState(FieldKeys.description)}
        onValuesChange={handleValuesChange}
        formValues={groupValues}
        mdEditorOpen={mdEditorOpen}
        openMdEditor={openMdEditor}
        closeMdEditor={closeMdEditor}
        mdValue={groupValues.description}
        onMdChange={handleMarkdownValue}
        clearMdValue={() => handleMarkdownValue("")}
        onSave={() => updateGroupInfo(FieldKeys.description)}
        saveStatus={state[FieldKeys.description].saveStatus}
        editingCurrentField={state[FieldKeys.description].editActive}
        renderSaveStatus={state[FieldKeys.description].saveStatus !== SaveStatus.Default}
      />
    </>
  );
};

export default GroupInformation;
