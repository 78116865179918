export const P19 = (props) => {
  return (
    <>
      <div className="itcsc" style={{ display: "grid", placeItems: "center" }}>
        <div className="references offset" style={{ width: '100%' }}>
          <ul>
            <li style={{ listStyle: 'none' }}>
              <ul>
                <li style={{ listStyle: 'none' }}>
                  and Inventorying of National Security Systems Guidance, 25 February 2022
                </li>
              </ul>
            </li>
          </ul>

        </div>
        <div className="references offset" style={{ width: '100%', textAlign: 'left' }}>
          <ol>
            <li>DOD Cloud Computing Security Requirements Guide, Version 1 Release 4, 20 January 2022</li>
            <li>NIST SP 800-210, General Access Control Guidance for Cloud Systems, Jul 2020</li>
            <li>CNSSI 1253F Attachment (Atch) 4, Intelligence Overlay, 19 April 2016</li>
            <li>CNSSI 1253E Atch 3, Cross Domain Solution Overlay, 08 February 2023</li>
            <li>The Nuclear Command, Control & Communications (NC3) Overlay is found at https://rmfks.osd.mil/rmf/HelpandResources/References/Reference%20Library/NC3_Overlay.pdf</li>
            <li>CNSSI 1253F Atch 2, Space Platform Overlay, 23 February 2018</li>
            <li>CNSSI 1253E Atch 5, Classified Information Overlay, 30 September 2022</li>
            <li>NIST SP 800-39, Managing Information Security Risk: Organization, Mission, and Information System View, 01 March 2011</li>
            <li>NIST SP 800-122, Guide to Protecting the Confidentiality of Personally Identifiable Information (PII), 06 April 2010</li>
            <li>DOD Chief Information Officer Memorandum, Treatment of Personally Identifiable Information within Information Impact<br /> Level 2 Commercial Cloud Services for the Department of Defense, 07 August 2019</li>
          </ol>
        </div>
        <div className="references offset-26" style={{ width: '100%', textAlign: 'left' }}>
          <ol>
            <li>CNSSI 1253F Atch 6, Privacy Overlay, 23 April 2015</li>
            <li>United States Office of Personnel Management, System of Records Notice (SORN) Guide, 22 April 2010</li>
            <li>Federal Information Processing Standards Publication 199, Standards for Security Categorization of Federal Information and<br /> Information Systems, February 2004</li>
            <li>NIST SP 800-60 Volumes 1 & 2 Revision 1, Guide for Mapping Types of Information and Information Systems to Security<br /> Categories, 01 August 2008</li>
          </ol>
        </div>
      </div>
    </>
  );
};
