import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

import P8D1 from "../Assets/P8D1.png";
import P8D2 from "../Assets/P8D2.png";

export const P8 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <>
      <div
        className="doc_pcil"
        style={{
          width: "95%",
          fontSize: 12,
        }}
      >
        <div style={{ paddingLeft: 80 }}>
          <img src={P8D1} alt="P8D1" style={{ width: "100%" }} />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>
            Factor 1. Select Identifiability impact value:
          </b>

          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <EditableRadio {...elementProps} fieldName="8_identifiable_impact_low" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>LOW</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="8_identifiable_impact_moderate" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>MODERATE</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="8_identifiable_impact_high" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>HIGH</span>
            </div>
          </div>
          <br />
          <b>Provide justification:</b>
          <div>
            <EditableField
              {...elementProps}
              fieldName="8_identifiable_impact_text"
              style={{ width: "100%", height: 100 }}
            />
          </div>
          <br />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>FACTOR 2 - QUANTITY OF PII</b>
        </div>
        <div style={{ paddingLeft: 80 }}>
          <img src={P8D2} alt="P8D2" style={{ width: "100%" }} />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>
            Factor 2. Select Quantity of PII impact value:
          </b>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <EditableRadio {...elementProps} fieldName="8_quantity_of_impact_low" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>LOW</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="8_quantity_of_impact_moderate" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>MODERATE</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="8_quantity_of_impact_high" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>HIGH</span>
            </div>
          </div>
          <br />
          <b>Provide justification:</b>
          <div>
            <EditableField
              {...elementProps}
              fieldName="8_quantity_of_impact_text"
              style={{ width: "100%", height: 100 }}
            />
          </div>
          <br />
        </div>

        <br />
        <br />
        <div style={{ paddingLeft: 80 }}>
          <span style={{ fontSize: 8 }}>5 </span>Ibid
        </div>
      </div>
    </>
  );
};
