import { P1 } from "./P1";
import { P2 } from "./P2";
import { P3 } from "./P3";
import { P4 } from "./P4";
import { P5 } from "./P5";
import { P6 } from "./P6";
import { P7 } from "./P7";
import { P8 } from "./P8";
import { P9 } from "./P9";
import { P10 } from "./P10";
import { P11 } from "./P11";
import { P12 } from "./P12";
import { P13 } from "./P13";
import { P14 } from "./P14";
import { P15 } from "./P15";
import "../style.css";

// prettier-ignore
const pages = [
  P1,
  P2,
  P3,
  P4,
  P5,
  P6,
  P7,
  P8,
  P9,
  P10,
  P11,
  P12,
  P13,
  P14,
  P15,
];

export default pages;
