import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Typography, Form, message, Button, Row, Radio, Select, Space } from "antd";
import Api from "api";
import useEnumPresentation from "hooks/useEnumPresentation";
import { ApplicationNode, OrganizationNode } from "types";
import { SaveOutlined } from "@ant-design/icons";
const { Title } = Typography;

// TODO: Swap dedicated queries to checking cache, or latching on to prior allApps query

interface RoleModalProps {
  user: User;
  closeView?: () => any;
}

const ManageRoleForm: React.FC<RoleModalProps> = ({ user, closeView }) => {
  const { PresentRoleName } = useEnumPresentation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const selectedRole = Form.useWatch("role", form);

  const [allRoles, setAllRoles] = useState<any>([]);
  const { loading: rolesLoading } = useQuery(Api.UserRole.GetAll(), {
    onCompleted: (res) => {
      const allRoles: any = {
        administrators: res.allRoles.administrators,
        advisors: res.allRoles.advisors,
        users: res.allRoles.users,
      };
      const roleKeys = Object.keys(allRoles);
      const structure = roleKeys.map((key) => {
        const roles = allRoles[key];
        return {
          title: key,
          roles: roles.map((role: any) => ({
            label: PresentRoleName(role.role),
            value: role.role,
          })),
        };
      });
      console.log({ Roles: structure });
      setAllRoles(structure);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const { data: orgList, loading: orgsLoading } = useQuery(Api.Organization.GetAll(), {
    onCompleted: (res) => {
      console.log({ Orgs: res.allOrganizations });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const { data: appList, loading: appsLoading } = useQuery(Api.Application.GetAll(), {
    onCompleted: (res) => {
      console.log({ Apps: res.allApplications });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const [updateRole, { loading: updateLoading }] = useMutation(Api.UserRole.UpdateRole(), {
    onCompleted: () => {
      message.success("Successfully Update User Role", 7);
      form.resetFields();
      navigate("/administration/users");
    },
    onError: (error) => {
      message.error(error.message, 7);
    },
    refetchQueries: [{ query: Api.User.GetAll() }],
  });

  const onFinish = (values: any) => {
    updateRole({
      variables: {
        userId: user.id,
        role: selectedRole,
        applicationId: values.applicationId,
        organizationId: values.organizationId,
      },
    });
  };

  const applicationOptions = appList?.allApplications.map((a: ApplicationNode) => ({
    value: a.id,
    label: a.name,
  }));

  const organizationOptions = orgList?.allOrganizations.map((o: OrganizationNode) => ({
    value: o.id,
    label: o.name,
  }));

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={user}
      name="manageUserRoleForm"
    >
      <Form.Item name="role">
        <Radio.Group disabled={rolesLoading} optionType="button" buttonStyle="solid">
          {allRoles.map((r: any, i: number) => (
            <React.Fragment key={i}>
              <Title level={5} style={{ textTransform: "capitalize", marginBottom: 20 }}>
                {r.title}
              </Title>
              {r.roles.map((r: any, i: number) => (
                <Radio.Button key={i} value={r.value} style={{ marginBottom: 20 }}>
                  {r.label}
                </Radio.Button>
              ))}
            </React.Fragment>
          ))}
        </Radio.Group>
      </Form.Item>
      {selectedRole === "ORGANIZATION_ADMINISTRATOR" && (
        <Form.Item
          label={<Title level={5}>Organization</Title>}
          name="organizationId"
          style={{ width: "calc(50% - 16px)" }}
          rules={[
            {
              required: true,
              message: "Please select an organization",
            },
          ]}
        >
          <Select options={organizationOptions} loading={orgsLoading} />
        </Form.Item>
      )}
      {(selectedRole === "CSAR_ADVISOR" || selectedRole === "APPLICATION_USER") && (
        <Form.Item
          label={<Title level={5}>Application</Title>}
          name="applicationId"
          style={{ width: "calc(50% - 16px)" }}
          rules={[{ required: true, message: "Please select an application" }]}
        >
          <Select options={applicationOptions} loading={appsLoading} />
        </Form.Item>
      )}
      <Row justify="end">
        <Space>
          <Button onClick={closeView}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            disabled={rolesLoading}
            loading={updateLoading}
          >
            Save Role
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default ManageRoleForm;
