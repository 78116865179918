import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

export const P14 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <>
      <div
        className="doc_pcil"
        style={{
          width: "95%",
          fontSize: 14,
          paddingTop: 20,
        }}
      >
        <div style={{ paddingLeft: 20 }}>
          <div style={{ fontSize: 16 }}>
            <p>
              <b>Appendix B — Signatures</b>
            </p>
          </div>

          <div style={{ paddingLeft: 20 }}>
            <div style={{ fontSize: 16 }}>
              <p>
                <u>Information System Security Manager</u>
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />

          <div
            style={{ width: "40%", marginBottom: 5, height: 2, backgroundColor: "black" }}
          />
          <div>
            <EditableField
              {...elementProps}
              fieldName="14_issm_signature"
              style={{ width: "60%", height: 100 }}
            />
          </div>

          <br />
          <br />

          <div style={{ paddingLeft: 20 }}>
            <div style={{ fontSize: 16 }}>
              <p>
                <u>Program Manager</u>
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />

          <div
            style={{ width: "40%", marginBottom: 5, height: 2, backgroundColor: "black" }}
          />
          <div>
            <EditableField
              {...elementProps}
              fieldName="14_pm_signature"
              style={{ width: "60%", height: 100 }}
            />
          </div>

          <br />
          <br />

          <div style={{ paddingLeft: 20 }}>
            <div style={{ fontSize: 16 }}>
              <p>
                <u>Air Force Privacy Officer</u>
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />

          <div
            style={{ width: "40%", marginBottom: 5, height: 2, backgroundColor: "black" }}
          />
          <div style={{ fontSize: 16 }}>
            <div>
              <div>LaDONNE L. WHITE, DAFC,</div>
              <div>Air Force Privacy and Civil Liberties Officer</div>
              <div>SAF/CIO A6XA</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
