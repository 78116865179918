import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { RiskLevelSelect } from "..";

export const P12 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const PCILDataFieldSensitivityMarking = {
    label: "Select Marking",
    value: "12_r3_pcil_dfs_value",
  };

  const PCILObligationProtectConfidentialityMarking = {
    label: "Select Marking",
    value: "12_r4_pcil_opc_value",
  };

  const PCILAccessLocationPIIMarking = {
    label: "Select Marking",
    value: "12_r5_pcil_alpii_value",
  };

  const PCILContextUseMarking = {
    label: "Select Marking",
    value: "12_r6_pcil_cou_value",
  };

  const PCILOverallValueMarking = {
    label: "Select Marking",
    value: "12_overall_pcil_value",
  };

  return (
    <>
      <div
        className="doc_pcilv2"
        style={{
          width: "95%",
          fontSize: 12,
        }}
      >
        <div style={{ paddingLeft: 80 }}>
          <table
            style={{
              width: "50%",
            }}
          >
            <tbody>
              <tr>
                <td>Data Field Sensitivity</td>
                <td>
                  <RiskLevelSelect
                    elementProps={elementProps}
                    config={PCILDataFieldSensitivityMarking}
                    style={{ width: "150px", border: "none" }}
                  />
                </td>
              </tr>
              <tr>
                <td>Obligation to Protect Confidentiality</td>
                <td>
                  <RiskLevelSelect
                    elementProps={elementProps}
                    config={PCILObligationProtectConfidentialityMarking}
                    style={{ width: "150px", border: "none" }}
                  />
                </td>
              </tr>
              <tr>
                <td>Access to and Location of PII</td>
                <td>
                  <RiskLevelSelect
                    elementProps={elementProps}
                    config={PCILAccessLocationPIIMarking}
                    style={{ width: "150px", border: "none" }}
                  />
                </td>
              </tr>
              <tr>
                <td>Context of Use</td>
                <td>
                  <RiskLevelSelect
                    elementProps={elementProps}
                    config={PCILContextUseMarking}
                    style={{ width: "150px", border: "none" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />

        <div style={{ paddingLeft: 80 }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>2.5.4</b>
          <b style={{ fontSize: 16 }}>
            <u>STEP 4. SELECT PII CONFIDENTIALITY IMPACT LEVEL (PCIL) VALUE:</u>
          </b>
        </div>

        <br />

        <div style={{ paddingLeft: 120, display: "flex" }}>
          <b style={{ fontSize: 14, paddingRight: 10 }}>OVERALL PCIL VALUE</b>
          <RiskLevelSelect
            elementProps={elementProps}
            config={PCILOverallValueMarking}
            style={{ width: "150px" }}
          />
        </div>

        <br />
        <br />

        <div style={{ paddingLeft: 40 }}>
          <p style={{ fontSize: 16, display: "flex", margin: 0 }}>
            <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
            <span>
              <b><i>Justify your selection of the overall</i></b> PII Confidentiality impact level <b><i>(PCIL) value.</i></b>
              <br />Take into consideration the FIPS 199 impact values from Table 1 (above)
              and the six factors from NIST SP 800-122. Use the “Balanced Approach” described
              in Section 2.6
            </span>
          </p>
          <div style={{ paddingLeft: 20 }}>
            <div>
              <EditableField
                {...elementProps}
                fieldName={"12_overall_pcil_value_text"}
                style={{ width: "100%", height: 100 }}
              />
            </div>
            <b style={{ fontSize: 16, paddingRight: 10 }}>
              2.6 Is your organization a covered entity or business associate under HIPAA?
            </b>
          </div>

          <br />

          <div style={{ paddingLeft: 80 }}>
            <b style={{ fontSize: 16, paddingRight: 10 }}>
              2.6.1 <u>Select</u> Organization HIPAA Status:
            </b>
            <div style={{ display: "flex", justifyContent: "space-evenly", width: "80%" }}>
              <div>
                <EditableRadio {...elementProps} fieldName={"12_hipaa_covered_entity"} />
                <span style={{ marginLeft: 10, fontSize: 14 }}>COVERED ENTITY</span>
              </div>
              <div>
                <EditableRadio {...elementProps} fieldName={"12_hipaa_business_associate"} />
                <span style={{ marginLeft: 10, fontSize: 14 }}>BUSINESS ASSOCIATE</span>
              </div>
              <div>
                <EditableRadio {...elementProps} fieldName={"12_hipaa_na"} />
                <span style={{ marginLeft: 10, fontSize: 14 }}>N/A</span>
              </div>
            </div>
            <br />
            <p>
              Refer to the Privacy Overlay for a complete description. Depending upon the types of information contained in
              it and who uses the information system, you may have to apply the PHI Overlay as well. Essentially, if your
              system could contain PHI, you must contact the Air Force Office of General Counsel to obtain an opinion.
            </p>
          </div>
        </div>

        <div style={{ paddingLeft: 40 }}>
          <p style={{ fontSize: 16, display: "flex", margin: 0 }}>
            <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
            <b style={{ flex: 1 }}>
              <i>
                If your organization is a covered entity or business associate and your system
                contains PHI, then the PHI Overlay applies as well.
              </i>
            </b>
          </p>
        </div>
      </div>
    </>
  );
};
