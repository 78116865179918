import { gql } from "graphql-tag";

export const Permission = {
  GetAll: () => gql`
    query permissionChoices {
      permissionChoices {
        csarPermissions
        organizationPermissions
        applicationPermissions
      }
    }
  `,
};
