import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { CategorizationInformation, SheetClassification, ClassificationSelect, ResetButton } from "..";
import { Space } from "antd";

export const P10 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };


  return (
    <>
      <div className="itcsc page">
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'25%'} />
              <col span={1} width={'15%'} />
              <col span={1} width={'15%'} />
              <col span={1} width={'15%'} />
              <col span={1} width={'25%'} />
            </colgroup>

            <thead>
              <tr>
                <th className="title" colSpan={5} style={{ borderBottom: 'none' }}>
                  15. Categorization Information <i>Refs: (ff) & (gg)</i>
                </th>
              </tr>
              <tr>
                <th className="text" colSpan={5} >
                  <i>Note 16</i>: Categorize the CIA for applicable Information Types (i.e., Low, Moderate, or High)
                </th>
              </tr>
              <tr>
                <th className="title">
                  Information Types
                </th>
                <th className="title">
                  Confidentiality
                </th>
                <th className="title">
                  Integrity
                </th>
                <th className="title">
                  Availability
                </th>
                <th className="title">
                  Amplifying Data
                </th>
              </tr>
            </thead>

            <tbody>
              {Array.from({ length: 24 }, (_, index) => (
                <tr>
                  <td>
                    {index + 1 < 24 ?
                      <EditableField {...elementProps} fieldName={`15_categorization_information_information_types_r${index + 1}`} />
                      :
                      <b>FINAL SECURITY <br />CATEGORIZATION</b>
                    }
                  </td>
                  <td> <CategorizationInformation style={{ width: '100%', height: '100%' }} elementProps={elementProps} fieldName={`15_categorization_information_confidentiality_r${index + 1}`} /> </td>
                  <td> <CategorizationInformation style={{ width: '100%', height: '100%' }} elementProps={elementProps} fieldName={`15_categorization_information_integrity_r${index + 1}`} /> </td>
                  <td> <CategorizationInformation style={{ width: '100%', height: '100%' }} elementProps={elementProps} fieldName={`15_categorization_information_availability_r${index + 1}`} /> </td>
                  <td> <EditableField {...elementProps} fieldName={`15_categorization_information_amplifying_data_r${index + 1}`} /></td>
                </tr>
              ))}

            </tbody>

          </table>
        </div>

        <ResetButton>Reset Section 15</ResetButton>

      </div>
    </>
  );
};
