import React, { useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Api from "api";
import InputConfirmModal from "../../Common/InputConfirmModal";
import useFormChanges from "hooks/useFormChanges";
import OrganizationForm from "./OrganizationForm";
import { OrganizationNode } from "types";
import LoadingSpinner from "components/Common/LoadingSpinner";

interface Props {
  organizationId: string;
}

const ManageOrganization: React.FC<Props> = ({ organizationId }) => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(Api.Organization.GetOne(), {
    variables: {
      id: organizationId,
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const organization: OrganizationNode = data?.organization;
  console.log({ Organization: organization });

  const { formChanges, hasFormChanges, onChange } = useFormChanges(organization);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteOrganization, { loading: deleteLoading }] = useMutation(
    Api.Organization.Delete(),
    {
      onCompleted: (res) => {
        console.log(res);
        message.success(`Successfully deleted ${organization.name}`);
        setDeleteModalOpen(false);
        navigate("/administration/organizations");
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message);
      },
      refetchQueries: [
        { query: Api.Organization.GetAll() },
        { query: Api.Organization.GetMy() },
      ],
    },
  );

  const [update, { loading: updateLoading }] = useMutation(Api.Organization.Update(), {
    onCompleted: (res) => {
      console.log(res);
      message.success("Successfully saved", 7);
      navigate(-1);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
    refetchQueries: [
      { query: Api.Organization.GetAll() },
      { query: Api.Organization.GetMy() },
    ],
  });

  const deleteOrganizationMutation = () => {
    deleteOrganization({
      variables: {
        id: organizationId,
      },
    });
  };

  const updateOrganization = () => {
    console.log({ Changes: formChanges });

    update({
      variables: {
        id: organization.id,
        name: formChanges.name,
        doingBusinessAs: formChanges.doingBusinessAs,
        pointOfContact: {
          name: formChanges.pocName,
          email: formChanges.pocEmail,
          phoneNumber: formChanges.pocPhoneNumber
            ? formChanges.pocPhoneNumber.toString()
            : null,
        },
        address: {
          street: formChanges.addressStreet,
          city: formChanges.addressCity,
          state: formChanges.addressState,
          zip: formChanges.addressZip,
        },
      },
    });
  };

  const handleChange = (changedValues) => {
    const ans = Object.keys(changedValues);
    onChange(ans[0], changedValues[ans[0]]);
  };

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <OrganizationForm
        submit={updateOrganization}
        loading={updateLoading || deleteLoading}
        initialValues={organization}
        allowDelete={() => setDeleteModalOpen(true)}
        disableSubmit={!hasFormChanges}
        onChange={handleChange}
        noRules
      />

      {deleteModalOpen && (
        <InputConfirmModal
          visible={deleteModalOpen}
          loading={deleteLoading}
          onClose={() => setDeleteModalOpen(false)}
          prompt="This will permanently delete this organization"
          confirmation={`organization/${organization?.name}`}
          mutation={deleteOrganizationMutation}
        />
      )}
    </>
  );
};

export default ManageOrganization;
