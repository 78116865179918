import { useEffect, useState } from "react";
import LoadingSpinner from "components/Common/LoadingSpinner";
import { Button } from "antd";
import useLogout from "./useLogout";

const Logout: React.FC = () => {
  const { logout } = useLogout();

  useEffect(() => {
    logout();
  }, []);

  return <LoadingSpinner global extra={<Refresh />} />;
};

export default Logout;

const Refresh = () => {
  const [stale, setStale] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStale(true);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div>You are being redirected, if not, please Refresh</div>
      {stale && (
        <div>
          <br />
          <Button type="link" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </div>
      )}
    </>
  );
};
