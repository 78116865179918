import { useMutation } from "@apollo/client";
import { message } from "antd";
import Api from "api";

const useCheckpoint = (updateCategory, closeOpenCard) => {
  const [createCheckpointMutation] = useMutation(
    Api.ApplicationCheckpoint.CreateCategoryCheckpoint(),
    {
      onCompleted: (res) => {
        updateCategory(res.createApplicationCheckpoint);
        closeOpenCard();
        console.log(
          "%cCreated Checkpoint",
          "color: steelblue",
          res.createApplicationCheckpoint,
        );
        message.success("Successfully Created Checkpoint");
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message);
      },
      refetchQueries: [
        {
          query: Api.ApplicationModule.GetOne(),
        },
      ],
    },
  );

  const [deleteCheckpointMutation] = useMutation(
    Api.ApplicationCheckpoint.DeleteCategoryCheckpoint(),
    {
      onCompleted: (res) => {
        updateCategory(res.deleteApplicationCheckpoint);
        closeOpenCard();
        console.log(
          "%cDeleted Checkpoint",
          "color: steelblue",
          res.deleteApplicationCheckpoint,
        );
        message.success("Successfully Deleted Checkpoint");
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message);
      },
      refetchQueries: [
        {
          query: Api.ApplicationModule.GetOne(),
        },
      ],
    },
  );

  const [updateCheckpointMutation] = useMutation(
    Api.ApplicationCheckpoint.UpdateCategoryCheckpoint(),
    {
      onCompleted: (res) => {
        updateCategory(res.updateApplicationCheckpoint);
        closeOpenCard();
        console.log(
          "%cUpdated Checkpoint",
          "color: steelblue",
          res.updateApplicationCheckpoint,
        );
        message.success("Successfully Updated Checkpoint");
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message);
      },
      refetchQueries: [
        {
          query: Api.ApplicationModule.GetOne(),
        },
      ],
    },
  );

  const createCheckpoint = (categoryId: string) => {
    createCheckpointMutation({
      variables: {
        id: categoryId,
      },
    });
  };

  const deleteCheckpoint = (categoryId: string) => {
    deleteCheckpointMutation({
      variables: {
        id: categoryId,
      },
    });
  };

  const toggleQuestionsLock = (categoryId: string, state: boolean) => {
    updateCheckpointMutation({
      variables: {
        id: categoryId,
        questionsLocked: state,
        sessionComplete: false,
      },
    });
  };

  return { createCheckpoint, deleteCheckpoint, toggleQuestionsLock };
};

export default useCheckpoint;
