import { useState, useEffect } from "react";
import { Button, message, Space, Row, Col, Segmented } from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Outlet } from "react-router-dom";
import Api from "api";
import useDocumentTitle from "hooks/useDocumentTitle";
import useSessionStorage from "hooks/useSessionStorage";
import { WorkflowEditorSkeleton } from "../../../../Common/Skeletons/CustomSkeletons";
import WorkflowSearchView from "./WorkflowSearchView";
import { QuestionModuleNode } from "types";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import ModuleTabView from "./Components/ModuleTabs";
import CreateWorkflowModal from "../../../Modals/CreateWorkflowModal";
import ManageModuleDrawer from "components/Administration/Modals/ManageModuleDrawer";

/**
 * @returns Tabs (modules) > nested collapse panel (category) > nested table (questions)
 *
 */

type Props = {
  state: any;
  dispatch: any;
};

const WorkflowEditor: React.FC<Props> = ({ state, dispatch }) => {
  useDocumentTitle("Workflow Editor");
  const [activeCategory, setActiveCategory] = useSessionStorage("activeCategory", "0");
  const [activeModule, setActiveModule] = useSessionStorage("activeModule", "0");

  useEffect(() => {
    // clears opened module, category from storage on component un-mount
    return () => {
      sessionStorage.removeItem("activeModule");
      sessionStorage.removeItem("activeCategory");
    };
  }, []);

  const { data, loading: allModulesLoading } = useQuery(Api.QuestionModule.WorkflowEditor(), {
    onCompleted: (res) => {
      console.log(res);
      if (res.allQuestionModules?.length <= 1) {
        console.log("set default active module");
        message.error(
          "We're having some trouble retrieving your modules, try again later",
          10,
        );
        setActiveModule(0);
        return;
      }

      if (
        sessionStorage.getItem("activeModule") === null ||
        sessionStorage.getItem("activeModule") === undefined
      ) {
        console.log("set active module from res.data");
        setActiveModule(res.allQuestionModules[0].id);
        dispatch({
          type: "initModule",
          params: {
            initModule: res.allQuestionModules[0],
          },
        });
        return;
      }
      const storedModuleKey = JSON.parse(sessionStorage.getItem("activeModule")!);
      const activeModuleExists: boolean = res.allQuestionModules.some(
        (module: QuestionModuleNode) => module.id === storedModuleKey,
      );
      if (activeModuleExists) {
        setActiveModule(storedModuleKey);
        console.log("set active module from sessionStorage");
      } else {
        console.log("final else, set active module from res.data");
        setActiveModule(res.allQuestionModules[0].id);
      }
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });
  const [toggleCheckpointModule, { loading: checkpointLoading }] = useMutation(
    Api.QuestionModule.ToggleCheckpoint(),
    {
      onCompleted: (res) => {
        console.log(res);
        message.success(`Module checkpoint toggle successful`, 5);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 7);
      },
      refetchQueries: [Api.QuestionModule.WorkflowEditor()],
    },
  );

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [moduleDrawerOpen, setModuleDrawerOpen] = useState(false);
  const [editorMode, setEditorMode] = useState<string | number>("editor");

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const moduleProps = {
    activeModule,
    setActiveModule,
    activeCategory,
    setActiveCategory,
    state,
    dispatch,
    modules: data,
  };

  const disableActions = allModulesLoading || checkpointLoading;

  const PageHeaderExtra = () => {
    return (
      <Row justify="start" style={{ textAlign: "left", marginBottom: 12 }}>
        <Col span={24}>
          {editorMode !== "search" && (
            <Space>
              <Button
                onClick={() =>
                  toggleCheckpointModule({
                    variables: {
                      objectId: activeModule,
                    },
                  })
                }
                loading={checkpointLoading}
                disabled={disableActions}
                icon={<LockOutlined />}
                key="toggleModuleCheckpoint"
              >
                Toggle Checkpoint
              </Button>
              <Button
                onClick={() => {
                  setModuleDrawerOpen(true);
                  dispatch({
                    type: "handleManageModuleClick",
                    params: {
                      data: data,
                      activeModule: activeModule,
                      manageAction: "edit",
                    },
                  });
                }}
                loading={allModulesLoading}
                disabled={disableActions}
                icon={<SettingOutlined />}
                key="manageModuleButton"
              >
                Module
              </Button>

              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={openCreateModal}
                disabled={disableActions}
                key="createButton"
              >
                Create
              </Button>
            </Space>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="Workflow Editor"
        actions={[<PageHeaderExtra key="extraActions" />]}
        footer={
          <Row style={{ textAlign: "left", paddingBottom: 12 }}>
            <Col>
              <Segmented options={modeOptions} value={editorMode} onChange={setEditorMode} />
            </Col>
          </Row>
        }
      />

      <Outlet />
      {editorMode === "search" ? (
        <WorkflowSearchView state={state} dispatch={dispatch} />
      ) : (
        <WorkflowEditorSkeleton loading={allModulesLoading}>
          <ModuleTabView {...moduleProps} />
        </WorkflowEditorSkeleton>
      )}
      {createModalOpen && (
        <CreateWorkflowModal
          closeModal={() => setCreateModalOpen(false)}
          state={state}
          open={createModalOpen}
        />
      )}
      {moduleDrawerOpen && (
        <ManageModuleDrawer
          open={moduleDrawerOpen}
          state={state}
          moduleId={activeModule}
          onClose={() => setModuleDrawerOpen(false)}
        />
      )}
    </>
  );
};

const modeOptions = [
  { label: "Editor Mode", value: "editor", icon: <EditOutlined /> },
  { label: "Search Mode", value: "search", icon: <SearchOutlined /> },
];

export default WorkflowEditor;
