import { useMutation } from "@apollo/client";
import { Form, message } from "antd";
import Api from "api";
import OrganizationForm from "./OrganizationForm";

type Props = {
  onClose: () => any;
};

const CreateOrganization: React.FC<Props> = ({ onClose }) => {
  const [createOrganization, { loading }] = useMutation(Api.Organization.Create(), {
    onCompleted: () => {
      message.success("Successfully created an Organization");
      form.resetFields();
      onClose();
    },
    onError: (error) => {
      message.error(error.message, 7);
    },
    refetchQueries: [Api.Organization.GetAll(), Api.Organization.GetMy()],
  });

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Success:", values);
    createOrganization({
      variables: {
        address: {
          city: values.addressCity,
          state: values.addressState,
          street: values.addressStreet,
          zip: Number(values.addressZip),
        },
        doingBusinessAs: values.doingBusinessAs,
        name: values.name,
        pointOfContact: {
          name: values.pocName,
          email: values.pocEmail,
          phoneNumber: values.pocPhoneNumber ? values.pocPhoneNumber.toString() : null,
        },
      },
    });
  };

  return <OrganizationForm submit={onFinish} noRules={false} loading={loading} />;
};

export default CreateOrganization;
