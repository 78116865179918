import CSAR_BRAND from "images/CSARLogo.png";

const CsarBrand = (props) => {
  const { onClick, style } = props;

  return (
    <img
      src={CSAR_BRAND}
      alt="CSAR brand logo"
      onClick={onClick}
      style={{ width: 100, ...style }}
    />
  );
};

export default CsarBrand;
