import { Col, Row, Statistic } from "antd";
import { SkeletonWrapper } from "components/Common/Skeletons/SkeletonLoading";
import { useMemo } from "react";
import { globalColors } from "../Utils/index";

type Props = {
  loading: Loading;
  data: any;
};

const AlertApprovalStats: React.FC<Props> = ({ loading, data }) => {
  console.log({ approvalStats: data });

  const needReviewCount = data?.filter(
    (finding: SecurityFinding) => finding.approvalStatus === "NEEDS_REVIEW",
  ).length;

  // const needReviewColor: { color: string } = useMemo(() => {
  //   return needReviewCount > 0 ? colorMap.red : colorMap.green;
  // }, [needReviewCount]);

  const needReviewColor = needReviewCount > 0 ? colorMap.red : colorMap.green;

  const pendingCount = data?.filter(
    (finding: SecurityFinding) => finding.approvalStatus === "PENDING_APPROVAL",
  ).length;

  const pendingColor = pendingCount > 0 ? colorMap.yellow : colorMap.green;

  const approvedCount = data?.filter(
    (finding: SecurityFinding) => finding.approvalStatus === "APPROVED",
  ).length;

  const approvedColor = approvedCount > 0 ? colorMap.green : colorMap.black;

  return (
    <Row>
      <Col xxl={5} xl={5} lg={5} md={6} sm={8} xs={24}>
        <SkeletonWrapper type="statistic" loading={loading}>
          <Statistic
            title="Alerts needing review"
            value={needReviewCount}
            valueStyle={needReviewColor}
          />
        </SkeletonWrapper>
      </Col>
      <Col xxl={5} xl={5} lg={5} md={6} sm={8} xs={24}>
        <SkeletonWrapper type="statistic" loading={loading}>
          <Statistic
            title="Alerts pending approval"
            value={pendingCount}
            valueStyle={pendingColor}
          />
        </SkeletonWrapper>
      </Col>
      <Col xxl={5} xl={5} lg={5} md={6} sm={8} xs={24}>
        <SkeletonWrapper type="statistic" loading={loading}>
          <Statistic
            title="Alerts approved"
            value={approvedCount}
            valueStyle={approvedColor}
          />
        </SkeletonWrapper>
      </Col>
    </Row>
  );
};

const colorMap = {
  green: { color: globalColors.green },
  yellow: { color: globalColors.yellow },
  orange: { color: globalColors.orange },
  red: { color: globalColors.red },
  black: { color: "black" },
};

export default AlertApprovalStats;
