import { useMutation } from "@apollo/client";
import Api from "api";
import { message } from "antd";
import {
  ClearSavedAnswerMutation,
  ClearSavedAnswerMutationVariables,
  ClearFileAnswerMutation,
  ClearFileAnswerMutationVariables,
  SaveFileUploadAnswerMutation,
  SaveFileUploadAnswerMutationVariables,
  SaveFulfillAnswerMutation,
  SaveFulfillAnswerMutationVariables,
  SaveMultipleChoiceAnswerMutation,
  SaveMultipleChoiceAnswerMutationVariables,
  UpdateFileStatusMutation,
  UpdateFileStatusMutationVariables,
} from "types";
import { useQuestionnairePageContext } from "../Workflow/Dashboards/Category/Questionnaire/Questionnaire";
import { useState } from "react";

const cLog = (msg: string, color: string, data?: any) =>
  console.log(`%c${msg}`, `color: ${color}`, data);

const useQuestion = (question, setSaveStatus) => {
  const { updateQuestionState } = useQuestionnairePageContext();

  const [saveFulfillAnswerMutation, { loading: saveFulfillLoading }] = useMutation<
    SaveFulfillAnswerMutation,
    SaveFulfillAnswerMutationVariables
  >(Api.ApplicationQuestion.SaveFulfillAnswer(), {
    onCompleted: (res) => {
      cLog("Updated Question", "steelblue", { Question: res.saveFulfillAnswer });
      updateQuestionState(res.saveFulfillAnswer);
    },
    onError: (error) => {
      setSaveStatus("failed");
      console.log(error.message);
      message.error(error.message);
    },
    fetchPolicy: "no-cache",
  });

  const [saveMultipleChoiceAnswerMutation, { loading: saveMultipleChoiceAnswerLoading }] =
    useMutation<SaveMultipleChoiceAnswerMutation, SaveMultipleChoiceAnswerMutationVariables>(
      Api.ApplicationQuestion.SaveMultipleChoiceAnswer(),
      {
        onCompleted: (res) => {
          cLog("Updated Question", "steelblue", { Question: res.saveMultipleChoiceAnswer });
          updateQuestionState(res.saveMultipleChoiceAnswer);
        },
        onError: (error) => {
          setSaveStatus("failed");
          console.log(error.message);
          message.error(error.message);
        },
        fetchPolicy: "no-cache",
      },
    );

  const [unSaveAnswerMutation, { loading: unSaveAnswerLoading }] = useMutation<
    ClearSavedAnswerMutation,
    ClearSavedAnswerMutationVariables
  >(Api.ApplicationQuestion.UnSaveAnswer(), {
    onCompleted: (res) => {
      cLog("Updated Question", "steelblue", { Question: res.clearSavedAnswer });
      updateQuestionState(res.clearSavedAnswer);
    },
    onError: (error) => {
      setSaveStatus("failed");
      console.log(error.message);
      message.error(error.message);
    },
    fetchPolicy: "no-cache",
  });

  const [file, setFile] = useState(undefined);
  const [saveFileMutation] = useMutation<
    SaveFileUploadAnswerMutation,
    SaveFileUploadAnswerMutationVariables
  >(Api.ApplicationQuestion.SaveFileAnswer(), {
    onCompleted: (res) => {
      const url = res.saveFileUploadAnswer?.url;
      if (!url) return;

      const postOutput = {
        url: res.saveFileUploadAnswer!.url,
        fields: res.saveFileUploadAnswer!.fields,
      };

      console.log("%cFile Upload: Fetching S3 Url", "color: goldenrod", url);
      UploadToS3Bucket(postOutput);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
      updateStatusWithFailure();
    },
    fetchPolicy: "no-cache",
  });

  const [updateStatus] = useMutation<
    UpdateFileStatusMutation,
    UpdateFileStatusMutationVariables
  >(Api.ApplicationQuestion.UpdateFileStatus(), {
    onCompleted: (res) => {
      console.log("%cUpdated Question", "color: steelblue", {
        Question: res.updateQuestionFileStatus,
      });
      updateQuestionState(res.updateQuestionFileStatus);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
    },
    fetchPolicy: "no-cache",
  });

  const [unSaveFileMutation, { loading: unSaveFileLoading }] = useMutation<
    ClearFileAnswerMutation,
    ClearFileAnswerMutationVariables
  >(Api.ApplicationQuestion.UnSaveFile(), {
    onCompleted: (res) => {
      cLog("Updated Question", "steelblue", { Question: res.deleteSavedQuestionFile });
      updateQuestionState(res.deleteSavedQuestionFile);
    },
    onError: (error) => {
      setSaveStatus("failed");
      console.log(error.message);
      message.error(error.message);
    },
    fetchPolicy: "no-cache",
  });

  const saveFulfillAnswer = (questionId: string, answer: { id: string; text: string }) => {
    cLog("Answer To Save", "goldenrod", { Answer: answer });
    setSaveStatus("saving");
    saveFulfillAnswerMutation({
      variables: {
        questionId: questionId,
        answerId: answer.id,
        answerText: answer.text,
      },
    });
  };

  const saveMultipleChoiceAnswer = (questionId: string, answer: { id: string }) => {
    cLog("Answer To Save", "goldenrod", { Answer: answer });
    setSaveStatus("saving");
    saveMultipleChoiceAnswerMutation({
      variables: {
        questionId: questionId,
        answerId: answer.id,
      },
    });
  };

  const saveAdditionalFieldAnswer = (
    questionId: string,
    answer: { id: string; text: string },
  ) => {
    cLog("Additional Answer To Save", "goldenrod", { AdditionalAnswer: answer });
    setSaveStatus("saving");
    saveMultipleChoiceAnswerMutation({
      variables: {
        questionId: questionId,
        answerId: answer.id,
        answerText: answer.text,
      },
    });
  };

  const unSaveAnswer = (questionId: string, answerId?: string) => {
    cLog("Answer To Unsave", "goldenrod", { questionId: questionId });
    setSaveStatus("unsaving");
    unSaveAnswerMutation({
      variables: {
        id: questionId,
        answers: answerId ? [answerId] : [],
      },
    });
  };

  const unSaveFile = (questionId: string) => {
    cLog("File To Unsave", "goldenrod", { QuestionId: questionId });
    setSaveStatus("unsaving");
    unSaveFileMutation({
      variables: {
        id: questionId,
      },
    });
  };

  const UploadToS3Bucket = ({ url, fields }: { url: string | any; fields: any }) => {
    console.log("%cFile Upload: Starting S3 Upload", "color: goldenrod");

    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file!);

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        updateStatusWithSuccess();
      })
      .catch(() => {
        updateStatusWithFailure();
      });
  };

  const saveFile = (questionId, answer: any) => {
    console.log("%cStarting File Upload process", "color: goldenrod", { Answer: answer });

    setFile(answer);
    setSaveStatus("saving");
    saveFileMutation({
      variables: {
        id: questionId,
        filename: answer.name,
      },
    });
  };

  const updateStatusWithSuccess = () => {
    console.log("%cFile Upload: %cS3 Success", "color: goldenrod", "color: green");

    updateStatus({
      variables: {
        id: question.id,
        status: 200,
      },
    });
  };

  const updateStatusWithFailure = () => {
    console.log("%cFile Upload: %cS3 Failed", "color: goldenrod", "color: red");

    updateStatus({
      variables: {
        id: question.id,
        status: 400,
      },
    });
  };

  const unSaveLoading = unSaveAnswerLoading || unSaveFileLoading;

  return {
    saveFile,
    saveFulfillAnswer,
    saveFulfillLoading,
    saveMultipleChoiceAnswer,
    saveAdditionalFieldAnswer,
    saveMultipleChoiceAnswerLoading,
    unSaveAnswer,
    unSaveFile,
    unSaveLoading,
  };
};

export default useQuestion;
