import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

import P7D1 from "../Assets/P7D1.png";
import Super from "components/Deliverables/Components/Super";

export const P7 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pcilv2"
        style={{
          width: "95%",
          fontSize: 12,
        }}
      >
        <div style={{ paddingLeft: 80 }}>
          <p>
            In order to determine the PII confidentiality impact level, the impact levels from Reference (d) and the 6 factors
            from Reference (f) should be used together using a “Balanced Approach.” The “Balanced Approach” considers
            all inputs as an average. It is a best judgment standard where the analyst considers the values and various
            weights of the individual components. This “Balanced Approach” takes all factors into consideration to
            determine the PII confidentiality impact level.
          </p>
          <p style={{ fontSize: 16, display: "flex" }}>
            <b style={{ paddingRight: 10 }}>2.5.1</b>
            <b style={{ flex: 1 }}>
              <u>
                STEP 1. REVIEW THE FIPS 199 IMPACT VALUE FOR EACH OF THE SIX
                FACTORS.
              </u>
            </b>
          </p>
        </div>
        <div style={{ paddingLeft: 40 }}>
          <p style={{ fontSize: 16, display: "flex" }}>
            <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
            <b style={{ flex: 1 }}>
              <i>
                Carefully read the definitions of each impact value in Table 1 (below). Use these
                definitions, as tailored below in Step 2, to determine the impact value for each of
                the six factors from NIST SP 800-122.
              </i>
            </b>
          </p>
        </div>
        <div style={{ paddingLeft: 60 }}>
          <img src={P7D1} alt="P7_D1" style={{ width: "100%" }} />
        </div>
        <div style={{ paddingLeft: 68 }}>
          <p style={{ fontSize: 16, display: "flex", marginLeft: 20 }}>
            <b style={{ paddingRight: 10 }}>2.5.2</b>
            <b style={{ flex: 1 }}>
              <u>
                STEP 2. USING THE GUIDANCE PROVIDED BELOW, DETERMINE THE
                IMPACT VALUE FOR EACH OF THE SIX (6) FACTORS FROM NIST SP 800-122
              </u>
            </b>
          </p>
          <p style={{ fontSize: 16, display: "flex" }}>
            <b>
              FACTOR 1 – IDENTIFIABILITY
            </b>
          </p>
          <table width={'100%'} align="center" style={{ textAlign: 'center', backgroundColor: '#C6D9F1' }}>
            <tbody >
              <tr>
                <td>
                  NIST SP 800-122
                </td>
                <td>
                  NIST SP 800-122 PII Confidentiality Impact levels<Super>4</Super>
                </td>
              </tr>
            </tbody>

          </table>
        </div>

        <br />
        <br />
        <div style={{ paddingLeft: 40 }}>
          <div style={{ borderTop: 'solid black 1px', width: 140 }}></div>
          <br />
          <br />
          4. Note: the descriptions given in the Low, Moderate, and High cells are examples. They are for illustrative purposes and provided to clarify
          both the more general descriptions in Table 1 and the six factors from NIST SP 800-122; each instance of PII is different, and each organization
          has a unique set of requirements and different missions to consider. Refer directly to NIST SP 800-122 section 3.2 for a more complete
          description of the 6 factors.
        </div>
      </div >
    </>
  );
};
