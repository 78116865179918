import { Link } from "react-router-dom";
import useEnumPresentation from "hooks/useEnumPresentation";
import { mapStatusToTag } from "../Common/Tags/ApplicationStatus";
import { RocketOutlined } from "@ant-design/icons";
import SmartTable, {
  compareStrings,
  compareDates,
  compareNumbers,
} from "components/Common/Tables/index";

import { ApplicationNode } from "types";
import type { ColumnsType } from "antd/es/table";
import { isNumber } from "lodash";

interface Props {
  loading: boolean;
  data: Array<ApplicationNode>;
}

const MyApplicationsTable: React.FC<Props> = ({ loading, data }) => {
  const { PresentActionPath } = useEnumPresentation();

  const columns: ColumnsType<ApplicationNode> = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      sorter: (a, b) => compareStrings(a.name, b.name),
      render: (name, { id }) => (
        <Link
          to={`/workflow/${id}`}
          state={{
            applicationName: name,
            key: "applicationDashboard",
          }}
        >
          {name}
        </Link>
      ),
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      defaultSortOrder: "descend",
      sorter: (a, b) => compareDates(a.createdOn, b.createdOn),
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => compareStrings(a.status, b.status),
      render: (status) => mapStatusToTag(status),
    },
    {
      title: "Action Path",
      dataIndex: "rmfActionPath",
      sorter: (a, b) => compareStrings(a.rmfActionPath, b.rmfActionPath),
      render: (rmfap) => PresentActionPath(rmfap),
    },
    {
      title: "Last Update",
      dataIndex: "updatedOn",
      sorter: (a, b) => compareDates(a.updatedOn, b.updatedOn),
      render: (date) => {
        if (!date) return;
        return new Date(date).toLocaleString();
      },
    },
    {
      title: "Completion",
      dataIndex: "totalProgress",
      sorter: (a, b) => compareNumbers(a.totalProgress, b.totalProgress),
      render: (totalProgress) => {
        const progress = isNumber(totalProgress) ? totalProgress : 0;
        return `${progress.toFixed(1)}%`;
      },
    },
    {
      title: "Timeline",
      dataIndex: "appTime",
      sorter: (a, b) => compareNumbers(a.appTime, b.appTime),
      render: (appTime) => `${appTime} hours`,
    },
    {
      title: "Risk",
      dataIndex: "totalRisk",
      sorter: (a, b) => compareNumbers(a.totalRisk, b.totalRisk),
      render: (totalRisk) => `${totalRisk}%`,
    },
    {
      title: "Launch",
      align: "right",
      render: (_, { name, id }) => (
        <Link
          to={`/workflow/${id}`}
          state={{
            applicationName: name,
            key: "applicationDashboard",
          }}
        >
          <RocketOutlined style={{ transform: "rotate(35deg)", fontSize: "18px" }} />
        </Link>
      ),
    },
  ];

  return (
    <SmartTable loading={loading} data={data} columns={columns} />
  );
};

export default MyApplicationsTable;
