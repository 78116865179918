import { useState } from "react";
import { useQuery } from "@apollo/client";
import Api from "api";
import { message } from "antd";
import { ApplicationQuestionGroupNode, ApplicationQuestionNode } from "types";

export interface QuestionnairePage {
  pageType: string;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  group: ApplicationQuestionGroupNode | null;
  questions: any;
}

const useQuestionnairePage = (categoryId: string, pageNumber: string) => {
  const [page, setPage] = useState<QuestionnairePage | null>(null);
  const { loading, error, refetch } = useQuery(Api.ApplicationCategory.GetQuestionsByPage(), {
    variables: {
      categoryId,
      pageNumber: parseInt(pageNumber),
    },
    onCompleted: (res) => {
      const p = res.getQuestionsByPage;

      setPage({
        pageType: p.pageType,
        pageNumber: p.pageNumber,
        pageSize: p.pageSize,
        totalPages: p.totalPages,
        group: p.group,
        questions: p[p.pageType.toLowerCase()],
      });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
    fetchPolicy: "no-cache",
  });

  const incrementTotalComments = (questionId: string) => {
    setPage((p) => ({
      ...p!,
      questions: p!.questions.map((question) =>
        question.id === questionId
          ? { ...question, totalComments: question.totalComments! + 1 }
          : question,
      ),
    }));
  };

  const updateListQuestionState = (update: ApplicationQuestionNode) => {
    console.log("%cUpdating List State", "color: red", update);
    // Root Update (old)
    // setAllQuestions((qs) => qs.map((q) => (q.id === update.id ? update : q)));

    // Recursive Search Update
    const updateRecursively = (list, update) =>
      list.map((q) =>
        q?.id === update.id
          ? update
          : {
              ...q,
              linkedQuestions: q?.linkedQuestions
                ? updateRecursively(q?.linkedQuestions, update)
                : q?.linkedQuestions,
            },
      );
    setPage((p) => ({
      ...p!,
      questions: updateRecursively(p!.questions, update),
    }));
  };

  const updateGridQuestionState = (update: ApplicationQuestionNode) => {
    console.log("%cUpdating Grid State", "color: red", update);

    setPage((p) => ({
      ...p!,
      questions: p!.questions.map((column) => ({
        ...column,
        rows: column.rows.map((row) =>
          row?.question?.id === update.id ? { ...row, question: update } : row,
        ),
      })),
    }));
  };

  // prettier-ignore
  const updateQuestionState =
    page?.pageType === "GRID"
    ? updateGridQuestionState
    : updateListQuestionState;

  console.log("%cPage", "color: steelblue", { Page: page });

  return {
    page,
    loading,
    error,
    refetch,
    incrementTotalComments,
    updateQuestionState,
  };
};

export default useQuestionnairePage;
