import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, message, Skeleton } from "antd";
import Api from "api";
import SmartTable from "components/Common/Tables";
import React, { useState } from "react";
import { ColumnsType } from "antd/es/table";
import { ApplicationDocumentNode, GetDownloadUrlQuery } from "types";
import moment from "moment";
import LoadingSpinner from "components/Common/LoadingSpinner";
import Tooltip from "antd/es/tooltip";
import { Link } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";

interface Props {
  applicationId?: string;
  searchText: string;
}

const StatusIcon = {
  draft: "Draft",
  sent: "Sent",
  signed: "Complete",
};

const downloadFile = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const CompletedApplicationDocuments: React.FC<Props> = (props) => {
  const { searchText, applicationId } = props;
  const [selectedDoc, setSelectedDoc] = useState<string | null>(null);

  const { data, loading } = useQuery(Api.ApplicationDocuments.GetAll(), {
    variables: {
      applicationId: applicationId,
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const [_getDownloadUrl, getDownloadUrlMeta] = useLazyQuery<GetDownloadUrlQuery>(
    Api.ApplicationDocuments.GetDownloadUrl(),
  );

  const getDownloadUrl = async (AppDoc: ApplicationDocumentNode) => {
    setSelectedDoc(AppDoc.id);
    const url = await _getDownloadUrl({
      variables: { applicationDocumentId: AppDoc.id },
    }).then(({ data }) => data?.getApplicationDocumentDownloadUrl);
    if (!url) return;
    downloadFile(url);
    setSelectedDoc(null);
  };

  if (loading) return <LoadingSpinner />;

  const columns: ColumnsType<ApplicationDocumentNode> = [
    {
      title: "Document",
      dataIndex: "documentName",
    },
    {
      title: "Version",
      dataIndex: "documentVersion",
      align: "center",
    },
    {
      title: "Last Updated",
      dataIndex: "stateUpdatedOn",
      align: "center",
      render: (data) => {
        const datetime = moment(data).format("MMM D, YYYY");
        return <div style={{ whiteSpace: "nowrap" }}>{datetime}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "state",
      align: "center",
      width: "fit-content",
      render: (data: string) => StatusIcon[data],
    },
    {
      title: "Action",
      dataIndex: "id",
      align: "center",
      width: "fit-content",
      render: (_, doc) => (
        <Tooltip title="Download">
          <Button
            type="link"
            icon={<DownloadOutlined />}
            loading={selectedDoc === doc.id && getDownloadUrlMeta.loading}
            onClick={async () => await getDownloadUrl(doc)}
          >
            Download
          </Button>
        </Tooltip>
      ),
    },
  ];

  const documents = data.allApplicationDocuments || [];
  const filteredDocuments = documents.filter((p) => p.documentName.includes(searchText));

  return (
    <SmartTable
      data={filteredDocuments}
      loading={loading}
      columns={columns}
      rowKey={(doc) => doc.id}
      size="middle"
    />
  );
};

export default CompletedApplicationDocuments;
