import { AnswerConditionEnums, QuestionTypesEnums } from "api/enums";
import { mapTagToType as mapConditionType } from "components/Common/Tags/AnswerCondition";
import { mapTagToType } from "components/Common/Tags/QuestionType";

export const logicOptions = AnswerConditionEnums.map((type) => ({
  label: mapConditionType(type.value),
  value: type.value,
  disabled: type.disabled,
}));

const activeQuestionTypes = [QuestionTypesEnums[0], QuestionTypesEnums[1]];

export const questionTypeOptions = activeQuestionTypes.map((type) => ({
  label: mapTagToType(type.value),
  value: type.value,
}));
