import { G2 } from "@ant-design/plots";
const { registerShape, Util } = G2;

export const createGaugeDial = () =>
  registerShape("point", "custom-gauge-indicator", {
    draw(cfg, container) {
      const { indicator, defaultColor } = cfg.customInfo;
      const { pointer } = indicator;
      const group = container.addGroup();

      const center = this.parsePoint({
        x: 0,
        y: 0,
      });

      if (pointer) {
        const { startAngle, endAngle } = Util.getAngle(cfg, this.coordinate);
        const radius = this.coordinate.getRadius();
        const midAngle = (startAngle + endAngle) / 2;
        const { x: x1, y: y1 } = Util.polarToCartesian(
          center.x,
          center.y,
          radius / 20,
          midAngle + 3 / Math.PI,
        );
        const { x: x2, y: y2 } = Util.polarToCartesian(
          center.x,
          center.y,
          radius / 20,
          midAngle - 3 / Math.PI,
        );
        const { x, y } = Util.polarToCartesian(
          center.x,
          center.y,
          radius * 0.65,
          midAngle,
        );
        const path = [
          ["M", center.x, center.y],
          ["L", x1, y1],
          ["L", x, y],
          ["L", x2, y2],
          ["Z"],
        ];

        group.addShape("path", {
          name: "pointer",
          attrs: {
            path,
            fill: defaultColor,
            ...pointer.style,
          },
        });
      }

      return group;
    },
  });
