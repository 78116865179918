import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import useViewport from "hooks/useViewport";
import useModule from "components/Applicant/Hooks/useModule";
import DashboardLayout from "../../Components/Dashboards/DashboardLayout";
import ProgressMetric from "../../Components/Metrics/ProgressMetric";
import ModuleCategories from "./ModuleCategories";
import EntityNotFound from "components/Common/EntityNotFound";
import { Typography, Card, Row, Col, Space, Tooltip, Tag } from "antd";
import { ClusterOutlined } from "@ant-design/icons";
import { ApplicationQuestionCategoryNode, ApplicationQuestionModuleNode } from "types";

const ModuleDashboard = () => {
  useDocumentTitle("Module Overview");

  const viewport = useViewport();
  const { moduleId } = useParams();

  const { mod, loading, error } = useModule(moduleId!);

  if (error) {
    return <EntityNotFound />;
  }

  const categories = (mod?.categories as Array<ApplicationQuestionCategoryNode>) ?? [];
  console.log({ Categories: categories });

  return (
    <DashboardLayout
      loading={loading}
      header={{
        title: "Module Dashboard",
        icon: <ClusterOutlined />,
      }}
      information={<ModuleInformation mod={mod!} viewport={viewport} />}
      time={mod?.appTime!}
      risk={mod?.totalRisk!}
      skeletonType="workflowCategoryContent"
    >
      <ModuleCategories
        loading={loading}
        modName={mod?.name}
        categories={loading ? [] : categories}
        isLocked={mod?.isLocked}
      />
    </DashboardLayout>
  );
};

export default ModuleDashboard;

const ModuleInformation: React.FC<{ mod: ApplicationQuestionModuleNode; viewport: any }> = ({
  mod,
  viewport,
}) => {
  return (
    <Card className="shadowed-static">
      <Row justify="space-between" style={{ marginBottom: 5 }}>
        <Row>
          <Typography.Title level={3}>{mod.name}</Typography.Title>
        </Row>
        {mod.checkpoint && (
          <Row
            justify="end"
            style={{ width: viewport.isMobile ? "100%" : "fit-content", marginBottom: 5 }}
          >
            <Space>
              <Tooltip title="Module Checkpoint">
                <Typography.Text style={{ marginRight: -5 }}>
                  <Tag color="orange">Checkpoint</Tag>
                </Typography.Text>
              </Tooltip>
            </Space>
          </Row>
        )}
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col>
          {/* <Row style={{ marginBottom: 5 }}>
            <b style={{ paddingRight: 5 }}>Last Update:</b>
            <Typography.Text>
              {mod.updatedOn ? moment(mod.updatedOn).format("LL") : "Not Started"}
            </Typography.Text>
          </Row> */}
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Module Progress
        </Typography.Title>
      </Row>
      <ProgressMetric percent={mod.totalProgress!} />
    </Card>
  );
};
