import { Col, Row } from "antd";
import SaveStatusIndicator from "components/Common/Tags/UpdateSaveStatusTag";
import { QuestionCategoryNode } from "types";
import { SaveStatusEnum } from "components/Administration/Workflow/Editor/Shared/types";
import HeaderStat from "../../../Shared/PageHeaderStat";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";

interface Props {
  category: QuestionCategoryNode;
  saveStatus: SaveStatusEnum;
  reset: () => void;
}

const QuestionListHeader: React.FC<Props> = ({ category, saveStatus, reset }) => {
  return (
    <CustomPageHeader
      onBack={reset}
      title={category?.name}
      level={3}
      extra={<SaveStatusIndicator status={saveStatus} />}
    >
      <Row justify="start">
        <Col flex="20">
          <HeaderStat title="Questions in Category" value={category?.allQuestions?.length} />
        </Col>
        <Col flex="15">
          <HeaderStat title="Groups" value={category?.groups?.length} />
        </Col>
        <Col flex="55">
          <HeaderStat title="Parent Module" value={category?.module?.name} />
        </Col>
      </Row>
    </CustomPageHeader>
  );
};

export default QuestionListHeader;
