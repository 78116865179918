import { Button, List, Space, Tag } from "antd";
import { OrderedListOutlined, PlusCircleOutlined } from "@ant-design/icons";
import ContentCard from "../../../Shared/ContentCard";
import { GroupFieldKeysEnum as FieldKeysEnum } from "components/Administration/Workflow/Editor/Group Editor/Components/reducer";
import { mapTagToType } from "components/Common/Tags/QuestionType";
import { QuestionNode } from "types";
import InfoTip from "components/Common/InfoTip";

const QuestionList = ({ category, questions, loading, setEditState }) => {
  const Actions = () => {
    // const manageButton = (
    //   <Button
    //     onClick={() => {
    //       setEditState(FieldKeysEnum.questions);
    //     }}
    //     icon={<SettingOutlined />}
    //   >
    //     Manage Questions
    //   </Button>
    // );
    const orderButton = (
      <Button
        onClick={() => {
          setEditState(FieldKeysEnum.questionOrder);
        }}
        icon={<OrderedListOutlined />}
      >
        Re-order
      </Button>
    );
    const addButton = (
      <Button
        onClick={() => {
          setEditState(FieldKeysEnum.questions);
        }}
        icon={<PlusCircleOutlined />}
      >
        Add Questions
      </Button>
    );

    if (questions.length < 0) {
      return addButton;
    }

    return (
      <Space>
        {/* {manageButton}  */}
        {orderButton}
      </Space>
    );
  };

  const hasCategoryQuestions = category?.questions && category?.questions?.length > 0;

  return (
    <ContentCard
      title={`Question List (${category?.allQuestions.length})`}
      loading={loading}
      setEditState={setEditState}
      fieldKey={FieldKeysEnum.questions}
      editableField={false}
      extra={
        hasCategoryQuestions ? (
          <Actions />
        ) : (
          <InfoTip
            iconFont={20}
            title="The questions in this list are directly attached to a Question Group and must be managed from their Group"
          />
        )
      }
      styles={{ height: "calc(85vh - 105px)", position: "relative" }}
      bodyStyles={{ height: "calc(100% - 65px)", overflow: "auto" }}
    >
      <List
        dataSource={questions}
        itemLayout="vertical"
        size="small"
        locale={{ emptyText: "No Questions Assigned" }}
        renderItem={(question: QuestionNode) => (
          <List.Item style={{ marginLeft: 0, paddingLeft: 0 }}>
            <List.Item.Meta
              title={<p style={{ fontSize: 14 }}>{question?.question}</p>}
              description={<p>{question?.helpText}</p>}
            />
            <div style={{ paddingBottom: 8 }}>
              <Space>
                {mapTagToType(question.questionType, question.id)}
                <GroupTag question={question} />
              </Space>
            </div>
          </List.Item>
        )}
      />
    </ContentCard>
  );
};

const GroupTag = ({ question }) => {
  if (!question?.groupId) {
    return <Tag key={question.id}>Not in Group</Tag>;
  }

  return <Tag key={question.id}>In Group</Tag>;
};

export default QuestionList;
