import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, ResetButton, YesNoSelect } from "..";

import { Button } from "antd";

export const P4 = (props) => {
  const { document, state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const NSDMarking = {
    label: "Select Marking",
    value: "nss_status_determination_marking",
  };

  return (
    <>
      <div className="itcsc page">
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'80%'} />
              <col span={1} />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={2}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <div>
                      5. NSS Status Determination <i>Refs: (r-t)</i>
                    </div>
                  </div>

                </th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'top' }}>

              <tr>
                <td className="label">
                  5A. Does the function, operation, or use of the system involve intelligence
                  activities?
                </td>
                <td className="answer" >
                  <YesNoSelect elementProps={elementProps} fieldName='nss_status_determination_5a' style={{ width: '100%' }} />
                </td>
              </tr>

              <tr>
                <td className="label">
                  5B. Does the function, operation, or use of the system involve cryptologic activities
                  related to national security?
                </td>
                <td className="answer" >
                  <YesNoSelect elementProps={elementProps} fieldName='nss_status_determination_5b' style={{ width: '100%' }} />
                </td>
              </tr>

              <tr>
                <td className="label">
                  5C. Does the function, operation, or use of the system involve military command
                  and control of military forces?
                </td>
                <td className="answer" >
                  <YesNoSelect elementProps={elementProps} fieldName='nss_status_determination_5c' style={{ width: '100%' }} />
                </td>
              </tr>

              <tr>
                <td className="label">
                  5D. Does the function, operation, or use of the system involve equipment that is an
                  integral part of the weapon or weapons system?
                </td>
                <td className="answer" >
                  <YesNoSelect elementProps={elementProps} fieldName='nss_status_determination_5d' style={{ width: '100%' }} />
                </td>
              </tr>

              <tr>
                <td className="label">
                  5E. If the system is not used for routine administrative or business
                  applications, is the system critical to the direct fulfillment of military or
                  intelligence missions?
                </td>
                <td className="answer" >
                  <YesNoSelect elementProps={elementProps} fieldName='nss_status_determination_5e' style={{ width: '100%' }} />
                </td>
              </tr>

              <tr>
                <td className="label">
                  5F. Does the system store, process, or communicate classified information?
                </td>
                <td className="answer" >
                  <YesNoSelect elementProps={elementProps} fieldName='nss_status_determination_5f' style={{ width: '100%' }} />
                </td>
              </tr>

              <tr>
                <td className="label">
                  5G. Does the system process any information the unauthorized access, use,
                  disclosure, disruption, modification, or destruction of which would have a debilitating
                  impact on the mission of the DOD or an element of the Intelligence Community?
                </td>
                <td className="answer" >
                  <YesNoSelect elementProps={elementProps} fieldName='nss_status_determination_5g' style={{ width: '100%' }} />
                </td>
              </tr>

              <tr>
                <td className="label">
                  5H. Is the system designated as NSS per organizationally defined guidance but does not
                  meet any of the above criteria? If yes, then an appropriate explanation must be
                  provided.
                </td>
                <td className="answer" >
                  <YesNoSelect elementProps={elementProps} fieldName='nss_status_determination_5h' style={{ width: '100%' }} />
                </td>
              </tr>
              <tr><td colSpan={2}><EditableField {...elementProps} fieldName="nss_status_determination_5h_text" style={{ minHeight: '100px' }} /></td></tr>


              <tr>
                <td className="label">
                  5I. Is this an NSS?<br />
                  <i style={{ fontWeight: 'normal' }}>Note 4: If any of the answers above is “Yes”, then the system is an NSS</i>
                </td>
                <td className="answer" >
                  <YesNoSelect elementProps={elementProps} fieldName='nss_status_determination_5i' style={{ width: '100%' }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ResetButton>Reset Section 5</ResetButton>
      </div>
    </>
  );
};
