import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  HourglassOutlined,
  LikeOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

export const NotStartedTag = () => {
  return (
    <Tag color="red" icon={<ExclamationCircleOutlined />}>
      Not Started
    </Tag>
  );
};

export const PendingTag = () => {
  return (
    <Tag color="gold" icon={<HourglassOutlined />}>
      Pending
    </Tag>
  );
};

export const InProgressTag = () => {
  return (
    <Tag color="blue" icon={<PlayCircleOutlined />}>
      In Progress
    </Tag>
  );
};

export const AuthorizedTag = () => {
  return (
    <Tag color="green" icon={<LikeOutlined />}>
      Authorized
    </Tag>
  );
};

export const CompletedTag = () => {
  return (
    <Tag color="green" icon={<CheckCircleOutlined />}>
      Completed
    </Tag>
  );
};

export const mapStatusToTag = (status?: any) => {
  let ans;

  switch (status) {
    case undefined:
      ans = <NotStartedTag />;
      break;
    case null:
      ans = <NotStartedTag />;
      break;
    case "Pending":
      ans = <PendingTag />;
      break;
    case "In Progress":
      ans = <InProgressTag />;
      break;
    case "Authorized":
      ans = <AuthorizedTag />;
      break;
    case "Completed":
      ans = <CompletedTag />;
      break;
    default:
      return <Tag>{status}</Tag>;
  }

  return ans;
};
