import Icon, { LockTwoTone, LockOutlined, UnlockTwoTone } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useState } from "react";

interface Props {
  locked: boolean;
  handleItemLock: any;
  itemId: string;
  index: number;
  iconStyle?: any;
  tooltipStyle?: any;
}

const LockIconButton: React.FC<Props> = (props) => {
  const { locked, handleItemLock, itemId, index, iconStyle, tooltipStyle, ...rest } = props;
  const [hovering, setHovering] = useState<any>();

  const icon = locked ? (
    <LockTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />
  ) : (
    <LockOutlined />
  );
  const unlockIcon = <UnlockTwoTone twoToneColor="#52c41a" style={{ fontSize: 26 }} />;

  const title = locked ? "Unlock position" : "Lock position";

  const toggleLocked = () => {
    handleItemLock(itemId, index);
  };

  return (
    <Tooltip
      title={title}
      color="#000000"
      mouseEnterDelay={0.3}
      style={tooltipStyle}
      {...rest}
    >
      {locked ? (
        <Icon
          component={() => {
            if (hovering) {
              return unlockIcon;
            }
            return icon;
          }}
          role="button"
          onClick={toggleLocked}
          id="LockSortIcon"
          style={iconStyle}
          onMouseOver={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        />
      ) : (
        <Button icon={<LockOutlined />} onClick={toggleLocked} size="small">
          Lock
        </Button>
      )}
    </Tooltip>
  );
};

export default LockIconButton;

// const LockIconButton: React.FC<Props> = (props) => {
//   const { locked, handleItemLock, itemId, index, iconStyle, tooltipStyle, ...rest } = props;

//   const icon = locked ? <LockTwoTone twoToneColor="#52c41a" /> : <LockOutlined />;

//   const title = locked ? "Unlock position" : "Lock position";

//   const toggleLocked = () => {
//     handleItemLock(itemId, index);
//   };

//   return (
//     <Tooltip
//       title={title}
//       color="#000000"
//       mouseEnterDelay={0.6}
//       style={tooltipStyle}
//       {...rest}
//     >
//       <Icon
//         component={() => icon}
//         role="button"
//         onClick={toggleLocked}
//         id="LockSortIcon"
//         style={iconStyle}
//       />
//     </Tooltip>
//   );
// };
