import { Route, useNavigate } from "react-router-dom";
import { useAuthContext } from "components/Providers/WithAuth";
import useAccessControl, { Permissions } from "hooks/useAccessControl";
import useLocalStorage from "hooks/useLocalStorage";
import { Roles } from "hooks/useAccessControl";
import AccessDenied from "components/Common/AccessDenied";

// Public Routes
import IndexLayout from "components/Layouts/IndexLayout";
import ApplicantLayout from "components/Layouts/ApplicantLayout";
import Login from "components/Auth/Login";
import Logout from "components/Auth/Logout";
import PasswordChange from "components/Auth/PasswordChange";
import PageNotFound from "components/Common/PageNotFound";

// Private Routes
// Applicant
import HomeDashboard from "components/Applicant/HomeDashboard";
import HomeCyberPlans from "components/Applicant/HomeCyberPlans";
import ApplicantAccount from "components/Accounts/ApplicantAccount";
import ApplicationDocuments from "components/Applicant/Workflow/Documents/ApplicationDocuments";
import DocumentGenerator from "components/Deliverables/DocumentGenerator";
import SecurityFindings from "components/Applicant/Workflow/SecurityFindings/SecurityFindingsPage";
import IntegrationsDashboard from "components/Applicant/Workflow/Integrations/IntegrationsPage";
import ApplicationDashboard from "components/Applicant/Workflow/Dashboards/Application";
import ModuleDashboard from "components/Applicant/Workflow/Dashboards/Module";
import CategoryDashboard from "components/Applicant/Workflow/Dashboards/Category";

// Administration
import AdministrationLayout from "components/Layouts/AdministrationLayout";
import AdministrationAccount from "components/Accounts/AdministrationAccount";
import ManageUsers from "components/Administration/Users/ManageUsersTable";
import ManageOrganizations from "components/Administration/Organizations/ManageOrganizationsTable";
import ManageApplications from "components/Administration/Applications/ManageApplicationsTable";
import ManageDocuments from "components/Administration/Documents/ManageDocumentsPage";
import MotarApplicationPrompt from "components/Common/Motar/Applications/MotarApplicationPrompt";
import MotarApplicationImport from "components/Common/Motar/Applications/MotarApplicationImport";
import QuestionGroupsTable from "components/Administration/Workflow/Tables/Groups/QuestionGroupsTable";
import AnswerConditionTable from "components/Administration/Workflow/Tables/AnswerConditionTable";
import QuestionRepositoryTable from "components/Administration/Workflow/Tables/QuestionRepositoryTable";
import WorkflowEditorPage from "components/Administration/Workflow/Editor/Workflow/Components";
import DocumentViewer from "components/Deliverables/DocumentViewer";
import DocumentEditor from "components/Deliverables/DocumentEditor";

import QuestionEditorDrawer from "components/Administration/Workflow/Editor/Question Editor/QuestionEditorDrawer";
import CategoryEditorDrawer from "components/Administration/Workflow/Editor/Category Editor/CategoryEditorDrawer";
import GroupEditorDrawer from "components/Administration/Workflow/Editor/Group Editor/GroupEditorDrawer";
import AnswerConditionDrawer from "./components/Administration/Workflow/Editor/Conditional Logic/AnswerConditionDrawer";
import ManageModal from "components/Administration/Modals/ManageDrawer";

type RouteType = {
  path: string;
  redirect?: string;
  private: boolean;
  component: JSX.Element;
  permissions?: Array<string>;
  roles?: Array<string>;
  children?: Array<RouteType>;
};

// prettier-ignore
export const RenderRouting = (routes: Array<RouteType>) =>
  routes.map((route:RouteType) => (
    <Route
      key={route.path}
      path={route.path}
      element={route.private
        ? <PrivateRoute route={route} />
        : route.component
      }
      children={route.children && RenderRouting(route.children)}
    />
  ));

export const PrivateRoute: React.FC<{ route: RouteType }> = ({ route }) => {
  const { permissions, roles, redirect, component } = route;
  const { RBAC, PBAC } = useAccessControl();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [auth] = useLocalStorage("authorization", { authorized: false });
  const isAuth = auth.authorized;

  // ? RBAC to implement with refresh token
  // useEffect(() => {
  //   if (roles?.length) {
  //     if (!RBAC(roles)) {
  //       console.log(
  //         `%cRoutes: %cInvalid Role, redirecting to ${redirect}`,
  //         "color:steelblue",
  //         "color:red",
  //       );
  //       navigate(redirect!);
  //     }
  //   }
  // }, []);

  if (!isAuth) {
    console.log(
      "%cRoutes: %cNo Auth, redirecting to Logout",
      "color:steelblue",
      "color:goldenrod",
    );
    return <Logout />;
  }

  if (!user.id) {
    console.log(
      "%cRoutes: %cNo User Id, redirecting to Logout",
      "color:steelblue",
      "color:goldenrod",
    );
    return <Logout />;
  }

  if (permissions?.length) {
    if (!PBAC(permissions)) {
      console.log(
        "%cRoutes: %cInvalid Permissions, Access Denied",
        "color:steelblue",
        "color:red",
      );
      return <AccessDenied />;
    }
  }

  return component;
};

export const PublicRoutes = [
  {
    private: false,
    path: "/login",
    component: <Login />,
  },
  {
    private: false,
    path: "/logout",
    component: <Logout />,
  },
  {
    private: false,
    path: "/password-change/:resetToken",
    component: <PasswordChange />,
  },
];

// prettier-ignore
export const PrivateRoutes: Array<RouteType> = [
  {
    private: true,
    path: "/",
    redirect: "/administration",
    component: <IndexLayout />,
    roles: [
      Roles.ApplicantUser,
      Roles.GlobalAdmin,
      Roles.CsarAdvisor,
      Roles.RiskManager,
    ],
    children: [
      {
        private: true,
        path: "",
        permissions: [],
        component: <HomeDashboard />,
      },
      {
        private: true,
        path: "/home",
        permissions: [],
        component: <HomeDashboard />,
      },
      {
        private: true,
        path: "/cyber-plans",
        permissions: [
          Permissions.ReadCyberPlans
        ],
        component: <HomeCyberPlans />,
      },
      {
        private: true,
        path: "account",
        permissions: [],
        component: <ApplicantAccount />,
      },
      {
        private: true,
        path: "/motar/apps/prompt",
        permissions: [],
        component: <MotarApplicationPrompt />,
      },
      {
        private: true,
        path: "/motar/apps/import",
        permissions: [],

        component: <MotarApplicationImport />,
      },
      {
        private: true,
        path: "*",
        permissions: [],
        component: <PageNotFound />,
      },
    ],
  },
  {
    private: true,
    path: "/workflow",
    component: <ApplicantLayout />,
    redirect: "/login",
    roles: [
      Roles.ApplicantUser,
      Roles.GlobalAdmin,
      Roles.CsarAdvisor,
      Roles.RiskManager,
    ],
    children: [
      {
        private: true,
        path: ":applicationId",
        permissions: [
          Permissions.ReadApplication,
          Permissions.UpdateApplication,
          Permissions.ReadApplications,
          Permissions.UpdateApplications,
        ],
        component: <ApplicationDashboard />,
      },
      {
        private: true,
        path: ":applicationId/documents",
        permissions: [
          Permissions.ReadApplication,
          Permissions.UpdateApplication,
          Permissions.ReadApplications,
          Permissions.UpdateApplications,
        ],
        component: <ApplicationDocuments />,
      },
      {
        private: true,
        path: ":applicationId/documents/:documentId",
        permissions: [
          Permissions.ReadApplication,
          Permissions.UpdateApplication,
          Permissions.ReadApplications,
          Permissions.UpdateApplications,
        ],
        component: <DocumentViewer />,
      },
      {
        private: true,
        path: ":applicationId/documents/generate/:documentId",
        permissions: [
          Permissions.ReadApplication,
          Permissions.UpdateApplication,
          Permissions.ReadApplications,
          Permissions.UpdateApplications,
        ],
        component: <DocumentGenerator />,
      },
      {
        private: true,
        path: ":applicationId/integrations",
        permissions: [
          Permissions.ReadSecFindings, 
          Permissions.UpdateSecFindings
        ],
        component: <IntegrationsDashboard />,
      },
      {
        private: true,
        path: ":applicationId/security-findings",
        permissions: [
          Permissions.CreateSecFindings,
          Permissions.ReadSecFindings,
          Permissions.UpdateSecFindings,
          Permissions.DeleteSecFindings,
        ],
        component: <SecurityFindings />,
      },
      {
        private: true,
        path: ":applicationId/:moduleId",
        permissions: [
          Permissions.ReadApplication,
          Permissions.UpdateApplication,
          Permissions.ReadApplications,
          Permissions.UpdateApplications,
        ],
        component: <ModuleDashboard />,
      },
      {
        private: true,
        path: ":applicationId/:moduleId/:categoryId/:pageNumber",
        permissions: [
          Permissions.ReadApplication,
          Permissions.UpdateApplication,
          Permissions.ReadApplications,
          Permissions.UpdateApplications,
        ],
        component: <CategoryDashboard />,
      },
      {
        private: true,
        path: ":applicationId/security-findings",
        permissions: [
          Permissions.CreateSecFindings,
          Permissions.ReadSecFindings,
          Permissions.UpdateSecFindings,
          Permissions.DeleteSecFindings,
        ],
        component: <SecurityFindings />,
      },
      {
        private: true,
        path: "*",
        permissions: [],
        component: <PageNotFound />,
      },
    ],
  },
  {
    private: true,
    path: "/administration",
    redirect: "/home",
    component: <AdministrationLayout />,
    roles: [
      Roles.GlobalAdmin,
      Roles.OrganizationAdmin,
      Roles.CsarAdvisor,
      Roles.WorkflowAdmin,
      Roles.RiskManager,
    ],
    children: [
      {
        private: true,
        path: "",
        permissions: [],
        component: <AdministrationAccount />,
      },
      {
        private: true,
        path: "users",
        permissions: [
          Permissions.CreateUsers,
          Permissions.ReadUsers,
          Permissions.UpdateUsers,
          Permissions.DeleteUsers,
          Permissions.ReadOrganization,
        ],
        component: <ManageUsers />,
        children: [
          {
            private: true,
            path: "manage/:userId",
            permissions: [
              Permissions.CreateUsers,
              Permissions.ReadUsers,
              Permissions.UpdateUsers,
              Permissions.DeleteUsers,
              Permissions.ReadOrganization,
            ],
            component: <ManageModal type="user" title="Manage User" />,
          },
        ],
      },
      {
        private: true,
        path: "organizations",
        permissions: [
          Permissions.ReadOrganization,
          Permissions.UpdateOrganization,
          Permissions.CreateOrganizations,
          Permissions.ReadOrganizations,
          Permissions.UpdateOrganizations,
          Permissions.DeleteOrganizations,
        ],
        component: <ManageOrganizations />,
        children: [
          {
            private: true,
            path: "manage/:organizationId",
            permissions: [
              Permissions.ReadOrganization,
              Permissions.UpdateOrganization,
              Permissions.UpdateOrganizations,
              Permissions.DeleteOrganizations,
            ],
            component: <ManageModal type="organization" title="Manage Organization" />,
          },
        ],
      },
      {
        private: true,
        path: "applications",
        permissions: [
          Permissions.ReadApplication,
          Permissions.UpdateApplication,
          Permissions.CreateApplications,
          Permissions.ReadApplications,
          Permissions.UpdateApplications,
          Permissions.DeleteApplications,
        ],
        component: <ManageApplications />,
        children: [
          {
            private: true,
            path: "manage/:applicationId",
            permissions: [
              Permissions.UpdateApplication,
              Permissions.CreateApplications,
              Permissions.ReadApplications,
              Permissions.UpdateApplications,
              Permissions.DeleteApplications,
            ],
            component: <ManageModal type="application" title="Manage Application" />,
          },
        ],
      },
      {
        private: true,
        path: "workflow-editor",
        permissions: [
          Permissions.CreateQuestionary,
          Permissions.ReadQuestionary,
          Permissions.UpdateQuestionary,
          Permissions.DeleteQuestionary,
        ],
        component: <WorkflowEditorPage />,
        children: [
          {
            private: true,
            path: "question-editor/:questionId",
            component: <QuestionEditorDrawer />,
          },
          {
            private: true,
            path: "category-editor/:categoryId",
            component: <CategoryEditorDrawer />,
          },
        ],
      },
      {
        private: true,
        path: "question-groups",
        permissions: [
          Permissions.CreateQuestionary,
          Permissions.ReadQuestionary,
          Permissions.UpdateQuestionary,
          Permissions.DeleteQuestionary,
        ],
        component: <QuestionGroupsTable />,
        children: [
          {
            private: true,
            path: "group-editor/:groupId",
            component: <GroupEditorDrawer />,
          },
        ],
      },
      {
        private: true,
        path: "answer-conditions",
        permissions: [
          Permissions.CreateQuestionary,
          Permissions.ReadQuestionary,
          Permissions.UpdateQuestionary,
          Permissions.DeleteQuestionary,
        ],
        component: <AnswerConditionTable />,
        children: [
          {
            private: true,
            path: "condition-editor/:conditionId",
            component: <AnswerConditionDrawer />,
          },
          {
            private: true,
            path: "condition-editor/new",
            component: <AnswerConditionDrawer />,
          },
        ],
      },
      {
        private: true,
        path: "question-repository",
        permissions: [
          Permissions.CreateQuestionary,
          Permissions.ReadQuestionary,
          Permissions.UpdateQuestionary,
          Permissions.DeleteQuestionary,
        ],
        component: <QuestionRepositoryTable />,
        children: [
          {
            private: true,
            path: "question-editor/:questionId",
            component: <QuestionEditorDrawer />,
          },
        ],
      },
      {
        private: true,
        path: "document-editor",
        permissions: [
          Permissions.ReadQuestionary,
          Permissions.UpdateQuestionary
        ],
        component: <ManageDocuments />,
      },
      {
        private: true,
        path: "document-editor/manage/:documentId",
        permissions: [
          Permissions.ReadQuestionary,
          Permissions.UpdateQuestionary
        ],
        component: <DocumentEditor />,
      },
      {
        private: true,
        path: "security-findings",
        permissions: [
          Permissions.ReadQuestionary,
          Permissions.UpdateQuestionary
        ],
        component: <SecurityFindings />,
      },

      {
        private: true,
        path: "*",
        permissions: [],
        component: <PageNotFound />,
      },
    ],
  },
];
