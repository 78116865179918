import React from "react";
import { Space, Table } from "antd";
import { QuestionGroupNode } from "types";
import { GroupFieldKeysEnum as FieldKeysEnum } from "../reducer";
import ContentCard from "../../../Shared/ContentCard";
import { mapTagToType } from "components/Common/Tags/QuestionType";

type Props = {
  group: QuestionGroupNode;
  loading: Loading;
  refetchGroup: any;
  editorMode: string;
  setEditorMode: any;
  setEditState: (arg0: FieldKeysEnum) => void;
};

function getData(numRows: number = 0, columns: Array<any>, rowLabels: Array<any> = []) {
  let temp: any[] = [];

  for (let r = 0; r < numRows; r++) {
    let item = {};
    for (let c = 0; c < columns.length; c++) {
      const dataType = columns[c]?.data_type;
      const _label = rowLabels[r];
      const label = c === 0 && _label !== "" ? _label : null;
      item[`col_${c}`] = label || dataType;
    }
    temp.push(item);
  }
  return temp;
}

const QuestionGrid: React.FC<Props> = ({ group, loading, setEditorMode, setEditState }) => {
  const numRows = group?.gridNumRows || 0;
  const columns = group?.columnLabels || [];
  const rowLabels = group?.rowLabels || [];

  const _columns = columns.map((col, index) => {
    return {
      dataIndex: `col_${index}`,
      title: col?.label,
      render: (t) => {
        return mapTagToType(t);
      },
    };
  });

  const _data = getData(numRows, columns, rowLabels);

  const Actions = () => {
    return <Space></Space>;
  };

  return (
    <ContentCard
      title={`Question Grid`}
      loading={loading}
      fieldKey={FieldKeysEnum.questions}
      editableField={false}
      extra={<Actions />}
      styles={{ height: 600 }}
      bodyStyles={{ height: 534, overflow: "auto" }}
    >
      <Table columns={_columns} dataSource={_data} pagination={false} />
    </ContentCard>
  );
};

export default QuestionGrid;
