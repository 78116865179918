import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../Providers/WithAuth";
import useAccessControl, { Roles } from "hooks/useAccessControl";
import { UserOutlined } from "@ant-design/icons";
import { Menu, MenuProps } from "antd";

// * gets tricky with route params using item ID's, create a hook using finalPath?
// * could use document titles, could be easier, talk with kyle

interface Props {
  items: Array<MenuItem>;
}

const subMenuKeys = ["modules", "workflowsMenu", "userMenu"];

const LayoutMenu: React.FC<Props> = ({ items }) => {
  const { user } = useAuthContext();
  const { RBAC } = useAccessControl();
  const location: any = useLocation();
  const finalPath = [location.pathname.split("/").pop()];
  const isAdminMode = location.pathname.split("/")[1] === "administration";

  const [activeKeys, setActiveKeys] = useState<any>(finalPath);
  const [openKeys, setOpenKeys] = useState<any>([]);

  useEffect(() => {
    if (location.state?.key) {
      setActiveKeys(location.state.key);
    } else {
      setActiveKeys(finalPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onSelect: MenuProps["onSelect"] = (keys) => {
    setActiveKeys(keys.keyPath);
  };

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (subMenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // prettier-ignore
  const menuItems:Array<MenuItem> = [
    ...items,
    {
      label: <div style={{textTransform: 'capitalize'}}>{user.fullname}</div>,
      key: "userMenu",
      icon: <UserOutlined />,
      style: { position: "absolute", bottom: 0, width: "100%" },
      children: [
        RBAC([
          Roles.GlobalAdmin,
          Roles.OrganizationAdmin,
          Roles.WorkflowAdmin,
          Roles.CsarAdvisor,
          Roles.RiskManager
        ]) && {
          label: isAdminMode ? (
            <Link to="/home">Applicant Mode</Link>
          ) : (
            <Link to="/administration">Administration Mode</Link>
          ),
          key: "mode",
        },
        {
          label: <Link to={isAdminMode ? "" : "/account"}>My Account</Link>,
          key: "userProfile",
        },
        {
          label: <Link to="/logout">Logout</Link>,
          key: "logOut",
        },
      ],
    },
  ];

  return (
    <Menu
      mode="inline"
      theme="dark"
      items={menuItems}
      selectedKeys={activeKeys}
      onSelect={onSelect}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    />
  );
};

export default LayoutMenu;
