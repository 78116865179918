import Api from "api";
import { useQuery } from "@apollo/client";
import SmartTable from "components/Common/Tables/index";
import { Typography, Row, Divider, message } from "antd";
import type { ColumnsType } from "antd/es/table";

const { Title } = Typography;

const OrganizationsTab: React.FC<any> = () => {
  const { data, loading } = useQuery(Api.Organization.GetMy(), {
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const organizations = data?.myOrganizations;
  console.log({ UsersOrganizations: organizations });

  const columns: ColumnsType<OrganizationDataType> = [
    { title: "Organization Name", dataIndex: "name" },
    {
      title: "Doing Business As",
      dataIndex: "doingBusinessAs",
      align: "right",
    },
  ];

  return (
    <>
      <Row>
        <Title level={3}>My Organizations</Title>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <SmartTable loading={loading} data={organizations} columns={columns} />
    </>
  );
};

export default OrganizationsTab;
