import { useLocation, useParams } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import useCategory from "components/Applicant/Hooks/useCategory";
import PageGroupSection from "./Questionnaire/Components/PageGroupHeader";
import Questionnaire from "./Questionnaire/Questionnaire";
import PageNotFound from "../../../../Common/PageNotFound";
import { SkeletonWrapper } from "components/Common/Skeletons/SkeletonLoading";
import { Alert, Card, Col, Dropdown, Button, Row, Space, Typography } from "antd";
import { ApplicationQuestionCategoryNode, ApplicationQuestionGroupNode } from "types";
import "styles/categoryDashboard.css";
import { useState } from "react";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import { ColumnWidthOutlined, ProfileOutlined } from "@ant-design/icons";
import useViewport from "hooks/useViewport";

const CategoryDashboard = () => {
  useDocumentTitle("Category Questionnaire");

  const viewport = useViewport();
  const location = useLocation();
  const categoryName = location?.state?.categoryName;
  const { categoryId, pageNumber } = useParams();

  const { category, loading, error } = useCategory(categoryId!);

  const viewWidthStorage = sessionStorage.getItem("viewWidth");
  const viewWidthState = viewWidthStorage ? JSON.parse(viewWidthStorage) : "1200px";

  const [viewWidth, setViewWidth] = useState(viewWidthState);
  const [pageGroup, setPageGroup] = useState<ApplicationQuestionGroupNode | any>({
    header: "",
    description: "",
  });

  if (error) {
    return <PageNotFound />;
  }

  const handleSetViewWidth = ({ key }: { key: string }) => {
    console.log("%cSet: Questionnaire View Width", "color: goldenrod");

    sessionStorage.setItem("viewWidth", JSON.stringify(key));
    setViewWidth(key);
  };

  const actions = [
    <Dropdown
      key="widthDropdown"
      menu={{
        onClick: handleSetViewWidth,
        items: [
          {
            key: "800px",
            label: "Small",
          },
          {
            key: "1200px",
            label: "Large",
          },
          {
            key: "100%",
            label: "Full Screen",
          },
        ],
      }}
    >
      <Button>
        <ColumnWidthOutlined />
      </Button>
    </Dropdown>,
  ];

  return (
    <>
      <CustomPageHeader
        title="Category Questionnaire"
        icon={
          <Space style={{ color: "#1890FF", marginBottom: 3 }}>
            <ProfileOutlined />
          </Space>
        }
        actions={viewport.isDesktop ? actions : []}
      />
      <Row className="categoryDashboard" style={{ maxWidth: viewWidth }}>
        <Row justify="center" style={{ width: "100%" }}>
          <CategoryHeader
            categoryName={categoryName!}
            category={category}
            pageGroup={pageGroup}
            loading={loading}
          />
        </Row>
        <Row justify="center" style={{ width: "100%" }}>
          <Col span={24}>
            <Questionnaire
              categoryId={categoryId!}
              pageNumber={pageNumber!}
              onPageChange={(page) => setPageGroup(page?.group)}
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default CategoryDashboard;

const CategoryHeader = ({
  categoryName,
  category,
  pageGroup,
  loading,
}: {
  categoryName: string;
  category: ApplicationQuestionCategoryNode;
  pageGroup: ApplicationQuestionGroupNode | any;
  loading: boolean;
}) => {
  return (
    <>
      <Card className="shadowed-static" style={{ width: "100%", marginBottom: 20 }}>
        <SkeletonWrapper loading={categoryName ? false : loading} type="categoryTitle">
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            {categoryName || category?.name}
          </Typography.Title>
          <PageGroupSection header={pageGroup?.header} description={pageGroup?.description} />
        </SkeletonWrapper>
      </Card>
      <CategoryLockedAlert category={category} />
    </>
  );
};

const CategoryLockedAlert = ({ category }: { category: ApplicationQuestionCategoryNode }) => {
  return (
    <Row justify="center" style={{ width: "100%" }}>
      {category?.isLocked && (
        <Alert
          style={{ width: "100%", marginBottom: 20 }}
          type="warning"
          message="Content Locked"
          description="Contact your advisor to unlock this section."
        />
      )}
    </Row>
  );
};
