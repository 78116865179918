import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import Api from "api";
import { Button, Divider, Form, message, Modal, Row } from "antd";
import { useParams } from "react-router-dom";

type Props = {
  state: IntegrationState;
  onClose: () => void;
};

const SetupModal: React.FC<Props> = ({ state, onClose }) => {
  const [form] = Form.useForm();
  const { applicationId } = useParams();
  const tokenInput = Form.useWatch("token", form);
  const orgInput = Form.useWatch("organization", form);
  const projectInput = Form.useWatch("project", form);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  const [save] = useMutation(Api.Integration.SetIntegrationConfig(), {
    onCompleted: (res) => {
      message.success("Integration Setup Successful");
      onClose();
    },
    onError: (error) => {
      message.error(error.message, 7);
    },
    fetchPolicy: "no-cache",
  });

  const handleSaveSetup = (file: any) => {
    const dataVals = {
      token: tokenInput,
      organization: orgInput,
      projects: projectInput,
    };

    save({
      variables: {
        applicationId: applicationId,
        name: state.integration!.id,
        data: JSON.stringify(dataVals),
      },
    });
  };

  const DynamicSetupComponent = state.integration?.setup;

  return (
    <Modal
      title={`Setup ${state.integration?.name}`}
      open={state.open}
      onCancel={onClose}
      footer={null}
    >
      <Form onFinish={handleSaveSetup} form={form} layout="vertical">
        <DynamicSetupComponent />
        <Divider />
        <Row justify="end">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default SetupModal;
