import { useEffect, useState } from "react";
import { Button, Col, message, Popconfirm, Row, Segmented, Space } from "antd";
import { MinusCircleTwoTone, PlusCircleTwoTone, SaveOutlined } from "@ant-design/icons";
import SmartTable from "components/Common/Tables/index";
import { useMutation, useQuery } from "@apollo/client";
import Api from "api";
import { SaveStatusEnum } from "components/Administration/Workflow/Editor/Shared/types";
import { CategoryFieldKeysEnum as FieldKeysEnum } from "components/Administration/Workflow/Editor/Category Editor/Components/reducer";
import { GetFullQuestionaryQuery, QuestionNode } from "types";
import useEditorLoading from "hooks/useEditorLoading";
import { mapTagToType } from "components/Common/Tags/QuestionType";
import Sortable from "components/Administration/Workflow/Editor/Shared/SortableList/Sortable";
import QuestionListHeader from "./QuestionListHeader";
import useDynamicDrawerWidth from "hooks/useDynamicDrawerWidth";

const QuestionListEditor = ({
  category,
  state,
  reset,
  setSaveState,
  loading,
  refetchCategory,
}) => {
  const {
    data,
    loading: questionsLoading,
    refetch: refetchQuestions,
  } = useQuery<GetFullQuestionaryQuery>(Api.Question.GetFullQuestionary(), {
    onCompleted: (res) => {
      console.log({ allQuestionsList: res });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 5);
    },
  });

  const determineStatus = () => {
    let status = SaveStatusEnum.Default;

    if (state[FieldKeysEnum.questions].saveStatus !== SaveStatusEnum.Default) {
      status = state[FieldKeysEnum.questions].saveStatus;
      return status;
    }

    if (state[FieldKeysEnum.questionOrder].saveStatus !== SaveStatusEnum.Default) {
      status = state[FieldKeysEnum.questionOrder].saveStatus;
      return status;
    }

    return status;
  };

  const saveStatus = determineStatus();

  console.log({ saveStatus });

  return (
    <>
      <QuestionListHeader category={category} saveStatus={saveStatus} reset={reset} />
      <EditorTable
        parent={category}
        state={state}
        questions={category?.questions}
        setSaveState={setSaveState}
        loading={loading}
        allQuestions={data?.allQuestions}
        refetchCategory={refetchCategory}
      />
    </>
  );
};

export default QuestionListEditor;

const EditorTable = ({
  parent,
  state,
  loading,
  questions,
  setSaveState,
  allQuestions,
  refetchCategory,
}) => {
  const width = useDynamicDrawerWidth();
  const [questionList, setQuestionList] = useState<QuestionNode[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [manageAction, setManageAction] = useState<string | number>("add");
  const editingOrder: boolean = state.questionOrder.editActive;
  const editingList: boolean = state.questions.editActive;

  useEffect(() => {
    if (questions.length < 1) return;
    setQuestionList(questions);
  }, [questions]);

  const [updateQuestions, { loading: mutationLoading }] = useMutation(
    Api.Question.UpdateMultiple(),
    {
      onCompleted: (res) => {
        console.log(res);
        message.success("Successfully update question(s) order");
        setSaveState(FieldKeysEnum.questionOrder, SaveStatusEnum.Success);
        refetchCategory();
      },
      onError: (error) => {
        console.log(error);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.questionOrder, SaveStatusEnum.Error);
      },
      refetchQueries: [Api.QuestionModule.WorkflowEditor()],
    },
  );

  useEffect(() => {
    console.log({ listEditorItems: questionList });
  }, [questionList]);

  const saveQuestionOrder = () => {
    setSaveState(FieldKeysEnum.questionOrder, SaveStatusEnum.Loading);
    console.log({ saveQuestionOrder: questionList });
    const indexedQuestions = questionList.map((q: QuestionNode, index) => ({
      id: q.id,
      order: index + 1,
    }));

    updateQuestions({
      variables: {
        items: [...indexedQuestions],
      },
    });
  };

  const stateLoading = useEditorLoading(FieldKeysEnum.questionOrder, state, loading);

  const columns = [
    { title: "Question", dataIndex: "question" },
    {
      title: "Data Type",
      dataIndex: "questionType",
      width: "8%",
      render: (text, record) => mapTagToType(text, record.id),
    },
    {
      title: "Category",
      dataIndex: "categoryId",
      render: (text, record) => record.category?.name,
    },
  ];

  const hasChanges = selectedQuestions.length >= 1;

  const disableRecord = (record: QuestionNode) => {
    /* block attempt to remove question thats not in the category
       block attempt to add questions to category when they're already in the category */
    if (allQuestions?.length < 1) return;

    if (manageAction === "add") {
      if (!record.category?.id) {
        return false;
      }
      if (record.category.id === parent.id) {
        return true;
      }
      return false;
    }

    // remove conditions
    if (record.category?.id === parent.id) {
      return false;
    }

    return true;
  };

  const rowSelection = {
    selectedRowKeys: selectedQuestions,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(selectedRowKeys);
      console.log(selectedRows);
      setSelectedQuestions(selectedRowKeys);
    },

    getCheckboxProps: (record: any) => ({
      name: record.name,
      disabled: disableRecord(record),
    }),
  };

  const addTableSelection = () => {
    console.log({ parentCategory: parent, selectedQuestions });
    setSaveState(FieldKeysEnum.questions, SaveStatusEnum.Loading);

    const formattedVals = selectedQuestions.map((q: QuestionNode) => ({
      categoryId: parent.id,
      id: q.id,
    }));

    updateQuestions({
      variables: {
        items: [...formattedVals],
      },
    });
  };

  const removeTableSelection = () => {
    console.log({ selectedQuestions: selectedQuestions });
    setSaveState(FieldKeysEnum.questions, SaveStatusEnum.Loading);

    const formattedVals = selectedQuestions.map((q: QuestionNode) => ({
      categoryId: "CLEAR",
      id: q.id,
    }));

    updateQuestions({
      variables: {
        items: [...formattedVals],
      },
    });
  };

  const TableHeader = () => {
    return (
      <Row justify="space-between" align="bottom">
        <Col span={8}>
          <Segmented options={manageActions} value={manageAction} onChange={setManageAction} />
        </Col>
        <Col span={5}>
          <Space align="baseline">
            <label style={{ fontWeight: "bold" }}>Selected Questions:</label>
            <label style={{ fontWeight: "bold" }}>{selectedQuestions.length}</label>
          </Space>
        </Col>

        <Col span={8} style={{ textAlign: "right" }}>
          <Space>
            {manageAction === "add" ? (
              <Button
                type="primary"
                onClick={addTableSelection}
                loading={updatesLoading}
                disabled={!hasChanges || manageAction !== "add"}
              >
                Add to Category
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure you want to remove the selected questions from the group?"
                onConfirm={removeTableSelection}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger={true}
                  type="primary"
                  loading={updatesLoading}
                  disabled={!hasChanges || manageAction !== "remove"}
                >
                  Remove from Category
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Col>
      </Row>
    );
  };

  const updatesLoading = stateLoading || mutationLoading || loading;

  return (
    <Row align="bottom">
      <Col span={24}>
        {editingList ? (
          <SmartTable
            loading={loading}
            columns={columns}
            data={allQuestions}
            title={() => <TableHeader />}
            pagination={{
              position: ["bottomLeft"],
              pageSizeOptions: [25, 50, 100, 200],
              defaultPageSize: 25,
            }}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
          />
        ) : (
          <Row>
            <Col span={24}>
              <Sortable
                parent={parent}
                parentType="category"
                itemList={questionList}
                setList={setQuestionList}
                submit={saveQuestionOrder}
              />
            </Col>
          </Row>
        )}
      </Col>
      {editingOrder && (
        <div
          style={{
            height: 55,
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            width: width,
            background: "#FFFFFF",
            padding: 12,
            position: "fixed",
            flexShrink: 0,
            borderTop: "1px solid #f0f0f0",
          }}
        >
          <div>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              loading={updatesLoading}
              onClick={saveQuestionOrder}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </Row>
  );
};

const manageActions = [
  {
    label: "Add",
    value: "add",
    icon: <PlusCircleTwoTone />,
  },
  {
    label: "Remove",
    value: "remove",
    icon: <MinusCircleTwoTone twoToneColor="#ff4d4f" />,
  },
];
