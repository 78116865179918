import React from "react";
import { Button, Space } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

const NessusDocs: React.FC<any> = () => {
  return (
    <>
      <Space align="baseline" style={{ marginBottom: 12 }}>
        <InfoCircleTwoTone />
        <p>This should help configure Nessus</p>
      </Space>
      <Button>Documentation</Button>
    </>
  );
};

export default NessusDocs;
