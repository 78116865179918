import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Button } from "antd";

export const P13 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="instructions">
        <ul className="slash" >
          <li>
            Exempt: Systems in sustainment; required to be registered in ITIPS but are not required to accomplish CCA compliance unless they are funding activities that result in improved capability or performance.
          </li>
        </ul>

        <p>eMASS ID: Enter eMASS registration number. An eMASS ID is required for all DAF systems. This information must be provided after eMASS registration.</p>
        <p>eMASS Classification: Select the eMASS classification for archiving/storing system authorization artifacts. </p>
        <p>DITPR ID: Enter the DITPR ID number. A DITPR ID is provided after an approved ITIPS registration (as applicable). </p>
        <p>AFID: Unique identifier given to SAP programs to account for them but anonymize the data, so the program name is not known.</p>

        <p><b>2.AOBoundary</b></p>

        <p>
          Listings of AOs and Boundary descriptions are posted on the DAF RMF Knowledge Service at <br />
          <a>https://rmfks.osd.mil/rmf/Collaboration/Component%20Workspaces/AirForce/Lists/Air%20Force%20Authori zation%20Boundaries/AllItems.aspx</a>
        </p>

        <p><b>3.Security Classification</b></p>
        <p><b>3A. Highest Data Classification Processed </b></p>
        <p>Identify the highest data classification that the System will process. </p>
        <p><b>3B. Dissemination Control </b></p>
        <p>Identify the dissemination control for the information that the System will process. </p>
        <p><b>3C. Does the system process SAP data? </b> Self-explanatory.</p>
        <p><b>3D. Any Foreign National Users? </b> Self-explanatory.</p>

        <p><b>4.Cybersecurity Service Provider (CSSP) Alignment and Validation</b></p>

        <p>All DAF systems must be aligned to a CSSP. The DAF is supported by 16 AF and USSF service providers and these entities serve as the only DAF approved ingress points for CSSP requests.</p>
        <p><b>4A. CSSP Alignment.</b> Self-explanatory.</p>
        <p><b>4B. CSSP Validation Date.</b> Self-explanatory.</p>

        <p><b>5.NSS Determination</b></p>
        <p>Carefully read and answer Questions 5A- 5H, checking the "Yes" or "No" box on each one. If the answer to any of the questions is “Yes,” then the System is an NSS as to be noted in Question 5I, contingent upon SAF/CN concurrence.</p>

        <p><b>6.System or Subsystem Types</b></p>
        <p>System or Subsystem type choices depend on selection in section 1. </p>
        <p>System Type: choose one (as applicable)</p>
        <p>Subsystem Type: determine if it is a PIT, Service or Product and select the appropriate choices.</p>

        <b>7.System Operational Status</b>

        <ul className="slash">
          <li>Operational - The System has satisfied program requirements and achieved IOC or FOC.</li>
          <li>
            Under Development - The System is in the design/development phase and has not entered finalproduction/operation.
          </li>
        </ul>

      </div>
    </>
  );
};
