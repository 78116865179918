import { useState } from "react";
import {
  ControlOutlined,
  FormOutlined,
  InfoCircleOutlined,
  TableOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Form,
  message,
  Select,
  Input,
  InputNumber,
  Button,
  Skeleton,
  Divider,
  Radio,
  Space,
} from "antd";
import Api from "api";
import { useCategoryList } from "api/hooks/useCategoryList";
import { CreateQuestionGroupMutation, GroupType } from "types";
import GridTableEditor from "components/Administration/Workflow/Tables/Groups/GridEditor/GridTableEditor";

const { Option } = Select;

type Props = {
  closeDrawer: () => void;
  setDrawerType: (type: "baseForm" | "gridEditor") => void;
};

type ColumnLabel = {
  label: string;
  dataType: string;
};

const CreateQuestionGroup: React.FC<Props> = ({ closeDrawer, setDrawerType }) => {
  const [form] = Form.useForm();
  const groupType = Form.useWatch("groupType", form);
  const formValues = form.getFieldsValue(true);
  const gridValues = formValues.gridValues;
  const [gridEditorActive, setGridEditorActive] = useState(false);

  const { categories, loading: categoriesLoading } = useCategoryList();
  const [createQuestionGroup, { loading }] = useMutation<CreateQuestionGroupMutation>(
    Api.QuestionGroup.Create(),
    {
      onCompleted: (res) => {
        console.log(res);
        message.success("Successfully created a Question Group!", 5);
        closeDrawer();
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 7);
      },
      refetchQueries: [{ query: Api.QuestionGroup.GetAll() }],
    },
  );

  const generateGridObject = () => {
    console.log("%cgenerateGridObject", "color:pink", { formValues: formValues });

    const { dataSource, columnState } = formValues.gridValues;

    const columnLabels: ColumnLabel[] = columnState?.map((col: any) => {
      const obj = {
        label: col.labelValue,
        dataType: col?.questionType,
      };

      if (col?.answers && col.answers.length > 0) {
        obj["options"] = col.answers;
      }

      return obj;
    });

    const rowLabels: string[] = dataSource?.map((row: any) => row.ignore ? "" : row.label)

    const gridNumRows: number = dataSource.length;

    return { columnLabels, rowLabels, gridNumRows };
  };

  const onFinish = (values: any) => {
    try {
      if (isGridType) {
        const { columnLabels, rowLabels, gridNumRows } = generateGridObject();
        console.log("%cCreateGroup callingMutation ; ISGRID ", "color:pink", {
          formValues,
          finValues: values,
          columnLabels,
          rowLabels,
          gridNumRows,
        });

        console.log("%cmutation variables", "color:orange", {
          variables: {
            description: values.description,
            groupType: values.groupType,
            header: values.header,
            order: values.order,
            columnLabels: columnLabels,
            rowLabels: rowLabels,
            gridNumRows: gridNumRows,
          },
        });      

        createQuestionGroup({
          variables: {
            description: values.description,
            groupType: values.groupType,
            header: values.header,
            order: values.order,
            categoryId: values.categoryId,
            columnLabels: columnLabels,
            rowLabels: rowLabels,
            gridNumRows: gridNumRows,
          },
        });
      } else {
        createQuestionGroup({
          variables: {
            ...values,
          },
        });
      }

      console.log("%cCreateGroup onFinish", "color:pink", {
        formValues,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const isGridType = groupType === GroupType.Grid;
  const isCreatingGrid = isGridType && gridEditorActive;

  const activateGridEditor = () => {
    setDrawerType("gridEditor");
    setGridEditorActive(true);
  };

  const closeGridEditor = () => {
    setDrawerType("baseForm");
    setGridEditorActive(false);
  };

  const handleGridFinish = (values: any) => {
    console.log("%chandleGridFinish from createQuestionGroup", "color:red", { values });
    form.setFieldValue("gridValues", values);
    closeGridEditor();
  };

  const disableSubmit = isCreatingGrid;

  const hasCreatedGrid =
    gridValues?.dataSource?.length > 0 && gridValues?.columnState?.length > 0;

  return (
    <Form
      layout="vertical"
      form={form}
      name="createQuestionGroupForm"
      onFinish={onFinish}
      validateMessages={validateMessages}
      style={{ height: "100%", width: "100%" }}
    >
      {/* Group Type Selector */}
      <Form.Item
        name="groupType"
        label="Group Type"
        rules={required}
        tooltip={{
          title:
            "Control groups include a predefined set of questions designed to collect data about security controls for an application. The questions that are included in a control question group are not editable. Generic groups are dynamic question groups that can include any question. Grid groups provide a way to collect data in a tabular format.",
          ...tooltipStyle,
        }}
      >
        <Radio.Group
          options={typeOptions}
          optionType="button"
          buttonStyle="solid"
          disabled={isCreatingGrid}
        />
      </Form.Item>
      
      {/* Show Create Grid Button */}
      {isGridType && !gridEditorActive && (
        <>
          <Divider />
          <Form.Item>
            <Button
              onClick={activateGridEditor}
              icon={<FormOutlined />}
              type={hasCreatedGrid ? undefined : "primary"}
            >
              {hasCreatedGrid ? "Edit Grid" : "Create Grid"}
            </Button>
          </Form.Item>
        </>
      )}

      {/* Group Info */}
      {isCreatingGrid ? (
        <Form.Item name="gridValues" shouldUpdate>
          <GridTableEditor
            onChange={handleGridFinish}
            cancelEditor={closeGridEditor}
            initialValues={hasCreatedGrid ? gridValues : undefined}
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            name="header"
            label="Group Header"
            rules={required}
            tooltip={{
              title: "Header text to be displayed for the Question Group.",
              ...tooltipStyle,
            }}
          >
            <Input.TextArea autoSize={{ minRows: 2 }} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={required}
            tooltip={{
              title: "Description for the Question Group",
              ...tooltipStyle,
            }}
          >
            <Input.TextArea autoSize={{ minRows: 4 }} />
          </Form.Item>
          <Form.Item
            name="categoryId"
            label="Assign to Category"
            tooltip={{
              title: "Assign to a Category. This can be changed or added later",
              ...tooltipStyle,
            }}
          >
            {categoriesLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                loading={categoriesLoading}
                dropdownMatchSelectWidth={false}
                style={{ width: "100%" }}
              >
                <Option value="">Not Assigned</Option>
                {categories.map((cat: BasicQuestionCategory) => (
                  <Option value={cat.id} key={cat.id}>
                    {cat.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            name="order"
            label="Order"
            tooltip={{
              title: "Order this Question Group appears in its parent Category",
            }}
          >
            <InputNumber min="0" />
          </Form.Item>
        </>
      )}

      <div
        style={{
          display: "flex",
          height: 40,
          justifyContent: "flex-end",
          bottom: 16,
          right: 24,
          position: "fixed",
        }}
      >
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} disabled={disableSubmit}>
            Submit
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

const validateMessages = {
  // intentional per Antd docs
  // eslint-disable-next-line
  required: "'${label}' is required!",
};

const required = [{ required: true }];

const typeOptions = [
  {
    label: (
      <Space>
        <ControlOutlined />
        Control
      </Space>
    ),
    value: GroupType.Control,
  },
  {
    label: (
      <Space>
        <UnlockOutlined />
        Generic
      </Space>
    ),
    value: GroupType.Generic,
  },
  {
    label: (
      <Space>
        <TableOutlined />
        Grid
      </Space>
    ),
    value: GroupType.Grid,
  },
];

const tooltipStyle = {
  color: "#000000",
  icon: <InfoCircleOutlined />,
};

export default CreateQuestionGroup;
