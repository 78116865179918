import { P1 } from "./P1";
import { P2 } from "./P2";
import { P3 } from "./P3";
import { P4 } from "./P4";
import { P5 } from "./P5";
import { P6 } from "./P6";
import { P7 } from "./P7";
import { P8 } from "./P8";

// prettier-ignore
export const pages = [
  P1,
  P2,
  P3,
  P4,
  P5,
  P6,
  P7,
  P8,
];

export default pages;
