import {
  QuestionAnswerChoiceNode,
  QuestionCategoryNode,
  QuestionGroupNode,
  QuestionModuleNode,
  QuestionRmfActionPathNode,
} from "types";

/**
 * Allows workflow editor to be aware of which tab is open, what collapse is open in between mutations
 */

export const workflowInitialState = {
  selectedCategory: {},
  selectedModule: {},
  selectedQuestions: [],
  manageAction: "",
  blankForm: false,
  tableSortState: {
    sortEnabled: false,
    expandable: true,
    categoryIndex: null,
  },
  newQuestionOrder: [],
};

type ManageAction = string;

type SelectedCategory = QuestionCategoryNode | any;

export type SelectedQuestion = {
  answers: Array<QuestionAnswerChoiceNode>;
  category?: any | null;
  group?: QuestionGroupNode;
  groupId: string | null;
  groupOrder: number | null;
  helpText: string | null;
  id: string;
  order: number | null;
  question: string;
  questionType: string;
  variableName: string;
  rmfActionPaths: QuestionRmfActionPathNode[];
  defaultAnswers: any;
};

export type SelectedQuestions = Array<SelectedQuestion>;

export interface WfReducerState {
  selectedCategory?: SelectedCategory;
  selectedModule?: QuestionModuleNode;
  manageAction?: ManageAction;
  newQuestionOrder?: any;
}

interface ModuleClick {
  data: any;
  activeModule: string;
  manageAction: string;
}

interface ChangeModule {
  data: any;
  params: any;
  activeModule: string;
}

interface CallbackState {
  callbackState: any;
}

type WorkflowReducer =
  | {
      type: "handleManageModuleClick";
      params: ModuleClick;
    }
  | {
      type: "initModule";
      params: {
        initModule: QuestionModuleNode;
      };
    }
  | {
      type: "changeModule";
      params: ChangeModule;
    }
  | {
      type: "onCollapse";
      params: TableSortState;
    }
  | {
      type: "onTabChange";
    }
  | {
      type: "resetSort";
      params: TableSortState;
    }
  | {
      type: "resetState";
    }
  | {
      type: "notifyDiscard";
      params: CallbackState;
    };

export const workflowStateReducer = (state: WfReducerState, action: WorkflowReducer) => {
  switch (action.type) {
    case "handleManageModuleClick":
      const { data, activeModule, manageAction } = action.params;
      console.log({ handleManageModuleClick: data, action });
      // finds module by id if the tab clicks is actually a module, to populate selected module prop
      const moduleObj = data.allQuestionModules.find((element: QuestionModuleNode) => {
        return element.id === activeModule;
      });
      console.log({ MODULEOBJ: moduleObj });

      return {
        ...state,
        manageAction,
        blankForm: false,
        selectedModule: moduleObj,
      };
    case "initModule":
      return {
        ...state,
        selectedModule: action.params.initModule,
      };
    case "changeModule":
      const moduleData = action?.params?.data?.allQuestionModules.find(
        (module: QuestionModuleNode) => {
          return module.id === action.params.activeModule;
        },
      );
      return {
        ...state,
        selectedModule: moduleData,
      };

    case "onCollapse":
      return {
        ...state,
        tableSortState: {
          expandable: true,
          sortEnabled: false,
          categoryIndex: null,
        },
      };
    case "onTabChange":
      console.log({ onTabChange: state });
      return {
        ...state,
      };
    case "resetState":
      return workflowInitialState;
    case "notifyDiscard":
      const { callbackState } = action.params;
      return {
        ...state,
        ...callbackState,
      };
    default:
      return state;
  }
};
