import React from "react";
import DocumentField, { IDocumentFieldProps } from "./DocumentField";

interface Props extends IDocumentFieldProps {
  label?: string;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

const EditableCheckbox: React.FC<Props> = (props) => {
  const { label, style, contentStyle, editable } = props;
  return (
    <DocumentField {...props} fieldType="MULTIPLE_CHOICE_PICK_MANY" showAnswers>
      {({ styleId, answer, handleClick }) => {
        const checked = answer?.applicationAnswer?.isSaved;
        if (!label) {
          return (
            <input
              readOnly
              type="checkbox"
              className="documentCheckbox"
              id={editable ? styleId : ""}
              checked={checked || false}
              onClick={handleClick}
            />
          );
        }

        return (
          <div style={{ display: "flex", width: "100%", ...style }}>
            <input
              readOnly
              type="checkbox"
              className="documentCheckbox"
              id={editable ? styleId : ""}
              checked={checked || false}
              onClick={handleClick}
            />
            <div
              style={{
                display: "flex",
                marginLeft: "4px",
                width: "100%",
                alignItems: "center",
                ...contentStyle,
              }}
            >
              {label}
            </div>
          </div>
        );
      }}
    </DocumentField>
  );
};

export default EditableCheckbox;
