import { ArrowLeftOutlined, ArrowRightOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Divider, Row, Space } from "antd";
import React, { useState, ReactNode } from "react";
import { Form } from "react-final-form";

interface Props {
  children: any;
  onSubmit: any;
  loading: boolean;
  initialValues?: any;
}

const Wizard = (props: Props) => {
  const { children, onSubmit, loading, initialValues } = props;
  const [page, setPage] = useState(0);

  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;

  const handleNext = (values) => {
    setPage((prevState) => Math.min(prevState + 1, React.Children.count(children) - 1));
  };

  const handlePrevious = () => {
    setPage((prevState) => Math.max(prevState - 1, 0));
  };

  const smartSubmit = async (values, form, callback) => {
    if (isLastPage) {
      return await onSubmit(values, form, callback);
    }

    return handleNext(values);
  };

  return (
    <Form
      initialValues={initialValues}
      subscription={{
        submitting: true,
        submitError: true,
        submitFailed: true,
        errors: true,
        values: true,
        pristine: true,
        valid: true,
        invalid: true,
        touched: true,
      }}
      onSubmit={smartSubmit}
      render={({
        handleSubmit,
        errors,
        submitError,
        submitFailed,
        submitting,
        pristine,
        touched,
        invalid,
        values,
      }) => (
        <form onSubmit={handleSubmit} id="createConditionForm">
          {activePage}
          <Row
            justify="end"
            align="middle"
            style={{
              bottom: 0,
              right: 0,
              height: 55,
              borderTop: "1px solid #f0f0f0",
              background: "white",
              position: "fixed",
              width: 1200,
            }}
          >
            <Space style={{ marginRight: 16 }}>
              {page > 0 && (
                <Button
                  disabled={loading || submitting}
                  onClick={handlePrevious}
                  icon={<ArrowLeftOutlined />}
                >
                  Previous
                </Button>
              )}
              {page < React.Children.count(children) - 1 && (
                <Button
                  disabled={invalid}
                  icon={<ArrowRightOutlined />}
                  htmlType="submit"
                  type="primary"
                >
                  Continue
                </Button>
              )}
              {isLastPage && (
                <Button
                  disabled={submitting || invalid || loading}
                  loading={submitting}
                  icon={<SaveOutlined />}
                  htmlType="submit"
                  type="primary"
                >
                  Submit
                </Button>
              )}
            </Space>
          </Row>
          {/* <pre>
            submitFailed:
            {JSON.stringify(submitFailed, null, 2)}
            <Divider />
            submitError:
            {JSON.stringify(submitError, null, 2)}
            <Divider />
            errors:
            {JSON.stringify(errors, null, 2)}
            <Divider />
            submitting: {JSON.stringify(submitting, null, 2)}
            <Divider />
            invalid:
            {JSON.stringify(invalid, null, 2)}
            <Divider />
            values
            <code>{JSON.stringify(values, null, 2)}</code>
          </pre> */}
        </form>
      )}
    />
  );
};

interface PageProps {
  children: ReactNode;
}

export const WizardPage: React.FC<PageProps> = ({ children }) => {
  return <>{children}</>;
};

export default Wizard;
