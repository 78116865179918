import MarkdownPreview from "@uiw/react-markdown-preview";
import "./styles.css";

interface Props {
  source: string;
  style?: any;
}

const MarkdownPreviewer: React.FC<Props> = ({ source, style }) => {
  return (
    <MarkdownPreview
      className="viewer-pane"
      source={source}
      style={{ width: "100%", ...style }}
      wrapperElement={{
        "data-color-mode": "light",
      }}
      data-color-mode="light"
    />
  );
};

export default MarkdownPreviewer;
