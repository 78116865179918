import React from "react";
import { Row, Progress, ProgressProps } from "antd";

interface Props extends ProgressProps {
  percent: number;
}

const ProgressMetric: React.FC<Props> = (props) => {
  const { percent, trailColor = "lightgrey", ...rest } = props;
  return (
    <Row justify="center">
      <Progress
        trailColor={trailColor}
        percent={parseInt(percent.toFixed(1))}
        status={percent >= 100 ? "success" : "normal"}
        {...rest}
      />
    </Row>
  );
};

export default ProgressMetric;
