import { useNavigate } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Result, Button } from "antd";

const AccessDenied: React.FC = () => {
  useDocumentTitle("Access Denied");
  const navigate = useNavigate();

  return (
    <Result
      style={{ background: "white" }}
      status="error"
      title="Access Denied"
      subTitle="You do not have permission to view this page."
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          Go back
        </Button>
      }
    />
  );
};

export default AccessDenied;
