import moment from "moment";
import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Row, Col } from "antd";

import pages from "./Pages";
export const CTPPages = pages;

export const CTPHeader = () => {
  return (
    <Row justify="space-between">
      <div style={{ marginTop: 20 }}></div>
    </Row>
  );
};

export const CTPFooter = ({ state, pageNumber }) => {
  const style = {
    paddingTop: 5,
    fontSize: 11,
  };

  const document = state?.document;

  return (
    <Row justify="space-between">
      <Col span={8}>
        <p style={style}>
          DD FORM {document?.formNumber}, {moment(document?.revisionDate).format("ll")}
        </p>
      </Col>
      <Col span={8}>
        <center style={style}>PREVIOUS EDITION IS OBSOLETE.</center>
      </Col>
      <Col span={8}>
        <p style={{ ...style, textAlign: "right" }}>
          Page: {pageNumber} of {document?.numberOfPages}
        </p>
      </Col>
    </Row>
  );
};

export const FieldWrapper = ({ height, children, styles }) => {
  return (
    <div
      style={{
        ...styles,
        background: "#AAD3EE",
        maxWidth: "100%",
        border: "1px solid #000000",
        height: height ? height : 170,
        marginBottom: 12,
      }}
    >
      {children}
    </div>
  );
};
