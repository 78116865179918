import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
const { Text } = Typography;

interface Props extends TextProps {
  label: string;
  strong?: boolean;
}

const TextLabel: React.FC<Props> = ({ label, strong = false, ...rest }) => {
  return (
    <Text type={label === "Unassigned" ? "warning" : undefined} strong={strong} {...rest}>
      {label}
    </Text>
  );
};

export default TextLabel;
