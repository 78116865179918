import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, InformationTypeSelect, PrivacyFactorSelect } from "..";
import { Button, Space } from "antd";

export const P9 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const OMarking = {
    label: "Select Marking",
    value: "overlay_marking",
  };

  return (
    <>
      <div className="itcsc page">
        <div className="page-table">
          <table border={1} className="privacy_c">
            <colgroup>
              <col span={1} width={'30%'} />
              <col span={1} width={'30%'} />
              <col span={1} width={'30%'} />
            </colgroup>

            <thead>
              <tr>
                <th className="title" colSpan={3} style={{ textAlign: 'start', paddingLeft: 5 }}>
                  14C. Privacy Factors (Select One Per Factor)
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="text">
                <td>
                  <b>
                    Privacy Factor 1 (Identifiability):
                  </b>
                  <p>
                    Organizations should evaluate how easily PII
                    can be used to identify specific individuals.
                  </p>
                </td>

                <td>
                  <b>
                    Privacy Factor 2 (Quantity of PII):
                  </b>
                  <p>
                    Organizations may also choose to consider
                    how many individuals are identified in the
                    information (e.g., number of records).
                  </p>
                </td>

                <td>
                  <b>
                    Privacy Factor 3 (Data Field Sensitivity):
                  </b>
                  <p>
                    Organizations should evaluate the sensitivity
                    of each individual PII data field, as well as the
                    sensitivity of the PII data fields together
                  </p>
                </td>
              </tr>

              <tr className="fields">
                <td>
                  <PrivacyFactorSelect
                    elementProps={elementProps}
                    fieldName={'14c_privacy_factor_1_identifiability'}
                  />
                </td>
                <td>
                  <PrivacyFactorSelect
                    elementProps={elementProps}
                    fieldName={'14c_privacy_factor_2_quantity'}
                  />
                </td>
                <td>
                  <PrivacyFactorSelect
                    elementProps={elementProps}
                    fieldName={'14c_privacy_factor_3_sensitivity'}
                  />
                </td>
              </tr>

              <tr className="text">
                <td>
                  <b>
                    Privacy Factor 4 (Context of Use):
                  </b>
                  <p>
                    Organizations should access the purpose for
                    which PII is collected, stored, used,
                    processed, disclosed, or disseminated.
                  </p>
                </td>

                <td>
                  <b>
                    Privacy Factor 5 (Obligation to Protect
                    Confidentiality):
                  </b>
                  Evaluate the requirements
                  (e.g., laws, mandates, and regulations) to
                  protect.
                </td>

                <td>
                  <b>
                    Privacy Factor 6 (Access and Location):
                  </b>
                  <p>
                    Organizations may choose to take into
                    consideration the nature of authorized access
                    to PII.
                  </p>
                </td>
              </tr>

              <tr className="fields">
                <td>
                  <PrivacyFactorSelect
                    elementProps={elementProps}
                    fieldName={'14c_privacy_factor_4_context'}
                  />
                </td>
                <td>
                  <PrivacyFactorSelect
                    elementProps={elementProps}
                    fieldName={'14c_privacy_factor_5_confidentiality'}
                  />
                </td>
                <td>
                  <PrivacyFactorSelect
                    elementProps={elementProps}
                    fieldName={'14c_privacy_factor_6_access'}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ borderRight: 'none' }}>
                  <b>14D. Determine PII Confidentiality Impact Level (PCIL)</b><br />
                  <i>Note 14</i>: The confidentiality of PII should be protected based on its impact level.<br />
                  The PCIL (Low, Moderate, or High) indicates the potential harm that could result to<br />
                  the subject individuals and/or the organization if PII were inappropriately accessed,<br />
                  used, or disclosed.<br />
                  <i>Note 15</i>: Apply applicable Privacy Overlay.
                </td>
                <td style={{ borderLeft: 'none', verticalAlign: 'middle' }}>
                  <PrivacyFactorSelect
                    elementProps={elementProps}
                    fieldName={'14d_privacy_factor_pcil'}
                  />
                </td>

              </tr>

            </tbody>


          </table>
        </div>

      </div>
    </>
  );
};
