import { useState } from "react";
import { useParams } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import { Card, Input } from "antd";
import {
  CheckCircleTwoTone,
  FileWordTwoTone,
  FormOutlined,
  MonitorOutlined,
} from "@ant-design/icons";
import DraftApplicationDocuments from "./DraftApplicationDocuments";
import CompletedApplicationDocuments from "./CompletedApplicationDocuments";

const ApplicationDocuments = () => {
  useDocumentTitle("Workflow Documents");
  const { applicationId } = useParams();
  const [page, setPage] = useState<string>("drafts");
  const [searchText, setSearchText] = useState("");

  const handleSearch = (input: string) => {
    setSearchText(input);
  };

  const tabList = [
    {
      tab: (
        <>
          <FormOutlined style={{ color: "#FAAD14" }} />
          Drafts
        </>
      ),
      key: "drafts",
    },
    {
      tab: (
        <>
          <CheckCircleTwoTone twoToneColor="#51C419" />
          Completed
        </>
      ),
      key: "completed",
    },
    {
      tab: (
        <>
          <MonitorOutlined style={{ color: "#rgb(24,144,255)" }} />
          Evidence
        </>
      ),
      key: "evidence",
      disabled: true,
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    drafts: (
      <DraftApplicationDocuments applicationId={applicationId} searchText={searchText} />
    ),
    completed: (
      <CompletedApplicationDocuments applicationId={applicationId} searchText={searchText} />
    ),
  };

  return (
    <>
      <CustomPageHeader title="Workflow Documents" icon={<FileWordTwoTone />} />
      <Card
        tabList={tabList}
        activeTabKey={page}
        onTabChange={setPage}
        tabBarExtraContent={
          <Input.Search
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            placeholder="Search for documents"
            style={{ width: 550 }}
            enterButton
          />
        }
      >
        {contentList[page]}
      </Card>
    </>
  );
};

export default ApplicationDocuments;
