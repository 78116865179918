import { Alert, Button, Form, Input, message, Modal, Row, Select } from "antd";
import commonModalProps from "components/Common/commonModalProps";
import { ApprovalStatusEnums } from "api/enums";
import useAccessControl, { Roles } from "hooks/useAccessControl";
import { useMutation } from "@apollo/client";
import Api from "api";
import { SecurityFindingNode } from "types";

type SecModalProps = {
  visible: boolean;
  selectedFindings: Array<SecurityFindingNode>;
  onClose: () => void;
};

const SecurityFindingsModal: React.FC<SecModalProps> = ({
  visible,
  onClose,
  selectedFindings,
}) => {
  const { RBAC } = useAccessControl();
  const [form] = Form.useForm();
  const [updateFinding, { loading }] = useMutation(Api.SecurityFindings.UpdateFinding(), {
    onCompleted: (res) => {
      message.success("Successfully updated Security Finding(s)", 7);
      console.log(res);
      onClose();
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
    refetchQueries: [Api.SecurityFindings.GetAppFindings()],
  });
  const rDescription = Form.useWatch("remediationDescription", form);
  const guidance = Form.useWatch("advisorGuidance", form);
  const status = Form.useWatch("approvalStatus", form);
  console.log({ selectedFindings });

  const onFinish = (values: any) => {
    console.log({ values });
    selectedFindings.forEach((finding) => {
      updateFinding({
        variables: {
          id: finding.id,
          remediationDescription: values.remediationDescription,
          advisorGuidance: values.advisorGuidance,
          approvalStatus: values.approvalStatus,
        },
      });
    });
  };

  const selectedFindingCount = selectedFindings?.length;
  const disableSubmit = !rDescription && !guidance && !status;

  console.log("disableSubmit?: ", disableSubmit);
  // only use initialValues when 1 finding selected
  const initialValues = selectedFindingCount > 1 ? [] : selectedFindings[0];

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Manage Security Findings"
      bodyStyle={{ paddingBottom: 0 }}
      {...commonModalProps}
    >
      <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
        {selectedFindings?.length >= 2 && (
          <Alert
            message={`Notice: You have ${selectedFindingCount} findings currently selected`}
            description={
              <p style={{ paddingBottom: 0, marginBottom: 0 }}>
                Any changes will overwrite <strong>all</strong> selected findings!
              </p>
            }
            type="warning"
            showIcon
            style={{ marginBottom: 24 }}
          />
        )}
        {RBAC([Roles.ApplicantUser]) && <UserForm />}
        {RBAC([Roles.CsarAdvisor]) && <AdvisorForm />}
        {RBAC([Roles.RiskManager]) && <RiskManagerForm />}
        <Row justify="end" style={{ marginTop: 32 }}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={disableSubmit}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default SecurityFindingsModal;

const UserForm: React.FC<any> = () => {
  return (
    <Form.Item
      label="Remediation Description"
      name="remediationDescription"
      tooltip={{
        title: "Provide a description for how you remediated the Security Finding",
      }}
    >
      <Input.TextArea autoSize={{ minRows: 2 }} />
    </Form.Item>
  );
};

const AdvisorForm: React.FC<any> = () => {
  return (
    <Form.Item
      label="Advisor Guidance"
      name="advisorGuidance"
      tooltip={{ title: "Provide guidance for a CSAR Application user." }}
    >
      <Input.TextArea autoSize={{ minRows: 2 }} />
    </Form.Item>
  );
};

const RiskManagerForm: React.FC<any> = () => {
  return (
    <>
      <UserForm />
      <AdvisorForm />
      <Form.Item label="Approval Status" name="approvalStatus">
        <Select options={ApprovalStatusEnums} />
      </Form.Item>
    </>
  );
};
