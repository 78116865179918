import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Col, Row } from "antd";

export const P2 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pia"
        style={{
          width: "100%",
          fontSize: 10,
        }}
      >
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col span={12} style={{ display: "flex", alignItems: 'center' }}>
            <div style={{ height: "fit-content", alignSelf: "center" }}>
              <EditableCheckbox {...elementProps} fieldName="2_other_dod_components" />{" "}
            </div>
            <span style={{ marginLeft: 10 }}>Other Dod Components</span>
          </Col>
          <Col span={11} style={{ display: "flex" }}>
            <span style={{ marginRight: 10 }}>Specify</span>
            <EditableField {...elementProps} fieldName="2_other_dod_components_text" />
          </Col>
        </Row>
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col span={12} style={{ display: "flex", alignItems: 'center' }}>
            <div style={{ height: "fit-content", alignSelf: "center" }}>
              <EditableCheckbox {...elementProps} fieldName="2_other_federal_agencies" />{" "}
            </div>
            <span style={{ marginLeft: 10 }}>Other Federal Agencies</span>
          </Col>
          <Col span={11} style={{ display: "flex" }}>
            <span style={{ marginRight: 10 }}>Specify</span>
            <EditableField {...elementProps} fieldName="2_other_federal_agencies_text" />
          </Col>
        </Row>
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col span={12} style={{ display: "flex", alignItems: 'center' }}>
            <div style={{ height: "fit-content", alignSelf: "center" }}>
              <EditableCheckbox {...elementProps} fieldName="2_state_local_agencies" />{" "}
            </div>
            <span style={{ marginLeft: 10 }}>State and Local Agencies</span>
          </Col>
          <Col span={11} style={{ display: "flex" }}>
            <span style={{ marginRight: 10 }}>Specify</span>
            <EditableField {...elementProps} fieldName="2_state_local_agencies_text" />
          </Col>
        </Row>
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col span={12} style={{ display: "flex", alignItems: 'center' }}>
            <div style={{ height: "fit-content", alignSelf: "center" }}>
              <EditableCheckbox {...elementProps} fieldName="2_contractor_name" />
            </div>
            <span style={{ marginLeft: 10, height: "fit-content" }}>
              Contractor <i>(Name of contractor and describe the language in the contract that safeguards PII.  Include whether FAR privacy clauses, i.e., 52.224-1, Privacy Act Notification, 52.224-2, Privacy Act, and FAR 39.105 are included in the contract.)</i>
            </span>
          </Col>
          <Col span={11} style={{ display: "flex" }}>
            <span style={{ marginRight: 10 }}>Specify</span>
            <EditableField {...elementProps} fieldName="2_contractor_name_text" />
          </Col>
        </Row>
        <Row justify="space-between" align="stretch">
          <Col span={12} style={{ display: "flex", alignItems: 'center' }}>
            <div style={{ height: "fit-content", alignSelf: "center" }}>
              <EditableCheckbox {...elementProps} fieldName="2_other" />
            </div>
            <span style={{ marginLeft: 10 }}>
              Other (e.g. commercial providers, colleges).
            </span>
          </Col>
          <Col span={11} style={{ display: "flex" }}>
            <span style={{ marginRight: 10 }}>Specify</span>
            <EditableField {...elementProps} fieldName="2_other_text" />
          </Col>
        </Row>
        <Row style={{ paddingBottom: 2 }}>
          <b>i. Source of the PII collected is:</b>
          <i>(Check all that apply and list all information systems if applicable)</i>
        </Row>
        <Row>
          <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <EditableCheckbox {...elementProps} fieldName="2_i_source_of_pii_individuals" />
              <span style={{ marginLeft: 10 }}>Individuals</span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_i_source_of_pii_existing_dod_info"
              />
              <span style={{ marginLeft: 10 }}>Existing DoD Information Systems</span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_i_source_of_pii_other_fed_info_sys"
              />
              <span style={{ marginLeft: 10 }}>Other Federal Information Systems</span>
            </div>
          </Col>
          <Col span={11} style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <EditableCheckbox {...elementProps} fieldName="2_i_source_of_pii_databases" />
              <span style={{ marginLeft: 10 }}>Databases</span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_i_source_of_pii_commercial_sys"
              />
              <span style={{ marginLeft: 10 }}>Commercial Systems</span>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: 5 }}>
          <EditableField
            {...elementProps}
            fieldName="2_i_source_of_pii_text"
            style={{ height: "50px" }}
          />
        </Row>
        <Row style={{ paddingBottom: 2 }}>
          <b>j. How will the information be collected:</b>
          <i>(Check all that apply and list all Official Form Numbers if applicable)</i>
        </Row>
        <Row>
          <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_j_how_will_information_collected_email"
              />
              <span style={{ marginLeft: 10 }}>E-mail</span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_j_how_will_information_collected_f2f_contact"
              />
              <span style={{ marginLeft: 10 }}>Face-to-Face Contact</span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_j_how_will_information_collected_fax"
              />
              <span style={{ marginLeft: 10 }}>Fax</span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_j_how_will_information_collected_infor_sharing"
              />
              <span style={{ marginLeft: 10 }}>Information Sharing - System to System</span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_j_how_will_information_collected_other"
              />
              <span style={{ marginLeft: 10 }}>
                Other <i>(If other, enter the information in the box below)</i>
              </span>
            </div>
          </Col>
          <Col span={11} style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_j_how_will_information_collected_official_form"
              />
              <span style={{ marginLeft: 10 }}>
                Official Form <i>(Enter Form Number(s) in the box below)</i>
              </span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_j_how_will_information_collected_paper"
              />
              <span style={{ marginLeft: 10 }}>Paper</span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_j_how_will_information_collected_telephone_interview"
              />
              <span style={{ marginLeft: 10 }}>Telephone Interview</span>
            </div>
            <div>
              <EditableCheckbox
                {...elementProps}
                fieldName="2_j_how_will_information_collected_website"
              />
              <span style={{ marginLeft: 10 }}>Website/E-Form</span>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: 5 }}>
          <EditableField
            {...elementProps}
            fieldName="2_j_how_will_information_collected_text"
            style={{ height: "50px" }}
          />
        </Row>
        <Row>
          <b>
            k.  Does this DoD Information system or electronic collection require a Privacy Act System of Records Notice (SORN)?
          </b>
          <p style={{ marginTop: 10 }}>
            A Privacy Act SORN is required if the information system or electronic collection contains information about U.S. citizens or lawful permanent U.S. residents that
            is retrieved by name or other unique identifier.  PIA and Privacy Act SORN information must be consistent.
          </p>
        </Row>
        <Row>
          <div style={{ marginRight: 10, marginLeft: 10 }}>
            <EditableRadio
              {...elementProps}
              fieldName="2_k_privacy_act_system_of_records_notice_yes"
            />
            <span style={{ marginLeft: 10 }}>Yes</span>
          </div>
          <div>
            <EditableRadio
              {...elementProps}
              fieldName="2_k_privacy_act_system_of_records_notice_no"
            />
            <span style={{ marginLeft: 10 }}>No</span>
          </div>
        </Row>
        <Row>
          If "Yes." enter SORN System Identifier
          <EditableField
            {...elementProps}
            fieldName="2_k_privacy_act_system_of_records_notice_yes_sorn_number"
            style={{ marginLeft: 10, width: "150px" }}
          />
        </Row>
        <br />
        <Row>
          SORN Identifier, not the Federal Register (FR) Citation.  Consult the DoD Component Privacy Office for additional information or http://dpcld.defense.gov/
          Privacy/SORNs/
          <div style={{ paddingLeft: 40 }}>or</div>
        </Row>
        <Row>
          If a SORN has not yet been published in the Federal Register, enter date of submission for approval to Defense Privacy, Civil Liberties, and Transparency
        </Row>
        <Row>
          Division (DPCLTD).  Consult the DoD Component Privacy Office for this date
          <EditableField
            {...elementProps}
            isDate
            fieldName="2_k_dpcltd_date_of_submission"
            style={{ marginLeft: 10, width: "150px" }}
          />
        </Row>

        <Row style={{ paddingTop: 5 }}>
          If "No," explain why the SORN is not required in accordance with DoD Regulation 5400.11-R: Department of Defense Privacy Program.
          <EditableField
            {...elementProps}
            fieldName="2_k_explain_sorn_not_required_text"
            style={{ height: "50px" }}
          />
        </Row>

        <Row>
          <b>
            l. What is the National Archives and Records Administration (NARA) approved,
            pending or general records schedule (GRS) disposition authority for the system or
            for the records maintained in the system?
          </b>
        </Row>

        <br />
        <Row>
          (1) NARA Job Number or General Records Schedule Authority.
          <EditableField
            {...elementProps}
            fieldName="2_l_nara_job_numbers_text"
            style={{ marginLeft: 10, width: "150px" }}
          />
        </Row>

        <br />
        <Row>
          (2) If pending, provide the date the SF-115 was submitted to NARA.
          <EditableField
            {...elementProps}
            isDate
            fieldName="2_l_pending_provide_date_text"
            style={{ marginLeft: 10, width: "150px" }}
          />
        </Row>

        <br />
        <Row>
          (3) Retention Instructions.
          <EditableField
            {...elementProps}
            fieldName="2_l_retention_instructions_text"
            style={{ width: "100%", height: "50px" }}
          />
        </Row>
      </div>
    </>
  );
};
