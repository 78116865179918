import { Button, Drawer, Space } from "antd";
import { useState } from "react";
import MarkdownEditor from "./Editor";
import useDynamicDrawerWidth from "hooks/useDynamicDrawerWidth";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import InputConfirmModal from "components/Common/InputConfirmModal";

interface Props {
  open: boolean;
  setOpen: (arg: boolean) => void;
  value: string;
  onChange: (value: string, arg2?: any) => void;
  clearValue?: () => void;
  onSubmit?: () => void;
  loading?: boolean;
}

/**
 * * will be a child component, not route params
 *
 * * use onBlur to always return the state value
 * * take in a submit function,
 * @returns
 */

const MarkdownDrawer: React.FC<Props> = ({
  open,
  setOpen,
  value,
  onChange,
  clearValue = () => {},
  onSubmit = () => {},
  loading,
}) => {
  const width = useDynamicDrawerWidth();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
    setDeleteModalOpen(false);
    clearValue();
  };

  const onSave = () => {
    onSubmit();
    setOpen(false);
    console.log({ savedValue: value });
  };

  return (
    <Drawer
      open={open}
      width={width}
      extra={
        <Space>
          <Button onClick={onClose} disabled={loading} icon={<CloseOutlined />} danger>
            Cancel
          </Button>
          <Button onClick={onSave} loading={loading} icon={<SaveOutlined />} type="primary">
            Save
          </Button>
        </Space>
      }
      title="Markdown Editor"
      maskClosable={false}
      closable={false}
    >
      <MarkdownEditor onChange={onChange} value={value} clearValue={clearValue} />
      {deleteModalOpen && (
        <InputConfirmModal
          prompt="You are about to lose your sick ass markdown bro."
          visible={deleteModalOpen}
          confirmation="Yes"
          mutation={onClose}
          loading={false}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
};

MarkdownDrawer.defaultProps = {
  onChange() {},
};

export default MarkdownDrawer;
