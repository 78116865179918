import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Link, Outlet, useLocation } from "react-router-dom";
import { message, Button, Tag, Card, Row, Col } from "antd";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import Api from "api";
import useDocumentTitle from "hooks/useDocumentTitle";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import NestedGroupsTable from "./NestedGroupsTable";
import ExpandIcon from "../../../../Common/ExpandedTableIcon";
import { ColumnsType } from "antd/es/table";
import { QuestionGroupNode } from "types";
import CreateGroupDrawer from "components/Administration/Modals/CreateGroupDrawer";
import "styles/draggableTable.css";
import { MapGroupTag } from "components/Common/Tags/QuestionGroup";
import SmartTable from "components/Common/Tables/index";

/**
 *
 * @returns Question Groups Table with nested table for questions in that group
 */

const QuestionGroupsTable: React.FC = () => {
  useDocumentTitle("Question Groups Management");
  const location = useLocation();
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const { data, loading } = useQuery(Api.QGroup.getAllTheQuestionGroups(), {
    onCompleted: (res) => {
      console.log({ allQuestionGroups: res });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const questionGroups = data?.allQuestionGroups;

  const onClose = () => {
    setCreateDrawerOpen(false);
  };

  const columns: ColumnsType<QuestionGroupNode> = [
    {
      title: "Type",
      dataIndex: "groupType",
      width: "8%",
      sorter: {
        compare: (a, b, c) =>
          a.groupType.localeCompare(b.groupType) - b.groupType.localeCompare(a.groupType),
      },
      render: (text, record, _index) => MapGroupTag(text, record.id),
    },
    { title: "Header", dataIndex: "header", width: "25%" },
    { title: "Description", dataIndex: "description" },
    {
      title: "Category",
      dataIndex: "categoryId",
      render: (_text, record, _index) =>
        record.category?.name ? record.category.name : <Tag key={record.id}>Unassigned</Tag>,
    },
    {
      title: "Manage",
      width: "5%",
      align: "right",
      render: (_text, record, _index) => {
        if (!record?.id) return;
        return (
          <Link to={`/administration/question-groups/group-editor/${record.id}`}>
            <Button icon={<SettingOutlined />} />
          </Link>
        );
      },
    },
  ];

  const activeRoute = location?.pathname.split("/").pop();
  const editorModalOpen = activeRoute !== "question-groups" ? true : false;

  useEffect(() => {
    console.log({ questionGroups });
  }, [questionGroups]);

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="Question Groups"
        extra={[
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setCreateDrawerOpen(true)}
            key="createButton"
          >
            Create
          </Button>,
        ]}
      />
      <Row align="middle">
        <Col span={24}>
          <Card className="shadowed-static">
            <SmartTable
              loading={loading}
              data={questionGroups}
              columns={columns}
              pagination={{ pageSizeOptions: [25, 50, 100, 200], defaultPageSize: 25 }}
              expandable={
                !editorModalOpen
                  ? {
                      expandedRowRender: (record: QuestionGroupNode) => (
                        <NestedGroupsTable data={record} key={record.id} />
                      ),
                      expandIcon: ({ expanded, onExpand, record }) =>
                        record.questions.length ? (
                          <ExpandIcon
                            expanded={expanded}
                            onExpand={onExpand}
                            record={record}
                            key={record.id}
                          />
                        ) : undefined,
                    }
                  : undefined
              }
            />
          </Card>
        </Col>
      </Row>
      {createDrawerOpen && <CreateGroupDrawer open={createDrawerOpen} onClose={onClose} />}
      <Outlet />
    </>
  );
};

export default QuestionGroupsTable;
