import React from "react";
import { MultipleChoiceInputProps } from "../Questions/MultipleChoiceQuestion";
import AdditionalAnswerRenderer from "../AdditionalAnswerRenderer";
import { Checkbox, Space } from "antd";

const PickManyInput: React.FC<MultipleChoiceInputProps> = ({
  defaultAnswer,
  question,
  onChange,
  onChangeAdditionalField,
  onUnsave,
  disabled,
}) => {
  return (
    <Checkbox.Group
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      }}
      defaultValue={defaultAnswer}
      disabled={disabled}
    >
      <Space direction="vertical" size={2}>
        {question.answerChoices.map((answerChoice: any) => {
          const isToggled = answerChoice.additionalFieldToggle;
          const isSaved = answerChoice.isSaved;
          const hasAdditional = answerChoice.additionalFieldType !== null;
          const showAdditional = hasAdditional && isToggled;
          const shouldUnsave = isToggled || isSaved;

          const savedAnswerChoice = question.savedAnswers.find(
            (sa) => sa?.id === answerChoice.id,
          )!;

          const defaultAnswer = savedAnswerChoice?.answer || "";

          return (
            <React.Fragment key={answerChoice.id}>
              <Checkbox
                value={answerChoice.id}
                onChange={(e) => {
                  const id = e.target.value;
                  const answer = { id };

                  if (hasAdditional) {
                    console.log("Toggling additional");
                  }

                  if (shouldUnsave) {
                    return onUnsave(id);
                  }

                  onChange(answer);
                }}
              >
                {answerChoice.answerText}
              </Checkbox>
              {showAdditional && (
                <AdditionalAnswerRenderer
                  type={answerChoice.additionalFieldType}
                  answerId={answerChoice.id}
                  defaultAnswer={defaultAnswer}
                  onChange={onChangeAdditionalField}
                  disabled={disabled}
                />
              )}
            </React.Fragment>
          );
        })}
      </Space>
    </Checkbox.Group>
  );
};

export default PickManyInput;
