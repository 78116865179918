import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Button } from "antd";

import P7D1 from '../Assets/P7D1.png'

export const P16 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="instructions">
        <ul className="slash">
          <li className="no-bullet">
            organizations in the community, a third party, or some combination of them, and it may exist on or off premises.
          </li>
          <li>
            Public Cloud: The cloud infrastructure is provisioned for open use by the general public. It may be owned, managed, and operated by a business, academic, or government organization, or some combination of them. It exists on the premises of the cloud provider.
          </li>
          <li>
            Hybrid Cloud: The cloud infrastructure is a composition of two or more distinct cloud infrastructures (private, community, or public) that remain unique entities, but are bound together by standardized or proprietary technology that enables data and application portability (e.g., cloud bursting for load balancing between clouds).”
          </li>

        </ul>

        <p><b>10A. Type of Cloud Service</b></p>

        <ul className="slash">
          <li>
            Infrastructure as a Service (IaaS). The capability provided to the consumer is to provision
            processing, storage, networks, and other fundamental computing resources where the consumer is
            able to deploy and run arbitrary software, which can include operating systems and applications.
            The consumer does not manage or control the underlying cloud infrastructure but has control over
            operating systems, storage, and deployed applications; and possibly limited control of select
            networking components (e.g., host firewalls).
          </li>
          <li>
            Platform as a Service (PaaS). The capability provided to the consumer is to deploy onto the cloud
            infrastructure consumer-created or acquired applications created using programming languages,
            libraries, services, and tools supported by the provider. The consumer does not manage or control the
            underlying cloud infrastructure including network, servers, operating systems, or storage, but has
            control over the deployed applications and possibly configuration settings for the application-hosting
            environment.
          </li>
          <li>
            Software as a Service (SaaS). The capability provided to the consumer is to use the provider's
            applications running on a cloud infrastructure. The applications are accessible from various client
            devices through either a thin client interface, such as a web browser (e.g., web-based email), or a
            program interface. The consumer does not manage or control the underlying cloud infrastructure
            including network, servers, operating systems, storage, or even individual application capabilities,
            with the possible exception of limited user- specific application configuration settings.
          </li>
        </ul>

        <img style={{ maxWidth: "100%" }} src={P7D1} alt="page 6 diagram 1" />
        <p><b>11.Cloud Impact Level (IL) Determination</b></p>

        <p>
          <b>11A. IL6:</b>Classified Information up to Secret. (Up toM-M-x): Accommodates non- public, classified NSS
          system data (i.e., classified national security information [NSI]) or non- public, unclassified data where the
          unauthorized disclosure of information could be expected to have a serious adverse effect on organizational
          operations, organizational assets, or individuals).
        </p>

      </div>
    </>
  );
};
