import { Card, Col, Row } from "antd";
import RenderModules from "./RenderModules";
import { QuestionModuleNode } from "types";
import LockedTabHeader from "./LockedTabHeader";
import ModuleStatsHeader from "./ModuleStatsHeader";

type Props = {
  modules: any;
  activeModule: string;
  setActiveModule: any;
  activeCategory: string;
  setActiveCategory: any;
  state: any;
  dispatch: any;
};

const ModuleTabView: React.FC<Props> = (props) => {
  const {
    modules,
    activeModule,
    setActiveModule,
    activeCategory,
    setActiveCategory,
    state,
    dispatch,
  } = props;

  const onTabChange = (key: string) => {
    setActiveModule(key);
    dispatch({
      type: "changeModule",
      params: {
        data: modules,
        activeModule: key,
      },
    });
  };

  return (
    <Row align="middle">
      <Col span={24}>
        <Card
          className="shadowed-static"
          activeTabKey={activeModule}
          onTabChange={onTabChange}
          tabList={modules?.allQuestionModules.map((module: QuestionModuleNode) => ({
            key: module.id,
            tab: <LockedTabHeader module={module} locked={module.hasCheckpoint!} />,
          }))}
          tabProps={{
            animated: {
              tabPane: true,
              inkBar: true,
            },
          }}
        >
          {modules?.allQuestionModules.map((module: QuestionModuleNode) => (
            <div key={module.id}>
              {activeModule === module.id && (
                <>
                  <ModuleStatsHeader module={module} />
                  <RenderModules
                    module={module}
                    state={state}
                    dispatch={dispatch}
                    activeCategory={activeCategory}
                    setActiveCategory={setActiveCategory}
                  />
                </>
              )}
            </div>
          ))}
        </Card>
      </Col>
    </Row>
  );
};

export default ModuleTabView;
