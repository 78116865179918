import { Checkbox, Col, Row, Switch } from "antd";
import { mapTagToType } from "components/Common/Tags/QuestionType";
import { check } from "prettier";
import { useEffect, useState } from "react";
import { DataRow, EditableColumn } from "../Shared/types";
import EditableCell from "./EditableCell";

interface Props {
  column: EditableColumn;
  columnIndex: number;
  record: any;
  dataSource: DataRow[];
  setDataSource: React.Dispatch<React.SetStateAction<any[]>>;
}

const ColumnRenderer: React.FC<Props> = (props) => {
  const { column, columnIndex, record, dataSource, setDataSource } = props;

  // console.log("%cColumnRenderProps: ", "color:purple", {
  //   record,
  //   column,
  //   columnIndex,
  //   dataSource,
  //   setDataSource,
  // });

  // useEffect(() => {
  //   if (column.questionType != undefined) {
  //     if (!columnsUpdated) {
  //       if (columnIndex === 0) {
  //         record.ignore = !record.ignore;
  //         setlabelOnly(record.ignore);
  //       }
  //       setcolumnsUpdated(true);
  //     }
  //   }
  // }, [column.questionType]);

  const onCheck = (event) => {
    // record.ignore = !record.ignore;
    // setlabelOnly(record.ignore);
    const oldDataSource = [...dataSource];
    const newDataSource = oldDataSource.map((row) => {
      if (row.key === record.key) {
        return {
          ...row,
          ignore: !row.ignore,
        };
      }
      return row;
    });

    console.log("%cUpdated Record: ", "color:orange", {
      record,
      dataSource,
    });
    setDataSource(newDataSource);
  };

  // * First column can be row label ONLY [These are ROW LABELS]
  if (columnIndex === 0) {
    return (
      <Row justify="space-between" style={{ width: "100%" }}>
        {column.questionType && (
          <Switch
            unCheckedChildren="Label"
            checkedChildren=""
            checked={record.ignore}
            style={{ marginRight: 10 }}
            onChange={onCheck}
          />
        )}
        {!record.ignore && (
          <Col flex="80">
            <EditableCell
              title={record.label}
              dataIndex={column.dataIndex}
              record={record}
              column={column}
              rows={dataSource}
              setRows={setDataSource}
              editable={true}
            />
          </Col>
        )}
        {column.questionType && record.ignore ? (
          <Col flex="10">{mapTagToType(column.questionType)}</Col>
        ) : null}
      </Row>
    );
  }

  if (column.questionType) {
    return <span>{mapTagToType(column.questionType)}</span>;
  }

  return <></>;
};

export default ColumnRenderer;
