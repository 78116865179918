import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

import P10D1 from "../Assets/P10D1.png";
import P10D2 from "../Assets/P10D2.png";
import Super from "components/Deliverables/Components/Super";

export const P10 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pcilv2"
        style={{
          width: "95%",
          fontSize: 12,
        }}
      >
        <div style={{ paddingLeft: 80 }}>
          <img src={P10D1} alt="P8D1" style={{ width: "100%" }} />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>
            Factor 4. <u>Select</u> Obligation to Protect Confidentiality impact value
          </b>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="10_obligation_protect_confidentiality_low"
              />
              <span style={{ marginLeft: 10, fontSize: 14 }}>LOW</span>
            </div>
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="10_obligation_protect_confidentiality_moderate"
              />
              <span style={{ marginLeft: 10, fontSize: 14 }}>MODERATE</span>
            </div>
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="10_obligation_protect_confidentiality_high"
              />
              <span style={{ marginLeft: 10, fontSize: 14 }}>HIGH</span>
            </div>
          </div>
          <br />
          <div>
            <EditableField
              {...elementProps}
              fieldName="10_obligation_protect_confidentiality_text"
              style={{ width: "100%", height: 100 }}
            />
          </div>
          <br />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <div>
            <b style={{ fontSize: 16, paddingRight: 10 }}>
              FACTOR 5 - ACCESS TO AND LOCATION OF PII
            </b>
          </div>
          <img src={P10D2} alt="P8D1" style={{ width: "100%" }} />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>
            Factor 5 - <u>Select</u> Access to and Location of PII impact value
          </b>
        </div>

        <br />
        <div style={{ marginLeft: 40, borderTop: 'solid black 1px', width: 140 }}></div>
        <br />
        <br />
        <div style={{ paddingLeft: 80 }}>
          <Super>8</Super> The Privacy Act of 1974 contains both civil and criminal penalties.
        </div>
        <div style={{ paddingLeft: 80 }}>
          <Super>9</Super>Ibid
        </div>
      </div>
    </>
  );
};
