import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

import P7D1 from "../Assets/P7D1.png";

export const P7 = (props) => {
  const { state } = props;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const ABTSLMarking = {
    label: "Select Marking",
    value: "authorization_boundary_topology_system_location_marking",
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                border: "1px solid black",
                backgroundColor: "lightgrey",
              }}
            >
              <div>
                <b style={{ fontSize: 14 }}>
                  9. Authorization Boundary Topology and System Location
                </b>
              </div>
              <div style={{ textAlign: "left", width: "150px" }}>
                <ClassificationSelect elementProps={elementProps} config={ABTSLMarking} />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
              }}
            >
              <i style={{ paddingLeft: 5, textAlign: "left" }}>
                NOTE 11: This is a text field only. Please upload any pictures or diagrams
                (e.g., DoDAF OV-1 and SV-6), including interface requirements, to eMASS as
                artifacts
              </i>
            </div>

            <div
              style={{
                display: "flex",
                border: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "225px",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="abtsl_9"
                  // fieldId={document.variableFields[154]?.id}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "40px 10px",
                }}
              >
                <img style={{ maxWidth: "100%" }} src={P7D1} alt="page 7 diagram 1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
