import SmartSearchDropdown from "../Components/SmartSearchDropdown";
import { SearchOutlined } from "@ant-design/icons";
import { SkeletonInnerTable } from "components/Common/Skeletons/CustomSkeletons";

const baseTableProps = (loading) => ({
  bordered: true,
  scroll: { x: 1000 },
  rowKey: (row: any) => row.id,
  pagination: {
    hideOnSinglePage: true,
  },
  locale: {
    emptyText: <SkeletonInnerTable rows={5} loading={loading} />,
  },
});

const getColumnSearchProps = (dataIndex, searchInput) => ({
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : "",
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <SmartSearchDropdown
      dataIndex={dataIndex}
      searchInput={searchInput}
      selectedKeys={selectedKeys}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      submit={confirm}
      reset={clearFilters}
    />
  ),
});

export { baseTableProps, getColumnSearchProps };
