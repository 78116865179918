import { useState } from "react";
import { Field, useFormState, FormSpy } from "react-final-form";
import { message, Typography } from "antd";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { ConditionAnswerOption as AnswerCondition, QuestionType } from "types";
import Wizard, { WizardPage } from "./Wizard";
import Condition, { MultiCondition } from "components/Common/RFForm/Condition";
import SelectField from "components/Common/RFForm/Fields/SelectField";
import Api from "api";
import QuestionInformation from "./Create/QuestionInformation";
import { logicOptions, questionTypeOptions } from "./Shared/SelectOptions";
import LinkQuestionsField from "./Create/Fields/LinkQuestionsField";
import QuestionSelectField from "./Create/Fields/QuestionSelectField";
import WrapperCard from "./Shared/WrapperCard";

interface FormValues {
  answerChoice: string;
  logicType: AnswerCondition;
  dataType: QuestionType;
  questionSelect?: any[];
}

const CreateAnswerConditions: React.FC = () => {
  const navigate = useNavigate();
  const [answerChoices, setAnswerChoices] = useState<any>([]);
  const [applyCondition, { loading }] = useMutation(Api.ConditionalLogic.ApplyCondition(), {
    refetchQueries: [{ query: Api.ConditionalLogic.GetAllConditionalRelationships() }],
  });

  const answerOptions =
    answerChoices.length > 0
      ? answerChoices
          .filter((ans) => ans.answerType == null)
          .map((_ans) => ({ label: _ans.answer, value: _ans.id }))
      : [];

  const onSubmit = (values: FormValues, form) => {
    console.log("onSubmit", { values, form });
    const { logicType, answerChoice } = values;

    if (logicType === AnswerCondition.AdditionalQuestions) {
      applyCondition({
        variables: {
          answerId: answerChoice,
          conditionType: AnswerCondition.AdditionalQuestions,
          additionalQuestionsArgs: {
            questionIds: values.questionSelect,
          },
        },
        onCompleted: (res) => {
          console.log({ UpdateAnswerCondition: res });
          message.success("Answer condition created successfully", 5);
          navigate(-1);
        },
        onError: (error) => {
          console.log(error.message);
          message.error(error.message, 5);
          form.reset(values);
          // return { [FORM_ERROR]: `Submission failed! ${error.message}` };
        },
      });
    } else {
      applyCondition({
        variables: {
          answerId: answerChoice,
          conditionType: AnswerCondition.AdditionalField,
          additionalFieldArgs: {
            dataType: values.dataType,
          },
          questionIds: null,
          moduleIds: null,
        },
        onCompleted: (res) => {
          console.log({ UpdateAnswerCondition: res });
          message.success("Answer condition created successfully", 5);
          navigate(-1);
        },
        onError: (error) => {
          console.log(error.message);
          message.error(error.message, 5);
          form.reset(values);
        },
      });
    }
  };

  const required = (value) => (value ? undefined : "Required");
  const hasQuestionValues = (value) => (value?.length > 0 ? undefined : "Required");

  return (
    <div style={{ paddingBottom: 55 }}>
      <Wizard loading={loading} onSubmit={onSubmit}>
        <WizardPage>
          <WrapperCard title="Question Selection">
            <Field
              name="questionChoice"
              validate={required}
              render={(props) => (
                <QuestionSelectField setAnswerChoices={setAnswerChoices} {...props} />
              )}
            />
          </WrapperCard>
        </WizardPage>

        <WizardPage>
          <WrapperCard title="Question Information">
            <FormSpy subscription={{ values: true }}>
              {(props) => <QuestionInformation question={props.values.questionChoice} />}
            </FormSpy>
          </WrapperCard>
          <WrapperCard title="Logic Options" style={{ marginTop: 16 }}>
            <Field
              name="answerChoice"
              label={
                <>
                  Answer Choice
                  <Typography.Text
                    italic
                    style={{ fontSize: 12, paddingLeft: 10, fontWeight: 400 }}
                  >
                    Answers with existing conditions will not be displayed
                  </Typography.Text>
                </>
              }
              validate={required}
              width="50%"
              options={answerOptions}
              component={SelectField}
            />

            <MultiCondition condition={({ answerChoice }) => answerChoice}>
              <Field<AnswerCondition>
                name="logicType"
                label="Logic Type"
                component={SelectField}
                options={logicOptions}
                validate={required}
                width="50%"
              />
            </MultiCondition>
            <Condition when="logicType" is={AnswerCondition.AdditionalField}>
              <Field<QuestionType>
                name="dataType"
                label="Additional Field Type"
                component={SelectField}
                options={questionTypeOptions}
                validate={required}
                width="50%"
              />
            </Condition>
            <Condition when="logicType" is={AnswerCondition.AdditionalQuestions}>
              <Field<any[]>
                name="questionSelect"
                label="Question Selection"
                validate={hasQuestionValues}
                render={(props) => (
                  <LinkQuestionsField setAnswerChoices={setAnswerChoices} {...props} />
                )}
              />
            </Condition>
          </WrapperCard>
        </WizardPage>
      </Wizard>
    </div>
  );
};

export default CreateAnswerConditions;
