import { useState } from "react";
import { useMutation } from "@apollo/client";
import Api from "api";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { ApplicationQuestionNode } from "types";

interface Props {
  userCanSeePrivate: boolean;
  question: ApplicationQuestionNode | null;
  setAdding: React.Dispatch<boolean>;
  incrementTotalComments: (questionId: string) => void;
}

const CommentForm: React.FC<Props> = ({
  userCanSeePrivate,
  question,
  setAdding,
  incrementTotalComments,
}) => {
  const [form] = Form.useForm();
  const comment = Form.useWatch("comment", form);
  const [privacy, setPrivacy] = useState<boolean>(false);

  const [createComment, { loading: createCommentLoading }] = useMutation(
    Api.ApplicationComment.Create(),
    {
      onCompleted: (res) => {
        message.success("Successfully created a Comment", 7);
        incrementTotalComments(question?.id!);
        setAdding(false);
        form.resetFields();
      },
      onError: (error) => {
        message.error(error.message, 7);
      },
      refetchQueries: [
        {
          query: Api.ApplicationComment.GetAll(),
          variables: {
            id: question?.id,
          },
        },
      ],
    },
  );

  const handleSave = (values: any) => {
    if (!comment) return;
    console.log({ CommentToSave: { ...values, privacy } });
    createComment({
      variables: {
        id: question?.id,
        text: values.comment,
        answerId: values.answerId,
        privacy: privacy ? "PRIVATE" : "PUBLIC",
      },
    });
  };

  const hasMultipleAnswers =
    question?.questionType === "MULTIPLE_CHOICE_PICK_ONE" ||
    (question?.questionType === "MULTIPLE_CHOICE_PICK_MANY" &&
      question?.answerChoices.length > 0);

  const answerOptions = question?.answerChoices.map((ac: any) => ({
    key: ac.id,
    label: ac.answerText,
    value: ac.id,
  }));

  return (
    <Form form={form} layout="vertical" onFinish={handleSave}>
      {userCanSeePrivate && (
        <Form.Item
          name="privacy"
          label={
            <Tooltip
              color="black"
              title="This comment will only be visible to administrators, advisors, and managers"
            >
              Privacy
            </Tooltip>
          }
        >
          <Checkbox checked={privacy} onChange={() => setPrivacy(!privacy)}>
            Private Comment
          </Checkbox>
        </Form.Item>
      )}
      <Form.Item
        name="comment"
        label="Comment"
        rules={[{ required: true, message: "Please enter a comment" }]}
      >
        <Input placeholder="Enter text" />
      </Form.Item>
      {hasMultipleAnswers && (
        <Form.Item name="answerId" label="Answer reference">
          <Select options={answerOptions} />
        </Form.Item>
      )}
      <Row justify="end">
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!comment}
            loading={createCommentLoading}
          >
            Save
          </Button>
          <Button type="primary" onClick={() => setAdding(false)}>
            Cancel
          </Button>
        </Space>
      </Row>
      <Divider />
    </Form>
  );
};

export default CommentForm;
