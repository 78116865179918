/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import useWindowSize from "./useWindowSize";

/**
 * @returns
 * - breakpoints: object with breakpoints as booleans denoting if breakpoint is active within the viewport
 */

interface Breakpoints {
  [key: string]: boolean;
}

enum BP {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
  XXL = "xxl",
}

export const bpSizes = {
  xs: 575,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

const useBreakpoints = () => {
  const { width } = useWindowSize();

  const [active, setActive] = useState<string>("");

  useEffect(() => {
    if (width <= bpSizes[BP.XS]) return setActive(BP.XS);

    let a = "";
    if (width > bpSizes[BP.SM]) a = BP.SM;
    if (width > bpSizes[BP.MD]) a = BP.MD;
    if (width > bpSizes[BP.LG]) a = BP.LG;
    if (width > bpSizes[BP.XL]) a = BP.XL;
    if (width > bpSizes[BP.XXL]) a = BP.XXL;
    return setActive(a);
  }, [width]);

  const breakpoints = useMemo(() => {
    const sizeKeys = Object.keys(bpSizes);
    const bps: Breakpoints = {};
    sizeKeys.forEach((key) => {
      bps[BP.XS] = BP.XS === active ? true : false;
      bps[key] = bpSizes[key] > bpSizes[active] ? false : true;
    });
    return bps;
  }, [active]);

  const isActive = (bp: string) => {
    return bpSizes[bp] <= bpSizes[active];
  };

  // console.log(active);
  // console.log(breakpoints);

  return { active, breakpoints, isActive, width };
};

export default useBreakpoints;
