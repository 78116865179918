import { Modal } from "antd";
import { PageHeaderTitle } from "components/Common/PageHeader/PageHeader";

type Props = {
  open: boolean;
  onClose: () => any;
  title: string;
  children: any;
};

const CreateModal: React.FC<Props> = ({ open, onClose, title, children }) => {
  return (
    <Modal
      width={600}
      open={open}
      onCancel={onClose}
      title={<PageHeaderTitle title={title} level={2} />}
      footer={false}
      destroyOnClose
      centered
    >
      {children}
    </Modal>
  );
};

export default CreateModal;
