import { gql } from "graphql-tag";

export const CyberPlan = {
  GetCyberPlans: () => gql`
    query allUserCyberPlans($bucket: String!, $fileName: String!) {
      me {
        downloadCyberplans(bucket: $bucket, fileName: $fileName)
      }
    }
  `,
};
