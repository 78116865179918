import { Alert } from "antd";
import GridPage from "./Grid/GridPage";
import ListPage from "./List/ListPage";

export interface PageRendererProps {
  pageType: string;
}

const PageRenderer: React.FC<PageRendererProps> = ({ pageType }) => {
  switch (pageType) {
    case "LIST":
      return <ListPage />;
    case "GRID":
      return <GridPage />;

    default:
      return (
        <Alert
          type="error"
          message="Error"
          description="Something went wrong trying to get this page."
          style={{ marginBottom: 20 }}
        />
      );
  }
};

export default PageRenderer;
