import React from "react";
import { Link } from "react-router-dom";
import DashboardCard, {
  DashboardCardCheckpointStatus,
  DashboardCardStatus,
} from "../../Components/Dashboards/DashboardCard";
import { Divider, Row, Col } from "antd";
import { ApplicationQuestionModuleNode } from "types";

interface Props {
  appId: string | undefined;
  appName: string | undefined;
  mod: ApplicationQuestionModuleNode;
}

const ModuleCard: React.FC<Props> = (props) => {
  const { appId, appName, mod } = props;

  const actions = [
    <Link
      to={`/workflow/${appId}/${mod.id}`}
      state={{
        applicationName: appName,
        moduleName: mod.name,
      }}
    >
      Start
    </Link>,
  ];

  const categoryCount = mod.totalCategories || 0;

  return (
    <DashboardCard title={mod.name} actions={actions} progress={mod.totalProgress!}>
      <Row justify="center">
        <Col span={24} style={{ marginBottom: 10, color: "#555" }}>
          Categories
        </Col>
        <Col>
          <b>{categoryCount}</b>
        </Col>
      </Row>

      <Divider />
      <Col style={{ padding: 0 }}>
        <DashboardCardCheckpointStatus
          isLocked={mod.checkpoint?.questionsLocked!}
          isComplete={mod.checkpoint?.checkpointSessionCompleted!}
          isActive={mod.checkpoint !== null}
        />
        <DashboardCardStatus
          type="Module"
          isLocked={false}
          inProgress={mod.totalProgress! >= 1 && mod.totalProgress! <= 99}
          isComplete={mod.totalProgress! >= 99}
        />
      </Col>
    </DashboardCard>
  );
};

export default ModuleCard;
