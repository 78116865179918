import { useMutation } from "@apollo/client";
import { message } from "antd";
import Api from "api";
import ApplicationForm from "./ApplicationForm";
import { CreateApplicationMutationVariables, CreateApplicationMutation } from "types";

interface Props {
  onClose: () => void;
}

const CreateApplication = ({ onClose }: Props) => {
  const [createApplication, { loading }] = useMutation<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >(Api.Application.Create(), {
    onCompleted: () => {
      message.success("Successfully created an Application", 7);
      onClose();
    },
    onError: (error) => {
      message.error(error.message, 7);
    },
    refetchQueries: [{ query: Api.Application.GetAll() }, { query: Api.Application.GetMy() }],
  });

  const onFinish = (values: any) => {
    console.log(values);
    createApplication({
      variables: {
        name: values.name,
        organizationId: values.organizationId,
        platform: values.platform,
        status: values.status,
        rmfActionPath: values.rmfActionPath,
        authorizer: {
          name: values.authorizerName,
          email: values.authorizerEmail,
          phoneNumber: values.authorizerPhone,
        },
      },
    });
  };

  return <ApplicationForm submit={onFinish} noRules={false} loading={loading} />;
};

export default CreateApplication;
