import { useEffect, useState } from "react";
import hasAnswerChange from "components/Applicant/Helpers/hasAnswerChange";
import useQuestion from "components/Applicant/Hooks/useQuestion";
import ListQuestion from "../ListQuestion";
import { ApplicationQuestionNode } from "types";

export interface MultipleChoiceInputProps {
  defaultAnswer: string | any;
  question: ApplicationQuestionNode;
  onChange: (answer: { id: string }) => void;
  onChangeAdditionalField: (answer: { id: string; text: string }) => void;
  onUnsave: (questionId: string) => void;
  disabled: boolean;
}

interface Props {
  input: React.FC<MultipleChoiceInputProps>;
  defaultAnswer: string | any;
  question: ApplicationQuestionNode;
}

const MultipleChoiceQuestion: React.FC<Props> = ({ input, question, defaultAnswer }) => {
  const [saveStatus, setSaveStatus] = useState<string>("");

  const { saveMultipleChoiceAnswer, saveAdditionalFieldAnswer, unSaveAnswer, unSaveLoading } =
    useQuestion(question, setSaveStatus);

  const Input = input;
  const hasDefaultAnswers = question.isSavedWithDefaults!;
  const hasSavedAnswers = question.isAnswered || question.savedAnswers.length > 0;
  const hasToggledAnswerChoices = question.answerChoices.some(
    (ac) => ac?.additionalFieldToggle,
  );

  const isLocked = question.isLocked! || hasDefaultAnswers;

  useEffect(() => {
    setSaveStatus(
      hasDefaultAnswers
        ? "default"
        : hasSavedAnswers
        ? "saved"
        : hasToggledAnswerChoices
        ? "toggled"
        : "",
    );
  }, [question]);

  const onUnsave = (answerId: string) => {
    unSaveAnswer(question.id, answerId);
  };

  const onChange = (answer: { id: string }) => {
    if (isLocked) return;

    saveMultipleChoiceAnswer(question.id, answer);
  };

  const onChangeAdditionalField = (answer: { id: string; text: string }) => {
    const change = hasAnswerChange(question, answer);
    if (!change) return;
    if (isLocked) return;

    saveAdditionalFieldAnswer(question.id, answer);
  };

  return (
    <ListQuestion
      question={question}
      saveStatus={saveStatus}
      unSave={unSaveAnswer}
      unSaveLoading={unSaveLoading}
    >
      <Input
        question={question}
        defaultAnswer={defaultAnswer}
        onChange={onChange}
        onChangeAdditionalField={onChangeAdditionalField}
        onUnsave={onUnsave}
        disabled={isLocked}
      />
    </ListQuestion>
  );
};

export default MultipleChoiceQuestion;
