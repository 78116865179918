import { P1 } from "./P1";
import { P2 } from "./P2";
import { P3 } from "./P3";
import { P4 } from "./P4";
import "../styles.css";
import EditableField from "components/Deliverables/Components/EditableField";

// prettier-ignore
const pages = [
  P1, P2, P3, P4
];

export default pages;

// ! need to find out if fields on the page are linked to the same questions (repeating variable field ids)

export const FieldWrapper = ({ height, children, styles }) => {
  return (
    <div
      style={{
        width: 200,
        maxWidth: "100%",
        border: "1px solid #000000",
        height: height,
        marginBottom: 12,
        ...styles,
      }}
    >
      {children}
    </div>
  );
};
