import {
  RMFActionPathEnums,
  GroupTypesEnums,
  ModuleTypesEnums,
  QuestionTypesEnums,
  TemplateGroupsEnums,
  UserRolesEnums,
  AnswerConditionEnums,
} from "api/enums";

const useEnumPresentation = () => {
  const PresentRoleName = (role: string) =>
    UserRolesEnums.find((e) => e.value === role)?.label;

  const PresentQuestionType = (type: string) =>
    QuestionTypesEnums.find((e) => e.value === type)?.label;

  const PresentActionPath = (path: string) =>
    RMFActionPathEnums.find((e) => e.value === path)?.label;

  const PresentModuleType = (type: string) =>
    ModuleTypesEnums.find((e) => e.value === type)?.label;

  const PresentGroupType = (group: string) =>
    GroupTypesEnums.find((e) => e.value === group)?.label;

  const PresentTemplateGroupType = (group: string) =>
    TemplateGroupsEnums.find((e) => e.value === group)?.label;

  const PresentAnswerConditionType = (type: string) =>
    AnswerConditionEnums.find((e) => e.value === type)?.label;

  return {
    PresentRoleName,
    PresentQuestionType,
    PresentAnswerConditionType,
    PresentActionPath,
    PresentModuleType,
    PresentGroupType,
    PresentTemplateGroupType,
  };
};

export default useEnumPresentation;
