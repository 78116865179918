import { gql } from "graphql-tag";

export const User = {
  Login: () => gql`
    mutation login($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        accessToken
        refreshToken
      }
    }
  `,
  LoginWithCac: () => gql`
    mutation loginWithCac($token: String!) {
      loginWithCac(token: $token) {
        token
      }
    }
  `,
  Logout: () => gql`
    mutation logout {
      logout {
        success
      }
    }
  `,
  ChangePassword: () => gql`
    mutation setUserPassword(
      $confirmPassword: String!
      $email: String!
      $password: String!
      $resetToken: String!
    ) {
      setUserPassword(
        confirmPassword: $confirmPassword
        email: $email
        password: $password
        resetToken: $resetToken
      ) {
        response
      }
    }
  `,
  SendPasswordChangeEmail: () => gql`
    mutation resetPassword($email: String!) {
      resetPassword(email: $email) {
        response
      }
    }
  `,
  GetAuth: () => gql`
    query authUser {
      me {
        id
        firstName
        lastName
        fullname
        salutation
        email
        role
        permissions {
          permission
        }
        cac {
          edipi
          subjectCommonName
          cacEmail
          serialNum
        }
      }
    }
  `,
  GetAll: () => gql`
    query allUsers {
      allUsers {
        id
        firstName
        lastName
        fullname
        salutation
        email
        role
        locked
        permissions {
          permission
        }
      }
    }
  `,
  GetUserInfo: () => gql`
    query user($id: String!) {
      user(id: $id) {
        firstName
        lastName
        fullname
        id
        organizations {
          id
          name
        }
        permissions {
          id
          permission
        }
        role
        salutation
        email
      }
    }
  `,
  GetUserCac: () => gql`
    query authUserCac {
      me {
        cac {
          edipi
          subjectCommonName
          cacEmail
          serialNum
        }
      }
    }
  `,
  AssociateCac: () => gql`
    mutation associateCacWithUser($token: String!) {
      associateCacWithUser(token: $token) {
        associated
      }
    }
  `,
  DisAssociateCac: () => gql`
    mutation disassociateCac {
      disassociateCac {
        disassociated
      }
    }
  `,
  Create: () => gql`
    mutation createUser(
      $role: UserRole
      $organizationId: String
      $applicationId: String
      $email: String!
      $firstName: String!
      $lastName: String
      $password: String!
      $phoneNumber: String
      $salutation: String
    ) {
      createUser(
        role: $role
        applicationId: $applicationId
        organizationId: $organizationId
        email: $email
        firstName: $firstName
        lastName: $lastName
        password: $password
        phoneNumber: $phoneNumber
        salutation: $salutation
      ) {
        id
        firstName
        lastName
        salutation
        email
        role
      }
    }
  `,
  UpdateInfo: () => gql`
    mutation updateUser(
      $id: String!
      $email: String
      $firstName: String
      $lastName: String
      $phoneNumber: String
      $salutation: String
    ) {
      updateUser(
        id: $id
        email: $email
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        salutation: $salutation
      ) {
        id
        firstName
        lastName
        salutation
        email
        role
      }
    }
  `,
  Delete: () => gql`
    mutation deleteUser($id: String!) {
      deleteUser(id: $id) {
        deletedId
      }
    }
  `,
};
