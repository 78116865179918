import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

import P4D1 from "../Assets/P4D1.png";

export const P4 = (props) => {
  const { document, state } = props;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const SAMarking = {
    label: "Select Marking",
    value: "system_architecture_marking",
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              backgroundColor: "lightgrey",
              borderTop: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>6. System Architecture</b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={SAMarking} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.34,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="system_architecture_on_premise_deployed"
                    // fieldId={document.variableFields[100]?.id}
                  />
                </div>
                <div>
                  <div>
                    <i>On-Premises Deployed</i> – This system is deployed completely on
                    premises.
                  </div>
                  <i>NOTE 6: If checked, skip to Section 8</i>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.33,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="system_architecture_hybrid_deployed"
                    // fieldId={document.variableFields[101]?.id}
                  />
                </div>
                <div>
                  <i>Hybrid Deployed</i> – This system is deployed on premises and in the
                  cloud.
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.33,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="system_architecture_cloud_deployed"
                    // fieldId={document.variableFields[102]?.id}
                  />
                </div>
                <div>
                  <i>Cloud Deployed</i> – This system is deployed exclusively in the cloud.
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              backgroundColor: "lightgrey",
              borderTop: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>
                6A. Type of Service utilized (Choose One) **<i>Cloud Systems Only</i>**
              </b>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.34,
                borderRight: "1px solid black",
                padding: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="type_of_service_infrastructure_as_service"
                    // fieldId={document.variableFields[103]?.id}
                  />
                </div>
                <div>
                  <i>Infrastructure as a Service (IaaS)</i>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.33,
                borderRight: "1px solid black",
                padding: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="type_of_service_platform_as_service"
                    // fieldId={document.variableFields[104]?.id}
                  />
                </div>
                <div>
                  <i>Platform as a Service (PaaS)</i>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.33,
                borderRight: "none",
                padding: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="type_of_service_software_as_service"
                    // fieldId={document.variableFields[105]?.id}
                  />
                </div>
                <div>
                  <i>Software as a Service (SaaS)</i>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0",
              }}
            >
              <img style={{ maxWidth: "100%" }} src={P4D1} alt="page 4 diagram 1" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
