import { gql } from "graphql-tag";

export const ApplicationModule = {
  GetOne: () => gql`
    query applicationModule($id: String!) {
      applicationModule(id: $id) {
        id
        updatedOn
        name
        type
        isLocked
        isComplete
        totalProgress
        totalRisk
        timeDelta
        appTime
        checkpoint {
          triggered
          questionsLocked
          checkpointSessionCompleted
          isWorkflowCheckpoint
        }
        categories {
          id
          name
          isLocked
          isComplete
          numQuestions
          numAnsweredQuestions
          checkpoint {
            triggered
            questionsLocked
            checkpointSessionCompleted
            isWorkflowCheckpoint
          }
          allQuestions {
            id
            savedAnswers {
              id
              answerText
              answer
              additionalFieldType
              answerType
              riskIncrease
              timeIncreaseInDays
            }
          }
        }
      }
    }
  `,
};
