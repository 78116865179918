import { useEffect } from "react";

const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
    return () => {
      document.title = "Cybersecurity Assurance Readiness";
    };
  }, []);
};

export default useDocumentTitle;
