import { Col, Divider, Row } from "antd";
import { HorizontalLabel, VerticalLabel } from "../Shared/LabelWrapper";
import QuoteLabel from "../Shared/QuoteLabel";
import TextLabel from "../Shared/TextLabel";

const QuestionInformation = ({ question }) => {
  console.log({ QuestionInformation: question });

  const moduleLabel = question?.category?.module?.id
    ? question.category.module.name
    : "Unassigned";
  const categoryLabel = question?.category?.id ? question.category.name : "Unassigned";

  if (!question) return <></>;

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <HorizontalLabel>
          <TextLabel label="Module:" strong={true} />
          <TextLabel label={moduleLabel} />
        </HorizontalLabel>
      </Col>
      <Col span={24}>
        <HorizontalLabel>
          <TextLabel label="Category:" strong={true} />
          <TextLabel label={categoryLabel} />
        </HorizontalLabel>
      </Col>

      <Divider style={{ margin: "6px 12px" }} />
      <Col span={24}>
        <VerticalLabel>
          <TextLabel label="Question Text:" strong={true} />
          <QuoteLabel>{question.question}</QuoteLabel>
        </VerticalLabel>
      </Col>
      <Divider style={{ margin: "6px 12px" }} />
      <Col span={24}>
        <VerticalLabel>
          <TextLabel label="Help Text:" strong={true} />
          <QuoteLabel>{question?.helpText ? question.helpText : "Not Set"}</QuoteLabel>
        </VerticalLabel>
      </Col>
    </Row>
  );
};

export default QuestionInformation;
