import { useState } from "react";
import { ApolloError } from "@apollo/client";
import { message } from "antd";
import { CategoryEditorState, CategoryFieldKeysEnum as FieldKeysEnum } from "./reducer";
import { QuestionCategoryNode } from "types";
import ContentCard from "../../Shared/ContentCard";
import { SaveStatusEnum } from "../../Shared/types";
import useEditorLoading from "hooks/useEditorLoading";

interface Props {
  state: CategoryEditorState;
  category: QuestionCategoryNode;
  loading: Loading;
  setEditState: any;
  setSaveState: any;
  updateCategory: any;
}

const CategoryInformation: React.FC<Props> = ({
  state,
  category,
  loading,
  setEditState,
  setSaveState,
  updateCategory,
}) => {
  const [formValues, setFormValues] = useState({
    categoryName: undefined,
    categoryOrder: null,
  });

  console.log({ categoryInformation: category, formValues, state });

  const handleValuesChange = (value: string, fieldKey: string) => {
    console.log("%chandleCategoryInfoValuesChange", "color:blue");
    console.log(`Field: ${fieldKey} | value: ${value}`);
    setFormValues({
      ...formValues,
      [fieldKey]: value,
    });
  };

  const updateCategoryInfo = (fieldKey: FieldKeysEnum) => {
    setSaveState(fieldKey, SaveStatusEnum.Loading);
    updateCategory({
      variables: {
        id: category.id,
        order: formValues.categoryOrder,
        name: formValues.categoryName,
      },
      onCompleted: (res: any) => {
        console.log(res);
        setSaveState(fieldKey, SaveStatusEnum.Success);
      },
      onError: (error: ApolloError) => {
        console.log(error.message);
        setSaveState(fieldKey, SaveStatusEnum.Error);
        message.error(error.message, 5);
      },
    });
  };

  const nameLoading = useEditorLoading(FieldKeysEnum.categoryName, state, loading);

  return (
    <>
      <ContentCard
        title="Category Name"
        fieldKey={FieldKeysEnum.categoryName}
        loading={loading || nameLoading}
        setEditState={setEditState}
        editableField={true}
        onValuesChange={handleValuesChange}
        formValues={formValues}
        onSave={() => updateCategoryInfo(FieldKeysEnum.categoryName)}
        editingCurrentField={state[FieldKeysEnum.categoryName].editActive}
        saveStatus={state[FieldKeysEnum.categoryName].saveStatus}
        renderSaveStatus={
          state[FieldKeysEnum.categoryName].saveStatus !== SaveStatusEnum.Default
        }
      >
        {category?.name}
      </ContentCard>
    </>
  );
};

export default CategoryInformation;
