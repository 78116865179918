import { useMutation } from "@apollo/client";
import { Input, Button, Row, message, Form, Select, InputNumber } from "antd";
import Api from "api";

const CreateModule = ({ closeModal }: { closeModal: any }) => {
  const [form] = Form.useForm();
  const [createModule, { loading }] = useMutation(Api.QuestionModule.Create(), {
    onCompleted: (data) => {
      console.log(data);
      message.success("Module created successfully!", 7);
      closeModal();
    },
    onError: (error) => {
      console.log(error);
      message.error(error.message, 7);
    },
    refetchQueries: [{ query: Api.QuestionModule.WorkflowEditor() }],
  });

  const onFinish = (values: any): void => {
    console.log(values);
    createModule({
      variables: {
        ...values,
        type: "QUESTION_AND_ANSWER",
      },
    });
  };

  const moduleTypeEnums = [
    { label: "Question & Answer", value: "QUESTION_AND_ANSWER" },
    // { label: "Control Module", value: "CONTROL" },
    // { label: "P.O.A.M.", value: "POAM" },
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ type: "QUESTION_AND_ANSWER" }}
    >
      <Form.Item
        label="Module Name"
        name="name"
        rules={[{ required: true, message: "Module Name is required!" }]}
      >
        <Input.TextArea autoSize={true} />
      </Form.Item>
      {/* <Form.Item label="Module Type" name="type">
        <Select options={moduleTypeEnums} />
      </Form.Item> */}
      <Form.Item label="Help Text" name="helpText">
        <Input.TextArea autoSize={true} />
      </Form.Item>
      <Form.Item label="Order" name="order">
        <InputNumber min={0} />
      </Form.Item>
      <Row justify="end" style={{ height: 24 }}>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default CreateModule;
