import React from "react";
import { Button, Col, Row, Space } from "antd";
import useViewport from "hooks/useViewport";
import MarkdownPreviewer from "components/Common/Markdown/MarkdownPreview";
import ResizableDrawer from "../ResizableDrawer";

interface Props {
  text: any;
  open: boolean;
  onClose: any;
}

const HelpDrawer: React.FC<Props> = ({ open, onClose, text }) => {
  const viewport = useViewport();

  return (
    <ResizableDrawer
      title="Get Help"
      open={open}
      onClose={onClose}
      width={viewport.drawer.width.small}
      canResize={viewport.drawer.resizable}
      // footer={<Footer />}
    >
      <MarkdownPreviewer source={text} />
    </ResizableDrawer>
  );
};

export default HelpDrawer;

const Footer = () => (
  <Row style={{ width: "100%" }} justify="space-between">
    <Space>
      <Col>Did you find this helpful?</Col>
    </Space>
    <Space>
      <Col>
        <Button style={{ marginRight: 8 }} type="primary">
          Yes
        </Button>
        <Button>No</Button>
      </Col>
    </Space>
  </Row>
);
