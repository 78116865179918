import { Space, Tag } from "antd";

interface Props {
  time: string | any;
  risk: string | any;
}

const MetricsStatusTags: React.FC<Props> = ({ time = 0, risk = 0 }) => {
  const getMetricColor = (metric: number) =>
    metric > 0 ? "red" : metric < 0 ? "green" : "orange";

  const timeColor = getMetricColor(time);
  const riskColor = getMetricColor(risk);

  return (
    <Space>
      <Tag color={timeColor} style={style}>
        <>Timeline {time}</>
      </Tag>
      <Tag color={riskColor} style={style}>
        <>Risk {risk}</>
      </Tag>
    </Space>
  );
};

export default MetricsStatusTags;

const style = {
  marginRight: 0,
};
