import { useReducer } from "react";
import ManageWorkflows from "../WorkflowEditor";
import { workflowInitialState, workflowStateReducer } from "./reducer";

const WorkflowEditorPage: React.FC = () => {
  const [state, dispatch] = useReducer(workflowStateReducer, workflowInitialState);

  return <ManageWorkflows state={state} dispatch={dispatch} />;
};

export default WorkflowEditorPage;
