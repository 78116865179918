import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

type Props = {
  title: string | any;
  transparent?: boolean;
  iconFont?: number;
  style?: any;
};

const InfoTip: React.FC<Props> = (props) => {
  const { transparent = false, iconFont, style, ...rest } = props;

  return (
    <Tooltip {...props} {...rest} style={style} color={transparent ? "transparent" : "black"}>
      <InfoCircleOutlined style={{ fontSize: iconFont }} />
    </Tooltip>
  );
};

export default InfoTip;
