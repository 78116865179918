import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";
import { Button } from "antd";

export const P1 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const P1WarningMarking = {
    label: "SELECT CLASSIFICATION",
    value: "p1_warning_marking",
  };

  return (
    <>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div style={{ textAlign: "center", fontSize: 22, padding: "20px 0" }}>
          <b>
            <div>Department of the Air Force (DAF)</div>
            <div>Risk Management Framework (RMF) Information Technology (IT)</div>
            <div>Categorization and Selection Checklist (ITCSC)</div>
          </b>
        </div>
        <div >
          <div style={{ display: "grid", placeItems: "center" }}>
            <div
              style={{
                width: "80%",
                textAlign: "center",
                border: "1px solid black",
                padding: 5,
              }}
            >
              <b style={{ fontSize: 14 }}>
                Controlled Unclassified Information (CUI) Designation Indicator <i>Refs: (a) & (b)</i>
              </b>
              <div style={{ display: "flex" }}>
                <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Controlled by (Office): </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <EditableField
                    {...elementProps}
                    fieldName="collected_by_office"
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>CUI Category: </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <EditableField
                    {...elementProps}
                    fieldName="cui_category_ref"
                  // fieldId={document.variableFields[6].id}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>
                  Distribution/Dissemination Control:
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <EditableField
                    {...elementProps}
                    fieldName="distribution_dissemination_control"
                  // fieldId={document.variableFields[7].id}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>POC:</div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <EditableField
                    {...elementProps}
                    fieldName="poc"
                  // fieldId={document.variableFields[8].id}
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div style={{ display: "grid", placeItems: "center" }}>
            <div
              style={{
                width: "80%",
                textAlign: "center",
                border: "1px solid black",
                padding: 5,
              }}
            >
              <b style={{ fontSize: 14 }}>CUI Warning Box for Classified Material <i>Refs: (b) & (c)</i></b>
              <div>
                <div style={{ textAlign: "left" }}>
                  <p>
                    The DAF ITCSC is classified at the 
                    <ClassificationSelect
                      elementProps={elementProps}
                      config={P1WarningMarking}
                      style={{ width: "165px", margin: "0 4px" }}
                    />
                    level and may contain<br />
                    elements of CUI, unclassified, or information classified at a lower level than the overall classification<br />
                    displayed. This content shall not be used as a source of derivative classification; refer instead to [cite<br />
                    specific reference, where possible, or state “the applicable classification guide(s)”]. It must be reviewed<br />
                    for both Classified National Security Information and CUI in accordance with (IAW) Department of<br />
                    Defense (DOD) Instruction (DODI) 5230.09 prior to public release.<br />
                  </p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Contact: </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <EditableField
                    {...elementProps}
                    fieldName="contact"
                  />{" "}
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div style={{ display: "grid", placeItems: "center" }}>
            <div
              style={{
                width: "80%",
                textAlign: "center",
                border: "1px solid black",
                padding: 5,
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Classified By: </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <EditableField
                    {...elementProps}
                    fieldName="classified_by"
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Derived From: </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <EditableField
                    {...elementProps}
                    fieldName="derived_from"
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Declassify On: </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <EditableField
                    {...elementProps}
                    isDate
                    fieldName="declassified_on"
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "grid", placeItems: "center" }}>
            <div
              style={{
                width: "80%",
                textAlign: "end",
                padding: 5,
              }}
            >
              <Button size="small" style={{ outline: '4px solid black', borderRadius: 0 }}>Reset Form</Button>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
