import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const MotarApplicationPrompt = () => {
  const navigate = useNavigate();

  useEffect(() => {
    confirm({
      title: "Attention!",
      icon: <ExclamationCircleOutlined />,
      content: "Would you like to import MOTAR Applications?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        navigate("/motar/apps/import");
      },
      onCancel() {
        navigate("/home");
      },
    });
  }, []);

  return <></>;
};

export default MotarApplicationPrompt;
