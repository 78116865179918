export const getFieldsMap = (fields, callback = null) => {
    if (!fields?.length) return {}
    const fieldsMap = fields.reduce((acc, curr) => {
        const { fieldName, pageNumber } = curr
        if (acc[pageNumber] === undefined) acc[pageNumber] = {}
        if (callback !== null) {
            acc[pageNumber][fieldName] = callback(curr, acc)
            return acc
        }
        acc[pageNumber][fieldName] = curr
        return acc
    }, {})
    return fieldsMap
}

export function getFieldFromMapping({ state, pageNumber, fieldName }) {
    const fields = state.document?.variableFieldsMap
    if (fields === undefined) return
    const page = fields?.[pageNumber]
    if(Array.isArray(fieldName)) {
        const obj = {}
        fieldName.forEach(p => {
            obj[p] = page?.[p]
        })
        return obj
    }
    return page?.[fieldName]
}

export function getAnswersFromMapping({ state, pageNumber, fieldName, editable }) {
    if (editable) return
    const fields = state?.fieldAnswersMap
    if (fields === undefined) return
    const page = fields?.[pageNumber]
    if(Array.isArray(fieldName)) {
        const ans = fieldName.map(p => page?.[p]).find(p => p?.applicationAnswer?.isSaved === true)
        return ans
    }
    return page?.[fieldName]
}

