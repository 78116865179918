import { useState } from "react";

const useFormChanges = (original: any) => {
  const [formChanges, setFormChanges] = useState<any>({});

  const onChange = (prop: string, value: any) => {
    if (prop === "pocPhone" && (value.length < 10 || value.length > 10)) return;

    if (original[prop] === value) {
      const changes: any = { ...formChanges };
      delete changes[prop];
      return setFormChanges(changes);
    }

    setFormChanges({
      ...formChanges,
      [prop]: value,
    });
  };

  const hasFormChanges = Object.keys(formChanges).length > 0 ? true : false;

  return { formChanges, hasFormChanges, onChange };
};

export default useFormChanges;
