import { ApolloError, useQuery } from "@apollo/client";
import { Col, message, Row, Tag, Typography } from "antd";
import { useState } from "react";
import { QuestionCategoryNode } from "types";
import SidebarSection, { SidebarManageButton } from "../../Shared/SidebarSection";
import { CategoryEditorState, CategoryFieldKeysEnum as FieldKeysEnum } from "./reducer";
import Api from "api";
import ParentOrderingField from "../../Shared/ParentOrderingField";
import ParentSelectField from "../../Shared/ParentSelectField";
import { SaveStatusEnum } from "../../Shared/types";
import useEditorLoading from "hooks/useEditorLoading";

type ParentInfoProps = {
  state: CategoryEditorState;
  loading: Loading;
  category: QuestionCategoryNode;
  updateCategory: any;
  setEditState: any;
  setSaveState: any;
};

type ParentOrderProps = {
  category: QuestionCategoryNode;
  updateCategory: any;
  state: any;
  loading: Loading;
  setEditState: any;
  setSaveState: any;
};

type SelectFieldProps = {
  state: CategoryEditorState;
  category: QuestionCategoryNode;
  updateCategory: any;
  setEditState: any;
  setSaveState: any;
  loading: Loading;
};

const ParentInformation: React.FC<ParentInfoProps> = ({
  state,
  loading,
  category,
  updateCategory,
  setEditState,
  setSaveState,
}) => {
  const hasParentModule = category?.module?.id ? true : false;
  const parentAssignButtonTooltip: string = hasParentModule
    ? "Change parent Module"
    : "Assign to Module";

  const sharedProps = {
    state,
    loading,
  };

  const parentModuleLoading = useEditorLoading(FieldKeysEnum.parentModule, state, loading);
  const parentOrderLoading = useEditorLoading(FieldKeysEnum.categoryOrder, state, loading);

  return (
    <>
      <SidebarSection
        title="Parent Module"
        loading={loading || parentModuleLoading}
        valueLoading={parentModuleLoading}
        renderSaveStatus={
          state[FieldKeysEnum.parentModule].saveStatus !== SaveStatusEnum.Default
        }
        saveStatus={state[FieldKeysEnum.parentModule].saveStatus}
        actionMenu={
          <SidebarManageButton
            tooltip={parentAssignButtonTooltip}
            fieldKey={FieldKeysEnum.parentModule}
            loading={parentModuleLoading}
            setEditState={setEditState}
            editingCurrentField={state[FieldKeysEnum.parentModule].editActive}
          />
        }
      >
        <ModuleSelectField
          category={category}
          updateCategory={updateCategory}
          setEditState={setEditState}
          setSaveState={setSaveState}
          {...sharedProps}
        />
      </SidebarSection>
      <SidebarSection
        title="Order"
        tooltip="The order this Category appears within it's parent Module"
        loading={loading || parentOrderLoading}
        valueLoading={parentOrderLoading}
        renderSaveStatus={
          state[FieldKeysEnum.categoryOrder].saveStatus !== SaveStatusEnum.Default
        }
        saveStatus={state[FieldKeysEnum.categoryOrder].saveStatus}
        actionMenu={
          <SidebarManageButton
            fieldKey={FieldKeysEnum.categoryOrder}
            setEditState={setEditState}
            loading={parentOrderLoading}
            editingCurrentField={state[FieldKeysEnum.categoryOrder].editActive}
          />
        }
      >
        <ParentOrderField
          category={category}
          updateCategory={updateCategory}
          setEditState={setEditState}
          setSaveState={setSaveState}
          {...sharedProps}
        />
      </SidebarSection>
    </>
  );
};

const ModuleSelectField: React.FC<SelectFieldProps> = ({
  state,
  category,
  updateCategory,
  setEditState,
  setSaveState,
  loading,
}) => {
  const editingCurrentField: boolean = state[FieldKeysEnum.parentModule].editActive;

  const [newModule, setNewModule] = useState<string | undefined>(undefined);
  const { data: allModules, loading: modulesLoading } = useQuery(
    Api.QuestionModule.GetBasicList(),
    {
      onCompleted: (res) => {
        console.log(res);
      },
      onError: (error) => {
        message.error(error.message, 5);
        console.log(error.message);
      },
    },
  );

  const changeModule = () => {
    setEditState(FieldKeysEnum.parentModule);
    setSaveState(FieldKeysEnum.parentModule, SaveStatusEnum.Loading);
    updateCategory({
      variables: {
        id: category.id,
        moduleId: newModule,
      },
      onCompleted: (res: any) => {
        console.log({ changedModuleRes: res });
        setSaveState(FieldKeysEnum.parentModule, SaveStatusEnum.Success);
      },
      onError: (error: ApolloError) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.parentModule, SaveStatusEnum.Error);
      },
    });
  };

  if (editingCurrentField) {
    return (
      <ParentSelectField
        options={allModules?.allQuestionModules.map((module: any) => ({
          label: module?.name,
          value: module?.id,
        }))}
        value={newModule}
        loading={loading || modulesLoading}
        onChange={setNewModule}
        onSave={changeModule}
      />
    );
  }
  return <ParentNameField parentName={category?.module?.name} />;
};

const ParentNameField = ({ parentName }: { parentName: string | undefined }) => {
  if (!parentName) return <Typography.Text>Not Assigned</Typography.Text>;
  return <Tag key={parentName}>{parentName}</Tag>;
};

const ParentOrderField: React.FC<ParentOrderProps> = ({
  category,
  updateCategory,
  state,
  setEditState,
  setSaveState,
}) => {
  const [orderVal, setOrderVal] = useState<number | undefined>(undefined);
  const editingCurrentField: boolean = state[FieldKeysEnum.categoryOrder].editActive;

  const handleOrderChange = (e: any) => {
    setOrderVal(Number(e.target.value));
  };

  const saveParentOrder = () => {
    setSaveState(FieldKeysEnum.categoryOrder, SaveStatusEnum.Loading);
    setEditState(FieldKeysEnum.categoryOrder);
    updateCategory({
      variables: {
        id: category.id,
        order: orderVal,
      },
      onCompleted: (res: QuestionCategoryNode) => {
        console.log({ updatedParentORder: res });
        setSaveState(FieldKeysEnum.categoryOrder, SaveStatusEnum.Success);
      },
      onError: (error: ApolloError) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.categoryOrder, SaveStatusEnum.Error);
      },
    });
  };

  if (editingCurrentField) {
    return (
      <Row align="top" justify="space-between">
        <Col span={20}>
          <ParentOrderingField
            defaultValue={category.order ? category.order : undefined}
            value={orderVal}
            onChange={(e: any) => handleOrderChange(e)}
            saveValue={saveParentOrder}
            styles={{ width: 85, height: "32.25px !important" }}
          />
        </Col>
      </Row>
    );
  }
  return <Typography.Text>{category?.order}</Typography.Text>;
};

export default ParentInformation;
