import React from "react";
import DocumentField, { IDocumentFieldProps } from "./DocumentField";
import { Table, Tooltip } from "antd";
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

interface EditableSelectOption {
  label: string;
  value: any;
}

interface Props extends IDocumentFieldProps {
  label: string;
  fieldName: string;
  options: EditableSelectOption[];
  style?: React.CSSProperties;
}

const EditableSelect: React.FC<Props> = (props) => {
  const { options, editable, style, label = "Select One", fieldName: name } = props;
  const fieldNames = options.map((p) => p.value);

  return (
    <DocumentField
      {...props}
      fieldType="MULTIPLE_CHOICE_PICK_ONE"
      fieldName={fieldNames}
      getStyleId={({ state, field: fields, fieldType }) => {
        const stateFieldID = state?.fieldId;
        const fieldList: any[] = Object.values(fields || {});
        if (fieldList.some((p) => p === undefined)) return "missingAssignment";
        if (stateFieldID && fieldList.some((p) => p?.id === stateFieldID))
          return "selectedAssignment";
        if (fieldList.some((p) => p?.fieldType !== fieldType)) return "mismatchTypes";
        if (fieldList.every((p) => p?.answer !== null)) return "savedAssignment";
        return "unsavedAssignment";
      }}
    >
      {({ handleChange, styleId, answer, field: fields }) => {
        const selected = options.find((p) => p?.value === answer?.field?.fieldName);
        if (!editable) {
          return (
            <select
              disabled
              className="documentSelect"
              style={{ ...props.style, outline: "none !important" }}
              id={editable ? styleId : ""}
            >
              <option>{(!editable && selected?.label) || ""}</option>
            </select>
          );
        }
        var opts = options;
        if (label && name) {
          opts = [{ label, value: name }, ...options];
        }

        return (
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <Table
                  rowClassName="select-table-row"
                  size="small"
                  pagination={false}
                  showHeader={false}
                  columns={[
                    {
                      dataIndex: "label",
                    },
                    {
                      dataIndex: "value",
                      render: (data) => {
                        const field = fields?.[data];
                        if (field === undefined)
                          return <ExclamationCircleOutlined style={{ color: "red" }} />;
                        if (field?.answer !== null && field?.answer !== undefined)
                          return <CheckOutlined style={{ color: "green" }} />;
                        return <CloseOutlined style={{ color: "red" }} />;
                      },
                    },
                  ]}
                  dataSource={options}
                />
              );
            }}
          >
            <select
              onChange={handleChange}
              className="documentSelect"
              style={{ ...style, outline: "none !important" }}
              id={editable ? styleId : ""}
            >
              {opts.map((o, i) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>
          </Tooltip>
        );
      }}
    </DocumentField>
  );
};

export default EditableSelect;
