import { useState } from "react";
import { Button, Col, Dropdown, Popconfirm, Row, Select, Space } from "antd";
import Icon, {
  CloseOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { mapTagToType } from "components/Common/Tags/QuestionType";
import { EditableColumn, DataRow, GridQuestionTypes as QuestionTypes } from "../Shared/types";
import EditableHeadCell from "./EditableHeadCell";
import { QuestionType } from "types";
import { QuestionTypesEnums } from "api/enums";

interface Props {
  column: EditableColumn;
  index: number;
  labelValue: string;
  questionType?: QuestionTypes;
  columnState: EditableColumn[];
  setColumnState: (newState: EditableColumn[]) => void;
  dataSource: any;
  setDataSource: (newState: DataRow[]) => void;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  setEditingColumn: any;
}

const ColumnHeader: React.FC<Props> = (props) => {
  const {
    column,
    index,
    labelValue,
    questionType,
    columnState,
    setColumnState,
    dataSource,
    setDataSource,
    setModalOpen,
    setEditingColumn,
  } = props;
  const [editingLabel, setEditingLabel] = useState(false);
  const [editingType, setEditingType] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const dataIndex = column.dataIndex;
  const canDeleteColumn = columnState.length > 2;

  const columnHasAnswers = column.answers && column.answers.length > 0;
  const columnHasType = column.questionType !== undefined;

  // console.log("%cColumnHeaderProps", "color:blue", {
  //   column,
  //   columnHasAnswers,
  //   index,
  //   labelValue,
  //   questionType,
  //   columnState,
  //   modalOpen,
  // });

  // * actions: Add label(required), add dataType, deleteColumn

  const removeType = () => {
    const currentColumns = [...columnState];

    const newColumns = currentColumns.map((col) => {
      if (col.dataIndex === dataIndex) {
        return {
          ...col,
          questionType: undefined,
        };
      }
      return col;
    });

    if (index === 0) {
      const currentDataSource = [...dataSource];
      const newDataSource = currentDataSource.map((row) => {
        return {
          ...row,
          ignore: false,
        };
      });
      setDataSource(newDataSource);
    }

    setColumnState(newColumns);
  };

  const items = [
    {
      label: "Edit Label",
      key: "editLabel",
      icon: <EditOutlined />,
    },
    {
      label: "Set Type",
      key: "setType",
      icon: <TypeIcon />,
    },
    columnHasType && index === 0
      ? {
          label: "Remove Type",
          key: "removeType",
          icon: <DeleteOutlined />,
        }
      : null,
    columnHasAnswers
      ? {
          label: "Edit Answers",
          key: "editAnswers",
          icon: <UnorderedListOutlined />,
        }
      : null,
    {
      label: "Remove Column",
      key: "removeColumn",
      icon: <DeleteOutlined />,
      disabled: !canDeleteColumn,
      danger: true,
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "editLabel":
        console.log("edit label");
        setEditingLabel(true);
        break;
      case "setType":
        console.log("set type");
        setEditingType(true);
        setEditingColumn(column);
        break;
      case "editAnswers":
        console.log("edit answers");
        setModalOpen(true);
        setEditingColumn(column);
        break;
      case "removeColumn":
        setConfirmOpen(true);
        break;
      case "removeType":
        console.log("remove Type");
        removeType();
        break;
      default:
        break;
    }
  };

  const handleColumnDelete = () => {
    console.log("%chandleColumnDelete: ", "color:blue", { dataIndex });
    const currentColumns = [...columnState];
    console.log({ currentColumns });

    const newColumns = currentColumns.filter((column) => column.dataIndex !== dataIndex);
    console.log({ columnsAfterFilter: newColumns });
    setColumnState(newColumns);
  };

  if (editingLabel) {
    return (
      <Row>
        <Col flex="90">
          <EditableHeadCell
            title={column.labelValue}
            dataIndex={column.dataIndex}
            setColumnState={setColumnState}
            editable={true}
            columns={columnState}
            setEditingLabel={setEditingLabel}
          />
        </Col>
        <Col flex="5">
          <Button icon={<CloseOutlined />} onClick={() => setEditingLabel(false)} />
        </Col>
      </Row>
    );
  }

  if (editingType) {
    return (
      <Row justify="space-between">
        <Col span={24} style={{ marginBottom: 12 }}>
          {labelValue}
        </Col>
        <Col flex="95">
          <Space.Compact>
            <QuestionTypeSelect
              current={column}
              currentIndex={index}
              columnState={columnState}
              setColumnState={setColumnState}
              dataSource={dataSource}
              setDataSource={setDataSource}
              setModalOpen={setModalOpen}
              setEditingType={setEditingType}
            />
            <Button
              onClick={() => {
                setEditingType(false);
                setEditingColumn(null);
              }}
              icon={<CloseOutlined />}
            />
          </Space.Compact>
        </Col>
      </Row>
    );
  }

  return (
    <Row justify="space-between">
      <Col flex="95" style={{ marginBottom: 12 }}>
        {labelValue}
      </Col>
      <Col flex="5">
        <Popconfirm
          open={confirmOpen}
          title="Confirm column delete?"
          onCancel={() => setConfirmOpen(false)}
          onConfirm={handleColumnDelete}
          okText="Confirm"
          icon={popconfirmIcon}
        >
          <Dropdown menu={{ items, onClick }}>
            <Button
              icon={<SettingOutlined />}
              size="small"
              onClick={() => {
                console.log("Clicked Settings", { index, column });
              }}
            />
          </Dropdown>
        </Popconfirm>
      </Col>
      <Col span={24}>{questionType && mapTagToType(questionType)}</Col>
    </Row>
  );
};

const QuestionTypeSelect = (props) => {
  const {
    current,
    currentIndex,
    columnState,
    setColumnState,
    dataIndex,
    value,
    modalOpen,
    dataSource,
    setDataSource,
    setModalOpen,
    setEditingType,
  } = props;

  // console.log("%cQuestionTypeSelect:CellDropdownProps: ", "color:cyan", {
  //   columnState,
  //   setColumnState,
  //   dataIndex,
  //   value,
  //   modalOpen,
  //   setModalOpen,
  // });

  const options = QuestionTypesEnums.filter((type) => {
    return (
      type.value !== QuestionType.MultipleChoicePickMany &&
      type.value !== QuestionType.UploadFile
    );
  });

  function pushColumnUpdates(updatedColumn: any) {
    const updatedColumns = columnState.map((obj) => {
      if (obj === current) {
        return updatedColumn;
      }
      return obj;
    });
    setColumnState(updatedColumns);
  }

  const handleSelect = (value: QuestionType) => {
    // console.log("%cQuestionTypeSelect:handleSelect", "color:purple", { value });
    console.log("HandleSelect", { current, value });

    const updatedColumn = {
      ...current,
      questionType: value,
    };

    if (value === QuestionType.MultipleChoicePickOne) {
      setModalOpen(true);
    } else {
      if (updatedColumn?.answers !== undefined) {
        delete updatedColumn.answers;
      }
      pushColumnUpdates(updatedColumn);
    }

    if (currentIndex === 0) {
      const oldDataSource = [...dataSource];
      const newDataSource = oldDataSource.map((row) => {
        return {
          ...row,
          ignore: true,
        };
      });
      setDataSource(newDataSource);
    }

    // if (columnIndex === 0) {
    //   if (currentItem.questionType === undefined) {
    //     const oldDataSource = [...dataSource];
    // const newDataSource = oldDataSource.map((row) => {
    //   return {
    //     ...row,
    //     ignore: true,
    //   };
    // });
    //     console.log("%cQuestionTypeSelect:Update Row ignore", "color:yellow", {
    //       oldDataSource,
    //       newDataSource,
    //     });
    //     setDataSource(newDataSource);
    //   }
    // }

    setEditingType(false);
  };

  return (
    <Row justify="start">
      <Select
        onChange={handleSelect}
        options={options}
        dropdownMatchSelectWidth={false}
        placeholder="Select a Type"
        style={{ minWidth: 150, width: "100%", maxWidth: 225 }}
        autoFocus={true}
      />
    </Row>
  );
};

const TypeIconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z" />
  </svg>
);

const TypeIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={TypeIconSvg} {...props} />;
};

const popconfirmIcon = <DeleteTwoTone twoToneColor="#eb2f96" />;

export default ColumnHeader;
