import { FulfillInputProps } from "../Questions/FulfillQuestion";
import { Button, Row, Space, Tag, Tooltip, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

interface FileInputProps {
  rows?: number;
}

const FileUploadInput: React.FC<FulfillInputProps & FileInputProps> = ({
  answerId,
  defaultAnswer,
  onChange,
  disabled,
}) => {
  const uploadProps: any = {
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    customRequest: (data: any) => {
      onChange(data.file);
    },
  };

  const tooltipTitle = [
    <div>Supported Extensions:</div>,
    <div>pdf, docx, xlsx, csv, txt</div>,
  ];

  return (
    <Upload {...uploadProps} id={answerId} disabled={disabled}>
      <Row justify="start">
        <Tooltip title={tooltipTitle}>
          <Button icon={<UploadOutlined />}>Choose a File</Button>
        </Tooltip>
        <Space align="end" style={{ marginLeft: "10px" }}>
          {defaultAnswer && <Tag>{defaultAnswer}</Tag>}
        </Space>
      </Row>
    </Upload>
  );
};

export default FileUploadInput;
