import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Button } from "antd";

export const P17 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="instructions">
        <p><b>11B. IL5</b>: CUI and Unclassified National Security Information (U-NSI). (Up to M- M-x): Accommodates non-public, unclassified NSS system data (i.e., U- NSI) or non-public, unclassified data where the unauthorized disclosure of information could be expected to have a serious adverse effect on organizational operations, organizational assets, or individuals. This includes CUI and/or other mission data that may require a higher level of protection than that afforded by IL4 as deemed necessary by the information owner, public law, or other government regulation.</p>
        <p><b>11C. IL4</b>: Controlled Unclassified Information. (Up toM-M-x): Accommodates non-public, unclassified data where the unauthorized disclosure of information could be expected to have a serious adverse effect on organizational operations and assets, or individuals. This encompasses CUI and/or other mission data, including that used in direct support of military or contingency operations.</p>
        <p><b>11D. IL2</b>: Non-Controlled Unclassified Information. (Up to L-M-x): Accommodates publicly releasable data or non-public unclassified data where the unauthorized disclosure of information could be expected to have a limited adverse effect on organizational operations and assets, or individuals. This includes all data cleared for public release as well as some low confidentiality unclassified information NOT designated as CUI or military/contingency operations mission data.</p>
        <p>DoD does not support information categorized as high confidentiality and high integrity (H-H- x) being deployed in commercial CSOs at this time.</p>

        <p><b>12. Security Description/Environment</b></p>

        <p>
          Provide a description of the security environment. This description should tell the story of who, what, where, when, why, and how cybersecurity and information assurance hardware and software is, or will be, protecting the system.
        </p>

        <ul className="slash">
          <li>Describe the network security infrastructure and the internal security components of the system to include encryption devices, firewalls, wireless, etc.</li>
          <li>Virtual Private Networks, Intrusion Detection Systems, Intrusion Prevention Systems, Demilitarized Zone, Host Based Security System, and Anti-Virus.</li>
          <li>If any security-related services are being provided by other systems (such as through a Service Level Agreement/Memorandum of Agreement/Memorandum of Understanding), describe this as well.</li>
          <li>Include any environmental or technical factors that raise special security concerns, such as use of Personal Digital Assistants, wireless technology, etc.</li>
          <li>Describe any interconnections the system has.</li>
          <li>Describe your Defense-in-Depth: Provide a narrative, DoDAF view or other artifact that captures processes in place to protect the information and or System.</li>
        </ul>

        <p><b>13. Overlays</b></p>
        <p><b>13A. Intelligence Overlay.</b></p>
        <p>Does the system process, store, or transmit intelligence, surveillance, or reconnaissance (ISR)?</p>
        <p><b>13B. Cross Domain Solution Overlay</b></p>
        <p>Will you implement, manage, or maintain a cross domain solution?</p>
        <p><b>13C. Nuclear Command, Control & Communications (NC3) Overlay</b></p>
        <p>Does the system store, process, or transmit NC3 data? Please note that use of the NC3 overlay also requires the implementation of the Intel overlay.</p>
        <p><b>13D. Space Platform Overlay</b></p>
        <p>Is the system (or subsystem) a space platform as defined in CNSSI 1253F Atch 2 and unmanned?</p>
        <p><b>13E. Classified Information Overlay</b></p>
        <p>Does the system store, process, or transmit classified information?</p>
        <p><b>13F. DoD Functional Mission/Capability Specific Overlay</b></p>
        <p>Is your system required to execute an organizational mission or function-specific overlay? For example,</p>
      </div>
    </>
  );
};
