import { Divider, Row, Typography } from "antd";
import MarkdownPreviewer from "components/Common/Markdown/MarkdownPreview";

const PageGroupSection = ({ header, description }) => {
  if (!header) return <></>;
  return (
    <>
      <Divider style={{ margin: 15 }} />
      <Typography.Title level={3} style={{ margin: 0 }}>
        {header}
      </Typography.Title>
      <Row justify="center" style={{ width: "100%", marginTop: 5 }}>
        <MarkdownPreviewer source={description} />
      </Row>
    </>
  );
};

export default PageGroupSection;
