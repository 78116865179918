import { useState } from "react";
import { Form, Field } from "react-final-form";
import { Button, Card, Col, message, Row, Space, Typography } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { ConditionAnswerOption as AnswerCondition, QuestionType } from "types";
import Api from "api";
import Condition from "components/Common/RFForm/Condition";
import SelectField from "components/Common/RFForm/Fields/SelectField";
import ConditionInformation from "./ConditionInformation";
import { logicOptions, questionTypeOptions } from "../Shared/SelectOptions";
import QuestionSelectField from "./Fields/QuestionSelectField";

const { Title } = Typography;

interface Props {
  setWidth: (width: number) => void;
}

interface FormValues {
  logicType: AnswerCondition;
  dataType: QuestionType;
  questionSelect?: any[];
}

const ManageAnswerConditions: React.FC<Props> = ({ setWidth }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { condition } = location.state;
  const [questionTableMode, setQuestionTableMode] = useState<"view" | "edit">("view");
  const [applyCondition, { loading }] = useMutation(Api.ConditionalLogic.ApplyCondition(), {
    refetchQueries: [{ query: Api.ConditionalLogic.GetAllConditionalRelationships() }],
  });

  const onSubmit = async (values: FormValues, form) => {
    console.log("onSubmit", { values, condition });
    const { logicType } = values;
    const answerId: string = condition.answer.id;

    try {
      if (logicType === AnswerCondition.AdditionalQuestions) {
        applyCondition({
          variables: {
            answerId: answerId,
            conditionType: AnswerCondition.AdditionalQuestions,
            additionalQuestionsArgs: {
              questionIds: values.questionSelect,
            },
          },
          onCompleted: (res) => {
            console.log({ UpdateAnswerCondition: res });
            message.success("Condition applied successfully", 5);
            setQuestionTableMode("view");
            navigate(-1);
          },
          onError: (error) => {
            console.log(error.message);
            message.error(error.message, 5);
            form.reset(values);
          },
        });
      } else {
        applyCondition({
          variables: {
            answerId: answerId,
            conditionType: AnswerCondition.AdditionalField,
            additionalFieldArgs: { dataType: values.dataType },
            questionIds: null,
            moduleIds: null,
          },
          onCompleted: (res) => {
            console.log({ UpdateAnswerCondition: res });
            message.success("Condition applied successfully", 5);
            setQuestionTableMode("view");
            navigate(-1);
          },
          onError: (error) => {
            console.log(error.message);
            message.error(error.message, 5);
            form.reset(values);
          },
        });
      }
    } catch (error) {
      console.log(error);
      form.reset(values);
    }
  };

  const isLinkedQuestion =
    condition.answerCondition === AnswerCondition.AdditionalQuestions &&
    condition.children.length > 0;

  const questionList = isLinkedQuestion
    ? condition.children.map((child) => child.question.id)
    : [];

  const initialValues = {
    logicType: condition.answer.answerType,
    questionSelect: questionList,
    dataType: condition.answer.additionalFieldType,
  };

  const required = (value) => (value ? undefined : "Required");

  const hasQuestionValues = (value) => (value.length > 0 ? undefined : "Required");

  console.log("%cManageAnswerConditions:", "color:cyan", {
    condition,
    questionList,
    initialValues,
  });

  return (
    <div>
      <ConditionInformation condition={condition} />

      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <Card
            title={
              <Title level={3} style={{ fontSize: 16 }}>
                Condition Logic
              </Title>
            }
            size="small"
            className="shadowed-static"
            bodyStyle={{ padding: 16 }}
          >
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              keepDirtyOnReinitialize={true}
              subscription={{
                submitting: true,
                pristine: true,
                values: true,
                valid: true,
                invalid: true,
                errors: true,
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                errors,
                invalid,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field<AnswerCondition>
                      name="logicType"
                      label="Logic Type"
                      component={SelectField}
                      options={logicOptions}
                      validate={required}
                      width="50%"
                    />
                    <Condition when="logicType" is={AnswerCondition.AdditionalField}>
                      <Field<QuestionType>
                        name="dataType"
                        label="Additional Field Type"
                        component={SelectField}
                        options={questionTypeOptions}
                        validate={required}
                        width="50%"
                      />
                    </Condition>
                    <Condition when="logicType" is={AnswerCondition.AdditionalQuestions}>
                      <Field<any[]>
                        name="questionSelect"
                        label="Question Selection"
                        validate={hasQuestionValues}
                        render={(props) => (
                          <QuestionSelectField
                            label="Question Selection"
                            setWidth={setWidth}
                            mode={questionTableMode}
                            setMode={setQuestionTableMode}
                            {...props}
                          />
                        )}
                      />
                    </Condition>
                  </div>
                  <Row justify="end" style={{ paddingBottom: 6 }}>
                    <Space>
                      <Button
                        disabled={invalid || submitting}
                        loading={loading}
                        icon={<SaveOutlined />}
                        type="primary"
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </Space>
                  </Row>
                </form>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ManageAnswerConditions;
