import useAccessControl, { Permissions } from "hooks/useAccessControl";
import { Badge, Button, Tooltip } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import { ApplicationQuestionNode } from "types";
import { CommentsDrawerState } from "./CommentsDrawer";

interface Props {
  question: ApplicationQuestionNode;
  setDrawer: React.Dispatch<CommentsDrawerState>;
}

const CommentsButton: React.FC<Props> = ({ question, setDrawer }) => {
  const { PBAC } = useAccessControl();

  if (
    !PBAC([
      Permissions.CreateAppComments,
      Permissions.ReadAppComments,
      Permissions.UpdateAppComments,
      Permissions.DeleteAppComments,
    ])
  )
    return <></>;

  return (
    <div
      onClick={() =>
        setDrawer({
          open: true,
          question: question,
        })
      }
    >
      <Badge
        count={question.totalComments}
        color="#1890ff"
        children={
          <Tooltip title="Open Comments">
            <Button style={{ border: "none", padding: "0 10px" }}>
              <CommentOutlined />
            </Button>
          </Tooltip>
        }
      ></Badge>
    </div>
  );
};

export default CommentsButton;
