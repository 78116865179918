export const P18 = (props) => {
  return (
    <>
      <div className="itcsc" style={{ display: "grid", placeItems: "center" }}>
        <h1>REFERENCES</h1>
        <div className="references" style={{ width: '100%' }}>
          <ol style={{ textAlign: 'left', listStyleType: 'lower-alpha' }}>
            <li>DOD CUI Registry is located at https://www.dodcui.mil/Home/DOD-CUI-Registry/</li>
            <li>DODI 5200.48, Controlled Unclassified Information (CUI), 06 March 2020</li>
            <li>DODI 5230.09 Change 1, Clearance of DOD Information for Public Release, 09 February 2022</li>
            <li>DAF Guidance Memorandum to AFI 17-101, Risk Management Framework (RMF) for Air Force Information Technology<br /> (IT), 10 June 2022</li>
            <li>AFI 33-332, Air Force Privacy and Civil Liberties Program, 12 May 2020</li>
            <li>DAF RMF Knowledge Service at<br /> https://rmfks.osd.mil/rmf/collaboration/Component%20Workspaces/AirForce/Pages/default.aspx</li>
            <li>DOD Manual (DODM) 5200.01 Volume 1, Change 2, DOD Information Security Program: Overview, Classification, and <br />Declassification, 28 July 2020</li>
            <li>DODM 5200.01 Volume 2, Change 4, DOD Information Security Program: Marking of Information, 28 July 2020</li>
            <li>DODI 5200.01 Change 2, DOD Information Security Program and Protection of Sensitive Compartmented Information (SCI),<br /> 01 October 2020</li>
            <li>DODI 5210.02 Change 2, Access to and Dissemination of Restricted Data and Formerly Restricted Data, 3 June 2011</li>
            <li>DOD Joint Special Access Program Implementation Guide (JSIG), 11 April 2016</li>
            <li>DoD Directive 5205.07, “Special Access Program (SAP) Policy,” July 1, 2010</li>
            <li>DOD Directive 5230.11, Disclosure of Classified Military Information to Foreign Governments and International<br /> Organizations, 16 June 1992</li>
            <li>CNSSI 1253, Security Categorization and Control Selection for National Security Systems, 01 August 2022</li>
            <li>
              References for NSS Determination
              <ul>
                <li>10 U.S.C. § 130b, Personnel in Overseas, Sensitive, or Routinely Deployable Units: Nondisclosure of Personally<br /> Identifying Information (2020)</li>
                <li>10 U.S.C. § 130e, Treatment under Freedom of Information Act of Certain Critical Infrastructure Security Information<br /> (2020)</li>
                <li>15 U.S.C. §§ 46(f), 57b-2 & 15 U.S.C. §3710a(c), Trade Secrets Act Data</li>
                <li>18 U.S.C. § 3771, Crime Victim’s Rights Act (DOD implemented by Article 6b, Uniform Code of Military Justice —<br /> 10 U.S.C. § 806b)</li>
                <li>40 U.S.C. § 11103, Applicability to NSS (2020)</li>
                <li>42 U.S.C. § 2162, Unclassified Nuclear Data</li>
                <li>Critical Infrastructure Information Act of 2002, Civilian Critical Infrastructure</li>
                <li>NIST SP 800-59, Guideline for Identifying an Information System as a National Security System, 20 August 2003</li>
                <li>DODI 6495.02 Volume 1 Change 7, Sexual Assault Prevention and Response: Program Procedures, 06 September<br /> 2022</li>
                <li>DOD CIO Memorandum, Implementing National Security Memorandum-8, Task 5: Identification and Inventorying of<br /> National Security Systems Guidance (NMM-2022-05) (25 February 2022), 05 May 2022</li>
                <li>National Manager Memorandum (NMM) NMM-2022-05, National Security Memorandum 8, Task 5: Identification</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};
