import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Col, Row } from "antd";
import LetterBullet from "../Assets/LetterBullet";

export const P4 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const questionAOptions = {
    '4_a_biometrics': 'Biometrics',
    '4_a_birth_date': 'Birth Date',
    '4_a_child_info': 'Child Information',
    '4_a_citizenship': 'Citizenship ',
    '4_a_disability_info': 'Disability Information',
    '4_a_dod_id_number': 'DoD ID Number',
    '4_a_drivers_license': 'Driver\'s License',
    '4_a_education_info': 'Education Information',
    '4_a_emergency_contact': 'Emergency Contact ',
    '4_a_employment_info': 'Employment Information',
    '4_a_financial_info': 'Financial Information',
    '4_a_gender_identification': 'Gender/Gender Identification',
    '4_a_home_cel': 'Home/Cell Phone',
    '4_a_law_enforcement_info': 'Law Enforcement Information ',
    '4_a_legal_status': 'Legal Status',
    '4_a_mailing_address': 'Mailing/Home Address',
    '4_a_marital_status': 'Marital Status ',
    '4_a_medical_info': 'Medical Information ',
    '4_a_military_records': 'Military Records',
    '4_a_mothers_maiden': 'Mother\'s Middle/Maiden Name',
    '4_a_names': 'Name(s)',
    '4_a_official_duty_address': 'Official Duty Address',
    '4_a_official_duty_telephone_phone': 'Official Duty Telephone Phone',
    '4_a_other_id_num': 'Other ID Number',
    '4_a_passport_info': 'Passport Information',
    '4_a_personal_email_address': 'Personal E-mail Address      ',
    '4_a_photo': 'Photo',
    '4_a_place_of_birth': 'Place of Birth',
    '4_a_position_title': 'Position/Title ',
    '4_a_protected_health_info': 'Protected Health Information (PHI)',
    '4_a_race_ethnicity': 'Race/Ethnicity',
    '4_a_rank_grade': 'Rank/Grade ',
    '4_a_religious_preference': 'Religious Preference ',
    '4_a_records': 'Records',
    '4_a_security_information': 'Security Information',
    '4_a_ssn': 'Social Security Number (SSN) (Full or in any form) ',
    '4_a_work_email_address': 'Work E-mail Address',
    '4_a_other_info': 'If Other, enter the information in the box below',
  }


  return (
    <>
      <div
        className="doc_pia"
        style={{
          width: "100%",
          fontSize: 10,
        }}
      >
        <Row justify="center">
          <div>
            <b>
              <u>SECTION 2: PII RISK REVIEW</u>
            </b>
          </div>
        </Row>
        <div style={{ display: "flex" }}>
          <Row style={{ padding: "0 10px" }}>
            <b>a.</b>
          </Row>
          <Row>
            <b>
              What PII will be collected (a data element alone or in combination that can
              uniquely identify an individual)? (Check all hat apply)
            </b>
          </Row>
        </div>

        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          paddingLeft: 10,
          paddingTop: 5,
        }}>
          {Object.entries(questionAOptions).map(([fieldName, text]) => (
            <div key={fieldName}>
              <EditableCheckbox {...elementProps} fieldName={fieldName} />
              <span style={{ marginLeft: 10 }}>{text}</span>
            </div>
          ))}
        </div>

        <Row style={{ paddingTop: 10 }}>
          <EditableField {...elementProps} fieldName="4_a_other_text" style={{ height: 40 }} />
        </Row>

        <div style={{ paddingLeft: 10 }}>
          <Row style={{ paddingTop: 5 }}>
            If the SSN is collected, complete the following questions.
          </Row>
          <Row style={{ paddingTop: 10 }}>
            <i>
              (DoD Instruction 1000.30 states that all DoD personnel shall reduce or eliminate the use of SSNs wherever possible.  SSNs shall not be used in spreadsheets,
              hard copy lists, electronic reports, or collected in surveys unless they meet one or more of the acceptable use criteria.)
            </i>
          </Row>
          <Row style={{ paddingLeft: 20, paddingTop: 10 }}>
            <LetterBullet letter={'(1)'}>
              Is there a current (dated within two (2) years) DPCLTD approved SSN
              Justification on Memo in place?
            </LetterBullet>
          </Row>

          <Row style={{ padding: "5px 0" }}>
            <div style={{ marginRight: 10 }}>
              <EditableRadio {...elementProps} fieldName="4_a_dod_dpcltd_approved_ssn_yes" />
              <span style={{ marginLeft: 10 }}>Yes</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="4_a_dod_dpcltd_approved_ssn_no" />
              <span style={{ marginLeft: 10 }}>No</span>
            </div>
          </Row>
          <Row>
            <div style={{ paddingLeft: 20 }}>
              If "Yes," provide the signatory and date approval.  If “No,” explain why there is no SSN Justification Memo.
            </div>
            <EditableField
              {...elementProps}
              fieldName="4_a_dod_dpcltd_approved_ssn_yes_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row>
            <div style={{ paddingLeft: 20 }}>
              <LetterBullet letter={'(2)'}>
                Describe the approved acceptable use in accordance with DoD Instruction 1000.30 “Reduction of Social Security Number (SSN) Use within DoD”.
              </LetterBullet>
            </div>
            <EditableField
              {...elementProps}
              fieldName="4_a_dod_dpcltd_describe_approved_acceptable_use_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row>
            <div style={{ paddingLeft: 20 }}>
              <LetterBullet letter={'(3)'}>
                Describe the mitigation efforts to reduce the use including visibility and printing of SSN in accordance with DoD Instructoin 1000.30, “Reduction of
                Social Security Number (SSN) Use within DoD”.
              </LetterBullet>
            </div>
            <EditableField
              {...elementProps}
              fieldName="4_a_dod_dpcltd_describe_mitigation_efforts_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row style={{ padding: "5px 10px", paddingLeft: 20 }}>
            <LetterBullet letter={'(4)'}>
              Has a plan to eliminate the use of the SSN or mitigate its use and or visibility been identified in the approved SSN Justification request?
            </LetterBullet>
          </Row>
          <Row style={{ paddingLeft: 30 }}>
            If "Yes", provide the unique identifier and when it can be eliminated
          </Row>
          <Row style={{ paddingLeft: 30 }}>
            If "No", explain
          </Row>
          <Row style={{ padding: "5px 10px" }}>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="4_a_4_plan_eliminate_ssn_or_use_yes"
              />
              <span style={{ marginLeft: 10 }}>Yes</span>
            </div>
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="4_a_4_plan_eliminate_ssn_or_use_no"
              />
              <span style={{ marginLeft: 10 }}>No</span>
            </div>
          </Row>

          <Row>
            <EditableField
              {...elementProps}
              fieldName="4_a_4_plan_eliminate_ssn_or_use_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row style={{ padding: "5px 0" }}>
            <b style={{ marginRight: 20 }}>b. What is the PII confidentiality impact level<b style={{ verticalAlign: 'super', fontSize: 8 }}>2</b>?</b>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="4_b_pii_confidentiality_impact_low"
              />
              <span style={{ marginLeft: 10 }}>Low</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="4_b_pii_confidentiality_impact_moderate"
              />
              <span style={{ marginLeft: 10 }}>Moderate</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="4_b_pii_confidentiality_impact_high"
              />
              <span style={{ marginLeft: 10 }}>High</span>
            </div>
          </Row>

          <Row>
            <EditableField
              {...elementProps}
              fieldName="4_b_pii_confidentiality_impact_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row style={{ fontSize: 8, paddingTop: 5 }}>
            <div>
              <span style={{ fontSize: 8, verticalAlign: 'super' }}>1</span>
              The definition of PHI involves evaluating conditions listed in the HIPAA. Consult with General Counsel to make this determination.
            </div>
            <div>
              <span style={{ fontSize: 8, verticalAlign: 'super' }}>2</span>
              Guidance on determining the PII confidentiality impact level, see Section 2.5 “Categorization of PII Using NIST SP 800-122.” Use the identified PII confidentiality impact level to apply the appropriate Privacy Overlay low, moderate, or high.  This activity may be conducted as part of the categorization exercise that occurs under the Risk Management Framework (RMF).  Note that categorization under the RMF is typically conducted using the information types described in NIST Special Publication (SP) 800-60, which are not as granular as the PII data elements listed in the PIA table. Determining the PII confidentiality impact level is most effective when done in collaboration with the Information Owner, Information System Owner, Information System Security Manager, and representatives from the security and privacy organizations, such as the Information System Security Officer (ISSO) and Senior Component Official for Privacy (SCOP) or designees.
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};
