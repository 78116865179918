import { ApiOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { ResultStatusType } from "antd/lib/result";
import { useNavigate, useParams } from "react-router-dom";

type RunResultProps = {
  onClose: any;
  status: ResultStatusType;
  integrationName: string;
};

const RunResult: React.FC<RunResultProps> = (props) => {
  const { onClose, status, integrationName } = props;
  const navigate = useNavigate();
  const { applicationId } = useParams();
  console.log(applicationId);

  const navigateToFindings = () => {
    return navigate(`/workflow/${applicationId}/security-findings`);
  };

  const title =
    status === "success"
      ? `${integrationName} was ran successfully!`
      : `${integrationName} run operation failed...`;
  const subTitle =
    status === "success"
      ? "Navigate to the Security Findings Page to view your security data"
      : `Please check your configuration and try again`;

  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      style={{ padding: "0px 0px 48px 0px" }}
      extra={[
        <Button icon={<ApiOutlined />} onClick={onClose} key="back">
          Back to Integrations
        </Button>,
        <Button
          type="primary"
          onClick={navigateToFindings}
          icon={<SearchOutlined />}
          key="secFindings"
        >
          Security Findings
        </Button>,
      ]}
    />
  );
};

export default RunResult;
