import { Comment, Divider, Row, Space, Tooltip } from "antd";
import { LockOutlined } from "@ant-design/icons";
import moment from "moment";
import { ApplicationQuestionCommentNode } from "types";

interface Props {
  visible: boolean;
  comment: ApplicationQuestionCommentNode;
}

const CommentItem: React.FC<Props> = ({ visible, comment }) => {
  if (!visible) return <></>;

  const CommentBody = () => (
    <Comment
      className="question-comment"
      author={
        <Row>
          <Space>
            <div style={{ textTransform: "capitalize" }}>{comment.author?.fullname}</div>
            <div>
              {comment.isPrivate && (
                <Tooltip
                  color="black"
                  title="This comment is only visible to administrators, advisors, and managers"
                >
                  <LockOutlined style={{ color: "black" }} />
                </Tooltip>
              )}
            </div>
          </Space>
        </Row>
      }
      content={
        <Row>
          <p>{comment.text!.charAt(0).toUpperCase() + comment.text!.slice(1)}</p>
        </Row>
      }
      actions={[
        <Row justify="end" style={{ fontSize: ".75rem" }}>
          {moment(comment.createdOn).format("llll")}
        </Row>,
      ]}
    />
  );

  return (
    <>
      {comment.answer ? (
        <Comment
          className="answer-comment"
          author={<Row>Answer: {comment.answer.answerText}</Row>}
          content={<></>}
          children={<CommentBody />}
        />
      ) : (
        <CommentBody />
      )}
      <Divider />
    </>
  );
};

export default CommentItem;
