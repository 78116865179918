import { gql } from "graphql-tag";

export const ApplicationQuestionFragments = gql`
  fragment ApplicationQuestionFragmentQuestion on ApplicationQuestionNode {
    id
    questionType
    question
    helpText
    totalComments
    totalTime
    totalRisk
    isLocked
    isAnswered
    isSavedWithDefaults
  }
  fragment ApplicationQuestionFragmentAnswerChoices on ApplicationAnswerChoiceNode {
    id
    isSaved
    answerText
    answer
    answerType
    additionalFieldType
    additionalFieldToggle
    riskIncrease
    timeIncreaseInDays
  }
  fragment ApplicationQuestionFragmentSavedAnswers on ApplicationAnswerChoiceNode {
    id
    answerText
    answer
    additionalFieldType
    answerType
    riskIncrease
    timeIncreaseInDays
  }
  fragment ApplicationQuestionFragmentGroup on ApplicationQuestionGroupNode {
    id
    header
    description
    order
    groupType
    categoryId
  }
`;

export const ApplicationQuestion = {
  UnSaveAnswer: () => gql`
    ${ApplicationQuestionFragments}
    mutation clearSavedAnswer($id: String!, $answers: [String]!) {
      clearSavedAnswer(id: $id, answers: $answers) {
        ...ApplicationQuestionFragmentQuestion
        linkedQuestions {
          ...ApplicationQuestionFragmentQuestion
          answerChoices {
            ...ApplicationQuestionFragmentAnswerChoices
          }
          savedAnswers {
            ...ApplicationQuestionFragmentSavedAnswers
          }
          linkedQuestions {
            ...ApplicationQuestionFragmentQuestion
            answerChoices {
              ...ApplicationQuestionFragmentAnswerChoices
            }
            savedAnswers {
              ...ApplicationQuestionFragmentSavedAnswers
            }
          }
        }
        answerChoices {
          ...ApplicationQuestionFragmentAnswerChoices
        }
        savedAnswers {
          ...ApplicationQuestionFragmentSavedAnswers
        }
        group {
          ...ApplicationQuestionFragmentGroup
        }
      }
    }
  `,
  SaveFulfillAnswer: () => gql`
    ${ApplicationQuestionFragments}
    mutation saveFulfillAnswer(
      $questionId: String!
      $answerId: String!
      $answerText: String!
    ) {
      saveFulfillAnswer(
        questionId: $questionId
        answerId: $answerId
        answerText: $answerText
      ) {
        ...ApplicationQuestionFragmentQuestion
        linkedQuestions {
          ...ApplicationQuestionFragmentQuestion
          answerChoices {
            ...ApplicationQuestionFragmentAnswerChoices
          }
          savedAnswers {
            ...ApplicationQuestionFragmentSavedAnswers
          }
          linkedQuestions {
            ...ApplicationQuestionFragmentQuestion
            answerChoices {
              ...ApplicationQuestionFragmentAnswerChoices
            }
            savedAnswers {
              ...ApplicationQuestionFragmentSavedAnswers
            }
          }
        }
        answerChoices {
          ...ApplicationQuestionFragmentAnswerChoices
        }
        savedAnswers {
          ...ApplicationQuestionFragmentSavedAnswers
        }
        group {
          ...ApplicationQuestionFragmentGroup
        }
      }
    }
  `,
  SaveMultipleChoiceAnswer: () => gql`
    ${ApplicationQuestionFragments}
    mutation saveMultipleChoiceAnswer(
      $questionId: String!
      $answerId: String!
      $answerText: String
    ) {
      saveMultipleChoiceAnswer(
        questionId: $questionId
        answerId: $answerId
        additionalFieldText: $answerText
      ) {
        ...ApplicationQuestionFragmentQuestion
        linkedQuestions {
          ...ApplicationQuestionFragmentQuestion
          answerChoices {
            ...ApplicationQuestionFragmentAnswerChoices
          }
          savedAnswers {
            ...ApplicationQuestionFragmentSavedAnswers
          }
          linkedQuestions {
            ...ApplicationQuestionFragmentQuestion
            answerChoices {
              ...ApplicationQuestionFragmentAnswerChoices
            }
            savedAnswers {
              ...ApplicationQuestionFragmentSavedAnswers
            }
          }
        }
        answerChoices {
          ...ApplicationQuestionFragmentAnswerChoices
        }
        savedAnswers {
          ...ApplicationQuestionFragmentSavedAnswers
        }
        group {
          ...ApplicationQuestionFragmentGroup
        }
      }
    }
  `,
  SaveFileAnswer: () => gql`
    mutation saveFileUploadAnswer($id: String!, $filename: String!) {
      saveFileUploadAnswer(questionId: $id, filename: $filename) {
        url
        fields
      }
    }
  `,
  UpdateFileStatus: () => gql`
    ${ApplicationQuestionFragments}
    mutation updateFileStatus($id: String!, $status: Int!) {
      updateQuestionFileStatus(questionId: $id, status: $status) {
        ...ApplicationQuestionFragmentQuestion
        linkedQuestions {
          ...ApplicationQuestionFragmentQuestion
          answerChoices {
            ...ApplicationQuestionFragmentAnswerChoices
          }
          savedAnswers {
            ...ApplicationQuestionFragmentSavedAnswers
          }
          linkedQuestions {
            ...ApplicationQuestionFragmentQuestion
            answerChoices {
              ...ApplicationQuestionFragmentAnswerChoices
            }
            savedAnswers {
              ...ApplicationQuestionFragmentSavedAnswers
            }
          }
        }
        answerChoices {
          ...ApplicationQuestionFragmentAnswerChoices
        }
        savedAnswers {
          ...ApplicationQuestionFragmentSavedAnswers
        }
        group {
          ...ApplicationQuestionFragmentGroup
        }
      }
    }
  `,
  UnSaveFile: () => gql`
    ${ApplicationQuestionFragments}
    mutation clearFileAnswer($id: String!) {
      deleteSavedQuestionFile(questionId: $id) {
        ...ApplicationQuestionFragmentQuestion
        linkedQuestions {
          ...ApplicationQuestionFragmentQuestion
          answerChoices {
            ...ApplicationQuestionFragmentAnswerChoices
          }
          savedAnswers {
            ...ApplicationQuestionFragmentSavedAnswers
          }
          linkedQuestions {
            ...ApplicationQuestionFragmentQuestion
            answerChoices {
              ...ApplicationQuestionFragmentAnswerChoices
            }
            savedAnswers {
              ...ApplicationQuestionFragmentSavedAnswers
            }
          }
        }
        answerChoices {
          ...ApplicationQuestionFragmentAnswerChoices
        }
        savedAnswers {
          ...ApplicationQuestionFragmentSavedAnswers
        }
        group {
          ...ApplicationQuestionFragmentGroup
        }
      }
    }
  `,
};
