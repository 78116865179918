import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useViewport from "hooks/useViewport";
import useChangePassword from "../Auth/useChangePassword";
import CsarBrand from "components/Common/Brands/CsarBrand";
import { Typography, Card, Button, Input, Form, Row, Divider, Layout, Col } from "antd";

const PasswordChange: React.FC = () => {
  const viewport = useViewport();
  const navigate = useNavigate();
  const params = useParams();
  const resetToken = params?.resetToken as string;

  const { changePassword, changePasswordLoading } = useChangePassword();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log({ resetToken, ...values });

    changePassword(values.email, resetToken, values.newPassword, values.confirmPassword);
  };

  const isDesktop = viewport.isDesktop;

  return (
    <Layout>
      <Row
        justify="center"
        align="middle"
        style={!isDesktop ? { height: "100%" } : { height: "100vh" }}
      >
        <Card
          className="shadowed-static"
          style={{
            width: "100%",
            maxWidth: !isDesktop ? "100%" : "600px",
          }}
        >
          <CsarBrand style={{ width: 60, marginBottom: 10 }} />
          <Typography.Title level={3}>Password Change</Typography.Title>
          <Divider />
          <Row justify="space-around" align="middle">
            <Col>
              <Form
                layout="vertical"
                name="form"
                form={form}
                onFinish={onFinish}
                style={{ textAlign: "left", width: "250px" }}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  label="New password"
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter new password",
                    },
                    {
                      message: "Please enter valid password",
                      validator(_, value) {
                        const hasSpecial = /[^a-z0-9]/gi.test(value);
                        const hasNumber = /[0-9]/gi.test(value);
                        const hasCapital = /[A-Z]/.test(value);
                        const hasLength = value.length >= 15;
                        const passwordValid = hasSpecial && hasLength && hasCapital && hasNumber;
                        console.log({ value, hasSpecial, hasLength, hasCapital, hasNumber });


                        if (!value || passwordValid) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error("Passwords do not match"));
                      },
                    },
                  ]}
                >
                  <Input.Password placeholder="New password" />
                </Form.Item>
                <Form.Item
                  label="Confirm password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Passwords do not match"));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm password" />
                </Form.Item>
                <Form.Item style={{ marginTop: 40, marginBottom: 0 }}>
                  <Row justify="space-between">
                    <Button
                      type="primary"
                      role="submit"
                      htmlType="submit"
                      loading={changePasswordLoading}
                    >
                      Submit
                    </Button>
                    <Button type="link" onClick={() => navigate("/login")}>
                      Go to Login
                    </Button>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
    </Layout>
  );
};

export default PasswordChange;
