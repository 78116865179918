import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { message } from "antd";
import { QuestionNode } from "types";
import { client } from "components/Providers/WithApollo";
import Api from "api";

/**
 * @description: retrieves basic list of Questions for use in selection components, will grab list from existing query if present, else query server
 */

export const useQuestionList = () => {
  const [questions, setQuestions] = useState<QuestionNode[]>([]);
  const [queryQuestions, { loading, error, refetch }] = useLazyQuery(
    Api.Question.QuestionSelectionTable(),
    {
      onCompleted: (res) => {
        setQuestions(res.allQuestions);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 5);
      },
    },
  );

  const getQuestions = () => {
    try {
      const existingQuestions = client.readQuery({
        query: Api.Question.QuestionSelectionTable(),
      });

      if (existingQuestions?.allQuestions.length > 0) {
        setQuestions(existingQuestions.allQuestions);
        return;
      }

      queryQuestions();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return { questions, loading, error, refetch };
};
