import { useEffect, useRef, useState } from "react";
import { Form, Input, InputRef } from "antd";
import { DataRow } from "../Shared/types";

interface Props {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: string;
  column: any;
  record: any;
  rows: DataRow[];
  setRows: any;
}

const EditableCell: React.FC<Props> = (props) => {
  const { title, editable, dataIndex, record, column, rows, setRows, ...restProps } = props;
  // console.log("%cEditableCellProps: ", "color:orange", {
  //   title,
  //   dataIndex,
  //   record,
  //   rows,
  //   restProps,
  // });

  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const rowKey = record.key;

  const value = record?.label;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = (e) => {
    try {
      toggleEdit();
      const currentRows = [...rows];

      const updatedRows = currentRows.map((row) => {
        if (row.key === rowKey) {
          return {
            ...row,
            label: e.target.value,
          };
        }
        return row;
      });

      console.log("%crowSave(): ", "color:orange", {
        currentRows,
        updatedRows,
      });

      setRows(updatedRows);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const onBlurSave = (e) => {
    if (value.length > 1 && e.target.value == "") {
      toggleEdit();
      return;
    }

    save(e);
  };

  let childNode = value;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={onBlurSave}
          onBlur={onBlurSave}
          value={value}
          defaultValue={value}
          autoComplete="off"
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ padding: 0 }} onClick={toggleEdit}>
        {value}
      </div>
    );
  }

  return <div {...restProps}>{childNode}</div>;
};

export default EditableCell;
