import { useState } from "react";
import { Button, Drawer, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import ManageModule from "../Workflow/Forms/Manage/ManageModule";
import Api from "api";
import InputConfirmModal from "components/Common/InputConfirmModal";

/**
 *
 * @returns Modal to manage a module
 */

type Props = {
  open: boolean;
  moduleId: string;
  state: any;
  onClose: () => void;
};

const ManageModuleDrawer: React.FC<Props> = ({ open, moduleId, state, onClose }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModule, { loading: deleteLoading }] = useMutation(Api.QuestionModule.Delete(), {
    onCompleted: (res) => {
      console.log(res);
      message.success("Module deleted successfully", 5);
      onClose();
    },
    onError: (error) => {
      console.log(error);
      message.error(error.message, 7);
    },
    refetchQueries: [{ query: Api.QuestionModule.WorkflowEditor() }],
  });

  const deleteModuleMutation = () => {
    deleteModule({
      variables: {
        id: moduleId,
      },
    });
  };

  return (
    <Drawer
      open={open}
      title="Manage Module"
      extra={
        <Button
          onClick={() => setDeleteModalOpen(true)}
          loading={deleteLoading}
          icon={<DeleteOutlined />}
          type="primary"
          danger
        >
          Delete Module
        </Button>
      }
      width={700}
      destroyOnClose={true}
      onClose={onClose}
    >
      <ManageModule onClose={onClose} state={state} />
      {deleteModalOpen && (
        <InputConfirmModal
          visible={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          loading={deleteLoading}
          prompt="This will permanently delete this module"
          confirmation={"delete/module"}
          mutation={deleteModuleMutation}
        />
      )}
    </Drawer>
  );
};

export default ManageModuleDrawer;
