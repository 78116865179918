import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Input, Button, Row, message, Form, Select, Space, InputNumber, Divider } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Api from "api";
import { WfReducerState } from "../../Editor/Workflow/Components/reducer";
import { useModuleList } from "api/hooks/useModuleList";
import RmfPathsSelector from "../../Editor/Question Editor/Components/RmfPathsSelector";

const { Option } = Select;

/**
 * @returns form to update / delete a module, assign RMFAP
 */

type Props = {
  onClose: () => void;
  state: WfReducerState;
};

const ManageModule: React.FC<Props> = ({ onClose, state }) => {
  const { selectedModule } = state;

  const [form] = Form.useForm();
  const moduleToDelete = Form.useWatch("id", form);
  const { modules, loading } = useModuleList();
  const [selectedPaths, setSelectedPaths] = useState<any>([]);

  const [updateModule, { loading: updateLoading }] = useMutation(Api.QuestionModule.Update(), {
    onCompleted: (res) => {
      console.log(res);
      message.success("Module updated successfully", 7);
      onClose();
    },
    onError: (error) => {
      console.log(error);
      message.error(error.message, 7);
    },
    refetchQueries: [{ query: Api.QuestionModule.WorkflowEditor() }],
  });

  useEffect(() => {
    // sets init path state
    if (!selectedModule) {
      console.log("%c!editingItem", "color:red");
      return;
    }

    if (selectedModule?.rmfActionPaths?.length > 0) {
      console.log("%csetRmfPaths in useEffect", "color:blue");
      setSelectedPaths(selectedModule.rmfActionPaths);
    }
  }, [selectedModule]);

  const onFinish = (values: any) => {
    const hasNameChange = values.name !== selectedModule?.name;
    const submitVals: any = {
      id: values.id,
      helpText: values.helpText,
      order: Number(values.order),
      rmfActionPath: selectedPaths.map((ap) => ap.path),
    };

    if (hasNameChange) {
      submitVals.name = values.name;
    }

    console.log({ values, hasNameChange, submitVals });
    updateModule({
      variables: {
        ...submitVals,
      },
    });
  };

  const toggleActionPath = (tag: any, checked: boolean) => {
    console.log("%ctoggleAp", "color:magenta", { tag, checked, selectedPaths });
    const nextSelectedTags: any[] = checked
      ? [...selectedPaths, { path: tag.value }]
      : selectedPaths.filter((t: any) => t.path !== tag.value);
    setSelectedPaths(nextSelectedTags);

    // set rmfaps state, pass rmfaps to mutation when user clicks submit
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={
        selectedModule && {
          id: selectedModule.id,
          order: selectedModule.order,
          helpText: selectedModule.helpText,
          name: selectedModule.name,
        }
      }
    >
      <Form.Item label="Module to manage" name="id">
        <Select placeholder="Select a Module" loading={loading}>
          {modules?.map((module) => (
            <Option key={module.id} label={module.name} value={module.id}>
              {module.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Help Text" name="helpText">
        <Input.TextArea autoSize={true} />
      </Form.Item>
      <Form.Item label="Order" name="order">
        <InputNumber min={0} style={{ width: "20%" }} />
      </Form.Item>
      <Divider />
      <Form.Item label="RMF Action Paths" name="rmfActionPaths">
        <RmfPathsSelector selectedPaths={selectedPaths} toggleActionPath={toggleActionPath} />
      </Form.Item>
      <Divider />
      <Row justify="end" style={{ marginBottom: 0 }}>
        <Space>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              loading={updateLoading}
              disabled={!moduleToDelete}
              icon={<SaveOutlined />}
              key="moduleUpdateButton"
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Row>
    </Form>
  );
};

export default ManageModule;
