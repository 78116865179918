import EditableField from "../../../Components/EditableField";
import { FieldWrapper } from ".";

export const P5 = (props) => {
  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <div className="ctpDocument">
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div className="fake-list-container">
          <p className="list-label">6. &nbsp; Test Cases</p>
          <FieldWrapper height={100}>
            <EditableField {...elementProps} fieldName="6_test_cases" />
          </FieldWrapper>
        </div>
        <div
          className="table-container"
          style={{ paddingLeft: 80, paddingRight: 80, fontSize: "8px" }}
        >
          <table style={{ width: "100%" }}>
            <thead style={{ border: "1px solid #000000", textAlign: "left" }}>
              <tr style={{ minWidth: "100%", width: "100%", background: "#AAD3EE" }}>
                <td>Test Case __: Title</td>
              </tr>
              <tr>
                <td>
                  Test Scenario: Provide a brief description. As appropriate divide into
                  functional sub-sections.
                </td>
              </tr>
            </thead>
          </table>
          <table style={{ width: "100%" }}>
            <thead
              style={{
                border: "1px solid #000000",
                textAlign: "center",
                background: "#AAD3EE",
              }}
            >
              <tr style={{ fontWeight: "bolder" }}>
                <td style={{ width: 20, minWidth: 20 }}>STEP</td>
                <td style={{ width: 140, minWidth: 140 }}>COMMAND/ACTION:</td>
                <td style={{ width: 80, minWidth: 80 }}>RESPONSE</td>
                <td style={{ width: 80, minWidth: 80 }}>PURPOSE</td>
                <td style={{ width: 80, minWidth: 80 }}>VERIFICATION</td>
                <td style={{ width: 10, minWidth: 10 }}>P/F</td>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: 25 }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style={{ height: 25 }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style={{ height: 25 }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style={{ height: 25 }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style={{ height: 25 }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style={{ height: 25 }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <tfoot>
              <tr style={{ height: 80, verticalAlign: "top" }}>
                <td>Test Director</td>
                <td>Date</td>
                <td>Customer Representative</td>
                <td>Date</td>
                <td>Site Representative</td>
                <td>Date</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="fake-list-container" style={{ marginTop: 24 }}>
          <p className="list-label">7. &nbsp; Test Completion Signature Page</p>
          <FieldWrapper height={50}>
            <EditableField {...elementProps} fieldName="7_test_completion_signature" />
          </FieldWrapper>
        </div>
      </div>
    </div>
  );
};
