import { FieldKeysEnum as FieldKeys } from "./reducer";
import ContentCard from "../../Shared/ContentCard";
import { SaveStatusEnum } from "components/Administration/Workflow/Editor/Shared/types";
import MdEditorCard, { MdCardActions } from "../../Shared/Markdown/MdEditorCard";
import { useEffect } from "react";

type Props = {
  title: string;
  textValue?: string | null;
  fieldKey: FieldKeys;
  mdEditorOpen: boolean;
  mdValue: string;
  clearMdValue: () => void;
  onMdChange: (value: string) => void;
  openMdEditor: () => void;
  closeMdEditor: () => void;
  setEditState: () => void;
  editingCurrentField: boolean;
  onSave: () => void;
  renderSaveStatus: boolean;
  saveStatus: SaveStatusEnum;
  loading: boolean;
  formValues: any; // todo: type this
  onValuesChange: (value: string, fieldKey: string) => void;
};

const QuestionTextEditor: React.FC<Props> = (props) => {
  const {
    title,
    textValue,
    fieldKey,
    mdEditorOpen,
    mdValue,
    clearMdValue,
    onMdChange,
    openMdEditor,
    closeMdEditor,
    setEditState,
    editingCurrentField,
    onSave,
    renderSaveStatus,
    saveStatus,
    loading,
    formValues,
    onValuesChange,
  } = props;

  const sharedProps = {
    setEditState,
    editingCurrentField,
    onSave,
    loading,
  };

  useEffect(() => {
    console.log("%cFormValsInQuestionTextEditor", "color:purple", { mdValue, formValues });
  }, [formValues, mdValue]);

  if (mdEditorOpen && editingCurrentField) {
    return (
      <MdEditorCard
        {...sharedProps}
        title={title}
        fieldKey={fieldKey}
        openMdEditor={openMdEditor}
        closeMdEditor={closeMdEditor}
        mdEditorOpen={mdEditorOpen}
        mdValue={mdValue}
        onMdChange={onMdChange}
        clearMdValue={clearMdValue}
      />
    );
  }

  return (
    <ContentCard
      {...sharedProps}
      title={title}
      fieldKey={fieldKey}
      key={fieldKey}
      onValuesChange={onValuesChange}
      formValues={formValues}
      editableField={true}
      renderSaveStatus={renderSaveStatus}
      saveStatus={saveStatus}
      extra={
        <MdCardActions
          {...sharedProps}
          openMdEditor={openMdEditor}
          closeMdEditor={closeMdEditor}
          mdEditorOpen={mdEditorOpen}
          clearMdValue={clearMdValue}
        />
      }
    >
      {textValue}
    </ContentCard>
  );
};

export default QuestionTextEditor;
