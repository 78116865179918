import React from "react";
import { useParams } from "react-router-dom";
import { sortAndMoveQuickStart } from "components/Applicant/Helpers/sortAndMoveQuickStart";
import ModuleCard from "./ModuleCard";
import { Card, List, Typography } from "antd";
import { ApplicationQuestionModuleNode } from "types";

interface Props {
  loading: boolean;
  appName: string | undefined;
  modules: Array<ApplicationQuestionModuleNode> | any;
}

const ApplicationModules: React.FC<Props> = ({ loading, appName, modules }) => {
  const { applicationId } = useParams();

  return (
    <>
      <Card className="shadowed-static" bodyStyle={{ padding: 15 }}>
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          Modules
        </Typography.Title>
      </Card>
      <br />
      <List
        grid={{
          gutter: 16,
          column: 3,
          xl: 2,
          lg: 2,
          md: 2,
          sm: 1,
          xs: 1,
        }}
        loading={loading}
        dataSource={sortAndMoveQuickStart(modules, "Quick Start")}
        renderItem={(mod: ApplicationQuestionModuleNode) => (
          <List.Item>
            <ModuleCard mod={mod} appId={applicationId} appName={appName} />
          </List.Item>
        )}
      />
    </>
  );
};

export default ApplicationModules;
