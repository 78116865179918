import { Select } from "antd";
import CustomFormItem from "components/Common/RFForm/CustomFormItem";

type SelectOption = {
  label: string | JSX.Element;
  value: string;
};

interface Props {
  input: {
    onChange: (value: any) => void;
    value: any;
  };
  meta: any;
  options: SelectOption[];
  label: string;
  width?: number | string;
  loading?: boolean;
} // ! cant get working

const SelectField = (props: any) => {
  const {
    input: { onChange, value },
    options,
    label,
    meta,
    width,
    loading,
    ...rest
  } = props;

  return (
    <CustomFormItem label={label}>
      <Select
        onChange={(value) => onChange(value)}
        loading={loading}
        options={options}
        style={width ? { width: width } : { width: "100%" }}
        dropdownMatchSelectWidth={false}
        value={value}
        disabled={loading}
        {...rest}
      />
    </CustomFormItem>
  );
};

export default SelectField;
