const saveStatusColor = (status) => {
  switch (status) {
    case "saving":
      return "steelblue";
    case "unsaving":
      return "steelblue";
    case "failed":
      return "red";
    case "saved":
      return "green";
    case "default":
      return "grey";

    default:
      return "transparent";
  }
};

export default saveStatusColor;
