import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { RiskLevelSelect } from "..";

import DOAFSeal from "../Assets/DOAFSeal.png";

export const P3 = (props) => {
  const { state } = props;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const riskLevelConfig = {
    label: "Select One",
    value: "bluf"
  };

  return (
    <>
      <div
        style={{
          fontSize: 48,
          textAlign: "center",
          fontWeight: "bold",
          width: "100%",
          margin: 0,
          color: "navy",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img style={{ width: "85px", margin: "10px" }} src={DOAFSeal} alt="" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Bottom line up front (BLUF)</span>
        </div>
      </div>

      <div
        style={{
          borderTop: "5px solid navy",
          borderBottom: "5px solid navy",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            padding: "0 40px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <div style={{ fontSize: 14, margin: "10px 0 0", display: "flex" }}>
                <b>Obtain an authorization determination for</b>
                <div style={{ height: "25px", width: "100px", margin: "0 5px" }}>
                  <EditableField {...elementProps} fieldName="bluf_system_name" />
                </div>
              </div>
              <div style={{ paddingLeft: "40px" }}>
                <div>
                  <p style={{ margin: "10px 0 2px" }}>
                    Outline mission need and strategic agenda
                  </p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField {...elementProps} fieldName="bluf_outline_mission_need" />
                  </div>
                  <ul>
                    <li>
                      Seeking an IATT to support the “XXX” training exercise scheduled for “day
                      month year” through “day month year”
                    </li>
                  </ul>
                </div>

                <div>
                  <p style={{ margin: "10px 0 2px" }}>Provide projected schedule</p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField
                      {...elementProps}
                      fieldName="bluf_provide_projected_schedule"
                    />
                  </div>
                </div>

                <div>
                  <p style={{ margin: "10px 0 2px" }}>Describe Assumptions and Constraints</p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField
                      {...elementProps}
                      fieldName="bluf_describe_assumptions_and_constraints"
                    />
                  </div>
                  <ul>
                    <li>Industry Partners/Services, Foreign Partners/Customers</li>
                    <li>Authorized interconnection between “XXX” and “YYY”</li>
                    <li>Network/Boundary Limitations</li>
                  </ul>
                </div>
              </div>

              <div style={{ fontSize: 14 }}>
                <b>Current Status</b>
              </div>
              <div style={{ paddingLeft: "40px" }}>
                <div>
                  <p style={{ margin: "10px 0 2px" }}>
                    List current or last approval, other circumstances
                  </p>
                  <div style={{ height: "25px", width: "300px", margin: "0 5px" }}>
                    <EditableField
                      {...elementProps}
                      fieldName="bluf_list_status_or_last_approval"
                    />
                  </div>
                  <ul>
                    <li>Approval and Expiration, under testing (IATT), etc</li>
                  </ul>
                </div>
              </div>

              <div style={{ fontSize: 14, margin: "10px 0 0", display: "flex" }}>
                <b>The overall level of residual risk of </b>
                <div style={{ height: "25px", width: "100px", margin: "0 5px" }}>
                  <EditableField {...elementProps} fieldName="bluf_system_name" />
                </div>
                <b> is assessed to be </b>
                <div style={{ height: "25px", width: "100px", margin: "0 5px" }}>
                  <RiskLevelSelect
                    elementProps={elementProps}
                    config={riskLevelConfig}
                    style={{ width: "150px", fontSize: 12 }}
                  />
                </div>
              </div>

              <br />
              <br />

              <div style={{ fontSize: 14, margin: "10px 0 0", display: "flex" }}>
                <b>The CRA recommends AO to support deployment of </b>
                <div style={{ height: "25px", width: "100px", margin: "0 5px" }}>
                  <EditableField {...elementProps} fieldName="bluf_system_name" />
                </div>
                <b> through </b>
                <div style={{ height: "25px", width: "100px", margin: "0 5px" }}>
                  <EditableField
                    {...elementProps}
                    fieldName="bluf_authorization_termination_date"
                  />
                </div>
                <span>.</span>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </>
  );
};
