import dayjs from "dayjs";
import { FulfillInputProps } from "../Questions/FulfillQuestion";
import { DatePicker } from "antd";

const FulfillDateRangeInput: React.FC<FulfillInputProps> = ({
  answerId,
  defaultAnswer,
  onChange,
  disabled,
  style,
}) => {
  const format = "DD MMM YY";

  const savedAnswerSplit = defaultAnswer?.split("-") || [];
  const savedAnswerStart = savedAnswerSplit[0];
  const savedAnswerEnd = savedAnswerSplit[1];
  const composedValue: any = defaultAnswer
    ? [dayjs(savedAnswerStart, format), dayjs(savedAnswerEnd, format)]
    : ["", ""];

  return (
    <>
      <DatePicker.RangePicker
        allowClear={false}
        id={answerId}
        format={format}
        defaultValue={composedValue}
        onChange={(dates, dateStrings) => {
          let answer = {
            id: answerId,
            text:
              dates?.[0]?.isValid() && dates?.[1]?.isValid()
                ? dateStrings[0] + "-" + dateStrings[1]
                : "",
          };

          onChange(answer);
        }}
        disabled={disabled}
        style={{
          display: "flex",
          width: "100%",
          ...style,
        }}
      />
    </>
  );
};

export default FulfillDateRangeInput;
