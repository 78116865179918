import { User } from "./operations/User";
import { UserRole } from "./operations/UserRole";
import { Permission } from "./operations/Permission";
import { CyberPlan } from "./operations/CyberPlan";
import { Document } from "./operations/Document";
import { Organization } from "./operations/Organization";
import { Application } from "./operations/Application";
import { ApplicationModule } from "./operations/ApplicationModule";
import { ApplicationCategory } from "./operations/ApplicationCategory";
import { ApplicationQuestion } from "./operations/ApplicationQuestion";
import { ApplicationCheckpoint } from "./operations/ApplicationCheckpoint";
import { ApplicationComment } from "./operations/ApplicationComment";
import { FormAssignment } from "./operations/FormAssignment";
import { Question } from "./operations/Question";
import { QuestionModule } from "./operations/QuestionModule";
import { QuestionCategory } from "./operations/QuestionCategory";
import { QuestionGroup, QGroup, QuestionGroupFragments } from "./operations/QuestionGroup";
import { ConditionalLogic } from "./operations/ConditionalLogic";
import { Integration } from "./operations/Integration";
import { SecurityFindings } from "./operations/SecurityFindings";
import { ApplicationDocuments } from "./operations/ApplicationDocuments";

const Api = {
  User,
  UserRole,
  Permission,
  CyberPlan,
  Document,
  Organization,
  Application,
  ApplicationModule,
  ApplicationCategory,
  ApplicationQuestion,
  ApplicationCheckpoint,
  ApplicationComment,
  ApplicationDocuments,
  FormAssignment,
  QuestionModule,
  QuestionCategory,
  QuestionGroup,
  ConditionalLogic,
  QGroup,
  Question,
  Integration,
  SecurityFindings,
  
};

export default Api;

export const Fragments = {
  QuestionGroup: QuestionGroupFragments,
};
