import { useParams } from "react-router-dom";

function isValidURL(str: string) {
  try {
    new URL(str);
    return true; // If URL constructor doesn't throw, it's a valid URL
  } catch (_) {
    return false; // Otherwise, it's not a valid URL
  }
}
// We are using named parameters for all consumed parameters from the URL
const useProtectedParams = () => {
  const params = useParams();
  const check = Object.entries(params).filter(([key, value]) => value && isValidURL(value));
  if (check.length > 0) throw new Error(`Invalid parameters passed: ${check.join(":")}`);
  return params;
};

export default useProtectedParams;
