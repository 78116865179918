import { gql } from "@apollo/client";

export const QuestionGroupFragments = {
  group: gql`
    fragment QuestionGroup on QuestionGroupNode {
      id
      header
      description
      groupType
      order
      categoryId
    }
  `,
  groupQuestions: gql`
    fragment QuestionGroupQuestions on QuestionGroupNode {
      gridNumRows
      gridNumCols
      columnLabels
      rowLabels
      questions {
        id
        question
        order
        questionType
        variableName
        helpText
        groupId
        groupOrder
      }
    }
  `,
};

export const QGroup = {
  getAllTheQuestionGroups: () => gql`
    ${QuestionGroupFragments.groupQuestions}
    ${QuestionGroupFragments.group}
    query allTheQuestionGroups($filter: QuestionGroupFilterInputType) {
      allQuestionGroups(filter: $filter) {
        categoryId
        id
        order
        ...QuestionGroup
        category {
          name
          id
        }
        ...QuestionGroupQuestions
      }
    }
  `,
  // _create: () => gql`
  //   ${QuestionGroupFragments.group}
  //   mutation _createQuestionGroup(
  //     $categoryId: String
  //     $header: String!
  //     $description: String!
  //     $groupType: GroupType!
  //     $order: Int
  //   ) {
  //     createQuestionGroup(
  //       categoryId: $categoryId
  //       header: $header
  //       description: $description
  //       groupType: $groupType
  //       order: $order
  //     ) {
  //      ...QuestionGroupFragments.group
  //     }
  //   }
  // `,
  // _update: () => gql`
  //   ${QuestionGroupFragments.group}
  //   mutation _updateQuestionGroup(
  //     $categoryId: String
  //     $description: String
  //     $groupType: GroupType
  //     $header: String
  //     $id: String!
  //     $order: Int
  //   ) {
  //     updateQuestionGroup(
  //       categoryId: $categoryId
  //       description: $description
  //       groupType: $groupType
  //       header: $header
  //       id: $id
  //       order: $order
  //     ) {
  //       ...QuestionGroupFragments.group
  //     }
  //   }
  // `,
  // // update multiple questions in a group at once
  // _bulkUpdate: () => gql`
  //   mutation _bulkUpdateGroup($groupId: String!, $questionIds: [String]!) {
  //     bulkUpdateGroup(groupId: $groupId, questionIds: $questionIds) {
  //       id
  //       variableName
  //       questionType
  //       question
  //       order
  //       helpText
  //       groupId
  //       groupOrder
  //     }
  //   }
  // `,
  // _delete: () => gql`
  //   mutation _deleteQuestionGroup($id: String!) {
  //     deleteQuestionGroup(id: $id) {
  //       deletedId
  //     }
  //   }
  // `,
};

export const QuestionGroup = {
  GetAll: () => gql`
    query allQuestionGroups($filter: QuestionGroupFilterInputType) {
      allQuestionGroups(filter: $filter) {
        categoryId
        id
        header
        order
        groupType
        description
        category {
          name
          id
        }
        questions {
          id
          variableName
          questionType
          question
          order
          helpText
          groupId
          groupOrder
        }
      }
    }
  `,
  GetBasicList: () => gql`
    query getBasicGroupList($filter: QuestionGroupFilterInputType) {
      allQuestionGroups(filter: $filter) {
        id
        header
        description
      }
    }
  `,

  GetOne: () => gql`
    query oneQuestionGroup($filter: QuestionGroupFilterInputType) {
      allQuestionGroups(filter: $filter) {
        id
        header
        order
        groupType
        description
        categoryId
      }
    }
  `,
  Create: () => gql`
    mutation createQuestionGroup(
      $categoryId: String
      $header: String!
      $description: String!
      $groupType: GroupType!
      $order: Int
      $columnLabels: [GridColumnLabelObject]
      $gridNumRows: Int
      $rowLabels: [String]
    ) {
      createQuestionGroup(
        categoryId: $categoryId
        header: $header
        description: $description
        groupType: $groupType
        order: $order
        columnLabels: $columnLabels
        gridNumRows: $gridNumRows
        rowLabels: $rowLabels
      ) {
        id
        header
        order
        groupType
        description
        categoryId
      }
    }
  `,
  Update: () => gql`
    mutation updateQuestionGroup(
      $categoryId: String
      $description: String
      $header: String
      $id: String!
      $order: Int
    ) {
      updateQuestionGroup(
        categoryId: $categoryId
        description: $description
        header: $header
        id: $id
        order: $order
      ) {
        id
        header
        order
        # groupType
        description
        categoryId
      }
    }
  `,
  BulkUpdate: () => gql`
    mutation bulkUpdateGroup($groupId: String!, $questionIds: [String]!) {
      bulkUpdateGroup(groupId: $groupId, questionIds: $questionIds) {
        id
        variableName
        questionType
        question
        order
        helpText
        groupId
        groupOrder
      }
    }
  `,
  Delete: () => gql`
    mutation deleteQuestionGroup($id: String!) {
      deleteQuestionGroup(id: $id) {
        deletedId
      }
    }
  `,
};
