import { Field, useFormState } from "react-final-form";

interface Props {
  when: string;
  is: any;
  children: any;
  string?: boolean;
  notNull?: boolean;
}

const Condition = ({ when, is, children }: { when: string; is: any; children: any }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export const MultiCondition = ({ condition, children }) => {
  const { values } = useFormState({ subscription: { values: true } });
  return condition(values) ? children : null;
};

export default Condition;
