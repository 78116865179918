import React, { useState, useEffect } from "react";
import { Drawer } from "antd";

const minWidth = 500;
const maxWidth = 1200;
let isResizing = false;

const ResizableDrawer = ({ children, ...props }) => {
  const [drawerWidth, setDrawerWidth] = useState<any>(undefined);

  const cbHandleMouseMove = React.useCallback(handleMousemove, []);
  const cbHandleMouseUp = React.useCallback(handleMouseup, []);

  useEffect(() => {
    setDrawerWidth(props.width);
  }, [props.open, props.width]);

  function handleMouseup(e) {
    if (!isResizing) return;
    isResizing = false;
    document.removeEventListener("mousemove", cbHandleMouseMove);
    document.removeEventListener("mouseup", cbHandleMouseUp);
  }

  function handleMousedown(e) {
    e.stopPropagation();
    e.preventDefault();
    isResizing = true;
    document.addEventListener("mousemove", cbHandleMouseMove);
    document.addEventListener("mouseup", cbHandleMouseUp);
  }

  function handleMousemove(e) {
    let offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);

    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setDrawerWidth(offsetRight);
    }
  }

  return (
    <Drawer {...props} width={drawerWidth}>
      {props.canResize && <div onMouseDown={handleMousedown} style={styles} />}
      {children}
    </Drawer>
  );
};

export default ResizableDrawer;

const styles: any = {
  zIndex: 100,
  width: "5px",
  cursor: "ew-resize",
  padding: "4px 0 0",
  borderTop: "1px solid #ddd",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
};
