import { Space, Tag } from "antd";

interface Props {
  status: string;
}

const SaveStatusTag: React.FC<Props> = ({ status }) => {
  switch (status) {
    case "saving":
      return (
        <Space>
          <StyledTag color="processing">Saving</StyledTag>
        </Space>
      );
    case "unsaving":
      return (
        <Space>
          <StyledTag color="processing">Unsaving</StyledTag>
        </Space>
      );
    case "failed":
      return (
        <Space>
          <StyledTag color="red">Failed</StyledTag>
        </Space>
      );
    case "saved":
      return (
        <Space>
          <StyledTag color="green">Saved</StyledTag>
        </Space>
      );
    case "default":
      return (
        <Space>
          <StyledTag color="grey">Default Answer</StyledTag>
        </Space>
      );
    case "toggled":
      return (
        <Space>
          <StyledTag color="default">Toggled</StyledTag>
        </Space>
      );

    default:
      return (
        <Space>
          <StyledTag color="default">Not Started</StyledTag>
        </Space>
      );
  }
};

export default SaveStatusTag;

const StyledTag: React.FC<{ color: string; children: any }> = ({ color, children }) => (
  <Tag color={color} style={{ marginRight: 0 }}>
    {children}
  </Tag>
);
