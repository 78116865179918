import React, { useEffect, useState } from "react";
import { Card, Space, Button, Popconfirm, Table, Divider, Switch } from "antd";
import {
  DeleteOutlined,
  DeleteTwoTone,
  InsertRowBelowOutlined,
  InsertRowRightOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { EditableColumn, DataRow, GridQuestionTypes as QuestionTypes, GridQuestionTypes } from "./Shared/types";
import ColumnHeader from "./Components/ColumnHeader";
import ColumnRenderer from "./Components/ColumnRenderer";
import "./../styles/gridEditor.css";
import QuestionTypeModal from "./Components/AnswerModal";
import { QuestionType } from "types";

const editableColClassName = "grid-editor-col";
const popconfirmIcon = <DeleteTwoTone twoToneColor="#eb2f96" />;

type Props = {
  cancelEditor: any;
  onChange?: (change: any) => void;
  initialValues?: any;
};

interface EditableRowProps {
  index: number;
}

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

// todo: manage col render conditions here?

const GridTableEditor: React.FC<Props> = ({
  cancelEditor,
  onChange = () => {},
  initialValues,
}) => {
  const [dataSource, setDataSource] = useState<DataRow[]>([
    {
      key: "row_1",
      label: "Row 1 label",
      ignore: false,
    },
    {
      key: "row_2",
      label: "Row 2 label",
      ignore: false,
    },
  ]);

  const [modalOpen, setModalOpen] = useState(false);
  const [columnIdx, setColumnIdx] = useState(2);
  const [rowIdx, setRowIdx] = useState(2);
  const [editingColumn, setEditingColumn] = useState<EditableColumn | null>(null);

  const rowCount = dataSource.length;
  const canAddRow = rowCount < 16;
  const canDeleteRow = rowCount > 2;

  // todo: write 'onLeave()' func to block leaving / tell user about loss of data

  const handleAddRow = () => {
    const rowIndex = rowIdx + 1;
    console.log("handleAddRow(): ", {
      columns: columns,
      dataSource,
      rowIndex,
      columnIndex: columnIdx,
    });
    const currentRows = [...dataSource];

    const newRow = {
      key: `row_${rowIndex}`,
      label: `Row  label`,
      ignore: false,
    };

    const newRows = [...currentRows, newRow];

    console.log({ currentRows, newRows });

    setRowIdx(rowIndex);
    setDataSource(newRows);
  };

  const handleDeleteRow = (key: React.Key) => {
    console.log("%chandleDeleteRow", "color:blue");
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const [columnState, setColumnState] = useState<EditableColumn[]>([
    {
      dataIndex: "col_1",
      name: "col_name_1",
      title: "Column label",
      className: editableColClassName,
      editable: true,
      labelValue: "Column label",
      questionType: QuestionType.FulfillText as GridQuestionTypes,
    },
    {
      dataIndex: "col_2",
      name: "col_name_2",
      title: "Column label",
      className: editableColClassName,
      editable: true,
      labelValue: "Column label",
      questionType: QuestionType.FulfillText as GridQuestionTypes,
    },
  ]);

  useEffect(() => {
    // mount effect
    if (initialValues?.dataSource.length > 0 && initialValues?.columnState.length > 0) {
      setDataSource(initialValues.dataSource);
      setColumnState(initialValues.columnState);
    }
  }, []);


  const addNewColumn = () => {
    console.log("addNewColumn(): ", { columnState });
    const currentColumns = [...columnState].filter((col) => col.dataIndex !== "operation");
    let columnIndex = columnIdx + 1;

    const newColumn = {
      dataIndex: `col_${columnIndex}`,
      name: `col_name_${columnIndex}`,
      title: "Column label",
      className: editableColClassName,
      editable: true,
      labelValue: "Column label",
      questionType: QuestionType.FulfillText as GridQuestionTypes,
    };

    const newColumns = [...currentColumns, newColumn];
    console.log("end of addNewColumn(): ", { currentColumns, newColumn, newColumns });

    setColumnIdx(columnIndex);
    setColumnState(newColumns);
  };

  const canAddColumn = columnState.length < 8;

  // should always be last
  const operationColumn = {
    title: "operation",
    dataIndex: "operation",
    width: "5%",
    align: "center",
    className: editableColClassName,
    editable: true,
    name: "operation",
    value: "operation",
    editing: false,
    render: (value, record: { key: React.Key }, index) => (
      <Popconfirm
        title="Confirm row delete"
        onConfirm={() => handleDeleteRow(record?.key)}
        okText="Confirm"
        icon={popconfirmIcon}
        placement="left"
      >
        <Button icon={<DeleteOutlined />} danger disabled={!canDeleteRow} size="small" />
      </Popconfirm>
    ),
  };

  let columnMap = columnState.map((col, colIndex) => {
    if (!col.editable) {
      return col;
    }
    return {
      title: (
        <ColumnHeader
          column={col}
          index={colIndex}
          labelValue={col.labelValue}
          questionType={col.questionType}
          columnState={columnState}
          setColumnState={setColumnState}
          dataSource={dataSource}
          setDataSource={setDataSource}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setEditingColumn={setEditingColumn}
        />
      ),
      render: (text, record: { key: React.Key }, index) => {
        // console.log("%cColumnRenderProps: ", "color:purple", {
        //   text,
        //   record,
        //   col,
        //   index,
        //   colIndex,
        // });

        return (
          <ColumnRenderer
            column={col}
            columnIndex={colIndex}
            record={record}
            dataSource={dataSource}
            setDataSource={setDataSource}
          />
        );
      },
      labelValue: col.labelValue,
      questionType: col.questionType,
    };
  });

  const columns = [...columnMap, operationColumn];
  // const columns = [];

  const data = {
    dataSource,
    columnState,
  };

  // useEffect(() => {
  //   console.log({ columnState });
  // }, [columnState]);

  // useEffect(() => {
  //   console.log({ dataSource });
  // }, [dataSource]);

  const saveGrid = () => {
    onChange(data);
    /*
		todo: validate required fields
			- All column labels must have a value
		*/
  };

  return (
    <Card
      extra={
        <Space>
          <Button
            onClick={addNewColumn}
            disabled={!canAddColumn}
            icon={<InsertRowRightOutlined />}
          >
            Add column
          </Button>
          <Button
            onClick={handleAddRow}
            disabled={!canAddRow}
            icon={<InsertRowBelowOutlined />}
          >
            Add row
          </Button>
          <Divider type="vertical" />
          <Button onClick={cancelEditor} icon={<DeleteOutlined />} danger>
            Cancel Grid Creation
          </Button>
          <Button onClick={saveGrid} type="primary" icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      }
      className="grid-editor-card shadowed-static"
      bodyStyle={{ padding: 12, height: 600 }}
    >
      <Table
        dataSource={dataSource}
        // onChange={(pagination, filters, sorter, extra) => {
        //   console.log("onTableChange: ", { pagination, filters, sorter, extra });
        // }}
        rowClassName={() => "editable-row"}
        columns={columns as ColumnTypes}
        pagination={false}
        scroll={{ x: 600 }}
        bordered
      />
      {modalOpen && (
        <QuestionTypeModal
          open={modalOpen}
          columnState={columnState}
          setColumnState={setColumnState}
          rowState={dataSource}
          editingColumn={editingColumn}
          setEditingColumn={setEditingColumn}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Card>
  );
};

GridTableEditor.defaultProps = {
  onChange() {},
};

export default GridTableEditor;
