import { useEffect, useRef, useState } from "react";
import { Form, Input, InputRef } from "antd";

interface Props {
  title?: string | React.ReactNode | any; // ? might not need this, component does not use
  editable: boolean;
  children?: React.ReactNode | any;
  dataIndex: string;
  columns?: any;
  setColumnState: any;
  setEditingLabel: (status: boolean) => void;
}

const EditableHeadCell: React.FC<Props> = (props) => {
  const {
    title,
    editable,
    dataIndex,
    setColumnState,
    columns = {},
    setEditingLabel,
    ...restProps
  } = props;
  const [editing, setEditing] = useState(true);
  const inputRef = useRef<InputRef>(null);
  const currentColumns = [...columns];
  const currentColumn = currentColumns.find((column) => column.dataIndex === dataIndex);
  const value = currentColumn?.labelValue;
  console.log("%cEditableHeadCellProps", "color:blue", {
    title,
    dataIndex,
    value,
    columns,
  });

  const trueColumns = columns.filter((col) => col.dataIndex !== "operation");
  const canDeleteColumn = trueColumns.length > 2;

  console.log({ canDeleteColumn, trueColumns, columns });

  useEffect(() => {
    if (editing) {
      console.log({ value, inputRef });
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    console.log("toggleEdit: ", { dataIndex, inputRef });
    setEditing(!editing);
    setEditingLabel(!editing);
  };

  const save = (e) => {
    try {
      console.log("onSave: ", {
        event: e.target.value,
        dataIndex,
        columns,
      });

      toggleEdit();
      const updateColumns = currentColumns.map((column) => {
        if (column.dataIndex === dataIndex) {
          return {
            ...column,
            labelValue: e.target.value,
          };
        }
        return column;
      });

      console.log({ currentColumns, currentColumn, updateColumns });

      setColumnState(updateColumns);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const onBlurSave = (e) => {
    if (currentColumn?.labelValue.length > 1 && e.target.value == "") {
      toggleEdit();
      return;
    }

    save(e);
  };

  let childNode = value;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0, width: "100%" }} name={dataIndex}>
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={onBlurSave}
          value={value}
          defaultValue={value}
          autoComplete="off"
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {value}
      </div>
    );
  }

  if (dataIndex === "operation") {
    return <th>{childNode}</th>;
  }

  return <div {...restProps}>{childNode}</div>;
};

export default EditableHeadCell;
