import { useEffect, useState } from "react";
import hasAnswerChange from "components/Applicant/Helpers/hasAnswerChange";
import useQuestion from "components/Applicant/Hooks/useQuestion";
import GridQuestion from "../GridQuestion";
import { ApplicationQuestionNode } from "types";

export interface FulfillInputProps {
  answerId: string;
  defaultAnswer: string;
  onChange: (answer: { id: string; text: string }) => void;
  disabled: boolean;
  style?: any;
}

interface Props {
  input: React.FC<FulfillInputProps>;
  defaultAnswer: string;
  question: ApplicationQuestionNode;
}

const FulfillQuestion: React.FC<Props> = ({ input, defaultAnswer, question }) => {
  const [saveStatus, setSaveStatus] = useState<string>("");

  const { saveFulfillAnswer } = useQuestion(question, setSaveStatus);

  const Input = input;
  const hasDefaultAnswers = question.isSavedWithDefaults!;
  const hasSavedAnswers = question.isAnswered || question.savedAnswers.length > 0;

  const answerId = question.answerChoices[0]?.id!;
  const isLocked = question.isLocked || hasDefaultAnswers;

  useEffect(() => {
    setSaveStatus(hasDefaultAnswers ? "default" : hasSavedAnswers ? "saved" : "");
  }, [question]);

  const onChange = (answer: { id: string; text: string }) => {
    const change = hasAnswerChange(question, answer);

    if (!change) return;
    if (isLocked) return;

    saveFulfillAnswer(question.id, answer);
  };

  return (
    <GridQuestion question={question} saveStatus={saveStatus}>
      <Input
        answerId={answerId}
        defaultAnswer={defaultAnswer}
        onChange={onChange}
        disabled={isLocked}
      />
    </GridQuestion>
  );
};

export default FulfillQuestion;
