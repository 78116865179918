import { useState } from "react";
import useLocalStorage from "./useLocalStorage";
import { useMutation } from "@apollo/client";
import Api from "api";
import { message } from "antd";

/**
 * References:
 *
 * Docs
 * https://netristsmartbridge.com/api-documentation
 */

const useCommonAccessCard = () => {
  const [auth, setAuth] = useLocalStorage("authorization", { authorized: false });
  const [cacLoginLoading, setCacLoginLoading] = useState(false);
  const [cacAssociationLoading, setCacAssociationLoading] = useState(false);

  // ASSOCIATE CAC

  const [associateCac] = useMutation(Api.User.AssociateCac(), {
    onCompleted: (res) => {
      console.log("%cCac: Associated", "color:red");
      setCacAssociationLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
      setCacAssociationLoading(false);
    },
    refetchQueries: [Api.User.GetUserCac()],
  });

  const associate = async () => {
    setCacAssociationLoading(true);

    const token = await postCacToSmartBridge();

    if (!token) {
      return setCacAssociationLoading(false);
    }

    associateCac({
      variables: {
        token,
      },
    });
  };

  // DIS-ASSOCIATE CAC

  const [disAssociateCac] = useMutation(Api.User.DisAssociateCac(), {
    onCompleted: (res) => {
      console.log("%cCac: DisAssociated", "color:red");
      setCacAssociationLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
      setCacAssociationLoading(false);
    },
    refetchQueries: [Api.User.GetUserCac()],
  });

  const disAssociate = () => {
    setCacAssociationLoading(true);
    disAssociateCac();
  };

  // LOGIN WITH CAC

  const [loginWithCsarFromCac] = useMutation(Api.User.LoginWithCac(), {
    onCompleted: (res) => {
      console.log("%cCacLogin: Setting Authorization", "color:red");
      setAuth({ authorized: true });

      setCacLoginLoading(false);
      setTimeout(() => {
        window.location.href = "/home";
      }, 250);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
      setCacLoginLoading(false);
    },
  });

  const loginWithCac = async () => {
    setCacLoginLoading(true);

    const token = await postCacToSmartBridge();

    if (!token) {
      return setCacLoginLoading(false);
    }

    loginWithCsarFromCac({
      variables: {
        token,
      },
    });
  };

  return {
    loginWithCac,
    cacLoginLoading,
    associate,
    disAssociate,
    cacAssociationLoading,
  };
};

export default useCommonAccessCard;

const postCacToSmartBridge = async () => {
  const Domain = `https://csar-cac.netristsmartbridge.com`;

  // console.log({
  //   callback: process.env.REACT_APP_SMARTBRIDGE_CALLBACK,
  //   clientId: process.env.REACT_APP_SMARTBRIDGE_CLIENT_ID,
  // });

  const res = await fetch(Domain + "/cac-login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      callbackUrl: process.env.REACT_APP_SMARTBRIDGE_CALLBACK,
      clientId: process.env.REACT_APP_SMARTBRIDGE_CLIENT_ID,
    }),
  });
  const data = await res.json();

  const success = data.success === "true" ? true : false;

  if (!success) {
    console.log("%cCacLogin: Smartbridge failed", "color:red");
    message.error(data.error);
    return undefined;
  } else {
    console.log("%cCacLogin: Smartbridge success", "color:red");
    return data.jwt;
  }
};
