import React, { createContext, useContext } from "react";
import { useAuthContext } from "./WithAuth";
import useEnumPresentation from "hooks/useEnumPresentation";

const PermissionContext = createContext<any>(undefined);

export const usePermissionContext = () => useContext(PermissionContext);

const PermissionProvider: React.FC<any> = ({ children }) => {
  const { PresentRoleName } = useEnumPresentation();
  const { user } = useAuthContext();
  const userPermissions = user.permissions.map((p: { permission: string }) => p.permission);
  const userRole = user.role;

  if (user.id) {
    console.log({ UserRole: PresentRoleName(user.role) });
    console.log({ Permissions: userPermissions });
  }

  const hasPermission = (permission: Array<string>) => userPermissions.includes(permission);
  const hasRole = (role: Array<string>) => userRole === role;

  return (
    <PermissionContext.Provider value={{ hasPermission, hasRole }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
