import { useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";
import { Button, Col, Dropdown, Menu, message, Popconfirm, Row, Tag, Typography } from "antd";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import {
  GetAllQuestionCategoriesQuery,
  QuestionGroupNode,
  UpdateQuestionGroupMutation,
} from "types";
import { GroupEditorState, GroupFieldKeysEnum as FieldKeysEnum } from "./reducer";
import Api from "api";
import ParentOrderingField from "../../Shared/ParentOrderingField";
import ParentSelectField from "../../Shared/ParentSelectField";
import SidebarSection, { SidebarManageButton } from "../../Shared/SidebarSection";
import { SaveStatusEnum } from "../../Shared/types";
import useEditorLoading from "hooks/useEditorLoading";

const { Text } = Typography;

type Props = {
  group: QuestionGroupNode;
  state: GroupEditorState;
  loading: Loading;
  setEditState: (arg0: FieldKeysEnum) => void;
  setSaveState: (arg0: FieldKeysEnum, arg1: SaveStatusEnum) => void;
  updateGroup: any;
  refetchGroup: any;
};

type OrderFieldProps = {
  group: QuestionGroupNode;
  state: GroupEditorState;
  loading: Loading;
  setEditState: (arg0: FieldKeysEnum) => void;
  setSaveState: (arg0: FieldKeysEnum, arg1: SaveStatusEnum) => void;
  updateGroup: any;
};

type CategorySelectProps = {
  updateGroup: any;
  group: QuestionGroupNode;
  state?: any;
  refetchGroup: any;
  setEditState: (arg0: FieldKeysEnum) => void;
  setSaveState: (arg0: FieldKeysEnum, arg1: SaveStatusEnum) => void;
};

const ParentInformation: React.FC<Props> = ({
  group,
  updateGroup,
  refetchGroup,
  state,
  loading,
  setEditState,
  setSaveState,
}) => {
  const editingParentAssignment: boolean = state[FieldKeysEnum.parentCategory].editActive;

  return (
    <>
      <SidebarSection
        title="Parent Category"
        loading={loading}
        renderSaveStatus={
          state[FieldKeysEnum.parentCategory].saveStatus !== SaveStatusEnum.Default
        }
        saveStatus={state[FieldKeysEnum.parentCategory].saveStatus}
        valueLoading={
          state[FieldKeysEnum.parentCategory].saveStatus === SaveStatusEnum.Loading
        }
        actionMenu={
          <ParentAssignmentDropdown
            group={group}
            updateGroup={updateGroup}
            refetchGroup={refetchGroup}
            setEditState={setEditState}
            setSaveState={setSaveState}
            fieldKey={FieldKeysEnum.parentCategory}
            state={state}
            loading={loading}
          />
        }
      >
        {editingParentAssignment ? (
          <CategorySelectField
            group={group}
            updateGroup={updateGroup}
            refetchGroup={refetchGroup}
            setEditState={setEditState}
            setSaveState={setSaveState}
          />
        ) : (
          <ParentNameField categoryName={group?.category?.name} />
        )}
      </SidebarSection>
      <ParentOrderField
        group={group}
        state={state}
        loading={loading}
        setEditState={setEditState}
        setSaveState={setSaveState}
        updateGroup={updateGroup}
      />
    </>
  );
};

const ParentOrderField: React.FC<OrderFieldProps> = (props) => {
  const [orderVal, setOrderVal] = useState<number | undefined>(undefined);

  const { loading, group, setSaveState, setEditState, state, updateGroup } = props;

  const handleOrderChange = (e: any) => {
    setOrderVal(Number(e.target.value));
  };

  const saveParentOrder = () => {
    if (!orderVal) return;
    if (orderVal !== group?.order) {
      setEditState(FieldKeysEnum.order);
      setSaveState(FieldKeysEnum.order, SaveStatusEnum.Loading);
      updateGroup({
        variables: {
          id: group.id,
          order: orderVal,
        },
        onCompleted: (res: UpdateQuestionGroupMutation) => {
          console.log(res);
          setSaveState(FieldKeysEnum.order, SaveStatusEnum.Success);
        },
        onError: (error: ApolloError) => {
          console.log(error.message);
          message.error(error.message, 5);
          setSaveState(FieldKeysEnum.order, SaveStatusEnum.Error);
        },
      });
    }
  };
  const parentOrderLoading = useEditorLoading(FieldKeysEnum.order, state, loading);
  const editingCurrentField: boolean = state[FieldKeysEnum.order].editActive;

  return (
    <SidebarSection
      title="Category Order"
      loading={loading}
      valueLoading={loading || parentOrderLoading}
      renderSaveStatus={state[FieldKeysEnum.order].saveStatus !== SaveStatusEnum.Default}
      saveStatus={state[FieldKeysEnum.order].saveStatus}
      actionMenu={
        <SidebarManageButton
          setEditState={setEditState}
          editingCurrentField={state[FieldKeysEnum.order].editActive}
          fieldKey={FieldKeysEnum.order}
          tooltip="The order this group appears in its Category"
          loading={parentOrderLoading}
        />
      }
    >
      {editingCurrentField ? (
        <Row align="top" justify="space-between">
          <Col span={20}>
            <ParentOrderingField
              value={orderVal}
              defaultValue={group?.order}
              onChange={(e: any) => handleOrderChange(e)}
              saveValue={saveParentOrder}
              styles={{ width: 85, height: "32.25px !important" }}
            />
          </Col>
        </Row>
      ) : (
        <Text>{group?.order}</Text>
      )}
    </SidebarSection>
  );
};

const ParentAssignmentDropdown: React.FC<any> = ({
  group,
  updateGroup,
  setSaveState,
  setEditState,
  refetchGroup,
  state,
  loading,
  fieldKey,
}) => {
  const removeGroupFromCategory = () => {
    setSaveState(FieldKeysEnum.parentCategory, SaveStatusEnum.Loading);
    updateGroup({
      variables: {
        id: group.id,
        categoryId: "CLEAR",
      },
      onCompleted: (res: UpdateQuestionGroupMutation) => {
        setSaveState(FieldKeysEnum.parentCategory, SaveStatusEnum.Success);
        refetchGroup();
      },
      onError: (error: ApolloError) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.parentCategory, SaveStatusEnum.Error);
      },
    });
  };

  const handleParentAssignmentMenu = (key: string) => {
    console.group("handleParentAssignmentMenu");
    console.log({ key });
    if (!key) return;
    if (key === "detachCategory") return;
    setEditState(FieldKeysEnum.parentCategory);
    console.groupEnd();
  };

  const resetEditing = () => {
    setEditState(FieldKeysEnum.parentCategory);
  };

  const menuItems = group?.categoryId
    ? [
        {
          label: "Change Category",
          key: "changeCategory",
        },
        {
          label: (
            <Popconfirm
              title="Confirm detach from Category"
              okText="Remove"
              cancelText="Cancel"
              onConfirm={removeGroupFromCategory}
            >
              <a href="categoryRemove">Remove from Category</a>
            </Popconfirm>
          ),
          key: "detachCategory",
          danger: true,
        },
      ]
    : [
        {
          label: "Attach to Category",
          key: "attachCategory",
        },
      ];

  const parentAssignmentMenu = (
    <Menu items={menuItems} onClick={(e) => handleParentAssignmentMenu(e.key)} />
  );

  const editingThisField: boolean = state[FieldKeysEnum.parentCategory].editActive;

  const parentAssignmentLoading = loading
    ? loading
    : state[fieldKey].saveStatus === SaveStatusEnum.Loading
    ? true
    : false;

  if (editingThisField) {
    return <Button icon={<CloseOutlined />} size="small" onClick={resetEditing} />;
  } else {
    return (
      <Dropdown overlay={parentAssignmentMenu}>
        <Button icon={<SettingOutlined />} size="small" disabled={parentAssignmentLoading} />
      </Dropdown>
    );
  }
};

const CategorySelectField: React.FC<CategorySelectProps> = ({
  updateGroup,
  setSaveState,
  setEditState,
  group,
  refetchGroup,
}) => {
  const [newCategory, setNewCategory] = useState<undefined | string>(undefined);

  const { data: allCategories, loading: categoriesLoading } =
    useQuery<GetAllQuestionCategoriesQuery>(Api.QuestionCategory.GetBasicList(), {
      onCompleted: (res) => {
        console.log(res);
      },
      onError: (error) => {
        message.error(error.message, 5);
        console.log(error.message);
      },
    });

  const handleChangeCategory = () => {
    setEditState(FieldKeysEnum.parentCategory);
    setSaveState(FieldKeysEnum.parentCategory, SaveStatusEnum.Loading);
    updateGroup({
      variables: {
        id: group.id,
        categoryId: newCategory,
      },
      onCompleted: (res: UpdateQuestionGroupMutation) => {
        setSaveState(FieldKeysEnum.parentCategory, SaveStatusEnum.Success);
        refetchGroup();
      },
      onError: (error: ApolloError) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.parentCategory, SaveStatusEnum.Error);
      },
    });
  };

  return (
    <ParentSelectField
      options={allCategories?.allQuestionCategories.map((category: any) => ({
        label: category?.name,
        value: category?.id,
      }))}
      value={newCategory}
      loading={categoriesLoading}
      onChange={setNewCategory}
      onSave={handleChangeCategory}
    />
  );
};

const ParentNameField = ({ categoryName }: { categoryName?: string | undefined }) => {
  if (!categoryName) {
    return <Tag>Not Assigned</Tag>;
  }
  return <Tag key={categoryName}>{categoryName}</Tag>;
};
export default ParentInformation;
