import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Row, Col } from "antd";

import pages from "./Pages";
export const PCILPages = pages;

export const PCILHeader = () => {
  return (
    <Row justify="space-between" style={{ marginTop: 20, color: "grey", fontSize: ".75rem" }}>
      <i>PII Confidentiality Impact Level Categorization Worksheet</i>
      <i>v.1.1 (February 2018)</i>
    </Row>
  );
};

export const PCILFooter = ({ state, pageNumber, totalPages }) => {
  const style = {
    paddingTop: 5,
    fontSize: 11,
  };

  const document = state?.document;

  return (
    <Row justify="space-between">
      <Col span={12}>
        <p style={style}>USAF PCIL Form v.1.1 (February 2018)</p>
      </Col>
      <Col span={12}>
        <p style={{ ...style, textAlign: "right" }}>
          Page: {pageNumber} of {totalPages}
        </p>
      </Col>
    </Row>
  );
};

export const RiskLevelSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "HIGH", value: `${config.value}_high` },
        { label: "MODERATE", value: `${config.value}_moderate` },
        { label: "LOW", value: `${config.value}_low` },
      ]}
    />
  );
};
