export const P18 = (props) => {
  return (
    <>
      <div className="itcsc" style={{ display: "grid", placeItems: "center" }}>
        <div
          className="instructions"
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "1.4rem", fontWeight: "bold" }}>INSTRUCTIONS</h1>
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "10px 20px",
              textAlign: "left",
            }}
          >
            <div style={{ width: "48%", marginRight: "40px" }}>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                Aggregation of all data, plus the potential impact and likelihood of a security
                issue arising from mishandling or misuse of that data, should factor in the
                assessment of all decisions within the ITCSC.
              </p>
              <p style={{ fontWeight: "bold" }}>General: CUI Designation Indicator Box</p>
              <p>
                If the completed ITCSC Form includes any CUI information or material, complete
                the CUI Designation Indicator Box on the bottom of Page 1:
              </p>
              <p>
                First Line: Identify the Office making the determination. Second line: Identify
                all types of CUI contained in the document.
              </p>
              <p>
                Third line: Identify the distribution statement or the dissemination controls
                applicable to the document.
              </p>
              <p>
                Fourth line: Identify phone number or office mailbox for the originating DoD
                Component or authorized CUI holder.
              </p>
              <i>See Ref: (b) for more information.</i>
              <p style={{ fontWeight: "bold" }}>
                General: CUI Warning Box for Classified Material
              </p>
              <div>
                If the ITCSC Form includes classified information or material, complete CUI
                Warning Box to alert to the presence of CUI in a classified DoD document.
              </div>
              <i>See Ref: (b) for more information.</i>
              <p style={{ fontWeight: "bold" }}>General: Section Marking</p>
              <div>Select classification for each Section, including the Summary.</div>
              <i> See Ref: (z, Enclosure 3) for more information.</i>
              <p style={{ fontWeight: "bold" }}>
                General: Overall Document (ITCSC) Marking Classification
              </p>
              <div>
                Select marking classification (header and footer). If ITCSC Form is classified,
                complete the Classification Authority Block on Page 1.
              </div>
              <i>See Ref: (z, Enclosure 3) for more information.</i>
            </div>
            <div style={{ width: "52%" }}>
              <p style={{ fontWeight: "bold" }}>1. System Identification Information</p>
              <p>
                System Name: The name of the System entered here MUST match the Investment Name
                being entered during Information Technology (IT) Investment Portfolio Suite
                (ITIPS) registration.
              </p>
              <p>
                System Acronym: The same acronym entered in ITIPS as the Investment Acronym.
              </p>
              <p>Version: Version number of the System.</p>
              <p>
                First Categorization Submission: Select “Yes” if first submission. Otherwise,
                select “No.”
              </p>
              <p>
                Enterprise Mission Assurance Support Service (eMASS) ID: Enter eMASS
                registration number. Otherwise, enter “0000000, ” if the System has been
                registered in eMASS. eMASS ID numbers may be required if{" "}
              </p>
              <ul>
                <li>Previous System registration in ITIPS or eMASS exists</li>
                <li>
                  <p>
                    You are following the Assess Only Process and integrating a product into a
                    host environment (i.e., the product is a PIT subsystem).
                  </p>
                  <p>
                    <i>See Ref: (o) for more information</i>
                  </p>
                </li>
              </ul>
              <p>
                eMASS Classification: Select the eMASS classification for archiving/storing
                System authorization artifacts.
              </p>
              <p>
                ITIPS ID: Enter ITIPS ID number. Otherwise, enter “0000000”, if the System has
                not been registered in ITIPS.
              </p>
              <p>
                DoD Information Technology Portfolio Repository (DITPR) ID: Enter the DITPR ID
                number. Otherwise, enter “0000000,” if the System has been assigned a DITPR ID
                number.
              </p>
              <p>
                Air Force ID (AFID): Unique identifier given to Special Access Program (SAP)
                programs to account for them, but anonymize the data so the program name is not
                known.
              </p>
              <p>RMF Action: Select the appropriate RMF action for the System:</p>
              <ul>
                <li>Assess Only - Assess and Incorporate</li>
                <li>Assess & Authorize</li>
                <li>Assess Only - Assess and Approve for Use and Inherit Process</li>
              </ul>
              <p>
                No Personally Identifiable Information (PII)/Protected Health Information (PHI)
                determination: The Department of the Air Force (DAF) identifies a System as not
                collecting PII, if it is collecting only rolodex information (e.g., name,
                Electronic Data Interchange Personal Identifier (EDIPI), business phone,
                business address, and rank) and it is only using it for authentication in a
                user table.
              </p>
              <i>
                NOTE 17: If the PII is used in any other manner or stored elsewhere, the DAF
                determines it a PII collection by the system, and a Privacy Impact Assessment
                (DD FORM 2930) is required.
              </i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
