import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Col, Row } from "antd";

export const P7 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pia"
        style={{
          width: "100%",
          fontSize: 10,
        }}
      >
        <Row justify="center">
          <div>
            <b>
              <u>SECTION 4: REVIEW AND APPROVAL SIGNATURES</u>
            </b>
          </div>
        </Row>
        <Row>
          <i>
            Completion of the PIA requires coordination by the program manager or designee
            through the information system security manager and privacy representative at he
            focal level. Mandatory coordinators are: Component CIO, Senior Component Official
            for Privacy, Component Senior Information Security Officer, and Component Records
            Officer.
          </i>
        </Row>
        <br />

        <Row justify="space-between">
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>
                  <b style={{ fontSize: 10 }}>a. Program Manager or Designee Name</b>
                </center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_a_program_manager"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(1) Title</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_a_title"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(2) Organization</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_a_organization"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(3) Work Telephone</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_a_telephone"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(4) DSN</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_a_dsn"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(5) Email Address</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_a_email_address"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            paddingTop: 2,
            paddingBottom: 2,
            marginBottom: 2,
            borderBottom: "1px solid black",
          }}
        >
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(6) Date of Review</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_a_date_of_review"
                  style={{ height: 50, width: "100%" }}
                  isDate
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(7) Signature</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_a_signature"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>
                  <b style={{ fontSize: 10 }}>
                    b. Other Official (to be used at Component discretion)
                  </b>
                </center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_b_other_official"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(1) Title</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_b_title"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(2) Organization</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_b_organization"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(3) Work Telephone</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_b_telephone"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(4) DSN</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_b_dsn"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(5) Email Address</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_b_email_address"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            paddingTop: 2,
            paddingBottom: 2,
            marginBottom: 2,
            borderBottom: "1px solid black",
          }}
        >
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(6) Date of Review</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_b_date_of_review"
                  style={{ height: 50, width: "100%" }}
                  isDate
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(7) Signature</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_b_signature"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>
                  <b style={{ fontSize: 10 }}>
                    c. Other Official (to be used at Component discretion)
                  </b>
                </center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_c_other_official"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(1) Title</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_c_title"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(2) Organization</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_c_organization"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(3) Work Telephone</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_c_telephone"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(4) DSN</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_c_dsn"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(5) Email Address</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_c_email_address"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            paddingTop: 2,
            paddingBottom: 2,
            marginBottom: 2,
            borderBottom: "1px solid black",
          }}
        >
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(6) Date of Review</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_c_date_of_review"
                  style={{ height: 50, width: "100%" }}
                  isDate
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(7) Signature</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_c_signature"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>
                  <b style={{ fontSize: 10 }}>d. Component Privacy Official (CPO)</b>
                </center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_d_other_official"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(1) Title</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_d_title"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(2) Organization</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_d_organization"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(3) Work Telephone</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_d_telephone"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(4) DSN</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_d_dsn"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(5) Email Address</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_d_email_address"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(6) Date of Review</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_d_date_of_review"
                  style={{ height: 50, width: "100%" }}
                  isDate
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(7) Signature</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="7_d_signature"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
