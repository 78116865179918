import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useAccessControl, { Roles } from "hooks/useAccessControl";
import useCheckpoint from "components/Applicant/Hooks/useCheckpoint";
import DashboardCard, {
  DashboardCardCheckpointStatus,
  DashboardCardStatus,
} from "../../Components/Dashboards/DashboardCard";
import { ProfileOutlined, RocketOutlined } from "@ant-design/icons";
import { Col, Tooltip, Divider, Row, Button } from "antd";
import { ApplicationQuestionCategoryNode } from "types";
import useProtectedParams from "hooks/useProtectedParams";

interface Props {
  modName: string;
  category: ApplicationQuestionCategoryNode;
  openCardId: string | null;
  toggleOpenCardId: (id: string) => void;
}

const CategoryCard: React.FC<Props> = (props) => {
  const { modName, category: cat, openCardId, toggleOpenCardId } = props;
  const { applicationId, moduleId } = useProtectedParams();

  const location: any = useLocation();
  const applicationName = location.state ? location.state.applicationName : "Application";

  const [category, setCategory] = useState<any>({
    allQuestions: [],
    checkpoint: null,
    isLocked: false,
    isComplete: false,
  });

  useEffect(() => {
    setCategory(cat);
  }, []);

  const { RBAC } = useAccessControl();

  const closeOpenCard = () => toggleOpenCardId(openCardId!);

  const updateCategory = (updatedCategory) => {
    setCategory((c) => ({
      ...c,
      ...updatedCategory,
    }));
  };

  const { createCheckpoint, deleteCheckpoint, toggleQuestionsLock } = useCheckpoint(
    updateCategory,
    closeOpenCard,
  );

  const totalCount = category.numQuestions!;
  const savedCount = category.numAnsweredQuestions!;
  const checkpointActive = category.checkpoint !== null;
  const categoryLocked = category.isLocked!;

  const cardControlsActive = openCardId === category.id;

  const actions = [
    // eslint-disable-next-line jsx-a11y/aria-role
    <div onClick={() => toggleOpenCardId(category.id)}>
      {cardControlsActive ? "Back" : "More"}
    </div>,
    <Link
      to={`/workflow/${applicationId}/${moduleId}/${category.id}/1`}
      state={{
        applicationName: applicationName,
        moduleName: modName,
        categoryName: category.name,
      }}
    >
      Start
    </Link>,
  ];

  const CheckpointControls = () => {
    const AdminControls = () => (
      <Row justify="center" style={{ width: "100%" }} gutter={10}>
        <Col>
          {checkpointActive ? (
            <Tooltip title="Delete Checkpoint">
              <Button
                style={{ marginBottom: 10 }}
                onClick={() => deleteCheckpoint(category.id)}
                danger={checkpointActive}
              >
                Delete
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Create Checkpoint">
              <Button type="primary" onClick={() => createCheckpoint(category.id)}>
                Create
              </Button>
            </Tooltip>
          )}
        </Col>
        <Col>
          {checkpointActive && (
            <Tooltip title={`${categoryLocked ? "Unlock" : "Lock"} Questions in category`}>
              <Button
                onClick={() => toggleQuestionsLock(category.id, !categoryLocked)}
                danger={categoryLocked}
              >
                {categoryLocked ? "Unlock" : "Lock"}
              </Button>
            </Tooltip>
          )}
        </Col>
      </Row>
    );

    const ApplicantControls = () => (
      <Row justify="center" style={{ width: "100%" }} gutter={10}>
        <Col>
          <Button style={{ marginBottom: 10 }} onClick={() => {}}>
            Get Help
          </Button>
        </Col>
        <Col>{checkpointActive && <Button onClick={() => {}}>Unlock Request</Button>}</Col>
      </Row>
    );

    const isAdmin = RBAC([Roles.GlobalAdmin, Roles.OrganizationAdmin, Roles.CsarAdvisor]);

    return (
      <Row justify="center">
        <b style={{ paddingBottom: 10 }}>Checkpoint</b>
        {isAdmin ? <AdminControls /> : <ApplicantControls />}
      </Row>
    );
  };

  return (
    <DashboardCard
      title={category.name}
      actions={actions}
      progress={(savedCount / totalCount) * 100}
    >
      <CardIcon category={category} />
      <Divider />
      <div style={{ minHeight: 80 }}>
        {cardControlsActive ? (
          <CheckpointControls />
        ) : (
          <Col style={{ padding: 0 }}>
            <QuestionStatus total={totalCount} saved={savedCount} />
            <DashboardCardCheckpointStatus isActive={checkpointActive} />
            <DashboardCardStatus
              type="Category"
              isLocked={categoryLocked}
              isComplete={savedCount === totalCount}
              inProgress={savedCount >= 1}
            />
          </Col>
        )}
      </div>
    </DashboardCard>
  );
};

export default CategoryCard;

const CardIcon: React.FC<{ category: ApplicationQuestionCategoryNode }> = ({ category }) => {
  let Icon = ProfileOutlined;
  if (category.name === "Quick Start") {
    Icon = RocketOutlined;
  }

  return (
    <Icon
      style={{
        fontSize: "40px",
        color: "grey",
      }}
    />
  );
};

const QuestionStatus: React.FC<{ total: number; saved: number }> = ({ total, saved }) => {
  return (
    <Row justify="space-between" style={{ marginBottom: 5 }}>
      <Col>Questions</Col>
      <Col style={{ cursor: "default" }}>
        <Tooltip title="Saved Questions / Total Questions">
          {saved} / {total}
        </Tooltip>
      </Col>
    </Row>
  );
};
