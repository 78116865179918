import { Form, Input, Row, Select, Button, message } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import Api from "api";
import { ModuleType, QuestionModuleNode } from "types";

const { Option } = Select;

type Props = {
  closeModal: () => void;
};

const CreateCategory: React.FC<Props> = ({ closeModal }) => {
  const [form] = Form.useForm();
  const { data, loading } = useQuery(Api.QuestionModule.GetBasicList(), {
    onCompleted: (res) => {
      console.log(res);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });
  const [createCategory, { loading: createLoading }] = useMutation(
    Api.QuestionCategory.Create(),
    {
      onCompleted: (data) => {
        console.log(data);
        message.success("Category created successfully!", 5);
        closeModal();
      },
      onError: (error) => {
        console.log(error);
        message.error(error.message, 7);
      },
      refetchQueries: [Api.QuestionModule.WorkflowEditor()],
    },
  );

  const onFinish = (values: any) => {
    createCategory({
      variables: {
        name: values.categoryName,
        moduleId: values.moduleId,
      },
    });
  };

  return (
    <Form form={form} layout="vertical" name="categoryEditorForm" onFinish={onFinish}>
      <Form.Item
        label="Category Name"
        name="categoryName"
        rules={[{ required: true, message: "A category name is required!" }]}
      >
        <Input.TextArea autoSize={{ minRows: 0 }} maxLength={65} />
      </Form.Item>
      <Form.Item
        label="Module this category belongs to?"
        name="moduleId"
        rules={[{ required: true, message: "Required!" }]}
      >
        <Select style={{ width: "100%" }} loading={loading}>
          {data.allQuestionModules.map((module: QuestionModuleNode) => (
            <Option value={module.id} key={module.id}>
              {module.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Row justify="end" style={{ height: 24 }}>
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={createLoading}>
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default CreateCategory;
