export const P19 = (props) => {
  return (
    <>
      <div className="itcsc" style={{ display: "grid", placeItems: "center" }}>
        <div
          className="instructions"
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "10px 20px",
              textAlign: "left",
            }}
          >
            <div style={{ width: "48%", marginRight: "40px" }}>
              <p style={{ fontWeight: "bold" }}>
                2. Authorizing Official (AO) & Authorization Boundary
              </p>
              <p>
                Listings of AOs and Boundary descriptions are posted on the DAF Risk Management
                Framework (RMF) Knowledge Service at https://rmfks.osd.mil/rmf/collaboration/
                Component% 20Workspaces/AirForce/Pages/default.aspx
              </p>
              <p style={{ fontWeight: "bold" }}>3. Technical Description/Purpose</p>

              <p>
                Prepare a general technical description of the function and purpose of the
                System. All acronyms must be spelled out once in the description. Failure to
                spell out acronyms once may result in the form being returned. This description
                should tell the story of who, what, where, when, why, and how the System
                supports the warfighter and/or other systems by answering each of the
                following:
              </p>
              <ul>
                <li>
                  Describe the purpose of the System and indicate if the System is mission
                  essential to the warfighter. Example: The purpose of the New System is to
                  provide a database of safety information that is searchable via a web
                  interface. The system is not mission essential to the warfighter but is
                  mission essential to the 57th Safety Wing.
                </li>
                <li>
                  Describe the MAJOR hardware/software components of the System. Example: The
                  system consists of multiple Dell PowerEdge 3000 servers loaded with Windows
                  Server 2012R2 and Microsoft SQL Server 2014…
                </li>
                <li>
                  Describe the services provided by the System and whether or not any of the
                  services are publicly accessible (Publicly accessible means does not require
                  authentication in order to access the information). Example: The system
                  provides, etc.
                </li>
                <li>
                  Describe how each of the Information Types are, or will be, stored,
                  processed, and/or transmitted by the System.
                </li>
                <li>
                  If the system is undergoing a Major Modification, Describe the modification
                  and its purpose: otherwise ignore this requirement.
                </li>
              </ul>
              <p style={{ fontWeight: "bold" }}>4. Security Description/Environment</p>
              <p>
                Provide a description of the security environment. All acronyms must be spelled
                out once in the description. Failure to spell out acronyms once may result in
                the form being returned. This description should tell the story of who, what,
                where, when, why, and how cybersecurity and information assurance hardware and
                software is, or will be, protecting the System
              </p>
              <ul>
                <li>
                  Describe the network security infrastructure and the internal security
                  components of the System to include encryption devices, firewalls, wireless,
                  Virtual Private Networks, Intrusion Detection Systems, Intrusion Prevention
                  Systems, Demilitarized Zone, Host Based Security System, and Anti-Virus
                </li>
                <li>
                  If any security-related services are being provided by other systems (such as
                  through a Service Level Agreement Memorandum of Agreement/Memorandum of
                  Understanding), describe this as well.
                </li>
                <li>
                  Include any environmental or technical factors that raise special security
                  concerns, such as use of Personal Digital Assistants, wireless technology,
                  etc.
                </li>
                <li>Describe any interconnections the System has.</li>
                <li>
                  Describe your Defense-in-Depth: Provide a narrative, Department of Defense
                  Architecture Framework (DoDAF) view or other artifact that captures processes
                  in place to protect the information and or System.
                </li>
              </ul>
            </div>

            <div style={{ width: "52%" }}>
              <p style={{ fontWeight: "bold" }}>5. System Operational Status</p>
              <p>
                Operational - The System has satisfied program requirements and achieved
                Initial Operating Capability (IOC) or Full Operating Capability (FOC).
              </p>
              <p>
                Under Development - The System is in the design/ development phase and has not
                entered final production/ operation.
              </p>
              <p>
                Major Modification - The System is undergoing a necessary modification to
                improve performance and reduce ownership costs, consistent with the limitations
                prescribed in 10 U.S.C 2244a.
              </p>
              <p style={{ fontWeight: "bold" }}>6. System Architecture</p>

              <p>On-Premises Deployed – This system is deployed completely on premises.</p>
              <p>Hybrid Deployed – This system is deployed on premises and in the cloud.</p>

              <p>Cloud Deployed – This system is deployed exclusively in the cloud. </p>

              <div style={{ paddingLeft: "20px" }}>
                <p style={{ fontWeight: "bold" }}>
                  6A. Type of Service utilized **Cloud Systems Only**
                </p>

                <ul>
                  <li>Infrastructure as a Service (IaaS)</li>
                  <li>Platform as a Service (PaaS)</li>
                  <li>Software as a Service (SaaS)</li>
                </ul>
              </div>

              <p style={{ fontWeight: "bold" }}>7. Cloud</p>
              <p>Cloud Information Impact Level (IL)</p>
              <ul>
                <li>
                  IL2 (L-M-X and below): Accommodates data cleared for public release as well
                  as some low confidentiality unclassified information NOT designated as
                  Controlled Unclassified Information (CUI) or critical military/contingency
                  operations mission data.
                </li>
                <li>
                  IL4 (M-M-X and below): Accommodates CUI and/or other mission critical data to
                  include that used in direct support of military or contingency operations.
                </li>
                <li>
                  IL5 (M-M-X and below): Accommodates CUI that may require a higher level of
                  protection than that afforded by Level 4 and unclassified National Security
                  Systems (NSSs).
                </li>
                <li>
                  IL6 (M-M-X and below): Accommodates classified (SECRET and below) national
                  security information.
                </li>
              </ul>
              <i>See Ref: (f) for more information</i>
              <div style={{ paddingLeft: "20px" }}>
                <p style={{ fontWeight: "bold" }}>7A. Cloud-Hosted</p>
                <p>
                  Is the System going to be hosted in a cloud environment? If “No,” then
                  continue to Section 12.
                </p>
                <p style={{ fontWeight: "bold" }}>7B. Classified Info through Cloud</p>
                <p>
                  If the answers to both Questions 7A and 11F are “Yes,” then this will also be
                  checked “Yes,” and the Cloud Impact Level will be IL6.
                </p>
                <p>
                  <i>See Ref: (g) for more information.</i>
                </p>

                <p style={{ fontWeight: "bold" }}>
                  7C/H. Personally Identifiable Information (PII) II and Rolodex Information
                </p>
                <p>
                  Does the Systemcontain PII other than information which may meet the
                  parameters of the Rolodex Exception, including rosters that contain only
                  business contact information?
                </p>
                <p>
                  <i>See Ref: (h, Paragraph 2.4) for more information.</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
