import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Button } from "antd";

export const P20 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="references">
        <ol style={{ counterIncrement: 'list-counter 22', marginBottom: 0 }}>
          <li>Overlay Application and Selection <a>https://rmfks.osd.mil/rmf/RMFImplementation/Select/Pages/OverlaySelection.aspx</a></li>
          <li>CNSSI 1253F Attachment (Atch) 4, Intelligence Overlay, 19 April 2016</li>
          <li>CNSSI 1253E Atch 3, Cross Domain Solution Overlay, 08 February 2023</li>
          <li>The Nuclear Command, Control & Communications (NC3) Overlay</li>
        </ol>
        <ol className="double_letters" style={{ marginTop: 0 }}>
          <li>CNSSI 1253F Atch 2, Space Platform Overlay, 23 February 2018</li>
          <li>CNSSI 1253E Atch 5, Classified Information Overlay, 30 September 2022</li>
          <li>CNSSI 1253F Atch 6, Privacy Overlay, 23 April 2015</li>
          <li>NIST SP 800-122, Guide to Protecting the Confidentiality of Personally Identifiable Information (PII), 06 April 2010</li>
          <li>DOD Chief Information Officer Memorandum, Treatment of Personally Identifiable Information within Information Impact Level 2 Commercial Cloud Services for the Department of Defense, 07 August 2019</li>
          <li>Federal Information Processing Standards Publication 199, Standards for Security Categorization of Federal Information and Information Systems, February 2004</li>
          <li>NIST SP 800-60 Volumes 1 & 2 Revision 1, Guide for Mapping Types of Information and Information Systems to Security Categories, 01 August 2008</li>
        </ol>

      </div>
    </>
  );
};
