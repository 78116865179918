import { CloseOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { OperationVariables, QueryResult, useQuery } from "@apollo/client";
import {
  Modal,
  ModalProps,
  Steps,
  StepProps,
  Form,
  Input,
  Row,
  InputNumber,
  Button,
  Space,
  Col,
  Typography,
  Alert,
  Descriptions,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import { DeliverableDocumentNode, GetDefinitionQuery } from "types";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submissionDocument: DeliverableDocumentNode;
  definition: GetDefinitionQuery["getApplicationDocumentDefinition"];
  onFinish?: (values?: any) => Promise<void> | void;
  onCancel?: () => void;
  onClose?: () => void;
  modalProps?: ModalProps;
}

const SubmissionForm: React.FC<Props> = (props) => {
  const {
    open,
    submissionDocument,
    definition,
    setOpen,
    onFinish,
    onCancel,
    onClose,
    modalProps,
  } = props;

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [ack, setAck] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        signers: definition?.anchors || [],
        ccs: definition?.ccs || [],
      });
    }
  }, [open, form]);

  const items = [
    {
      title: "Signers",
      content: (...props: any) => <SignersList {...props} />,
    },
    {
      title: "CCs",
      content: (...props: any) => <CCsList {...props} />,
    },
    {
      title: "Summary",
      content: (...props: any) => (
        <Summary
          {...props}
          submissionDocument={submissionDocument}
          ack={ack}
          setAck={setAck}
        />
      ),
    },
  ];

  const close = () => {
    if (onClose) onClose();
    setCurrentStep(0);
    setOpen(false);
  };

  const next = async () => {
    form
      .validateFields()
      .then((values) => {
        if (currentStep + 1 < items.length) {
          setCurrentStep(currentStep + 1);
          return;
        }
        form.submit();
      })
      .catch((errorInfo) => {
        // Handle validation failure (errorInfo contains the validation errors)
        console.log("Validation Failed:", errorInfo);
        
      });
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      return;
    }
    if (onCancel) onCancel();
    close();
  };

  return (
    <Modal
      {...modalProps}
      closable={false}
      centered
      width={"fit-content"}
      afterClose={() => {
        form.resetFields();
        setAck(false);
      }}
      style={{
        ...modalProps?.style,
        maxWidth: "60%",
        minWidth: "40%",
      }}
      open={open}
      destroyOnClose={true}
      footer={
        <Row justify={"space-between"}>
          <div>
            <Button danger onClick={close}>
              Cancel All
            </Button>
          </div>
          <div>
            <Button onClick={prev}>{currentStep !== 0 ? "Previous" : "Cancel"}</Button>
            <Button
              type="primary"
              onClick={next}
              disabled={currentStep + 1 >= items.length && !ack}
            >
              {currentStep + 1 < items.length ? "Next" : "Submit"}
            </Button>
          </div>
        </Row>
      }
      modalRender={(dom) => (
        <Form
          form={form}
          labelWrap
          preserve={true}
          onFinish={async () => {
            const vals = await form.validateFields(["signers", "ccs"]);
            if (!onFinish) {
              close();
              return;
            }
            onFinish({ values: vals, documentId: submissionDocument.id });
            close();
          }}
        >
          {dom}
        </Form>
      )}
    >
      <Steps current={currentStep} items={items.map((i) => ({ title: i.title }))} />
      <br />
      {items[currentStep].content()}
    </Modal>
  );
};

const SignersList = () => {
  const form = Form.useFormInstance();
  return (
    <>
      <Typography.Paragraph>
        Please include the <b>Full Name</b> and <b>Email</b> of individual signers where
        appropriate.
        <br />
        The <b>Signing Order</b> is the position in which the individual will recieve notice to
        sign the document.
        <br />
        If the same person fills more than 1 role, then use the same Name and Email Address in
        that space.
      </Typography.Paragraph>
      <div style={{ padding: "10px", maxHeight: "600px", overflowY: "auto" }}>
        <Form.List name={"signers"}>
          {(fields) => {
            const items = fields.map(({ name, ...rest }) => {
              const label = form.getFieldValue(["signers", name, "label"]);
              return {
                ...rest,
                name: name,
                label: label,
              };
            });
            return (
              <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <Row gutter={16} justify={"start"} align={"bottom"}>
                  <Col span={4} push={0}>
                    <Typography.Title level={5} style={{ margin: 0 }} underline>
                      Role
                    </Typography.Title>
                  </Col>
                  <Col span={6} push={0}>
                    <Typography.Title level={5} style={{ margin: 0 }} underline>
                      Name
                    </Typography.Title>
                  </Col>
                  <Col span={8} push={0}>
                    <Typography.Title level={5} style={{ margin: 0 }} underline>
                      Email
                    </Typography.Title>
                  </Col>
                  <Col span={6}>
                    <Typography.Title level={5} style={{ margin: 0 }} underline>
                      Signing Order
                    </Typography.Title>
                  </Col>
                </Row>
                {items.map(({ name, key, label, ...rest }) => (
                  <Row key={key} gutter={16} justify={"start"} align={"bottom"}>
                    <Col span={4}>
                      <Form.Item name={[name, "role"]} {...rest}>
                        {label}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...rest}
                        name={[name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Full Name" />
                      </Form.Item>
                    </Col>
                    <Col span={8} aria-placeholder="Email">
                      <Form.Item
                        {...rest}
                        name={[name, "email"]}
                        rules={[
                          { required: true, message: "Email is required" },
                          { type: "email", message: "Must be a valid email" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                    </Col>
                    <Col span={6} aria-placeholder="Order">
                      <Form.Item name={[name, "order"]} {...rest}>
                        <InputNumber min={1} />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </div>
            );
          }}
        </Form.List>
      </div>
    </>
  );
};

const CCsList = () => {
  const form = Form.useFormInstance();
  const signers = form.getFieldValue("signers");

  return (
    <>
      <Typography.Paragraph>
        <b>(Optional)</b> Carbon Copies are notified when the document is complete.
      </Typography.Paragraph>
      <Alert message="Carbon Copies cannot also be Signers" type="warning" banner />
      <br />
      <div style={{ padding: "10px" }}>
        <Form.List name={"ccs"}>
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ name, key, ...rest }) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignContent: "center",
                    gap: "8px",
                  }}
                >
                  <Form.Item
                    {...rest}
                    style={{ width: "80%" }}
                    name={[name, "name"]}
                    rules={[{ required: true, message: "Name is required" }]}
                    noStyle={false}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item
                    {...rest}
                    style={{ width: "100%" }}
                    name={[name, "email"]}
                    rules={[
                      { required: true, message: "Email is required" },
                      { type: "email", message: "Must be a valid email" },
                      {
                        validator: async (_, value) => {
                          if (!value) return;
                          const ccs = form.getFieldValue("ccs");
                          const signer = signers.some((p) => p.email === value);
                          const cc = ccs.some((v, i) => v.email === value && i !== name);
                          if (cc)
                            return Promise.reject(new Error("Email already exists as a CC"));
                          if (signer)
                            return Promise.reject(new Error("Email already exists as signer"));
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      icon={<CloseOutlined />}
                      type="text"
                      danger
                      onClick={() => remove(name)}
                    />
                  </Form.Item>
                </div>
              ))}
              <Row justify={"end"}>
                <Button icon={<PlusOutlined />} onClick={() => add()}>
                  Add
                </Button>
              </Row>
            </div>
          )}
        </Form.List>
      </div>
    </>
  );
};

interface SummaryParams {
  submissionDocument: DeliverableDocumentNode;
  ack: boolean;
  setAck: React.Dispatch<React.SetStateAction<boolean>>;
}
const Summary: React.FC<SummaryParams> = ({ submissionDocument, ack, setAck }) => {
  const form = Form.useFormInstance();
  const signers = form.getFieldValue("signers");
  const ccs = form.getFieldValue("ccs") || [];

  return (
    <div style={{ textAlign: "start", maxHeight: "500px", overflowY: "auto" }}>
      <Typography.Title level={2}>Summary</Typography.Title>
      <Typography.Paragraph>
        Please review the following details to ensure correctness.
      </Typography.Paragraph>
      <Descriptions column={2} title="Document" bordered layout="vertical" size="small">
        <Descriptions.Item label="Filename" span={3}>
          <Typography.Text code>{submissionDocument.name}.pdf</Typography.Text>
        </Descriptions.Item>

        <Descriptions.Item label="Document">
          {submissionDocument.identifier.toUpperCase()}
        </Descriptions.Item>
        <Descriptions.Item label="Version">{submissionDocument.version}</Descriptions.Item>
      </Descriptions>

      <Descriptions title={"Signers"} bordered size="small" column={2}>
        {signers
          .sort((a: any, b: any) => a.order - b.order)
          .map((s: any) => (
            <React.Fragment key={s.role}>
              <Descriptions.Item
                label={s.label}
                labelStyle={{
                  width: "200px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {s.name}
              </Descriptions.Item>
              <Descriptions.Item>{s.email}</Descriptions.Item>
            </React.Fragment>
          ))}
      </Descriptions>

      <Descriptions title={"CCs"} bordered column={2} size="small">
        {ccs.map((s: any, index: number) => (
          <React.Fragment key={s.email}>
            <Descriptions.Item
              label={index + 1}
              labelStyle={{
                width: "50px",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {s.name}
            </Descriptions.Item>
            <Descriptions.Item>{s.email}</Descriptions.Item>
          </React.Fragment>
        ))}
      </Descriptions>
      <br />
      {submissionDocument.envelope != null && (
        <Alert message="You are about to overwrite your existing document" type="error" />
      )}
      <br />
      <Alert
        message="Approval Needed"
        type="warning"
        banner
        description={
          <div>
            <Typography.Paragraph>
              By clicking submit, this document and your responses will be sent to Docusign for
              processing.
              <br />
              Attempting to resubmit this document will result in invalidating previous
              submissions.
              <br />
              <br />
              This system is not designed to process, store or transmit CUI.
              <br />
              <b>This communication should does not contain CUI.</b>
              <br />
              <br />
              Do you acknowledge and agree to proceed with this communication?
            </Typography.Paragraph>
            <Space style={{ width: "100%", justifyContent: "end" }}>
              <Checkbox checked={ack} onChange={(e) => setAck(e.target.checked)}>
                I Understand and Approve
              </Checkbox>
            </Space>
          </div>
        }
      />
    </div>
  );
};

export default SubmissionForm;
