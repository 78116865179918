import { PIAFooter, PIAHeader, PIAPages } from "./Documents/PIA";
import { ITCSCv83Header, ITCSCv83Pages, ITCSCv83Footer } from "./Documents/ITCSCv8.3";
import { ITCSCv84Header, ITCSCv84Pages, ITCSCv84Footer } from "./Documents/ITCSCv8.4";
import { ITCSCv902Header, ITCSCv902Pages, ITCSCv902Footer } from "./Documents/ITCSCv9.0.2";
import { ITCSCv10Header, ITCSCv10Pages, ITCSCv10Footer } from "./Documents/ITCSCv10.1";
import { CTPHeader, CTPPages, CTPFooter } from "./Documents/CTP/index";
import { AMHeader, AMPages, AMFooter } from "./Documents/AM/index";
import { AODBHeader, AODBPages, AODBFooter } from "./Documents/AODB/index";
import { PCILHeader, PCILPages, PCILFooter } from "./Documents/PCIL/index";
import { PCILv21Header, PCILv21Pages, PCILv21Footer } from "./Documents/PCILv2.1/index";

const documents = [
  {
    id: "privacy_impact_assessment",
    header: PIAHeader,
    forms: PIAPages,
    footer: PIAFooter,
    bodyStyle: { border: 'solid black 3px', padding: 4 }
  },
  {
    id: "information_technology_categorization_and_selection_checklist_8.4",
    header: ITCSCv84Header,
    forms: ITCSCv84Pages,
    footer: ITCSCv84Footer,
  },
  {
    id: "information_technology_categorization_and_selection_checklist_9.0.2",
    header: ITCSCv902Header,
    forms: ITCSCv902Pages,
    footer: ITCSCv902Footer,
  },
  {
    id: "information_technology_categorization_and_selection_checklist_10.1.0",
    header: ITCSCv10Header,
    forms: ITCSCv10Pages,
    footer: ITCSCv10Footer,
  },
  {
    id: "pii_confidentiality_impact_level_categorization",
    header: PCILHeader,
    forms: PCILPages,
    footer: PCILFooter,
  },
  {
    id: "pii_confidentiality_impact_level_categorization_2.1",
    header: PCILv21Header,
    forms: PCILv21Pages,
    footer: PCILv21Footer,
    headerStyle: {
      borderTop: 'solid black 6px',
      borderLeft: 'solid black 6px',
      borderRight: 'solid black 6px',
    },
    bodyStyle: {
      borderLeft: 'solid black 6px',
      borderRight: 'solid black 6px',
    },
    footerStyle: {
      borderLeft: 'solid black 6px',
      borderRight: 'solid black 6px',
      borderBottom: 'solid black 6px',
    },

  },
  {
    id: "certification_test_plan",
    header: CTPHeader,
    forms: CTPPages,
    footer: CTPFooter,
  },
  {
    id: "authorization_memo",
    header: AMHeader,
    forms: AMPages,
    footer: AMFooter,
  },
  {
    id: "ao_determination_brief",
    header: AODBHeader,
    forms: AODBPages,
    footer: AODBFooter,
  },
];

export default documents;
