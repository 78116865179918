import {
  ApolloProvider as Provider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { onError } from "@apollo/client/link/error";
import { shouldLogout } from "./WithAuth";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  credentials: "include",
});

const errorCatch = "Authentication is required to perform this action";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (shouldLogout(window.location.pathname)) {
        console.log(
          `%c[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          "border: 1px solid red; padding: 5px 5px 10px; margin: 10px 0",
        );
        if (message === errorCatch) {
          console.log("SHOULD KICK USER OUT FROM FAILURE FROM COOKIE");
          localStorage.removeItem("authorization");
          window.location.href = "/login";
        }
      }
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  connectToDevTools: process.env.NODE_ENV !== "PRODUCTION" ? true : false,
});

const ApolloProvider: React.FC<any> = ({ children }) => (
  <Provider client={client}>{children}</Provider>
);

export default ApolloProvider;
