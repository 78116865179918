import { Link } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Result, Button } from "antd";

const EntityNotFound: React.FC = () => {
  useDocumentTitle("Entity not found");

  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, the entity you are looking was not found."
      extra={
        <Button type="primary">
          <Link to="/home">Back to Home</Link>
        </Button>
      }
    />
  );
};

export default EntityNotFound;
