import { useEffect, useMemo, useState } from "react";
import { Col, message, Row } from "antd";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { QuestionNode, QuestionType, UpdateQuestionMutation } from "types";
import RmfPathsSelector from "./Components/RmfPathsSelector";
import ManageRmfapAnswers from "./Components/ManageRmfapAnswers";
import ParentInformation from "./Components/ParentInformation";
import ManageAnswers from "./Components/ManageAnswers";
import QuestionInformation from "./Components/QuestionInformation";
import {
  FieldKeys,
  FieldKeysEnum,
  OrderHeadingType,
  ParentTypeEnum,
  QuestionEditorState,
  QuestMgmtReducerActionEnum as ReducerEnum,
} from "./Components/reducer";
import { mapTagToType } from "components/Common/Tags/QuestionType";
import Api from "api";
import ContentCard from "../Shared/ContentCard";
import SidebarSection, { SidebarManageButton } from "../Shared/SidebarSection";
import { SaveStatusEnum } from "../Shared/types";
import useEditorLoading from "hooks/useEditorLoading";

type Props = {
  state: QuestionEditorState;
  dispatch: any;
  loading: Loading;
  question: QuestionNode;
  refetchQuestion: any;
};

const QuestionEditor: React.FC<Props> = ({
  question,
  refetchQuestion,
  state,
  dispatch,
  loading,
}) => {
  const { questionId } = useParams();

  const questionType = question?.questionType;
  const questionIsSelect: boolean =
    questionType === QuestionType.MultipleChoicePickOne ||
    questionType === QuestionType.MultipleChoicePickMany
      ? true
      : false;

  const [selectedPaths, setSelectedPaths] = useState<any>([]);

  useEffect(() => {
    // sets init path state
    if (!question) {
      console.log("%c!editingItem", "color:red");
      return;
    } else {
      console.log("%csetRmfPaths in useEffect", "color:blue");
      const paths = question?.rmfActionPaths?.map((ap: any) => {
        return ap;
      });
      setSelectedPaths(paths);
    }
  }, [question]);

  const [updateQuestion, { loading: updateLoading }] = useMutation<UpdateQuestionMutation>(
    Api.Question.Update(),
    {
      onCompleted: (res) => {
        console.log(res);
      },
      refetchQueries: [
        { query: Api.QuestionModule.WorkflowEditor() },
        { query: Api.Question.GetAllOrphanedQuestions() },
      ],
    },
  );

  const setEditState = (fieldKey: FieldKeys) => {
    console.log("%cSetEditState: ", "color:green");
    console.log(`field: ${fieldKey} being set `);

    dispatch({
      type: ReducerEnum.ToggleEdit,
      params: {
        fieldKey,
      },
    });
  };

  const setSaveState = (fieldKey: FieldKeys, status: SaveStatusEnum) => {
    let saveAction;
    console.log("%csetSaveState action", "color:green");
    console.log(`fieldKey: ${fieldKey}, status: ${status}`);

    switch (status) {
      case SaveStatusEnum.Default:
        console.log("%cDEFAULT", "color:orange");
        saveAction = ReducerEnum.SetDefault;
        break;
      case SaveStatusEnum.Loading:
        console.log("%cLOADING", "color:orange");
        saveAction = ReducerEnum.SetLoading;
        break;
      case SaveStatusEnum.Success:
        console.log("%cSUCCESS", "color:orange");
        saveAction = ReducerEnum.SetSuccess;
        break;
      case SaveStatusEnum.Error:
        console.log("%cERROR", "color:orange");
        saveAction = ReducerEnum.SetError;
        break;
      default:
        console.log("%cDEFAULT STATEMENT", "color:orange");
        saveAction = ReducerEnum.SetDefault;
    }

    console.log(`%cdispatch sending ${saveAction}`, "color:orange");

    dispatch({
      type: saveAction,
      params: {
        fieldKey,
      },
    });
    // error message shown longer
    if (saveAction === ReducerEnum.SetError) {
      return setTimeout(() => {
        dispatch({
          type: ReducerEnum.SetDefault,
          params: {
            fieldKey,
          },
        });
      }, 5000);
    }
    if (saveAction === ReducerEnum.SetSuccess) {
      return setTimeout(() => {
        dispatch({
          type: ReducerEnum.SetDefault,
          params: {
            fieldKey,
          },
        });
      }, 2500);
    }
  };

  // todo: refactor / improve
  const parentValues = useMemo(() => {
    const questionType = question.questionType;
    const questionIsSelect =
      questionType === "MULTIPLE_CHOICE_PICK_ONE" ||
      questionType === "MULTIPLE_CHOICE_PICK_MANY";
    const questionHasAnswers = question.answers?.length > 0;
    let categoryName;
    let categoryId;
    let categoryOrder;
    let groupHeader;
    let groupId;
    let groupOrder;
    let editableOrder: number | undefined | null;
    let parentStatus: ParentTypeEnum;
    let orderHeading: OrderHeadingType = "unassigned";
    let directParentName;
    let directParentId;
    let orderKey;
    let renderParentOrder: boolean;

    // console.group("%cparentValues** ", "color:pink");

    if (question.categoryId) {
      // ONLY IN CATEGORY
      // console.log("%cquestion had question.categoryId", "color:pink");
      categoryName = question.category!.name;
      categoryId = question.category!.id;
      categoryOrder = question.categoryOrder;
      parentStatus = ParentTypeEnum.category;
      orderHeading = "Category Order";
      orderKey = "order";
      editableOrder = question.order;
      directParentName = question.category?.name;
      directParentId = question.category?.id;
      renderParentOrder = true;
    } else {
      if (question.groupId) {
        // console.log("%cquestion had question.groupId, but not categoryId", "color:pink");
        // #2 group not in category
        groupHeader = question?.group?.header;
        groupId = question.groupId;
        groupOrder = question.groupOrder;
        parentStatus = ParentTypeEnum.group;
        orderHeading = "Group Order";
        orderKey = "groupOrder";
        editableOrder = question.groupOrder;
        renderParentOrder = true;
        if (question.group?.categoryId) {
          // console.log(
          //   "%cquestion had question.groupId, and question.group.categoryId",
          //   "color:pink",
          // );
          // grab category stuff
          // #3 GROUP IN CATEGORY
          categoryName = question.category!.name;
          categoryId = question.category!.id;
          categoryOrder = question.category?.order;
          editableOrder = question.groupOrder;
          groupHeader = question.group?.header;
          groupId = question.groupId;
          orderHeading = "Group Order";
          orderKey = "groupOrder";
          parentStatus = ParentTypeEnum.group;
          directParentName = question.group?.header;
          directParentId = question?.groupId;
          renderParentOrder = true;
        }
      } else {
        // console.log("%cquestion is ORPHAN", "color:pink");
        // #4 orphan
        parentStatus = ParentTypeEnum.orphan;
        editableOrder = undefined;
        renderParentOrder = false;
      }
    }

    const editableParent = {
      order: editableOrder,
      orderKey: orderKey,
      name: directParentName,
      id: directParentId,
    };

    // console.log({ editableParent });

    // console.groupEnd();

    return {
      renderParentOrder,
      questionType,
      questionIsSelect,
      questionHasAnswers,
      parentStatus,
      orderHeading,
      editableOrder,
      groupHeader,
      groupOrder,
      editableParent,
      categoryId,
      categoryName,
      categoryOrder,
      groupId,
    };
  }, [question]);

  console.log({ parentValues, question, state });

  const groupHeader = parentValues?.groupHeader;

  const rmfSelectorLoading = useEditorLoading(FieldKeysEnum.rmfPaths, state, loading);

  const sharedProps = {
    question,
    refetchQuestion,
    updateQuestion,
    setEditState,
    setSaveState,
    state,
    loading,
  };

  const toggleActionPath = (tag: any, checked: boolean) => {
    const nextSelectedTags: any[] = checked
      ? [...selectedPaths, { path: tag.value }]
      : selectedPaths.filter((t: any) => t.path !== tag.value);

    setSelectedPaths(nextSelectedTags);
    setSaveState(FieldKeysEnum.rmfPaths, SaveStatusEnum.Loading);

    if (nextSelectedTags.length < 1) return;

    updateQuestion({
      variables: {
        id: question.id,
        rmfActionPath: nextSelectedTags.map((ap: any) => ap.path),
      },
      onCompleted: (res) => {
        console.log({ updatedQuesRmfPaths: res });
        const currentPaths = [...selectedPaths];
        console.log({ currentPaths });
        setSelectedPaths(res.updateQuestion?.rmfActionPaths); // ???
        setSaveState(FieldKeysEnum.rmfPaths, SaveStatusEnum.Success);
        refetchQuestion();
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.rmfPaths, SaveStatusEnum.Error);
      },
    });
  };

  console.log({ selectedPaths });

  const renderRmfPaths: boolean = questionType !== QuestionType.UploadFile;
  const renderRmfAnswers: boolean =
    state[FieldKeysEnum.rmfAnswers].editActive && questionType !== QuestionType.UploadFile;

  return (
    <Row justify="space-between" style={{ padding: 12 }}>
      <Col span={17}>
        <Row justify="start">
          <Col span={24} style={{ textAlign: "left", paddingLeft: 0 }}>
            <QuestionInformation {...sharedProps} />
            {groupHeader && (
              <ContentCard
                title="Group Header"
                loading={loading}
                fieldKey={FieldKeysEnum.header}
                editableField={false}
              >
                {groupHeader}
              </ContentCard>
            )}
            {questionIsSelect && (
              <ManageAnswers {...sharedProps} fieldKey={FieldKeysEnum.questionAnswers} />
            )}
            {renderRmfAnswers && (
              <ManageRmfapAnswers fieldKey={FieldKeysEnum.rmfAnswers} {...sharedProps} />
            )}
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <Row align="top" justify="start">
          <Col span={24}>
            <SidebarSection title="Question Type" loading={loading}>
              {mapTagToType(questionType!, questionId)}
            </SidebarSection>
            <ParentInformation
              {...sharedProps}
              parentValues={parentValues}
              fieldKey={FieldKeysEnum.parentOrder}
            />
            {renderRmfPaths && (
              <SidebarSection
                title="RMF Action Paths"
                tooltip="Select a path to assign"
                loading={loading}
                valueLoading={rmfSelectorLoading}
                renderSaveStatus={
                  state[FieldKeysEnum.rmfPaths].saveStatus !== SaveStatusEnum.Default
                }
                saveStatus={state[FieldKeysEnum.rmfPaths].saveStatus}
                actionMenu={
                  <SidebarManageButton
                    setEditState={setEditState}
                    tooltip="Manage RMF AP default answers"
                    loading={rmfSelectorLoading}
                    fieldKey={FieldKeysEnum.rmfAnswers} // this button opens rmfAnswers edit card
                    editingCurrentField={state[FieldKeysEnum.rmfAnswers].editActive}
                  />
                }
              >
                <RmfPathsSelector
                  toggleActionPath={toggleActionPath}
                  selectedPaths={selectedPaths}
                />
              </SidebarSection>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default QuestionEditor;
