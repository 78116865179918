import { gql } from "graphql-tag";

export const ApplicationComment = {
  GetAll: () => gql`
    query questionComments($id: String!) {
      questionComments(questionId: $id) {
        id
        questionId
        answerId
        createdOn
        text
        isPrivate
        author {
          fullname
        }
        answer {
          answer
          answerText
        }
      }
    }
  `,
  Create: () => gql`
    mutation createComment(
      $id: String!
      $answerId: String
      $privacy: CommentPrivacy!
      $text: String!
    ) {
      createApplicationComment(
        questionId: $id
        answerId: $answerId
        privacy: $privacy
        text: $text
      ) {
        id
        privacy
        questionId
        answerId
        text
      }
    }
  `,
};
