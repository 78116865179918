import { P1 } from "./P1";
import { P2 } from "./P2";
import { P3 } from "./P3";
import { P4 } from "./P4";

// prettier-ignore
const pages = [
  P1,
  P2,
  P3,
  P4,
];

export default pages;
