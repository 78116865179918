import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

type Props = {
  expanded: boolean;
  onExpand: (record: any, e: any) => any;
  record: any;
};

const ExpandIcon: React.FC<Props> = ({ expanded, onExpand, record }) => {
  return expanded ? (
    <Tooltip title="Collapse" color="#000000">
      <MinusCircleOutlined
        onClick={(e) => onExpand(record, e)}
        key={record.id}
      />
    </Tooltip>
  ) : (
    <Tooltip title="Expand" color="#000000">
      <PlusCircleOutlined
        onClick={(e) => onExpand(record, e)}
        key={record.id}
      />
    </Tooltip>
  );
};

export default ExpandIcon;
