import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { message } from "antd";
import { QuestionModuleNode } from "types";
import { client } from "components/Providers/WithApollo";
import Api from "api";

/**
 * @description: retrieves basic list of modules for use in selection components, will grab list from existing query if present, else query server
 */

const filterBasicList = (list: QuestionModuleNode[]) => {
  return list.map((module) => ({ name: module.name, id: module.id }));
};

export const useModuleList = () => {
  const [modules, setModules] = useState<BasicQuestionModule[]>([]);
  const [queryModules, { loading, error, refetch }] = useLazyQuery(
    Api.QuestionModule.GetBasicList(),
    {
      onCompleted: (res) => {
        const filtered = filterBasicList(res.allQuestionModules);
        setModules(filtered);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 5);
      },
    },
  );

  const getModules = () => {
    try {
      const existingModules = client.readQuery({
        query: Api.QuestionModule.WorkflowEditor(),
      });
      if (existingModules?.allQuestionModules.length > 0) {
        const filtered = filterBasicList(existingModules.allQuestionModules);
        setModules(filtered);
        return;
      }

      queryModules();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getModules();
  }, []);

  return { modules, loading, error, refetch };
};
