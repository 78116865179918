import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

export const P13 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pcilv2"
        style={{
          width: "95%",
          fontSize: 12,
          paddingTop: 20,
        }}
      >

        <div style={{ paddingLeft: 20, fontSize: 15 }}>
          <div style={{ fontSize: 16 }}>
            <p>
              <b>Appendix A - References</b>
            </p>
          </div>

          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>a.</span>
            DODI 8510.01, Risk Management Framework (RMF) for DoD Information Technology (IT), 10
            November 2015
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>b.</span>
            CNSSI 1253, Security Categorization and Control Selection for National Security Systems, 27
            March 2014
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>c.</span>
            NIST SP 800-37 Rev 1, Guide for Applying the Risk Management Framework to Federal
            Information Systems, February 2010
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>d.</span>
            FIPS 199, Standards for Security Categorization of Federal Information and Information Systems,
            February 2004
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>e.</span>
            CNSSI 1253, Appendix F, Attachment 6, Privacy Overlays
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>f.</span>
            NIST SP 800-122, Guide to Protecting the Confidentiality of Personally Identifiable Information
            (PII), April 2010
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>g.</span>
            NIST SP 800-60, Vol I, Guide for Mapping Types of Information and Information Systems to
            Security Categories
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>h.</span>
            NIST SP 800-60, Vol II, Appendices to Guide for Mapping Types of Information and Information
            Systems to Security Categories
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>i.</span>
            DoD DD Form 2930, Privacy Impact Analysis
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>j.</span>
            Applicable Privacy Act System of Records Notice,
            <a>http://dpcld.defense.gov/Privacy/SORNs.aspx</a>
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>k.</span>
            OMB Circular A-130, “Managing Information as a Strategic Resource,” 07/28/2016, 81 FR
            49689
          </div>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <span style={{ paddingRight: 40 }}>l.</span>
            OMB Circular A-108, “Federal Agency Responsibilities for Review, Reporting, and Publication
            Under the Privacy Act,” 12/23/2016, 81 FR 94424
          </div>
        </div>
      </div>
    </>
  );
};
