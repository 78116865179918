import CSAR_BRAND_FULL from "images/CSARLogo_Full.png";

const CsarBrand = (props) => {
  const { width = 100, onClick, style } = props;

  return (
    <img
      src={CSAR_BRAND_FULL}
      alt="CSAR brand full logo"
      onClick={onClick}
      style={{ width: width, ...style }}
    />
  );
};

export default CsarBrand;
