import React from "react";
import {
  QuestionType,
  ApplicationVariableField,
  DeliverableDocumentVariableFieldNode,
} from "types";
import { useDocument } from "../DocumentEditor";
import { getAnswersFromMapping, getFieldFromMapping } from "../fieldmap";

type TQuestionType = `${QuestionType}`;

export interface IDocumentFieldProps {
  formId: string;
  fieldName: string | string[];
  pageNumber: number;
  state: any;
  dispatch: any;
  editable: boolean;
}

interface IDocumentFieldChildrenProps {
  field: DeliverableDocumentVariableFieldNode;
  answer: ApplicationVariableField | undefined;
  handleClick: React.MouseEventHandler;
  handleChange: React.ChangeEventHandler<any>;
  styleId: string;
}

interface IGetStyleIdArgs {
  field: any | any[];
  state: any;
  fieldType: TQuestionType;
}

interface IFieldProps extends IDocumentFieldProps {
  fieldType: TQuestionType;
  children: (args: IDocumentFieldChildrenProps) => React.ReactNode;
  showAnswers?: boolean;
  getStyleId?: (args: IGetStyleIdArgs) => string;
  multiField?: boolean;
}

function defaultGetStyleID({ field, state, fieldType }: IGetStyleIdArgs) {
  if (Array.isArray(field)) return "unsavedAssignment";

  return field === undefined
    ? "missingAssignment"
    : state?.fieldId !== undefined && state?.fieldId === field?.id
    ? "selectedAssignment"
    : fieldType !== field?.fieldType
    ? "mismatchTypes"
    : field.answer !== null
    ? "savedAssignment"
    : "unsavedAssignment";
}

const DocumentField: React.FC<IFieldProps> = (props) => {
  const {
    children,
    dispatch,
    pageNumber,
    fieldName,
    fieldType,
    state,
    editable,
    formId,
    showAnswers = false,
    getStyleId = defaultGetStyleID,
    multiField = false,
  } = props;

  useDocument({
    page_number: pageNumber,
    field_name: fieldName,
    field_type: fieldType,
  });

  var field = getFieldFromMapping({
    state: state,
    pageNumber: pageNumber,
    fieldName: fieldName,
  });

  var answer = getAnswersFromMapping({
    state: state,
    pageNumber: pageNumber,
    fieldName: fieldName,
    editable: editable,
  });

  const handleClick = (e: React.MouseEvent) => {
    if (!editable) return;

    dispatch({
      type: "SET_ASSIGNMENT",
      state: {
        formId: formId,
        fieldId: field.id,
        fieldName: fieldName,
        questionType: fieldType,
        showAnswers: showAnswers,
      },
    });
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    if (!editable) return;
    const value = e.target?.value;
    const field = getFieldFromMapping({
      state: state,
      pageNumber: pageNumber,
      fieldName: value,
    });
    if (!field) return;

    dispatch({
      type: "SET_ASSIGNMENT",
      state: {
        formId: formId,
        fieldId: field.id,
        questionType: fieldType,
        showAnswers: showAnswers,
      },
    });
  };

  const styleId = getStyleId({ field, state, fieldType });

  return <>{children({ field, answer, handleClick, handleChange, styleId })}</>;
};

export default DocumentField;
