import React from "react";
import { Button, Card, Col, Divider, Row, Space, Typography } from "antd";
import { DownOutlined, MenuOutlined, UpOutlined } from "@ant-design/icons";
import { QuestionNode } from "types";
import { mapTagToType } from "components/Common/Tags/QuestionType";
import { ParentTag } from "components/Common/Tags/WorflowParentTags";
import LockIconButton from "components/Common/Buttons/LockIconButton";

interface SortCardProps {
  question: QuestionNode | any;
  index: number;
  openDetails?: any;
  provided: any;
  snapshot: any;
  innerRef: HTMLElement | any;
  moveItem: (arg0: string, arg1: string, arg2: number) => void;
  itemList: any[];
  lockedItems: any[];
  handleItemLock: any;
}

const SortCard: React.FC<SortCardProps> = (props) => {
  const {
    question,
    index,
    provided,
    snapshot,
    innerRef,
    moveItem,
    itemList,
    handleItemLock,
    lockedItems,
  } = props;
  const dataType = question.questionType;
  const questionId = question.id;
  const questionText = question.question;
  const locked: boolean = lockedItems?.some((item) => item?.id === questionId);

  const getStyle = (style: any, snapshot: any) => {
    if (!snapshot.isDropAnimating) return style;

    return {
      ...style,
      animation: "fadeIn 1s",
      transition: "all 0.5s ease",
    };
  };

  const disableUp: boolean =
    index === 0 || lockedItems.some((item) => item.index === index - 1);
  const disableDown: boolean =
    index >= itemList.length - 1 || lockedItems.some((item) => item.index === index + 1);

  if (locked) {
    return (
      <LockedSortCard
        question={question}
        key={question.id}
        index={index}
        provided={provided}
        snapshot={snapshot}
        innerRef={provided.innerRef}
        lockedItems={lockedItems}
        handleItemLock={handleItemLock}
        moveItem={moveItem}
        itemList={itemList}
      />
    );
  }

  return (
    <Card
      className="draggableCard"
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={innerRef}
      id={question.id}
      style={getStyle(provided.draggableProps.style, snapshot)}
      bodyStyle={{ padding: 12 }}
      index={question.id}
    >
      <Row justify="space-between" style={{ height: 110 }}>
        <Col
          span={1}
          style={{
            height: "100%",
            cursor: "grab",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Space direction="vertical" align="center" style={{ width: "100%" }}>
            <Button
              onClick={() => moveItem("up", questionId, index)}
              type="text"
              icon={<UpOutlined />}
              disabled={disableUp}
              key={`up-${questionId}`}
            />

            <Typography.Paragraph
              strong
              style={{
                fontSize: 18,
                marginBottom: 0,
              }}
              key={`orderNumber-${questionId}`}
            >
              {index + 1}
            </Typography.Paragraph>

            <Button
              onClick={() => moveItem("down", questionId, index)}
              type="text"
              icon={<DownOutlined />}
              disabled={disableDown}
              key={`down-${questionId}`}
            />
          </Space>
        </Col>
        <Col
          span={20}
          id="paragraphCol"
          style={{
            height: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              height: "100%",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Typography.Paragraph
              style={{
                fontSize: 14,
                marginBottom: 0,
              }}
              ellipsis={{
                rows: 3,
              }}
            >
              {questionText}
            </Typography.Paragraph>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: 32,
              bottom: 6,
              position: "absolute",
              paddingTop: 12,
              borderTop: "1px solid #F0F0F0",
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Space split={<Divider type="vertical" />} align="center" size="small">
              {/* // * When sorting cat questions, show group tag, reverse for group org? */}
              <ParentTag type="category" />
              {question.groupId && <ParentTag type="group" />}
              {mapTagToType(dataType, question.id)}
            </Space>
            <LockIconButton
              locked={locked}
              handleItemLock={handleItemLock}
              itemId={questionId}
              index={index}
            />
          </div>
        </Col>
        <Col
          span={1}
          style={{
            height: "100%",
            textAlign: "right",
            cursor: "grab",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MenuOutlined
            style={{
              fontSize: 24,
              fontWeight: "bolder",
              color: "#8C8C8C",
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

const LockedSortCard: React.FC<any> = (props) => {
  const { question, index, provided, snapshot, innerRef, handleItemLock } = props;
  const questionText = question.question;

  const getStyle = (style: any, snapshot: any) => {
    if (!snapshot.isDropAnimating) return style;

    return {
      ...style,
      animation: "fadeIn 1s",
      transition: "all 0.5s ease",
    };
  };

  return (
    <Card
      className="disabledDraggableCard"
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={innerRef}
      id={question.id}
      style={getStyle(provided.draggableProps.style, snapshot)}
      bodyStyle={{ padding: 12 }}
      index={question.id}
    >
      <Row justify="space-between" align="middle" style={{ height: 45 }}>
        <Col
          span={1}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            cursor: "no-drop",
          }}
        >
          <Typography.Paragraph
            strong
            style={{
              fontSize: 18,
              marginBottom: 0,
            }}
          >
            {index + 1}
          </Typography.Paragraph>
        </Col>
        <Col span={20} style={{ textAlign: "left", cursor: "no-drop" }}>
          <Typography.Paragraph
            style={{
              fontSize: 14,
              marginBottom: 0,
            }}
            ellipsis={{
              rows: 2,
            }}
          >
            {questionText}
          </Typography.Paragraph>
        </Col>
        <Col span={1} style={{ textAlign: "left" }}>
          <LockIconButton
            locked={true}
            itemId={question.id}
            handleItemLock={handleItemLock}
            index={index}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SortCard;
