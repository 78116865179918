import { gql } from "graphql-tag";

export const ApplicationFragments = gql`
  fragment ApplicationFields on ApplicationNode {
    id
    createdOn
    updatedOn
    name
    platform
    rmfActionPath
    status
    totalProgress
    totalRisk
    timeDelta
    appTime
    authorizerName
    authorizerEmail
    authorizerPhoneNumber
    organizationId
  }
`;

export const Application = {
  GetMy: () => gql`
    ${ApplicationFragments}
    query myApplications {
      myApplications {
        ...ApplicationFields
      }
    }
  `,
  GetAllBasicList: () => gql`
    query allApplicationsBasicList {
      allApplications {
        id
        name
      }
    }
  `,
  GetAll: () => gql`
    ${ApplicationFragments}
    query allApplications {
      allApplications {
        ...ApplicationFields
      }
    }
  `,
  GetOne: () => gql`
    ${ApplicationFragments}
    query application($id: String!) {
      application(id: $id) {
        ...ApplicationFields
        organization {
          name
          id
        }
        questionModules {
          id
          name
          type
          isLocked
          isComplete
          totalProgress
          totalCategories
          checkpoint {
            triggered
            questionsLocked
            checkpointSessionCompleted
            isWorkflowCheckpoint
          }
        }
      }
    }
  `,
  Create: () => gql`
    ${ApplicationFragments}
    mutation createApplication(
      $name: String!
      $organizationId: String!
      $platform: String
      $status: String
      $authorizer: PersonObject
      $rmfActionPath: RMFActionPath
    ) {
      createApplication(
        name: $name
        platform: $platform
        status: $status
        organizationId: $organizationId
        authorizer: $authorizer
        rmfActionPath: $rmfActionPath
      ) {
        ...ApplicationFields
      }
    }
  `,
  Update: () => gql`
    ${ApplicationFragments}
    mutation updateApplication(
      $id: String!
      $organizationId: String
      $name: String
      $platform: String
      $status: String
      $authorizer: PersonObject
      $rmfActionPath: RMFActionPath
    ) {
      updateApplication(
        id: $id
        organizationId: $organizationId
        name: $name
        platform: $platform
        status: $status
        authorizer: $authorizer
        rmfActionPath: $rmfActionPath
      ) {
        ...ApplicationFields
      }
    }
  `,
  Delete: () => gql`
    mutation deleteApplication($id: String!) {
      deleteApplication(id: $id) {
        deletedId
      }
    }
  `,
};
