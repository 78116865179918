export const P23 = (props) => {
  return (
    <>
      <div className="itcsc" style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "left",
          }}
        >
          <ol type="a" style={{ padding: "80px", position: "relative" }}>
            <div style={{ position: "absolute", top: "-130px" }}>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
              <li style={{ visibility: "hidden" }}></li>
            </div>
            <li>
              Department of the Air Froce Guidance Memorandum to AF Instruction 17-101,
              <i>
                Risk Management Framework (RMF) for Air Force Information Technology (IT),{" "}
              </i>
              10 June 2022
            </li>
            <li>
              For more information on preparing or processing an “Assess Only” package, refer
              to the DAF Assess Only Guidance Folder on the DAF RMF Knowledge Service at
              https://rmfks.osd.mil/rmf/collaboration/Component%
              20Workspaces/AirForce/Pages/default.aspx
            </li>
            <li>CNSSI 1253F Attachment (Atch) 4, Intelligence Overlay, 19 April 2016</li>
            <li>CNSSI 1253F Atch 3, Cross Domain Solution Overlay, 12 September 2017</li>
            <li>
              The Nuclear Command, Control & Communications (NC3) Overlay is found at
              https://rmfks.osd.mil/rmf/HelpandResources/References/Reference%20Library/NC3_Overlay.pdf
            </li>
            <li>
              CNSSI 1253F Atch 2, <i>Space Platform Overlay</i>, 23 February 2018
            </li>
            <li>
              CNSSI 1253F Atch 5, <i>Classified Information Overlay</i>, 09 May 2014
            </li>
            <li>
              NIST SP 800-39,{" "}
              <i>
                Managing Information Security Risk: Organization, Mission, and Information
                System View
              </i>
              , 01 March 2011
            </li>
            <li>
              NIST SP 800-122,{" "}
              <i>
                Guide to Protecting the Confidentiality of Personally Identifiable Information
                (PII)
              </i>
              , 06 April 2010
            </li>
            <li>
              DoD Manual (DoDM) 5200.01 Volume 1, Change 4,{" "}
              <i>
                DoD Information Security Program: Overview, Classification, and
                Declassification
              </i>
              , 28 July 2020
            </li>
            <li>
              DoDI 5210.02 Change 2,{" "}
              <i>
                Access to and Dissemination of Restricted Data and Formerly Restricted Data
              </i>
              , 21 August 2018
            </li>
            <li>
              DoDM 5200.01 Volume 2, Change 4,{" "}
              <i>DoD Information Security Program: Marking of Information</i>, 28 July 2020
            </li>
            <li>
              DoD Joint Special Access Program Implementation Guide (JSIG), 11 April 2016
            </li>
            <li>
              DoD Directive 5230.11,{" "}
              <i>
                Disclosure of Classified Military Information to Foreign Governments and
                International Organizations
              </i>
              , 16 June 1992
            </li>
            <li>
              CNSSI 1253,{" "}
              <i>
                Security Categorization and Control Selection for National Security Systems
              </i>
              , 27 March 2014
            </li>
            <li>
              Federal Information Processing Standards Publication 199,{" "}
              <i>
                Standards for Security Categorization of Federal Information and Information
                Systems
              </i>
              , February 2004
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};
