import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Row, Col, Button } from "antd";

import pages from "./Pages";

export const ITCSCv10Pages = pages;

export const ITCSCv10Header = (props) => {
  const document = props?.state?.document;
  const pageNumber = props?.pageNumber || 0

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <Row justify="center" align="top" className="itcsc">
      <div style={{ width: "150px", textAlign: 'center' }}>
        {pageNumber < 12 ?
          <>
            <h2 style={{ marginBottom: '0px' }}>OVERALL</h2>
            <SheetClassification elementProps={elementProps} />
          </>
          : null
        }
      </div>
    </Row >
  );
};

export const ITCSCv10Footer = (props) => {
  const style = {
    paddingTop: 5,
    marginBottom: 0,
    fontSize: 11,
  };

  const { state, pageNumber } = props;

  const document = state?.document;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: pageNumber
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', alignItems: 'end' }}>
      <div style={{ fontSize: '10px', textAlign: 'center' }}>
        DAF ITCSC, v10.1, 18 Apr 2024
      </div>
      <div style={{ width: "150px", textAlign: 'center' }}>
        {pageNumber < 12 ?
          <>
            <h2 style={{ marginBottom: '0px' }}>OVERALL</h2>
            <SheetClassification
              elementProps={elementProps}
            />
          </>
          : null}
      </div>
      <div></div>
    </div>
  );
};

export const ResetButton = ({ children }) => {
  return (
    <div className="reset-button">
      <Button size="small" style={{ outline: '4px solid black', borderRadius: 0, minWidth: '150px' }}>
        {children}
      </Button>
    </div>
  )
}

export const ClassificationSelect = ({ elementProps, config, style = {} }) => {
  if (!config.label) console.log("no label in config", { elementProps });
  if (elementProps.pageNumber === 2) {
    console.log('ClassSelect', config)
  }

  return (
    <EditableSelect
      {...elementProps}
      style={{ fontSize: 11, ...style }}
      label={config.label}
      fieldName={config.value}
      pageNumber={1}
      options={[
        { label: "TOP SECRET (TS)", value: `${config.value}_top_secret`, },
        { label: "SECRET (S)", value: `${config.value}_secret`, },
        { label: "CONFIDENTIAL", value: `${config.value}_confidential`, },
        { label: "CUI", value: `${config.value}_cui`, },
        { label: "UNCLASSIFIED (U)", value: `${config.value}_unclassified`, },
        { label: "TS/SCI", value: `${config.value}_tssci` },
      ]}
    />
  );
};

export const SheetClassification = ({ elementProps }) => {
  return (
    <ClassificationSelect
      style={{ width: "100%" }}
      elementProps={elementProps}
      config={{
        label: "Select Classification",
        value: "document_classification",
      }}
    />
  );
};

export const SecurityClassificationSelect = ({ elementProps, config, style = {} }) => {
  return <EditableSelect
    {...elementProps}
    label={config.label}
    fieldName={config.value}
    style={style}
    options={[
      { label: 'Top Secret/Sensitive Compartmented Information (TS/SCI)', value: `${config.value}_tscsi` },
      { label: 'Top Secret (TS)', value: `${config.value}_topsecret` },
      { label: 'Secret (S)', value: `${config.value}_secret` },
      { label: 'Confidential', value: `${config.value}_confidential` },
      { label: 'Controlled Unclassified Information (CUI)', value: `${config.value}_cui` },
      { label: 'Unclassified (U)', value: `${config.value}_unclassified` },
    ]}
  />
}

export const InformationTypeSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "HIGH", value: `${config.value}_high` },
        { label: "MODERATE", value: `${config.value}_moderate` },
        { label: "LOW", value: `${config.value}_low` },
      ]}
    />
  );
};

export const FirstCategorySubmissionSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "Yes", value: `${config.value}_yes` },
        { label: "No", value: `${config.value}_no` },
      ]}
    />
  );
};

export const YesNoSelect = ({ elementProps, fieldName, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={'Select One'}
      fieldName={fieldName}
      options={[
        { label: "Yes", value: `${fieldName}_yes` },
        { label: "No", value: `${fieldName}_no` },
      ]}
    />
  );
};

export const ComplianceStatusSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "Compliant", value: `${config.value}_compliant` },
        { label: "Non-compliant", value: `${config.value}_non_compliant` },
        { label: "Not Assessed", value: `${config.value}_not_assessed` },
        { label: "Exempt", value: `${config.value}_exempt` },
      ]}
    />
  );
};

export const AOBoundarySelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={"SELECT AO Boundary"}
      fieldName={config.value}
      options={[
        { label: "Air Force Offensive Cyber Operations & Cyberspace Weapons Systems (AF OCO & CWS)", value: `${config.value}_air_force_offensive_cyber_operationscyberspace_weapons_systems_af_oco_cws` },
        { label: "Air Force Office of Special Investigations (AFOSI)", value: `${config.value}_air_force_office_of_special_investigations_afosi` },
        { label: "Air Force Operational Test and Evaluation Center (AFOTEC)", value: `${config.value}_air_force_operational_test_and_evaluation_center_afotec` },
        { label: "Air Force Special Operations Command (AFSOC)", value: `${config.value}_air_force_special_operations_command_afsoc` },
        { label: "Air Force Sustainment Center Software Enterprise (AFSC SWENT)", value: `${config.value}_air_force_sustainment_center_software_enterprise_afsc_swent` },
        { label: "Air Force Weather Weapon Systems", value: `${config.value}_air_force_weather_weapon_systems` },
        { label: "Aircraft", value: `${config.value}_aircraft` },
        { label: "Civil Engineering Control Systems (CE CS)", value: `${config.value}_civil_engineering_control_systems_ce_cs` },
        { label: "CE IT and Platforms", value: `${config.value}_ce_it_and_platforms` },
        { label: "Command and Control (C2)", value: `${config.value}_command_and_control_c2` },
        { label: "Cyberspace Innovation", value: `${config.value}_cyberspace_innovation` },
        { label: "DAF Cloud", value: `${config.value}_daf_cloud` },
        { label: "DAF DevSecOps", value: `${config.value}_daf_devsecops` },
        { label: "DAF Enterprise", value: `${config.value}_daf_enterprise` },
        { label: "DoD Cyber Crime Center (DC3)", value: `${config.value}_dod_cyber_crime_center_dc3` },
        { label: "DoD Mission Partner Environment (DoD MPE)", value: `${config.value}_dod_mission_partner_environment_dod_mpe` },
        { label: "Depot Support Systems (DSS)", value: `${config.value}_depot_support_systems_dss` },
        { label: "Developmental Test and Evaluation (DT&E)", value: `${config.value}_developmental_test_and_evaluation_dte` },
        { label: "Finance", value: `${config.value}_finance` },
        { label: "Force Development", value: `${config.value}_force_development` },
        { label: "Force Protection (A4S)", value: `${config.value}_force_protection_a4s` },
        { label: "Headquarters Air Force (HAF)", value: `${config.value}_headquarters_air_force_haf` },
        { label: "Human Resource Management (HRM)", value: `${config.value}_human_resource_management_hrm` },
        { label: "Industrial Depot Maintenance (IDM)", value: `${config.value}_industrial_depot_maintenance_idm` },
        { label: "Logistics", value: `${config.value}_logistics` },
        { label: "Operational Test and Training Infrastructure (OTTI)", value: `${config.value}_operational_test_and_training_infrastructure_otti` },
        { label: "Rapid Cyber Acquisition (RCA)", value: `${config.value}_rapid_cyber_acquisition_rca` },
        { label: "Science & Technology (S&T)", value: `${config.value}_sciencetechnology_st` },
        { label: "Special Access Program (SAP)", value: `${config.value}_special_access_program_sap` },
        { label: "Strategic Systems (SS)", value: `${config.value}_strategic_systems_ss` },
        { label: "Systems-of-Systems Technology Integration Tool Chain for Heterogeneous Electronic Systems (STITCHES)", value: `${config.value}_systems_of_systems_technology_integration_tool_chain_for_heterogeneous_electronic_systems_stitches` },
        { label: "US Air Forces in Europe (USAFE)", value: `${config.value}_us_air_forces_in_europe_usafe` },
        { label: "US Space Force (USSF)", value: `${config.value}_us_space_force_ussf` },
        { label: "USSF Space Systems Command (SSC)", value: `${config.value}_ussf_space_systems_command_ssc` },
        { label: "Weapons", value: `${config.value}_weapons` },
      ]}
    />
  );
};


export const EmassClassificationSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "NIPR", value: `${config.value}_nipr` },
        { label: "SIPR", value: `${config.value}_sipr` },
        { label: "N/A", value: `${config.value}_na` },
      ]}
    />
  );
};


export const SpecialHandlingCaveatsSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: 'NOFORN', value: `${config.value}_noforn` },
        { label: 'NATO', value: `${config.value}_nato` },
        { label: 'FIVE EYES (FVEY)', value: `${config.value}_fvey` },
        { label: 'None', value: `${config.value}_none` },
      ]}
    />
  );
};

// prettier-ignore
export const RmfActionSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "Assess & Authorize", value: `${config.value}_assess_and_authorize` },
        { label: "Assess Only - Assess and Incorporate", value: `${config.value}_assess_and_incorporate` },
        {
          label: "Assess Only - Assess and Approve for Use and Inherit Process",
          value: `${config.value}_assess_and_approve`,
        },
      ]}
    />
  );
};

export const PrivacyFactorSelect = ({ elementProps, fieldName, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldName={fieldName}
      options={[
        { label: "HIGH", value: `${fieldName}_high` },
        { label: "MODERATE", value: `${fieldName}_moderate` },
        { label: "LOW", value: `${fieldName}_low` },
      ]}
    />
  );
};

export const CategorizationInformation = ({ elementProps, fieldName, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label="   "
      fieldName={fieldName}
      options={[
        { label: "HIGH", value: `${fieldName}_high` },
        { label: "MODERATE", value: `${fieldName}_moderate` },
        { label: "LOW", value: `${fieldName}_low` },
      ]}
    />
  );
};
