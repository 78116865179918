import { Button, Divider, Form, Input, Modal, Row, Typography } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnLabel } from "../Shared/types";
import { QuestionType } from "types";

interface FinalData {
  columnLabels: ColumnLabel[];
  rowLabels?: string[];
}

const QuestionTypeModal = (props) => {
  const {
    open,
    onClose,
    columnState,
    setColumnState,
    rowState,
    editingColumn,
    setEditingColumn,
  } = props;
  const [form] = Form.useForm();

  const editColumn = editingColumn;

  const initialValues =
    editColumn?.answers && editColumn.answers.length > 0 ? editColumn.answers : ["", ""];

  const data = {
    columnState,
    rowState,
  };

  const mapAnswerData = async (formValues: any) => {
    const currentColumns = [...columnState];

    const newColumn = {
      ...editColumn,
      questionType: QuestionType.MultipleChoicePickOne,
      answers: formValues,
    };

    if (formValues === null) {
      if(editColumn?.answers === undefined){
        delete newColumn.answers
        newColumn.questionType = undefined
      }
    }

    const newColumns = currentColumns.map((col) => {
      if (col.dataIndex === editColumn.dataIndex) {
        return newColumn;
      }
      return col;
    });

    return newColumns;
  };

  const onFinish = async () => {
    const formValues = form.getFieldsValue(true).pickOneAnswers;

    form
      .validateFields()
      .then((values) => {
        mapAnswerData(formValues).then((newColumnState) => {
          setColumnState(newColumnState);
          setEditingColumn(null);
          onClose();
        });
      })
      .catch((errorInfo) => {
        console.log("%cQuestionTypeModal: onFinish:Error", "color:red", {
          errorInfo,
        });
      });
  };

  const onCancel = async () => {
    mapAnswerData(null).then((newColumnState) => {
      setColumnState(newColumnState);
      setEditingColumn(null);
      onClose();
    });
  };

  const handleOnSave = (e) => {
    if (e.target.value == "") {
      return;
    }
  };

  // ? form.list vs controlled custom form for dynamic pick one select options
  return (
    <Modal
      title="Answer Choice Options"
      open={open}
      onCancel={onCancel}
      onOk={onFinish}
      okText="Save"
      destroyOnClose={true}
    >
      <Form form={form} name="QuestionTypeForm" layout="vertical">
        {/* <Divider orientation="left">
          <Typography.Title level={3} style={{ fontSize: 18 }}>
            Answers
          </Typography.Title>
        </Divider> */}
        <Form.List name="pickOneAnswers" initialValue={initialValues}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key} style={{ width: "100%" }}>
                  <Form.Item
                    {...field}
                    key={field.key}
                    label={`Answer ${index + 1}`}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Answer Choice cannot be empty",
                      },
                    ]}
                  >
                    <Input
                      style={{ minWidth: 250, maxWidth: "calc(100% - 32px)" }}
                      value={field.key}
                      onPressEnter={handleOnSave}
                      onBlur={handleOnSave}
                      addonAfter={
                        fields.length > 2 && (
                          <DeleteOutlined onClick={() => remove(field.name)} />
                        )
                      }
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
              ))}
              <Row justify="center">
                <Form.Item>
                  <Button onClick={() => add()} type="primary" icon={<PlusOutlined />}>
                    Add Option
                  </Button>
                </Form.Item>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default QuestionTypeModal;
