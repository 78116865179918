import React, { useEffect, useState } from "react";
import InfoTip from "../../../../Common/InfoTip";
import { createGaugeDial } from ".";
import { Typography, Card, Space, Tag, Tooltip } from "antd";
import { Gauge, GaugeConfig } from "@ant-design/plots";

const { Title, Text } = Typography;

interface Props {
  loading: boolean;
  time: number | any;
  risk: number | any;
}

const GaugeMetrics: React.FC<Props> = ({ loading, time, risk }) => {
  const timeValueInPercent = time / 365;
  const riskValueInPercent = risk / 100;

  const timeStatus =
    timeValueInPercent < 0.33
      ? "Expedited"
      : timeValueInPercent >= 0.33 && timeValueInPercent <= 0.66
      ? "Normal"
      : "Extended";
  const riskStatus =
    riskValueInPercent < 0.33
      ? "Low"
      : riskValueInPercent >= 0.33 && riskValueInPercent <= 0.66
      ? "Medium"
      : "High";

  const timeConfig: GaugeConfig = {
    ...gaugeConfig,
    loading: loading,
    percent: timeValueInPercent,
    axis: {
      ...gaugeConfig.axis,
      label: {
        ...gaugeConfig.axis.label,
        formatter: (_: any, item: any) => {
          const val = 365 * item.value;
          return val;
        },
      },
    },
    statistic: {
      content: {
        ...gaugeConfig.statistic.content,
        formatter: () => (time !== undefined ? `${time} Hours` : "Error"),
      },
    },
  };

  const riskConfig: GaugeConfig = {
    ...gaugeConfig,
    loading: loading,
    percent: riskValueInPercent,
    axis: {
      ...gaugeConfig.axis,
      label: {
        ...gaugeConfig.axis.label,
        formatter: (_: any, item: any) => {
          const val = 100 * item.value;
          return val;
        },
      },
    },
    statistic: {
      content: {
        ...gaugeConfig.statistic.content,
        formatter: () => (risk !== undefined ? `${risk}%` : "Error"),
      },
    },
  };

  const timelineHelpText =
    "CSAR’s Timeline Gauge displays a timeline to document submissions. This timeline measures how long you might take to move through Quick Start and complete your ITCSC, PIA, and PCIL. This number is an estimate and does not represent a commitment or guarantee by CSAR.";
  const riskHelpText =
    "CSAR’s Risk Gauge displays the risk of your documents being returned. This number is an estimate and does not represent a commitment or guarantee by CSAR.";

  return (
    <>
      <Card className="shadowed-static" bodyStyle={{ padding: 15 }}>
        <Title level={3} style={{ marginBottom: 0 }}>
          Metrics
        </Title>
      </Card>
      <br />

      <MetricCard
        title="Timeline"
        helpText={timelineHelpText}
        status={timeStatus}
        config={timeConfig}
        style={{ marginBottom: 16 }}
      />
      <MetricCard
        title="Risk"
        helpText={riskHelpText}
        status={riskStatus}
        config={riskConfig}
      />
    </>
  );
};

interface MetricCardProps {
  title: string;
  helpText: string;
  status: string;
  config: any;
  style?: any;
}

const MetricCard: React.FC<MetricCardProps> = ({ title, helpText, status, config, style }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    // must wait for the card to render before rendering the gauge so avoid ui flash bug
    setTimeout(() => {
      setRender(true);
    }, 200);
  }, []);

  const statuses = {
    Expedited: {
      label: "Expedited",
      color: "green",
    },
    Normal: {
      label: "Normal",
      color: "orange",
    },
    Extended: {
      label: "Extended",
      color: "red",
    },
    Low: {
      label: "Low",
      color: "green",
    },
    Medium: {
      label: "Medium",
      color: "orange",
    },
    High: {
      label: "High",
      color: "red",
    },
  };
  return (
    <Card
      className="shadowed-static"
      style={{
        overflow: "hidden",
        maxHeight: 300,
        ...style,
      }}
      bodyStyle={{
        paddingBottom: 45,
      }}
      title={
        <Space>
          <Text>Estimated {title}</Text>
          <InfoTip title={helpText} />
        </Space>
      }
      actions={[
        <Tooltip title={`The ${title} gauge status`}>
          <Tag color={statuses[status].color}>{statuses[status].label}</Tag>
        </Tooltip>,
      ]}
    >
      <Space
        style={{ maxWidth: 250, maxHeight: 350, overflow: render ? "visible" : "hidden" }}
      >
        <Gauge {...config} />
      </Space>
    </Card>
  );
};

export default GaugeMetrics;

const gaugeConfig = {
  style: { maxWidth: 180, maxHeight: 90 },
  autoFit: true,
  innerRadius: 0.85,
  startAngle: Math.PI,
  endAngle: 2 * Math.PI,
  range: {
    ticks: [0, 1 / 3, 2 / 3, 1],
    color: ["green", "orange", "crimson"],
  },
  axis: {
    tickLine: null,
    label: {
      offset: -15,
    },
  },
  indicator: {
    shape: "custom-gauge-indicator",
    pointer: {
      style: {
        stroke: null,
        lineWidth: 1,
        fill: "#888",
      },
    },
  },
  statistic: {
    content: {
      style: {
        fontSize: "16px",
        lineHeight: "88px",
      },
    },
  },
};

createGaugeDial();
