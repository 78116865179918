import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

export const P5 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pcilv2"
        style={{
          width: "95%",
          fontSize: 12,
          paddingTop: 20,
        }}
      >
        <div style={{ fontSize: 16, marginLeft: 20, display: "flex" }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
          <span>
            <b>
              <i>
                The Privacy Officer will provide a signed copy of the worksheet that will
                accompany the DD Form 2930 (Privacy Impact Assessment), provided by the
                ISSM for the information system.
              </i>
            </b>
          </span>
        </div>
        <br />
        <div style={{ paddingLeft: 20 }}>
          <b style={{ fontSize: 16 }}>2. Analyze PII Data and Determine PCIL</b>
          <div style={{ paddingLeft: 20 }}>
            <b style={{ fontSize: 16 }}>
              <span style={{ paddingRight: 10 }}>2.1 </span>Does the information system
              contain, process, or transact PII?
            </b>
            <ul style={{ marginTop: 20 }}>
              <p>
                PII is defined in Reference (e) as (i) data elements which alone can distinguish or trace an individual’s identity,
                i.e., unique identifiers; (ii) non-PII that becomes PII when it identifies an individual in aggregate, i.e.,
                compilation effect; and (iii) non-PII that becomes PII when combined with a unique identifier or data elements
                that have aggregated to become PII, i.e., by association.
              </p>
              <p>
                Given the definition provided, determine if the applicable system contains PII. In order to make this
                determination, consider the Privacy Impact Assessment (PIA), the system data elements/dictionary, mission
                description, and system data description. All of these items should be discussed in Reference (i).
              </p>
              <p>
                *Note: Historical data maintained within the system still requires PII protections and should be considered in the
                analysis.
              </p>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div>
                  <EditableRadio {...elementProps} fieldName="5_transact_pii_yes" />
                  <span style={{ marginLeft: 10, fontSize: 14 }}>YES</span>
                </div>
                <div>
                  <EditableRadio {...elementProps} fieldName="5_transact_pii_no" />
                  <span style={{ marginLeft: 10, fontSize: 14 }}>NO</span>
                </div>
              </div>
              <br />

              <div style={{ fontSize: 16, marginLeft: -60 }}>
                <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
                <b>
                  <i>
                    If the response selected for item 2.1 is, “NO,” then sign at Appendix B.
                  </i>
                </b>
              </div>
              <div style={{ fontSize: 16, marginLeft: -60 }}>
                <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
                <b>
                  <i>
                    If the response selected for item 2.1 is, “YES,” then continue to item 2.2.
                  </i>
                </b>
              </div>
            </ul>

            <div style={{ paddingLeft: 40 }}>
              <b style={{ fontSize: 16, display: "flex" }}>
                <span style={{ paddingRight: 10 }}>2.2 </span> Use the space below to identify
                the types of PII and data elements contained in, processed by, or transacted
                through the information system:
              </b>
              <ul>
                <div>
                  <EditableField
                    {...elementProps}
                    fieldName="5_identify_types_of_pii_text"
                    style={{ width: "100%", height: 100 }}
                  />
                </div>
              </ul>
            </div>

            <div style={{ paddingLeft: 40 }}>
              <b style={{ fontSize: 16, display: "flex" }}>
                <span style={{ paddingRight: 10 }}>2.3 </span> Estimate the number of records
                containing PII:
              </b>
              <ul>
                <div>
                  <EditableField
                    {...elementProps}
                    fieldName="5_estimate_records_containing_pii_text"
                    style={{ width: "100%", height: 100 }}
                  />
                </div>
              </ul>
            </div>

            <div style={{ paddingLeft: 40 }}>
              <b style={{ fontSize: 16, display: "flex" }}>
                <span style={{ paddingRight: 10 }}>2.4 </span> Define the user community:
              </b>
              <ul>
                <div>
                  <EditableField
                    {...elementProps}
                    fieldName="5_define_user_com_text"
                    style={{ width: "100%", height: 100 }}
                  />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
