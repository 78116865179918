import { Typography } from "antd";

const { Paragraph } = Typography;

const QuoteLabel = ({ children }) => {
  return (
    <Paragraph>
      <blockquote style={{ margin: "0px 2px" }}>{children}</blockquote>
    </Paragraph>
  );
};

export default QuoteLabel;
