import { Col, Row, Segmented, Space, Tag } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import QuestionLinkTable from "./LinkQuestionField";
import CustomFormItem from "components/Common/RFForm/CustomFormItem";

const QuestionSelectField: React.FC<any> = (props) => {
  const {
    input: { onChange, value },
    label,
    setWidth,
    mode,
    setMode,
    meta,
  } = props;

  useEffect(() => {
    // increase drawer width to fit table
    setWidth(1200);
  }, []);

  useEffect(() => {
    return () => setWidth(600);
  }, []);

  const fullLabel = `${label} | ${
    value.length > 0 ? `${value.length} selected` : "0 selected"
  }`;

  return (
    <CustomFormItem
      label={
        <Row>
          <Col flex="100">
            <Space>
              {fullLabel} <ModeSelector mode={mode} setMode={setMode} />
            </Space>
          </Col>
        </Row>
      }
    >
      {meta.error && meta.touched && (
        <Col flex="100" style={{ marginBottom: 12 }}>
          <Tag color="red">Please select at least 1 question</Tag>
        </Col>
      )}
      <QuestionLinkTable onChange={onChange} value={value} mode={mode} />
    </CustomFormItem>
  );
};

const ModeSelector = ({ mode, setMode }) => {
  const options = [
    { label: "View", value: "view", icon: <EyeOutlined /> },
    { label: "Edit", value: "edit", icon: <EditOutlined /> },
  ];

  const onChange = (value: string | number) => {
    if (value === "edit") {
      setMode("edit");
    } else {
      setMode("view");
    }
  };

  return <Segmented onChange={onChange} value={mode} options={options} size="small" />;
};

export default QuestionSelectField;
