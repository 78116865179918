import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, EmassClassificationSelect, RmfActionSelect } from "..";

export const P15 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  const EmassClassificationConfig = {
    label: "Select One",
    value: "emass_classification",
  };

  const RmfActionConfig = {
    label: "Select One",
    value: "rmf_action",
  };

  const CIMarking = {
    label: "Select Marking",
    value: "summary_marking",
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>Summary</b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={CIMarking} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              padding: "0 5px",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b>Technical Description/Purpose (Section 2)</b>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "225px",
                fontSize: 14,
              }}
            >
              <EditableField
                borderless
                {...elementProps}
                fieldName="technical_description_purpose_s2"
                // fieldId={document.variableFields[446]?.id}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>System Name:</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="sum_system_name"
                    // fieldId={document.variableFields[447]?.id}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>System Acronym:</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="sum_system_acronym"
                    // fieldId={document.variableFields[448]?.id}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>Version:</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="sum_version"
                    // fieldId={document.variableFields[449]?.id}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>eMASS ID:</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="sum_emass_id"
                    // fieldId={document.variableFields[450]?.id}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>eMASS Classification:</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: 14,
                  }}
                >
                  {/* <EditableField
                    borderless
                    {...elementProps}
                    fieldName="sum_emass_classification"
                    // fieldId={document.variableFields[451]?.id}
                  /> */}
                  <EmassClassificationSelect
                    style={{ width: "100%", height: "100%", border: "none" }}
                    elementProps={elementProps}
                    config={EmassClassificationConfig}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>ITIPS ID:</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="sum_itips_id"
                    // fieldId={document.variableFields[452]?.id}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>DITPR ID:</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="sum_ditpr_id"
                    // fieldId={document.variableFields[453]?.id}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>AFID:</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="sum_afid"
                    // fieldId={document.variableFields[454]?.id}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.3,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>RMF ACTION:</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: 14,
                  }}
                >
                  {/* <EditableField
                    borderless
                    {...elementProps}
                    fieldName="sum_rmf_action"
                    // fieldId={document.variableFields[455]?.id}
                  /> */}

                  <RmfActionSelect
                    style={{ width: "100%", border: "none", height: "100%" }}
                    elementProps={elementProps}
                    config={RmfActionConfig}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
