import { gql } from "graphql-tag";

export const QuestionModule = {
  GetAll: () => gql`
    query questionModules {
      allQuestionModules {
        id
        name
        order
        type
        hasCheckpoint
        categories {
          id
          name
          order
          hasCheckpoint
          allQuestions {
            id
            categoryId
            variableName
            questionType
            question
            order
            helpText
            groupId
            groupOrder
            # group {
            #   id
            #   header
            #   order
            #   description
            #   groupType
            #   categoryId
            # }
            answers {
              id
              # answer
              # riskIncrease
              # timeIncreaseInDays
            }
            category {
              id
              name
              order
              # module {
              #   id
              #   name
              # }
            }
            rmfActionPaths {
              path
              questionId
              answers {
                answerId
                # textAnswer
              }
            }
          }
        }
      }
    }
  `,
  GetDocumentEditorModules: () => gql`
    query documentEditorModules {
      allQuestionModules {
        id
        name
        categories {
          id
          name
          allQuestions:allQuestionsPlusGrids {
            id
            question
            questionType
            answers {
              id
              answer
            }
          }
        }
      }
    }
  `,
  WorkflowEditor: () => gql`
    query getWorkflowEditor {
      allQuestionModules {
        id
        name
        order
        type
        helpText
        hasCheckpoint
        categories {
          id
          name
          order
          hasCheckpoint
          allQuestions {
            id
            questionType
            question
            order
            helpText
            groupId
            groupOrder
            categoryId
            answers {
              id
              answer
              answerType
            }
            group {
              id
              header
              description
            }
          }
        }
        rmfActionPaths {
          path
          moduleId
        }
      }
    }
  `,
  GetBasicList: () => gql`
    query basicModuleList {
      allQuestionModules {
        id
        name
      }
    }
  `,
  GetByCategoryId: () => gql`
    query oneQuestionModule($filter: QuestionModuleFilterInputType) {
      allQuestionModules(filter: $filter) {
        id
        name
        order
      }
    }
  `,
  Create: () => gql`
    mutation createQuestionModule(
      $name: String!
      $order: Int
      $description: String
      $type: ModuleType!
      $helpText: String
    ) {
      createQuestionModule(
        name: $name
        order: $order
        description: $description
        type: $type
        helpText: $helpText
      ) {
        id
        name
        order
        type
      }
    }
  `,
  Update: () => gql`
    mutation updateQuestionModule(
      $id: String!
      $name: String
      $order: Int
      $helpText: String
      $rmfActionPath: [RMFActionPath]
    ) {
      updateQuestionModule(
        id: $id
        name: $name
        order: $order
        helpText: $helpText
        rmfActionPath: $rmfActionPath
      ) {
        id
        name
        order
        type
        rmfActionPaths {
          path
          moduleId
        }
      }
    }
  `,
  Delete: () => gql`
    mutation deleteQuestionModule($id: String!) {
      deleteQuestionModule(id: $id) {
        deletedId
      }
    }
  `,
  ToggleCheckpoint: () => gql`
    mutation toggleWorkflowCheckpoint($objectId: String!) {
      toggleWorkflowCheckpoint(objectId: $objectId) {
        ... on QuestionModuleNode {
          id
          hasCheckpoint
        }
        ... on QuestionCategoryNode {
          id
          hasCheckpoint
        }
      }
    }
  `,
};
