import { useState, useEffect } from "react";
import SmartTable from "components/Common/Tables/index";
import { mapTagToType } from "components/Common/Tags/QuestionType";
import { useModuleList } from "api/hooks/useModuleList";
import { useCategoryList } from "api/hooks/useCategoryList";
import { useQuestionList } from "api/hooks/useQuestionList";

interface Props {}

const LinkQuestionsTable: React.FC<any> = ({ onChange, value, ...rest }) => {
  const [state, setState] = useState(undefined);
  useEffect(() => {}, []);
  const { modules, loading: modulesLoading } = useModuleList();
  const { categories, loading: categoriesLoading } = useCategoryList();
  const { questions, loading: questionsLoading } = useQuestionList();

  console.log("%cQuestionLinkTableProps", "color:pink", { value, ...rest });

  const moduleFilters = modules?.map((module) => ({
    text: module.name,
    value: module.id,
  }));

  const categoryFilters = categories?.map((category) => ({
    text: category.name,
    value: category.id,
  }));

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      width: "50%",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => mapTagToType(record.questionType),
    },
    {
      title: "Module",
      dataIndex: "module",
      filters: moduleFilters,
      onFilter: (value: string, record) => {
        if (record?.moduleId) return record.moduleId.indexOf(value) === 0;
      },
      defaultFilteredValue: value,
    },
    {
      title: "Category",
      dataIndex: "category",
      filters: categoryFilters,
      onFilter: (value: string, record) => {
        if (record?.categoryId) return record.categoryId.indexOf(value) === 0;
      },
    },
  ];

  const onQuestionSelect = (selectedKeys) => {
    console.log("handleTableSelection", { selectedKeys });
    onChange(selectedKeys);
  };

  const dataSource = questions.map((question) => ({
    id: question.id,
    question: question.question,
    questionType: question.questionType,
    category: question?.category?.id ? question.category.name : "Unassigned",
    categoryId: question?.category?.id,
    module: question?.category?.module?.id ? question.category.module.name : "Unassigned",
    moduleId: question?.category?.module?.id,
  }));

  const rowSelection = {
    onChange: onQuestionSelect,
    selectedRowKeys: value ? value : [],
    selections: value ? true : false,
    preserveSelectedRowKeys: true,
  };

  const selectedQuestions: any[] = dataSource?.filter((question) =>
    value.includes(question.id),
  );

  const onTableChange = (change) => {
    console.log("%conTableChange: ", "color:green", { change });
  };

  const loading = questionsLoading || modulesLoading || categoriesLoading;

  console.log({ TableData: { dataSource, modules, categories, selectedQuestions } });

  return (
    <SmartTable
      data={dataSource}
      loading={loading}
      rowSelection={rowSelection}
      columns={columns}
      rowKey={(record) => record.id}
      onChange={onTableChange}
      pagination={{
        position: ["bottomLeft"],
        defaultPageSize: 25,
        pageSizeOptions: [25, 50, 100, 200],
        responsive: true,
      }}
      scroll={{
        x: 400,
      }}
      size="small"
    />
  );
};

export default LinkQuestionsTable;
