import { OrderedListOutlined, SettingOutlined } from "@ant-design/icons";
import { Col, Row, Segmented, Space } from "antd";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import { MapGroupTag } from "components/Common/Tags/QuestionGroup";
import { QuestionGroupNode } from "types";
import HeaderStat from "../../../Shared/PageHeaderStat";
import { EditorMode } from "../../GroupEditor";

interface Props {
  editorMode: string | number;
  setEditorMode: (mode: string | number) => void;
  group: QuestionGroupNode;
  loading: boolean;
  reset: (mode: EditorMode) => void;
}

const QuestionListHeader: React.FC<Props> = (props) => {
  const { editorMode, setEditorMode, group, loading, reset } = props;

  console.log({ GroupEditorHeader: group });

  return (
    <>
      <CustomPageHeader
        onBack={reset}
        title={group?.header}
        level={3}
        extra={
          <Space direction="vertical" style={{ width: "100%" }}>
            <label>Operation Mode: </label>
            <Segmented
              size="middle"
              onChange={setEditorMode}
              value={editorMode}
              disabled={loading}
              options={operationModes}
            />
          </Space>
        }
      >
        <Row justify="start">
          <Col span={6}>
            <HeaderStat title="Questions in Group" value={group?.questions?.length} />
          </Col>
          <Col span={6}>
            <HeaderStat
              title="Group Type"
              valueRender={() => MapGroupTag(group?.groupType, group?.id)}
            />
          </Col>
          <Col span={12}>
            <HeaderStat
              title="Parent Category"
              value={group?.categoryId ? group.category?.name : "Unassigned"}
            />
          </Col>
        </Row>
      </CustomPageHeader>
    </>
  );
};

const operationModes = [
  {
    label: "Order",
    value: "order",
    icon: <OrderedListOutlined />,
  },
  {
    label: "Questions",
    value: "manage",
    icon: <SettingOutlined />,
  },
];

export default QuestionListHeader;
