import { Table } from "antd";
import {
  mapTagToType,
  MultiChoiceTag,
  NoHelpTextTag,
} from "../../../Common/Tags/QuestionType";
import { QuestionNode, QuestionType } from "types";
import { ColumnsType } from "antd/es/table";

type Props = {
  data: QuestionNode;
};

const NestedOrphanTable: React.FC<Props> = (data) => {
  const nestedColumns: ColumnsType<QuestionNode> = [
    {
      title: "Data Type",
      dataIndex: "questionType",
      key: "questionType",
      render: (text, record, _index) => {
        return mapTagToType(text, record.id);
      },
    },
    {
      title: "Help Text",
      dataIndex: "helpText",
      key: "helpText",
      render: (text, record, _index) => {
        return text ? text : <NoHelpTextTag key={record.id} />;
      },
    },
    {
      title: "Answers",
      dataIndex: "answers",
      key: "answers",
      render: (_text, record, _index) => {
        if (
          record.questionType === QuestionType.MultipleChoicePickMany ||
          record.questionType === QuestionType.MultipleChoicePickOne
        ) {
          if (!record.answers || record.answers.length === 0) {
            return null;
          }
          return record.answers.map((rec, index) => (
            <>
              <MultiChoiceTag key={index} id={rec?.id!}>
                {rec?.answer!}
              </MultiChoiceTag>
            </>
          ));
        }
      },
    },
  ];

  return (
    <Table
      dataSource={Object.values(data)}
      columns={nestedColumns}
      rowKey={(row) => row.id}
      pagination={false}
      bordered
    />
  );
};

export default NestedOrphanTable;
