import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { MultiChoiceTag } from "components/Common/Tags/QuestionType";
import { AnswerChoiceInput, ConditionAnswerOption as AnswerType, QuestionNode } from "types";
import { AnswerConditionEnums, QuestionAnswerRiskLevelEnums } from "api/enums";
import Api from "api";
import { FieldKeys, FieldKeysEnum, QuestionEditorState } from "./reducer";
import ContentCard from "../../Shared/ContentCard";
import { SaveStatusEnum } from "../../Shared/types";
import { mapTagToType as mapConditionType } from "components/Common/Tags/AnswerCondition";

const { Text } = Typography;

type Props = {
  setEditState: any;
  setSaveState: any;
  fieldKey: FieldKeys;
  question: QuestionNode;
  loading: Loading;
  state: QuestionEditorState;
  refetchQuestion: any;
};

type AnswerRendererProps = {
  questionHasAnswers: boolean;
  question: QuestionNode;
};

type AnswersFormProps = {
  form: FormInstance<any>;
  question: QuestionNode;
  saveAnswers: any;
  onValuesChange: any;
};

const ManageAnswers: React.FC<Props> = ({
  setEditState,
  setSaveState,
  fieldKey,
  loading,
  state,
  question,
  refetchQuestion,
}) => {
  const [form] = Form.useForm();

  const editingThisField: boolean = state[fieldKey]?.editActive;
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  console.log({ initialValues: question.answers, question });
  const questionHasAnswers = question?.answers?.length > 0;

  const resetForm = () => {
    setCanSubmit(false);
    setEditState(fieldKey);
    form.resetFields();
  };

  const [updateAnswers, { loading: updatesLoading }] = useMutation(
    Api.Question.UpdateQuestionAnswerChoices(),
    {
      onCompleted: (res) => {
        console.log(res);
        message.success("Successfully updated question answers!", 5);
        setSaveState(FieldKeysEnum.questionAnswers, SaveStatusEnum.Success);
        refetchQuestion();
        setCanSubmit(false);
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 5);
        setSaveState(FieldKeysEnum.questionAnswers, SaveStatusEnum.Error);
      },
      refetchQueries: [
        {
          query: Api.QuestionModule.WorkflowEditor(),
        },
        {
          query: Api.Question.GetFullQuestionary(),
        },
        {
          query: Api.Question.GetAllOrphanedQuestions(),
        },
      ],
    },
  );

  const saveQuestionAnswers = (values: any) => {
    console.log({ values });

    setSaveState(FieldKeysEnum.questionAnswers, SaveStatusEnum.Loading);

    if (values.answers?.length >= 1) {
      const answerChoices = values.answers.map((ans: AnswerChoiceInput) => {
        return {
          answer: ans.answer,
          answerType: ans.answerType,
          additionalFieldType: ans.additionalFieldType,
          riskIncrease: ans.riskIncrease,
          timeIncreaseInDays: ans.timeIncreaseInDays,
        };
      });
      updateAnswers({
        variables: {
          questionId: question.id,
          answerChoices: [...answerChoices],
        },
      });
    } else {
      console.log("%cMANAGE ANSWERS ELSE", "color:red");
    }
  };

  const onValuesChange = (_changedValues: any, allValues: any) => {
    // acts as validation, checks for answer text in all rendered fields
    console.log("onValuesChange: allValues", { allValues });

    const submitResult = allValues.answers.some((answer: AnswerChoiceInput) => {
      const hasAnswerText = answer.answer;

      if (!hasAnswerText) {
        console.log("onValuesChange: !hasAnswerText", { answer });
        return false;
      }

      const hasAnswerCondition = answer.answerType !== undefined || answer.answerType !== null;
      const hasFieldType = answer.additionalFieldType !== undefined;

      if (hasAnswerCondition && !hasFieldType) {
        console.log("onValuesChange: hasAnswerCondition && !hasFieldType", { answer, hasAnswerCondition, hasFieldType });
        return false;
      }

      return true;
    });
    console.log("onValuesChange: canSubmit", { CanSubmit: submitResult });

    setCanSubmit(submitResult);
  };

  const CardActions = () => {
    // only show edit actions on selectable question types
    if (!editingThisField) {
      return <Button onClick={() => resetForm()} icon={<EditOutlined />} disabled={loading} />;
    } else {
      return (
        <Space>
          <Button
            icon={<SaveOutlined />}
            disabled={!canSubmit}
            loading={updatesLoading}
            type="primary"
            htmlType="submit"
            onClick={() => {
              setEditState(fieldKey, false);
              form.submit();
            }}
          >
            Save
          </Button>

          <Button
            icon={<CloseCircleOutlined />}
            onClick={() => {
              setEditState(fieldKey, false);
              form.resetFields();
            }}
          >
            Cancel
          </Button>
        </Space>
      );
    }
  };

  let val = state[FieldKeysEnum.questionAnswers].saveStatus;

  console.log("ManageAnswers", { val });

  return (
    <ContentCard
      title="Question Answers"
      fieldKey={FieldKeysEnum.questionAnswers}
      onValuesChange={onValuesChange}
      extra={<CardActions />}
      loading={loading || updatesLoading}
      setEditState={setEditState}
      editingCurrentField={editingThisField}
      saveStatus={state[FieldKeysEnum.questionAnswers].saveStatus}
      editableField={false}
      renderSaveStatus={
        state[FieldKeysEnum.questionAnswers].saveStatus !== SaveStatusEnum.Default
      }
    >
      {editingThisField ? (
        <ManageAnswersForm
          onValuesChange={onValuesChange}
          saveAnswers={saveQuestionAnswers}
          form={form}
          question={question}
        />
      ) : (
        <QuestionAnswerRenderer questionHasAnswers={questionHasAnswers} question={question} />
      )}
    </ContentCard>
  );
};

const ManageAnswersForm: React.FC<AnswersFormProps> = ({
  form,
  question,
  saveAnswers,
  onValuesChange,
}) => {
  const formValues = form.getFieldsValue(true);
  const answerType = form.getFieldValue("answers");

  const answers = Form.useWatch("answers", form);
  console.log({ answerType, answers });

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ paddingTop: 8 }}
      onFinish={saveAnswers}
      onValuesChange={onValuesChange}
      name="questionAnswersForm"
      autoComplete="off"
    >
      <Form.List name="answers" initialValue={question?.answers}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div key={`answer_${index}`}>
                <Form.Item
                  {...field}
                  key={`Answer_text_${field.key}`}
                  label={`Answer #${index + 1}`}
                  name={[field.name, "answer"]}
                  rules={
                    index <= 1
                      ? [
                          {
                            required: true,
                            message: "2 or more answers required!",
                          },
                        ]
                      : undefined
                  }
                >
                  <Input
                    placeholder="Answer text"
                    id="answer"
                    addonAfter={
                      fields.length > 2 && (
                        <DeleteOutlined
                          onClick={() => {
                            remove(field.key);
                            console.log(fields);
                          }}
                        />
                      )
                    }
                  />
                </Form.Item>

                <Form.Item label="Condition Type" name={[field.name, "additionalFieldType"]}>
                  <Select disabled={true} options={logicOptions} style={{ width: "30%" }} />
                </Form.Item>

                {/** Risk Estimation Section */}
                <Row justify="space-between" style={{ marginTop: 32 }}>
                  <Col span={24}>
                    <Space style={{ marginBottom: 24, marginTop: 12 }}>
                      <FieldTimeOutlined style={{ fontSize: 16 }} />
                      <Typography.Title style={{ fontSize: 16, marginBottom: 0 }}>
                        Estimations
                      </Typography.Title>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      {...field}
                      key={`riskIncrease_${field.key}`}
                      label="Additional risk"
                      name={[field.name, "riskIncrease"]}
                    >
                      <Radio.Group size="middle">
                        {QuestionAnswerRiskLevelEnums.map((risk) => (
                          <Radio.Button value={risk.value} key={`${risk.value}_${field.key}`}>
                            {risk.label}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...field}
                      key={`timeIncrease_${field.key}`}
                      label="Completion time"
                      name={[field.name, "timeIncreaseInDays"]}
                      style={{ marginBottom: 16 }}
                    >
                      <InputNumber placeholder="In days" id="timeIncreaseInDays" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ marginBottom: 32, marginTop: 0 }} />
              </div>
            ))}
            <Row justify="end">
              <Form.Item style={{ marginBottom: 0 }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => add()}>
                  Add Answer
                </Button>
              </Form.Item>
            </Row>
          </>
        )}
      </Form.List>
    </Form>
  );
};

const logicOptions = AnswerConditionEnums.map((type) => ({
  label: mapConditionType(type.value),
  value: type.value,
  disabled: type.disabled,
}));

const QuestionAnswerRenderer: React.FC<AnswerRendererProps> = ({
  questionHasAnswers,
  question,
}): any => {
  if (questionHasAnswers) {
    return question.answers.map((ans: any) => (
      <MultiChoiceTag id={ans.id} styles={{ marginBottom: 6 }} key={ans.id}>
        {ans.answer}
      </MultiChoiceTag>
    ));
  } else {
    return <Text>This question does not have editable answers</Text>;
  }
};

// const colors = {
//   blue: "",
//   green: "#21A000",
//   yellow: "#ccb100",
//   orange: "#FF7000",
//   red: "#C70000",
// };

// const riskMap = {
//   0: {
//     label: "None",
//     value: "NONE",
//     color: "black",
//   },
//   1: {
//     label: "Very Low",
//     value: "VERY_LOW",
//     color: "#005EC7",
//   },
//   2: {
//     label: "Low",
//     value: "LOW",
//     color: "#21A000",
//   },
//   3: {
//     label: "Moderate",
//     value: "MODERATE",
//     color: "#ccb100",
//   },
//   4: {
//     label: "High",
//     value: "HIGH",
//     color: "#FF7000",
//   },
//   5: {
//     label: "Very High",
//     value: "VERY_HIGH",
//     color: "#C70000",
//   },
// };

// #Region: Risk Enum Slider and logic
// const [activeMark, setActiveMark] = useState(0); // derive colors from this instead of independent state var
// const activeColor: string = riskMap[activeMark].color;

// const handleSlider = (value: number) => {
//   setActiveMark(value);
// };

//  useEffect(() => {
//    console.log({ activeMark });
//  }, [activeMark]);

// const sliderMarks: SliderMarks = {
//   0: {
//     label:
//       activeMark === 0 ? (
//         <label>None</label>
//       ) : (
//         <IconTip title="None" icon={<StopOutlined style={{ fontSize: 16 }} />} />
//       ),
//   },
//   1: {
//     label:
//       activeMark === 1 ? (
//         <label>Very Low</label>
//       ) : (
//         <IconTip title="Very Low" icon={<InfoCircleTwoTone style={{ fontSize: 16 }} />} />
//       ),
//     style: {
//       color: "#005EC7",
//     },
//   },
//   2: {
//     label:
//       activeMark === 2 ? (
//         <label>Low</label>
//       ) : (
//         <IconTip
//           title="Low"
//           icon={<FrownTwoTone twoToneColor="#21A000" style={{ fontSize: 16 }} />}
//         />
//       ),
//     style: {
//       color: colors.green,
//     },
//   },
//   3: {
//     label:
//       activeMark === 3 ? (
//         <label>Moderate</label>
//       ) : (
//         <IconTip
//           title="Moderate"
//           icon={<ExclamationCircleOutlined style={{ fontSize: 16 }} />}
//         />
//       ),
//     style: {
//       color: "#ccb100",
//     },
//   },
//   4: {
//     label:
//       activeMark === 4 ? (
//         <label>High</label>
//       ) : (
//         <IconTip title="High" icon={<AlertOutlined style={{ fontSize: 16 }} />} />
//       ),
//     style: { color: "#FF7000" },
//   },
//   5: {
//     label:
//       activeMark === 5 ? (
//         <label>Very High</label>
//       ) : (
//         <IconTip title="Very High" icon={<WarningOutlined style={{ fontSize: 16 }} />} />
//       ),
//     style: { color: "#C70000" },
//   },
// };

// const SliderMark = (mark, fieldValue) => {
//   if (fieldValue) {
//   }
// };

/**
 * * 1.) Capture the handleChange of slider, use riskMap to set correct enum value to form values
 * * 2.) Derive activeMark from form values
 * * 3.) Derive color from activeMark
 */

// const handleRiskValue = (value: number) => {
//   switch (value) {
//     case 0:
//       console.log(`value = ${value} > RiskLevel: ${RiskLevel.None}`);
//       return RiskLevel.None;
//     case 1:
//       console.log(`value = ${value} > RiskLevel: ${RiskLevel.VeryLow}`);
//       return RiskLevel.VeryLow;
//     case 2:
//       console.log(`value = ${value} > RiskLevel: ${RiskLevel.Low}`);
//       return RiskLevel.Low;
//     case 3:
//       console.log(`value = ${value} > RiskLevel: ${RiskLevel.Moderate}`);
//       return RiskLevel.Moderate;
//     case 4:
//       console.log(`value = ${value} > RiskLevel: ${RiskLevel.High}`);
//       return RiskLevel.High;
//     case 5:
//       console.log(`value = ${value} > RiskLevel: ${RiskLevel.VeryHigh}`);
//       return RiskLevel.VeryHigh;
//     default:
//       console.log(`defaultCase*: value = ${value} > RiskLevel: ${RiskLevel.None}`);
//       return RiskLevel.None;
//   }
// };

// const handleRiskEvent = (value: number, field: any, index: number) => {
//   console.log({ riskValue: value, fieldName: field.name });
//   handleSlider(value);
//   const enumValue = handleRiskValue(value);
//   if (!enumValue) return;

//   const fields = form.getFieldsValue();
//   const allVals = form.getFieldsValue(true);
//   const { answers } = form.getFieldsValue(["answers"]);
//   console.log("fieldValue", form.getFieldValue(["riskIncrease"]));
//   let answerVals = "";
//   const prevValue = answerVals[index];

//   console.log({ fields, prevValue, answerVals, allVals, answers });

//   // form.setFieldValue("answers", enumValue);
//   const newAns = (allVals[index].riskIncrease = enumValue);
//   const clone = [...allVals, newAns];
//   form.setFieldsValue(clone);
//   // form.setFieldsValue(enumValue);
//   // form.setFieldsValue({
//   //   [["answers"][0]]: { ...prevValue, riskIncrease: enumValue },
//   // });
// };
// #endregion

export default ManageAnswers;
