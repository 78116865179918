import { useState, useEffect } from "react";
import { QuestionCategoryNode, QuestionNode } from "types";
import { Col, message, Row, Switch, Tag } from "antd";
import { LockOutlined } from "@ant-design/icons";
import CategoryInformation from "./Components/CategoryInformation";
import SidebarSection from "../Shared/SidebarSection";
import {
  CategoryEditorReducerEnum as ReducerEnum,
  CategoryEditorState as EditorState,
  CategoryFieldKeysEnum as FieldKeysEnum,
} from "./Components/reducer";
import RmfPathsSelector from "../Question Editor/Components/RmfPathsSelector";
import { useMutation } from "@apollo/client";
import Api from "api";
import ParentInformation from "./Components/ParentInformation";
import { SaveStatusEnum } from "../Shared/types";
import useEditorLoading from "hooks/useEditorLoading";
import QuestionList from "./Components/QuestionManagement/QuestionList";
import QuestionListEditor from "./Components/QuestionManagement/QuestionListEditor";
import "styles/workflowEditor.css";

type Props = {
  state: EditorState;
  dispatch: any;
  category: QuestionCategoryNode;
  refetchCategory: any;
  loading: Loading;
};

const CategoryEditor: React.FC<Props> = ({
  state,
  dispatch,
  loading,
  category,
  refetchCategory,
}) => {
  console.log({ selectedCategory: category });
  const editingQuestions = state.questionOrder.editActive || state.questions.editActive;

  const setDefault = (fieldKey: FieldKeysEnum) => {
    setTimeout(() => {
      console.log("%cdispatching setDefault!", "color:orange");
      dispatch({
        type: ReducerEnum.SetDefault,
        params: {
          fieldKey,
        },
      });
    }, 2500);
  };

  const [selectedPaths, setSelectedPaths] = useState<any>([]);
  const [updateCategory, { loading: updateLoading }] = useMutation(
    Api.QuestionCategory.Update(),
    {
      onCompleted: (res) => {
        console.log({ updateCategory: res });
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 7);
      },
      refetchQueries: [Api.QuestionModule.WorkflowEditor()],
    },
  );

  const [toggleCheckpoint, { loading: checkpointLoading }] = useMutation(
    Api.QuestionModule.ToggleCheckpoint(),
    {
      variables: {
        objectId: category?.id,
      },
      onCompleted: (res) => {
        console.log(res);
        console.log("%ctoggleCheckpoint onComplete()", "color:purple");
        console.log({ category });
        setSaveState(FieldKeysEnum.checkpointStatus, SaveStatusEnum.Success);
        setDefault(FieldKeysEnum.checkpointStatus);
        refetchCategory();
      },
      onError: (error) => {
        console.log(error.message);
        setSaveState(FieldKeysEnum.checkpointStatus, SaveStatusEnum.Error);
        message.error(error.message, 5);
      },

      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (!category) {
      console.log("%c!Question", "color:red");
      return;
    } else {
      console.log("%csetRmfPaths in useEffect", "color:blue");
      const paths = category.rmfActionPaths?.map((ap: any) => {
        return ap;
      });
      setSelectedPaths(paths);
    }
  }, [category]);

  const toggleActionPath = (tag: any, checked: boolean) => {
    const nextSelectedTags: any[] = checked
      ? [...selectedPaths, { path: tag.value }]
      : selectedPaths.filter((t: any) => t.path !== tag.value);

    setSelectedPaths(nextSelectedTags);
    setSaveState(FieldKeysEnum.rmfPaths, SaveStatusEnum.Loading);
    if (nextSelectedTags.length < 1) return;

    updateCategory({
      variables: {
        id: category.id,
        rmfActionPath: nextSelectedTags.map((ap: any) => ap.path),
      },
      onCompleted: (res) => {
        console.log({ updatedCatRmfPaths: res.updateQuestionCategory });
        const currentPaths = [...selectedPaths];
        console.log({ currentPaths });
        setSelectedPaths(res.updateQuestionCategory?.rmfActionPaths); // ???
        setSaveState(FieldKeysEnum.rmfPaths, SaveStatusEnum.Success);
        refetchCategory();
      },
      onError: (error) => {
        setSaveState(FieldKeysEnum.rmfPaths, SaveStatusEnum.Error);
        console.log(error.message);
        message.error(error.message, 5);
      },
    });
  };

  const setEditState = (fieldKey: FieldKeysEnum) => {
    dispatch({
      type: ReducerEnum.ToggleEdit,
      params: {
        fieldKey,
      },
    });
  };

  const setSaveState = (fieldKey: FieldKeysEnum, status: SaveStatusEnum) => {
    let saveAction;
    console.log("%csetSaveState action", "color:green");
    console.log(`fieldKey: ${fieldKey}, status: ${status}`);

    switch (status) {
      case SaveStatusEnum.Default:
        console.log("%cDEFAULT", "color:orange");
        saveAction = ReducerEnum.SetDefault;
        break;
      case SaveStatusEnum.Loading:
        console.log("%cLOADING", "color:orange");
        saveAction = ReducerEnum.SetLoading;
        break;
      case SaveStatusEnum.Success:
        console.log("%cSUCCESS", "color:orange");
        saveAction = ReducerEnum.SetSuccess;
        break;
      case SaveStatusEnum.Error:
        console.log("%cERROR", "color:orange");
        saveAction = ReducerEnum.SetError;
        break;
      default:
        console.log("%cDEFAULT STATEMENT", "color:orange");
        saveAction = ReducerEnum.SetDefault;
    }

    console.log(`%cdispatch sending ${saveAction}`, "color:orange");

    dispatch({
      type: saveAction,
      params: {
        fieldKey,
      },
    });

    // error message shown longer
    if (saveAction === ReducerEnum.SetError) {
      return setTimeout(() => {
        dispatch({
          type: ReducerEnum.SetDefault,
          params: {
            fieldKey,
          },
        });
      }, 5000);
    }

    if (saveAction === ReducerEnum.SetSuccess) {
      setTimeout(() => {
        console.log("%cdispatching setDefault!", "color:orange");
        dispatch({
          type: ReducerEnum.SetDefault,
          params: {
            fieldKey,
          },
        });
      }, 2500);
    }
  };

  const handleCheckpointToggle = (checked: boolean) => {
    console.log("%ctoggleCheckpoint called!***", "color:purple");
    console.log({ category });

    setSaveState(FieldKeysEnum.checkpointStatus, SaveStatusEnum.Loading);
    toggleCheckpoint();
  };

  const resetEditor = () => {
    dispatch({ type: ReducerEnum.ResetEditor });
  };

  const checkpointStatusLoading = useEditorLoading(
    FieldKeysEnum.checkpointStatus,
    state,
    loading,
  );
  const questionList: QuestionNode[] | any = category?.allQuestions;
  const hasCheckpoint = category?.hasCheckpoint ? true : false;

  if (editingQuestions) {
    return (
      <Row
        style={{
          height: "calc(100vh - 65px) !important",
          maxHeight: "calc(100vh - 65px)",
          width: "100%",
          overflow: "auto",
          padding: "16px 16px 72px 16px",
        }}
      >
        <Col span={24}>
          <QuestionListEditor
            category={category}
            state={state}
            reset={resetEditor}
            setSaveState={setSaveState}
            loading={loading}
            refetchCategory={refetchCategory}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row justify="space-between" style={{ padding: 16 }}>
      <Col span={17}>
        <Row justify="start" style={{ maxHeight: "calc(100vh - 105px)" }}>
          <Col
            span={24}
            style={{
              textAlign: "left",
              paddingLeft: 0,
              height: "calc(100vh - 105px)",
            }}
          >
            <CategoryInformation
              loading={loading}
              state={state}
              category={category}
              setEditState={setEditState}
              setSaveState={setSaveState}
              updateCategory={updateCategory}
            />
            <QuestionList
              category={category}
              questions={questionList}
              loading={loading}
              setEditState={setEditState}
            />
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <Row align="top" justify="start">
          <Col span={24}>
            <SidebarSection
              title="Checkpoint Status"
              loading={loading}
              valueLoading={loading || checkpointStatusLoading}
              renderSaveStatus={
                state[FieldKeysEnum.checkpointStatus].saveStatus !== SaveStatusEnum.Default
              }
              saveStatus={state[FieldKeysEnum.checkpointStatus].saveStatus}
              actionMenu={
                <Switch
                  checked={hasCheckpoint}
                  loading={loading}
                  onChange={handleCheckpointToggle}
                  disabled={loading || checkpointLoading || checkpointStatusLoading}
                  size="small"
                />
              }
            >
              <CheckpointStatusTag locked={hasCheckpoint} />
            </SidebarSection>
            <ParentInformation
              state={state}
              loading={loading}
              category={category}
              setEditState={setEditState}
              setSaveState={setSaveState}
              updateCategory={updateCategory}
            />
            <SidebarSection
              title="RMF Action Paths"
              tooltip="Select a path to assign"
              loading={loading}
              valueLoading={checkpointStatusLoading}
              renderSaveStatus={
                state[FieldKeysEnum.rmfPaths].saveStatus !== SaveStatusEnum.Default
              }
              saveStatus={state[FieldKeysEnum.rmfPaths].saveStatus}
            >
              <RmfPathsSelector
                toggleActionPath={toggleActionPath}
                selectedPaths={selectedPaths}
              />
            </SidebarSection>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const CheckpointStatusTag = ({ locked }: { locked: boolean }) => {
  if (!locked) return <Tag>Inactive</Tag>;
  return (
    <Tag color="#c25604" icon={<LockOutlined />}>
      Active
    </Tag>
  );
};

export default CategoryEditor;
