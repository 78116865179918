import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import Super from "components/Deliverables/Components/Super";

export const P6 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pcilv2"
        style={{
          width: "95%",
          fontSize: 12,
        }}
      >
        <div style={{ paddingLeft: 80 }}>
          <div style={{ display: "flex" }}>
            <b style={{ fontSize: 16 }}>
              <span style={{ paddingRight: 10 }}>2.5</span>
              Determine the PII confidentiality impact level (PCIL)
            </b>
          </div>

          <div>
            <br />
            <br />
            <p>
              The PII confidentiality impact level (PCIL) in NIST SP 800-122 — low, moderate, or high — is based on a
              combination of the FIPS 199 impact values and six factors for determining the harm<Super>2</Super> (see Table 2 below) that
              could result to the subject individuals, the organization, or both, if PII were inappropriately accessed, used, or
              disclosed.<Super>3</Super>
            </p>
            <p>
              The Privacy Overlay (Reference (e)) references FIPS 199 (Reference (d)) for a definition of the impact levels
              and NIST SP 800-122 (Reference (f)) for six (6) factors that determine the harm that could result to individuals,
              the organization, or both.
            </p>
          </div>

          {/* <div>
            <ul>
              <div>
                <i>
                  Refer to Appendix C or to Reference (e), section 2.4, pages 9-10, to
                  determine if the PII within the applicable system meets the “Exception of
                  Business Rolodex Information.”
                </i>
              </div>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div>
                  <EditableRadio {...elementProps} fieldName="6_yes_rolodex" />
                  <span style={{ marginLeft: 10, fontSize: 14 }}>YES. ROLODEX APPLIES</span>
                </div>
                <div>
                  <EditableRadio {...elementProps} fieldName="6_no_rolodex" />
                  <span style={{ marginLeft: 10, fontSize: 14 }}>
                    NO. ROLODEX does NOT apply
                  </span>
                </div>
              </div>
              <b>If Yes, provide explanation:</b>
              <div>
                <EditableField
                  {...elementProps}
                  fieldName="6_explanation_rolodex"
                  style={{ width: "100%", height: 100 }}
                />
              </div>
              <br />
              <div style={{ fontSize: 16, marginLeft: -60 }}>
                <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
                <b>
                  <i>
                    If the response selected for item 2.5 is “YES,” then sign at Appendix B.
                  </i>
                </b>
              </div>
              <div style={{ fontSize: 16, marginLeft: -60 }}>
                <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
                <b>
                  <i>
                    If the response selected for item 2.5 is “NO”, continue to Section 2.6.
                  </i>
                </b>
              </div>
            </ul>
          </div>
          <br />
          <div>
            <b style={{ fontSize: 16 }}>
              <span style={{ paddingRight: 10 }}>2.6</span>
              Determine the PII confidentiality impact level (PCIL)
            </b>
            <ul>
              <div>
                The PII confidentiality impact level (PCIL) in NIST SP 800-122 — low, moderate,
                or high — is based on a combination of the FIPS 199 impact values and six
                factors for determining the harm2 (see Table 2 below) that could result to the
                subject individuals, the organization, or both, if PII were inappropriately
                accessed, used, or disclosed.3
              </div>
              <div>
                The Privacy Overlay (Reference (e)) references FIPS 199 (Reference (d)) for a
                definition of the impact levels and NIST SP 800-122 (Reference (f)) for six (6)
                factors that determine the harm that could result to individuals, the
                organization, or both.
              </div>
            </ul>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div>
            <span style={{ fontSize: 8 }}>2</span> NIST SP 800-122, Section 3.1, “For the
            purposes of this document, harm means any adverse effects that would be experienced
            by an individual whose PII was the subject of a loss of confidentiality, as well as
            any adverse effects experienced by the organization that maintains the PII. Harm to
            an individual includes any negative or unwanted effects (i.e., that may be
            socially, physically, or financially damaging). Examples of types of harm to
            individuals include, but are not limited to, the potential for blackmail, identity
            theft, physical harm, discrimination, or emotional distress. Organizations may also
            experience harm as a result of a loss of confidentiality of PII maintained by the
            organization, including but not limited to administrative burden, financial losses,
            loss of public reputation and public confidence, and legal liability.”
          </div>
          <div>
            <span style={{ fontSize: 8 }}>3</span> NIST SP 800-122, Section 3.2, discusses the
            use of six factors to determine impact levels and the freedom of agencies to
            determine the most relevant factors, including extending the six factors when
            appropriate. The six factors include identifiability, quantity of PII, data field
            sensitivity, context of use, obligation to protect confidentiality, and access to
            and location of PII (see Table 2 of the Privacy Overlays for illustrative examples
            of these six factors for each PII confidentiality impact level). NIST SP 800-122
            leaves it to the organization’s discretion to determine whether additional factors
            should be considered beyond the six defined by NIST. NIST also notes the importance
            of considering the relevant factors together as the impact levels of each factor
            may differ.
          </div> */}
        </div>
        <br />
        <br />
        <br />
        <br />

        <div style={{ marginLeft: 20 }}>
          <div style={{ borderTop: 'solid black 1px', width: 180, }}></div>
          <br />
          <br />
          <p>
            <Super>2</Super> NIST SP 800-122, Section 3.1, “For the purposes of this document, harm means any adverse effects that would be
            experienced by an individual whose PII was the subject of a loss of confidentiality, as well as any adverse effects experienced
            by the organization that maintains the PII. Harm to an individual includes any negative or unwanted effects (i.e., that may be
            socially, physically, or financially damaging). Examples of types of harm to individuals include, but are not limited to, the
            potential for blackmail, identity theft, physical harm, discrimination, or emotional distress. Organizations may also
            experience harm as a result of a loss of confidentiality of PII maintained by the organization, including but not limited to
            administrative burden, financial losses, loss of public reputation and public confidence, and legal liability.”
          </p>
          <p>
            <Super>3</Super> NIST SP 800-122, Section 3.2, discusses the use of six factors to determine impact levels and the freedom of agencies to
            determine the most relevant factors, including extending the six factors when appropriate. The six factors include
            identifiability, quantity of PII, data field sensitivity, context of use, obligation to protect confidentiality, and access to and
            location of PII (see Table 2 of the Privacy Overlays for illustrative examples of these six factors for each PII confidentiality
            impact level). NIST SP 800-122 leaves it to the organization’s discretion to determine whether additional factors should be
            considered beyond the six defined by NIST. NIST also notes the importance of considering the relevant factors together as
            the impact levels of each factor may differ.
          </p>
        </div>
      </div>
    </>
  );
};
