import { ControlOutlined, TableOutlined, UnlockOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { GroupType } from "types";

type GroupTagProps = {
  children: any;
  id: string;
  styles: any;
};

export const GenericGroupTag = ({ id }: { id: string }) => {
  return (
    <Tooltip
      title="Generic Groups are dynamic and can include any questions. Generic Groups are modifiable"
      color="#000000"
      // placement="right"
      key={id}
    >
      <Tag color="green" icon={<UnlockOutlined />} key={id}>
        GENERIC
      </Tag>
    </Tooltip>
  );
};

export const ControlGroupTag = ({ id }: { id: string }) => {
  return (
    <Tooltip
      title="Control Groups include a set of predefined questions and are not modifiable"
      color="#000000"
      key={id}
    >
      <Tag color="blue" icon={<ControlOutlined />} key={id}>
        CONTROL
      </Tag>
    </Tooltip>
  );
};

export const GridGroupTag = ({ id }: { id: string }) => {
  return (
    <Tooltip
      title="Grid Groups are a special type of Generic Group that can be used to create a table of questions"
      color="#000000"
      key={id}
    >
      <Tag color="magenta" icon={<TableOutlined />} key={id}>
        GRID
      </Tag>
    </Tooltip>
  );
};

export const MapGroupTag = (groupType: GroupType | string, id?: string) => {
  let tag;

  const tagKey = id ? id : groupType;

  switch (groupType) {
    case GroupType.Control:
      tag = <ControlGroupTag id={tagKey} />;
      break;
    case GroupType.Generic:
      tag = <GenericGroupTag id={tagKey} />;
      break;
    case GroupType.Grid:
      tag = <GridGroupTag id={tagKey} />;
      break;
    default:
      console.warn("incorrect group type enum!!");
      return groupType;
  }
  return tag;
};
