export const P16 = (props) => {
  return (
    <>
      <div className="itcsc" style={{
        columnCount: 2,
        padding: '0px 6%',
        height: '100%'
      }}>
        <div className="instructions" style={{ width: '100%', textAlign: 'left', height: '100%' }}>
          <ul>
            <li style={{ listStyle: 'none' }}>
              be expected to have a serious adverse effect on<br />
              organizational operations,<br />
              organizational assets, or individuals).<br />
            </li>
            <li>
              IL5: CUI and Unclassified National Security<br />
              Information (U-NSI). (Up to M-M-x):<br />
              Accommodates non-public, unclassified NSS<br />
              system data (i.e., U-NSI) or non-public,<br />
              unclassified data where the unauthorized<br />
              disclosure of information could be expected to<br />
              have a serious adverse effect on organizational<br />
              operations, organizational assets, or<br />
              individuals. This includes CUI and/or other<br />
              mission data that may require a higher level of<br />
              protection than that afforded by IL4 as deemed<br />
              necessary by the information owner, public<br />
              law, or other government regulation.
            </li>
            <li>
              IL4: Controlled Unclassified Information. (Up<br />
              to M-M-x): Accommodates non-public,<br />
              unclassified data where the unauthorized<br />
              disclosure of information could be expected to<br />
              have a serious adverse effect on organizational<br />
              operations and assets, or individuals. This<br />
              encompasses CUI and/or other mission data,<br />
              including that used in direct support of military<br />
              or contingency operations.
            </li>
            <li>
              IL2: Non-Controlled Unclassified Information.<br />
              (Up to L-M-x): Accommodates publicly<br />
              releasable data or non-public unclassified data<br />
              where the unauthorized disclosure of<br />
              information could be expected to have a<br />
              limited adverse effect on organizational<br />
              operations and assets, or individuals. This<br />
              includes all data cleared for public release as<br />
              well as some low confidentiality unclassified<br />
              information NOT designated as CUI or<br />
              military/contingency operations mission data.
            </li>
            <li>
              DoD does not support information categorized<br />
              as high confidentiality and high integrity (H-H-<br />
              x) being deployed in commercial CSOs at this<br />
              time.
            </li>
          </ul>
          <p><b>11.Security Description/Environment</b></p>
          <p>
            Provide a description of the security environment. This <br />
            description should tell the story of who, what, where, <br />
            when, why, and how cybersecurity and information <br />
            assurance hardware and software is, or will be, <br />
            protecting the System.
          </p>
          <ul>
            <li>
              Describe the network security infrastructure<br />
              and the internal security components of the<br />
              System to include encryption devices,<br />
              firewalls, wireless, Virtual Private Networks,<br />
              Intrusion Detection Systems, Intrusion<br />
              Prevention Systems, Demilitarized Zone, Host<br />
              Based Security System, and Anti-Virus.
            </li>
            <li>
              If any security-related services are being<br />
              provided by other systems (such as through a<br />
              Service Level Agreement Memorandum of<br />
              Agreement/Memorandum of Understanding),<br />
              describe this as well.<br />
            </li>
            <li>
              Include any environmental or technical factors<br />
              that raise special security concerns, such as use<br />
              of Personal Digital Assistants, wireless<br />
              technology, etc.
            </li>
            <li>Describe any interconnections the System has.</li>
            <li>
              Describe your Defense-in-Depth: Provide a<br />
              narrative, DoDAF view or other artifact that<br />
              captures processes in place to protect the<br />
              information and or System.<br />
            </li>
          </ul>
          <p><b>12.Overlays</b></p>

          <p><b>12A. Intelligence Overlay</b></p>
          <p>
            Does the System process, store, or transmit Intelligence,<br />
            Surveillance, or Reconnaissance (ISR)?
          </p>

          <p><b>12B. Cross Domain Solution Overlay</b></p>
          <p>
            Will you implement, manage, or maintain a Cross<br />
            Domain Solution?
          </p>

          <p><b>12C. Nuclear Command, Control & Communications (NC3) Overlay</b></p>
          <p>
            Does the System store, process, or transmit NC3 data?<br />
            Please note that use of the NC3 Overlay also requires the<br />
            implementation of the Intel Overlay.
          </p>

          <p><b>12D. Space Platform Overlay</b></p>
          <p>
            Is the System (or subsystem) a space platform as defined<br />
            in CNSSI 1253F Atch 2 and unmanned?
          </p>

          <p><b>12E. Classified Information Overlay</b></p>
          <p>Does the System store, process, or transmit classified<br /> information?</p>

          <p><b>12F. DoD Functional Mission/Capability Specific Overlay</b></p>
          <p>
            Is your System required to execute an organizational<br />
            mission or function-specific overlay? For example, <br />
            Financial (FIAR), Acquisition.
          </p>

          <p><b>13.Privacy</b></p>
          <p>
            For assistance with completing this section, please<br />
            contact your Base Privacy Manager.
          </p>

          <p><b>13A. PII/PHI</b></p>
          <p>Will the system process, store, or transmit PII/PHI?</p>

          <p><b>13B. PHI</b></p>
          <p>Will the system process, store, or transmit PHI? Apply<br /> PHI Overlay.</p>

          <p><b>13C. PII and NSS</b></p>
          <p>Is the system an NSS and does the system contain<br /> information reflected in Ref: (x)?</p>

          <p><b>13D. Personable Identifier and SORN</b></p>
          <p>Is the PII maintained in a system that meets<br /> the definition of a “system of records” under the Privacy<br /> Act?</p>

          <p><b>13E Privacy Factors</b></p>
          <p>From the Privacy Factors provided, evaluate the PII in<br /> the System.</p>
        </div>
      </div>
    </>
  );
};
