import React from "react";
import { DashOutlined, DownOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Row, Space } from "antd";
import { QuestionnairePage } from "components/Applicant/Hooks/useQuestionnairePage";
import useViewport from "hooks/useViewport";

interface Props {
  page: QuestionnairePage | null;
  onChange: (arg: any) => void;
}

const PageControls: React.FC<Props> = ({ page, onChange }) => {
  const viewport = useViewport();

  if (!page) return <></>;

  const pageIndex = page.pageNumber;
  const totalPages = page.totalPages;
  const pages = Array.from(Array(page.totalPages).keys());
  const showControls = viewport.questionnaire.pageControls.showControls;

  const PaginationButtons = (): any => {
    const n = viewport.questionnaire.pageControls.edges;

    let divide = false;
    return pages.map((_, i) => {
      const page = i + 1;
      const isFirst = page === 1;
      const isLast = page === totalPages;
      const inRange = page >= pageIndex - n && page <= pageIndex + n;

      const IndexButton = () => (
        <Col>
          <Button
            type={page === pageIndex ? "primary" : undefined}
            onClick={() => onChange(page)}
          >
            {page}
          </Button>
        </Col>
      );

      let IndexDivider = () => (
        <Space style={{ margin: "0 4px" }}>
          <DashOutlined />
        </Space>
      );

      if (isFirst || isLast) return <IndexButton key={i} />;

      if (!inRange) {
        if (divide) return <React.Fragment key={i} />;
        divide = true;
      } else {
        divide = false;
      }

      if (inRange) return <IndexButton key={i} />;
      else return <IndexDivider key={i} />;
    });
  };

  const CurrentIndexButton = () => <Button type="primary">{page?.pageNumber}</Button>;

  const jumpToMenu: any = {
    items: pages.map((g, i) => ({
      label: i + 1,
      key: i + 1,
    })),
    onClick: (e) => onChange(parseInt(e.key)),
    style: { textAlign: "center", maxHeight: "250px", overflowY: "scroll" },
  };

  const stickyStyles: any = {
    position: "sticky",
    left: 0,
    bottom: 0,
    width: "100%",
    padding: 20,
    background: "white",
  };

  return (
    <div className="shadowed-static" style={stickyStyles}>
      <Row
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col span={24}>
          <Row
            gutter={[8, 8]}
            style={{ width: "100%", flex: 1 }}
            justify={showControls ? "center" : "start"}
          >
            <Button
              style={{ marginRight: 4 }}
              onClick={() => onChange(pageIndex - 1)}
              disabled={pageIndex === 1}
            >
              <LeftOutlined />
            </Button>
            {showControls ? <PaginationButtons /> : <CurrentIndexButton />}
            <Button
              style={{ marginLeft: 4 }}
              onClick={() => onChange(pageIndex + 1)}
              disabled={pageIndex === totalPages}
            >
              <RightOutlined />
            </Button>
          </Row>
        </Col>
        <Col
          style={{
            marginTop: 10,
            position: "absolute",
            right: 0,
            bottom: 0,
          }}
        >
          <Dropdown placement="top" menu={jumpToMenu}>
            <Button style={{ marginRight: 10 }}>
              Jump to
              <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
};

export default PageControls;
