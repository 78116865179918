import { useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import Api from "api";

export const useAssignmentValue = (props) => {
  const { applicationId, fieldIds, editable } = props;

  const [getAppFormAssignment, { appDocumentData: data }] = useLazyQuery(Api.FormAssignment.GetOne(), {
    variables: {
      id: applicationId,
      ids: fieldIds,
    },
  });

  useEffect(() => {
    if (!editable && applicationId) {
      getAppFormAssignment();
    }
  }, [editable, applicationId]);

  const assignment = data?.application.formAssignments[0];

  // if (!editable && !assignment) return null;
  if (!editable && !assignment) return undefined;

  return assignment;
};

export const useSaveAssignment = (cb) => {
  const [save, { loading }] = useMutation(Api.Document.Update(), {
    onCompleted: cb,
    refetchQueries: [Api.Document.GetOneVariableFields()],
  });

  const saveFormAssignment = (fieldId, questionId, answerId = undefined) => {
    save({
      variables: {
        fieldId,
        questionId,
        answerId,
      },
    });
  };

  return [saveFormAssignment, loading];
};
