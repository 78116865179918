import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { QuestionNode, QuestionType } from "types";
import ExpandIcon from "../../../Common/ExpandedTableIcon";
import { MultiChoiceTag, NoHelpTextTag } from "../../../Common/Tags/QuestionType";

/**
 * @returns Nested Question table (expanded row) + expanded row components
 *
 */

type Props = {
  data: QuestionNode;
};

type ExpandProps = {
  expanded: boolean;
  onExpand: any;
  record: any;
};

export const NestedQuestionTable: React.FC<Props> = (data) => {
  return (
    <Table
      columns={nestedColumns}
      pagination={false}
      dataSource={Object.values(data)}
      rowKey={(row) => row.id}
      style={{ marginLeft: "3%" }}
      bordered
    />
  );
};

const canHaveCondition = (questionType: QuestionType) =>
  questionType === QuestionType.MultipleChoicePickOne ||
  questionType === QuestionType.MultipleChoicePickMany;

const nestedColumns: ColumnsType<QuestionNode> = [
  {
    title: "Help Text",
    dataIndex: "helpText",
    key: "helpText",
    render: (text, record, _index) => {
      return text ? text : <NoHelpTextTag key={record.id} />;
    },
  },
  {
    title: "Answers",
    dataIndex: "answers",
    key: "answers",
    render: (_text, record, _index) => {
      if (canHaveCondition(record.questionType)) {
        if (!record.answers || record.answers.length === 0) {
          return null;
        }
        return record.answers.map((rec) => (
          <>
            <MultiChoiceTag key={rec?.id!} id={rec?.id!}>
              {rec?.answer}
            </MultiChoiceTag>
          </>
        ));
      }
    },
  },
];

export const expandComponents = {
  expandedRowRender: (record: any) => <NestedQuestionTable data={record} key={record.id} />,
  expandIcon: ({ expanded, onExpand, record }: ExpandProps) => {
    return <ExpandIcon expanded={expanded} onExpand={onExpand} record={record} />;
  },
};
