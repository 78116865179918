export const P15 = (props) => {
  return (
    <>
      <div className="itcsc" style={{
        columnCount: 2,
        padding: '40px 6%',
        height: '100%'
      }}>
        <div className="instructions" style={{ width: '100%', textAlign: 'left', height: '100%' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              New System is to provide a database of safety<br />
              information that is searchable via a web interface.<br />
              The system is not mission essential<br />
              to the warfighter but is mission essential to the<br />
              57th Safety Wing.
            </li>
            <li>
              Describe the major hardware/software<br />
              components of the System. Example: The<br />
              system consists of multiple Dell PowerEdge<br />
              3000 servers loaded with Windows Server<br />
              2012R2 and Microsoft SQL Server 2014…<br />
            </li>
            <li>
              Describe the services provided by the System<br />
              and whether any of the services are publicly<br />
              accessible (Publicly accessible means does not<br />
              require authentication to access the<br />
              information). <i>Example: The system provides,<br />
                etc.</i>
            </li>
            <li>
              Describe how each of the Information Types<br />
              are, or will be, stored, processed, and/or<br />
              transmitted by the System.
            </li>
            <li>
              If the system is undergoing a Major<br />
              Modification, Describe the modification and its<br />
              purpose: otherwise ignore this requirement.
            </li>
          </ul>
          <p><b>8.Boundary Topology and System Location </b></p>
          <p>
            Provide a detailed boundary drawing that clearly shows<br />
            the cybersecurity authorization boundary. This topology<br />
            should match the infrastructure outlined in both the<br />
            System and Security Descriptions.
          </p>
          <p>
            Required DoDAF Artifact: If external interfaces exist,<br />
            the follow-on requirement is to generate a detailed and<br />
            comprehensive boundary drawing (DoDAF OV-1 and<br />
            SV-1) and post it to eMASS as an artifact during or after<br />
            the initial registration.
          </p>

          <p><b>9.System Architecture</b></p>
          <ul>
            <li>
              On-Premises Deployed – This system is<br />
              deployed completely on premises.
            </li>
            <li>
              Private Cloud: The cloud infrastructure is<br />
              provisioned for exclusive use by a single<br />
              organization comprising multiple consumers<br />
              (e.g., business units). It may be owned,<br />
              managed, and operated by the organization, a<br />
              third party, or some combination of them, and<br />
              it may exist on or off premises.
            </li>
            <li>
              Community Cloud: The cloud infrastructure is<br />
              provisioned for exclusive use by a specific<br />
              community of consumers from organizations<br />
              that have shared concerns (e.g., mission,<br />
              security requirements, policy, and compliance<br />
              considerations). It may be owned, managed,and operated by one or more of the<br />
              organizations in the community, a third party,or some combination of them, and it may exist<br />
              on or off premises.
            </li>
            <li>
              Public Cloud: The cloud infrastructure is<br />
              provisioned for open use by the general public.<br />
              It may be owned, managed, and operated by a<br />
              business, academic, or government
            </li>
          </ul>
        </div>

        <div className="instructions" style={{ width: '100%', textAlign: 'left', height: '100%' }}>
          <ul>
            <li style={{ listStyle: 'none' }}>
              organization, or some combination of them. It<br />
              exists on the premises of the cloud provider.<br />
              8A. Type of Cloud Service
            </li>
            <li>
              Hybrid Cloud: The cloud infrastructure is a<br />
              composition of two or more distinct cloud<br />
              infrastructures (private, community, or public)<br />
              that remain unique entities, but are bound<br />
              together by standardized or proprietary<br />
              technology that enables data and application<br />
              portability (e.g., cloud bursting for load<br />
              balancing between clouds).”
            </li>
          </ul>
          <p><b>9A. Type of Cloud Service</b></p>
          <ul>
            <li>
              Infrastructure as a Service (IaaS). The<br />
              capability provided to the consumer is to<br />
              provision processing, storage, networks, and<br />
              other fundamental computing resources where<br />
              the consumer is able to deploy and run<br />
              arbitrary software, which can include operating<br />
              systems and applications. The consumer does<br />
              not manage or control the underlying cloud<br />
              infrastructure but has control over operating<br />
              systems, storage, and deployed applications;<br />
              and possibly limited control of select<br />
              networking components (e.g., host firewalls).
            </li>
            <li>
              Platform as a Service (PaaS). The capability<br />
              provided to the consumer is to deploy onto the<br />
              cloud infrastructure consumer-created or<br />
              acquired applications created using<br />
              programming languages, libraries, services,<br />
              and tools supported by the provider. The<br />
              consumer does not manage or control the<br />
              underlying cloud infrastructure including<br />
              network, servers, operating systems, or<br />
              storage, but has control over the deployed<br />
              applications and possibly configuration<br />
              settings for the application-hosting<br />
              environment.
            </li>
            <li>
              Software as a Service (SaaS). The capability<br />
              provided to the consumer is to use the<br />
              provider’s applications running on a cloud<br />
              infrastructure. The applications are accessible<br />
              from various client devices through either a<br />
              thin client interface, such as a web browser<br />
              (e.g., web-based email), or a program<br />
              interface. The consumer does not manage or<br />
              control the underlying cloud infrastructure<br />
              including network, servers, operating systems,<br />
              storage, or even individual application<br />
              capabilities, with the possible exception of<br />
              limited user-specific application configuration<br />
              settings
            </li>
          </ul>
          <p><b>10.Cloud Impact Level (IL) Determination</b></p>
          <ul>
            <li>
              IL6: Classified Information up to Secret. (Up<br />
              to M-M-x): Accommodates non-public,<br />
              classified NSS system data (i.e., classified<br />
              national security information [NSI]) or non-<br />
              public, unclassified data where the<br />
              unauthorized disclosure of information could
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
