import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Row, Col } from "antd";

import pages from "./Pages";
export const AMPages = pages;

export const AMHeader = () => {
  return (
    <Row justify="space-between">
      <div style={{ marginTop: 20 }}></div>
    </Row>
  );
};

export const AMFooter = ({ state, pageNumber }) => {
  const style = {
    paddingTop: 5,
    fontSize: 11,
  };

  const document = state?.document;

  return (
    <Row justify="space-between">
      <Col span={12}>
        <div style={style}>AM</div>
      </Col>
      <Col span={12}>
        <div style={{ ...style, textAlign: "right" }}>
          Page: {pageNumber} of {document?.numberOfPages}
        </div>
      </Col>
    </Row>
  );
};
