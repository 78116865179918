import { useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Drawer, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteQuestionMutation,
  GetFullQuestionaryQuery,
  QuestionNode,
  QuestionType,
} from "types";
import Api from "api";
import InputConfirmModal from "components/Common/InputConfirmModal";
import { questionMgmtReducer } from "./Components/reducer";
import QuestionEditor from "./QuestionEditor";
import useDynamicDrawerWidth from "hooks/useDynamicDrawerWidth";

const initialQuestionState: QuestionNode | any = {
  category: {
    name: "initCategory",
    id: "123",
    allQuestions: [],
    questions: [],
    groups: [],
    rmfActionPaths: [],
  },
  categoryId: null,
  defaultAnswers: [],
  linkedQuestions: [],
  group: null,
  groupId: null,
  groupOrder: null,
  helpText: null,
  id: "123",
  order: null,
  question: "Initial Question",
  questionType: QuestionType.FulfillText,
  rmfActionPaths: [],
  variableName: "init_question",
  answers: [
    {
      id: "123",
      riskIncrease: undefined,
      timeIncreaseInDays: 0,
      answerType: undefined,
      additionalFieldType: undefined,
      linkedQuestions: [],
    },
  ],
};

const initialState: any = {
  questionText: {
    editActive: false,
    saveStatus: "default",
  },
  helpText: {
    editActive: false,
    saveStatus: "default",
  },
  questionAnswers: {
    editActive: false,
    saveStatus: "default",
  },
  parentAssignment: {
    editActive: false,
    saveStatus: "default",
  },
  parentOrder: {
    editActive: false,
    saveStatus: "default",
  },
  rmfAnswers: {
    editActive: false,
    saveStatus: "default",
  },
  rmfPaths: {
    editActive: false,
    saveStatus: "default",
  },
  questionType: {
    editActive: false,
    saveStatus: "default",
  },
  header: {
    editActive: false,
    saveStatus: "default",
  },
  categoryName: {
    editActive: false,
    saveStatus: "default",
  },
};

const QuestionEditorDrawer: React.FC = () => {
  const navigate = useNavigate();
  const width = useDynamicDrawerWidth();
  const { questionId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionNode | any>(
    initialQuestionState,
  );

  console.log({ selectedQuestion });
  const [state, dispatch] = useReducer(questionMgmtReducer, initialState);
  const [open, setOpen] = useState(true);
  const { loading, refetch: refetchQuestion } = useQuery<GetFullQuestionaryQuery>(
    Api.Question.GetFullQuestionary(),
    {
      variables: {
        filter: {
          id: questionId,
        },
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 7);
      },
      onCompleted: (res) => {
        console.log(res);
        console.log("%c**setSelectedQuestion from onCompleted()**", "color:blue");
        setSelectedQuestion(res?.allQuestions[0]);
      },
    },
  );

  const [deleteQuestion, { loading: deleteLoading }] = useMutation<DeleteQuestionMutation>(
    Api.Question.Delete(),
    {
      onCompleted: (res) => {
        message.success("Successfully deleted question!", 5);
        onClose();
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message, 7);
      },
      refetchQueries: [
        { query: Api.QuestionModule.WorkflowEditor() },
        { query: Api.Question.GetAllOrphanedQuestions() },
      ],
    },
  );

  const onClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const deleteQuestionMutation = () => {
    deleteQuestion({
      variables: {
        id: questionId,
      },
    });
  };

  const invalidQuestion = selectedQuestion.id === "123";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      destroyOnClose={true}
      width={width}
      title="Manage Question"
      extra={
        <Button
          danger
          icon={<DeleteOutlined />}
          type="primary"
          key="delete"
          disabled={invalidQuestion}
          onClick={() => setDeleteModalOpen(true)}
        >
          Delete Question
        </Button>
      }
      bodyStyle={{ background: "#f0f0f0", padding: 0 }}
      keyboard={false}
      maskClosable={false}
    >
      <QuestionEditor
        state={state}
        dispatch={dispatch}
        loading={loading}
        question={selectedQuestion}
        refetchQuestion={refetchQuestion}
        key="questionEditor"
      />
      {deleteModalOpen && (
        <InputConfirmModal
          visible={deleteModalOpen}
          loading={deleteLoading}
          prompt="Are you sure you want to delete this question?"
          mutation={deleteQuestionMutation}
          confirmation="Question"
          onClose={onClose}
        />
      )}
    </Drawer>
  );
};

export default QuestionEditorDrawer;
