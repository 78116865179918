import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, ResetButton } from "..";

export const P8 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const SDEMarking = {
    label: "Select Marking",
    value: "security_description_environment_marking",
  };

  return (
    <>
      <div className="itcsc page">
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width="70%" />
              <col span={1} width="30%" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={3} className="title" style={{ border: 'none' }}>
                  14. Privacy <i>Refs: (dd-ee)</i>
                </th>
              </tr>
              <tr>
                <th colSpan={3} className="title" style={{ border: 'none', fontWeight: 'normal', textAlign: 'start', borderBottom: 'solid black 1px' }}>
                  <i>Note 12:</i> For assistance with completing this section, contact your Base Privacy Manager.
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>
                    14A. Complete DD Form 2930, Privacy Impact Assessment (PIA). Will this System process,<br />
                    store, or transmit PII/PHI?
                  </b><br />
                  <i>Note 13</i>: In the case where no PII is collected, the PIA will serve as a conclusive determination that
                  privacy requirements do not apply to the system.
                </td>
                <td style={{ padding: '5px' }}>
                  <EditableRadio {...elementProps} fieldName="14_privacy_yes" label="Yes" />
                  <EditableRadio {...elementProps} fieldName="14_privacy_no" label="No (Proceed to Section 15)" />
                </td>
              </tr>

            </tbody>
          </table>

          <table className="privacy">
            <colgroup>
              <col span={1} width="30%" />
              <col span={1} width="30%" />
              <col span={1} width="30%" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={3} className="title" style={{ border: 'none' }}>
                  14B. What PII will be collected (a data element alone or in combination that can uniquely identify an individual)? (Select all that apply)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingTop: 10 }}>
                  <b>Could be LOW</b>
                </td>
                <td style={{ paddingTop: 10 }}>
                  <b>Could be MODERATE</b>
                </td>
                <td style={{ paddingTop: 10 }}>
                  <b>Could be <u>HIGH</u></b>
                </td>
              </tr>

              <tr>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_dod_id'} label={"DOD ID Number (EDIPI)"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_birth_date'} label={"Birth Date"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_law_enforcement_information'} label={"Law Enforcement Information"} /></td>
              </tr>

              <tr>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_employment_information'} label={"Employment Information"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_birth_place'} label={"Birth Place"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_legal_records'} label={"Legal Records"} /></td>
              </tr>

              <tr>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_home_cell_phone'} label={"Home / Cell Phone"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_child_information'} label={"Child Information"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_medical_information'} label={"Medical Information"} /></td>
              </tr>

              <tr>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_mailing_home_address'} label={"Mailing/Home Address"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_citizenship'} label={"Citizenship"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_passport_number'} label={"Passport Number"} /></td>
              </tr>

              <tr>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_official_duty_address'} label={"Official Duty Address"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_drivers_license'} label={"Driver's License"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_phi'} label={"Protected Health Information (PHI)"} /></td>
              </tr>

              <tr>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_personal_email_address'} label={"Personal Email Address"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_emergency_contact'} label={"Emergency Contact"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_security_information'} label={"Security Information"} /></td>
              </tr>

              <tr>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_photo'} label={"Photo"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_financial_information'} label={"Financial Information"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_ssn'} label={"Social Security Number (SSN)"} /></td>
              </tr>

              <tr>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_position'} label={"Position / Title Rank / Grade"} /></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_gender'} label={"Gender / Gender Identification"} /></td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_legal_status'} label={"Legal Status"} /></td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_maiden_name'} label={"Mother's Middle / Maiden Name"} /></td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_other_id_number'} label={"Other ID Number"} /></td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_photo_with_ephemeris_data'} label={"Photo with ephemeris data"} /></td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td><EditableCheckbox {...elementProps} fieldName={'14b_race'} label={"Race / Ethnicity"} /></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
