import { QuestionType } from "types";
import { FieldEditState, SaveStatusEnum, SaveStatusType } from "../../Shared/types";

export type FieldKeys =
  | "questionText"
  | "helpText"
  | "questionAnswers"
  | "parentAssignment"
  | "parentOrder"
  | "categoryName"
  | "header"
  | "rmfPaths"
  | "rmfAnswers"
  | "questionType";

export enum FieldKeysEnum {
  questionText = "questionText",
  helpText = "helpText",
  questionAnswers = "questionAnswers",
  parentAssignment = "parentAssignment",
  parentOrder = "parentOrder",
  header = "header",
  rmfPaths = "rmfPaths",
  rmfAnswers = "rmfAnswers",
  questionType = "questionType",
}

export type EditStateType = {
  fieldKey?: FieldKeys;
  editActive: boolean;
};

export type SaveStateType = {
  fieldKey?: FieldKeys;
  status: SaveStatusType;
};

export type ParentType = "group" | "category" | "orphan" | undefined;
export enum ParentTypeEnum {
  group = "group",
  category = "category",
  orphan = "orphan",
}

export type OrderHeadingType = "Category Order" | "Group Order" | "unassigned";
export enum OrderHeadingEnum {
  categoryOrder = "Category Order",
  groupOrder = "Group Order",
  unassigned = "unassigned",
}

export type QuestionInfoValuesType = {
  questionText?: string;
  helpText?: string;
  groupHeader?: string | null;
};
export type ParentStateType = {};

export type CategoryState = {
  categoryName?: string;
  categoryId?: string;
  categoryOrder?: string;
};

export type GroupState = {
  groupHeader?: string;
  groupId?: string;
  groupOrder?: number;
};

export type SelectedQuestionState = {
  questionType: QuestionType;
  questionIsSelect: boolean;
  questionHasAnswers: boolean;
  orderHeading: OrderHeadingType;
  parentStatus: ParentType;
  editableOrder?: number | null;
  categoryName?: string;
  categoryId?: string;
  categoryOrder?: number | null;
  groupHeader?: string;
  groupId?: string;
  groupOrder?: number | null;
};

export type QuestionEditorState = {
  questionText: FieldEditState;
  helpText: FieldEditState;
  questionAnswers: FieldEditState;
  parentAssignment: FieldEditState;
  parentOrder: FieldEditState;
  rmfAnswers: FieldEditState;
  rmfPaths: FieldEditState;
  // below are not editable, but required for cleaner code / later feature
  categoryName: FieldEditState;
  header: FieldEditState;
  questionType: FieldEditState;
};

export enum QuestMgmtReducerActionEnum {
  ToggleEdit = "toggleEdit",
  SetLoading = "setLoading",
  SetSuccess = "setSuccess",
  SetError = "setError",
  SetDefault = "setDefault",
  UpdateQuestion = "updateQuestion",
}

export type QuesMgmtReducer =
  | {
      type: QuestMgmtReducerActionEnum.SetDefault;
      params: any;
    }
  | {
      type: QuestMgmtReducerActionEnum.SetLoading;
      params: any;
    }
  | {
      type: QuestMgmtReducerActionEnum.SetSuccess;
      params: any;
    }
  | {
      type: QuestMgmtReducerActionEnum.SetError;
      params: any;
    }
  | {
      type: QuestMgmtReducerActionEnum.ToggleEdit;
      params: any;
    };

export const questionMgmtReducer = (
  state: QuestionEditorState,
  action: QuesMgmtReducer,
): any => {
  console.log("Reducer Logs");
  console.log({ stateInReducer: state });
  console.log({ action });
  const fieldKey: FieldKeys = action?.params?.fieldKey;

  switch (action.type) {
    case QuestMgmtReducerActionEnum.ToggleEdit:
      console.log("%ctoggleEdit **", "color:cyan");
      console.group("toggle edit");
      const toggleState = { ...state };
      const currentFieldState = toggleState[fieldKey];
      const editActive: boolean | undefined = currentFieldState?.editActive;
      console.log({ fieldKey }, { was: editActive }, { setTo: !editActive });

      console.log({ currentFieldState });

      const newFieldState = {
        ...currentFieldState,
        editActive: !editActive,
      };
      console.log({ newFieldState });
      console.log({
        returnState: {
          ...state,
          [fieldKey]: newFieldState,
        },
      });
      console.groupEnd();
      return {
        ...state,
        [fieldKey]: newFieldState,
      };

    case QuestMgmtReducerActionEnum.SetLoading:
      console.log("%csetLoading", "color:green");
      const setLoadingState: QuestionEditorState = { ...state };
      console.log({ fieldKey });
      setLoadingState[fieldKey].saveStatus = SaveStatusEnum.Loading;
      console.log({ setLoadingState });
      return {
        ...setLoadingState,
      };

    case QuestMgmtReducerActionEnum.SetSuccess:
      console.log("%csetSuccess", "color:green");
      const stopLoadingState = { ...state };
      stopLoadingState[fieldKey].saveStatus = SaveStatusEnum.Success;
      return {
        ...stopLoadingState,
      };

    case QuestMgmtReducerActionEnum.SetError:
      console.log("%csetError", "color:green");
      const setErrorState = { ...state };
      setErrorState[fieldKey].saveStatus = SaveStatusEnum.Error;

      return {
        ...setErrorState,
      };

    case QuestMgmtReducerActionEnum.SetDefault:
      console.log("%csetDefault", "color:green");
      const setDefaultState = { ...state };
      setDefaultState[fieldKey].saveStatus = SaveStatusEnum.Default;

      return {
        ...setDefaultState,
      };
    default:
      return state;
  }
};
