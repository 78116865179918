import { Space, SpaceProps } from "antd";

interface Props extends SpaceProps {
  children: any;
}

export const HorizontalLabel: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Space style={{ width: "100%" }} wrap {...rest}>
      {children}
    </Space>
  );
};

export const VerticalLabel: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Space direction="vertical" style={{ width: "100%" }} {...rest}>
      {children}
    </Space>
  );
};
