import { Row } from "antd";
import { ApplicationQuestionNode } from "types";
import saveStatusColor from "../../Components/SaveStatusColor";

interface Props {
  question: ApplicationQuestionNode;
  saveStatus: string;
  children: any;
}

const GridQuestion: React.FC<Props> = ({ saveStatus, children }) => {
  const statusColor = saveStatusColor(saveStatus);
  return (
    <Row
      style={{
        flex: 1,
        borderLeft: `4px solid ${statusColor}`,
      }}
    >
      {children}
    </Row>
  );
};

export default GridQuestion;
