import ListQuestionRenderer from "./ListQuestionRenderer";
import { List } from "antd";
import { ApplicationQuestionNode } from "types";
import { useQuestionnairePageContext } from "../../Questionnaire";

export type ListContent = Array<ApplicationQuestionNode>;

const ListPage: React.FC = () => {
  const { page } = useQuestionnairePageContext();

  return (
    <List
      itemLayout="vertical"
      dataSource={page.questions}
      renderItem={(item: ApplicationQuestionNode) => <ListQuestionRenderer question={item} />}
    />
  );
};

export default ListPage;
