import React, { useState } from "react";
import { useAuthContext } from "../Providers/WithAuth";
import useDocumentTitle from "hooks/useDocumentTitle";
import useViewport from "hooks/useViewport";
import { PageHeaderSubTitle, PageHeaderTitle } from "components/Common/PageHeader/PageHeader";
import { Tabs, Row, Col, Card, PageHeader } from "antd";

interface Props {
  tabs: Array<any>;
}

const Account: React.FC<Props> = ({ tabs }) => {
  useDocumentTitle("My Account");
  const { user } = useAuthContext();
  const viewport = useViewport();
  const [activeTab, setActiveTab] = useState("information");

  const handleTabChange = (activeTab: any) => {
    setActiveTab(activeTab);
  };

  return (
    <>
      <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          <PageHeader
            className="shadowed-static"
            title={<PageHeaderTitle title="My Account" level={1} />}
            subTitle={
              <PageHeaderSubTitle
                subTitle={user.fullname}
                styles={{ textTransform: "capitalize" }}
              />
            }
            ghost={false}
            style={{ height: 72 }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card className="shadowed-static" bodyStyle={viewport.account.styles.card}>
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              items={tabs}
              tabPosition={viewport.account.tabPosition}
              centered={viewport.account.tabsCentered}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Account;
