import React, { ReactNode, useMemo } from "react";
import { Col, Divider, PageHeader, PageHeaderProps, Row, Space, Typography } from "antd";
import Icon from "@ant-design/icons";
import useBreakpoints from "hooks/useBreakpoints";

const { Title } = Typography;

type Level = 1 | 2 | 3 | 4 | 5 | undefined;

type TitleProps = {
  title: string;
  level: Level;
  styles?: any;
  icon?: any;
};

type SubTitleProps = {
  subTitle: string;
  level?: Level;
  styles?: any;
};

interface HeaderProps extends PageHeaderProps {
  className?: string;
  title: string;
  level?: Level;
  subTitle?: string;
  icon?: any;
  children?: any;
  headerStyles?: any;
  titleStyles?: any;
  subTitleStyles?: any;
  actions?: Array<ReactNode> | undefined;
  onBack?: any;
  footer?: any;
}

export const PageHeaderTitle: React.FC<TitleProps> = ({ title, level, styles, icon }) => {
  const fontStyles = useMemo(() => {
    let styles = {
      marginBottom: 0,
      fontSize: 30,
      lineHeight: "38px",
    };
    switch (level) {
      case 1:
        styles = {
          ...styles,
          fontSize: 30,
          lineHeight: "38px",
        };
        break;
      case 2:
        styles = { ...styles, fontSize: 24, lineHeight: "32px" };
        break;
      case 3:
        styles = { ...styles, fontSize: 20, lineHeight: "28px" };
        break;

      case 4:
        styles = { ...styles, fontSize: 16, lineHeight: "24px" };
        break;
      case 5:
        styles = { ...styles, fontSize: 14, lineHeight: "22px" };
        break;

      default:
        styles = { ...styles, fontSize: 12, lineHeight: "20px" };
        break;
    }
    return styles;
  }, [level]);

  return (
    <Title level={level} style={styles ? { ...styles, ...fontStyles } : { ...fontStyles }}>
      {icon ? (
        <Space align="end">
          <Icon component={() => icon} />
          {title}
        </Space>
      ) : (
        title
      )}
    </Title>
  );
};

export const PageHeaderSubTitle: React.FC<SubTitleProps> = ({
  subTitle,
  level = 2,
  styles,
}) => {
  const defaultStyles = {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.45)",
    fontWeight: 400,
    marginBottom: 0,
  };

  return (
    <Title
      style={styles ? { ...styles, ...defaultStyles } : { ...defaultStyles }}
      level={level}
    >
      <div style={{ paddingTop: 10 }}>{subTitle}</div>
    </Title>
  );
};

const CustomPageHeader: React.FC<HeaderProps> = (props) => {
  const { breakpoints } = useBreakpoints();
  const {
    className,
    title,
    level,
    icon,
    subTitle,
    headerStyles,
    titleStyles,
    subTitleStyles,
    children,
    actions,
    footer,
    ...rest
  } = props;

  return (
    <Row className={className} style={{ marginBottom: 16 }}>
      <Col span={24}>
        <PageHeader
          title={
            <PageHeaderTitle
              title={title}
              level={level ? level : 1}
              styles={titleStyles}
              icon={icon}
            />
          }
          subTitle={
            subTitle &&
            breakpoints.md && (
              <PageHeaderSubTitle subTitle={subTitle} styles={subTitleStyles} />
            )
          }
          className="shadowed-static"
          style={!breakpoints.md ? { padding: "12px", ...headerStyles } : headerStyles}
          footer={footer}
          ghost={false}
          extra={actions}
          {...rest}
        >
          {children ? (
            <>
              <Divider style={{ marginTop: 0 }} />
              {children}
            </>
          ) : null}
        </PageHeader>
      </Col>
    </Row>
  );
};

export default CustomPageHeader;
