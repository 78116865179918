import { FulfillInputProps } from "../Questions/FulfillQuestion";
import { Input } from "antd";

interface TextInputProps {
  rows?: number;
}

const FulfillTextInput: React.FC<FulfillInputProps & TextInputProps> = ({
  answerId,
  defaultAnswer,
  onChange,
  disabled,
  style,
  rows = 3,
}) => {
  return (
    <Input.TextArea
      id={answerId}
      rows={rows}
      autoComplete="off"
      placeholder="Enter text"
      defaultValue={defaultAnswer}
      onBlur={(e) => {
        let value = e.target.value;
        const answer = {
          id: answerId,
          text: value,
        };

        onChange(answer);
      }}
      disabled={disabled}
      style={{
        display: "flex",
        width: "100%",
        ...style,
      }}
    />
  );
};

export default FulfillTextInput;
