import { CloseOutlined, InfoCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Skeleton, Tooltip, Typography } from "antd";
import SaveStatusIndicator from "components/Common/Tags/UpdateSaveStatusTag";
import { CategoryFieldKeysEnum } from "../Category Editor/Components/reducer";
import { GroupFieldKeysEnum } from "../Group Editor/Components/reducer";
import { FieldKeysEnum as QuestionFieldKeysEnum } from "../Question Editor/Components/reducer";
import { SaveStatusEnum } from "./types";

const { Title } = Typography;

type TooltipProp = string | undefined;

type SidebarSectionProps = {
  title: string;
  tooltip?: TooltipProp;
  actionMenu?: any; // JSX
  saveStatus?: SaveStatusEnum;
  renderSaveStatus?: boolean;
  loading: Loading;
  valueLoading?: boolean;
  children: any;
};

type TooltipTitleProps = {
  tooltip?: TooltipProp;
  title: string;
};

type SidebarButtonProps = {
  loading: Loading;
  tooltip?: TooltipProp;
  fieldKey: GroupFieldKeysEnum | QuestionFieldKeysEnum | CategoryFieldKeysEnum;
  editingCurrentField?: boolean;
  setEditState?: any;
  customAction?: any;
};

type RowSkeletonProps = {
  loading: Loading;
  children: any;
};

const RowSkeletonWrapper: React.FC<RowSkeletonProps> = ({ loading, children }) => {
  return loading ? <Skeleton active={true} paragraph={{ rows: 1 }} title={false} /> : children;
};

const SidebarSection: React.FC<SidebarSectionProps> = ({
  title,
  tooltip,
  actionMenu,
  loading,
  children,
  saveStatus,
  renderSaveStatus,
  valueLoading,
}) => {
  return (
    <Row justify="space-between">
      <Col span={22}>
        <TooltipTitle title={title} tooltip={tooltip} />
        <RowSkeletonWrapper loading={loading || valueLoading!}>{children}</RowSkeletonWrapper>
      </Col>
      <Col span={2}>
        {renderSaveStatus! ? (
          <SaveStatusIndicator status={saveStatus!} styles={{ marginLeft: -64 }} />
        ) : (
          actionMenu
        )}
      </Col>
      <Divider style={styles.divider} />
    </Row>
  );
};

const TooltipTitle: React.FC<TooltipTitleProps> = ({ tooltip, title }) => {
  return tooltip ? (
    <Tooltip
      title={tooltip}
      color="#000000"
      style={{ fontSize: 14 }}
      placement="topLeft"
      mouseEnterDelay={0.3}
    >
      <Title level={5} style={{ marginBottom: 16 }}>
        {title}
        <span style={{ marginLeft: 8 }}>
          <InfoCircleOutlined style={{ fontSize: 14 }} />
        </span>
      </Title>
    </Tooltip>
  ) : (
    <Title level={5} style={{ marginBottom: 16 }}>
      {title}
    </Title>
  );
};

export const SidebarManageButton: React.FC<SidebarButtonProps> = ({
  tooltip,
  loading,
  fieldKey,
  setEditState,
  customAction,
  editingCurrentField,
}) => {
  const onCancel = () => {
    setEditState(fieldKey);
  };

  const onAction = () => {
    if (customAction) {
      console.log("%cCUSTOM ACTION RAN", "color:blue");
      customAction();
    } else {
      setEditState(fieldKey);
      console.log("%cSET EDIT STATE RAN", "color:blue");
      console.log({ fieldKey });
    }
  };

  const ActionButton = () => {
    return (
      <Button icon={<SettingOutlined />} size="small" onClick={onAction} disabled={loading} />
    );
  };

  const CancelButton = () => {
    return (
      <Button size="small" icon={<CloseOutlined />} onClick={onCancel} disabled={loading} />
    );
  };

  const ActionTipButton = () => {
    // span > button required to be direct child of tip for it to render
    return tooltip ? (
      <Tooltip title={tooltip} color="#000000" placement="left" mouseEnterDelay={0.7}>
        <span>
          <ActionButton />
        </span>
      </Tooltip>
    ) : (
      <span>
        <ActionButton />
      </span>
    );
  };

  return editingCurrentField ? <CancelButton /> : <ActionTipButton />;
};

const styles = {
  divider: {
    margin: "20px -12px 20px -12px",
    borderTop: "1.5px solid rgba(0, 0, 0, 0.06)",
  },
};

export default SidebarSection;
