import { gql } from "graphql-tag";

export const ApplicationDocuments = {
  GetAll: () => gql`
    query getApplicationDocuments($applicationId: String!) {
      allApplicationDocuments(applicationId: $applicationId) {
        id
        documentName
        documentVersion
        state
        stateUpdatedOn
      }
    }
  `,
  GetDefinition: () => gql`
    query GetDefinition( $documentId: String!, $applicationId: String ){
      getApplicationDocumentDefinition( documentId:$documentId, applicationId: $applicationId ){
        anchors {
          key
          label
          order
          name
          email
        }
        ccs {
          name
          email
        }
      }
    }
  `,
  GetDownloadUrl: () => gql`
    query GetDownloadUrl($applicationDocumentId: String!){
      getApplicationDocumentDownloadUrl(applicationDocumentId:$applicationDocumentId)
    }
  `,
  SendToDocusign: () => gql`
    mutation sendToDocusign(
      $applicationId: String!,
      $documentId:  String!,
      $signers: [DsUserNode]!,
      $ccs: [DsUserNode]
    ){
      sendToDocusign(
        applicationId: $applicationId,
        documentId: $documentId,
        signers: $signers,
        ccs: $ccs,
      ){
        ack
      }
    }
  `
};
