import { useState } from "react";
import ListQuestionRenderer, { ListRendererProps } from "./ListQuestionRenderer";
import { List, Tag, Tooltip } from "antd";
import { SisternodeOutlined } from "@ant-design/icons";
import { ApplicationQuestionNode } from "types";

const LinkedQuestionsRenderer: React.FC<ListRendererProps> = (props) => {
  const [open, setOpen] = useState(true);

  if (!props.question.linkedQuestions) return <></>;
  if (!props.question.linkedQuestions.length) return <></>;

  const listClassName = "linked-questions-list " + (open ? "" : "link-mini");
  const headerClassName =
    "shadowed-static-dark link-header " + (open ? "link-header" : "link-header-mini");

  const questions = props.question.linkedQuestions as Array<ApplicationQuestionNode>;
  function countLinkedQuestions(list: Array<ApplicationQuestionNode>): number {
    return (list ?? []).reduce((count, q: any) => {
      return count + 1 + countLinkedQuestions(q.linkedQuestions);
    }, 0);
  }
  const count = countLinkedQuestions(questions);

  return (
    <div className={listClassName}>
      <Tooltip mouseEnterDelay={0.2} title={open ? "Minimize" : "Expand"}>
        <div className={headerClassName} onClick={() => setOpen(!open)}>
          <SisternodeOutlined className="link-icon" />
        </div>
      </Tooltip>
      {open ? (
        <List
          itemLayout="vertical"
          dataSource={questions}
          renderItem={(item: ApplicationQuestionNode) => (
            <ListQuestionRenderer question={item} />
          )}
        />
      ) : (
        <MinimizedLinkedQuestions count={count} onClick={() => setOpen(!open)} />
      )}
    </div>
  );
};

export default LinkedQuestionsRenderer;

const MinimizedLinkedQuestions = ({ count, onClick }) => (
  <div className="minimized-linked-questions-list" onClick={onClick}>
    <Tag className="minimized-tag">
      Minimized <b>{count}</b> Linked Questions
    </Tag>
  </div>
);
