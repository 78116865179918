import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, ResetButton } from "..";


export const P7 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const ABTSLMarking = {
    label: "Select Marking",
    value: "system_architecture_marking",
  };

  const CILDMarking = {
    label: "Select Marking",
    value: "cloud_impact_level_determination_marking",
  };

  return (
    <>
      <div className="itcsc page">
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width="60%" />
              <col span={1} width="40%" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={3} className="title" >
                  13. Overlays <i>Ref (w-cc)</i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>13A. Intelligence Overlay:</b> Does the System process, store, or transmit<br />
                  Intelligence, Surveillance, or Reconnaissance (ISR)?
                </td>
                <td style={{ padding: '5px' }}>
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '100%' }}
                    fieldName="13a_overlays"
                    options={[
                      { label: 'Yes (Intelligence Overlay required)', value: "13a_overlays_yes" },
                      { label: 'No', value: "13a_overlays_no" },
                    ]}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <b>13B. Cross Domain Solution (CDS) Overlay</b>: Will you implement,<br />
                  manage, or maintain a CDS?
                </td>
                <td style={{ padding: '5px' }}>
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '100%' }}
                    fieldName="13b_overlays"
                    options={[
                      { label: 'Yes (CDS Overlay required)', value: "13b_overlays_yes" },
                      { label: 'No', value: "13b_overlays_no" },
                    ]}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <b>
                    <span style={{ wordSpacing: '30px' }}>
                      13C. Nuclear Command, Control & Communications
                    </span>
                    <br />
                    (NC3) Overlay
                  </b>
                  : Does the System store, process, or transmit NC3 data?
                </td>
                <td style={{ padding: '5px' }}>
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '100%' }}
                    fieldName="13c_overlays"
                    options={[
                      { label: 'Yes (NC3 & Intelligence Overlays required)', value: "13c_overlays_yes" },
                      { label: 'No', value: "13c_overlays_no" },
                    ]}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <b>13D. Space Platform Overlay</b>: Is the System or (subsystem) a space<br />
                  platform (as defined in Committee on National Security Systems<br />
                  Instruction (CNSSI) 1253F, Attachment 2) and unmanned?
                </td>
                <td style={{ padding: '5px' }}>
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '100%' }}
                    fieldName="13d_overlays"
                    options={[
                      { label: 'Yes (Space Platform Overlay required)', value: "13d_overlays_yes" },
                      { label: 'No', value: "13d_overlays_no" },
                    ]}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <b>13E. Classified Information Overlay</b>: Does the System store, process, or<br />
                  transmit classified information?
                </td>
                <td style={{ padding: '5px' }}>
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '100%' }}
                    fieldName="13e_overlays"
                    options={[
                      { label: 'Yes (Classified Overlay required)', value: "13e_overlays_yes" },
                      { label: 'No', value: "13e_overlays_no" },
                    ]}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <b>13F. Privacy Overlay</b>: Does the information system contain PII?<br />
                  See sect 14 and instructions
                </td>
                <td style={{ padding: '5px' }}>
                  <EditableSelect
                    {...elementProps}
                    style={{ width: '100%' }}
                    fieldName="13f_overlays"
                    options={[
                      { label: 'Yes (Privacy Overlay required)', value: "13f_overlays_yes" },
                      { label: 'No', value: "13f_overlays_no" },
                    ]}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <b>13G. DOD Functional Mission / Capability Specific Overlay</b>: Is your<br />
                  System required to execute an organizational mission or function-specific<br />
                  capability (e.g., Financial, Acquisition)?
                </td>
                <td style={{ padding: '5px' }}>
                  <EditableRadio {...elementProps} fieldName={'13g_overlays_yes'} label="Yes (Specify Overlay)" />
                  <EditableField {...elementProps} fieldName={'13g_overlays_text'} />
                  <EditableRadio {...elementProps} fieldName={'13g_overlays_no'} label="No" />
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <ResetButton>Reset Section 13</ResetButton>
      </div>
    </>
  );
};
