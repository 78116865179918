import { useQuery } from "@apollo/client";
import { message } from "antd";
import Api from "api";
import { ApplicationCategoryQuery, ApplicationQuestionCategoryNode } from "types";

const useCategory = (categoryId: string) => {
  const { data, loading, error } = useQuery<ApplicationCategoryQuery>(
    Api.ApplicationCategory.GetOne(),
    {
      variables: {
        id: categoryId,
      },
      onError: (error) => {
        console.log(error.message);
        message.error("Category not found", 7);
      },
    },
  );

  const category: ApplicationQuestionCategoryNode | any = data?.applicationCategory;
  console.log("%cCategory", "color: steelblue", { Category: category });

  return { category, loading, error };
};

export default useCategory;
