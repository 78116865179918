import { Link, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { message, Button, Card, Row, Col } from "antd";
import {
  BranchesOutlined,
  PlusOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Api from "api";
import useDocumentTitle from "hooks/useDocumentTitle";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import SmartTable from "components/Common/Tables";
import { ColumnsType } from "antd/es/table";
import { mapTagToType as mapAnswerCondition } from "components/Common/Tags/AnswerCondition";
import { mapTagToType } from "components/Common/Tags/QuestionType";

import "styles/draggableTable.css";

const AnswerConditionTable: React.FC = () => {
  useDocumentTitle("Answer Conditions Management");

  const { data, loading } = useQuery(Api.ConditionalLogic.GetAllConditionalRelationships(), {
    onCompleted: (res) => {},
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 5);
    },
  });

  const allConditionalRelationships = data?.allConditionalRelationships;
  allConditionalRelationships &&
    console.log({ AllConditionalRelationships: allConditionalRelationships });

  const links: Array<string> = [];

  const mapChildrenRecursively = (list) =>
    list.map((q) => {
      links.push(q.question.id);
      const result: any = {
        id: q.answer?.id,
        question: q.question,
        answer: q.answer?.answer,
        answerCondition: q.answer,
        category: q.question?.category,
      };
      if (q.question?.linkedQuestions.length)
        result.children = mapChildrenRecursively(q.question?.linkedQuestions ?? []);

      return result;
    });

  const relationships = allConditionalRelationships
    ?.map((rel) => {
      const result: any = {
        id: rel.parentQuestion?.id,
        question: rel.parentQuestion,
        answer: rel.parentAnswerChoice,
        answerCondition: rel.logicType,
        module: rel.parentModule,
        category: rel.parentCategory,
      };

      if (rel.parentAnswerChoice?.additionalFieldType) {
        result.additionalFieldType = rel.parentAnswerChoice.additionalFieldType;
      }

      if (rel.parentQuestion?.linkedQuestions.length)
        result.children = mapChildrenRecursively(rel.parentQuestion?.linkedQuestions ?? []);

      return result;
    })
    .filter((rel) => !links.includes(rel.id));

  console.log("%cRelationships", "color: steelblue", relationships);

  const columns: ColumnsType<any> = [
    {
      dataIndex: "question",
      title: "Parent Question",
      key: "question",
      render: (item, row) => <>{item?.question}</>,
    },
    {
      dataIndex: "answer",
      title: "Parent Answer",
      key: "answer",
      render: (item, row) => <>{item?.answer}</>,
    },
    {
      dataIndex: "answerCondition",
      title: "Condition Type",
      key: "answerCondition",
      render: (item, row) => {
        if (row?.additionalFieldType) {
          return (
            <Row align="middle" style={{ marginRight: -7 }} wrap={false}>
              {mapAnswerCondition(item)}
              <RightOutlined style={{ marginRight: 8, fontSize: 12 }} />
              {mapTagToType(row.additionalFieldType)}
            </Row>
          );
        }
        return mapAnswerCondition(item);
      },
    },
    {
      dataIndex: "category",
      title: "Category",
      key: "category",
      render: (item, row) => <>{item?.name}</>,
    },
    {
      dataIndex: "module",
      title: "Module",
      key: "module",
      render: (item, row) => <>{item?.name}</>,
    },
    {
      dataIndex: "question",
      title: "Manage",
      key: "question",
      align: "center",
      width: "1%",
      render: (item, row) => (
        <Link
          to={`/administration/answer-conditions/condition-editor/${item?.id}`}
          state={{
            condition: {
              ...row,
            },
          }}
        >
          <Button icon={<SettingOutlined />} />
        </Link>
      ),
    },
  ];

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="Answer Conditions"
        actions={[
          <Link to={`/administration/answer-conditions/condition-editor/new`}>
            <Button type="primary" icon={<PlusOutlined />}>
              Create
            </Button>
          </Link>,
        ]}
        icon={<BranchesOutlined style={{ color: "#1890FF" }} />}
      />
      <Row align="middle">
        <Col span={24}>
          <Card className="shadowed-static">
            <SmartTable
              loading={loading}
              data={relationships}
              columns={columns}
              pagination={false}
              rowKey={(condition) => condition.id}
              scroll={{ x: 800 }}
              size="small"
            />
          </Card>
        </Col>
      </Row>
      <Outlet />
    </>
  );
};

export default AnswerConditionTable;
