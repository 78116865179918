import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Select,
  Button,
  message,
  Space,
  Divider,
  Col,
  InputNumber,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  FileMarkdownOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import Api from "api";
import LoadingSpinner from "../../../../Common/LoadingSpinner";
import { QuestionTypesEnums } from "api/enums";
import { QuestionType, RiskLevel } from "types";
import { useCategoryList } from "../../../../../api/hooks/useCategoryList";
import MarkdownDrawer from "components/Common/Markdown/MarkdownDrawer";
import { mapTagToType } from "components/Common/Tags/QuestionType";

const { Option } = Select;

// todo: Swap state setting on initial values for form.setFieldsValue
/**
 *
 * purpose: provides form to create a question
 * @returns
 * text field form
 * select field form
 * NOTICE: Antd form limitations require all of the conditions for buttons
 */

type Answer = {
  answer?: string;
  timeIncreaseInDays?: number;
  riskIncrease?: RiskLevel;
};

type InitialValues = {
  answers: [Answer] | [Answer, Answer] | any;
};

type Props = {
  closeModal: () => void;
  initialValues?: InitialValues;
};

type SelectFormProps = {
  initialValues: InitialValues;
  questionType: string;
  createLoading: boolean;
  hasSecondAnswer: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const toolTipStyle = {
  color: "#000000",
  icon: <InfoCircleOutlined />,
};

const CreateQuestion: React.FC<Props> = ({ closeModal }) => {
  const [form] = Form.useForm();
  const [hasMarkdownValue, setHasMarkdownValue] = useState(false);
  const [markdownEditorOpen, setMarkdownEditorOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const questionType = Form.useWatch("questionType", form);
  const questionText = Form.useWatch("questionText", form);
  const categoryId = Form.useWatch("categoryId", form);
  const helpText = Form.useWatch("helpText", form);
  const formVals = form.getFieldsValue(true);
  const isOrphan = categoryId ? false : true;
  const [initialValues, setInitialValues] = useState<InitialValues>({
    answers: [
      {
        answer: undefined,
        timeIncreaseInDays: 0,
        riskIncrease: RiskLevel.None,
      },
    ],
  });
  const refetchQueries = isOrphan
    ? [
        { query: Api.Question.GetAllOrphanedQuestions() },
        { query: Api.QuestionModule.WorkflowEditor() },
      ]
    : [{ query: Api.QuestionModule.WorkflowEditor() }];
  const [hasSecondAnswer, setHasSecondAnswer] = useState(false);
  const [createQuestion, { loading: createLoading }] = useMutation(Api.Question.Create(), {
    onCompleted: (data) => {
      console.log(data);
      message.success("Question created successfully!", 7);
      closeModal();
    },
    onError: (error) => {
      console.log(error);
      message.error(error.message, 7);
    },
    refetchQueries: refetchQueries,
  });
  const { categories, loading } = useCategoryList();

  const canNext = questionType && questionText ? true : false;

  useEffect(() => {
    // sets initial values for two form types
    if (questionType === "FULFILL_TEXT") {
      setInitialValues({
        answers: [
          {
            answer: undefined,
            timeIncreaseInDays: 0,
            riskIncrease: RiskLevel.None,
          },
        ],
      });
    } else {
      setInitialValues({
        answers: [
          {
            answer: undefined,
            timeIncreaseInDays: undefined,
            riskIncrease:  RiskLevel.None,
          },
          {
            answer: undefined,
            timeIncreaseInDays: undefined,
            riskIncrease:  RiskLevel.None,
          },
        ],
      });
    }
  }, [questionType]);

  const handleAnswersValidation = (fields: any) => {
    // validates/ blocks submit button until at least 2 answer on multi-choice
    if (currentPage === 1) {
      if (fields.answers[0] && fields.answers[1]) {
        console.log("Both answers were provided");
        setHasSecondAnswer(true);
      } else {
        console.log("missing answers");
        setHasSecondAnswer(false);
      }
    }
  };

  const onFinish = () => {
    const vals = form.getFieldsValue(true);
    console.log(vals);
    const freeTextAnswers = [
      {
        answer: "",
        timeIncreaseInDays: Number(vals.timeIncreaseInDays),
        riskIncrease: vals.riskIncrease,
      },
    ];

    const answers =
      vals.answers && vals.answers.length > 1 ? [...vals.answers] : freeTextAnswers;

    createQuestion({
      variables: {
        categoryId: vals.categoryId,
        variableName: `var_${Math.round(Math.random() * 100)}`,
        question: vals.questionText,
        questionType: vals.questionType,
        answers: answers,
        order: Number(vals.order),
        helpText: vals.helpText,
      },
    });
  };

  const openMarkdownEditor = () => {
    setMarkdownEditorOpen(true);
  };

  const onValuesChange = (_, allFields) => {
    console.log({ allFields });
    handleAnswersValidation(allFields);
  };

  const handleMarkdownValue = (value: string) => {
    form.setFieldValue("helpText", value);
    setHasMarkdownValue(true);
  };

  const clearMarkdownValue = () => {
    form.setFieldValue("helpText", undefined);
    setHasMarkdownValue(false);
  };

  useEffect(() => {
    console.log({ helpText, formVals });
  }, [helpText, formVals]);

  const helpTextWidth = hasMarkdownValue ? "calc(100% - 64px)" : "calc(100% - 32px)";

  // conditions for submit or paginate footer buttons
  const renderSubmit: boolean =
    questionType &&
    questionType !== QuestionType.MultipleChoicePickOne &&
    questionType !== QuestionType.MultipleChoicePickMany;
  const renderStepFooter: boolean =
    (questionType && questionType === QuestionType.MultipleChoicePickOne) ||
    questionType === QuestionType.MultipleChoicePickMany;

  return (
    <>
      <Form
        form={form}
        name="questionEditorForm"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        {currentPage === 0 ? (
          <>
            <Form.Item
              label="Belongs to category"
              name="categoryId"
              tooltip={{
                title:
                  "Attaches the question to a category, leaving this blank will leave this question without a parent category",
                ...toolTipStyle,
              }}
            >
              {loading ? (
                <LoadingSpinner />
              ) : (
                <Select allowClear={true}>
                  {categories?.map((category: BasicQuestionCategory) => (
                    <Option value={category.id} key={category.id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Order in category"
              name="order"
              tooltip={{
                title: "The order within the category this question should be given",
                ...toolTipStyle,
              }}
            >
              <InputNumber min={0} style={{ width: "15%" }} />
            </Form.Item>
            <Form.Item
              label="Question text"
              name="questionText"
              rules={[{ required: true }]}
              tooltip={{
                title: "The question text displayed to a user answering the question",
                ...toolTipStyle,
              }}
            >
              <Input.TextArea autoSize={true} />
            </Form.Item>
            <Form.Item
              label="Question help text"
              name="helpText"
              tooltip={{
                title: "Provide text and/or a link to help user understand the question",
                ...toolTipStyle,
              }}
            >
              <Input.Group compact>
                <Input.TextArea
                  autoSize={true}
                  style={{ width: helpTextWidth }}
                  disabled={hasMarkdownValue}
                  placeholder={hasMarkdownValue ? "Markdown value set" : undefined}
                />
                <Tooltip title="Launch Markdown Editor" color="#000000" mouseEnterDelay={0.2}>
                  <Button icon={<FileMarkdownOutlined />} onClick={openMarkdownEditor} />
                </Tooltip>
                {hasMarkdownValue && (
                  <Tooltip title="Clear markdown value" color="#000000" mouseEnterDelay={0.2}>
                    <Button danger icon={<DeleteOutlined />} onClick={clearMarkdownValue} />
                  </Tooltip>
                )}
              </Input.Group>
            </Form.Item>
            <Form.Item
              label="Data type of answer"
              name="questionType"
              shouldUpdate
              rules={[{ required: true }]}
            >
              <Select
                options={questionTypeOptions}
                id="questionType"
                style={{ width: "100%" }}
              />
            </Form.Item>
            {renderStepFooter && (
              <Row justify="end" align="bottom" style={{ height: 24 }}>
                <Space>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => setCurrentPage(1)}
                      disabled={!canNext}
                    >
                      Add Answers
                    </Button>
                  </Form.Item>
                </Space>
              </Row>
            )}
            {renderSubmit && (
              <Row justify="end" style={{ height: 24 }}>
                <Form.Item shouldUpdate>
                  <Button htmlType="submit" type="primary" loading={createLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Row>
            )}
          </>
        ) : (
          <SelectFieldForm
            questionType={questionType}
            initialValues={initialValues.answers}
            setCurrentPage={setCurrentPage}
            createLoading={createLoading}
            hasSecondAnswer={hasSecondAnswer}
          />
        )}
      </Form>
      <MarkdownDrawer
        open={markdownEditorOpen}
        setOpen={setMarkdownEditorOpen}
        value={helpText}
        onChange={handleMarkdownValue}
        clearValue={clearMarkdownValue}
      />
    </>
  );
};

export default CreateQuestion;

const riskLevelOptions = [
  {
    label: "None",
    value: RiskLevel.None,
  },
  {
    label: "Very Low",
    value: RiskLevel.VeryLow,
  },
  {
    label: "Low",
    value: RiskLevel.Low,
  },
  {
    label: "Moderate",
    value: RiskLevel.Moderate,
  },
  {
    label: "High",
    value: RiskLevel.High,
  },
  {
    label: "Very High",
    value: RiskLevel.VeryHigh,
  },
];

const SelectFieldForm: React.FC<SelectFormProps> = (props) => {
  const { initialValues, questionType, createLoading, hasSecondAnswer, setCurrentPage } =
    props;
  return (
    <>
      <Form.List name="answers" initialValue={initialValues.answers}>
        {(fields, { add, remove }) => (
          <>
            <Row justify="space-between" align="bottom">
              <Col span={18}>
                <label style={{ fontWeight: "bold" }}>
                  {questionType === "MULTIPLE_CHOICE_PICK_ONE"
                    ? "Select: Single Answer > Answers"
                    : "Select: Multiple Answers > Answers"}
                </label>
              </Col>
              <Col span={6}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button onClick={() => add()} type="primary" icon={<PlusOutlined />}>
                    Add Answer
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            {fields.length < 2 && (
              <p style={{ fontWeight: "bold", color: "red" }}>
                2 answers required for this question type
              </p>
            )}
            {fields.map((field, index) => (
              <div key={`answer_${index}`}>
                <Form.Item>
                  <Form.Item
                    {...field}
                    label={`Answer #${index + 1}`}
                    name={[field.name, "answer"]}
                    rules={index <= 1 ? [{ required: true, message: "Required" }] : undefined}
                  >
                    <Input
                      placeholder="Answer text"
                      addonAfter={
                        fields.length > 2 && (
                          <DeleteOutlined
                            onClick={() => {
                              remove(field.name); // name working more consistent
                              console.log(fields);
                            }}
                          />
                        )
                      }
                    />
                  </Form.Item>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        label="Estimated completion time"
                        name={[field.name, "timeIncreaseInDays"]}
                      >
                        <InputNumber placeholder="In days" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        label="Estimated additional risk"
                        name={[field.name, "riskIncrease"]}
                        style={{ marginBottom: 0 }}
                      >
                        <Select options={riskLevelOptions} />
                      </Form.Item>
                    </Col>
                    <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                  </Row>
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>

      <Row justify="end" align="bottom" style={{ height: 24 }}>
        <Space>
          <Form.Item>
            <Button onClick={() => setCurrentPage(0)}>Previous</Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={createLoading}
              disabled={!hasSecondAnswer}
            >
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Row>
    </>
  );
};

const questionTypeOptions = QuestionTypesEnums.map((type) => ({
  label: mapTagToType(type.value),
  value: type.value,
}));
