import React, { createContext, useContext, useReducer } from "react";
import { PermissionNode } from "types";

const ImpersonationContext = createContext<any>(undefined);

export const useImpersonationContext = () => useContext(ImpersonationContext);

const impersonationStorage = sessionStorage.getItem("roleImpersonation");
const impersonationState: ImpersonationState = impersonationStorage
  ? JSON.parse(impersonationStorage)
  : {
      role: undefined,
      permissions: [],
    };

const ImpersonationProvider: React.FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(impersonationReducer, impersonationState);

  console.log({ Impersonation: impersonationState });

  return (
    <ImpersonationContext.Provider value={{ state, dispatch }}>
      {children}
    </ImpersonationContext.Provider>
  );
};

export default ImpersonationProvider;

interface ImpersonationState {
  role: string | undefined;
  permissions: Array<PermissionNode>;
}

type ImpersonationReducer =
  | { type: "SET_IMPERSONATION"; impersonation: ImpersonationState }
  | { type: "STOP_IMPERSONATION"; impersonation: ImpersonationState };

const impersonationReducer = (state: ImpersonationState, action: ImpersonationReducer) => {
  switch (action.type) {
    case "SET_IMPERSONATION":
      return action.impersonation;
    case "STOP_IMPERSONATION":
      return action.impersonation;
    default:
      return state;
  }
};
