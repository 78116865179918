import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, message, Button } from "antd";
import { useQuery } from "@apollo/client";
import { editorStateReducer, initialEditorState } from "./reducer";
import documents from "./index";
import DocumentSheet from "./Components/DocumentSheet";
import EditorDrawer from "./Components/EditorDrawer";
import PageNotFound from "../Common/PageNotFound";
import Api from "api";
import LoadingSpinner from "components/Common/LoadingSpinner";
import "styles/documents.css";
import { getFieldsMap } from "./fieldmap";

export const DocumentContext = React.createContext()

const DevTools = process.env.REACT_APP_DEV_TOOLS !== undefined;

function addNewFieldToRef(ref, object) {
  if (ref.current === undefined) {
    ref.current = { names: new Set(), objects: new Set() }
  }

  ref.current.names.add(object.field_name)
  if (ref.current.names.size > ref.current.objects.size) {
    ref.current.objects.add(object)
  }

  return ref
}

export function useDocument(object, fieldNames) {
  if (DevTools !== true) return
  const context = useContext(DocumentContext)
  if (context === undefined) return

  const ref = context.ref
  if (object === undefined) return ref

  const { field_name: fieldName } = object

  if (typeof fieldName === 'object' && fieldName?.length !== undefined) {
    fieldName.forEach(e => {
      addNewFieldToRef(ref, {
        ...object,
        field_name: e
      })
    })
    return ref
  }

  return addNewFieldToRef(ref, object)
}

const DocumentEditor = () => {
  const location = useLocation();
  const { documentId, id } = location.state;
  const ref = useRef({ names: new Set(), objects: new Set() })
  const [state, dispatch] = useReducer(editorStateReducer, initialEditorState);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { loading } = useQuery(Api.Document.GetOne(), {
    variables: { id },
    onCompleted: (res) => {
      if (!res?.document) return;
      const fieldsMap = getFieldsMap(res.document?.variableFields)
      console.log('GetOneDocument', { GetOneDocument: res, fieldsMap });

      dispatch({
        type: "INIT_DOCUMENT",
        state: {
          document: {
            ...res.document,
            variableFieldsMap: fieldsMap
          },

        },
      });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 5);
    },
  });

  useQuery(Api.Document.GetOneVariableFields(), {
    variables: {
      id,
    },
    onCompleted: (res) => {
      console.log({ GetOneVFs: res });
      if (!res?.document) return;
      dispatch({
        type: "SET_VARIABLE_FIELDS",
        variableFields: res.document.variableFields,
      });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 5);
    },
  });

  const doc = documents.find((d) => d.id === documentId);

  useEffect(() => {
    // open drawer when field selected, maybe better in reducer?
    if (!state) return;

    if (state.fieldId) {
      setDrawerOpen(true);
    }
  }, [state.fieldId]);

  console.log("%cEditor: State", "color:steelblue", state);

  if (!doc) {
    message.error("Document not found");
    return <PageNotFound />;
  }

  if (loading || !state.document) {
    return <LoadingSpinner />;
  }

  const header = doc.header;
  const formId = doc.id;
  const footer = doc.footer;

  const { headerStyle, bodyStyle, footerStyle } = doc


  return (
    <Row>
      <DocumentContext.Provider value={{ ref }}>
        <Col span={drawerOpen ? 16 : 22}>
          <div className="documentView">
            {doc.forms.map((form, i) => {
              const sheetProps = {
                editable: true,
                formId,
                state,
                dispatch,
                pageNumber: i + 1,
                totalPages: doc.forms.length || 1,
                header,
                form,
                footer,
                headerStyle: headerStyle || {},
                bodyStyle: bodyStyle || {},
                footerStyle: footerStyle || {},
              };
              return (
                <React.Fragment key={i} >
                  <DocumentSheet {...sheetProps} />
                  <br />
                </React.Fragment>
              );
            })}
          </div>
        </Col>
        <Col span={drawerOpen ? 8 : 2}>
          <div style={{ position: 'sticky', top: '8%' }}>
            <EditorDrawer
              state={state}
              dispatch={dispatch}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
            />
          </div>
        </Col>


      </DocumentContext.Provider>

    </Row>
  );
};

export default DocumentEditor;
