import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, Col, message, Popconfirm, Row, Segmented, Space, Tag } from "antd";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { GetFullQuestionaryQuery, Maybe, QuestionGroupNode, QuestionNode } from "types";
import { GroupFieldKeysEnum as FieldKeysEnum } from "../reducer";
import Api from "api";
import { SaveStatusEnum } from "../../../Shared/types";
import SmartTable from "components/Common/Tables";
import Sortable from "components/Administration/Workflow/Editor/Shared/SortableList/Sortable";
import { SkeletonWrapper } from "components/Common/Skeletons/SkeletonLoading";
import QuestionListHeader from "./QuestionListHeader";
import useDynamicDrawerWidth from "hooks/useDynamicDrawerWidth";
import { EditorMode } from "../../GroupEditor";
import { SegmentedValue } from "antd/lib/segmented";

type Props = {
  group: QuestionGroupNode;
  loading?: Loading;
  questions: QuestionNode[] | Maybe<QuestionNode>[];
  refetchGroup?: any;
  editorMode: string;
  setEditorMode: any;
  setSaveState: (arg0: FieldKeysEnum, arg1: SaveStatusEnum) => void;
  reset: (mode: EditorMode) => void;
};

export const QuestionListEditor: React.FC<Props> = (props) => {
  const { group, refetchGroup, questions, editorMode, setEditorMode, setSaveState, reset } =
    props;
  const width = useDynamicDrawerWidth();
  const {
    data,
    loading,
    refetch: refetchQuestions,
  } = useQuery<GetFullQuestionaryQuery>(Api.Question.GetFullQuestionary(), {
    onCompleted: (res) => {
      console.log({ allQuestionsList: res });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 5);
    },
  });

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [sortList, setSortList] = useState<QuestionNode[]>([]);
  const [manageAction, setManageAction] = useState<string | number>("add");
  console.log({ questions });

  useEffect(() => {
    if (questions.length < 1) return;
    setSortList(questions as QuestionNode[]);
  }, [questions]);

  const [updateQuestionList, { loading: questionUpdatesLoading }] = useMutation(
    Api.QuestionGroup.BulkUpdate(),
    {
      variables: {
        groupId: group.id,
        questionIds: [...selectedQuestions],
      },
      onCompleted: (res) => {
        console.log({ updateQuestionList: res });
        setSelectedQuestions([]);
        refetchQuestions();
        refetchGroup();
        setSaveState(FieldKeysEnum.questions, SaveStatusEnum.Success);
        message.success("Questions successfully added to group!", 5);
      },
      onError: (error) => {
        console.log(error.message);
        setSaveState(FieldKeysEnum.questions, SaveStatusEnum.Error);
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: Api.QuestionGroup.GetAll() }],
    },
  );
  const [updateGroupOrder, { loading: orderUpdateLoading }] = useMutation(
    Api.Question.UpdateMultiple(),
    {
      onCompleted: (res) => {
        console.log({ updatedGroupOrder: res });
        setSaveState(FieldKeysEnum.questionOrder, SaveStatusEnum.Success);
      },
      onError: (error) => {
        setSaveState(FieldKeysEnum.questionOrder, SaveStatusEnum.Error);
        console.log(error.message);
        message.error(error.message, 5);
      },

      refetchQueries: [{ query: Api.QuestionGroup.GetAll() }],
    },
  );

  const questionTableColumns: ColumnsType<any> = [
    {
      title: "Question",
      dataIndex: "question",
      width: "40%",
    },
    {
      title: "Group Status",
      dataIndex: "groupId",
      width: "17%",
      defaultSortOrder: "ascend",
      align: "left",
      sorter: {
        compare: (a, b) => {
          if (manageAction === "add") {
            if (!a.groupId) {
              return -1;
            }
            if (a.groupId !== group.id) {
              return -1;
            } else {
              return 1;
            }
          }
          // remove mode
          if (a.groupId === group.id) {
            return -1;
          } else {
            return 1;
          }
        },
      },
      render: (_text, record, _index) => {
        if (!record.groupId) {
          return (
            <Tag icon={<StopOutlined />} key={record.id}>
              Not in Group
            </Tag>
          );
        }

        if (record.groupId === group.id) {
          return (
            <Tag color="green" icon={<CheckCircleOutlined />} key={record.id}>
              In Current Group
            </Tag>
          );
        }

        return (
          <Tag color="blue" icon={<InfoCircleOutlined />} key={record.id}>
            In Different Group
          </Tag>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: {
        compare: (a, b) => a.category?.name.localeCompare(b.category?.name),
      },
      render: (_text, record, _index) => {
        return record.category?.name;
      },
    },
    // {
    //   title: "Module",
    //   dataIndex: "module",
    //   sorter: {
    //     compare: (a, b) => a.module.localeCompare(b.module),
    //   },
    //   render: (_text, record, _index) => {
    //     return record.category?.module?.name;
    //   },
    // },
  ];

  const disableRecord = (record: QuestionNode) => {
    /* block attempt to remove question thats not in the group
       block attempt to add questions to group when they're already in the group */
    if (editorMode === "default" || questions?.length < 1) return;

    let disabled = false;
    if (manageAction === "add") {
      if (record.groupId === group?.id) {
        disabled = true;
      } else {
        disabled = false;
      }
      return disabled;
    }
    if (record.groupId !== group?.id) {
      disabled = true;
    } else {
      disabled = false;
    }
    return disabled;
  };

  const rowSelection = {
    selectedRowKeys: selectedQuestions,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(selectedRowKeys);
      console.log(selectedRows);
      setSelectedQuestions(selectedRowKeys);
    },

    getCheckboxProps: (record: any) => ({
      name: record.name,
      disabled: disableRecord(record),
    }),
  };

  const saveNewQuestionOrder = () => {
    setSaveState(FieldKeysEnum.questionOrder, SaveStatusEnum.Loading);
    const questionList: any[] = [];
    sortList.forEach((ans: any, index: number) => {
      console.log(ans);
      questionList.push({ id: ans.id, order: index + 1 });
    });
    console.log(questionList);
    updateGroupOrder({
      variables: {
        items: [...questionList],
      },
    });
  };

  const handleManageAction = (value: SegmentedValue) => {
    setManageAction(value);
    setSelectedQuestions([]);
  };

  const addTableSelection = () => {
    console.log(selectedQuestions);
    console.log(group);
    setSaveState(FieldKeysEnum.questions, SaveStatusEnum.Loading);
    updateQuestionList({
      variables: {
        groupId: group.id,
        questionIds: [...selectedQuestions],
      },
    });
  };

  const removeTableSelection = () => {
    console.log({ selectedQuestions: selectedQuestions });
    setSaveState(FieldKeysEnum.questions, SaveStatusEnum.Loading);
    updateQuestionList({
      variables: {
        groupId: "CLEAR",
        questionIds: [...selectedQuestions],
      },
    });
  };

  const hasManageChanges = editorMode === "manage" && selectedQuestions.length >= 1;

  const TableHeader = () => {
    return (
      <Row justify="space-between" align="bottom">
        <Col span={8}>
          <Segmented
            options={manageActions}
            value={manageAction}
            onChange={handleManageAction}
          />
        </Col>
        <Col span={5}>
          <Space align="baseline">
            <label style={{ fontWeight: "bold" }}>Selected Questions:</label>
            <label style={{ fontWeight: "bold" }}>{selectedQuestions.length}</label>
          </Space>
        </Col>

        <Col span={8} style={{ textAlign: "right" }}>
          <Space>
            {manageAction === "add" ? (
              <Button
                type="primary"
                onClick={addTableSelection}
                loading={questionUpdatesLoading}
                disabled={!hasManageChanges || manageAction !== "add"}
              >
                Add to Group
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure you want to remove the selected questions from the group?"
                onConfirm={removeTableSelection}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger={true}
                  type="primary"
                  loading={questionUpdatesLoading}
                  disabled={!hasManageChanges || manageAction !== "remove"}
                >
                  Remove from Group
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <QuestionListHeader
        editorMode={editorMode}
        setEditorMode={setEditorMode}
        group={group}
        loading={loading}
        reset={reset}
      />

      <Row align="bottom">
        <Col span={24}>
          {editorMode === "manage" ? (
            <Card className="shadowed-static" bodyStyle={{ padding: 12 }}>
              <SmartTable
                loading={loading || questionUpdatesLoading}
                columns={questionTableColumns}
                data={data?.allQuestions!}
                title={() => <TableHeader />}
                pagination={{
                  position: ["bottomLeft"],
                  pageSizeOptions: [25, 50, 100, 200],
                  defaultPageSize: 25,
                }}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
              />
            </Card>
          ) : (
            <>
              <Col span={24}>
                <SkeletonWrapper loading={loading || orderUpdateLoading} type="sorterList">
                  <Sortable
                    parent={group}
                    parentType="group"
                    itemList={sortList}
                    setList={setSortList}
                  />
                </SkeletonWrapper>
              </Col>
              <div
                style={{
                  height: 55,
                  display: "flex",
                  justifyContent: "flex-end",
                  bottom: 0,
                  right: 0,
                  width: width,
                  background: "#FFFFFF",
                  padding: 12,
                  position: "fixed",
                  flexShrink: 0,
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                <div>
                  <Button
                    type="primary"
                    onClick={saveNewQuestionOrder}
                    loading={orderUpdateLoading}
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const manageActions = [
  {
    label: "Add",
    value: "add",
    icon: <PlusCircleTwoTone />,
  },
  {
    label: "Remove",
    value: "remove",
    icon: <MinusCircleTwoTone twoToneColor="#ff4d4f" />,
  },
];

export default QuestionListEditor;
