import {
  RmfActionPath,
  GroupType,
  ModuleType,
  QuestionType,
  TemplateGroup,
  UserRole,
  ConditionAnswerOption,
} from "types";

export const UserRolesEnums = [
  {
    label: "Applicant User",
    value: UserRole.ApplicationUser,
  },
  {
    label: "Global Administrator",
    value: UserRole.GlobalAdministrator,
  },
  {
    label: "CSAR Advisor",
    value: UserRole.CsarAdvisor,
  },
  {
    label: "Organization Administrator",
    value: UserRole.OrganizationAdministrator,
  },
  {
    label: "Workflow Administrator",
    value: UserRole.QuestionaryAdministrator,
  },
  {
    label: "Risk Manager",
    value: UserRole.RiskManager,
  },
  {
    label: "Organization Reader",
    value: UserRole.OrganizationReader,
  },
];

export const SalutationEnums = [
  {
    label: "",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr.",
  },
  {
    label: "Ms.",
    value: "Ms.",
  },
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Miss",
    value: "Miss",
  },
];

export const QuestionTypesEnums = [
  {
    label: "Fulfill Text",
    value: QuestionType.FulfillText,
  },
  {
    label: "Fulfill Date",
    value: QuestionType.FulfillDate,
  },
  {
    label: "Fulfill Date Range",
    value: QuestionType.FulfillDateRange,
  },
  {
    label: "Pick One",
    value: QuestionType.MultipleChoicePickOne,
  },
  {
    label: "Pick Many",
    value: QuestionType.MultipleChoicePickMany,
  },
  {
    label: "File Upload",
    value: QuestionType.UploadFile,
  },
];

export const AnswerConditionEnums = [
  { label: "Additional Field(s)", value: ConditionAnswerOption.AdditionalField },
  { label: "Additional Question(s)", value: ConditionAnswerOption.AdditionalQuestions },
  { label: "Document Filtering", value: ConditionAnswerOption.DocumentFilter, disabled: true },
  { label: "Module Filtering", value: ConditionAnswerOption.ModuleFilter, disabled: true },
  {
    label: "POAM Entry/Security Control Question",
    value: ConditionAnswerOption.PoamEntry,
    disabled: true,
  },
  {
    label: "Tiered Condition Logic",
    value: ConditionAnswerOption.TieredCondition,
    disabled: true,
  },
];

export const RMFActionPathEnums = [
  {
    label: "Fast Track ATO",
    value: RmfActionPath.FastTrackAto,
  },
  {
    label: "Assess and Incorporate",
    value: RmfActionPath.AssessAndIncorporate,
  },
  {
    label: "Software Certification",
    value: RmfActionPath.SoftwareCertification,
  },
  {
    label: "RMF Prepare",
    value: RmfActionPath.RmfPrepare,
  },
  {
    label: "Rapid Cyber Acquisition IATT",
    value: RmfActionPath.RcaIatt,
  },
];

export const ApplicationStatusEnums = [
  {
    label: "",
    value: "",
  },
  {
    label: "In Progress",
    value: "In Progress",
  },
  {
    label: "Completed",
    value: "Completed",
  },
  {
    label: "Authorized",
    value: "Authorized",
  },
];

export const TemplateGroupsEnums = [
  {
    label: "Control",
    value: TemplateGroup.Control,
  },
];

export const GroupTypesEnums = [
  {
    label: "Control",
    value: GroupType.Control,
  },
  {
    label: "Generic",
    value: GroupType.Generic,
  },
  {
    label: "Grid",
    value: GroupType.Grid,
  },
];

export const ModuleTypesEnums = [
  {
    label: "Question and Answer",
    value: ModuleType.QuestionAndAnswer,
  },
];

export const ApprovalStatusEnums = [
  { label: "Pending Approval", value: "PENDING_APPROVAL" },
  { label: "Needs Review", value: "NEEDS_REVIEW" },
  { label: "Approved", value: "APPROVED" },
];

export const QuestionAnswerRiskLevelEnums = [
  { label: "None", value: "NONE" },
  { label: "Very Low", value: "VERY_LOW" },
  { label: "Low", value: "LOW" },
  { label: "Moderate", value: "MODERATE" },
  { label: "High", value: "HIGH" },
  { label: "Very High", value: "VERY_HIGH" },
];
