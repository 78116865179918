import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P10 = (props) => {
  const { state } = props;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName).id;

  const PMarking = {
    label: "Select Marking",
    value: "privacy_marking",
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>12. Privacy</b>
            </div>
            <i>
              NOTE 14: For assistance with completing this section, please contact your Base
              Privacy Manager.
            </i>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={PMarking} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  12A. Will this System collect, maintain, use, and/or disseminate PII about
                  members of the public, Federal personnel, contractors, or foreign nationals
                  employed at U.S. military facilities internationally?
                </b>
                <div>Ref: (h)</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12a_yes"
                      // fieldId={document.variableFields[200]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12a_no"
                      // fieldId={document.variableFields[201]?.id}
                    />
                  </span>
                  <b>No</b> Proceed to Section 13
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  12B. Is the System an NSS (IAW Question 7G), or does the System contain
                  information reflected in Ref: (n)?
                </b>
                <div>Refs: (h) and (n)</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12b_yes"
                      // fieldId={document.variableFields[202]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> (Privacy Overlay Required)
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12b_no"
                      // fieldId={document.variableFields[203]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                12C. Does it contain PII other than rolodex information?
                </b>
                <div>Ref: (h)</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12c_yes"
                      // fieldId={document.variableFields[204]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> (Privacy Overlay Required)
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12c_no"
                      // fieldId={document.variableFields[205]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              padding: "0 5px",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div>
              <b>12D. What is the context of the CUI, if there is any?</b> (Examples: A list of
              deployed individuals [higher risk due to context], A list of safety meeting
              attendees [negligible risk due to context])
            </div>
            <div>Refs: (b) and (k)</div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderTop: "none",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "225px",
                fontSize: 14,
              }}
            >
              <EditableField
                borderless
                {...elementProps}
                fieldName="12d_text"
                // fieldId={document.variableFields[206]?.id}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "none",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <b style={{ flex: 1, fontSize: 14 }}>
              12E. What Type of PII will be contained within or pass through the system?
            </b>
            <div>[** Rolodex Information as defined in Ref: (h)]</div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.34,
                borderRight: "1px solid black",
                padding: "10px 0",
              }}
            >
              <b>Could Be LOW</b>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_business_organization"
                      // fieldId={document.variableFields[207]?.id}
                    />
                  </div>
                  <div>
                    <i>** Business Organization</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_business_phone_number_fax"
                      // fieldId={document.variableFields[208]?.id}
                    />
                  </div>
                  <div>
                    <i>** Business Phone Numbers (includes Fax)</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_business_street_address"
                      // fieldId={document.variableFields[209]?.id}
                    />
                  </div>
                  <div>
                    <i>** Business Street Address</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_business_work_email"
                      // fieldId={document.variableFields[210]?.id}
                    />
                  </div>
                  <div>
                    <i>** Business/Work E-mail Address</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_dod_id_number_edipi"
                      // fieldId={document.variableFields[211]?.id}
                    />
                  </div>
                  <div>
                    <i>DoD ID Number (EDIPI)</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_employment_information"
                      // fieldId={document.variableFields[212]?.id}
                    />
                  </div>
                  <div>
                    <i>Employment Information</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_home_cell_phone"
                      // fieldId={document.variableFields[213]?.id}
                    />
                  </div>
                  <div>
                    <i>Home/Cell Phone</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_mailing_home_address"
                      // fieldId={document.variableFields[214]?.id}
                    />
                  </div>
                  <div>
                    <i>Mailing/Home Address</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_name_full_partial"
                      // fieldId={document.variableFields[215]?.id}
                    />
                  </div>
                  <div>
                    <i>** Name (full or partial)</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_official_dutyaddress"
                      // fieldId={document.variableFields[216]?.id}
                    />
                  </div>
                  <div>
                    <i>Official DutyAddress</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_official_dutytelephone"
                      // fieldId={document.variableFields[217]?.id}
                    />
                  </div>
                  <div>
                    <i>** Official DutyTelephone</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_personal_email"
                      // fieldId={document.variableFields[218]?.id}
                    />
                  </div>
                  <div>
                    <i>Personal E-mail Address</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_photo"
                      // fieldId={document.variableFields[219]?.id}
                    />
                  </div>
                  <div>
                    <i>Photo</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_position_title"
                      // fieldId={document.variableFields[220]?.id}
                    />
                  </div>
                  <div>
                    <i>Position/Title</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_low_rank_grade"
                      // fieldId={document.variableFields[221]?.id}
                    />
                  </div>
                  <div>
                    <i>Rank/Grade</i>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.33,
                borderRight: "1px solid black",
                padding: "10px 0",
              }}
            >
              <b>Could Be MODERATE</b>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_birth_date"
                      // fieldId={document.variableFields[222]?.id}
                    />
                  </div>
                  <div>
                    <i>*Birth Date</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_child_information"
                      // fieldId={document.variableFields[223]?.id}
                    />
                  </div>
                  <div>
                    <i>Child Information</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_citizenship"
                      // fieldId={document.variableFields[224]?.id}
                    />
                  </div>
                  <div>
                    <i>Citizenship</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_drivers_license"
                      // fieldId={document.variableFields[225]?.id}
                    />
                  </div>
                  <div>
                    <i>Driver’s License</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_emergency_contact"
                      // fieldId={document.variableFields[226]?.id}
                    />
                  </div>
                  <div>
                    <i>Emergency Contact</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_financial_information"
                      // fieldId={document.variableFields[227]?.id}
                    />
                  </div>
                  <div>
                    <i>Financial Information</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_gender_identification"
                      // fieldId={document.variableFields[228]?.id}
                    />
                  </div>
                  <div>
                    <i>Gender/Gender Identification</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_legal_status"
                      // fieldId={document.variableFields[229]?.id}
                    />
                  </div>
                  <div>
                    <i>Legal Status</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_mothers_middle_maiden_name"
                      // fieldId={document.variableFields[230]?.id}
                    />
                  </div>
                  <div>
                    <i>Mother’s Middle/Maiden Name</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_other_id_number"
                      // fieldId={document.variableFields[231]?.id}
                    />
                  </div>
                  <div>
                    <i>Other ID Number</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_photo_with_ephemeris_data"
                      // fieldId={document.variableFields[232]?.id}
                    />
                  </div>
                  <div>
                    <i>Photo with ephemeris data</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_place_of_birth"
                      // fieldId={document.variableFields[233]?.id}
                    />
                  </div>
                  <div>
                    <i>Place of Birth</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_moderate_race_ethnicity"
                      // fieldId={document.variableFields[234]?.id}
                    />
                  </div>
                  <div>
                    <i>Race/Ethnicity</i>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.33,
                borderRight: "none",
                padding: "10px 0",
              }}
            >
              <b>Could Be HIGH</b>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_high_law_enforcement_information"
                      // fieldId={document.variableFields[235]?.id}
                    />
                  </div>
                  <div>
                    <i>Law Enforcement Information</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_high_legal_records"
                      // fieldId={document.variableFields[236]?.id}
                    />
                  </div>
                  <div>
                    <i>Legal Records</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_high_medical_information"
                      // fieldId={document.variableFields[237]?.id}
                    />
                  </div>
                  <div>
                    <i>Medical Information</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_high_passport_number"
                      // fieldId={document.variableFields[238]?.id}
                    />
                  </div>
                  <div>
                    <i>Passport Number</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_high_protected_health_information"
                      // fieldId={document.variableFields[239]?.id}
                    />
                  </div>
                  <div>
                    <i>Protected Health Information (PHI)</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_high_security_information"
                      // fieldId={document.variableFields[240]?.id}
                    />
                  </div>
                  <div>
                    <i>Security Information</i>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="12e_high_social_security_number"
                      // fieldId={document.variableFields[241]?.id}
                    />
                  </div>
                  <div>
                    <i>Social Security Number(SSN)</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
