import { Typography, Form, Input, Row, Select, Col, Space, InputNumber, Button } from "antd";
import {
  ContactsOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { OrganizationNode } from "types";

const { Title } = Typography;

type Props = {
  noRules?: boolean;
  loading: boolean;
  submit: (any) => any;
  allowDelete?: (e: any) => void;
  disableSubmit?: boolean;
  initialValues?: OrganizationNode;
  onChange?: any;
};

const OrganizationForm: React.FC<Props> = ({
  noRules,
  loading,
  submit,
  allowDelete,
  disableSubmit,
  initialValues,
  onChange,
}) => {
  const [form] = Form.useForm();
  const isManagingOrg = !!initialValues?.id;

  return (
    <Form
      form={form}
      onFinish={submit}
      initialValues={initialValues}
      onValuesChange={onChange}
      layout="vertical"
      autoComplete="off"
      style={{ width: "100%", height: "100%" }}
      role="form"
    >
      <Row style={{ marginBottom: 16 }}>
        <Space align="baseline">
          <InfoCircleOutlined style={{ fontSize: 20 }} />
          <Title level={4} style={{ marginBottom: 0 }}>
            General
          </Title>
        </Space>
      </Row>

      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            label="Name"
            name="name"
            rules={noRules ? [] : [{ required: true, message: "Please input a name" }]}
            style={{ textAlign: "left" }}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item label="DBA (Doing Business As)" name="doingBusinessAs">
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ marginBottom: 16, marginTop: 24 }}>
        <Space align="baseline">
          <ContactsOutlined style={{ fontSize: 20 }} />
          <Title level={4} style={{ marginBottom: 0 }}>
            Point of Contact
          </Title>
        </Space>
      </Row>

      <Row justify="space-between">
        <Col span={11}>
          <Form.Item label="Name" name="pocName">
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item label="Email" name="pocEmail">
            <Input type="email" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Form.Item label="Phone" name="pocPhoneNumber">
            <Input addonBefore="1" type="tel" />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ marginBottom: 16, marginTop: 24 }}>
        <Space align="baseline">
          <EnvironmentOutlined style={{ fontSize: 20 }} />
          <Title level={4} style={{ marginBottom: 0 }}>
            Location
          </Title>
        </Space>
      </Row>

      <Row justify="space-between">
        <Col span={11}>
          <Form.Item label="Street" name="addressStreet">
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item label="City" name="addressCity">
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item label="State" name="addressState" style={{ textAlign: "left" }}>
            <Select options={stateOptions} />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item label="Zip Code" name="addressZip" style={{ textAlign: "left" }}>
            <InputNumber controls={false} />
          </Form.Item>
        </Col>
      </Row>

      <div
        style={
          isManagingOrg
            ? { ...styles, bottom: 16, right: 24, position: "fixed" }
            : { ...styles }
        }
      >
        <Space>
          {allowDelete && (
            <Button type="primary" danger icon={<DeleteOutlined />} onClick={allowDelete}>
              Delete
            </Button>
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={isManagingOrg ? <SaveOutlined /> : undefined}
              disabled={disableSubmit}
            >
              {isManagingOrg ? "Save" : "Submit"}
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

const styles = {
  display: "flex",
  height: 40,
  justifyContent: "flex-end",
};

const stateOptions = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
  { label: "District of Columbia", value: "DC" },
  { label: "American Samoa", value: "AS" },
  { label: "Guam", value: "GU" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Puerto Rico", value: "PR" },
  { label: "U.S. Virgin Islands", value: "VI" },
];

export default OrganizationForm;
