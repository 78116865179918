export const globalColors = {
  green: "#21A000",
  yellow: "#FFDC00",
  red: "#C70000",
  orange: "#FF7000",
  blue: "#005EC7",
};

export const countBySeverity = (severity: Severity, source: any) => {
  return source.filter((alert: SecurityFinding) => alert.severity.toLowerCase() === severity);
};

export const countByAlertType = (type: string, source: any) => {
  return source.filter((alert: SecurityFinding) => alert.alertName === type);
};

export const countBySource = (type: string, source: any) => {
  return source.filter((alert: SecurityFinding) => alert.sourceTool.toLowerCase() === type);
};
