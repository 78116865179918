import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Col, Row } from "antd";

export const P8 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pia"
        style={{
          width: "100%",
          fontSize: 10,
        }}
      >
        <Row justify="space-between">
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>
                  <b style={{ fontSize: 10 }}>e. Component Records Officer</b>
                </center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_e_component_records_officer"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(1) Title</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_e_title"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(2) Organization</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_e_organization"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(3) Work Telephone</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_e_telephone"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(4) DSN</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_e_dsn"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(5) Email Address</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_e_email_address"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            paddingTop: 2,
            paddingBottom: 2,
            marginBottom: 2,
            borderBottom: "1px solid black",
          }}
        >
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(6) Date of Review</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_e_date_of_review"
                  style={{ height: 50, width: "100%" }}
                  isDate
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(7) Signature</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_e_signature"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>
                  <b style={{ fontSize: 10 }}>
                    f. Component Senior Information Security Officer/Designee Name
                  </b>
                </center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_f_component_senior_information_security_officer"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(1) Title</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_f_title"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(2) Organization</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_f_organization"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(3) Work Telephone</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_f_telephone"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(4) DSN</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_f_dsn"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(5) Email Address</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_f_email_address"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            paddingTop: 2,
            paddingBottom: 2,
            marginBottom: 2,
            borderBottom: "1px solid black",
          }}
        >
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(6) Date of Review</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_f_date_of_review"
                  style={{ height: 50, width: "100%" }}
                  isDate
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(7) Signature</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_f_signature"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>
                  <b style={{ fontSize: 10 }}>
                    g. Senior Component Official for Privacy (SCOP)/Designee Name
                  </b>
                </center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_g_senior_component_official_for_privacy_scop"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(1) Title</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_g_title"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(2) Organization</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_g_organization"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(3) Work Telephone</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_g_telephone"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(4) DSN</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_g_dsn"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(5) Email Address</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_g_email_address"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            paddingTop: 2,
            paddingBottom: 2,
            marginBottom: 2,
            borderBottom: "1px solid black",
          }}
        >
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(6) Date of Review</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_g_date_of_review"
                  style={{ height: 50, width: "100%" }}
                  isDate
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(7) Signature</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_g_signature"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>
                  <b style={{ fontSize: 10 }}>h. Component CIO Reviewing Official Name</b>
                </center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_h_other_official"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(1) Title</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_h_title"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(2) Organization</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_h_organization"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(3) Work Telephone</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_h_telephone"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(4) DSN</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_h_dsn"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(5) Email Address</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_h_email_address"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: 2 }}>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(6) Date of Review</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_h_date_of_review"
                  style={{ height: 50, width: "100%" }}
                  isDate
                />
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                <center>(7) Signature</center>
              </Col>
              <Col span={16}>
                <EditableField
                  {...elementProps}
                  fieldName="8_h_signature"
                  style={{ height: 50, width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ display: "flex", paddingTop: 5 }}>
          <b style={{ paddingRight: 10 }}>Publishing:</b>
          <div>
            Only Section 1 of ths PIA will be published. Each DoD Component will maintain a
            central repository of PIAs on the Components public Web site. DoD Components will
            submit an electronic copy of each approved PIA to the DoD CIO at: 0sd.me-alex
            dod-cio.mbx.pia@mail.mil
            <div style={{ paddingTop: 5 }}>
              If the PIA document contains information that would reveal sensitive information
              of raise security concerns, the DoD Component may restrict the publication of the
              assessment to include Section 1.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
