import { Tag, Tooltip } from "antd";
import {
  GroupOutlined,
  MessageOutlined,
  PartitionOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { QuestionType } from "types";

type TagProps = {
  tooltip?: string;
};

type ParentTagProps = {
  type: "category" | "group";
};

export const CategoryTag: React.FC<TagProps> = ({ tooltip }) => {
  const hasTooltip = tooltip && tooltip.length > 0 ? true : false;
  const tag = <Tag icon={<PartitionOutlined />}>In Category</Tag>;
  if (hasTooltip) {
    return <Tooltip color="#000000">{tag}</Tooltip>;
  }

  return tag;
};

export const GroupTag: React.FC<TagProps> = ({ tooltip }) => {
  const hasTooltip = tooltip && tooltip.length > 0 ? true : false;
  const tag = <Tag icon={<GroupOutlined />}>In Group</Tag>;

  if (hasTooltip) {
    return <Tooltip color="#000000">{tag}</Tooltip>;
  }

  return tag;
};

export const ParentTag: React.FC<ParentTagProps> = ({ type }) => {
  if (type === "category") {
    return <CategoryTag />;
  }
  return <GroupTag />;
};

// export const mapTagToType = (dataType: QuestionType, id?: string): JSX.Element | string => {
//   let ans;
//   const tagKey = id ? id : dataType;
//   switch (dataType) {
//     case QuestionType.UploadFile:
//       ans = <UploadFileTag key={tagKey} />;
//       break;
//     case QuestionType.FulfillText:
//       ans = <FreeTextTag key={tagKey} />;
//       break;
//     case QuestionType.MultipleChoicePickOne:
//       ans = <MultiChoiceTag children="Multi-Choice: Pick 1" id={tagKey} key={tagKey} />;
//       break;
//     case QuestionType.MultipleChoicePickMany:
//       ans = <MultiChoiceTag children="Multi Choice: Pick Many" id={tagKey} key={tagKey} />;
//       break;
//     default:
//       return dataType;
//   }

//   return ans;
// };
