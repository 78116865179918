import LinkedQuestionsRenderer from "./LinkedQuestionsRenderer";
import FileUploadQuestion from "./Questions/FileUploadQuestion";
import FulfillQuestion from "./Questions/FulfillQuestion";
import MultipleChoiceQuestion from "./Questions/MultipleChoiceQuestion";

import FileUploadInput from "./Inputs/FileUploadInput";
import FulfillTextInput from "./Inputs/FulfillTextInput";
import FulfillDateInput from "./Inputs/FulfillDateInput";
import FulfillDateRangeInput from "./Inputs/FulfillDateRangeInput";
import PickOneInput from "./Inputs/PickOneInput";
import PickManyInput from "./Inputs/PickManyInput";

import { ApplicationQuestionNode, QuestionType } from "types";
import "styles/questionnaire.css";

export interface ListRendererProps {
  question: ApplicationQuestionNode;
}

const ListQuestionRenderer: React.FC<ListRendererProps> = ({ question }) => {
  switch (question.questionType) {
    case QuestionType.UploadFile:
      return (
        <FileUploadQuestion
          question={question}
          input={FileUploadInput}
          defaultAnswer={question.savedAnswers[0]?.answer!}
        />
      );
    case QuestionType.FulfillText:
      return (
        <FulfillQuestion
          question={question}
          input={FulfillTextInput}
          defaultAnswer={question.savedAnswers[0]?.answer!}
        />
      );
    case QuestionType.FulfillDate:
      return (
        <FulfillQuestion
          question={question}
          input={FulfillDateInput}
          defaultAnswer={question.savedAnswers[0]?.answer!}
        />
      );
    case QuestionType.FulfillDateRange:
      return (
        <FulfillQuestion
          question={question}
          input={FulfillDateRangeInput}
          defaultAnswer={question.savedAnswers[0]?.answer!}
        />
      );
    case QuestionType.MultipleChoicePickOne:
      return (
        <div>
          <MultipleChoiceQuestion
            question={question}
            input={PickOneInput}
            defaultAnswer={question.savedAnswers[0]?.id!}
          />

          <LinkedQuestionsRenderer question={question} />
        </div>
      );
    case QuestionType.MultipleChoicePickMany:
      return (
        <div>
          <MultipleChoiceQuestion
            question={question}
            input={PickManyInput}
            defaultAnswer={question.savedAnswers.map((saved: any) => saved!.id)!}
          />
          <LinkedQuestionsRenderer question={question} />
        </div>
      );
    default:
      return <>Invalid Question Type</>;
  }
};

export default ListQuestionRenderer;
