export const P14 = (props) => {
  return (
    <>
      <div className="itcsc" style={{
        columnCount: 2,
        padding: '40px 6%',
        height: '100%'
      }}>
        <div className="instructions" style={{ width: '100%', textAlign: 'left', height: '100%' }}>
          <div style={{ textAlign: 'center', padding: '20px', fontSize: 18 }}>
            <h1>INSTRUCTIONS</h1>
          </div>
          <div>
            <p>
              <b>
                Aggregation of all data, plus the potential impact and
                likelihood of a security issue arising from mishandling
                or misuse of that data, should factor in the assessment
                of all decisions within the ITCSC.
                <br />
                <br />
                All acronyms must be spelled out once in the
                description. Failure to spell out acronyms once may
                result in the form being returned.
              </b>
            </p>
            <p><b>General: CUI Designation Indicator Box</b></p>
            <p>
              If the ITCSC Form includes any CUI information or<br />
              material, complete the CUI Designation Indicator Box on<br />
              the bottom of Page 1:
            </p>
            <p>First Line: Identify the Office making the determination</p>

            <p>Second line: Identify all types of CUI contained in the document.</p>
            <p>
              Third line: Identify the distribution statement or the<br />
              dissemination controls applicable to the document.
            </p>
            <p>
              Fourth line: Identify phone number or office mailbox for<br />
              the originating DoD Component or authorized CUI holder.
            </p>

            <p><b>General: CUI Warning Box for Classified Material</b></p>

            <p>
              If the ITCSC Form includes classified information, <br />
              complete the CUI Warning Box to alert to the presence of <br />
              CUI in a classified DoD document.
            </p>
            <p><b>General: Overall Document Marking Classification</b></p>
            <p>
              Select marking classification (header and footer). Hit <br />
              enter key. If document is classified, complete<br />
              the Classification Authority Block on Page 1.
            </p>

            <p><b>General: Section Marking</b></p>
            <p>Select security classification for each Section. Hit enter key.</p>

            <p><b>1. System Identification Information</b></p>

            <p>
              System Name: The name of the System must match <br />
              the Investment Name being entered during ITIPS <br />
              registration.
            </p>

            <p>
              System Acronym: The same acronym entered in ITIPS as<br />
              the investment acronym.
            </p>
            <p>Version: Version number of the System.</p>
            <p>System vs. Subsystem: Select the appropriate button</p>
            <p>
              First Categorization Submission: Select “Yes” if <br />
              first submission. Otherwise, select “No.”
            </p>
            <p>
              ITIPS ID: Enter ITIPS ID number. An ITIPS ID is<br />
              required for all DAF systems and will be provided after an<br />
              approved ITIPS registration.
            </p>
            <p>
              eMASS ID: Enter eMASS registration number. An<br />
              eMASS ID is required for all DAF systems. This<br />
              information must be provided after eMASS registration.
            </p>
            <p>
              eMASS Classification: Select the eMASS classification <br />
              for archiving/storing System authorization artifacts
            </p>
          </div>
        </div>

        <div className="instructions" style={{ width: '100%', textAlign: 'left', height: '100%' }}>
          <p>
            DITPR ID: Enter the DITPR ID number. A DITPR ID<br />
            is provided after an approved ITIPS registration (as<br />
            applicable).
          </p>
          <p>
            AFID: Unique identifier given to SAP programs to<br />
            account for them but anonymize the data, so the program<br />
            name is not known.</p>
          <p><b>2. AO Boundary</b></p>
          <p>
            Listings of AOs and Boundary descriptions are posted on<br />
            the DAF RMF Knowledge Service at <br />
            https://rmfks.osd.mil/rmf/Collaboration/Component%20W<br />
            orkspaces/AirForce/Lists/Air%20Force%20Authorization<br />
            %20Boundaries/AllItems.aspx
          </p>
          <p><b>3.Security Classification</b></p>
          <p><b>3A. Highest Data Classification Processed</b></p>

          <p>Identify the highest data classification that the System<br />
            will process.</p>
          <p><b>3B. Dissemination Control</b></p>
          <p>Identify the dissemination control for the information<br />
            that the System will process.</p>
          <p><b>3C. Does the system process SAP data?</b></p>
          <p><b>3E. Any Foreign National Users?</b></p>
          <p><b>4.NSS Determination</b></p>
          <p>
            Carefully read and answer Questions 3A- 3H, checking<br />
            the "Yes" or "No" box on each one. If the answer to any of<br />
            the questions is “Yes,” then the System is an NSS as to be<br />
            noted in Question 3I, contingent upon SAF/CN<br />
            concurrence.
          </p>

          <p><b>5.System or Subsystem Types</b></p>
          <p>Select one box from the listed System/Subsystem Types.</p>
          <p><b>6.System Operational Status</b></p>
          <ul>
            <li>Operational - The System has satisfiedprogram requirements and achieved IOC or <br />FOC.</li>
            <li>
              Under Development - The System is <br />
              in thedesign/development phase and has not entered<br />
              final production/operation.
            </li>
            <li>
              Major Modification - The System is<br />
              undergoing a necessary modification to<br />
              improve performance and reduce ownership<br />
              costs.
            </li>
          </ul>
          <p><b>7.System Description/Purpose</b></p>
          <p>
            Prepare a general technical description of the function<br />
            and purpose of the System. All acronyms must be spelled<br />
            out once in the description. Failure to spell out acronyms<br />
            once may result in the form being returned. This<br />
            description should tell the story of who, what, where,<br />
            when, why, and how the System supports the warfighter<br />
            and/or other systems by answering each of the following:<br />
          </p>
          <ul>
            <li>
              Describe the purpose of the System and <br />
              indicate if the System is mission essential to<br />
              the warfighter. Example: The purpose of the
            </li>
          </ul>
        </div>


      </div>
    </>
  );
};
