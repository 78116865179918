import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Divider, Input, Row, Space, Tag, Typography } from "antd";
import type { InputRef } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useCategoryList } from "api/hooks/useCategoryList";
import { useModuleList } from "api/hooks/useModuleList";
import { mapTagToType } from "components/Common/Tags/QuestionType";
import SmartTable from "components/Common/Tables/index";
import { useQuestionList } from "api/hooks/useQuestionList";
import { QuestionType } from "types";
import { ColumnType } from "antd/lib/table";

const { Paragraph } = Typography;

const QuestionSelectionTable: React.FC<any> = ({
  onChange,
  selectedQuestion,
  setAnswerChoices,
}) => {
  const { modules, loading: modulesLoading } = useModuleList();
  const { categories, loading: categoriesLoading } = useCategoryList();
  // const { groups, loading: groupsLoading } = useGroupList();
  const { questions, loading: questionsLoading } = useQuestionList();
  // const searchInput = useRef<InputRef>(null);
  // const [searchText, setSearchText] = useState("");
  const hasSelectedQuestion = selectedQuestion && selectedQuestion.id;

  const loading = questionsLoading || modulesLoading || categoriesLoading;

  const moduleFilters = modules?.map((module) => ({
    text: module.name,
    value: module.id,
  }));

  const categoryFilters = categories?.map((category) => ({
    text: category.name,
    value: category.id,
  }));

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      width: "70%",
      filterSearch: true,
      onFilter: (value: string, record) => record.name.startsWith(value),
      // ...getColumnSearchProps("question"),
    },
    {
      title: "Module",
      dataIndex: "module",
      filters: moduleFilters,
      onFilter: (value: string, record) => {
        return record?.category && record.category?.module?.id.indexOf(value) === 0;
      },
      render: (text, record) => {
        return record?.category && record.category?.module && record.category?.module?.name;
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      filters: categoryFilters,
      onFilter: (value: string, record) => {
        if (record?.categoryId) return record.categoryId.indexOf(value) === 0;
      },
      render: (text, record) => {
        return record?.category && record.category?.name;
      },
    },
  ];

  const onQuestionSelect = (selectedKeys: any) => {
    console.log("handleTableSelection", { selectedKeys, selectedQuestion });

    const fullQuestion = questions?.filter((question) => question.id === selectedKeys[0])[0];

    const { answers } = fullQuestion;

    console.log({ fullQuestion, answers });

    setAnswerChoices(answers);
    onChange(fullQuestion);
  };

  const rowSelection = {
    onChange: onQuestionSelect,
    selectedRowKeys: hasSelectedQuestion ? [selectedQuestion.id] : [],
    preserveSelectedRowKeys: true,
  };

  const dataSource = questions?.filter(
    (question) =>
      (question.questionType === QuestionType.MultipleChoicePickOne ||
        question.questionType === QuestionType.MultipleChoicePickMany) &&
      question?.gridCol === null,
  );

  const onTableChange = (change) => {
    console.log("%conTableChange: ", "color:green", { change });
    // setDataSource();
  };

  useEffect(() => {
    console.log("%cQUestionSeelctTable", "color:purple", { questions, dataSource });
  }, [questions, dataSource]);

  return (
    <Row>
      <Col span={24}>
        <SmartTable
          data={dataSource}
          loading={loading}
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          columns={columns}
          rowKey={(record) => record.id}
          onChange={onTableChange}
          pagination={{
            position: ["bottomLeft"],
            defaultPageSize: 25,
            pageSizeOptions: [25, 50, 100, 200],
            responsive: true,
          }}
          expandable={{
            expandedRowRender: (question) => (
              <Card type="inner">
                <Row>
                  <Col span={24}>
                    <Space direction="vertical" size="large">
                      <Space>
                        <strong style={{ paddingRight: 8 }}>Answers:</strong>
                        {mapTagToType(question.questionType)}
                      </Space>
                      <Space>
                        {question.answers.map((answer) => (
                          <Tag key={answer.id}>{answer.answer}</Tag>
                        ))}
                      </Space>
                    </Space>
                  </Col>
                  <Divider style={{ margin: "12px 0px" }} />
                  <Col span={24}>
                    <Space direction="vertical">
                      <strong>Help Text:</strong>
                      <Paragraph style={{ marginBottom: 0 }}>
                        <blockquote style={{ marginTop: 7, marginBottom: 7 }}>
                          {question?.helpText ? question.helpText : "Not Set"}
                        </blockquote>
                      </Paragraph>
                    </Space>
                  </Col>
                </Row>
              </Card>
            ),
          }}
          scroll={{
            x: 400,
          }}
          size="small"
        />
      </Col>
    </Row>
  );
};

// * uncompleted search by question
//  const getColumnSearchProps = (dataIndex: string): ColumnType<any> => ({
//    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
//      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
//        <Input
//          ref={searchInput}
//          placeholder={`Search ${dataIndex}`}
//          value={selectedKeys[0]}
//          onChange={(e) => setSearchText(e.target.value ? e.target.value : "")}
//          onBlur={(e) => setSearchText(e.target.value ? e.target.value : "")}
//          // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
//          // onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
//          style={{ marginBottom: 8, display: "block" }}
//        />
//        <Space>
//          <Button
//            type="primary"
//            onClick={() =>
//              dataSource.filter(
//                (question) =>
//                  question.question.includes(searchText) ||
//                  question.question.startsWith(searchText),
//              )
//            }
//            // onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
//            icon={<SearchOutlined />}
//            size="small"
//            style={{ width: 90 }}
//          >
//            Search
//          </Button>
//          <Button
//            // onClick={() => clearFilters && handleReset(clearFilters)}
//            onClick={() => {
//              setSearchText("");
//              setDataSource(questions);
//            }}
//            size="small"
//            style={{ width: 90 }}
//          >
//            Reset
//          </Button>
//          {/* <Button
//             type="link"
//             size="small"
//             onClick={() => {
//               confirm({ closeDropdown: false });
//               // setSearchText((selectedKeys as string[])[0]);
//               // setSearchedColumn(dataIndex);
//             }}
//           >
//             Filter
//           </Button> */}
//          <Button
//            type="link"
//            size="small"
//            onClick={() => {
//              close();
//            }}
//          >
//            close
//          </Button>
//        </Space>
//      </div>
//    ),
//    filterIcon: (filtered: boolean) => (
//      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
//    ),
//    onFilter: (value, record) =>
//      record[dataIndex]
//        .toString()
//        .toLowerCase()
//        .includes((value as string).toLowerCase()),
//    onFilterDropdownOpenChange: (visible) => {
//      if (visible) {
//        setTimeout(() => searchInput.current?.select(), 100);
//      }
//    },
//    render: (text) =>
//      searchText.length > 0 ? (
//        <Highlighter
//          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
//          searchWords={[searchText]}
//          autoEscape
//          textToHighlight={text ? text.toString() : ""}
//        />
//      ) : (
//        text
//      ),
//  });

export default QuestionSelectionTable;
