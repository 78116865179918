import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Button, Empty, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { expandComponents } from "./NestedQuestionTable";
import { Link } from "react-router-dom";
import { QuestionNode, QuestionType } from "types";
import SmartTable from "components/Common/Tables";
import "styles/draggableTable.css";
import { mapTagToType } from "components/Common/Tags/QuestionType";
/**
 *
 * * Note: Expanded row & draggable components cannot be rendered at the same time
 * @returns: Draggable table to re-order questions within a category collapse panel
 */

type Props = {
  questions?: QuestionNode[];
};

const CategoryQuestionsTable: React.FC<Props> = ({ questions }) => {
  const data =
    questions && questions.length > 0
      ? questions?.slice().sort((a: QuestionNode, b: QuestionNode) => a.order! - b.order!)
      : [];

  if (questions && questions.length > 0) {
    return (
      <SmartTable
        loading={false}
        data={data}
        columns={tableColumns}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => expandComponents.expandedRowRender(record),
          expandIcon: ({ expanded, onExpand, record }) =>
            expandComponents.expandIcon({
              expanded,
              onExpand,
              record,
            }),
        }}
      />
    );
  }

  return <Empty description="No Questions assigned to this Category..." />;
};

const tableColumns: ColumnsType<QuestionNode> = [
  {
    title: "Order",
    dataIndex: "order",
    key: "order",
    width: "8%",
    align: "center",
    render: (_text, record, index) => {
      // return record.order;
      if (record.groupId) {
        // is group, use groupOrder
        return record?.groupOrder;
      } else {
        return record?.order;
      }
    },
  },
  {
    title: "Question",
    dataIndex: "question",
    key: "question",
    className: "drag-visible",
    width: "50%",
  },
  {
    title: "Data Type",
    dataIndex: "questionType",
    key: "questionType",
    render: (text: QuestionType, record) => {
      return mapTagToType(text, record.id);
    },
  },
  {
    title: "Group Header",
    dataIndex: "groupId",
    render: (_text, record, _index) => {
      if (!record?.groupId) {
        return <Tag key={record.id}>Not in Group</Tag>;
      }
      if (record.group?.header) {
        return record.group.header;
      }
      return <Tag key={record.id}>In Group</Tag>;
    },
  },
  {
    title: "Manage",
    width: "5%",
    align: "right",
    render: (_text, record, _index) => {
      return (
        <Link
          to={`/administration/workflow-editor/question-editor/${record.id}`}
          state={{
            selectedQuestion: record.id,
          }}
          key={record.id}
        >
          <Button icon={<SettingOutlined />} />
        </Link>
      );
    },
  },
];

export default CategoryQuestionsTable;
