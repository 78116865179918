import _ from "lodash";
import { ApplicationQuestionNode } from "types";

const hasAnswerChange = (
  question: ApplicationQuestionNode,
  answer: { id: string; text: string },
) => {
  const savedAnswer: any = question.savedAnswers
    .map((sa: any) => ({ id: sa.id, text: sa.answer }))
    .find((sa) => sa.id === answer.id);

  const invalidChange = !savedAnswer && !answer.text;
  const changed = !_.isEqual(savedAnswer, answer);

  if (!invalidChange && changed) {
    console.log("%cAnswer Changed", "color: goldenrod", {
      Old: savedAnswer?.text,
      New: answer.text,
    });
    return true;
  } else {
    console.log("%cNo Change", "color: steelblue");
    return false;
  }
};

export default hasAnswerChange;
