import { Card, Col, Row, Tag, Tooltip } from "antd";
import React from "react";
import ProgressMetric from "../Metrics/ProgressMetric";

const DashboardCard: React.FC<{
  title: string;
  actions: React.ReactNode[];
  progress?: number;
  children: React.ReactNode;
}> = (props) => {
  const { title, actions, progress, children } = props;

  return (
    <Card
      className="shadowed-w-hover"
      title={title}
      actions={actions}
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ padding: "20px 20px 10px" }}>{children}</div>
      <DashboardCardProgress progress={progress!} />
    </Card>
  );
};

export default DashboardCard;

export const DashboardCardStatus: React.FC<{
  type: string;
  isLocked?: boolean;
  isComplete?: boolean;
  inProgress?: boolean;
}> = ({ type, isLocked, isComplete, inProgress }) => {
  const status = isLocked ? (
    <Tag color="red" style={{ marginRight: 0 }}>
      Locked
    </Tag>
  ) : isComplete ? (
    <Tag color="green" style={{ marginRight: 0 }}>
      Complete
    </Tag>
  ) : inProgress ? (
    <Tag color="processing" style={{ marginRight: 0 }}>
      In Progress
    </Tag>
  ) : (
    <Tag color="default" style={{ marginRight: 0 }}>
      Not Started
    </Tag>
  );

  return (
    <Row justify="space-between" style={{ marginBottom: 5 }}>
      <Col>Status</Col>
      <Tooltip title={`${type} Status`}>
        <Col style={{ cursor: "default" }}>{status}</Col>
      </Tooltip>
    </Row>
  );
};

export const DashboardCardCheckpointStatus: React.FC<{
  isLocked?: boolean;
  isComplete?: boolean;
  isActive?: boolean;
}> = ({ isLocked, isComplete, isActive }) => {
  const status = isLocked ? (
    <Tag color="red" style={{ marginRight: 0 }}>
      Locked
    </Tag>
  ) : isComplete ? (
    <Tag color="green" style={{ marginRight: 0 }}>
      Complete
    </Tag>
  ) : isActive ? (
    <Tag color="processing" style={{ marginRight: 0 }}>
      Active
    </Tag>
  ) : (
    <Tag color="default" style={{ marginRight: 0 }}>
      N/A
    </Tag>
  );

  return (
    <Row justify="space-between" style={{ marginBottom: 5 }}>
      <Col>Checkpoint</Col>
      <Tooltip title="Checkpoint Status">
        <Col style={{ cursor: "default" }}>{status}</Col>
      </Tooltip>
    </Row>
  );
};

export const DashboardCardProgress: React.FC<{ progress: number }> = ({ progress }) => (
  <div
    style={{
      overflow: "hidden",
      width: "100%",
      height: "10px",
    }}
  >
    {progress !== undefined && (
      <Tooltip title={`Progress ${progress}%`} mouseEnterDelay={0.4}>
        <ProgressMetric
          percent={progress!}
          showInfo={false}
          strokeLinecap="square"
          trailColor="#e2e2e2"
        />
      </Tooltip>
    )}
  </div>
);
