import { ResetButton } from "..";
import EditableField from "../../../Components/EditableField";

export const P11 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };


  return (
    <>
      <div className="itcsc page">
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'25%'} />
              <col span={1} width={'25%'} />
              <col span={1} width={'25%'} />
              <col span={1} width={'25%'} />
            </colgroup>

            <thead>
              <tr>
                <th className="title" colSpan={4}>
                  16.RMF Key Roles <i>Ref: (d)</i>
                </th>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <th>Title</th>
                <th style={{ border: 'none' }}>Name</th>
                <th style={{ border: 'none' }}>Phone (Comm/DSN)</th>
                <th style={{ border: 'none' }}>Organization</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}><b>AO *</b></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_name_ao`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_phone_ao`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_org_ao`} /></td>
              </tr>
              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}><b>AO Designated<br /> Representative (AODR)</b></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_name_aodr`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_phone_aodr`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_org_aodr`} /></td>
              </tr>

              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}><b>Information System<br /> Owner (ISO) *</b></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_name_iso`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_phone_iso`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_org_iso`} /></td>
              </tr>

              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}><b>Program Manager (PM)</b></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_name_pm`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_phone_pm`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_org_pm`} /></td>
              </tr>

              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}><b>Security Control<br /> Assessor (SCA) *</b></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_name_sca`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_phone_sca`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_org_sca`} /></td>
              </tr>

              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}><b>Information System<br /> Security Manager (ISSM) *</b></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_name_issm`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_phone_issm`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_org_issm`} /></td>
              </tr>

              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}><b>Information System<br /> Security Officer (ISSO)</b></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_name_isso`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_phone_isso`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_org_isso`} /></td>
              </tr>

              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}><b>Information System<br /> Security Engineer (ISSE)</b></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_name_isse`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_phone_isse`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_org_isse`} /></td>
              </tr>

              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}><b>User Representative</b></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_name_ur`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_phone_ur`} /></td>
                <td style={{ height: '35px', border: 'none' }}><EditableField {...elementProps} fieldName={`16_rmf_key_roles_org_ur`} /></td>
              </tr>
            </tbody>
          </table>

          <table border={1}>
            <colgroup>
              <col span={1} width={'50%'} />
              <col span={1} width={'50%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="title"></th>
                <th className="title" style={{ textAlign: 'start', paddingLeft: 50 }}>Approval</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}>
                  <b>ISO/PM:</b> (Digital Signature)
                </td>
                <td><EditableField {...elementProps} fieldName={'16_rmf_key_roles_approval_isopm'} /></td>
              </tr>
            </tbody>
          </table>

          <table border={1}>
            <colgroup>
              <col span={1} width={'50%'} />
              <col span={1} width={'50%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="title"></th>
                <th className="title" style={{ height: '20px' }}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ height: '35px', padding: '0px 4px', textAlign: 'start', verticalAlign: 'baseline' }}>
                  <b>AO/AODR:</b> (Digital Signature)
                </td>
                <td><EditableField {...elementProps} fieldName={'16_rmf_key_roles_approval_aododr'} /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="reset-button">
          <p>*denotes minimum staffing</p>
          <ResetButton>Reset Section 16</ResetButton>
        </div>
      </div>
    </>
  );
};
