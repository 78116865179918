import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import P1AFPCIL from "../Assets/P1_AFPCIL.png";

export const P1 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <>
      <div
        className="doc_pcil"
        style={{
          width: "100%",
          display: "grid",
          placeItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ display: "grid", placeItems: "center" }}>
          <img src={P1AFPCIL} alt="af_pcil" style={{ maxWidth: "100%" }} />
        </div>
        <div style={{ width: "80%", textAlign: "center" }}>
          <label>Air Force System Name</label>
          <EditableField {...elementProps} fieldName="1_air_force_system_name" />
          <br />
          <label>Air Force System Acronym</label>
          <EditableField {...elementProps} fieldName="1_air_force_system_acronym" />
          <br />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <label>System Version</label>
            <div style={{ width: "40%" }}>
              <EditableField {...elementProps} fieldName="1_air_force_system_version" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
