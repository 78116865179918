type MaybeNumber = number | undefined | null;
type MaybeString = string | undefined | null;
type MaybeDate = Date | null;

export const compareNumbers = (a: MaybeNumber, b: MaybeNumber) => {
  const num1 = a || 0;
  const num2 = b || 0;

  return num1 - num2;
};

export const compareStrings = (a: MaybeString, b: MaybeString) => {
  const str1 = a || "";
  const str2 = b || "";

  return str1.localeCompare(str2);
};

export const compareDates = (a: MaybeDate, b: MaybeDate) => {
  const date1 = a || "";
  const date2 = b || "";

  return new Date(date2).getTime() - new Date(date1).getTime();
};
