import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Col, Row } from "antd";

export const P6 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pia"
        style={{
          width: "100%",
          fontSize: 10,
        }}
      >
        <Row justify="center">
          <div>
            <b>
              <u>SECTION 3: RELATED COMPLIANCE INFORMATION</u>
            </b>
          </div>
        </Row>
        <Row style={{ display: "flex" }}>
          <b>
            a. Is this DoD Information System registered in the DoD IT Portfolio Repository (DITPR) or the DoD Secret Internet Protocol Router Network
            (SIPRNET) Information Technology (IT) Registry or Risk Management Framework (RMF) tool<span style={{ verticalAlign: 'super', fontSize: 8 }}>3</span>?
          </b>
        </Row>

        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col style={{ display: "flex" }}>
            <EditableCheckbox {...elementProps} fieldName="6_a_yes_ditpr" />
            <span style={{ marginLeft: 10 }}>Yes, DITPR</span>
          </Col>
          <Col span={14} style={{ display: "flex" }}>
            <span style={{ marginRight: 10, whiteSpace: "nowrap" }}>
              DITPR System Identification Number
            </span>
            <EditableField {...elementProps} fieldName="6_a_ditpr_iden_num_text" />
          </Col>
        </Row>
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col style={{ display: "flex" }}>
            <EditableCheckbox {...elementProps} fieldName="6_a_yes_siprnet" />
            <span style={{ marginLeft: 10 }}>Yes, SIPRNET</span>
          </Col>
          <Col span={14} style={{ display: "flex" }}>
            <span style={{ marginRight: 10, whiteSpace: "nowrap" }}>
              SIPRNET Identification Number
            </span>
            <EditableField {...elementProps} fieldName="6_a_siprnet_iden_num_text" />
          </Col>
        </Row>
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col style={{ display: "flex" }}>
            <EditableCheckbox {...elementProps} fieldName="6_a_yes_rmftool" />
            <span style={{ marginLeft: 10 }}>Yes, RMF tool</span>
          </Col>
          <Col span={14} style={{ display: "flex" }}>
            <span style={{ marginRight: 10, whiteSpace: "nowrap" }}>
              RMF tool Identification Number
            </span>
            <EditableField {...elementProps} fieldName="6_a_rmftool_iden_num_text" />
          </Col>
        </Row>
        <Row>
          <EditableCheckbox {...elementProps} fieldName="6_a_no" />
          <div style={{ paddingLeft: 10 }}>No</div><br />
        </Row>
        <Row>
          <div style={{ paddingLeft: 24 }}>If "No", Explain</div>
          <EditableField {...elementProps} fieldName="6_a_no_text" style={{ height: 50 }} />
        </Row>
        <br />

        <Row style={{ display: "flex" }}>
          <b>
            b. DoD information systems require assessment and authorization under the DoD Instruction 8510.01, “Risk Management Framework for DoD
            Information Technology”.
          </b>
          <div>Indicate the assessment and authorization status:</div>
        </Row>
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col style={{ display: "flex" }}>
            <EditableRadio {...elementProps} fieldName="6_b_authorization_to_operate" />
            <span style={{ marginLeft: 10 }}>Authorization to Operate (ATO)</span>
          </Col>
          <Col span={10} style={{ display: "flex" }}>
            <span style={{ marginRight: 10, whiteSpace: "nowrap" }}>Date Granted </span>
            <EditableField
              {...elementProps}
              isDate
              fieldName="6_b_authorization_to_operate_date_granted"
            />
          </Col>
        </Row>
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col style={{ display: "flex" }}>
            <EditableRadio {...elementProps} fieldName="6_b_ato_with_conditions" />
            <span style={{ marginLeft: 10 }}>ATO with Conditions Date Granted</span>
          </Col>
          <Col span={10} style={{ display: "flex" }}>
            <span style={{ marginRight: 10, whiteSpace: "nowrap" }}>Date Granted</span>
            <EditableField
              {...elementProps}
              isDate
              fieldName="6_b_ato_with_conditions_date_granted"
            />
          </Col>
        </Row>
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col style={{ display: "flex" }}>
            <EditableRadio {...elementProps} fieldName="6_b_denial_of_ato" />
            <span style={{ marginLeft: 10 }}>Denial of Authorization to Operate (DATO)</span>
          </Col>
          <Col span={10} style={{ display: "flex" }}>
            <span style={{ marginRight: 10, whiteSpace: "nowrap" }}>Date Granted</span>
            <EditableField {...elementProps}
              isDate
              fieldName="6_b_denial_of_ato_date_granted" />
          </Col>
        </Row>
        <Row justify="space-between" align="stretch" style={{ paddingBottom: 2 }}>
          <Col style={{ display: "flex" }}>
            <EditableRadio
              {...elementProps}
              fieldName="6_b_interim_authorization_to_test"
            />
            <span style={{ marginLeft: 10 }}>Interim Authorization to Test (ATT)</span>
          </Col>
          <Col span={10} style={{ display: "flex" }}>
            <span style={{ marginRight: 10, whiteSpace: "nowrap" }}>Date Granted</span>
            <EditableField
              {...elementProps}
              isDate
              fieldName="6_b_interim_authorization_to_test_date_granted"
            />
          </Col>
        </Row>
        <Row>
          <div style={{ paddingLeft: 20 }}>
            (1) If an assessment and authorization is pending, indicate the type and projected
            date of completion.
          </div>
          <EditableField
            {...elementProps}
            fieldName="6_b_1_pending_text"
            style={{ height: 50 }}
          />
        </Row>
        <br />
        <Row>
          <div style={{ paddingLeft: 20 }}>
            (2) If an assessment and authorization is not using RMF, indicate the projected
            transition date.
          </div>
          <EditableField
            {...elementProps}
            fieldName="6_b_2_rmftool_text"
            style={{ height: 50 }}
          />
        </Row>
        <Row style={{ padding: "5px 0" }}>
          <b style={{ marginRight: 20 }}>
            c. Does this DoD information system have an IT investment Unique Investment Identifier (UII), required by Office of Management and Budget (OMB)
            Circular A-11?
          </b>
        </Row>
        <Row style={{ paddingLeft: 40 }}>
          <div style={{ marginRight: 10 }}>
            <EditableRadio {...elementProps} fieldName="6_c_dod_ui_omb_yes" />
            <span style={{ marginLeft: 10 }}>Yes</span>
          </div>
          <div style={{ marginRight: 10 }}>
            <EditableRadio {...elementProps} fieldName="6_c_dod_ui_omb_no" />
            <span style={{ marginLeft: 10 }}>No</span>
          </div>
        </Row>
        <br />
        <Row style={{ paddingLeft: 40 }}>
          If "Yes" Enter UII
          <EditableField
            {...elementProps}
            fieldName="6_c_dod_ui_omb_yes_text"
            style={{ width: "150px", margin: "0 20px" }}
          />
          If unsure, consult the component IT Budget Point of Contact to obtain the UII
        </Row>
      </div>
    </>
  );
};
