import React from "react";
import { InfoCircleTwoTone, UploadOutlined } from "@ant-design/icons";
import { Form, Button, Row, Space, Upload } from "antd";
import InfoTip from "components/Common/InfoTip";
import SaveStatusTag from "../../Dashboards/Category/Questionnaire/Components/SaveStatusTag";

const SetupNessus: React.FC<any> = ({ status, setSaveStatus, file, setFile }) => {
  const onChange = (file: any) => {
    console.log("%cGetting Presigned Url", "color: steelblue");
    setFile({ name: file.name });
    setSaveStatus("");
  };

  const uploadProps = {
    multiple: false,
    maxCount: 1,
    fileList: file ? [file] : [],
    customRequest: (data: any) => {
      onChange(data.file);
    },
    itemRender: (_: any, file: any) => (
      <Row justify="start">
        <Space>{file.name}</Space>
      </Row>
    ),
  };

  return (
    <>
      <Space align="baseline" style={{ marginBottom: 12 }}>
        <InfoCircleTwoTone />
        <p>Please upload your Nessus export file</p>
      </Space>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Space>
          <span>Upload Export File</span>
          <InfoTip title="Nessus Export file available within Nessus Dashboard" />
        </Space>
        <SaveStatusTag status={status} />
      </Row>
      <Form.Item name="export" required>
        <Upload {...uploadProps}>
          <Row justify="start">
            <Space>
              <Button icon={<UploadOutlined />}>Choose a File</Button>
            </Space>
          </Row>
          <br />
        </Upload>
      </Form.Item>
    </>
  );
};

export default SetupNessus;
