import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

import DOAFSeal from "../Assets/DOAFSeal.png";
import P10D1 from "../Assets/P10D1.png";

export const P4 = (props) => {
  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <>
      <div
        style={{
          fontSize: 48,
          textAlign: "center",
          fontWeight: "bold",
          width: "100%",
          margin: 0,
          color: "navy",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img style={{ width: "85px", margin: "10px" }} src={DOAFSeal} alt="" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Authorization Boundary</span>
        </div>
      </div>

      <div
        style={{
          borderTop: "5px solid navy",
          borderBottom: "5px solid navy",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            padding: "0 40px",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ maxWidth: "100%" }} src={P10D1} alt="" />
        </div>
      </div>
      <p style={{ width: "100%", textAlign: "center", fontSize: 24, paddingTop: 10 }}>
        <i style={{ padding: "0 5px" }}>I n t e g r i t y</i> -{" "}
        <i style={{ padding: "0 5px" }}>S e r v i c e</i> -{" "}
        <i style={{ padding: "0 5px" }}>E x c e l l e n c e</i>
      </p>
    </>
  );
};
