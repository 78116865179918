import { useEffect, useState } from "react";
import useQuestion from "components/Applicant/Hooks/useQuestion";
import GridQuestion from "../GridQuestion";
import { ApplicationQuestionNode } from "types";

export interface MultipleChoiceInputProps {
  defaultAnswer: string | any;
  question: ApplicationQuestionNode;
  onChange: (answer: { id: string }) => void;
  onUnsave: (questionId: string) => void;
  disabled: boolean;
}

interface Props {
  input: React.FC<MultipleChoiceInputProps>;
  defaultAnswer: string | any;
  question: ApplicationQuestionNode;
}

const MultipleChoiceQuestion: React.FC<Props> = ({ input, question, defaultAnswer }) => {
  const [saveStatus, setSaveStatus] = useState<string>("");

  const { saveMultipleChoiceAnswer, unSaveAnswer } = useQuestion(question, setSaveStatus);

  const Input = input;
  const hasDefaultAnswers = question.isSavedWithDefaults!;
  const hasSavedAnswers = question.isAnswered || question.savedAnswers.length > 0;

  const isLocked = question.isLocked! || hasDefaultAnswers;

  useEffect(() => {
    setSaveStatus(hasDefaultAnswers ? "default" : hasSavedAnswers ? "saved" : "");
  }, [question]);

  const onUnsave = (answerId: string) => {
    unSaveAnswer(question.id, answerId);
  };

  const onChange = (answer: { id: string }) => {
    if (isLocked) return;

    saveMultipleChoiceAnswer(question.id, answer);
  };

  return (
    <GridQuestion question={question} saveStatus={saveStatus}>
      <Input
        question={question}
        defaultAnswer={defaultAnswer}
        onChange={onChange}
        onUnsave={onUnsave}
        disabled={isLocked}
      />
    </GridQuestion>
  );
};

export default MultipleChoiceQuestion;
