import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

export const P3 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div
        className="doc_pcilv2"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >

        <div style={{ width: "90%" }}>
          <ul className="leaders">
            <li className="bold">
              <span>
                <b style={{ marginRight: 30 }}>1.</b> <b>Overview</b>
              </span>
              <span>4</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>1.1</b> <span>Purpose</span>
              </span>
              <span>4</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>1.2</b> <span>Scope</span>
              </span>
              <span>4</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>1.3</b>
                <span>Instructions</span>
              </span>
              <span>4</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>1.4</b>
                <span>Four Key Questions</span>
              </span>
              <span>4</span>
            </li>

            <li className="bold">
              <span>
                <b style={{ marginRight: 30 }}>2.</b>
                <b>Analyze PII Data and Determine PCIL</b>
              </span>
              <span>5</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.1</b>
                <span>Does the information system contain, process, or transact PII?</span>
              </span>
              <span>5</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.2</b>
                <span>
                  Use the space below to identify the types of PII and data elements contained in, processed by, or
                  <br />
                  <span style={{ marginLeft: 58 }}>transacted through the information system:</span>
                </span>
              </span>
              <span>5</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.3</b>
                <span>Estimate the number of records containing PII</span>
              </span>
              <span>5</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.4</b>
                <span>Define the user community</span>
              </span>
              <span>5</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.5</b>
                <span>Determine the PII confidentiality impact level (PCIL)</span>
              </span>
              <span>6</span>
            </li>

            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.5.1</b>
                <span>
                  STEP 1. REVIEW THE FIPS 199 IMPACT VALUE FOR EACH OF THE SIX
                  <br /><span style={{ marginLeft: 110 }}>FACTORS</span>.
                </span>
              </span>
              <span>7</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.5.2</b>
                <span>
                  STEP 2. USING THE GUIDANCE PROVIDED BELOW, DETERMINE THE IMPACT
                  <br /><span style={{ marginLeft: 110 }}>VALUE FOR EACH OF THE SIX (6) FACTORS FROM NIST SP 800-122</span>.
                </span>
              </span>
              <span>7</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.5.3</b>
                <span>STEP 3. DETERMINE PII CONFIDENTIALITY IMPACT LEVEL (PCIL) VALUE</span>
              </span>
              <span>11</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.5.4</b>
                <span>STEP 4. SELECT PII CONFIDENTIALITY IMPACT LEVEL (PCIL) VALUE:</span>
              </span>
              <span>12</span>
            </li>

            <li>
              <span>
                <b style={{ fontWeight: "normal", marginRight: 30 }}>2.6</b>
                <span>
                  Is your organization a covered entity or business associate under HIPAA?
                </span>
              </span>
              <span>12</span>
            </li>
            <li>
              <span>
                <b style={{ fontWeight: "normal", margin: 20 }}>2.6.1</b>
                <span>Select Organization HIPAA Status</span>
              </span>
              <span>12</span>
            </li>

            <li className="bold">
              <span>
                <b>Appendix A — References</b>
              </span>
              <span>13</span>
            </li>
            <li className="bold">
              <span>
                <b>Appendix B — Signatures</b>
              </span>
              <span>14</span>
            </li>
            <li className="bold">
              <span>
                <b>Appendix C — Definitions</b>
              </span>
              <span>15</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
