import { useState } from "react";
import { Col, Row } from "antd";
import { useMutation } from "@apollo/client";
import { MapGroupTag } from "components/Common/Tags/QuestionGroup";
import { Maybe, QuestionGroupNode, QuestionNode, UpdateQuestionGroupMutation } from "types";
import {
  GroupEditorReducerEnum as ReducerEnum,
  GroupEditorState,
  GroupFieldKeysEnum as FieldKeysEnum,
} from "./Components/reducer";
import Api from "api";
import QuestionList from "./Components/QuestionManagement/QuestionList";
import QuestionListEditor from "./Components/QuestionManagement/QuestionListEditor";
import ParentInformation from "./Components/ParentInformation";
import SidebarSection from "../Shared/SidebarSection";
import { SaveStatusEnum } from "../Shared/types";
import GroupInformation from "./Components/GroupInformation";
import QuestionGrid from "./Components/QuestionManagement/QuestionGrid";

type Props = {
  state: GroupEditorState;
  dispatch: any;
  group: QuestionGroupNode;
  refetchGroup: any;
  loading: Loading;
};

export type EditorMode = "default" | "manage" | "order";

const GroupEditor: React.FC<Props> = ({ state, dispatch, group, refetchGroup, loading }) => {
  console.log({ editorGroup: group, groupEditorState: state });
  const [editorMode, setEditorMode] = useState<EditorMode>("default");

  const [updateGroup] = useMutation<UpdateQuestionGroupMutation>(Api.QuestionGroup.Update());

  const setEditState = (fieldKey: FieldKeysEnum) => {
    console.log({ fieldKey });
    dispatch({
      type: ReducerEnum.ToggleEdit,
      params: { fieldKey },
    });
  };

  const resetQuestionEditor = (editorMode: EditorMode) => {
    if (editorMode === "manage") {
      dispatch({
        type: ReducerEnum.ToggleEdit,
        params: { fieldKey: FieldKeysEnum.questions },
      });
    } else {
      dispatch({
        type: ReducerEnum.ToggleEdit,
        params: { fieldKey: FieldKeysEnum.questionOrder },
      });
    }
    setEditorMode("default");
  };

  const setSaveState = (fieldKey: FieldKeysEnum, status: SaveStatusEnum) => {
    let saveAction;

    switch (status) {
      case SaveStatusEnum.Default:
        saveAction = ReducerEnum.SetDefault;
        break;
      case SaveStatusEnum.Loading:
        saveAction = ReducerEnum.SetLoading;
        break;
      case SaveStatusEnum.Success:
        saveAction = ReducerEnum.SetSuccess;
        break;
      case SaveStatusEnum.Error:
        saveAction = ReducerEnum.SetError;
        break;
      default:
        saveAction = ReducerEnum.SetDefault;
    }

    dispatch({
      type: saveAction,
      params: {
        fieldKey,
      },
    });

    // error message shown longer
    if (saveAction === ReducerEnum.SetError) {
      return setTimeout(() => {
        dispatch({
          type: ReducerEnum.SetDefault,
          params: {
            fieldKey,
          },
        });
      }, 5000);
    }
    if (saveAction === ReducerEnum.SetSuccess) {
      return setTimeout(() => {
        dispatch({
          type: ReducerEnum.SetDefault,
          params: {
            fieldKey,
          },
        });
      }, 2500);
    }
  };

  const questions: Maybe<QuestionNode> | Maybe<QuestionNode>[] = group?.questions;

  const editingQuestions = editorMode === "manage" || editorMode === "order";

  if (editingQuestions) {
    return (
      <Row
        style={{
          height: "calc(100vh - 65px) !important",
          maxHeight: "calc(100vh - 65px)",
          width: "100%",
          overflow: "auto",
          padding: "16px 16px 72px 16px",
        }}
      >
        <Col span={24}>
          <QuestionListEditor
            group={group}
            loading={loading}
            refetchGroup={refetchGroup}
            editorMode={editorMode}
            setEditorMode={setEditorMode}
            setSaveState={setSaveState}
            questions={questions}
            reset={resetQuestionEditor}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row justify="space-between" style={{ padding: 16 }}>
        <Col span={17}>
          <Row justify="start">
            <Col
              span={24}
              style={{
                textAlign: "left",
                paddingLeft: 0,
              }}
            >
              <GroupInformation
                loading={loading}
                state={state}
                group={group}
                updateGroup={updateGroup}
                refetchGroup={refetchGroup}
                setSaveState={setSaveState}
                setEditState={setEditState}
              />
              {group?.groupType === "GRID" ? (
                <QuestionGrid
                  group={group}
                  loading={loading}
                  refetchGroup={refetchGroup}
                  editorMode={editorMode}
                  setEditorMode={setEditorMode}
                  setEditState={setEditState}
                />
              ) : (
                <QuestionList
                  group={group}
                  loading={loading}
                  refetchGroup={refetchGroup}
                  editorMode={editorMode}
                  setEditorMode={setEditorMode}
                  setEditState={setEditState}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row align="top" justify="start">
            <Col span={24}>
              <SidebarSection
                title="Group Type"
                loading={loading}
                tooltip="Control groups include a predefined set of questions designed to collect data about security controls for an application. The questions that are included in a control question group are not editable. Generic groups are dynamic question groups that can include any question."
              >
                {MapGroupTag(group?.groupType, group?.id)}
              </SidebarSection>
              <ParentInformation
                loading={loading}
                state={state}
                group={group}
                setEditState={setEditState}
                setSaveState={setSaveState}
                refetchGroup={refetchGroup}
                updateGroup={updateGroup}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default GroupEditor;
