import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import Api from "api";
import { Modal, message, Form, Row, Button, Divider } from "antd";
import { useParams } from "react-router-dom";

type Props = {
  state: IntegrationState;
  onClose: () => void;
};

const RunModal: React.FC<Props> = ({ state, onClose }) => {
  const { applicationId } = useParams();
  const [form] = Form.useForm();

  const [file, setFile] = useState<any>(undefined);
  const [saveStatus, setSaveStatus] = useState("");

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  console.log({ stateFromRunModal: state });

  const [uploadFile] = useMutation(Api.Integration.UploadFile(), {
    onCompleted: (res) => {
      console.log("%cUploading to S3", "color: steelblue");
      const url = res.uploadIntegrationFile?.postOutput.url;
      if (!url) return;
      UploadToS3Bucket(res.uploadIntegrationFile);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
      setFile(undefined);
    },
    fetchPolicy: "no-cache",
  });

  const UploadToS3Bucket = async ({
    fileId,
    postOutput: { url, fields },
  }: {
    fileId: string;
    postOutput: {
      url: string;
      fields: any;
    };
  }) => {
    const formData: any = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append([key], fields[key]);
    });
    formData.append("file", file);

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        updateStatusWithSuccess(fileId);
      })
      .catch(() => {
        updateStatusWithFailure(fileId);
      });
  };

  const [updateStatus] = useMutation(Api.Integration.UpdateFileStatus(), {
    onCompleted: (res) => {
      console.log("%cUpdated Question", "color: steelblue", res);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
    },
    fetchPolicy: "no-cache",
  });

  const updateStatusWithSuccess = (fileId: string) => {
    console.log("%cS3 Upload Successful", "color: green");
    message.success("Sucessfully Uploaded File");
    setSaveStatus("saved");
    updateStatus({
      variables: {
        fileId,
        status: 200,
      },
    });
  };

  const updateStatusWithFailure = (fileId: string) => {
    console.log("%cS3 Upload Failed", "color: red");
    message.success("Failed to Upload File");
    setSaveStatus("failed");
    updateStatus({
      variables: {
        fileId,
        status: 400,
      },
    });
  };

  const handleUploadFile = (values: any) => {
    console.log({ FileToSave: values.export.file });

    setSaveStatus("saving");
    uploadFile({
      variables: {
        applicationId,
        filename: file.name,
        tool: state.integration?.id,
      },
    });
  };

  const DynamicRunForm = state.integration?.run;

  return (
    <Modal
      title={`Run ${state.integration?.name}`}
      visible={state.open}
      onCancel={onClose}
      footer={null}
    >
      <Form onFinish={handleUploadFile} form={form} layout="vertical">
        <DynamicRunForm
          status={saveStatus}
          setSaveStatus={setSaveStatus}
          file={file}
          setFile={setFile}
          onClose={onClose}
          fileId={state.integration?.fileId}
        />

        {/* {state.integration?.name !== "Sonarcloud" && (
          <>
            <Divider />
            <Row justify="end">
              <Form.Item style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit" disabled={!file}>
                  Submit
                </Button>
              </Form.Item>
            </Row>
          </>
        )} */}
      </Form>
    </Modal>
  );
};

export default RunModal;
