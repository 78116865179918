import { CloseCircleOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Input, Skeleton, Space, Typography } from "antd";
import SaveStatusIndicator from "components/Common/Tags/UpdateSaveStatusTag";
import { CategoryFieldKeysEnum } from "../Category Editor/Components/reducer";
import { GroupFieldKeysEnum } from "../Group Editor/Components/reducer";
import { FieldKeysEnum } from "../Question Editor/Components/reducer";
import { SaveStatusEnum } from "./types";

const { Title, Text } = Typography;

// todo: find way to not need a separate card for mdEditor?

/**
 * When `editableField` is true, editingCurrentField, setEditState, formValues, onValuesChange, onSave, saveStatus, renderSaveStatus are REQUIRED
 */

type FieldKey = GroupFieldKeysEnum | CategoryFieldKeysEnum | FieldKeysEnum;

type ContentCardProps = {
  title: string;
  fieldKey: FieldKey;
  editableField: boolean;
  editingCurrentField?: boolean;
  setEditState?: any;
  formValues?: any;
  onValuesChange?: (value: string, fieldKey: string) => void;
  onSave?: any;
  saveStatus?: SaveStatusEnum;
  renderSaveStatus?: boolean;
  extra?: any;
  loading: Loading;
  children?: any;
  isMdEditor?: boolean;
  styles?: any;
  bodyStyles?: any;
};

type EditableFieldProps = {
  editingCurrentField: boolean;
  editableField: boolean;
  onValuesChange: (arg0: string, arg1: string) => void;
  formValues: any;
  fieldKey: GroupFieldKeysEnum | CategoryFieldKeysEnum | FieldKeysEnum;
  children?: any;
};

const CardContentSkeletonWrapper = ({
  loading,
  children,
}: {
  loading: Loading;
  children: any;
}) => {
  return loading ? <Skeleton active={true} paragraph={{ rows: 1, width: "85%" }} /> : children;
};

const ContentCard: React.FC<ContentCardProps> = (props) => {
  const {
    title,
    extra,
    loading,
    fieldKey,
    onValuesChange,
    formValues,
    onSave,
    children,
    setEditState,
    editableField,
    editingCurrentField,
    saveStatus,
    renderSaveStatus,
    styles,
    bodyStyles,
  } = props;

  const ContentCardActions: React.FC = () => {
    const handleSave = () => {
      console.log({ formValues });
      onSave();
      setEditState(fieldKey);
    };

    if (!editableField) return <></>;

    if (!editingCurrentField) {
      return (
        <Button
          onClick={() => setEditState(fieldKey)}
          icon={<EditOutlined />}
          disabled={loading}
        />
      );
    }

    return (
      <Space>
        <Button onClick={handleSave} icon={<SaveOutlined />} type="primary">
          Save
        </Button>
        <Button icon={<CloseCircleOutlined />} onClick={() => setEditState(fieldKey)}>
          Cancel
        </Button>
      </Space>
    );
  };

  return (
    <Card
      title={
        <Space align="center">
          <Title level={5} style={{ marginBottom: -3 }}>
            {title}
          </Title>
          {renderSaveStatus && <SaveStatusIndicator status={saveStatus!} />}
        </Space>
      }
      style={{ marginBottom: 16, ...styles }}
      bodyStyle={{
        paddingTop: 12,
        paddingBottom: 12,
        minHeight: 50,
        overflowWrap: "break-word",
        ...bodyStyles,
      }}
      extra={extra ? extra : <ContentCardActions />}
      className="shadowed-static"
    >
      <CardContentSkeletonWrapper loading={loading} key={fieldKey}>
        <EditableContentField
          fieldKey={fieldKey!}
          editingCurrentField={editingCurrentField!}
          editableField={editableField}
          formValues={formValues}
          onValuesChange={onValuesChange!}
        >
          {children}
        </EditableContentField>
      </CardContentSkeletonWrapper>
    </Card>
  );
};

const EditableContentField: React.FC<EditableFieldProps> = ({
  children,
  editingCurrentField,
  editableField,
  fieldKey,
  formValues,
  onValuesChange,
}) => {
  if (!editableField) {
    return (
      <Text style={{ fontSize: 14 }} aria-label="Question Text Field">
        {children}
      </Text>
    );
  }
  if (editingCurrentField) {
    return (
      <Input.TextArea
        defaultValue={children}
        onChange={(e) => onValuesChange(e.target.value, fieldKey)}
        value={formValues[fieldKey]}
        autoSize={{
          minRows: 2,
        }}
        key={fieldKey}
      />
    );
  }

  return children;
};

export default ContentCard;
