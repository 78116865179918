import { InfoCircleTwoTone } from "@ant-design/icons";
import { Form, Input, Space } from "antd";
import InfoTip from "components/Common/InfoTip";

const SetupSonarcloud: React.FC = () => {
  return (
    <>
      <Space align="baseline" style={{ marginBottom: 12 }}>
        <InfoCircleTwoTone />
        <p>Enter your Sonarcloud.io Web API authentication information below</p>
      </Space>
      <Form.Item
        required
        name="token"
        label={
          <>
            <span style={{ marginRight: 5 }}>Security Token</span>
            <InfoTip title="Token available within SonarCloud API console" />
          </>
        }
      >
        <Input.TextArea autoSize={true} required={true} />
      </Form.Item>
      <Form.Item
        required
        name="organization"
        label={
          <>
            <span style={{ marginRight: 5 }}>Organization Key</span>
            <InfoTip title="Your organization key" />
          </>
        }
      >
        <Input.TextArea autoSize={true} required={true} />
      </Form.Item>
      <Form.Item
        required
        name="project"
        style={{ marginBottom: 36 }}
        label={
          <>
            <span style={{ marginRight: 5 }}>Project Key</span>
            <InfoTip title="Your project key" />
          </>
        }
      >
        <Input.TextArea autoSize={true} required={true} />
      </Form.Item>
    </>
  );
};

export default SetupSonarcloud;
