import { useQuery } from "@apollo/client";
import Api from "api";
import SmartTable from "components/Common/Tables/index";
import { Typography, Row, Divider, message } from "antd";
import type { ColumnsType } from "antd/es/table";
const { Title } = Typography;

const ApplicationsTab: React.FC<any> = () => {
  const { data, loading } = useQuery(Api.Application.GetMy(), {
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const applications = data?.myApplications;
  console.log({ UsersApplications: applications });

  const columns: ColumnsType<ApplicationDataType> = [
    {
      title: "Application Name",
      dataIndex: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "right",
    },
  ];

  return (
    <>
      <Row>
        <Title level={3}>My Applications</Title>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <SmartTable loading={loading} data={applications} columns={columns} />
    </>
  );
};

export default ApplicationsTab;
