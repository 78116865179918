import { CheckCircleOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { RMFActionPathEnums } from "api/enums";
import { RmfActionPath } from "types";

const { CheckableTag } = Tag;

type RmfPathsProps = {
  selectedPaths: Array<RmfActionPath>;
  toggleActionPath: any;
};

const RmfPathsSelector: React.FC<RmfPathsProps> = (props) => {
  const { selectedPaths, toggleActionPath } = props;

  return (
    <>
      <Space direction="vertical">
        {RMFActionPathEnums?.map((path: any) => {
          const hasThisPath: boolean = selectedPaths?.some((p: any) => p.path === path.value);

          return (
            <CheckableTag
              key={path.value}
              checked={hasThisPath}
              onChange={(checked) => toggleActionPath(path, checked)}
              style={{
                paddingLeft: 0,
                MozUserSelect: "none",
                KhtmlUserSelect: "none",
                WebkitUserSelect: "none",
              }}
            >
              <span>
                {hasThisPath && (
                  <span>
                    <CheckCircleOutlined style={{ paddingRight: 6, paddingLeft: 6 }} />
                  </span>
                )}
                <span>{path.label}</span>
              </span>
            </CheckableTag>
          );
        })}
      </Space>
    </>
  );
};

export default RmfPathsSelector;
