import { useMutation } from "@apollo/client";
import { useState, useReducer } from "react";
import { Button, Drawer, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { GroupType, QuestionGroupNode, QuestionType } from "types";
import { groupEditorReducer, GroupEditorState } from "./Components/reducer";
import Api from "api";
import InputConfirmModal from "components/Common/InputConfirmModal";
import GroupEditor from "./GroupEditor";
import { SaveStatusEnum } from "../Shared/types";
import { useQuestionGroup } from "api/hooks/useQuestionGroup";
import useDynamicDrawerWidth from "hooks/useDynamicDrawerWidth";

const initialGroupState: QuestionGroupNode = {
  id: "345",
  header: "groupHeader",
  categoryId: "5123",
  questions: [
    {
      id: "423",
      question: "sdf",
      questionType: QuestionType.FulfillText,
      variableName: "var",
      answers: [],
      defaultAnswers: [],
      rmfActionPaths: [],
      linkedQuestions: [],
    },
  ],
  groupType: GroupType.Generic,
  order: 0,
  description: "",
};

const initialState: GroupEditorState = {
  groupType: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  header: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  description: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  parentCategory: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  order: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  questions: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  questionOrder: {
    editActive: false,
    saveStatus: SaveStatusEnum.Default,
  },
  update: () => {},
};

const GroupEditorDrawer: React.FC = () => {
  const { groupId } = useParams();
  const width = useDynamicDrawerWidth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState<QuestionGroupNode | any>(
    initialGroupState,
  );

  const { loading, refetchGroup, update } = useQuestionGroup(groupId, setSelectedGroup);

  const [deleteGroup, { loading: deleteLoading }] = useMutation(Api.QuestionGroup.Delete(), {
    variables: {
      id: groupId,
    },
    onCompleted: (res) => {
      console.log(res);
      message.success("Group successfully deleted!", 5);
      navigate(-1);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 5);
    },
    refetchQueries: [{ query: Api.QuestionGroup.GetAll() }],
  });

  const [state, dispatch] = useReducer(groupEditorReducer, initialState);

  const deleteGroupMutation = () => {
    deleteGroup({
      variables: { id: groupId },
    });
  };

  const onClose = () => {
    setOpen(false);
    navigate(-1);
  };

  console.log({ selectedGroup });

  const invalidGroup = selectedGroup.id === "345";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={width}
      destroyOnClose={true}
      title="Manage Question Group"
      extra={
        <Button
          danger={true}
          onClick={() => setDeleteModalOpen(true)}
          type="primary"
          icon={<DeleteOutlined />}
          disabled={invalidGroup}
        >
          Delete Group
        </Button>
      }
      bodyStyle={{
        background: "#f0f0f0",
        overflow: "hidden",
        padding: 0,
        height: "calc(100vh - 65px) !important",
        maxHeight: "calc(100vh - 65px)",
      }}
      className="wf-editor-drawer"
    >
      <GroupEditor
        state={state}
        dispatch={dispatch}
        group={selectedGroup}
        refetchGroup={refetchGroup}
        loading={loading}
        key="groupEditor"
      />
      {deleteModalOpen && (
        <InputConfirmModal
          visible={deleteModalOpen}
          loading={deleteLoading}
          prompt="Are you sure you want to delete this Question Group?"
          mutation={deleteGroupMutation}
          confirmation="delete/group"
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
    </Drawer>
  );
};

export default GroupEditorDrawer;
