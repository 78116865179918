import { SaveOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";

type Props = {
  value: number | undefined;
  defaultValue?: number;
  onChange: any;
  saveValue: any;
  styles?: any;
};

const ParentOrderingField: React.FC<Props> = ({
  value,
  defaultValue,
  onChange,
  saveValue,
  styles,
}) => {
  return (
    <Input.Group compact>
      <Input
        defaultValue={defaultValue}
        value={value}
        onBlur={saveValue}
        onChange={(e: any) => onChange(e)}
        type="number"
        onPressEnter={saveValue}
        style={{ ...styles, width: 85, height: "32.25px !important" }}
      />
      <Button icon={<SaveOutlined />} type="primary" onClick={saveValue} />
    </Input.Group>
  );
};

export default ParentOrderingField;
