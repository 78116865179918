import { useEffect } from "react";
import moment from "moment";
import useDocumentTitle from "hooks/useDocumentTitle";
import useViewport from "hooks/useViewport";
import useEnumPresentation from "hooks/useEnumPresentation";
import { useApplicationContext } from "components/Layouts/ApplicantLayout";
import { mapStatusToTag } from "components/Common/Tags/ApplicationStatus";
import DashboardLayout from "../../Components/Dashboards/DashboardLayout";
import ProgressMetric from "../../Components/Metrics/ProgressMetric";
import ApplicationModules from "./ApplicationModules";
import EntityNotFound from "components/Common/EntityNotFound";
import { Row, Col, Card, Typography, Space, Tooltip } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import { ApplicationNode, ApplicationQuestionModuleNode } from "types";

const ApplicationDashboard = () => {
  useDocumentTitle("Application Dashboard");

  const viewport = useViewport();
  const { application, loading, error, refetch } = useApplicationContext();

  useEffect(() => {
    refetch();
  }, []);

  if (error) {
    return <EntityNotFound />;
  }

  console.log({ Application: application });
  const modules = (application?.questionModules as Array<ApplicationQuestionModuleNode>) ?? [];
  console.log({ Modules: modules });

  return (
    <DashboardLayout
      loading={loading}
      header={{
        title: "Application Dashboard",
        icon: <GlobalOutlined />,
      }}
      information={<ApplicationInformation application={application!} viewport={viewport} />}
      time={application?.appTime!}
      risk={application?.totalRisk!}
      skeletonType="workflowModuleContent"
    >
      <ApplicationModules
        loading={loading}
        appName={application?.name}
        modules={loading ? [] : modules}
      />
    </DashboardLayout>
  );
};

export default ApplicationDashboard;

const ApplicationInformation: React.FC<{ application: ApplicationNode; viewport: any }> = ({
  application,
  viewport,
}) => {
  const { PresentActionPath } = useEnumPresentation();

  return (
    <Card className="shadowed-static">
      <Row justify="space-between" style={{ marginBottom: 5 }}>
        <Row>
          <Typography.Title level={3}>{application.name}</Typography.Title>
        </Row>
        <Row
          justify="end"
          style={{ width: viewport.isMobile ? "100%" : "fit-content", marginBottom: 5 }}
        >
          <Space>
            <Tooltip title="Application Status">
              <Typography.Text style={{ marginRight: -5 }}>
                {mapStatusToTag(application.status) || "Not Started"}
              </Typography.Text>
            </Tooltip>
          </Space>
        </Row>
      </Row>
      <Row justify="space-between" style={{ marginBottom: 5 }}>
        <Col>
          <Row style={{ marginBottom: 5 }}>
            <b style={{ paddingRight: 5 }}>Action Path:</b>
            <Typography.Text>
              {PresentActionPath(application.rmfActionPath!) || "Unassigned"}
            </Typography.Text>
          </Row>
          <Row style={{ marginBottom: 5 }}>
            <b style={{ paddingRight: 5 }}>Platform:</b>
            <Typography.Text>{application.platform || "Unassigned"}</Typography.Text>
          </Row>
        </Col>
        <Col>
          <Row justify={viewport.isMobile ? "start" : "end"} style={{ marginBottom: 5 }}>
            <b style={{ paddingRight: 5 }}>Created On:</b>
            <Typography.Text>{moment(application.createdOn).format("LL")}</Typography.Text>
          </Row>
          <Row justify={viewport.isMobile ? "start" : "end"} style={{ marginBottom: 5 }}>
            {/* <b style={{ paddingRight: 5 }}>Last Update:</b>
            <Typography.Text>
              {application.updatedOn
                ? moment(application.updatedOn).format("LL")
                : "Not Started"}
            </Typography.Text> */}
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Application Progress
        </Typography.Title>
      </Row>
      <ProgressMetric percent={application.totalProgress!} />
    </Card>
  );
};
