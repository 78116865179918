import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Row, Col } from "antd";

import pages from "./Pages";
export const PCILv21Pages = pages;

export const PCILv21Header = ({ pageNumber }) => {
  if (pageNumber === 1) return null

  return (
    <Row className="doc_pcilv2" justify="space-between" align="top" style={{ margin: '0px 20px', color: "grey", fontSize: ".75rem" }}>
      <i>PII Confidentiality Impact Level Categorization Template</i>
      <i>Version.2.1 (June 2023)</i>
    </Row>
  );
};

export const PCILv21Footer = ({ state, pageNumber }) => {
  const style = {
    paddingTop: 5,
    fontSize: 11,
  };

  if (pageNumber === 1) return null

  const document = state?.document;

  return (
    <Row justify="center" className="doc_pcilv2">
      <div style={{ borderTop: 'solid black 1px', width: '90%', display: 'flex', justifyContent: 'center' }}>
        {pageNumber}
      </div>
    </Row>
  );
};

export const RiskLevelSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      label={config.label}
      fieldName={config.value}
      options={[
        { label: "HIGH", value: `${config.value}_high` },
        { label: "MODERATE", value: `${config.value}_moderate` },
        { label: "LOW", value: `${config.value}_low` },
      ]}
    />
  );
};
