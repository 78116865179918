import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Button } from "antd";
import P6D1 from '../Assets/P6D1.png'
import P6D2 from '../Assets/P6D2.png'

export const P15 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="instructions">
        <img style={{ maxWidth: "80%" }} src={P6D1} alt="page 6 diagram 1" />
        <img style={{ maxWidth: "80%" }} src={P6D2} alt="page 6 diagram 1" />
        <p><b>10.SystemArchitecture</b></p>

        <span>On-Premises Deployed: This system is deployed completely on premises.</span>
        <p>Cloud Deployment:</p>
        <ul className="slash">
          <li>
            Private Cloud: The cloud infrastructure is provisioned for exclusive use by a single organization comprising multiple consumers (e.g., business units). It may be owned, managed, and operated by the organization, a third party, or some combination of them, and it may exist on or off premises.
          </li>
          <li>
            Community Cloud: The cloud infrastructure is provisioned for exclusive use by a specific community of consumers from organizations that have shared concerns (e.g., mission, security requirements, policy, and compliance considerations). It may be owned, managed, and operated by one or more of the
          </li>
        </ul>
      </div>
    </>
  );
};
