import {
  CalendarOutlined,
  MessageOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { QuestionType } from "types";

type MultiChoiceTagProps = {
  children: any;
  id: string;
  styles?: any;
};

export const FreeTextTag = () => {
  return (
    <Tag icon={<MessageOutlined />} color="magenta">
      Free Text
    </Tag>
  );
};

export const UploadFileTag = () => {
  return (
    <Tag icon={<UploadOutlined />} color="purple">
      File Upload
    </Tag>
  );
};

export const MultiChoiceTag: React.FC<MultiChoiceTagProps> = ({ children, id, styles }) => {
  return (
    <Tag icon={<UnorderedListOutlined />} color="geekblue" key={id} style={styles}>
      {children}
    </Tag>
  );
};

export const NoHelpTextTag = () => {
  return (
    <Tooltip title="Manage this question to set help text" color="#000000">
      <Tag color="orange">Not Set</Tag>
    </Tooltip>
  );
};

export const DateTag = () => {
  return (
    <Tag icon={<CalendarOutlined />} color="cyan">
      Date
    </Tag>
  );
};

export const DateRangeTag = () => {
  return (
    <Tag icon={<CalendarOutlined />} color="cyan">
      Date Range
    </Tag>
  );
};

export const mapTagToType = (dataType: QuestionType, id?: string): JSX.Element | string => {
  let ans;
  const tagKey = id ? id : dataType;
  switch (dataType) {
    case QuestionType.UploadFile:
      ans = <UploadFileTag key={tagKey} />;
      break;
    case QuestionType.FulfillText:
      ans = <FreeTextTag key={tagKey} />;
      break;
    case QuestionType.MultipleChoicePickOne:
      ans = <MultiChoiceTag children="Multi-Choice: Pick 1" id={tagKey} key={tagKey} />;
      break;
    case QuestionType.MultipleChoicePickMany:
      ans = <MultiChoiceTag children="Multi Choice: Pick Many" id={tagKey} key={tagKey} />;
      break;
    case QuestionType.FulfillDate:
      ans = <DateTag key={tagKey} />;
      break;
    case QuestionType.FulfillDateRange:
      ans = <DateRangeTag key={tagKey} />;
      break;
    default:
      return dataType;
  }

  return ans;
};

export const mapQuestionIcon = (type: QuestionType) => {
  let icon;

  const styles = { fontSize: 16, cursor: "pointer" };

  switch (type) {
    case QuestionType.UploadFile:
      icon = (
        <Tooltip title="Answer Type: File Upload">
          <UploadOutlined style={styles} />
        </Tooltip>
      );
      break;
    case QuestionType.FulfillText:
      icon = (
        <Tooltip title="Answer Type: Free Text" color="black">
          <MessageOutlined style={styles} />
        </Tooltip>
      );
      break;
    case QuestionType.MultipleChoicePickOne:
      icon = (
        <Tooltip title="Answer Type: Select 1" color="black">
          <OrderedListOutlined style={styles} />
        </Tooltip>
      );
      break;
    case QuestionType.MultipleChoicePickMany:
      icon = (
        <Tooltip title="Answer Type: Select Many" color="black">
          <UnorderedListOutlined style={styles} />
        </Tooltip>
      );
      break;
    default:
      icon = <></>;
  }

  return icon;
};
