import { useQuery } from "@apollo/client";
import { message } from "antd";
import Api from "api";
import { ApplicationNode, ApplicationQuery } from "types";

const useApplication = (applicationId: string) => {
  const { data, loading, error, refetch } = useQuery<ApplicationQuery>(
    Api.Application.GetOne(),
    {
      variables: {
        id: applicationId,
      },
      onError: (error) => {
        console.log(error.message);
        message.error("Application not found", 7);
      },
    },
  );

  const application: ApplicationNode | any = data?.application;
  console.log("%cApplication", "color: steelblue", { Application: application });

  return { application, loading, error, refetch };
};

export default useApplication;
