import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { AlignedEditableCheckbox, AlignedEditableRadio } from "..";
import { Footer } from "./P2";

export const P4 = (props) => {
  const elementProps = {

    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  	pageNumber: props.pageNumber
}

  return (
    <>
      <div
        className="ao-document"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 40,
        }}
      >
        <div className="attachment-3">
          <div style={{ width: 100 }}>
            <h1
              style={{
                textDecoration: "underline",
                fontWeight: "bolder",
                fontSize: 12,
                marginBottom: 16,
              }}
            >
              Attachment 3
            </h1>
          </div>
          <div
            style={{
              width: "35%",
              marginBottom: 16,
            }}
          >
            <h1
              style={{
                fontWeight: "bolder",
                fontSize: 12,
                height: "100%",
                width: "max-content",
              }}
            >
              Plan of Action and Milestones (POA&M)
            </h1>
          </div>
          <div style={{ marginBottom: 18 }}>
            <p>
              {" "}
              <strong>NOTE: </strong>{" "}
              <i>If classified, please specify the location as to where to find the POA&M</i>
            </p>
          </div>
        </div>

        <div
          className="attachment-3-table"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <table border="1">
            <thead>
              <tr>
                <td style={{ fontWeight: "bolder" }} width="3%"></td>
                <td width="35%">Finding Brief Description</td>
                <td width="30%">Risk</td>
                <td width="20%">Target Mitigation Date</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bolder" }}>1.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>2.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>3.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>4.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>5.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>6.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>7.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>8.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>9.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>10.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <Footer {...props} />
      </div>
    </>
  );
};

export const FieldWrapper = ({ height, children, styles }) => {
  return (
    <div
      style={{
        background: "#AAD3EE",
        width: 200,
        maxWidth: "100%",
        border: "1px solid #000000",
        height: height,
        marginBottom: 12,
        ...styles,
      }}
    >
      {children}
    </div>
  );
};
