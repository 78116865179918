import { gql } from "graphql-tag";

export const ApplicationCheckpoint = {
  CreateCategoryCheckpoint: () => gql`
    mutation createApplicationCheckpoint(
      $id: String!
      $questionsLocked: Boolean
      $sessionComplete: Boolean
    ) {
      createApplicationCheckpoint(
        categoryId: $id
        questionsLocked: $questionsLocked
        checkpointSessionCompleted: $sessionComplete
      ) {
        id
        isLocked
        isComplete
        checkpoint {
          triggered
          questionsLocked
          checkpointSessionCompleted
          isWorkflowCheckpoint
        }
      }
    }
  `,
  UpdateCategoryCheckpoint: () => gql`
    mutation updateApplicationCheckpoint(
      $id: String!
      $questionsLocked: Boolean
      $sessionComplete: Boolean
    ) {
      updateApplicationCheckpoint(
        objectId: $id
        questionsLocked: $questionsLocked
        checkpointSessionCompleted: $sessionComplete
      ) {
        ... on ApplicationQuestionModuleNode {
          id
          isLocked
          isComplete
          checkpoint {
            triggered
            questionsLocked
            checkpointSessionCompleted
            isWorkflowCheckpoint
          }
        }
        ... on ApplicationQuestionCategoryNode {
          id
          isLocked
          isComplete
          checkpoint {
            triggered
            questionsLocked
            checkpointSessionCompleted
            isWorkflowCheckpoint
          }
        }
      }
    }
  `,
  DeleteCategoryCheckpoint: () => gql`
    mutation deleteApplicationCheckpoint($id: String!) {
      deleteApplicationCheckpoint(categoryId: $id) {
        id
        isLocked
        isComplete
        checkpoint {
          triggered
          questionsLocked
          checkpointSessionCompleted
          isWorkflowCheckpoint
        }
      }
    }
  `,
};
