import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Api from "api";
import useDocumentTitle from "hooks/useDocumentTitle";
import commonModalProps from "../../../Common/commonModalProps";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import SmartTable from "components/Common/Tables";
import NestedOrphanTable from "./NestedOrphanTable";
import ExpandIcon from "../../../Common/ExpandedTableIcon";
import InputConfirmModal from "../../../Common/InputConfirmModal";
import CreateQuestion from "../Forms/Create/CreateQuestion";
import { Button, Card, message, Modal, Row, Col } from "antd";
import { SettingOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { QuestionNode } from "types";

/**
 * @returns Question Repository Table (orphan questions)
 */

const QuestionRepositoryTable: React.FC = () => {
  useDocumentTitle("Question Repository");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionNode | any>({});

  const onClose = () => {
    setCreateModalOpen(false);
    setDeleteModalOpen(false);
    setSelectedQuestion({});
  };

  const { data, loading } = useQuery(Api.Question.GetAllOrphanedQuestions(), {
    onCompleted: (res) => {
      console.log(res);
    },
    onError: (error) => {
      console.log(error);
      message.error(error.message, 7);
    },
  });

  const orphanedQuestions = data?.allOrphanQuestions;

  const [deleteQuestion, { loading: deleteQuestionLoading }] = useMutation(
    Api.Question.Delete(),
    {
      onCompleted: () => {
        message.success("Successfully deleted a question!", 7);
        onClose();
      },
      onError: (error) => {
        message.error(error.message, 7);
        console.log(error.message);
        onClose();
      },
      refetchQueries: [{ query: Api.Question.GetAllOrphanedQuestions() }],
    },
  );

  const deleteQuestionMutation = () => {
    deleteQuestion({
      variables: {
        id: selectedQuestion.id,
      },
    });
  };

  const columns: ColumnsType<QuestionNode> = [
    {
      title: "Question",
      dataIndex: "question",
      width: "95%",
    },
    {
      title: "Manage",
      align: "right",
      width: "5%",
      render: (_text, record, _index) => (
        <Link to={`/administration/question-repository/question-editor/${record.id}`}>
          <Button icon={<SettingOutlined />} />
        </Link>
      ),
    },
  ];

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="Question Repository"
        subTitle="Questions not assigned to a category or group"
        actions={[
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setCreateModalOpen(true)}
          >
            Create
          </Button>,
        ]}
      />
      <Row align="middle">
        <Col span={24}>
          <Card className="shadowed-static">
            <SmartTable
              loading={loading}
              data={orphanedQuestions}
              columns={columns}
              expandable={{
                expandedRowRender: (record: QuestionNode) => (
                  <NestedOrphanTable data={record} key={record.id} />
                ),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <ExpandIcon
                    expanded={expanded}
                    onExpand={onExpand}
                    record={record}
                    key={record.id}
                  />
                ),
              }}
            />
          </Card>
        </Col>
      </Row>
      {createModalOpen && <QuestionRepoModal open={createModalOpen} onClose={onClose} />}
      {deleteModalOpen && (
        <InputConfirmModal
          visible={deleteModalOpen}
          prompt="This will permanently delete this Question"
          confirmation={"delete/question"}
          mutation={deleteQuestionMutation}
          onClose={() => setDeleteModalOpen(false)}
          loading={deleteQuestionLoading}
        />
      )}
      <Outlet />
    </>
  );
};

export default QuestionRepositoryTable;

type ModalProps = {
  open: boolean;
  onClose: any;
};

const QuestionRepoModal: React.FC<ModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      title="Create Question"
      open={open}
      onCancel={onClose}
      okText="Save"
      width={580}
      bodyStyle={{ maxHeight: 700, overflow: "auto" }}
      {...commonModalProps}
    >
      <CreateQuestion closeModal={onClose} />
    </Modal>
  );
};
