import dayjs from "dayjs";
import { FulfillInputProps } from "../Questions/FulfillQuestion";
import { DatePicker } from "antd";

const FulfillDateInput: React.FC<FulfillInputProps> = ({
  answerId,
  defaultAnswer,
  onChange,
  disabled,
  style,
}) => {
  const format = "DD MMM YY";

  const composedValue: any = defaultAnswer ? dayjs(defaultAnswer, format) : "";

  return (
    <>
      <DatePicker
        id={answerId}
        format={format}
        defaultValue={composedValue}
        onChange={(date, dateString) => {
          let answer = {
            id: answerId,
            text: date?.isValid ? dateString : "",
          };

          onChange(answer);
        }}
        disabled={disabled}
        style={{
          display: "flex",
          width: "100%",
          ...style,
        }}
      />
    </>
  );
};

export default FulfillDateInput;
