import { useQuery } from "@apollo/client";
import { message } from "antd";
import Api from "api";
import { ApplicationModuleQuery, ApplicationQuestionModuleNode } from "types";

const useModule = (moduleId: string) => {
  const { data, loading, error } = useQuery<ApplicationModuleQuery>(
    Api.ApplicationModule.GetOne(),
    {
      variables: {
        id: moduleId,
      },
      onError: (error) => {
        console.log(error.message);
        message.error("Module not found", 7);
      },
    },
  );

  const mod: ApplicationQuestionModuleNode | any = data?.applicationModule;
  console.log("%cModule", "color: steelblue", { Module: mod });

  return { mod, loading, error };
};

export default useModule;
