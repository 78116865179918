import { useMutation } from "@apollo/client";
import Api from "api";
import { message } from "antd";

const usePasswordChange = () => {
  const [sendPasswordResetEmailMutation, { loading: sendPasswordResetEmailLoading }] =
    useMutation(Api.User.SendPasswordChangeEmail(), {
      onCompleted: (res) => {
        console.log(res);
        message.info(res.resetPassword.response, 10);
        console.log(
          "%cPassword Change: %cEmail Sent Successfully",
          "color: steelblue",
          "color: green",
        );
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message);
      },
    });

  const [changePasswordMutation, { loading: changePasswordLoading }] = useMutation(
    Api.User.ChangePassword(),
    {
      onCompleted: (res) => {
        console.log(res);
        message.info(res.setUserPassword.response, 10);
        console.log(
          "%cPassword Reset: %cChanged Successfully",
          "color: steelblue",
          "color: green",
        );
      },
      onError: (error) => {
        console.log(error.message);
        message.error(error.message);
      },
    },
  );

  const changePassword = (
    email: string,
    resetToken: string,
    newPassword: string,
    confirmPassword: string,
  ) => {
    console.log(
      "%cPassword Change: %cRequesting Change",
      "color: steelblue",
      "color: goldenrod",
      email,
    );
    changePasswordMutation({
      variables: {
        email,
        resetToken,
        password: newPassword,
        confirmPassword: confirmPassword,
      },
    });
  };

  const sendPasswordResetEmail = (email: string) => {
    console.log(
      "%cPassword Change: %cRequesting Reset Email",
      "color: steelblue",
      "color: goldenrod",
      email,
    );
    sendPasswordResetEmailMutation({
      variables: {
        email,
      },
    });
  };

  return {
    changePassword,
    changePasswordLoading,
    sendPasswordResetEmail,
    sendPasswordResetEmailLoading,
  };
};

export default usePasswordChange;
