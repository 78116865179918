import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, FirstCategorySubmissionSelect, ResetButton, SecurityClassificationSelect, SpecialHandlingCaveatsSelect } from "..";
import { Row, Col, Button } from "antd";

export const P3 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  return (
    <>
      <div className="itcsc page">
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'50%'} />
              <col span={1} width={'50%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={2}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <div>3.Security Classification <i>Refs: (i-o)</i></div>
                  </div>

                </th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td className="label">
                  3A. Highest Classification of Information Processed
                </td>
                <td className="answer">
                  <SecurityClassificationSelect
                    elementProps={elementProps}
                    config={{ label: 'Select One', value: 'security_classification_3a' }}
                    style={{ width: '100%' }}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  3B. Are there special handling caveats (e.g.,<br />
                  dissemination control or releasability) for the<br />
                  information processed?
                </td>
                <td className="answer">
                  <SpecialHandlingCaveatsSelect
                    elementProps={elementProps}
                    style={{ width: '100%' }}
                    config={{
                      label: 'Select One',
                      value: "security_classification_3b"
                    }}
                  />
                  <div style={{ width: '100%', display: 'flex' }}>
                    <EditableRadio {...elementProps}
                      fieldName="security_classification_3b_other"
                      label={<b>Other</b>}
                      extra={<EditableField {...elementProps} fieldName="security_classification_3b_other_text" style={{ marginLeft: '40px' }} />}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  3C. Does the system process SAP data?
                </td>
                <td className="answer">
                  <FirstCategorySubmissionSelect
                    elementProps={elementProps}
                    style={{ width: '100%' }}
                    config={{
                      label: 'Select One',
                      value: 'security_classification_3c'
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  3D. Any Foreign National Users?
                </td>
                <td className="answer">
                  <FirstCategorySubmissionSelect
                    elementProps={elementProps}
                    style={{ width: '100%' }}
                    config={{
                      label: 'Select One',
                      value: 'security_classification_3d'
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  3E. List applicable Security Classification Guide (SCG)
                </td>
                <td className="answer">
                  <EditableField {...elementProps} fieldName="security_classification_3e" />
                </td>
              </tr>
            </tbody>
          </table>
          <table border={1}>
            <colgroup>
              <col span={1} width={'50%'} />
              <col span={1} width={'50%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={2}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <div>4. Cybersecurity Service Provider (CSSP) Alignment and Validation<i>Refs: (p-q)</i></div>
                  </div>

                </th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td className="label">
                  4A. CSSP Compliant?
                </td>
                <td className="answer">
                  <div style={{ display: 'flex', gap: 50, width: 'fit-content' }}>
                    <EditableRadio {...elementProps} fieldName="cssp_alignment_validation_4a_yes" label="Yes" style={{ flexDirection: 'row-reverse', gap: 20 }} />
                    <EditableRadio {...elementProps} fieldName="cssp_alignment_validation_4a_no" label="No" style={{ flexDirection: 'row-reverse', gap: 20 }} />
                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  4B. CSSP Validation (Enter Date)
                </td>
                <td className="answer">
                  <EditableField {...elementProps} fieldName="cssp_alignment_validation_4b_date" isDate/>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="text" style={{ padding: '6px 10px' }}>
                  <i>Note 3:</i> All DAF systems must be aligned to a CSSP. The DAF is supported by 16 AF and USSF service providers and these<br />
                  entities serve as the only DAF approved ingress points for CSSP requests.<br />
                  Request or Confirm CSSP alignment through the DAF CSSP Intelink Site <i>@ https://intelshare.intelink.gov/sites/16af/cssp/</i><br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ResetButton>Reset Section 3-4</ResetButton>
      </div>
    </>
  );
};
