import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";
import P6D1 from "../Assets/P6D1.png";
import P6D2 from "../Assets/P6D2.png";
import P6D3 from "../Assets/P6D3.png";
import { Row } from "antd";

export const P6 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props.pageNumber
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const BTSLMarking = {
    label: "Select Marking",
    value: "boundary_topology_system_location_marking",
  };

  return (
    <>
      <div>
        {/* 7. System Description / Purpose */}
        <div className="page-table">
          <table width={'100%'}>
            <colgroup>
              <col span={1} width="100%" />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={BTSLMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                      8.Boundary Topology and System Location
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="text">
                  <i>
                    Note 10: This is a text field only. Please provide narrative reference in the field below and upload any pictures or diagrams<br />
                    (e.g., DODAF OV-1 and SV-6), including interface requirements, to eMASS as artifacts
                  </i>
                </td>
              </tr>
              <tr>
                <td>
                  <EditableField {...elementProps} fieldName="8_boundary_topology"
                    style={{ minHeight: '250px' }}
                  />
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ padding: '30px' }}>
              <h4>
                Example Drawings: Actual Drawings Should be<br />
                Posted to eMASS
              </h4>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', marginBottom: '4%' }}>
              <img style={{ maxWidth: "100%" }} src={P6D3} alt="page 6 diagram 1" />
              Reference: Cloud Connection Process Guide
            </div>

          </div>

          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', rowGap: '4px' }}>
            <img style={{ maxWidth: "80%", marginLeft: '10%' }} src={P6D2} alt="page 6 diagram 1" />
            <img style={{ maxWidth: "100%" }} src={P6D1} alt="page 6 diagram 1" />
          </div>
        </div>
      </div>
    </>
  );
};
